<template>
  <div class="form-retirar-cacamba">
    <span class="dx-form-group-caption">Retirada de Caçamba</span>

    <template v-if="solicitacaoData">
      <fields-destino-final-veiculo v-model="solicitacaoData"/>

      <grid-residuos v-model="solicitacaoData"/>
      <hr />

      <model-file-uploader
        v-model="solicitacaoData.fotoCacamba"
        accept="image/*"
        attribute="fotoCacamba"
        class="flex-col-6"
        entity="SolicitacaoTransporte"
        :showRemoveButton="false"
        :getDefaultImage="false"
        :file-name="fotoCacambaFilename"
        :id="solicitacaoDataIdToDownloadFile"
        :label="fileLabel"/>
      <div v-if="solicitacaoData.nomeFotoCacamba || solicitacaoData.fotoCacamba.length">
        <dx-button
          style="margin-right: 10px"
          type="info"
          text="Baixar"
          @click="downloadAnexo()"
        />
        <dx-button
          type="info"
          text="Remover"
          @click="removerAnexo()"
        />
        <span style="place-self: center; font-weight: bold; margin-left: 10px">{{ solicitacaoData.fotoCacamba[0] && solicitacaoData.fotoCacamba[0].name || solicitacaoData.nomeFotoCacamba }}</span>
      </div>
      <text-area-field
        v-model="solicitacaoData.observacao"
        class="flex-col-6"
        label="Observações"
        :options="observacoesFieldOptions"/>
    </template>

    <message-error :message.sync="errorMessage"/>

    <div>
      <dx-button
        v-if="solicitacaoData"
        class="float-right button-save-solicitacao"
        type="success"
        @click="onButtonSaveClick">
        Salvar
      </dx-button>
      <dx-button
        class="float-right"
        type="normal"
        @click="goToList">Cancelar</dx-button>
    </div>
  </div>
</template>
<script>
import { DxButton } from 'devextreme-vue'
import { mapActions } from 'vuex'

import {
  saveSolicitacaoTransporte,
  getSolicitacaoTransporteById
} from '../service'
import SaveSolicitacaoTransporteComCacambaDto from './SaveSolicitacaoTransporteComCacambaDto'
import GetSolicitacaoCacambaDto from './GetSolicitacaoCacambaDto'
import FieldsDestinoFinalVeiculo from './FieldsDestinoFinalVeiculo'
import MessageError from '@/components/MessageError'
import GridResiduos from '@/components/SolicitacaoTransporte/GridResiduos'
import ModelFileUploader from '@/components/Form/ModelFileUploader'
import TextAreaField from '@/components/Form/TextAreaField'
import moment from 'moment'
import { confirm } from 'devextreme/ui/dialog'
import { doRequest } from '@/pluggables/http'
import notify from 'devextreme/ui/notify'

export default {
  name: 'form-retirar-cacamba',
  components: {
    DxButton,
    MessageError,
    FieldsDestinoFinalVeiculo,
    GridResiduos,
    ModelFileUploader,
    TextAreaField
  },
  /**
   * Metodo do vue, para obter o estado inicial do componente.
   * @returns {Object} O estado inicial do componente.
   */
  data () {
    return {
      solicitacaoData: null,
      observacoesFieldOptions: {
        maxLength: 200
      },
      errorMessage: null,
      solicitacaoDataIdToDownloadFile: null
    }
  },
  computed: {
    fileLabel () {
      return this.solicitacaoData
        ? this.solicitacaoData.fotoCacamba
          ? 'Substituir foto da caçamba'
          : 'Anexar foto da caçamba'
        : null
    },
    /**
     * Valor computado do nome do arquivo PGRCC.
     * @returns {string}
     */
    fotoCacambaFilename () {
      return (this.solicitacaoData &&
        this.solicitacaoData.fotoCacamba &&
        this.solicitacaoData.fotoCacamba[0] &&
        this.solicitacaoData.fotoCacamba[0].name) || undefined
    }
  },
  async mounted () {
    try {
      this.setLoading(true)
      const solicitacaoResult = await getSolicitacaoTransporteById(this.$route.params.id)
      if (!solicitacaoResult.success) {
        throw new Error(solicitacaoResult.error)
      }
      if (!solicitacaoResult.data) {
        this.errorMessage = 'Não foi encontrada a solicitação de transporte, tente novamente.'
        return
      }
      if (!['Enviada', 'Alocada'].includes(solicitacaoResult.data.situacao)) {
        this.errorMessage = `Não é possível retirar a caçamba da solicitação de transporte ${this.$route.params.id}, tente retirar a caçamba de outra solicitação de transporte.`
        return
      }

      this.solicitacaoData = new GetSolicitacaoCacambaDto(solicitacaoResult.data)

      // Se a solicitacao tiver uma foto salvar o id para poder fazer o
      // download da foto.
      if (this.solicitacaoData.fotoCacamba) {
        this.solicitacaoDataIdToDownloadFile = this.solicitacaoData.id
      }
    } catch (e) {
      console.error(e)
      this.errorMessage = 'Ocorreu uma falha ao buscar os dados da solicitação de transporte, tente novamente.'
    } finally {
      this.setLoading(false)
    }
  },
  methods: {
    /**
     * Metodo de callback de quando foi clicado no botao de salvar a
     * solicitacao. Com o objetivo de enviar a requisicao de salvar a
     * solicitacao de transporte nao solicitado.
     */
    async onButtonSaveClick () {
      this.setLoading(true)
      this.errorMessage = null

      if (this._validateForm()) {
        this.solicitacaoData.residuos.forEach(residuo => {
          residuo.volumeAproximadoM3 = residuo.volume
        })
        const params = new SaveSolicitacaoTransporteComCacambaDto(this.solicitacaoData)
        if (!params.idResiduoPredominante) {
          this.errorMessage = 'Não foi selecionado um destino final.'
        } else {
          const res = await saveSolicitacaoTransporte(params.toRequestParams())
          if (res.success || !res) {
            this.goToList()
          } else {
            this.errorMessage = res.error
            this.setLoading(false)
          }
        }
      }
      this.setLoading(false)
    },

    /**
     * Metodo de comando, com o objetivo de ir para a pagina de listagem de
     * solicitacoes de transporte nao solicitado.
     */
    goToList () {
      this.$router.push({ path: '/retirar-cacamba' })
    },

    /**
     * Metodo de comando, com o objetivo de validar o formulario.
     * @returns {Boolean} Se o formulario esta valido ou nao.
     */
    _validateForm () {
      const solicitacaoDatePlus30Min = moment(new Date(this.solicitacaoData.dataHoraAlocacao)).add(30, 'minutes')
      if (this.solicitacaoData.tipo !== 'Basculante' && moment().valueOf() <= solicitacaoDatePlus30Min.valueOf()) {
        this.errorMessage = `Atenção, esta caçamba só poderá ser retirada em ${(moment().locale('pt-br').from(solicitacaoDatePlus30Min)).replace('há', '')}`
        return false
      }

      /* const solicitacaoFulLDate = moment(this.solicitacaoData.dataHoraAlocacao).locale('pt-br').format('DD/MM/YYYY HH:mm:ss')
      const solicitacaoDate = solicitacaoFulLDate.split(' ')

      const currentFullDate = new Date().toLocaleString()
      const currentDate = currentFullDate.split(' ') */
      if (!this.solicitacaoData.veiculo) {
        this.errorMessage = 'Não foi selecionado um veiculo.'
        return false
      }
      if (!this.solicitacaoData.destinoFinal) {
        this.errorMessage = 'Não foi selecionado um destino final.'
        return false
      }

      if (!this.solicitacaoData.tipoResiduoPredominante) {
        this.errorMessage = 'Não foi informado o campo de tipo de resíduo predominante.'
        return false
      }

      const residuosRepeted = Object.entries(this.solicitacaoData.residuos
        // Conta quantas vezes cada id aparece
        .reduce((acc, { tipoResiduo }) => {
          if (!(tipoResiduo.id in acc)) {
            acc[tipoResiduo.id] = { count: 0, tipoResiduo }
          }
          acc[tipoResiduo.id].count++
          return acc
        }, {}))
        // Filtra os que sao maiores que um
        .filter(v => v[1].count > 1)
        // Pega os nomes
        .map(v => v[1].tipoResiduo.nome)
      if (residuosRepeted.length) {
        this.errorMessage = `O(s) tipo(s) de resíduos ${residuosRepeted.join(', ')} está(ão) repetido(s).`
        return false
      }

      if (this.solicitacaoData.fotoCacamba.length) {
        const [file] = this.solicitacaoData.fotoCacamba
        if (file.size > 1000000) {
          this.errorMessage = 'Só é permitido anexar um arquivo com tamanho máximo: 1MB.'
          return false
        }

        if (!file.type.match(/^image\/.*/i)) {
          this.errorMessage = 'Só é permitido anexar um arquivo no formato de imagem.'
          return false
        }
      }

      this.setLoading(false)
      return true
    },
    async removerAnexo () {
      if (this.solicitacaoData.nomeFotoCacamba) {
        const dialogResult = await confirm('<i>Tem certeza que deseja remover este anexo?</i>', 'Confirmar')
        if (dialogResult) {
          try {
            await doRequest({
              method: 'put',
              url: `SolicitacaoTransporte/${this.solicitacaoData.id}`,
              params: {
                fotoCacamba: null,
                nomeFotoCacamba: null
              }
            })
            this.solicitacaoData.nomeFotoCacamba = null
          } catch (error) {
            notify('Não foi possível remover este anexo, por favor contate um administrador do sistema', 'error')
            console.error(error)
          }
        }
      }
      this.solicitacaoData.fotoCacamba = []
    },
    async downloadAnexo () {
      const download = async (buffer, name) => {
        const fileBuffer = new Uint8Array(buffer)
        const blob = new Blob([fileBuffer])
        const fileName = name || this.solicitacaoData.nomeFotoCacamba
        const link = document.createElement('a')
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob)
          link.setAttribute('href', url)
          link.setAttribute('download', fileName)
          link.style.visibility = 'hidden'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      }
      const { data: solicitacaoTransporte } = await doRequest({
        method: 'post',
        url: 'SolicitacaoTransporte/find',
        params: {
          fields: ['fotoCacamba'],
          where: {
            id: this.solicitacaoData.id
          }
        }
      })
      if (Array.isArray(solicitacaoTransporte.rows) && solicitacaoTransporte.rows.length && solicitacaoTransporte.rows[0].fotoCacamba) {
        const row = solicitacaoTransporte.rows[0] && solicitacaoTransporte.rows[0].fotoCacamba ? solicitacaoTransporte.rows[0].fotoCacamba : null
        await download(row.data)
      } else if (this.solicitacaoData.fotoCacamba.length) {
        const buffer = await this.solicitacaoData.fotoCacamba[0].arrayBuffer()
        await download(buffer, this.solicitacaoData.fotoCacamba[0].name)
      }
    },
    ...mapActions('Crud', ['setLoading'])
  }
}
</script>

<style lang="scss">
@import '../../../../global.scss';

.form-retirar-cacamba {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  > .dx-form-group-caption {
    font-family: Montserrat, sans-serif;
  }

  > .group-cacamba-veiculo {
    flex-wrap: wrap;
    margin-bottom: 20px;
  }

  .button-save-solicitacao {
    margin-left: 12px;
  }

  > .error-message-card {
    margin-bottom: 16px;
  }
}
</style>
