import * as getters from './getters'
import actions from './actions'
import mutations from './mutations'

export default {
  namespaced: true,
  actions,
  mutations,
  getters,
  state: {
    status: '',
    token: localStorage.getItem('userToken') || null,
    user: null
  }
}
