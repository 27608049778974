<template>
  <div class="list-obra">
    <div class="md-layout">
      <div class="md-layout-item">
        <h2 class="crud-title">Cadastro de Obras</h2>
      </div>

      <div class="md-layout-item" style="text-align: right;">
        <dx-button v-if="false" type="normal" @click="$router.push({ name: 'obra-map' })">
          {{ getMapObraLabel() }}
        </dx-button>
        <dx-button
          v-if="canAdd"
          type="success"
          @click="$router.push({ path: 'obra/add' })">
          Adicionar Novo
        </dx-button>
      </div>
    </div>
    <simple-grid
      entity="Obra"
      :grid-data="gridData"
      :deletable="canDelete"
      :editable="false"
      :add-column-to-action-buttons="false"/>
  </div>
</template>
<script>
import CustomStore from 'devextreme/data/custom_store'
import DxButton from 'devextreme-vue/button'
import { mapGetters } from 'vuex'

import config from 'config'
import { SimpleGrid } from '@/components'
import { deleteObra, listObra } from '../service'
import { parseFilterParams, parseSortParams } from '../../../../util/dxDatasourceHelper'
import { hasPermission } from '../../../../util/permissions'

export default {
  name: 'list-obra',
  components: {
    SimpleGrid,
    DxButton
  },
  computed: {
    /**
     * Valor computado para verificar se o usuario logado e uma secretaria.
     * @returns {Boolean}
     */
    isSecretaria () {
      return !!this.userData.userTypes.find(userType => userType === 'Secretaria')
    },
    isAdministrador () {
      return !!this.userData.userTypes.find(userType => userType === 'Administrador')
    },
    /**
     * Valor computado dos dados do grid.
     * @returns {Object}
     */
    gridData () {
      const canEdit = hasPermission('Manter Obra', 'altera')

      return {
        value: {
          visible: true
        },
        columns: [
          { dataField: 'nomeObra', caption: 'Nome da Obra' },
          { dataField: 'cep', caption: 'CEP da Obra', dataType: 'string' },
          { dataField: 'endereco', caption: 'Endereço da Obra' },
          {
            dataField: '$gerador->pessoa.cpf_cnpj$',
            calculateSortValue: 'gerador.pessoa.cpfCnpj',
            calculateDisplayValue: data => data.cpfCnpj,
            dataType: 'string',
            caption: 'CPF/CNPJ'
          },
          {
            dataField: '$gerador->pessoa.nome_razao_social$',
            calculateSortValue: 'gerador.pessoa.nomeRazaoSocial',
            calculateDisplayValue: data => data.nomeRazaoSocial,
            dataType: 'string',
            caption: 'Nome/Razão Social'
          },
          {
            dataField: 'duracaoInicial',
            caption: 'Duração inicial',
            dataType: 'date'
          },
          {
            dataField: 'duracaoFinal',
            caption: 'Duração final',
            dataType: 'date'
          },
          {
            type: 'buttons',
            showInColumnChooser: false,
            buttons: [
              {
                name: 'pgrcc',
                cssClass: 'dx-button dx-button-primary mdi mdi-file-document',
                text: 'PGRCC',
                onClick: ({ row: { data } }) => {
                  this.onObraActionClick('pgrcc', data)
                },
                visible ({ row: { data } }) {
                  return data.pgrccNomeArquivo
                }
              },
              {
                name: 'map',
                cssClass: 'dx-button dx-button-primary mdi mdi-map',
                text: 'MAPA',
                onClick: ({ row: { data } }) => {
                  this.onObraActionClick('map', data)
                }
              },
              ...(hasPermission('Manter Obra', 'consulta') || canEdit ? [{
                ...(canEdit ? {
                  cssClass: 'dx-button dx-button-success dx-button-mode-contained mdi mdi-pencil',
                  text: 'EDITAR'
                } : {
                  cssClass: 'dx-button dx-button-success dx-button-mode-contained mdi mdi-magnify',
                  text: 'VISUALIZAR'
                }),
                onClick: ({ row: { data } }) => {
                  this.onObraActionClick('update', data)
                }
              }] : [])
            ]
          }
        ],
        dataSource: new CustomStore({
          key: 'id',
          load: async loadOptions => {
            const limit = loadOptions.take ? loadOptions.take : 10
            const offset = loadOptions.skip ? loadOptions.skip : 0
            const where = loadOptions.filter ? parseFilterParams(loadOptions.filter).filterParams : {}
            const order = loadOptions.sort ? parseSortParams((loadOptions.sort)) : []
            const result = await this.$utils.wrapRequestForGrid(listObra({ limit, offset, where, order, ...loadOptions.customQueryParams }))
            if (!(loadOptions.customQueryParams?.format === 'xlsx')) {
              const data = result.success ? result.data.toObraListObjects() : []
              return {
                data,
                totalCount: result.success ? result.data.count : 0
              }
            }
          },
          remove: (key) => deleteObra(key)
        })
      }
    },

    /**
     * Valor computado de se o usuario logado tem permissao de adicionar obras.
     * @returns {boolean}
     */
    canAdd () {
      return hasPermission('Manter Obra', 'inclui')
    },

    /**
     * Valor computado de se o usuario logado tem permissao de excluir obras.
     * @returns {boolean}
     */
    canDelete () {
      return hasPermission('Manter Obra', 'exclui')
    },

    ...mapGetters('Login', ['userData'])
  },
  methods: {
    /**
     * Metodo para obter o rotulo do botao de abrir o mapa com as obras.
     * @returns {string} O rotulo do mapa.
     */
    getMapObraLabel () {
      return this.isSecretaria || this.isAdministrador
        ? 'Visualizar todas obras'
        : 'Visualizar minhas obras'
    },

    /**
     * Metodo de callback de quando e clicado no botao de acao da obra,
     * com o objetivo de executar uma acao na obra.
     * @param {string} action - A acao que sera feita na obra, 'pgrcc'
     * para visualizar o pgrcc da obra, 'update' para atualizar a obra, ou
     * 'map' para abrir o mapa com a localizacao da obra.
     * @param {Object} obra - A obra que foi clicada.
     */
    onObraActionClick (action, obra) {
      switch (action) {
        case 'pgrcc':
          window.open(`${config.baseUrl}/Obra/${obra.id}/pgrcc?format=file&fileName=PGRCC.pdf`, '_blank')
          break
        case 'update':
          this.$router.push({ path: `/obra/edit/${obra.id}` })
          break
        case 'map':
          this.$router.push({
            name: 'obra-map',
            params: { ids: [obra.id] }
          })
          break
      }
    }
  }
}
</script>

<style lang="scss">
.list-obra {
  .md-layout-item > .dx-button:not(:last-child) {
    margin-right: 8px;
  }

  .map-cell > i {
    cursor: pointer;
  }
}
</style>
