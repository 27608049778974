<template>
  <div class="dx-field width-100">
    <div v-if="label" class="dx-field-label-location-top">
      <span class="dx-field-item-label-text">{{ label }}</span>
      <span v-if="required" class="dx-field-item-required-mark">*</span>
    </div>
    <div class="dx-field-content">
      <component
        v-bind="options"
        :is="fieldComponent"
        :value.sync="innerValue"/>
    </div>
  </div>
</template>

<script>
import DxTextBox from 'devextreme-vue/text-box'
import DxNumberBox from 'devextreme-vue/number-box'

import GenericField from './GenericField'

export default {
  name: 'text-field',
  extends: GenericField,
  props: {
    /**
     * O tipo do campo, 'text' se e um campo de texto e 'number' se e um campo
     * de numero. O valor padrao e 'text'.
     */
    type: {
      type: String,
      default: 'text',
      validator: v => ['text', 'number'].includes(v)
    }
  },
  computed: {
    /**
     * Valor computado do componente do dev extreme usado no campo.
     */
    fieldComponent () {
      return this.type === 'text' ? DxTextBox : DxNumberBox
    }
  }
}
</script>

<style lang="scss">
.dx-field {
  font-family: Montserrat, sans-serif;
}
</style>
