<template>
  <div class="address-fields">
    <component
      v-for="[prop, field] in Object.entries(fields)"
      :key="prop"
      :is="field.component"
      v-bind="_getFieldBinds(field.binds)"
      :value="value[prop]"
      @input="onFieldInput(prop, $event)"/>

    <message-error :message.sync="errorMessage" />
  </div>
</template>
<script>
import TextField from '../TextField'
import ModelSelect from '../ModelSelect'
import MessageError from '../../MessageError'
import { getEndereco } from './service'
import fieldsConfig from './fieldsConfig'

export default {
  name: 'address-fields',
  components: {
    MessageError
  },
  props: {
    /**
     * O objeto com os valores dos campos. as props do objeto sao 'cep', 'uf',
     * 'municipio', 'bairro', 'endereco', 'complemento' e 'numero'.
     */
    value: {
      type: Object,
      required: true
    },

    /**
     * Se os campos de endereco estao validos, essa prop e mudada em tempo real.
     */
    isValid: {
      type: Boolean,
      default: false
    },

    /**
     * Se os campos sao somente para leitura.
     */
    readOnly: {
      type: Boolean,
      default: false
    }
  },

  /**
   * Metodo do vue para obter o estado inicial do componente.
   * @returns {Object} O estado inicial do componente.
   */
  data () {
    return {
      fields: { ...fieldsConfig },
      errorMessage: null
    }
  },

  watch: {
    /**
     * Metodo de callback de mudancas no valor da prop 'value', com o objetivo
     * de verificar se os campos obrigatorios estao validos e atualizar o valor
     * da prop 'isValid'
     * @param {Object} value - O valor atual da prop.
     */
    value: {
      immediate: true,
      handler (value) {
        const fieldIsValid = val => Boolean(val && val.length)

        const requiredFieldsIsValid = Boolean(Object.entries(this.fields)
          .every(([prop, field]) => {
            if (!field.binds.required) {
              return true
            }

            const val = value[prop]
            return fieldIsValid(val)
          }))

        this.$emit('update:isValid', requiredFieldsIsValid &&
          Boolean(value.shape) &&
          fieldIsValid(value.codIbge))
      }
    }
  },

  methods: {
    /**
     * Metodo de callback de mudanca no valor de um dos campos de endereco, com
     * o objetivo de emitir o evento de atualizar o valor e executar hooks se
     * necessario.
     * @param {string} prop - A prop do value que foi alterada.
     * @param {string|Object} value - O novo valor da prop.
     */
    onFieldInput (prop, value) {
      this.$emit('input', {
        ...this.value,
        [prop]: value
      })

      const onChangeFn = {
        bairro: this._getShapeOfAddress,
        cep: this._onChangeCep,
        endereco: this._getShapeOfAddress,
        municipio: this._onChangeMunicipio
      }[prop]

      onChangeFn && onChangeFn(value)
    },

    /**
     * Hook de quando foi alterado o valor do cep, com o objetivo de buscar os
     * dados do endereco e definir no form ou abrir os campos para escolha
     * manual.
     * @param {string|Object} value - O valor do campo CEP.
     */
    async _onChangeCep (value) {
      if (value && value.length === 8) {
        const result = await getEndereco({ cep: value })
        if (result.success) {
          const { municipio } = this.fields
          if (municipio.component === ModelSelect) {
            municipio.component = TextField
            municipio.binds = {
              class: 'col-6',
              label: 'Município: ',
              name: 'municipio',
              options: {
                disabled: true
              },
              required: true
            }
            this.fields.endereco.binds.options.disabled = true
          }

          this.$emit('input', {
            ...this.value,
            bairro: result.data.bairro,
            uf: result.data.uf,
            codIbge: result.data.codIbge,
            complemento: result.data.complemento,
            municipio: result.data.municipio,
            endereco: result.data.endereco,
            shape: result.data.shape
          })
        } else {
          this.$emit('input', {
            ...this.value,
            bairro: null,
            uf: null,
            codIbge: null,
            complemento: null,
            municipio: null,
            endereco: null,
            shape: null
          })

          const { municipio } = this.fields
          if (municipio.component === TextField) {
            municipio.component = ModelSelect
            municipio.binds = {
              class: 'col-6',
              entity: 'Municipio',
              label: 'Município: ',
              labelProp: 'nome',
              name: 'municipio'
            }
            this.fields.endereco.binds.options.disabled = false
          }
        }
      }
    },

    /**
     * Hook de quando foi alterado o valor do municipio, com o objetivo de
     * definir o uf se foi ecolhido o municipio manualmente.
     * @param {string|Object} value - O valor do campo municipio.
     */
    _onChangeMunicipio (value) {
      if (value && typeof value === 'object') {
        this.$emit('input', {
          ...this.value,
          municipio: value,
          uf: value.uf
        })
      }
    },

    /**
     * Metodo de comando, com o objetivo de obter o shape do endereco.
     */
    async _getShapeOfAddress () {
      if (!this.fields.endereco.binds.options.disabled) {
        this.errorMessage = null

        const address = [this.value.bairro, this.value.complemento, this.value.municipio.nome, this.value.uf]
          .filter(v => v && v.length)
          .join(', ')
        const result = await getEndereco({ address })

        if (result.success) {
          this.value.shape = result.data.shape
        } else {
          this.value.shape = null
          this.errorMessage = result.data.data
        }
      }
    },

    /**
     * Metodo para obter os binds de um campo.
     * @param {Object} fieldBinds - Os binds padrao do campo.
     * @returns {Object}
     */
    _getFieldBinds (fieldBinds) {
      const ret = { ...fieldBinds }
      ret.options.readOnly = this.readOnly
      return ret
    }
  }
}
</script>

<style lang="scss">
.address-fields {
  display: flex;
  flex-wrap: wrap;

  > .col-12 {
    padding: 4px;
    width: 100%;
  }

  > .col-6 {
    padding: 4px;
    width: 50%;
  }
}
</style>
