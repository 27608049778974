<template>
  <div class="editar-dados-container">
    <VuexWrapper ref="vuexWrapper"/>
    <DxTooltip
      ref="dadosUsuarioTooltip"
      :position="'top'"
      :hide-on-outside-click="false"
      :target="'#dados-usuario-question-mark'">
        <span>
           Dados que correspondem ao usuário
        </span>
    </DxTooltip>
    <DxTooltip
      ref="dadosEmpresaTooltip"
      :position="'top'"
      :hide-on-outside-click="false"
      :target="'#dados-empresa-question-mark'">
        <span>
           Dados que correspondem a pessoa jurídica
        </span>
    </DxTooltip>

    <DxTooltip
      ref="dadosApiTooltip"
      :position="'top'"
      :hide-on-outside-click="false"
      :target="'#dados-api-question-mark'">
        <span>
           Dados para consumo da API de integração. Não compartilhe estas informações.
          <br>Utilize o identificar e token de acesso para comsumir a API de integração.
        </span>
    </DxTooltip>

    <div v-if="Object.entries(formData).length">
      <div v-show="loading" style="position: relative; width: 100%; padding-bottom: 20px; top: 0">
        <md-progress-bar class="md-primary" md-mode="indeterminate"></md-progress-bar>
      </div>
      <form @submit="save">
        <DxForm
          :form-data.sync="formData"
          :show-validation-summary="false"
          validationGroup="dados-usuario"
          ref="dxFormInstance">
          <DxGroupItem caption="Dados do usuário" :col-count="2" cssClass="dados-usuario-class">
            <DxSimpleItem
              v-for="(field) in usuarioFields"
              :key="field.dataField"
              v-bind="field">
            </DxSimpleItem>
          </DxGroupItem>
          <DxGroupItem caption="Dados da empresa" :col-count="2" :visible="vuexWrapper && vuexWrapper.user.pessoa.tipoPessoa === 'J'" cssClass="dados-empresa-class">
            <DxSimpleItem
              v-for="(field) in pessoaFields"
              :key="field.dataField"
              v-bind="field">
            </DxSimpleItem>
          </DxGroupItem>

          <DxGroupItem caption=" " :col-count="1" cssClass="actions-container">
            <DxButtonItem
              :button-options="{
                text: 'Salvar Alterações',
                type: 'success',
                useSubmitBehavior: true,
                icon: 'mdi mdi-content-save',
                disabled: loading
              }"
              cssClass="salvar-usuario-icon"
              horizontal-alignment="center"
            />
          </DxGroupItem>
        </DxForm>
      </form>

      <!--  API TOKEN  -->
      <form @submit="btnSaveChave">
        <DxForm
          :form-data.sync="formData"
          :show-validation-summary="false"
          validationGroup="dados-api"
          ref="dxFormInstance">
          <DxGroupItem caption="Dados da API" name="token" :col-count="3" cssClass="dados-api-class" :visible="formData.pessoa.acessoApi !== 0">
            <DxSimpleItem
              v-for="(field) in chaveFields"
              :key="field.dataField"
              v-bind="field">
            </DxSimpleItem>

              <DxButtonItem
                :button-options="{
                text: 'Salvar Identificador',
                onClick: btnSaveIdentificadorChave,
                type: 'default',
                icon: 'mdi mdi-key',
                disabled: !formData?.chave?.clientId
              }"
                horizontal-alignment="center"
              />
              <DxButtonItem
                :button-options="{
                text: [ formData.chave && formData.chave?.clientKey ? 'Gerar novo token de acesso' : 'Gerar token de acesso' ],
                onClick: btnSaveChave,
                type: 'default',
                icon: 'mdi mdi-key',
                disabled: !formData.chave
              }"
                horizontal-alignment="center"
              />
              <DxButtonItem
                :button-options="{
                text: 'Apagar Token',
                onClick: btnDeleteChave,
                type: 'default',
                icon: 'mdi mdi-key',
                disabled: !formData?.chave?.clientKey
              }"
                horizontal-alignment="center"
              />

          </DxGroupItem>
        </DxForm>
      </form>
      <DxValidationSummary
        @itemClick="clickValidationError"
        validationGroup="dados-usuario"
        ref="validationSummaryInstance"
        @contentReady="summaryContentReady"
      />
      <DxValidationSummary
        @itemClick="clickValidationError"
        validationGroup="dados-api"
      />
    </div>
  </div>
</template>
<script setup>
import VuexWrapper from '@/helper/VuexWrapper'
import DxForm, { DxButtonItem, DxGroupItem, DxSimpleItem } from 'devextreme-vue/form'
import config from 'config'
import DxValidationSummary from 'devextreme-vue/validation-summary'
import { nextTick, onMounted, ref } from 'vue'
import { http } from '@/pluggables/http'
import notify from 'devextreme/ui/notify'
import { DxTooltip } from 'devextreme-vue/tooltip'
import { confirm } from 'devextreme/ui/dialog'

const dadosApiTooltip = ref()
const dadosUsuarioTooltip = ref()
const dadosEmpresaTooltip = ref()
const validationSummaryInstance = ref()
/**
 * Evento disparado assim que o contentReady do validatorSummary é disparado
 * é usado para a animação de "head-shake" do botão de cadastrar/editar
 */
const summaryContentReady = () => {
  const items = validationSummaryInstance.value.instance.option('items')
  if (items.length) {
    const enviarTransporteIcon = document.getElementsByClassName('salvar-usuario-icon')[0]
    enviarTransporteIcon.querySelector('.dx-button').style.backgroundColor = 'red'
    enviarTransporteIcon.querySelector('.dx-button').style.transition = 'all 0.5s'
    enviarTransporteIcon.querySelector('.dx-button').style.animation = 'headShake 0.5s'
    setTimeout(() => {
      document.getElementsByClassName('dx-validationsummary')[0].scrollIntoView({ behavior: 'smooth' })
      enviarTransporteIcon.querySelector('.dx-button').style.backgroundColor = ''
      enviarTransporteIcon.querySelector('.dx-button').style.animation = null
    }, 500)
  }
}

const clickValidationError = (data) => {
  const element = data.itemData.validator.$element()[0]
  element.classList.add('flash')
  setTimeout(() => {
    element.classList.remove('flash')
  }, 1200)
}

const vuexWrapper = ref()
const formData = ref({})
const dxFormInstance = ref()
const loading = ref(false)
const getChaveAtiva = async () => {
  const { data: { data } } = await http.get(`${config.baseUrl}/chave/`, {
    params: {
      where: {
        excluido: 'N'
      }
    }
  })
  const chave = data ? { ...data.rows[0] } : {}
  return chave
}

onMounted(async () => {
  const dadosChave = await getChaveAtiva()
  const dadosUsuario = {
    cpf: vuexWrapper.value.user.cpf,
    nome: vuexWrapper.value.user.nome,
    codIbge: vuexWrapper.value.user.codIbge,
    uf: vuexWrapper.value.user.municipio.uf,
    municipioNome: vuexWrapper.value.user.municipio.nome,
    celular: vuexWrapper.value.user.celular,
    email: vuexWrapper.value.user.email,
    telefone: vuexWrapper.value.user.telefone,
    cep: vuexWrapper.value.user.cep,
    bairro: vuexWrapper.value.user.bairro,
    endereco: vuexWrapper.value.user.endereco,
    complemento: vuexWrapper.value.user.complemento,
    numero: vuexWrapper.value.user.numero,
    senha: null,
    confirmarSenha: null
  }
  const dadosPessoa = {
    acessoApi: vuexWrapper.value.user.pessoa.acessoApi,
    cpfCnpj: vuexWrapper.value.user.pessoa.tipoPessoa === 'J' ? vuexWrapper.value.user.pessoa.cpfCnpj : vuexWrapper.value.user.cpf,
    nomeRazaoSocial: vuexWrapper.value.user.pessoa.tipoPessoa === 'J' ? vuexWrapper.value.user.pessoa.nomeRazaoSocial : vuexWrapper.value.user.nome,
    celular: vuexWrapper.value.user.pessoa.tipoPessoa === 'J' ? vuexWrapper.value.user.pessoa.celular : vuexWrapper.value.user.celular,
    telefone: vuexWrapper.value.user.pessoa.tipoPessoa === 'J' ? vuexWrapper.value.user.pessoa.telefone : vuexWrapper.value.user.telefone,
    email: vuexWrapper.value.user.pessoa.tipoPessoa === 'J' ? vuexWrapper.value.user.pessoa.email : vuexWrapper.value.user.email,
    cep: vuexWrapper.value.user.pessoa.tipoPessoa === 'J' ? vuexWrapper.value.user.pessoa.cep : vuexWrapper.value.user.cep,
    codIbge: vuexWrapper.value.user.pessoa.tipoPessoa === 'J' ? vuexWrapper.value.user.pessoa.codIbge : vuexWrapper.value.user.codIbge,
    uf: vuexWrapper.value.user.pessoa.tipoPessoa === 'J' ? vuexWrapper.value.user.pessoa.municipio.uf : vuexWrapper.value.user.municipio.uf,
    municipioNome: vuexWrapper.value.user.pessoa.tipoPessoa === 'J' ? vuexWrapper.value.user.pessoa.municipio.nome : vuexWrapper.value.user.municipio.nome,
    bairro: vuexWrapper.value.user.pessoa.tipoPessoa === 'J' ? vuexWrapper.value.user.pessoa.bairro : vuexWrapper.value.user.bairro,
    endereco: vuexWrapper.value.user.pessoa.tipoPessoa === 'J' ? vuexWrapper.value.user.pessoa.endereco : vuexWrapper.value.user.endereco,
    complemento: vuexWrapper.value.user.pessoa.tipoPessoa === 'J' ? vuexWrapper.value.user.pessoa.complemento : vuexWrapper.value.user.complemento,
    numero: vuexWrapper.value.user.pessoa.tipoPessoa === 'J' ? vuexWrapper.value.user.pessoa.numero : vuexWrapper.value.user.numero
  }

  formData.value = {
    ...dadosUsuario,
    pessoa: dadosPessoa,
    chave: dadosChave
  }

  /**
   *  Inputa ícone de usuário no título do grupo de dados de usuário, além disso também inputa ícone de interrogação
   *  embutido com um DxToolTip
   */
  await nextTick()
  const titleElement = document.getElementsByClassName('dados-usuario-class')[0].querySelector('.dx-form-group-caption')
  const questionMarkIcon = document.createElement('i')
  questionMarkIcon.id = 'dados-usuario-question-mark'
  questionMarkIcon.classList.add('mdi')
  questionMarkIcon.classList.add('mdi-help-circle-outline')
  questionMarkIcon.style.fontSize = '1rem'
  questionMarkIcon.style.marginLeft = '0.5rem'
  questionMarkIcon.addEventListener('mouseenter', () => {
    dadosUsuarioTooltip.value.instance.show()
    setTimeout(() => {
      if (dadosUsuarioTooltip.value) dadosUsuarioTooltip.value.instance.hide()
    }, 3000)
  })
  questionMarkIcon.addEventListener('mouseleave', () => {
    dadosUsuarioTooltip.value.instance.hide()
  })
  const personIcon = document.createElement('i')
  personIcon.classList.add('mdi')
  personIcon.classList.add('mdi-account-circle')
  personIcon.style.fontSize = '1.5rem'
  personIcon.style.marginRight = '0.5rem'
  titleElement.parentNode.insertBefore(personIcon, titleElement)
  titleElement.appendChild(questionMarkIcon)

  /**
   *  Inputa ícone de API, além disso também inputa ícone de interrogação embutido com um DxToolTip
   */
  const apiIcon = document.createElement('i')
  apiIcon.classList.add('mdi')
  apiIcon.classList.add('mdi-api')
  apiIcon.style.fontSize = '1.5rem'
  apiIcon.style.marginRight = '0.5rem'
  const titleApiElement = document.getElementsByClassName('dados-api-class')[0].querySelector('.dx-form-group-caption')
  titleApiElement.parentNode.insertBefore(apiIcon, titleApiElement)
  const questionMarkApiIcon = document.createElement('i')
  questionMarkApiIcon.id = 'dados-api-question-mark'
  questionMarkApiIcon.classList.add('mdi')
  questionMarkApiIcon.classList.add('mdi-help-circle-outline')
  questionMarkApiIcon.style.fontSize = '1rem'
  questionMarkApiIcon.style.marginLeft = '0.5rem'
  titleApiElement.appendChild(questionMarkApiIcon)
  questionMarkApiIcon.addEventListener('mouseenter', () => {
    dadosApiTooltip.value.instance.show()
    setTimeout(() => {
      if (dadosApiTooltip.value) dadosApiTooltip.value.instance.hide()
    }, 3000)
  })
  questionMarkApiIcon.addEventListener('mouseleave', () => {
    dadosApiTooltip.value.instance.hide()
  })

  /**
   *  Inputa ícone de empresa/usuario-empresa no título do grupo de dados da empresa, além disso também inputa ícone de interrogação
   *  embutido com um DxToolTip
   */
  if (vuexWrapper.value.user.pessoa.tipoPessoa === 'J') {
    const empresaIcon = document.createElement('i')
    empresaIcon.classList.add('mdi')
    empresaIcon.classList.add('mdi-account-box-multiple')
    empresaIcon.style.fontSize = '1.5rem'
    empresaIcon.style.marginRight = '0.5rem'
    const titleElement = document.getElementsByClassName('dados-empresa-class')[0].querySelector('.dx-form-group-caption')
    titleElement.parentNode.insertBefore(empresaIcon, titleElement)
    const questionMarkIcon = document.createElement('i')
    questionMarkIcon.id = 'dados-empresa-question-mark'
    questionMarkIcon.classList.add('mdi')
    questionMarkIcon.classList.add('mdi-help-circle-outline')
    questionMarkIcon.style.fontSize = '1rem'
    questionMarkIcon.style.marginLeft = '0.5rem'
    titleElement.appendChild(questionMarkIcon)
    questionMarkIcon.addEventListener('mouseenter', () => {
      dadosEmpresaTooltip.value.instance.show()
      setTimeout(() => {
        if (dadosEmpresaTooltip.value) dadosEmpresaTooltip.value.instance.hide()
      }, 3000)
    })
    questionMarkIcon.addEventListener('mouseleave', () => {
      dadosEmpresaTooltip.value.instance.hide()
    })
  }
})

const save = async (e) => {
  e.preventDefault()
  loading.value = true
  try {
    await http.post('/usuario-api/alterar-meus-dados', formData.value)
    const { pessoa, ...dadosUsuarios } = formData.value
    vuexWrapper.value.updateUser({ user: dadosUsuarios })
    vuexWrapper.value.updateUserPessoa({
      pessoa: {
        ...formData.value.pessoa,
        municipio: {
          codIbge: formData.value.pessoa.codIbge,
          nome: formData.value.pessoa.municipioNome,
          uf: formData.value.pessoa.uf
        }
      }
    })
    loading.value = false
    const router = await import('@/router')
    await router.default.go(-1)
  } catch (err) {
    loading.value = false
    console.error(err)
    notify('Não foi possivel salvar dados de usuário, por favor, contate um administrador do sistema')
  }
}

const usuarioFields = ref([
  {
    dataField: 'cpf',
    label: { text: 'CPF', location: 'top' },
    colSpan: 1,
    editorOptions: {
      mask: '000.000.000-00',
      maskInvalidMessage: 'CPF/CNPJ Incompleto',
      readOnly: true
    }
  },
  {
    dataField: 'nome',
    label: { text: 'Nome', location: 'top' },
    colSpan: 2,
    validationRules: [{ type: 'required', message: 'Nome não foi preenchido' }]
  },
  {
    dataField: 'celular',
    label: { text: 'Celular', location: 'top' },
    colSpan: 1,
    editorOptions: {
      mask: '(00) 00000-0000'
    },
    validationRules: [{ type: 'required', message: 'Celular não foi preenchido' }]
  },
  {
    dataField: 'telefone',
    label: { text: 'Telefone Comercial', location: 'top' },
    editorOptions: {
      mask: '(00) 90000-0000'
    },
    colSpan: 1,
    validationRules: [{ type: 'required', message: 'Telefone Comercial não foi preenchido' }]
  },
  {
    itemType: 'empty',
    colSpan: 1
  },
  {
    dataField: 'email',
    colSpan: 1,
    label: { text: 'E-mail', location: 'top' },
    dataType: 'string',
    validationRules: [{ type: 'required', message: 'E-mail não foi preenchido' }, { type: 'email' }]
  },
  {
    itemType: 'empty',
    colSpan: 2
  },
  {
    dataField: 'senha',
    colSpan: 1,
    label: { text: 'Senha', location: 'top' },
    dataType: 'string',
    editorOptions: {
      onFocusOut: () => {
        dxFormInstance.value.instance.validate()
      }
    }
  },
  {
    dataField: 'confirmarSenha',
    colSpan: 1,
    label: { text: 'Confirmar senha', location: 'top' },
    dataType: 'string',
    validationRules: [{
      type: 'compare',
      comparisonTarget: () => formData.value.senha,
      message: 'A senhas não coincidem'
    }]
  },
  {
    itemType: 'empty',
    colSpan: 1
  },
  {
    dataField: 'cep',
    colSpan: 1,
    label: { text: 'CEP', location: 'top' },
    dataType: 'string',
    editorOptions: {
      mask: '00.000-000',
      onFocusOut: async () => {
        loading.value = true
        try {
          const { data: { info: { endereco: address } } } = await http.post(`${config.baseUrl}/findAddress`, {
            cep: formData.value.cep
          })
          dxFormInstance.value.instance.updateData('uf', address.uf)
          dxFormInstance.value.instance.updateData('municipioNome', address.localidade)
          dxFormInstance.value.instance.updateData('bairro', address.bairro)
          dxFormInstance.value.instance.updateData('endereco', address.logradouro)
          dxFormInstance.value.instance.updateData('complemento', address.complemento)
          dxFormInstance.value.instance.updateData('codIbge', address.ibge)
          loading.value = false
        } catch (err) {
          dxFormInstance.value.instance.updateData('uf', null)
          dxFormInstance.value.instance.updateData('municipioNome', null)
          dxFormInstance.value.instance.updateData('bairro', null)
          dxFormInstance.value.instance.updateData('endereco', null)
          dxFormInstance.value.instance.updateData('complemento', null)
          dxFormInstance.value.instance.updateData('codIbge', null)
          loading.value = false
        }
      }
    },
    validationRules: [{ type: 'required', message: 'CEP não foi preenchido' }]
  },
  {
    dataField: 'uf',
    colSpan: 1,
    label: { text: 'UF', location: 'top' },
    dataType: 'string',
    editorOptions: {
      readOnly: true
    },
    validationRules: [{ type: 'required', message: 'UF não foi preenchido' }]
  },
  {
    dataField: 'municipioNome',
    colSpan: 1,
    label: { text: 'Município', location: 'top' },
    dataType: 'string',
    editorOptions: {
      readOnly: true
    },
    validationRules: [{ type: 'required', message: 'Município não foi preenchido' }]
  },
  {
    dataField: 'bairro',
    colSpan: 1,
    label: { text: 'Bairro', location: 'top' },
    dataType: 'string',
    validationRules: [{ type: 'required', message: 'Bairro não foi preenchido' }]
  },
  {
    dataField: 'endereco',
    colSpan: 1,
    label: { text: 'Endereço', location: 'top' },
    dataType: 'string',
    validationRules: [{ type: 'required', message: 'Endereço não foi preenchido' }]
  },
  {
    dataField: 'complemento',
    colSpan: 1,
    label: { text: 'Complemento', location: 'top' },
    dataType: 'string'
  },
  {
    dataField: 'numero',
    colSpan: 1,
    label: { text: 'Número', location: 'top' },
    editorType: 'dxTextBox',
    dataType: 'string',
    validationRules: [{ type: 'required', message: 'Número não foi preenchido' }]
  }
])

const pessoaFields = ref([
  {
    dataField: 'pessoa.cpfCnpj',
    label: { text: 'CNPJ', location: 'top' },
    colSpan: 1,
    editorOptions: {
      mask: '00.000.000/0000-00',
      maskInvalidMessage: 'CPF/CNPJ Incompleto',
      readOnly: true
    },
    validationRules: [{ type: 'required', message: 'CNPJ da empresa não foi preenchido' }]
  },
  {
    dataField: 'pessoa.nomeRazaoSocial',
    label: { text: 'Razão Social', location: 'top' },
    colSpan: 2,
    validationRules: [{ type: 'required', message: 'Nome razão social da empresa não foi preenchido' }]
  },
  {
    dataField: 'pessoa.celular',
    label: { text: 'Celular', location: 'top' },
    colSpan: 1,
    editorOptions: {
      mask: '(00) 00000-0000'
    }
  },
  {
    dataField: 'pessoa.telefone',
    label: { text: 'Telefone Comercial', location: 'top' },
    colSpan: 1,
    editorOptions: {
      mask: '(00) 90000-0000'
    }
  },
  {
    itemType: 'empty',
    colSpan: 1
  },
  {
    dataField: 'pessoa.email',
    colSpan: 1,
    label: { text: 'E-mail', location: 'top' },
    dataType: 'string',
    validationRules: [{ type: 'required', message: 'E-mail da empresa não foi preenchido' }, { type: 'email' }]
  },
  {
    itemType: 'empty',
    colSpan: 2
  },
  {
    dataField: 'pessoa.cep',
    colSpan: 1,
    label: { text: 'CEP', location: 'top' },
    dataType: 'string',
    editorOptions: {
      mask: '00.000-000',
      onFocusOut: async () => {
        loading.value = true
        try {
          const { data: { info: { endereco: address } } } = await http.post(`${config.baseUrl}/findAddress`, {
            cep: formData.value.pessoa.cep
          })
          dxFormInstance.value.instance.updateData('pessoa.uf', address.uf)
          dxFormInstance.value.instance.updateData('pessoa.municipioNome', address.localidade)
          dxFormInstance.value.instance.updateData('pessoa.bairro', address.bairro)
          dxFormInstance.value.instance.updateData('pessoa.endereco', address.logradouro)
          dxFormInstance.value.instance.updateData('pessoa.complemento', address.complemento)
          dxFormInstance.value.instance.updateData('pessoa.codIbge', address.ibge)
          loading.value = false
        } catch (err) {
          dxFormInstance.value.instance.updateData('pessoa.uf', null)
          dxFormInstance.value.instance.updateData('pessoa.municipioNome', null)
          dxFormInstance.value.instance.updateData('pessoa.bairro', null)
          dxFormInstance.value.instance.updateData('pessoa.endereco', null)
          dxFormInstance.value.instance.updateData('pessoa.complemento', null)
          dxFormInstance.value.instance.updateData('pessoa.codIbge', null)
          loading.value = false
        }
      }
    },
    validationRules: [{ type: 'required', message: 'CEP da empresa não foi preenchido' }]
  },
  {
    dataField: 'pessoa.uf',
    colSpan: 1,
    label: { text: 'UF', location: 'top' },
    dataType: 'string',
    editorOptions: {
      readOnly: true
    },
    validationRules: [{ type: 'required', message: 'UF da empresa não foi preenchido' }]
  },
  {
    dataField: 'pessoa.municipioNome',
    colSpan: 1,
    label: { text: 'Município', location: 'top' },
    dataType: 'string',
    editorOptions: {
      readOnly: true
    },
    validationRules: [{ type: 'required', message: 'Município da empresa não foi preenchido' }]
  },
  {
    dataField: 'pessoa.bairro',
    colSpan: 1,
    label: { text: 'Bairro', location: 'top' },
    dataType: 'string',
    validationRules: [{ type: 'required', message: 'Bairro da empresa não foi preenchido' }]
  },
  {
    dataField: 'pessoa.endereco',
    colSpan: 1,
    label: { text: 'Endereço', location: 'top' },
    dataType: 'string',
    validationRules: [{ type: 'required', message: 'Endereço da empresa não foi preenchido' }]
  },
  {
    dataField: 'pessoa.complemento',
    colSpan: 1,
    label: { text: 'Complemento', location: 'top' },
    dataType: 'string'
  },
  {
    dataField: 'pessoa.numero',
    colSpan: 1,
    label: { text: 'Número', location: 'top' },
    dataType: 'string',
    validationRules: [{ type: 'required', message: 'Número da empresa não foi preenchido' }]
  }
])

// API TOKEN START
const chaveFields = ref([
  {
    dataField: 'chave.clientId',
    colSpan: 1,
    name: 'client',
    label: { text: 'Identificador', location: 'top' },
    dataType: 'string',
    validationRules: [{ type: 'required', message: 'Identificador da Chave não foi preenchido' }],
    editorOptions: {
      maxLength: 12,
      placeholder: 'Título da Chave'
    }
  },
  {
    dataField: 'chave.clientKey',
    colSpan: 2,
    label: { text: 'Token', location: 'top' },
    dataType: 'string',
    editorOptions: {
      placeholder: 'Token da Chave',
      readOnly: true
    }
  }
])

const btnSaveChave = async () => {
  loading.value = true
  try {
    const formDataApi = dxFormInstance.value.instance?.validate()
    if (formDataApi?.isValid && formData.value.chave?.clientKey) {
      const result = await confirm('<i>ATENÇÃO. Ao gerar um novo token o anterior será invalidado. ' +
        'Qualquer tentativa de acesso à API de integração será bloqueada ao utilizar o antigo token. ' +
        'Tem certeza que você quer gerar um novo token de acesso?</i>', 'Confirmar')
      if (result) {
        await createChave()
      }
    } else if (formDataApi?.isValid) {
      await createChave()
    }
    loading.value = false
  } catch (err) {
    loading.value = false
    console.error(`ERRO: ${err}`)
  }
}

const createChave = async () => {
  try {
    const { data: { data } } = await http.post(`${config.baseUrl}/chave`, {
      clientId: formData.value.chave.clientId,
      clientKey: '',
      idPessoa: vuexWrapper.value.user.idPessoa,
      idUsuario: vuexWrapper.value.user.id,
      excluido: 'N'
    })
    if (data) {
      formData.value.chave = data
      notify('Token gerado com sucesso', 'success', 3000)
    }
  } catch (err) {
    notify('Erro ao gerar token', 'error', 3000)
    console.error(`ERRO: ${err}`)
  }
}

const btnSaveIdentificadorChave = async () => {
  try {
    dxFormInstance.value.instance.validate()
    const data = await http.put(`${config.baseUrl}/chave/${formData.value?.chave?.id}`, {
      clientId: formData.value.chave.clientId,
      clientKey: formData.value.chave.clientKey,
      idUsuario: vuexWrapper.value.user.id,
      idPessoa: vuexWrapper.value.user.idPessoa
    })
    if (data) {
      const getUpdateChave = await getChaveAtiva()
      formData.value.chave = getUpdateChave
      notify('Identificador alterado com sucesso', 'success', 3000)
    }
  } catch (e) {
    notify('Não foi possivel salvar o identificador da chave token, por favor, contate um administrador do sistema')
    console.error(`ERRO: ${e}`)
  }
}
const btnDeleteChave = async () => {
  try {
    if (formData.value.chave.clientKey) {
      const result = await confirm('<i>ATENÇÃO. Ao apagar o token qualquer tentativa de acesso à API de integração será bloqueada ao utilizar o antigo token. Tem certeza que você quer apagar o token de acesso?</i>', 'Confirmar')
      if (result) {
        const data = await http.put(`${config.baseUrl}/chave/${formData.value?.chave?.id}`, {
          excluido: 'S'
        })
        if (data) {
          formData.value.chave = {}
          notify('Token apagado com sucesso', 'success', 3000)
        }
      }
    }
  } catch (err) {
    notify('Não foi possivel deletar a sua chave token, por favor, contate um administrador do sistema')
    console.error(`ERRO: ${err}`)
  }
}
/* export default {
  components: {
    FormUsuario,
    DxButton
  },
  computed: {
    ...mapState('Login', ['user'])
  },
  methods: {
    ...mapMutations('Login', ['updateUser']),
    async salvarAlteracoes () {
      try {
        this.$refs.form.validarUsuario()
        const usuarioForm = this.$refs.form.getDadosFormulario()
        await this.$http.post('/usuario-api/alterar-meus-dados', {
          ...(usuarioForm.senha ? {
            senha: usuarioForm.senha,
            confirmarSenha: usuarioForm.confirmarSenha
          } : {}),
          cpf: usuarioForm.cpf,
          nome: usuarioForm.nome,
          codIbge: usuarioForm.codIbge,
          celular: usuarioForm.celular,
          email: usuarioForm.email,
          telefone: usuarioForm.telefone,
          cep: usuarioForm.cep,
          endereco: usuarioForm.endereco,
          bairro: usuarioForm.bairro,
          complemento: usuarioForm.complemento,
          numero: usuarioForm.numero
        })
        this.$utils.notifySuccess('Alterações feitas com sucesso')
        this.updateUser({ user: usuarioForm })
      } catch (error) {
        this.$utils.notifyError(error)
      }
    }
  }
} */
</script>
<style>
.salvar-usuario-icon:hover .dx-button-text {
  font-style: italic;
  transition: all 0.4s;
}

.salvar-usuario-icon .dx-button .dx-icon {
  transition: all 0.2s;
  position: relative;
  right: 100%;
}
.salvar-usuario-icon:hover .dx-button .dx-icon {
  transition: all 0.2s;
  position: relative;
  right: 0;
}
@keyframes headShake {
  0% { transform: translateX(0); }
  12.5% {transform: translateX(-6px) rotateY(-9deg) skewY(1deg);}
  37.5% {transform: translateX(5px) rotateY(4.5deg) skewY(-1deg);}
  62.5% { transform: translateX(-3px) rotateY(-2.25deg) skewY(0);}
  87.5% { transform: translateX(2px) rotateY(3deg);}
  100% {transform: translateX(0);}
}
.flash {
  border: solid red 1px !important;
  border-radius: 5px;
  transform: scale(1.01);
  transition: all 0.5s;
}
.editar-dados-container {
  padding-bottom: 10%
}
/* API TOKEN START */
.group-btn-key-class {
  display: flex;
  width: 20rem;
}
.dx-item-content dx-box-item-content .save-title{
border: solid 2px red;
  width: 1rem;
}
.btn-key-class .dx-button-content{
  width: 100%;
}
</style>
