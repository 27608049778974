import ListObra from './List/ListObra.vue'
import MaintainObra from './Maintain/MaintainObra'
import MapObras from './Map/MapObras'

export default [
  {
    path: '/obra',
    name: 'obra',
    component: ListObra,
    meta: {
      icon: 'mdi mdi-excavator',
      title: 'Manter Obra',
      requiresAuth: true,
      permissionType: 'consulta'
    }
  },
  {
    path: '/obra/add',
    name: 'obra-add',
    component: MaintainObra,
    meta: {
      requiresAuth: true,
      title: 'Manter Obra',
      permissionType: 'inclui'
    }
  },
  {
    path: '/obra/edit/:id',
    name: 'obra-edit',
    component: MaintainObra,
    meta: {
      requiresAuth: true,
      title: 'Manter Obra',
      permissionType: 'consulta'
    }
  },
  {
    path: '/obra/map',
    name: 'obra-map',
    component: MapObras,
    meta: {
      requiresAuth: true,
      title: 'Manter Obra',
      permissionType: 'consulta'
    }
  },
  {
    path: '/obra/cacamba-map',
    name: 'obra-cacamba-map',
    component: MapObras,
    meta: {
      requiresAuth: true,
      title: 'Retirar Caçamba', // @XXX: duplicado com a página de retirar caçamba
      permissionType: 'consulta'
    }
  }
]
