<template>
  <div class="state-tooltip">
    <div v-if="info.argument === 'Em andamento'">
      <p style="font-size: 24px; position: relative; top: -10px" class="mdi mdi-clipboard-edit-outline"></p>
      <p style="font-size: 12px">Situação da ocorrência: <b>{{ info.argumentText }}</b></p>
      <p style="font-size: 12px">Quantidade de ocorrências: <b>{{ info.point.data.quantidade }}</b></p>
    </div>

    <div v-if="info.argument === 'Respondida'">
      <p style="font-size: 24px; position: relative; top: -10px" class="mdi mdi-clipboard-check-outline"></p>
      <p style="font-size: 12px">Situação da ocorrência: <b>{{ info.argumentText }}</b></p>
      <p style="font-size: 12px">Quantidade de ocorrências: <b>{{ info.point.data.quantidade }}</b></p>
    </div>

    <div v-if="info.argument === 'Aguardando resposta'">
      <p style="font-size: 24px; position: relative; top: -10px" class="mdi mdi-clipboard-account-outline"></p>
      <p style="font-size: 12px">Situação da ocorrência: <b>{{ info.argumentText }}</b></p>
      <p style="font-size: 12px">Quantidade de ocorrências: <b>{{ info.point.data.quantidade }}</b></p>
    </div>

    <div v-if="info.argument === 'Caçamba'">
      <p style="font-size: 24px; position: relative; top: -10px" class="mdi mdi-truck"></p>
      <p style="font-size: 12px">Irregularidade com: <b>{{ info.argumentText }}</b></p>
      <p style="font-size: 12px">Quantidade de irregularidades: <b>{{ info.point.data.quantidade }}</b></p>
    </div>

    <div v-if="info.argument === 'Transporte'">
      <p style="font-size: 24px" class="mdi mdi-car-outline"></p>
      <p style="font-size: 12px">Irregularidade com: <b>{{ info.argumentText }}</b></p>
      <p style="font-size: 12px">Quantidade de irregularidades: <b>{{ info.point.data.quantidade }}</b></p>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
<style>
.state-tooltip {
  height: 90px;
}

.state-tooltip > img {
  width: 60px;
  height: 40px;
  display: block;
  margin: 0 5px 0 0;
  float: left;
}

.state-tooltip > h4 {
  line-height: 40px;
  font-size: 14px;
  margin-bottom: 5px;
}

.state-tooltip .caption {
  font-weight: 500;
}

.state-tooltip sup {
  font-size: 0.8em;
  vertical-align: super;
  line-height: 0;
}
</style>
