<template>
  <dx-popup
    :hide-on-outside-click="true"
    :drag-enabled="false"
    height="auto"
    :show-title="true"
    :title="title"
    :visible.sync="popupActive">
    <template v-slot:content>
      <div class="alterar-transportador-popup-content">
        <div>
          <div v-if="solicitacaoTransporte && solicitacaoTransporte.motivoRecSolicitacao">
            <h4>Motivo da Recusa: "{{ solicitacaoTransporte.motivoRecSolicitacao }}"</h4>
          </div>
          <span>Transportador</span>
          <dx-lookup
            v-bind="fieldsBind.transportador"
            v-model="formData.transportador"
            ref="transportadorLookup"
          />
        </div>

        <message-error :message="popupErrorMessage"/>
        <div class="alterar-transportador-popup-buttons">
          <dx-button
            type="success"
            @click="onButtonSaveClick">
            Salvar
          </dx-button>
          <dx-button type="normal" @click="popupActive = false">Cancelar</dx-button>
        </div>
      </div>
    </template>
  </dx-popup>
</template>
<script>
import { DxButton, DxPopup, DxLookup } from 'devextreme-vue'
import mixinPopup from './mixin-popup'
import CustomStore from 'devextreme/data/custom_store'
import mixinCrud from '@/pages/Cruds/mixin-crud'
import MessageError from '../MessageError'
import { alterarTransportador } from '@/components/SolicitacaoTransporte/service'

export default {
  name: 'popup-update-solicitacao',
  mixins: [
    mixinPopup,
    mixinCrud
  ],
  components: {
    DxButton,
    DxPopup,
    DxLookup,
    MessageError
  },
  props: {
    editable: {
      type: Boolean,
      default: () => false
    },
    /**
     * O titulo do popup.
     */
    title: {
      type: String,
      required: true
    },
    /**
     * A solicitacao que sera atualizada.
     */
    solicitacao: {
      type: Object
    }
  },
  /**
   * Metodo do vue, para obter o estado inicial do componente.
   * @returns {Object} O estado inicial do componente.
   */
  data () {
    return {
      popupErrorMessage: null,
      fieldsBind: {
        transportador: {
          dataSource: {
            store: new CustomStore({
              load: (loadOptions) => {
                return this.$utils.wrapRequestForGrid(this.find({
                  entity: 'ViewTransportador',
                  loadOptions,
                  params: {
                    fields: ['id', 'idPerfil', 'nomeRazaoSocial', 'situacaoValidacao'],
                    where: {
                      situacaoValidacao: 'A'
                    }
                  }
                }))
              },
              byKey: async key => {
                const res = await this.$utils.wrapRequestForGrid(this.find({
                  entity: 'ViewTransportador',
                  params: {
                    fields: ['id', 'idPerfil', 'nomeRazaoSocial', 'situacaoValidacao'],
                    where: { id: this.solicitacao.idTransportador }
                  }
                }))
                return res.totalCount > 0 ? res.data[0] : null
              }
            }),
            sort: [
              { selector: 'nomeRazaoSocial', asc: true }
            ],
            paginate: true,
            pageSize: 10
          },
          valueExpr: 'id',
          displayExpr: 'nomeRazaoSocial'
        }
      },
      formData: {
        transportador: null
      },
      solicitacaoTransporte: null
    }
  },
  watch: {
    /**
     * Metodo de callback de mudancas no valor da prop 'active', ativado de acordo com a visibilidade do popup
     * @param {boolean} active - O valor atual da prop.
     */
    async active (active) {
      this.formData.transportador = this.solicitacao.idTransportador
      this.solicitacaoTransporte = await this.findOne({
        entity: 'SolicitacaoTransporte',
        params: {
          where: {
            id: this.solicitacao.id
          },
          attributes: ['id', 'motivoRecSolicitacao']
        }
      })
      if (this.$refs.transportadorLookup && this.$refs.transportadorLookup.instance) {
        this.$refs.transportadorLookup.instance.close()
        this.$refs.transportadorLookup.instance.repaint()
      }
    }
  },
  methods: {
    async onButtonSaveClick () {
      this.popupErrorMessage = null
      if (!this.formData.transportador) {
        this.popupErrorMessage = 'Não foi selecionado um transportador.'
      } else {
        try {
          await alterarTransportador(
            {
              situacao: 'Solicitada',
              idTransportador: this.formData.transportador,
              motivoRecSolicitacao: null
            }, this.solicitacao.id)
          this.popupActive = false
          this.$emit('updated-solicitacao')
        } catch (err) {
          this.popupErrorMessage = 'Não foi alterar o transportador, por favor contate um administrador do sistema'
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import '../../global.scss';

.alterar-transportador-popup-content {
  display: flex;
  flex-direction: column;
  padding: 5px;

  > .alterar-transportador-popup-form {
    flex: 1;
  }

  > .alterar-transportador-popup-buttons {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 25px;
  }

  .error-message-card {
    background-color: #B00020 !important;
    color: white !important;
    width: 100%;
    margin-top: 6px;
    margin-bottom: 16px;
  }
}
</style>
