import PendenciaDocumentos from './PendenciaDocumentos.vue'

export default {
  path: '/pendencia-documentos',
  name: 'Documentos',
  component: PendenciaDocumentos,
  meta: {
    title: 'Documentos'
  },
  requiresAuth: true,
  props: true
}
