import AddSolicitacaoNaoSolicitada from './EditarSolicitacaoTransporte.vue'
export default [
  {
    path: '/editar-solicitacao-transporte/:id',
    name: 'editar-solicitacao-transporte',
    component: AddSolicitacaoNaoSolicitada,
    meta: {
      requiresAuth: true,
      title: 'Editar CTR',
      permissionType: 'altera'
    }
  }
]
