import SimpleGrid from './SimpleGrid.vue'
import SimpleForm from './SimpleForm.vue'
import Card from './Cards/Card.vue'

const components = {
  Card,
  SimpleGrid,
  SimpleForm
}

export default components

export {
  Card,
  SimpleGrid,
  SimpleForm
}
