import { render, staticRenderFns } from "./Mensagens.vue?vue&type=template&id=69bd19e2&scoped=true&"
import script from "./Mensagens.vue?vue&type=script&setup=true&lang=js&"
export * from "./Mensagens.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./Mensagens.vue?vue&type=style&index=0&id=69bd19e2&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69bd19e2",
  null
  
)

export default component.exports