<template>
  <div>
    <dx-popup
      ref="popupRef"
      :hide-on-outside-click="true"
      :drag-enabled="false"
      max-height="40vh"
      min-width="300px"
      width="80vw"
      v-bind="$attrs"
      :show-title="true"
      @hidden="$emit('closed')"
      title="Receber em Lote">
      <template v-slot:content>
        <dx-scroll-view use-native id="popup-lote-scroll-view">
          <div class="lote-popup-lookup-area">
            <span>Selecione o número dos CTRs que deverão ser recebidos:</span>
            <DxTagBox
              v-bind="tagBoxConfig"
              v-model="tagBoxConfig.value"
            />
            <DxTextArea
              placeholder="Observação"
              v-model="formData.observacao"
            />
          </div>
        </dx-scroll-view>
      </template>
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :disabled="popupConfig.saveButton.disabled"
        :options="popupConfig.saveButton"
      />
      <DxToolbarItem template="loadingTemplate" toolbar="bottom" location="center" cssClass="popup-lote-toolbar"/>
      <template #loadingTemplate>
        <div v-show="loading.show" style="width: 100%; padding-top: 10px">
          <md-progress-bar class="md-primary" md-mode="indeterminate"/>
        </div>
      </template>
    </dx-popup>
  </div>
</template>
<script setup>
import DxPopup, { DxToolbarItem } from 'devextreme-vue/popup'
import DxScrollView from 'devextreme-vue/scroll-view'
import { DxTagBox } from 'devextreme-vue'
import { ref, watch } from 'vue'
import { getSolicitacoesAvaliarRecebimento } from '@/pages/Cruds/AnalisarRecebimentoResiduo/service'
import { staticMethodsUtil } from '@/pluggables/utils'
import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import DxTextArea from 'devextreme-vue/text-area'
import { doRequest } from '@/pluggables/http'

/**
 * Events
 */
const emit = defineEmits(['closed', 'updated-solicitacao'])

/**
 * Variables
 */
const popupRef = ref()
const formData = ref({ observacao: null, avaliacao: true })
const loading = ref({ show: false })

/**
 * Everything related to the configuration of this current instance of DxPopup
 * @type {
 *  saveButton: {onClick: ((function(): Promise<void>)|*),
 *  color: string,
 *  width: number,
 *  disabled: boolean,
 *  text: string,
 *  type: string,
 *  stylingMode: string
 * }
 */
const popupConfig = ref({
  saveButton: {
    width: 300,
    disabled: true,
    text: 'Receber',
    color: 'primary',
    type: 'default',
    stylingMode: 'contained',
    onClick: async () => {
      loading.value.show = true
      popupConfig.value.saveButton.disabled = true
      const failedRequests = []
      for (const lote of tagBoxConfig.value.value) {
        try {
          await doRequest({
            method: 'put',
            url: `RecebimentoResiduo/${lote.id}`,
            params: {
              observacao: formData.value.observacao,
              avaliacao: formData.value.avaliacao
            }
          })
        } catch (e) {
          failedRequests.push(lote.id)
          console.error(e)
        }
      }
      loading.value.show = false
      popupConfig.value.saveButton.disabled = false
      popupRef.value.instance.hide()
      emit('finished', failedRequests)
    }
  }
})

/**
 * Everything related to the configuration of the TagBox component
 * @type {
 *  value: any[],
 *  dataSource: DataSource<any, TKey>,
 *  deferRendering: boolean,
 *  selected: null,
 *  searchEnabled: boolean,
 *  displayExpr: string
 *  }
 *
 */
const tagBoxConfig = ref({
  value: [],
  deferRendering: false,
  displayExpr: 'id',
  selected: null,
  searchEnabled: true,
  dataSource: new DataSource({
    pageSize: 10,
    store: new CustomStore({
      key: 'id',
      load: async loadOptions => {
        const filter = {
          situacao: {
            or: [{ eq: 'Retirada' }, { eq: 'Cancelamento Recusado' }, { eq: 'Em Pesagem' }]
          }
        }
        if (loadOptions.searchOperation) {
          loadOptions.searchOperation = '='
        }
        if (loadOptions.filter) {
          loadOptions.filter = [loadOptions.filter, 'and', [
            'situacao',
            'or',
            filter.situacao.or
          ]]
        } else {
          loadOptions.filter = [
            'situacao',
            'or',
            filter.situacao.or
          ]
        }
        const result = await staticMethodsUtil.wrapRequestForGrid(getSolicitacoesAvaliarRecebimento(loadOptions))
        if (result && result.data && Array.isArray(result.data)) {
          result.data.forEach((item) => {
            item.text = `CTR: ${item.id}`
          })
        }
        return result
      }
    })
  })
})

/**
 * Watchers
 */
watch(() => tagBoxConfig.value.value, (tagBoxValue) => {
  popupConfig.value.saveButton.disabled = !(tagBoxValue.length)
})

</script>

<style lang="scss">
.dx-toolbar-center:has(> .popup-lote-toolbar) {
  display: flex !important;
  flex-direction: column !important;
}

.lote-popup-lookup-area {
  display: flex;
  flex-direction: column;
  gap: 15px;
  span {
    margin: 5px;
    font-size: 1.2em;
  }
}
</style>
