import { doRequest, http } from '@/pluggables/http'
import { getParamsFromLoadOptions } from '@/util/loadOptionsParams'
import storeHelper from '@/helper/storeHelper'
import config from 'config'

/**
 * Metodo de comando, com o objetivo de realizar a requisica de obter uma lista
 * de obras com a lista de pessoas relacionadas a elas.
 * @param {Object} params - Os parametros da requisicao.
 * @param {Object} [params.where={}] - Os parametros de where da busca.
 * @param {Object} [params.loadOptions=null] - Os parametros de busca do
 * datasource do devextreme.
 * @param {string} params.displayField - O campo que vai ser usado para
 * apresentar os valores.
 * @returns {Object} O resultado da requisicao.
 */
export async function getPessoaGeradorObra ({ where = {}, loadOptions = null, displayField }) {
  const params = loadOptions !== null ? getParamsFromLoadOptions(loadOptions) : {}
  params.where = {
    ...params.where,
    ...where,
    '$perfis->obras->solicitacaoTransporte.situacao$': 'Solicitada'
  }
  params.include = JSON.stringify({
    model: 'Perfil',
    as: 'perfis',
    attributes: [],
    subQuery: false,
    include: {
      model: 'Obra',
      as: 'obras',
      attributes: [],
      include: {
        model: 'SolicitacaoTransporte',
        as: 'solicitacaoTransporte',
        attributes: []
      }
    }
  })
  params.fields = ['id', displayField]

  const res = await doRequest({
    method: 'post',
    url: 'Pessoa/find',
    params
  })

  if (!res.success) {
    throw new Error(res.error)
  }

  return { data: res.data.rows, totalCount: res.data.count }
}

/**
 * Metodo de comando, com o objetivo de realizar a requisica de obter uma lista
 * de obras.
 * @param {Object} params - Os parametros da requisicao.
 * @param {Object} [params.where={}] - Os parametros de where da busca.
 * @param {Object} [params.loadOptions=null] - Os parametros de busca do
 * datasource do devextreme.
 * @param {string[]} params.displayFields - O campso que vao ser usados para
 * apresentar os valores.
 * @returns {Object} O resultado da requisicao.
 */
export async function getObras ({ where = {}, loadOptions = null, displayFields }) {
  const params = loadOptions !== null ? getParamsFromLoadOptions(loadOptions) : {}
  params.where = {
    ...params.where,
    ...where,
    '$solicitacaoTransporte.situacao$': 'Solicitada'
  }
  params.include = JSON.stringify({
    model: 'SolicitacaoTransporte',
    as: 'solicitacaoTransporte',
    attributes: []
  })
  params.fields = ['id', ...displayFields]

  const res = await doRequest({
    method: 'post',
    url: 'Obra/find',
    params: {
      ...params,
      subQuery: false
    }
  })

  if (!res.success) {
    throw new Error(res.error)
  }

  return { data: res.data.rows, totalCount: res.data.count }
}

/**
 * Metodo de comando, com o objetivo de realizar a requisica de obter uma lista
 * de obras.
 * @param {Object} params - Os parametros da requisicao.
 * @param {Object} [params.where={}] - Os parametros de where da busca.
 * @param {Object} [params.loadOptions=null] - Os parametros de busca do
 * datasource do devextreme.
 * @param {string[]} params.displayFields - Os campos que vao ser usados para
 * apresentar os valores.
 * @returns {Object} O resultado da requisicao.
 */
export async function getNomeObras ({ loadOptions = null, loadOnlyCurrentTransportador = true }) {
  const params = getParamsFromLoadOptions(loadOptions)
  const baseUrl = config.baseUrl

  if (loadOnlyCurrentTransportador) {
    params.attributes = [{ fn: 'DISTINCT', col: 'nomeObra', as: 'nomeObra' }]
    params.order = JSON.stringify([['nomeObra', 'asc']])
    params.subQuery = false
    params.where = {
      ...params.where,
      situacao: ['Alocada', 'Enviada', 'Resíduo Rejeitado', 'Cancelamento Recusado', 'Cancelamento Solicitado', 'Solicitada']
    }
  }
  const res = await http.post(`${baseUrl}/ViewSolicitacaoTransporte/find`, params)

  if (!res.data.success) {
    throw new Error(res.error)
  }
  return { data: res.data.data.rows, totalCount: res.data.data.count }
}

/**
 * Metodo de comando, com o objetivo de realizar a requisica de obter uma lista
 * de tipos de residuos.
 * @param {Object} params - Os parametros da requisicao.
 * @param {Object} [params.where={}] - Os parametros de where da busca.
 * @param {Object} [params.loadOptions=null] - Os parametros de busca do
 * datasource do devextreme.
 * @returns {Object} O resultado da requisicao.
 */
export async function getResiduosPredominantes ({ where = {}, loadOptions = null }) {
  const params = loadOptions !== null ? getParamsFromLoadOptions(loadOptions) : {}
  params.where = {
    ...params.where,
    ...where,
    '$solicitacoesTransporte.situacao$': 'Solicitada'
  }
  params.include = JSON.stringify([
    {
      model: 'ClasseMaterial',
      as: 'classeMaterial',
      attributes: ['id', 'classe']
    },
    {
      model: 'SolicitacaoTransporte',
      as: 'solicitacoesTransporte',
      attributes: ['id', 'idResiduoPredominante', 'situacao']
    }
  ])
  params.fields = ['id', 'nome']

  const res = await doRequest({
    method: 'post',
    url: 'TipoResiduo/find',
    params: {
      ...params,
      subQuery: false
    }
  })

  if (!res.success) {
    throw new Error(res.error)
  }

  return { data: res.data.rows, totalCount: res.data.count }
}

/**
 * Metodo de comando, com o objetivo de realizar a requisica de obter uma lista
 * de solicitações de transporte com a situacao 'Solicitada'.
 * @param {Object} loadOptions - Os parametros de busca do
 * datasource do devextreme.
 * @param {Boolean} [loadOnlyCurrentTransportador] Flag que estabelece se apenas as solicitações do transportador logado devem retornar
 * @returns {Object} O resultado da requisicao.
 */
export async function getSolicitacoesTransporteSolicitados (loadOptions, loadOnlyCurrentTransportador = true) {
  const params = getParamsFromLoadOptions(loadOptions)

  if ('$obra->gerador->pessoa.telefone$' in params.where) {
    const telefoneParam = params.where['$obra->gerador->pessoa.telefone$']
    Object.keys(telefoneParam).forEach(key => {
      telefoneParam[key] = String(telefoneParam[key])
    })
  }

  if (loadOnlyCurrentTransportador) {
    const transportadores = storeHelper.getCurrentPerfisTransportador()

    params.where = {
      ...params.where,
      idTransportador: (transportadores[0] || {}).id
    }
  }

  const res = await doRequest({
    method: 'post',
    url: 'SolicitacaoTransporte/find-solicitadas',
    params
  })

  if (!(params.format === 'xlsx')) {
    if (!res.success) {
      throw new Error(res.error)
    }

    return { data: res.data.rows, totalCount: res.data.count }
  }
}

/**
 * Metodo de comando, com o objetivo de realizar a requisica de obter uma lista
 * de cacambas disponiveis.
 * @param {Object} params - Os parametros da requisicao.
 * @param {Object} [params.where={}] - Os parametros de where da busca.
 * @param {Object} [params.loadOptions=null] - Os parametros de busca do
 * datasource do devextreme.
 * @param {Boolean=} [params.whereSituacao=null] - parâmetro adicional usado no where de situação
 * @returns {Object} O resultado da requisicao.
 */
export async function getAvailableCacambas ({ where = {}, loadOptions = null, whereSituacao = true }) {
  const params = loadOptions !== null ? getParamsFromLoadOptions(loadOptions) : {}
  const defaultWhere = {
    or: [
      { '$solicitacoesTransporte.id_cacamba$': null },
      {
        '$solicitacoesTransporte.situacao$': whereSituacao ? {
          in: ['Destinada', 'Recusada', 'Cancelada', 'Resíduo Rejeitado']
        } : undefined
      }
    ]
  }
  params.subQuery = false
  params.fields = ['id', 'numeroIdentificacao']
  params.where = {
    and: [
      { ...params.where, id: undefined },
      defaultWhere
    ]
  }
  params.include = [
    {
      model: 'SolicitacaoTransporte',
      as: 'solicitacoesTransporte',
      attributes: [],
      required: false
    },
    {
      model: 'Perfil',
      as: 'perfil',
      attributes: [],
      required: true
    }
  ]
  const res = await doRequest({
    method: 'post',
    url: 'Cacamba/find',
    params
  })

  if (!res.success) {
    throw new Error(res.error)
  }

  return { data: res.data.rows, totalCount: res.data.count }
}

/**
 * Metodo de comando, com o objetivo de realizar a requisica de obter uma lista
 * de veiculos disponiveis.
 * @param {Object} params - Os parametros da requisicao.
 * @param {Object} [params.where={}] - Os parametros de where da busca.
 * @param {Object} [params.loadOptions=null] - Os parametros de busca do
 * datasource do devextreme.
 * @param {Boolean=} [params.whereSituacao=null] - parâmetro adicional usado no where de situação
 * @returns {Object} O resultado da requisicao.
 */
export async function getAvailableVeiculos ({ where = {}, loadOptions = null, whereSituacao = true }) {
  const params = loadOptions !== null ? getParamsFromLoadOptions(loadOptions) : {}
  const defaultWhere = {
    or: [
      { '$solicitacoesTransporte.id_veiculo$': null },
      {
        '$solicitacoesTransporte.situacao$': whereSituacao ? {
          in: ['Destinada', 'Recusada', 'Cancelada', 'Resíduo Rejeitado']
        } : undefined
      }
    ]
  }
  params.subQuery = false
  params.where = {
    and: [
      { ...params.where, id: undefined },
      defaultWhere
    ]
  }
  params.include = [
    {
      model: 'SolicitacaoTransporte',
      as: 'solicitacoesTransporte',
      required: false
    },
    {
      model: 'Perfil',
      as: 'perfil',
      attributes: [],
      required: true
    }
  ]
  params.fields = ['id', 'placa', 'tipo', 'poliguindaste']
  const res = await doRequest({
    method: 'post',
    url: 'Veiculo/find',
    params
  })

  if (!res.success) {
    throw new Error(res.error)
  }

  return { data: res.data.rows, totalCount: res.data.count }
}

/**
 * Metodo de comando, com o objetivo de realizar a requisica de salvar a
 * avaliacao de uma solicitacao de transporte.
 * @param {Object} params - Os parametros da requisicao.
 * @returns {Object} O resultado da requisicao.
 */
export function saveAvaliacaoSolicitacoesTransporte (params) {
  return doRequest({
    method: 'put',
    url: `avaliar_solicitacao/${params.id}`,
    params
  })
}
