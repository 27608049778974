import { http } from '@/pluggables/http'
import Swal from 'sweetalert2'
import config from 'config'
import _ from 'lodash'

import { parseFilterParams, parseSortParams } from '../../util/dxDatasourceHelper'

const baseUrl = config.baseUrl

export default {
  async findAddress ({ commit }, params) {
    const { updateState = true } = (params || {})

    let result
    if (params.cep) {
      result = await http.post(`${baseUrl}/findAddress`, {
        cep: params.cep
      })
    }
    if (params.address) {
      result = await http.post(`${baseUrl}/findAddress`, {
        address: params.address
      })
    }

    if (updateState) {
      commit('set_request_address', { response: result })
    }

    return (result || {}).data || null
  },
  async calculateCTRCanceladas ({ commit }, params) {
    let result
    if (params.startDate && params.endDate && params.type) {
      result = await http.post(`${baseUrl}/cancelamento-ctr/calculateCTR`, {
        startDate: params.startDate,
        endDate: params.endDate,
        type: params.type,
        solicitante: params.solicitante
      })
    }

    return result
  },
  setLoading ({ commit }, isLoading) {
    commit('set_loading', isLoading)
  },
  async find ({ commit }, { entity, params, loadOptions, rowsOnly, customSearchFilter }) {
    if (customSearchFilter) {
      loadOptions.filter = loadOptions.columnNames
    }
    if (_.isEmpty(params)) {
      params = {}
    }

    if (!_.isEmpty(loadOptions) && loadOptions !== null) {
      params.limit = loadOptions.take ? loadOptions.take : 10
      params.offset = loadOptions.skip ? loadOptions.skip : 0

      if (params.attributes && Array.isArray(params.attributes) && !params.order) {
        params.attributes.forEach(a => {
          if (a.fn && a.fn === 'DISTINCT' && a.col) {
            params.order = JSON.stringify([[a.col, 'asc']])
          }
        })
      }
      if (loadOptions.sort) {
        params.order = params.order ? [
          params.order,
          parseSortParams(loadOptions.sort)
        ] : parseSortParams(loadOptions.sort)

        if (params.order === '[]') {
          delete params.order
        }
      }

      if (loadOptions.searchOperation && !_.isEmpty(loadOptions.searchExpr) && !_.isEmpty(loadOptions.searchValue)) {
        const { filterParams, subQuery } = parseFilterParams([loadOptions.searchExpr, loadOptions.searchOperation, loadOptions.searchValue])
        params.where = JSON.stringify(params.where ? {
          ...params.where,
          ...filterParams
          // and: [
          //   params.where,
          //   filterParams
          // ]
        } : filterParams)
        params.subQuery = typeof params.subQuery === 'boolean' ? params.subQuery : subQuery
      } else if (loadOptions.filter) {
        const { filterParams, subQuery } = parseFilterParams(loadOptions.filter)
        params.where = params.where ? {
          ...params.where,
          ...filterParams
          // and: [
          //   params.where,
          //   parseFilterParams(loadOptions.filter)
          // ]
        } : filterParams
        params.subQuery = typeof params.subQuery === 'boolean' ? params.subQuery : subQuery
      }
    }

    if (loadOptions?.customQueryParams) {
      params = { ...params, ...loadOptions?.customQueryParams }
    }

    const result = await http.post(`${baseUrl}/${entity}/find`, {
      ..._.cloneDeep(params)
    }).catch((error) => {
      commit('set_loading', false)
      throw new Error(error.response.data.message)
    })
    commit('set_loading', false)

    if (!result.data.success) {
      return false
    }

    commit('set_data', { entity: entity, data: result.data.data.rows })

    if (rowsOnly) {
      return result.data.data.rows
    } else {
      return { data: result.data.data.rows, totalCount: result.data.data.count }
    }
  },
  async findOne ({ commit }, { entity, params }) {
    if (_.isEmpty(params)) {
      params = {}
    }

    let row = null

    try {
      const { data } = await http.post(`${baseUrl}/${entity}/find`, params)
      row = data.data?.rows[0] || null
      commit('set_data', { entity, data: row ? [row] : [] })
    } catch (error) {
      throw new Error(error.response.data.message)
    } finally {
      commit('set_loading', false)
    }

    return row
  },
  async findId ({ commit }, { entity, id, params }) {
    if (_.isEmpty(params)) {
      params = {}
    }

    params.where = {
      id
    }

    const result = await http.post(`${baseUrl}/${entity}/find`, {
      ..._.cloneDeep(params)
    }).then(res => {
      commit('set_data', { entity: entity, data: [res.data.data] })
      return res
    }).catch((error) => {
      commit('set_loading', false)
      throw new Error(error.response.data.message)
    })
    commit('set_loading', false)

    if (!result.data.success) {
      return false
    }

    return _.get(result, 'data.data.rows[0]', {})
  },
  async insertRow ({ commit, state }, { entity, values }) {
    commit('set_loading', true)
    const val = (JSON.stringify(values))
    const value = JSON.parse(val)
    const params = {
      ...value
    }
    const res = await http.post(`${baseUrl}/${entity}`, {
      ...params
    }).catch((error) => {
      commit('set_loading', false)
      throw new Error(error.response.data.message)
    })
    commit('set_loading', false)

    if (!_.has(res, 'data.data.id') || !res.data.success) {
      return false
    } else {
      commit('set_data', { entity: entity, data: res.data.data })
    }

    return res
  },
  async removeRow ({ commit, state }, { entity, key }) {
    commit('set_loading', true)
    const params = {
      id: key
    }
    const res = await http.delete(`${baseUrl}/${entity}/${key}`, {
      ...params
    }).catch((error) => {
      commit('set_loading', false)
      throw new Error(error.response.data.message)
    })
    commit('set_loading', false)

    if (!res.data.success || res.data.data !== 1) {
      Swal.fire({
        position: 'bottom-end',
        icon: 'error',
        title: 'Erro ao excluir registro',
        text: 'Este item pode estar vinculado à outro registro.',
        showConfirmButton: false,
        timer: 5000
      })

      return false
    }

    const current = (state.data[entity] || []).filter(v => {
      if (v.id !== key) {
        return v
      }
    })

    commit('set_data', { entity: entity, data: current })
    return res
  },
  async updateRow ({ commit, state }, { entity, values, key }) {
    commit('set_loading', true)
    const current = (state.data[entity] || []).filter(v => {
      if (v.id === key) {
        return v
      }
    })

    const val = (JSON.stringify({ ...(current[0] || {}), ...values }))
    const value = JSON.parse(val)

    const params = {
      id: key,
      ...value
    }
    try {
      const res = await http.put(`${baseUrl}/${entity}/${key}`, {
        ...params
      })

      if (!res.data.success) {
        return false
      }

      const curr = (state.data[entity] || []).filter(v => {
        if (v.id !== key) {
          return v
        }
      })

      curr.push(res.data.data)

      commit('set_data', { entity: entity, data: curr })
      return res
    } catch (error) {
      console.error(error)
      throw new Error(error.response.data.message)
    } finally {
      commit('set_loading', false)
    }
  },
  async findAtt ({ commit }, params) {
    const result = await http.post(`${baseUrl}/findATT`,
      params
    )
    commit('set_request_att', { response: result })
    return result.data
  },
  async findCTR ({ commit }, params) {
    const result = await http.post(`${baseUrl}/findCTR`,
      params
    )
    commit('set_request_ctr', { response: result })
    return result.data
  },
  async findMoveResiduo ({ commit }, params) {
    const result = await http.post(`${baseUrl}/findMoveResiduo`,
      params
    )
    commit('set_request_move_residuo', { response: result })
    return result.data
  },
  async findVeiculoCacamba ({ commit }, params) {
    const result = await http.post(`${baseUrl}/findVeiculoCacamba`,
      params
    )
    commit('set_request_veiculo_cacamba', { response: result })
    return result.data
  },
  async findResiduoRegiao ({ commit }, params) {
    const result = await http.post(`${baseUrl}/findResiduoRegiao`,
      params
    )
    commit('set_request_residuo_regiao', { response: result })
    return result.data
  },
  async chartResiduoRegiao ({ commit }, params) {
    let result
    if (params.startDate && params.endDate && params.type) {
      result = await http.post(`${baseUrl}/find-residuo-regiao/chart`, {
        startDate: params.startDate,
        endDate: params.endDate,
        type: params.type,
        ra: params.ra
      })
    }
    return result
  }
}
