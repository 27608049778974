<template>
  <div class="dx-field width-100">
    <div v-if="label" class="dx-field-label-location-top">
      <span class="dx-field-item-label-text">{{ label }}</span>
      <span v-if="required" class="dx-field-item-required-mark">*</span>
    </div>
    <div class="dx-field-content">
      <dx-select-box
        v-bind="options"
        :selected-item.sync="innerValue"
        ref="selectBox"
        :value="innerValue"/>
    </div>
  </div>
</template>

<script>
import DxSelectBox from 'devextreme-vue/select-box'

import GenericField from './GenericField'

export default {
  name: 'select-field',
  components: {
    DxSelectBox
  },
  extends: GenericField
}
</script>

<style lang="scss">
.dx-field {
  font-family: Montserrat, sans-serif;
}
</style>
