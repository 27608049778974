import ConsultaATT from './ConsultaVeiculoCacamba.vue'

export default {
  path: '/consulta-veiculo-cacamba',
  name: 'Consultar Veículos e Caçambas do Transportador',
  component: ConsultaATT,
  meta: {
    icon: 'mdi mdi-truck-trailer',
    title: 'Consultar Veículo e Caçambas do Transportador',
    requiresAuth: true,
    permissionType: 'consulta'
  },
  children: [],
  props: true
}
