import Cacamba from './Cacamba.vue'

export default {
  path: '/cacamba',
  name: 'cacamba',
  component: Cacamba,
  meta: {
    icon: 'mdi mdi-bucket',
    title: 'Manter Caçamba',
    requiresAuth: true,
    permissionType: 'consulta'
  },
  children: [
    {
      path: '/cacamba/:action',
      name: 'cacamba-add',
      component: Cacamba,
      meta: {
        title: 'Manter Caçamba',
        requiresAuth: true,
        permissionType: 'inclui'
      },
      props: true
    },
    {
      path: '/cacamba/:action/:id',
      name: 'cacamba-edit',
      component: Cacamba,
      meta: {
        title: 'Manter Caçamba',
        requiresAuth: true,
        permissionType: 'consulta'
      },
      props: true
    }
  ],
  props: true
}
