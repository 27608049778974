<template>
  <div class="generic-crud">
    <div class="generic-crud-form">
      <fieldset>
        <legend>Alocar Caçamba</legend>
        <div v-if="formData">
          <div class="detalhes-veiculo">
            <div>
              <div>Nº Do CTR</div>
              <dx-text-box :disabled="true" :value="''+formData.id"/>
            </div>
            <div>
              <div>Situação do CTR</div>
              <dx-text-box :disabled="true" :value="formData.situacao"/>
            </div>

            <div class="detalhes-veiculo">
              <h3>Veículo</h3>
              <div class="detalhes-veiculo">
                <div v-if="formData && formData.veiculo">
                  <span>Veículo (Placa)</span>
                  <dx-lookup
                    v-bind="fieldsBind.veiculo"
                    v-model="formData.veiculo"
                    ref="veiculoLookup"
                  />
                </div>
                <div v-if="formData.veiculo && ['Poliguindaste'].includes(formData.veiculo.tipo)">
                  <span>Caçamba(Nº de Identificação)</span>
                  <dx-lookup
                    v-bind="fieldsBind.cacamba"
                    v-model="formData.cacamba"
                    ref="cacambaLookup"
                  />
                </div>
                <div v-if="formData.veiculo">
                  <div>
                    Tipo do Veículo
                  </div>
                  <dx-text-box :disabled="true" :value="formData.veiculo.tipo"/>
                </div>
                <div v-if="formData.veiculo && ['Poliguindaste'].includes(formData.veiculo.tipo)">
                  <div>Poliguindaste</div>
                  <dx-select-box
                    :disabled="true"
                    v-model="formData.veiculo.poliguindaste"
                    :items="['Simples', 'Duplo', 'Triplo']"
                    placeholder="..."
                  />
                </div>
              </div>
            </div>

          </div>
          <div class="detalhes-veiculo">
            <div v-if="formData.obra">
              <div>
                Endereço da Obra:
              </div>
              <div style="color: #777;">
                <b>Endereço: </b> {{formData.obra.endereco}} {{formData.obra.complemento}}, {{formData.obra.numero}} <br>
                <b>Bairro: </b> {{formData.obra.bairro}} <br>
                <b>CEP: </b> {{$utils.formatarCep(formData.obra.cep)}}
              </div>
            </div>
          </div>
          <dx-button
            v-if="canEdit"
            type="success"
            @click="salvar">
            Salvar
          </dx-button>
        </div>
      </fieldset>
    </div>
  </div>
</template>
<style lang="scss">
  .detalhes-veiculo {
    width: 100%;
    display: inline-block;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 10px;
    color: #333;

    > * {
      margin: 10px 20px 0 0;
    }
  }
</style>

<script>
import { DxButton, DxSelectBox, DxTextBox, DxLookup } from 'devextreme-vue'
import notify from 'devextreme/ui/notify'

import { hasPermission } from '../../../util/permissions'
import alocarCacambaMixin from './aloca-cacamba-mixin'
import mixinCrud from '../mixin-crud'
import { doRequest } from '@/pluggables/http'
import CustomStore from 'devextreme/data/custom_store'
import { getAvailableCacambas, getAvailableVeiculos, getCacamba } from '@/components/SolicitacaoTransporte/service'

export default {
  mixins: [
    alocarCacambaMixin,
    mixinCrud
  ],
  components: {
    DxSelectBox,
    DxTextBox,
    DxButton,
    DxLookup
  },
  data () {
    return {
      formDataSetted: false,
      fieldsBind: {
        cacamba: {
          allowClearing: true,
          showClearButton: true,
          showCancelButton: false,
          dropDownOptions: {
            closeOnOutsideClick: true
          },
          dataSource: {
            store: new CustomStore({
              key: 'id',
              load: async loadOptions => {
                const cacambas = await this.$utils.wrapRequestForGrid(getAvailableCacambas({}, { ...loadOptions, paginate: false }))
                if (this.formData.cacamba) {
                  if (!cacambas.data.find(cacamba => cacamba.id === this.formData.cacamba.id)) cacambas.data.unshift(this.formData.cacamba)
                }
                return { data: cacambas.data, totalCount: cacambas.totalCount }
              },
              byKey: async key => {
                const res = await this.$utils.wrapRequestForGrid(getCacamba(key))
                return res.data
              }
            }),
            paginate: false
          },
          displayExpr: 'numeroIdentificacao',
          noDataText: 'Não existem caçambas disponíveis nesse momento.'
        },
        veiculo: {
          allowClearing: true,
          showClearButton: true,
          showCancelButton: false,
          dataSource: {
            store: new CustomStore({
              key: 'id',
              load: async loadOptions => {
                const veiculos = await this.$utils.wrapRequestForGrid(getAvailableVeiculos({}, { ...loadOptions, paginate: false }))
                if (!veiculos.data.find(veiculo => veiculo.id === this.formData.veiculo.id)) veiculos.data.unshift(this.formData.veiculo)
                return { data: veiculos.data, totalCount: veiculos.totalCount }
              },
              byKey: async key => {
                const res = await this.$utils.wrapRequestForGrid(getAvailableVeiculos({ where: { id: key } }))
                if (res.data.length) {
                  return res.data
                } else {
                  return this.formData.veiculo
                }
              }
            }),
            paginate: false
          },
          displayExpr: 'placa',
          noDataText: 'Não existem veículos disponíveis nesse momento.'
        },
        tipo: {
          readOnly: true
        },
        poliguindaste: {
          readOnly: true
        }
      }
    }
  },
  computed: {
    /**
     * Valor computado de se o usuario logado tem permissao de edicao.
     * @returns {boolean}
     */
    canEdit () {
      return hasPermission('Alocar Caçamba', 'altera')
    },

    /**
     * Calor computado das opcoes do campo de cacamba.
     * @returns {Object}
     */
    cacambaOptions () {
      return {
        disabled: this.disableField('cacamba'),
        readOnly: !this.canEdit
      }
    },

    /**
     * Calor computado das opcoes do campo de veiculo.
     * @returns {Object}
     */
    veiculoOptions () {
      return {
        disabled: this.disableField('veiculo'),
        readOnly: !this.canEdit
      }
    },

    /**
     * Calor computado das opcoes do campo de obra.
     * @returns {Object}
     */
    obraOptions () {
      return {
        disabled: this.disableField('obra'),
        readOnly: !this.canEdit
      }
    }
  },
  watch: {
    formData: {
      immediate: true,
      async handler () {
        await this.init()
      }
    }
  },
  methods: {
    async salvar () {
      try {
        let idCacamba

        if (!this.formData.obra) {
          throw new CustomError('Obra é um campo obrigatório para a alocação')
        }
        if (!this.formData.veiculo) {
          throw new CustomError('Veículo é um campo obrigatório para a alocação')
        }
        if (['Poliguindaste'].includes(this.formData.veiculo.tipo)) {
          if (!this.formData.cacamba) {
            throw new CustomError('Caçamba é um campo obrigatório para a alocação')
          }
          idCacamba = this.formData.cacamba.id
        } else {
          idCacamba = null
        }
        const idObra = this.formData.obra.id
        const idVeiculo = this.formData.veiculo.id
        const situacao = 'Alocada'

        await doRequest({
          method: 'put',
          url: `alocar_cacamba/${this.id}`,
          params: {
            situacao,
            idObra,
            idCacamba,
            idVeiculo
          }
        })
        notify('Dados salvos com sucesso! CTR Alocada', 'success')
        this.formDataSetted = false
        this.formData = {
          ...this.formData,
          situacao,
          idObra,
          idCacamba,
          idVeiculo
        }
        this.$router.push('/alocar-cacamba')
      } catch (error) {
        this.$utils.notifyError(error, 'Não foi possível alocar a cacamba')
      }
    },
    async init () {
      if (this.formDataSetted) {
        return
      }
      if (this.formData) {
        this.setLoading(true)

        this.formDataSetted = true
        let veiculo = null
        let cacamba = null
        let obra = null

        if (this.formData.idVeiculo) {
          veiculo = await this.findId({
            entity: 'Veiculo',
            id: this.formData.idVeiculo,
            params: {
              fields: ['id', 'idPerfil', 'numeroIdentificacao', 'placa', 'poliguindaste', 'tipo']
            }
          })
        }
        if (this.formData.idCacamba) {
          cacamba = await this.findId({
            entity: 'Cacamba',
            id: this.formData.idCacamba
          })
        }
        obra = {
          id: this.formData.idObra,
          endereco: this.formData.endereco,
          complemento: this.formData.complemento,
          numero: this.formData.numero,
          bairro: this.formData.bairro,
          cep: this.formData.cep
        }
        this.formData = {
          ...this.formData,
          obra,
          veiculo,
          cacamba
        }
        this.setLoading(false)
      }
    },
    disableField (field) {
      const {
        situacao
      } = this.formData
      const mapFields = {
        obra: () => {
          return ['Alocada'].includes(situacao)
        },
        cacamba: () => {
          return false
        },
        veiculo: () => {
          return ['Alocada'].includes(situacao)
        }
      }
      return mapFields[field] ? mapFields[field]() : false
    }
  }
}
</script>
