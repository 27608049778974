<template>
  <div class="grid-tipo-residuos">
    <template v-if="!readOnly">
      <div class="flex">
        <div class="flex-2 field-select-tipo-residuo">
          <div>Material - Tipo de Residuo</div>
          <dx-select-box
            :items="dynamicValues.tipoResiduo"
            value-expr="id"
            display-expr="nome"
            label="Material - Tipo de Residuo"
            v-model="triagemResiduoForm.idTipoResiduo"/>
        </div>
        <div class="flex-2">
          <text-field
            v-model="triagemResiduoForm.volumeAproximadoM3"
            label="Volume Aproximado M³"
            :options="volumeFieldOptions"
            type="number"
            required/>
        </div>
        <div class="add-button">
          <dx-button
            type="success"
            @click="onClickButtonAddTipoResiduo">
            {{ !!triagem.triagemResiduo.find(({ idTipoResiduo }) => triagemResiduoForm.idTipoResiduo === idTipoResiduo) ? 'Salvar Alterações' : 'Inserir' }}
          </dx-button>
        </div>
      </div>
      <div class="flex">
        <message-error class="width-100" :message.sync="errorMessageTipoResiduo"/>
      </div>
    </template>
    <div class="flex">
      <dx-data-grid
        ref="dataGridTriagemResiduo"
        :columns="gridData.columns"
        :data-source="listResiduos"/>
    </div>
  </div>
</template>

<script>
import { DxButton, DxDataGrid, DxSelectBox } from 'devextreme-vue'

import TextField from '../Form/TextField'
import MessageError from '../MessageError'
import { listClassesMateriais } from '@/components/Obra/service'
import { mapActions } from 'vuex'

export default {
  name: 'grid-triagem-residuos',
  components: {
    DxSelectBox,
    TextField,
    MessageError,
    DxButton,
    DxDataGrid
  },
  props: {
    /**
     * O objeto com o valor do grid. a props do objeto e 'residuos'.
     */
    id: {
      type: [Number, String],
      required: true
    },
    /**
     * Se o grid e somente para leitura.
     */
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dynamicValues: {
        tipoResiduo: []
      },
      triagem: {
        triagemResiduo: []
      },
      tempTriagemResiduoForm: null,
      triagemResiduoForm: {
        id: null,
        volumeAproximadoM3: null,
        idTipoResiduo: null
      },
      residuosSelecteds: [],
      errorMessageTipoResiduo: null,
      gridData: {
        columns: [
          { dataField: 'id', caption: 'Id', width: 50, visible: false },
          { dataField: 'tipoResiduo.classeMaterial.classe', caption: 'Classe de material', width: 145 },
          { dataField: 'tipoResiduo.nome', caption: 'Tipo de Resíduo', width: 145 },
          { dataField: 'volumeAproximadoM3', caption: 'Volume Aproximado M³', width: 145 },
          {
            type: 'buttons',
            buttons: [
              {
                cssClass: 'dx-button dx-button-primary mdi mdi-pencil',
                text: 'Editar',
                onClick: ({ row: { data } }) => {
                  this.triagemResiduoForm = {
                    id: data.id,
                    volumeAproximadoM3: data.volumeAproximadoM3,
                    idTipoResiduo: data.idTipoResiduo
                  }
                  this.tempTriagemResiduoForm = this.triagemResiduoForm
                }
              },
              {
                cssClass: 'dx-button dx-button-primary mdi mdi-delete',
                text: 'Remover',
                onClick: ({ row: { data } }) => {
                  const list = []
                  for (const triagemResiduo of this.triagem.triagemResiduo) {
                    if (triagemResiduo.idTipoResiduo === data.idTipoResiduo) {
                      if (triagemResiduo.id) {
                        list.push({
                          id: triagemResiduo.id,
                          _status: 'remove'
                        })
                      }
                    } else {
                      list.push(triagemResiduo)
                    }
                  }
                  this.triagem.triagemResiduo = list
                }
              }
            ]
          }
        ]
      },
      volumeFieldOptions: {
        min: 0
      }
    }
  },
  computed: {
    listResiduos () {
      if (this.triagem && this.triagem.triagemResiduo) {
        return this.triagem.triagemResiduo.filter(({ _status }) => _status !== 'remove')
      } else {
        return this.triagem.triagemResiduo
      }
    }
  },
  watch: {
    id: {
      immediate: true,
      async handler (idSolicitacaoTransporte) {
        this.triagem = (await this.find({
          entity: 'Triagem',
          rowsOnly: true,
          params: {
            where: {},
            include: [
              {
                required: true,
                model: 'SolicitacaoTransporte',
                as: 'ctrs',
                where: {
                  id: idSolicitacaoTransporte
                }
              },
              {
                model: 'TriagemResiduo',
                as: 'triagemResiduo',
                include: [
                  {
                    model: 'TipoResiduo',
                    as: 'tipoResiduo',
                    include: [
                      {
                        model: 'ClasseMaterial',
                        as: 'classeMaterial'
                      }
                    ]
                  }
                ]
              }
            ]
          }
        })).map((triagem) => {
          return {
            ...triagem,
            triagemResiduo: triagem.triagemResiduo.map((triagemResiduo) => {
              return {
                ...triagemResiduo,
                _status: 'update'
              }
            })
          }
        })[0] || {
          triagemResiduo: []
        }
      }
    },
    'triagemResiduoForm.idTipoResiduo' () {
      const residuoTriado = this.triagem.triagemResiduo.find(({ idTipoResiduo }) => this.triagemResiduoForm.idTipoResiduo === idTipoResiduo)
      if (residuoTriado && residuoTriado.volumeAproximadoM3) {
        this.triagemResiduoForm.volumeAproximadoM3 = residuoTriado.volumeAproximadoM3
      }
    }
  },
  async mounted () {
    const solicitacaoTransporte = (await this.find({
      rowsOnly: true,
      entity: 'SolicitacaoTransporte',
      params: {
        where: JSON.stringify({
          id: this.id
        }),
        include: JSON.stringify([
          {
            model: 'Residuo',
            as: 'residuos',
            include: [
              {
                required: true,
                model: 'TipoResiduo',
                as: 'tipoResiduo',
                include: [
                  {
                    model: 'ClasseMaterial',
                    as: 'classeMaterial'
                  }
                ]
              }
            ]
          }
        ])
      }
    }))[0] || { residuos: [] }
    const tipoResiduo = []
    for (const { tipoResiduo: t } of solicitacaoTransporte.residuos) {
      tipoResiduo.push(t)
    }
    this.dynamicValues.tipoResiduo = tipoResiduo
  },
  methods: {
    ...mapActions('Crud', ['find', 'insertRow', 'updateRow', 'setLoading']),
    /**
     * Metodo de callback de quando e clicado no botao de adicionar um tipo de
     * residuo. Com o objetivo de adicionar um tipo de residuo na listagem de
     * tipos de residuos da obra.
     */
    async onClickButtonAddTipoResiduo () {
      this.errorMessageTipoResiduo = null

      try {
        const tipoResiduoSelecionado = this.dynamicValues.tipoResiduo.find(({ id }) => id === this.triagemResiduoForm.idTipoResiduo)
        const volume = this.triagemResiduoForm.volumeAproximadoM3

        if (!tipoResiduoSelecionado) {
          throw new CustomError('Não foi escolhido um Tipo de Residuo.')
        }

        if (!volume || volume === 0) {
          throw new CustomError('Não foi digitado o Volume.')
        }

        if (this.triagem.triagemResiduo) {
          const tipoResiduoJaAdicionado = (this.triagem.triagemResiduo
            .find(({ idTipoResiduo }) => idTipoResiduo === tipoResiduoSelecionado.id)) || {}

          const triagemResiduo = {
            _status: tipoResiduoJaAdicionado.id ? 'update' : 'insert',
            ...(tipoResiduoJaAdicionado ? { id: tipoResiduoJaAdicionado.id } : {}),
            idTipoResiduo: this.triagemResiduoForm.idTipoResiduo,
            volumeAproximadoM3: this.triagemResiduoForm.volumeAproximadoM3,
            tipoResiduo: tipoResiduoSelecionado
          }

          const index = this.triagem.triagemResiduo.findIndex(({ idTipoResiduo }) => idTipoResiduo === triagemResiduo.idTipoResiduo)
          const triagemResiduoList = [
            ...this.triagem.triagemResiduo
          ]
          if (index === -1) {
            triagemResiduoList.push(triagemResiduo)
          } else {
            triagemResiduoList[index] = triagemResiduo
          }
          this.triagem.triagemResiduo = triagemResiduoList

          this.triagemResiduoForm = {
            idTipoResiduo: null,
            volumeAproximadoM3: null
          }
          this.tempTriagemResiduoForm = this.triagemResiduoForm

          this.$refs.dataGridTriagemResiduo.$_instance.refresh()
        }
      } catch (error) {
        let message = 'Erro durante o processamento'
        if (error instanceof CustomError) {
          message = error.message
        }
        this.errorMessageTipoResiduo = message
        console.error(error)
      } finally {
        this.setLoading(false)
      }
    },

    /**
     * Metodo de callback de quando e clicado no botao de remover um tipo de
     * residuo. Com o objetivo de remover um tipo de residuo da listagem de
     * tipos de residuos da obra.
     */
    onClickButtonRemoveTipoResiduo () {
      this.$emit('input', {
        ...this.value,
        residuos: this.gridData.dataSource
      })
    },

    /**
     * Metodo para buscar a lista de tipos de residuos agrupados pelas classes
     * de materiais do servidor para o combo de classes de materiais.
     * @param {Object} params - Os parametros da requisicao.
     * @returns {Object[]} A lista de tipos de res.
     */
    async _doClasseMaterialRequest (params) {
      const result = await listClassesMateriais(params)

      if (result.success) {
        this.allClassesMateriais = result.data.rows || []
        return result.data.rows.map(classe => ({
          key: classe.classe,
          tipoResiduosSelected: classe.tipoResiduos
        }))
      } else {
        throw new Error('Falha ao buscar a lista de transportadoras')
      }
    }
  }
}
</script>
