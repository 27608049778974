import ListSituacaoCacambas from './List/ListSituacaoCacambas.vue'
import MapLocalizarCacamba from './Map/MapLocalizarCacamba'

export default [
  {
    path: '/localizar-cacamba',
    name: 'localizar-cacamba',
    component: ListSituacaoCacambas,
    meta: {
      requiresAuth: true,
      title: 'Localizar Caçamba',
      permissionType: 'consulta'
    }
  },
  {
    path: '/localizar-cacamba/map',
    name: 'localizar-cacamba-map',
    component: MapLocalizarCacamba,
    meta: {
      requiresAuth: true,
      title: 'Localizar Caçamba',
      permissionType: 'consulta'
    }
  }
]
