import { doRequest, http } from '@/pluggables/http'
import FindObraResponseDto from './Maintain/FindObraResponseDto'
import ListObraResponseDto from './List/ListObraResponseDto'
/**
 * Metodo de comando, com o objetivo de realizar a requisica de listar todas as
 * obras.
 * @param {Object} params - Os parametros da requisicao.
 * @returns {Object} O resultado da requisicao.
 */
export async function listOnlyObra (params) {
  return doRequest({
    method: 'post',
    url: 'Obra/find',
    params: {
      ...params
    }
  })
}

/**
 * Metodo de comando, com o objetivo de realizar a requisica de listar todas as
 * obras.
 * @param {Object} params - Os parametros da requisicao.
 * @returns {Object} O resultado da requisicao.
 */
export async function listObra (params) {
  const defaultWhere = {}
  const result = await doRequest({
    method: 'post',
    url: 'Obra/find',
    params: {
      subQuery: false,
      fields: params.outFields ? params.outFields : JSON.stringify(['id', 'nomeObra', 'cep', 'endereco', 'duracaoInicial', 'duracaoFinal', 'pgrccNomeArquivo']),
      include: [{
        model: 'Perfil',
        as: 'gerador',
        required: true,
        attributes: ['id'],
        include: {
          model: 'Pessoa',
          as: 'pessoa',
          attributes: ['id', 'cpfCnpj', 'nomeRazaoSocial']
        }
      }],
      ...params,
      where: {
        and: [
          defaultWhere,
          params.where
        ]
      }
    }
  })
  return {
    ...result,
    data: result.success ? new ListObraResponseDto(result.data) : null
  }
}

/**
 * Metodo de comando, com o objetivo de realizar a requisica de obter uma lista
 * de obras que tem geometria.
 * @param {Object} params - Os parametros da requisicao.
 * @returns {Object} O resultado da requisicao.
 */
export async function getObrasWithShape (params) {
  return doRequest({
    method: 'post',
    url: 'Obra/find',
    params: {
      fields: ['id', 'nomeObra', 'shape'],
      where: params
    }
  })
}

/**
 * Metodo de comando, com o objetivo de realizar a requisica de buscar uma obra
 * pelo seu id.
 * @param {number} id - O id da obra a ser buscada.
 * @returns {Object} O resultado da requisicao.
 */
export async function getObraById (id) {
  const res = await doRequest({
    method: 'post',
    url: 'Obra/find',
    params: {
      fields: ['id', 'idGerador', 'nomeObra', 'codIbge', 'cep', 'bairro', 'endereco', 'complemento', 'numero', 'duracaoInicial', 'duracaoFinal', 'possuiAlvara', 'numeroAlvara', 'pgrccNomeArquivo', 'observacao', 'motivoAlteracao', 'shape'],
      include: [
        {
          model: 'Perfil',
          as: 'gerador',
          required: true,
          include: [
            {
              model: 'Pessoa',
              as: 'pessoa'
            }
          ]
        },
        {
          model: 'Perfil',
          as: 'transportadores',
          required: false,
          through: 'ObraTransportador',
          include: {
            model: 'Pessoa',
            as: 'pessoa'
          }
        },
        {
          model: 'ObraTipoResiduo',
          as: 'obraTipoResiduos',
          required: false,
          include: {
            model: 'TipoResiduo',
            as: 'tipoResiduo',
            include: {
              model: 'ClasseMaterial',
              as: 'classeMaterial',
              include: {
                model: 'TipoResiduo',
                as: 'tipoResiduos'
              }
            }
          }
        },
        {
          model: 'Municipio',
          as: 'municipio',
          required: false
        }
      ],
      where: { id },
      limit: 1
    }
  })
  return {
    ...res,
    data: res.success ? new FindObraResponseDto(res.data) : null
  }
}

/**
 * Metodo de comand, com o objetivo de realizar a requisicao de salvar os dados
 * de uma obra no servidor.
 * @param {FormData} formData - Os dados da obra a ser salva.
 * @returns {Object} O resultado da requisicao.
 */
export function saveObra (formData) {
  if (formData.has('id')) {
    return doRequest({
      method: 'put',
      url: `Obra/${formData.get('id')}`,
      params: formData,
      options: {
        cleanObject: false,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    })
  } else {
    return doRequest({
      method: 'post',
      url: 'Obra',
      params: formData,
      options: {
        cleanObject: false,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    })
  }
}

/**
 * Metodo de comando, com o objetivo de fazer a requisicao de remover uma obra
 * apartir do seu id.
 * @returns {Object} O resultado da requisicao.
 */
export function deleteObra (id) {
  return http.delete(`Obra/${id}`)
    .catch(() => {
      console.error('Erro ao remover obra')
    })
}

/**
 * Metodo de comando, com o objetivo de fazer a requisicao de listar todas as
 * transportadoras para o servidor.
 * @param {Object} params - Os parametros da requisicao.
 * @returns {Object} O resultado da requisicao.
 */
export function listTransportadoras (params) {
  return doRequest({
    method: 'post',
    url: 'ViewTransportador/find',
    params: {
      where: {
        and: [
          { ...params.where },
          { situacaoValidacao: 'A' }
        ]
      }
    }
  })
}
