<template>
  <div class="enviar-transporte">
    <div>
      <popup-update-solicitacao
        :active.sync="popupUpdateVisible"
        :solicitacao="solicitacaoPopup"
        title="Alterar solicitação de transporte não solicitado"
        @updated-solicitacao="onPopupUpdatedSolicitacao"/>

      <popup-cancel-solicitacao
        :active.sync="popupCancelVisible"
        :solicitacao="solicitacaoPopup"
        title="Cancelar solicitação de transporte não solicitado"
        @updated-solicitacao="onPopupUpdatedSolicitacao"/>

      <div class="md-layout">
        <div class="md-layout-item">
          <h2 class="crud-title">Enviar Transporte Não Solicitado</h2>
        </div>

        <div
          v-if="canAdd"
          class="md-layout-item"
          style="text-align: right;">
          <dx-button type="success" icon="plus" styling-mode="contained" @click="$router.push({ path: 'enviar-transporte-nao-solicitado/add' })">Enviar novo</dx-button>
        </div>
      </div>
      <simple-grid
        entity="ViewSolicitacaoTransporte"
        :editable="false"
        :deletable="false"
        :grid-data="gridData"
        popup-title="Solicitacao transporte"
        ref="simpleGrid"/>
    </div>
  </div>
</template>
<script>
import { SimpleGrid } from '@/components'
import CustomStore from 'devextreme/data/custom_store'
import DxButton from 'devextreme-vue/button'
import config from 'config'

import { getSolicitacoesTransporteSolicitados } from '../service'
import mixinCrud from '../../mixin-crud'
import PopupUpdateSolicitacao from '@/components/SolicitacaoTransporte/PopupUpdateSolicitacao'
import PopupCancelSolicitacao from '@/components/SolicitacaoTransporte/PopupCancelSolicitacao'
import { hasPermission } from '@/util/permissions'

export default {
  name: 'list-solicitacoes-nao-solicitadas',
  components: {
    SimpleGrid,
    DxButton,
    PopupUpdateSolicitacao,
    PopupCancelSolicitacao
  },
  mixins: [mixinCrud],
  /**
   * Metodo do vue, para obter o estado inicial do componente.
   * @returns {Object} O estado inicial do componente.
   */
  data () {
    const dataSourceView = new CustomStore({
      key: 'id',
      load: loadOptions => this.$utils.wrapRequestForGrid(getSolicitacoesTransporteSolicitados(loadOptions)),
      byKey: async key => {
        const res = await getSolicitacoesTransporteSolicitados({
          where: { id: key }
        }, false)
        return res.totalCount > 0 ? res.data[0] : null
      }
    })
    return {
      gridData: {
        value: {
          visible: true
        },
        columns: this.parseColumns([
          {
            dataField: 'nomeRazaoSocial',
            calculateDisplayValue: 'nomeRazaoSocial',
            caption: 'Enviado Para',
            editorType: 'dxLookup',
            editorOptions: {
              allowClearing: true,
              showClearButton: true,
              showCancelButton: false,
              dataSource: dataSourceView,
              displayExpr: 'nomeRazaoSocial',
              valueExpr: 'nomeRazaoSocial'
            }
          },
          {
            dataField: 'cep',
            calculateDisplayValue (data) {
              const { cep = null, endereco } = data || {}
              return endereco && cep
                ? [endereco || '', `CEP: ${cep || ''}`]
                  .filter(v => v.length)
                  .join(' - ')
                : ''
            },
            caption: 'Endereço',
            editorType: 'dxLookup',
            editorOptions: {
              allowClearing: true,
              showClearButton: true,
              showCancelButton: false,
              dataSource: dataSourceView,
              displayExpr (item) {
                const { cep = null, endereco } = item || {}
                return endereco && cep
                  ? [endereco || '', `CEP: ${cep || ''}`]
                    .filter(v => v.length)
                    .join(' - ')
                  : ''
              },
              valueExpr: 'cep'
            }
          },
          {
            dataField: 'dataHoraSolicitacao',
            caption: 'Data/Hora do Envio',
            format: 'dd/MM/yyyy - HH:mm',
            dataType: 'date',
            editorType: 'dxDateBox'
          },
          {
            dataField: 'classeMaterialPredominante',
            calculateDisplayValue: 'classeMaterialPredominante',
            caption: 'Classe de Material/Tipo de Resíduo Predominante',
            editorType: 'dxLookup',
            editorOptions: {
              allowClearing: true,
              showClearButton: true,
              showCancelButton: false,
              dataSource: dataSourceView,
              displayExpr: 'classeMaterialPredominante',
              valueExpr: 'classeMaterialPredominante'
            }
          },
          {
            dataField: 'id',
            caption: 'Nº do CTR',
            editorType: 'dxNumberBox'
          },
          {
            dataField: 'situacao',
            calculateDisplayValue: 'situacao',
            caption: 'Situação',
            editorType: 'dxLookup',
            editorOptions: {
              allowClearing: true,
              showClearButton: true,
              showCancelButton: false,
              dataSource: dataSourceView,
              displayExpr: 'situacao',
              valueExpr: 'situacao'
            }
          },
          {
            type: 'buttons',
            minWidth: 345,
            width: 345,
            showInColumnChooser: false,
            buttons: [
              {
                name: 'view',
                cssClass: 'dx-button mdi mdi-file-document',
                text: 'CTR',
                onClick: ({ row: { data } }) => {
                  this.onSoliticaoActionClick('view', data)
                }
              },
              ...(hasPermission('Enviar Transporte não Solicitado', 'altera') ? [{
                cssClass: 'dx-button dx-button-success dx-button-mode-contained mdi mdi-pencil',
                text: 'EDITAR',
                onClick: ({ row: { data } }) => {
                  this.onSoliticaoActionClick('update', data)
                },
                visible ({ row: { data } }) {
                  return ['Alocada', 'Enviada'].includes(data.situacao)
                }
              }] : []),
              {
                cssClass: 'dx-button dx-button-primary mdi mdi-close-thick',
                text: 'CANCELAR',
                onClick: ({ row: { data } }) => {
                  this.onSoliticaoActionClick('cancel', data)
                },
                visible ({ row: { data } }) {
                  return ['Alocada', 'Enviada', 'Solicitada'].includes(data.situacao)
                }
              }
            ]
          }
        ]),
        dataSource: new CustomStore({
          key: 'id',
          load: loadOptions => this.$utils.wrapRequestForGrid(getSolicitacoesTransporteSolicitados(loadOptions))
        })
      },
      popupUpdateVisible: false,
      popupCancelVisible: false,
      solicitacaoPopup: null,
      classesMateriais: {}
    }
  },

  computed: {
    /**
     * Valor computado de se o usuario logado pode adicionar uma solicitacao nao
     * solicitada.
     * @returns {boolean}
     */
    canAdd () {
      return hasPermission('Enviar Transporte não Solicitado', 'inclui')
    }
  },

  methods: {
    /**
     * Metodo de callback de quando e clicado no botao de acao da solicitacao,
     * com o objetivo de abrir a popup de acao da solicitacao.
     * @param {string} action - A acao que sera feita na solicitacao, 'view'
     * para visualizar a solicitacao, 'update' para atualizar a solicitacao, ou
     * 'cancel' para solicitar o cancelamento da solicitacao.
     * @param {Object} solicitacao - A solicitacao que foi clicada.
     */
    onSoliticaoActionClick (action, solicitacao) {
      switch (action) {
        case 'view':
          window.open(`${config.baseUrl}/FichaCTR/${solicitacao.id}`)
          break
        case 'update':
          this.popupUpdateVisible = true
          this.solicitacaoPopup = solicitacao
          break
        case 'cancel':
          this.popupCancelVisible = true
          this.solicitacaoPopup = solicitacao
          break
      }
    },

    /**
     * Metodo de callback de quando um popup altera valores nas justificativas.
     * Com o objetivo de recarregar a lista de solicitacoes.
     */
    onPopupUpdatedSolicitacao () {
      this.$refs.simpleGrid.$refs.gridInstance.instance.getDataSource().reload()
    },

    /**
     * Metodo para obter o atribute 'title' da celula de situacao da solicitacao.
     * @param {Object} solicitacao - A solicitacao que se deseja obter o title
     * da situacao.
     * @returns {string|undefined} O title da situacao atual.
     */
    _getTitleSituacao ({ situacao, motivoSolCancelamento, motivoRecSolCancelamento, motivoRecSolicitacao }) {
      switch (situacao) {
        case 'Cancelamento Solicitado':
          return motivoSolCancelamento
        case 'Cancelamento Recusado':
          return motivoRecSolCancelamento
        case 'Resíduo Rejeitado':
          return motivoRecSolicitacao
        default:
          return ''
      }
    }
  }
}
</script>

<style lang="scss">
@import '../../../../global.scss';

.solicitacao-decline-popup-content {
  display: flex;
  flex-direction: column;

  .solicitacao-decline-popup-buttons {
    display: flex;
    flex-direction: row-reverse;
  }

  > :not(.solicitacao-decline-popup-buttons) {
    flex: 1;
  }

  .error-message-card {
    background-color: #B00020 !important;
    color: white !important;
    width: 100%;
    margin-top: 6px;
    margin-bottom: 16px;
  }
}

.solicitacao-accept-popup-content {
  display: flex;
  flex-direction: column;

  > .solicitacao-accept-popup-form {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 16px;
  }

  > .solicitacao-accept-popup-buttons {
    display: flex;
    flex-direction: row-reverse;
  }

  .error-message-card {
    background-color: #B00020 !important;
    color: white !important;
    width: 100%;
    margin-top: 6px;
    margin-bottom: 16px;
  }
}
</style>
