import ListAcompanharOcorrencia from './List/ListAcompanharOcorrencia.vue'
import MapAcompanharOcorrencia from './Map/MapAcompanharOcorrencia.vue'

export default [
  {
    path: '/acompanhar-ocorrencia',
    name: 'acompanhar-ocorrencia',
    component: ListAcompanharOcorrencia,
    meta: {
      requiresAuth: true,
      title: 'Acompanhar Ocorrência',
      permissionType: 'consulta'
    }
  },
  {
    path: '/acompanhar-ocorrencia/map',
    name: 'acompanhar-ocorrencia-map',
    component: MapAcompanharOcorrencia,
    meta: {
      requiresAuth: true,
      title: 'Acompanhar Ocorrência',
      permissionType: 'altera',
      hideFromMenu: true
    },
    props: true
  }
]
