import ListSolicitacoesNaoSolicitadas from './List/ListSolicitacoesNaoSolicitadas.vue'
// import AddSolicitacaoNaoSolicitada from './Add/AddSolicitacaoNaoSolicitada.vue'
import AddSolicitacaoNaoSolicitada from './Add/AddSolicitacaoNaoSolicitadaNew.vue'

export default [
  {
    path: '/enviar-transporte-nao-solicitado',
    name: 'enviar-transporte-nao-solicitado',
    component: ListSolicitacoesNaoSolicitadas,
    meta: {
      icon: 'mdi mdi-truck-delivery-outline',
      title: 'Enviar Transporte não Solicitado',
      requiresAuth: true,
      permissionType: 'consulta'
    },
    props: true
  },
  {
    path: '/enviar-transporte-nao-solicitado/add',
    name: 'enviar-transporte-nao-solicitado-add',
    component: AddSolicitacaoNaoSolicitada,
    meta: {
      requiresAuth: true,
      title: 'Enviar Transporte não Solicitado',
      permissionType: 'inclui'
    }
  }
]
