import ConsultaEmpresaCRT from './ConsultaEmpresaCTR.vue'

export default {
  path: '/consultar-empresa-ctr',
  name: 'consultar-empresa-ctr',
  component: ConsultaEmpresaCRT,
  meta: {
    icon: 'mdi mdi-file-swap-outline',
    title: 'Consultar Empresa Ativa com CTR',
    requiresAuth: true,
    permissionType: 'consulta'
  },
  props: true
}
