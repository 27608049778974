<template>
  <div class="generic-crud">
    <div v-if="action && (canAdd || canEdit || canView)" class="generic-crud-form">
      <h2 v-if="action === 'add'">Incluir Veículo</h2>
      <h2 v-else-if="canEdit">Editar Veículo</h2>
      <h2 v-else>Visualizar Veículo</h2>
      <fieldset>
        <legend v-if="action === 'add'">Veículo</legend>
        <legend v-else>Veículo</legend>
        <simple-form
          v-model="formData"
          id="group"
          :fields="gridData.columns"
          :form-options="formOptions"
          @formContentReady="onFormContentReady"
          ref="crudForm"/>
        <div v-if="formData" class="fileuploader-container">
          <dx-file-uploader
            :disabled="disabledDocumentos"
            :selectButtonText="attachment ? 'Substituir documento' : 'Adicionar documento'"
            :allow-canceling="true"
            :value="attachment ? [attachment] : []"
            upload-mode="useForm"
            @value-changed="onChangeAttachment"
          />
          <div style="padding: 10px" v-if="attachment">
            <dx-button
              icon="mdi mdi-download"
              @click="onDownloadAttachment">
              <span class="mdi mdi-download"/> Baixar Anexo
            </dx-button>
          </div>
        </div>
        <div class="action-buttons">
          <dx-button
            style="margin-right: 12px"
            v-if="!isReadOnly"
            text="Salvar"
            type="success"
            @click="saveFormItem"/>
          <dx-button
            v-if="action === 'edit' && formData && formData.token"
            text="Imprimir QRCODE"
            type="primary"
            @click="generateQR"/>
          <dx-button
            v-if="action === 'edit' && formData && formData.token"
            style="margin-left: 12px"
            text="Copiar link de acesso rápido"
            type="primary"
            @click="copyAcessoRapido"/>
        </div>
      </fieldset>
    </div>
    <div v-else>
      <div class="md-layout">
        <div class="md-layout-item">
          <h2 class="crud-title">Manter Veículo</h2>
        </div>

        <div
          v-if="canAdd"
          class="md-layout-item"
          style="text-align: right;">
          <dx-button type="success" @click="$router.push({ path: 'veiculo/add' })" text="Adicionar Novo"></dx-button>
        </div>
      </div>
    <simple-grid
      :deletable="false"
      :editable="false"
      entity="Veiculo"
      :form-options="formOptions"
      :grid-data="gridData"
      :insertable="canAdd"
      popup-title="Editar Veículo"
      :viewable="canView && !canEdit"
      ref="simpleGrid"/>
    </div>
  </div>
</template>
<script>
import { SimpleGrid, SimpleForm } from '@/components'
import CustomStore from 'devextreme/data/custom_store'
import { mapState, mapGetters } from 'vuex'
import { DxButton } from 'devextreme-vue'
import QRCode from 'qrcode'
import notify from 'devextreme/ui/notify'
import mixinCrud from '../mixin-crud'
import { hasPermission } from '@/util/permissions'
import _ from 'lodash'
import config from 'config'
import { DxFileUploader } from 'devextreme-vue/file-uploader'
import { http } from '@/pluggables/http'
import { confirm } from 'devextreme/ui/dialog'

const baseUrl = config.baseUrl
export default {
  name: 'veiculo-crud',
  mixins: [
    mixinCrud
  ],
  components: {
    SimpleGrid,
    DxButton,
    SimpleForm,
    DxFileUploader
  },
  data () {
    return {
      entity: 'veiculo',
      disabledDocumentos: false,
      permissionName: 'Manter Veículo',
      formData: null,
      lookupResult: null,
      selectedUserSigla: null,
      gridData: {
        value: {
          visible: true
        },
        columns: this.parseColumns([
          {
            dataField: 'idPerfil',
            calculateSortValue: 'perfil.pessoa.nomeRazaoSocial',
            calculateDisplayValue: 'perfil.pessoa.nomeRazaoSocial',
            dataType: 'number',
            caption: 'Transportador',
            editorType: 'dxLookup',
            editorOptions: {
              dataSource: {
                store: new CustomStore({
                  key: 'id',
                  load: async (loadOptions) => {
                    this.lookupResult = await this.$utils.wrapRequestForGrid(
                      this.find({
                        entity: 'ViewTransportador',
                        loadOptions
                      })
                    )
                    return this.lookupResult
                  },
                  byKey: key => {
                    return this.findId({
                      entity: 'ViewTransportador',
                      id: key
                    })
                  }
                }),
                paginate: true,
                pageSize: 5
              },
              displayExpr: 'nomeRazaoSocial',
              valueExpr: 'id',
              onSelectionChanged: (data) => {
                if (data.selectedItem) {
                  this.selectedUserSigla = data.selectedItem.siglaNome
                }
              },
              onInitialized: e => {
                if (this._currentUserIs('Transportador') && document.querySelector('.dx-form')) {
                  setTimeout(() => {
                    e.component.beginUpdate()
                    e.component.option('value', this.currentPerfil)
                    e.component.option('readOnly', true)
                    e.component.option('visible', false)
                    e.component.field().value = this.currentPerfil
                    e.component.endUpdate()
                  }, 400)
                }
              }
            },
            validationRules: [{
              type: 'required'
            }]
          },

          {
            dataField: 'placa',
            caption: 'Placa',
            dataType: 'string',
            validationRules: [
              { type: 'required' },
              {
                type: 'custom',
                validationCallback: ({ value }) => !(value.length !== 7)
              }
            ],
            formItem: {
              editorOptions: {
                mask: 'HHH0A00',
                maskRules: { H: /[a-z]/i },
                maskInvalidMessage: 'Digite uma placa de veículo válida',
                maxLength: 7,
                minLength: 7,
                inputAttr: { style: 'text-transform: uppercase' },
                onFocusOut: async ({ component }) => {
                  const value = component.instance().option('value')
                  const { data: veiculo } = await http.post(`${baseUrl}/find-veiculos-prestador`, {
                    placa: value
                  })
                  const fields = ['marca', 'modelo', 'renavam', 'cor', 'ano', 'tipo', 'volumeM3', 'poliguindaste', 'documento']
                  if (veiculo && this.action) {
                    const dialogResult = await confirm('<i>Este veículo já está cadastrado para outro Transportador, deseja continuar?</i>', 'Confirmar')
                    if (dialogResult) {
                      this.setPrestador(fields, veiculo)
                    } else {
                      this.clearData()
                    }
                  } else {
                    this.clearData()
                  }
                }
              }
            }
          },
          {
            dataField: 'marca',
            caption: 'Marca',
            dataType: 'string',
            editorOptions: { maxLength: 40 },
            validationRules: [{
              type: 'required'
            }]
          },
          {
            dataField: 'modelo',
            caption: 'Modelo',
            dataType: 'string',
            editorOptions: { maxLength: 30 },
            validationRules: [{
              type: 'required'
            }]
          },
          {
            dataField: 'numeroIdentificacao',
            caption: 'Número de identificação',
            dataType: 'string',
            helpText: 'Nº de identificação que será adesivado no caminhão',
            validationRules: [{
              type: 'required'
            }],
            formItem: {
              visible: () => {
                return false
              },
              disabled: () => {
                return true
              }
            }
          },
          {
            dataField: 'renavam',
            caption: 'Renavam',
            editorType: 'dxTextBox',
            dataType: 'string',
            editorOptions: {
              mask: '00000000000',
              maxLength: 11
            },
            validationRules: [
              { type: 'required' },
              {
                type: 'custom',
                validationCallback ({ value }) {
                  return value.length === 11
                }
              }
            ]
          },
          {
            dataField: 'cor',
            dataType: 'string',
            caption: 'Cor'
          },
          {
            dataField: 'ano',
            caption: 'Ano de Fabricação',
            dataType: 'string',
            editorType: 'dxNumberBox',
            formItem: {
              editorOptions: {
                min: 1970,
                max: (new Date().getFullYear()) + 1
              }
            },
            validationRules: [{
              type: 'required'
            }, { type: 'range', min: 1970, max: (new Date().getFullYear()) + 1 }]
          },
          {
            dataField: 'tipo',
            caption: 'Tipo',
            editorType: 'dxLookup',
            dataType: 'string',
            editorOptions: {
              searchEnabled: false,
              showCancelButton: false,
              allowClearing: true,
              showClearButton: true,
              dataSource: [
                {
                  name: 'Basculante',
                  value: 'Basculante'
                },
                {
                  name: 'Poliguindaste',
                  value: 'Poliguindaste'
                }
              ],
              valueExpr: 'value',
              displayExpr: 'name',
              inputAttr: {
                id: 'tipoValue'
              },
              onInitialized: e => {
                if (document.querySelector('.dx-form')) {
                  /* setTimeout(() => {
                    const inputValue = e.element.querySelector('input[type="hidden"]')
                    if (inputValue) {
                      const poliguindasteItem = document.querySelector('.dx-form .poliguindasteItem')
                      if (poliguindasteItem) {
                        // poliguindasteItem.style.display = (inputValue.value && inputValue.value === 'Poliguindaste') ? 'block' : 'none'
                      }
                    }
                  }, 400) */
                }
              },
              onSelectionChanged: e => {
                if (document.querySelector('.dx-form')) {
                  if (e.selectedItem && e.selectedItem.value !== 'Poliguindaste') {
                    this.formData.poliguindaste = null
                  }
                  const poliguindasteItem = document.querySelector('.dx-form .poliguindasteItem')
                  if (poliguindasteItem) {
                    poliguindasteItem.style.display = (e.selectedItem && e.selectedItem.value === 'Poliguindaste') ? 'block' : 'none'
                  }
                }
              }
            }
          },
          {
            dataField: 'volumeM3',
            caption: 'Volume (m³)',
            dataType: 'number',
            editorType: 'dxNumberBox',
            editorOptions: {
              min: 0,
              max: 100
            },
            validationRules: [
              { type: 'required' },
              { type: 'range', min: 0, max: 100 }
            ]
          },
          {
            dataField: 'poliguindaste',
            dataType: 'string',
            caption: 'Poliguindaste',
            formItem: {
              cssClass: 'poliguindasteItem'
            },
            editorType: 'dxLookup',
            editorOptions: {
              allowClearing: true,
              showClearButton: true,
              searchEnabled: false,
              showCancelButton: false,
              dataSource: [
                'Simples', 'Duplo', 'Triplo'
              ]
            }
          },
          {
            dataField: 'token',
            caption: 'Token',
            dataType: 'string',
            editorType: 'dxNumberBox',
            editorOptions: {
              onInput: (e) => {
                const inputElement = e.event.target
                if (inputElement.value.length > 6) { inputElement.value = inputElement.value.slice(0, 6) }
              }
            }
          },
          {
            dataField: 'excluido',
            caption: 'Excluído',
            dataType: 'number',
            editorType: 'dxLookup',
            sortOrder: 'asc',
            width: 120,
            calculateSortValue: 'excluido',
            filterValue: 0,
            calculateDisplayValue: (data) => {
              return {
                0: 'Não',
                1: 'Sim'
              }[data.excluido]
            },
            editorOptions: {
              items: [{ value: 0, label: 'Não' }, { value: 1, label: 'Sim' }],
              displayExpr: 'label',
              valueExpr: 'value'
            },
            formItem: {
              visible: false
            }
          },
          {
            name: 'buttons-column',
            type: 'buttons',
            showInColumnChooser: false,
            buttons: [
              {
                cssClass: 'dx-button dx-button-success dx-button-mode-contained dx-widget dx-button-has-text mdi mdi-pencil',
                text: 'Editar',
                onClick: ({ row: { data } }) => {
                  this.$router.push(`/veiculo/edit/${data.id}`)
                },
                visible: ({ row: { data } }) => {
                  return (hasPermission('Manter Veículo', 'altera') && data.excluido === 0)
                }
              },
              {
                cssClass: 'dx-link dx-button dx-button-danger dx-button-mode-contained dx-widget dx-link-icon mdi mdi-delete',
                text: 'Excluir',
                onClick: ({ row: { data } }) => {
                  const result = confirm('Tem certeza que deseja excluir este veículo?', 'Atenção!')
                  result.then(async (dialogResult) => {
                    if (dialogResult) {
                      await this.$http.put(`veiculo/${data.id}`, {
                        excluido: 1
                      })
                      this.$refs.simpleGrid.$refs.gridInstance.instance.refresh()
                    }
                  })
                },
                visible: ({ row: { data } }) => {
                  return (hasPermission('Manter Veículo', 'exclui') && data.excluido === 0)
                }
              },
              {
                cssClass: 'dx-button dx-button-success dx-button-mode-contained dx-widget dx-button-has-text mdi mdi-restore',
                text: 'Restaurar',
                onClick: ({ row: { data } }) => {
                  const result = confirm('Tem certeza que deseja restaurar este veículo?', 'Atenção!')
                  result.then(async (dialogResult) => {
                    if (dialogResult) {
                      await this.$http.put(`veiculo/${data.id}`, {
                        excluido: 0
                      })
                      this.$refs.simpleGrid.$refs.gridInstance.instance.refresh()
                    }
                  })
                },
                visible: ({ row: { data } }) => {
                  return (hasPermission('Manter Veículo', 'exclui') && data.excluido === 1 && this.isAdministrador)
                }
              }
            ]
          }
        ]),
        dataSource: new CustomStore({
          key: 'id',
          load: (loadOptions) => {
            return this.$utils.wrapRequestForGrid(this.find({
              entity: this.entity,
              params: {
                fields: ['id', 'placa', 'modelo', 'numeroIdentificacao', 'renavam', 'cor', 'ano', 'tipo', 'volumeM3', 'token', 'poliguindaste', 'excluido', 'marca'],
                include: JSON.stringify([{
                  model: 'Perfil',
                  as: 'perfil',
                  required: true,
                  attributes: ['id', 'idPessoa'],
                  include: [
                    {
                      model: 'Pessoa',
                      as: 'pessoa',
                      attributes: ['id', 'nomeRazaoSocial']
                    }
                  ]
                }])
              },
              loadOptions
            }))
          },
          insert: (values) => this.insertRow({
            entity: this.entity,
            values: {
              ...values,
              poliguindaste: values.tipo === 'Poliguindaste' ? values.poliguindaste : null
            }
          }),
          update: (key, values) => {
            return this.updateRow({
              entity: this.entity,
              values: {
                ...values,
                poliguindaste: values.tipo === 'Poliguindaste' ? values.poliguindaste : null
              },
              key
            })
          },
          remove: (key, values) => this.removeRow({ entity: this.entity, key })
        }),
        onEditingStart: () => {
          this.isEditingData = true
        }
      },
      isEditingData: false,
      attachment: null
    }
  },
  methods: {
    setPrestador (fields, veiculo, updateData = true) {
      for (const field of fields) {
        if (updateData) this.$refs.crudForm.instance.updateData(field, veiculo[field])
        this.$refs.crudForm.instance.getEditor(field)?.option('disabled', true)
      }
      if (updateData) {
        this.formData.prestador = 1
        this.formData.idVeiculoOriginal = veiculo.id
      }
      if (this.formData.documento) {
        const fileBuffer = new Uint8Array(this.formData.documento.data)
        const blob = new Blob([fileBuffer], { type: veiculo.contentType || this.attachment.type })
        this.attachment = new File([blob], veiculo.nomeDocumento || this.attachment.name, { type: veiculo.contentType || this.attachment.type })
      }
      this.disabledDocumentos = true
    },
    clearData () {
      const fields = ['placa', 'marca', 'modelo', 'renavam', 'cor', 'ano', 'tipo', 'volumeM3']
      for (const field of fields) {
        if (this.$refs.crudForm) {
          this.$refs.crudForm.instance.updateData(field, null)
          this.$refs.crudForm.instance.getEditor(field)?.option('disabled', false)
        }
      }
      this.disabledDocumentos = false
      this.attachment = null
      if (this.formData) {
        this.formData.prestador = 0
        this.formData.idVeiculoOriginal = null
      }
    },
    async saveFormItem () {
      if (!this.isSecretaria || !this.isAdministrador) {
        this.formData.sigla = this.userData.sigla
      } else {
        this.formData.sigla = this.selectedUserSigla
      }
      if (this.formData.placa) this.formData.placa = this.formData.placa.toUpperCase()
      if (this.formData.token) {
        this.$refs.crudForm.instance.itemOption('token', 'validationRules', [{ type: 'stringLength', min: 6, message: 'O token deve ter 6 caracteres' }])
      } else {
        this.$refs.crudForm.instance.itemOption('token', 'validationRules', undefined)
      }
      const fields = ['placa', 'marca', 'modelo', 'renavam', 'cor', 'ano', 'tipo', 'volumeM3', 'poliguindaste', 'documento']
      this.setPrestador(fields, this.formData, false)

      let { isValid } = this.$refs.crudForm?.instance?.validate()
      if (!this.attachment) {
        notify('Nenhum documento foi anexado a este veículo', 'warning')
        isValid = false
      }
      if (isValid === false) {
        return
      }
      const attachmentFormData = new FormData()

      /**
       * Converte formData para uma instância de FormData
       */
      const data = _.cloneDeep(this.formData)
      for (const [key, value] of Object.entries(data)) {
        if (value && key !== 'documento') attachmentFormData.append(key, value)
      }

      /**
       * Inserção dos documentos
       * @type {Blob}
       */
      attachmentFormData.set('documento', this.attachment, this.attachment.name)
      attachmentFormData.set('contentType', this.attachment.type)
      attachmentFormData.set('nomeDocumento', this.attachment.name)

      if (this.action === 'add') {
        try {
          await http.post(`${baseUrl}/${this.entity}`, attachmentFormData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          this.$router.push('/veiculo')
        } catch (err) {
          if (err.response && err.response.data && err.response.data.details && err.response.data.details.errorType && err.response.data.details.errorType === 'SequelizeUniqueConstraintError') {
            notify('Um veículo ja foi cadastrado com esta placa e token', 'error', 2000)
            console.error(err.message)
          } else if (err.response && err.response.data && err.response.data.message && err.response.data.message === 'Já existe um veículo com esta placa') {
            notify('Já existe um veículo com esta placa', 'error', 2000)
            console.error(err.message)
          } else {
            notify('Atenção, alguns campos obrigatórios não foram preenchidos', 'error', 2000)
            console.error(err.message)
          }
        }
      } else {
        try {
          await http.put(`${baseUrl}/${this.entity}/${this.formData.id}`, attachmentFormData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          this.$router.push('/veiculo')
        } catch (err) {
          if (err.response && err.response.data && err.response.data.details && err.response.data.details.errorType && err.response.data.details.errorType === 'SequelizeUniqueConstraintError') {
            notify('Um veículo ja foi cadastrado com esta placa e token', 'error', 2000)
            console.error(err.message)
          } else {
            notify('Atenção, alguns campos obrigatórios não foram preenchidos', 'error', 2000)
            console.error(err.message)
          }
        }
      }
      if (this.formData.shape && !this.formData.shape.wkid) {
        this.formData.shape.wkid = '4326'
        this.formData.shape.crs = { type: 'name', properties: { name: 'EPSG:4326' } }
      }
    },
    setCustomFormData () {
      const name = (Object.values(this.user.pessoa.nomeRazaoSocial)[0] + Object.values(this.user.pessoa.nomeRazaoSocial)[1]).toUpperCase()
      if (this.formData) this.formData.numeroIdentificacao = name + '001'
    },
    _currentUserIs (perfil) {
      return this.user.perfis.filter(p => {
        if (typeof perfil === 'string') {
          return p.tipoPerfil.descricao === perfil
        } else {
          return perfil.includes(p.tipoPerfil.descricao)
        }
      }).length >= 1
    },
    async onFormContentReady (instance) {
      await this.$nextTick()
      instance.itemOption('token', 'validationRules', undefined)
      instance.validate()
    },
    copyAcessoRapido () {
      navigator.clipboard.writeText(`${config.destinoRapidoUrl}/${this.formData.placa}/${this.formData.token}`)
      notify('Link de acesso copiado com sucesso', 'success')
    },
    async generateQR () {
      try {
        const base64QrCode = await QRCode.toDataURL(`${config.destinoRapidoUrl}/${this.formData.placa}/${this.formData.token}`, { scale: 3 })
        const el = document.createElement('div')
        el.innerHTML = `
        <div ref="printContainer" style="display: flex; height: 100vh">
          <div style="margin:auto">
            <div style="text-align: center">
              <h3 style="text-align: center; margin: 0">Acesso ao destino rápido</h3>
              <img style="height: 200px; width: 200px" src="${base64QrCode}">
              <h3 style="text-align: center; margin: 0">${config.destinoRapidoUrl}/${this.formData.placa}/${this.formData.token}</h3>
            </div>
          </div>
        </div>`
        const pdfWindow = window.open('')
        pdfWindow.document.body.innerHTML = el.innerHTML
        await this.$nextTick()
        pdfWindow.print()
      } catch (err) {
        console.error(err)
      }
    },
    onChangeAttachment ({ value }) {
      if (value.length) {
        this.attachment = value[0]
      }
    },
    async onDownloadAttachment  () {
      const fileBuffer = new Uint8Array(this.formData.documento.data)
      const blob = new Blob([fileBuffer], { type: this.attachment.type })
      const fileName = this.attachment.name
      const link = document.createElement('a')
      // Browsers that support HTML5 download attribute
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', fileName)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    }
  },
  computed: {
    /**
     * Valor computado para verificar se o usuario logado e uma secretaria.
     * @returns {Boolean}
     */
    isSecretaria () {
      return !!this.userData.userTypes.find(userType => userType === 'Secretaria')
    },
    isAdministrador () {
      return !!this.userData.userTypes.find(userType => userType === 'Administrador')
    },

    ...mapGetters('Login', { userData: 'userData' }),
    ...mapState('Login', ['user']),
    currentPerfil () {
      return this.user.perfis.find(v => v.tipoPerfil.descricao === 'Transportador').id
    },
    /**
     * Valor computado de se o usuario logado pode incluir cacambas.
     * @returns {boolean}
     */
    canAdd () {
      return hasPermission('Manter Caçamba', 'inclui')
    },

    /**
     * Valor computado de se o usuario logado pode editar cacambas.
     * @returns {boolean}
     */
    canEdit () {
      return hasPermission('Manter Veículo', 'altera')
    },

    /**
     * Valor computado de se o usuario logado pode excluir cacambas.
     * @returns {boolean}
     */
    canDelete () {
      return hasPermission('Manter Veículo', 'exclui')
    },

    /**
     * Valor computado de se o usuario logado tem permissao de consultar
     * cacamba.
     * @returns {boolean}
     */
    canView () {
      return hasPermission('Manter Veículo', 'consulta')
    },

    /**
     * Valor computado de se o formulario e somente para leitura.
     * @returns {boolean}
     */
    isReadOnly () {
      return (!this.canAdd && this.action === 'add') ||
        (!this.canEdit && this.action === 'edit')
    },

    /**
     * Valor computado das opcoes do devextreme do componente de form.
     * @returns {Object}
     */
    formOptions () {
      return {
        colCount: 2,
        readOnly: this.isReadOnly
      }
    }
  },
  watch: {
    async action (value) {
      await this.$nextTick()
      this.attachment = null
      this.clearData()
      if (value === 'edit' || value === 'add') {
        if (this._currentUserIs('Transportador') && this.$refs.crudForm) {
          this.$refs.crudForm.instance.updateData('idPerfil', this.currentPerfil)
          this.$refs.crudForm.instance.itemOption('idPerfil', 'visible', false)
        }
        if (value === 'edit' && this.$refs.crudForm) {
          this.$refs.crudForm.instance.itemOption('numeroIdentificacao', 'visible', true)
          this.$refs.crudForm.instance.itemOption('numeroIdentificacao', 'disabled', true)
        } else if (value === 'add' && this.$refs.crudForm) {
          this.$refs.crudForm.instance.itemOption('numeroIdentificacao', 'visible', false)
          this.$refs.crudForm.instance.itemOption('numeroIdentificacao', 'disabled', true)
        }
        this.$refs.crudForm.instance.itemOption('tipo', 'visible', !!(this.formData.tipo))
      } else if (this.$refs.crudForm && this.$refs.crudForm.instance) {
        this.$refs.crudForm.instance.itemOption('numeroIdentificacao', 'visible', true)
      }
    },
    /**
     * Assiste formData para preencher variavel attachment para o anexo
     * @param value
     */
    formData (value) {
      if (value.documento) {
        this.attachment = new File(value.documento.data, value.nomeDocumento, { type: value.contentType })
      }
      if (value.id && value.prestador === 1) {
        const fields = ['placa', 'marca', 'modelo', 'renavam', 'cor', 'ano', 'tipo', 'volumeM3', 'poliguindaste', 'documento']
        notify('Atenção! Este veículo já cadastrado para um outro Transportador', 'warning')
        this.setPrestador(fields, this.formData, false)
      }
    }
  },
  async mounted () {
    await this.$nextTick()
    this.$refs.simpleGrid.getDataGridInstance().columnOption('idPerfil', 'visible', false)
    if (this.$refs.simpleGrid && (this._currentUserIs('Secretaria') || this._currentUserIs('Administrador'))) {
      this.$refs.simpleGrid.getDataGridInstance().columnOption('idPerfil', 'visible', true)
    }
    if (this.$refs.crudForm) {
      if (this.action === 'edit') {
        this.$refs.crudForm.instance.itemOption('numeroIdentificacao', 'visible', true)
        this.$refs.crudForm.instance.itemOption('numeroIdentificacao', 'disabled', true)
      } else if (this.action === 'add') {
        this.$refs.crudForm.instance.itemOption('numeroIdentificacao', 'visible', false)
        this.$refs.crudForm.instance.itemOption('numeroIdentificacao', 'disabled', true)
      }
    }
  }

}
</script>
<style lang="scss">
.fileuploader-container {
  border: 1px solid #d3d3d3;
  margin-bottom: 20px;
}
</style>
