<template>
  <div>
   <VuexWrapper ref="vuexWrapper" />
    <div v-if="!!(messages.length)">
      <DxPopup
        ref="dxPopupRef"
        :visible.sync="popupActive"
        :dragEnabled="true"
        :hide-on-outside-click="false"
        :show-close-button="false"
        :show-title="true"
        height="50vh"
        width="80vw"
        :title="messages[index].titulo">
        <DxToolbarItem
          :visible="index > 0"
          widget="dxButton"
          toolbar="bottom"
          location="before"
          :options="backButtonOptions"
        />
        <DxToolbarItem
          :visible="messages.length > 1 && index <= messages.length - 2"
          widget="dxButton"
          toolbar="bottom"
          location="after"
          :options="nextButtonOptions"
        />
        <DxToolbarItem
          :visible="messages.length >= 1 && index === messages.length - 1"
          widget="dxButton"
          toolbar="bottom"
          location="after"
          :options="okButtonOptions"
        />
        <template #content>
          <DxScrollView width="100%" height="100%">
            <div id="textBlock">
              <DxHtmlEditor
                :value="messages[index].mensagem"
                :readOnly="true"
              >
              </DxHtmlEditor>
            </div>
          </DxScrollView>
        </template>
      </DxPopup>
    </div>
  </div>
</template>
<script setup>
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup'
import { DxScrollView } from 'devextreme-vue/scroll-view'
import { DxHtmlEditor } from 'devextreme-vue/html-editor'
import { onMounted, ref } from 'vue'
import { doRequest } from '@/pluggables/http'
import VuexWrapper from '@/helper/VuexWrapper'
import notify from 'devextreme/ui/notify'

const vuexWrapper = ref()
const messages = ref([])
const popupActive = ref(false)

const dxPopupRef = ref()
const index = ref(0)

onMounted(async () => {
  try {
    const idTipoPerfis = vuexWrapper.value.user.perfis.map(perfil => { return { idTipoPerfil: { contains: [perfil.idTipoPerfil] } } })
    const { data: { rows: foundMessages } } = await doRequest({
      method: 'post',
      url: 'mensagem/find',
      params: {
        subQuery: false,
        where: {
          ativa: 'S',
          or: idTipoPerfis
        },
        attributes: ['id', 'titulo', 'mensagem', 'idTipoPerfil'],
        include: [
          {
            model: 'LeituraMensagem',
            as: 'leituras',
            required: false,
            attributed: ['idUsuarioPerfil'],
            include: [
              {
                model: 'UsuarioPerfil',
                as: 'usuarioPerfil',
                required: true,
                attributes: ['id'],
                where: {
                  idUsuario: vuexWrapper.value.user.id
                }
              }
            ]
          }
        ]

      }
    })
    messages.value = foundMessages
    popupActive.value = !!(messages.value.length)
  } catch (e) {
    console.error(e)
    notify('Não foi possivel buscar as mensagens, por favor, contate um administrador do sistema', 'error', 3500)
  }
})

const backButtonOptions = {
  icon: 'back',
  text: 'Anterior',
  onClick: () => {
    index.value -= 1
  }
}
const nextButtonOptions = {
  icon: 'chevronnext',
  text: 'Próximo',
  onClick: async () => {
    index.value += 1
  }
}
const okButtonOptions = {
  icon: 'check',
  text: 'Ok',
  onClick: async () => {
    popupActive.value = false
    const mensagensNaoLidas = messages.value.filter(msg => !msg.leituras.length)
    try {
      for (const mensagem of mensagensNaoLidas) {
        for (const perfil of vuexWrapper.value.user.perfis) {
          // verifica se a mensagem é para o perfil do usuário, se for, marca como lida
          if (mensagem.idTipoPerfil.includes(perfil.idTipoPerfil)) {
            await doRequest({
              url: 'leituraMensagem',
              method: 'post',
              params: {
                idUsuarioPerfil: perfil.usuarioPerfil.id,
                idMensagem: mensagem.id
              }
            })
          }
        }
      }
      localStorage.setItem('popupViewed', true)
    } catch (error) {
      console.error(error)
    }
  }
}
</script>
<style>
</style>
