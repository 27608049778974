
/**
 * Método com o objetivo de converter latLong para o padrão DMS
 * @param {String} lat - Latitude
 * @param {String} lng - Longitude
 * @returns {String} A latitude e longitude no formato DMS: (XX XX XX S) (XX XX XX W).
 */
export function convertDMS (lat, lng) {
  const latitude = _toDegreesMinutesAndSeconds(lat)
  const latitudeCardinal = lat >= 0 ? 'N' : 'S'

  const longitude = _toDegreesMinutesAndSeconds(lng)
  const longitudeCardinal = lng >= 0 ? 'E' : 'W'

  return `Latitude: ${latitudeCardinal} ${latitude} \nLongitude: ${longitudeCardinal} ${longitude} `
}
function _toDegreesMinutesAndSeconds (coordinate) {
  const absolute = Math.abs(coordinate)
  const degrees = Math.floor(absolute)
  const minutesNotTruncated = (absolute - degrees) * 60
  const minutes = Math.floor(minutesNotTruncated)
  const seconds = Math.floor((minutesNotTruncated - minutes) * 60)

  return `${degrees}° ${minutes}' ${seconds}''`
}
