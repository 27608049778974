import TextField from '../TextField'

export default {
  cep: {
    component: TextField,
    binds: {
      class: 'col-6',
      label: 'CEP: ',
      name: 'cep',
      options: {
        mask: '00000-000',
        maskInvalidMessage: 'O CEP deve conter 8 números',
        maxLength: 8
      },
      required: true
    }
  },
  uf: {
    component: TextField,
    binds: {
      class: 'col-6',
      label: 'UF: ',
      name: 'uf',
      options: {
        disabled: true
      },
      required: true
    }
  },
  municipio: {
    component: TextField,
    binds: {
      class: 'col-6',
      label: 'Município: ',
      name: 'municipio',
      options: {
        disabled: true
      },
      required: true
    }
  },
  bairro: {
    component: TextField,
    binds: {
      class: 'col-6',
      label: 'Bairro: ',
      name: 'bairro',
      options: {},
      required: true
    }
  },
  endereco: {
    component: TextField,
    binds: {
      class: 'col-12',
      label: 'Endereço: ',
      name: 'endereco',
      options: {
        disabled: true
      }
    }
  },
  complemento: {
    component: TextField,
    binds: {
      class: 'col-6',
      label: 'Complemento: ',
      name: 'complemento',
      options: {
        maxLength: 100
      }
    }
  },
  numero: {
    component: TextField,
    binds: {
      class: 'col-6',
      label: 'Número: ',
      name: 'numero',
      options: {
        maxLength: 8
      }
    }
  }
}
