<template>
  <div class="md-layout md-gutter md-alignment-center-center" style="height: 100vh !important;">
    <md-card class="md-layout-item md-size-25 md-medium-size-25 md-small-size-80 md-elevation-3" style="padding: 16px">
      <md-card-header style="padding: 0 !important;">
        <md-card-header-text>
          <div class="md-layout md-gutter md-alignment-center-center">
            <img id="slu_logo"  src="slu_logo.jpg" width="250px">
          </div>
          <div style="padding-left: 16px">
            <div style="margin: 10px 0; font-weight: bold; font-family: Roboto; font-size: 1rem; font-style: italic">Destino Rápido</div>
            <div class="md-subhead" style="font-style: italic; font-size: 0.9rem; margin-bottom: 1.5rem">
              Acesso rápido para pesagem na URE
            </div>
          </div>
        </md-card-header-text>
      </md-card-header>
      <md-card-content>
        <form @submit="onSubmit">
          <DxForm
            :form-data="formData"
            label-location="top"
            :show-validation-summary="true"
          >
            <DxSimpleItem
              data-field="placa"
              editor-type="dxTextBox"
              :editor-options="{
                stylingMode:'underlined',
                mask:'LLL-0A00',
                onKeyUp: onKeyUpPlaca,
              }"
              :label="{ text: 'Placa do veículo' }"
              :validation-rules="[{ type: 'required', message: 'placa é obrigatoria' },]"
            />
            <DxSimpleItem
              data-field="token"
              editor-type="dxTextBox"
              :editor-options="{
                stylingMode:'underlined',
              }"
              :label="{ text: 'Token de acesso' }"
              :validation-rules="[{ type: 'required', message: 'token é obrigatorio' }, { type: 'stringLength', max: 6 }]"
            />
            <DxButtonItem
              horizontal-alignment="right"
              :button-options="{ text: 'Entrar', useSubmitBehavior: true }"
              cssClass="btn-submit"
            />
          </DxForm>
        </form>
      </md-card-content>
    </md-card>
  </div>
</template>
<script setup>
import { DxForm, DxSimpleItem, DxButtonItem } from 'devextreme-vue/form'
import { http } from '@/pluggables/http'
import notify from 'devextreme/ui/notify'
import { ref } from 'vue'

const formData = ref({
  placa: '',
  token: ''
})
const onKeyUpPlaca = (e) => {
  formData.value.placa = e.event.target.value.toUpperCase()
}
const onSubmit = async (e) => {
  try {
    e.preventDefault()
    const { token } = formData.value
    const placa = formData.value.placa.replace(/-/g, '')

    const result = (await http.get(`/destinoRapido/findVeiculo/${placa}/${token}`)).data
    if (result.placa !== placa) {
      notify({ message: 'Nenhum veículo foi encontrado a partir desta placa e deste token' }, 'error', 3000)
    } else {
      const router = await import('@/router')
      await router.default.push(`/destino-rapido/${placa}/${token}`)
    }
  } catch (err) {
    notify({ message: 'Não foi possível buscar o veículo, por favor, contate um administrador do sistema' }, 'error', 3000)
  }
}
</script>
<style scoped>
.btn-submit > div{
  background-color: rgb(255, 132, 2);
  color: white;
  width: 100%;
  margin: 16px 0;
}
.btn-submit > div:hover{
  background-color: rgb(255, 132, 2);
  color: white;
}
</style>
