<template>
  <div class="generic-crud">
    <div>
      <div class="md-layout">
        <div class="md-layout-item">
          <h2 class="crud-title">Consulta Movimentação de Resíduos</h2>
        </div>
      </div>
      <simple-grid entity="Pessoa" popup-title="Pessoa" :popup-data="popupData" :grid-data="gridData" :deletable="false" :editable="false" :viewable="false"></simple-grid>
    </div>
    <div class="sum-box">
      <h2></h2>
      <table>
        <tr>
          <td>SALDO TOTAL VOLUME RECEBIDO EM M³ (ENTREGUE PELO TRANSPORTADOR)</td>
          <td class="value"><span>{{ saldo.tr }}</span></td>
        </tr>
        <tr>
          <td>SALDO TOTAL VOLUME RECEBIDO EM M³ NA ATT</td>
          <td class="value"><span>{{ saldo.att }}</span></td>
        </tr>
        <tr>
          <td>SALDO TOTAL VOLUME RECEBIDO EM M³ NO ATERRO</td>
          <td v-if="saldo.atr" class="value"><span>{{ saldo.atr }}</span></td>
          <td v-else class="value"><span>-</span></td>
        </tr>
        <tr>
          <td>SALDO TOTAL VOLUME RECEBIDO EM M³ NA RECICLADORA</td>
          <td v-if="saldo.rec" class="value"><span>{{ saldo.rec }}</span></td>
          <td v-else class="value"><span>-</span></td>
        </tr>
      </table>
    </div>
  </div>

</template>
<script>
import { SimpleGrid } from '@/components'
import CustomStore from 'devextreme/data/custom_store'
import mixinCrud from '../mixin-crud'
import { mapActions } from 'vuex'
import { getParamsFromLoadOptions } from '../../../util/loadOptionsParams'

export default {
  name: 'pessoa-crud',
  mixins: [
    mixinCrud
  ],
  components: {
    SimpleGrid
  },
  data () {
    return {
      entity: 'Pessoa',
      permissionName: 'Consultar Movimentação de Resíduos',
      formData: null,
      saldo: {
        atr: null,
        att: null,
        rec: null,
        tr: null
      },
      gridData: {
        value: {
          visible: true
        },
        columns: this.parseColumns([
          {
            dataField: 'id',
            visible: false,
            caption: 'id'
          },
          {
            dataField: 'pessoa.nome_razao_social',
            caption: 'Nome razão social',
            calculateDisplayValue: 'nome_razao_social',
            editorType: 'dxLookup',
            editorOptions: {
              allowClearing: true,
              showClearButton: true,
              acceptCustomValue: true,
              dataSource: {
                store: new CustomStore({
                  load: (loadOptions) => {
                    return this.$utils.wrapRequestForGrid(this.find({
                      entity: 'ViewTransportador',
                      loadOptions
                    }))
                  },
                  byKey: key => key || null
                }),
                sort: [
                  { selector: 'nomeRazaoSocial', asc: true }
                ],
                paginate: true,
                pageSize: 10
              },
              displayExpr: 'nomeRazaoSocial',
              valueExpr: 'nomeRazaoSocial'
            }
          },
          {
            dataField: 'pessoa.cpf_cnpj',
            caption: 'cnpj',
            calculateDisplayValue: 'cpf_cnpj',
            editorType: 'dxLookup',
            editorOptions: {
              allowClearing: true,
              showClearButton: true,
              acceptCustomValue: true,
              dataSource: {
                store: new CustomStore({
                  load: (loadOptions) => this.$utils.wrapRequestForGrid(this.find({
                    entity: 'ViewTransportador',
                    loadOptions
                  })),
                  byKey: key => key || null
                }),
                sort: [
                  { selector: 'cpfCnpj', asc: true }
                ],
                paginate: true,
                pageSize: 10
              },
              displayExpr: 'cpfCnpj',
              valueExpr: 'cpfCnpj'
            }
          },
          {
            dataField: 'volume_transportador',
            caption: 'VOLUME RECEBIDO EM M³ (ENTREGUE PELO TRANSPORTADOR)',
            allowFiltering: false
          },
          {
            dataField: 'volume_att',
            caption: 'VOLUME RECEBIDO EM M³ NA ATT',
            allowFiltering: false
          },
          {
            dataField: 'volume_aterro',
            caption: 'VOLUME RECEBIDO EM M³ NO ATERRO',
            calculateDisplayValue: (data) => {
              if (data.volume_aterro) {
                return data.volume_aterro
              } else {
                return '-'
              }
            },
            calculateSortValue: 'volume_aterro',
            allowFiltering: false
          },
          {
            dataField: 'volume_recicladora',
            caption: 'VOLUME RECEBIDO EM M³ NA RECICLADORA',
            calculateDisplayValue: (data) => {
              if (data.volume_recicladora) {
                return data.volume_recicladora
              } else {
                return '-'
              }
            },
            calculateSortValue: 'volume_aterro',
            allowFiltering: false
          },
          {
            dataField: 'saldo',
            caption: 'saldo',
            allowFiltering: false,
            calculateDisplayValue: (data) => {
              const volumeRecicladora = data.volume_recicladora ? data.volume_recicladora : 0
              const volumeAterro = data.volume_aterro ? data.volume_aterro : 0
              const volumeAtt = data.volume_att ? data.volume_att : 0
              const saldo = parseFloat(volumeRecicladora) + parseFloat(volumeAterro) + parseFloat(volumeAtt)
              return saldo
            }
          }
        ]),
        dataSource: new CustomStore({
          key: 'id',
          load: (loadOptions) => {
            return this.$utils.wrapRequestForGrid(this.findMoveResiduos(loadOptions))
          }
        })
      }
    }
  },

  methods: {
    /**
     * Metodo para obter o objeto com valores do grid
     * @param {Object} loadOptions - Os parametros de busca do datasource do
     * devextreme.
     * @returns {Object} Os parametros da requisicao.
     */
    async findMoveResiduos (loadOptions) {
      const params = getParamsFromLoadOptions(loadOptions, true)
      const result = await this.findMoveResiduo(params)
      if (!(params.format === 'xlsx')) {
        this.saldo.atr = result.data.sum[0].volume_aterro
        this.saldo.att = result.data.sum[0].volume_att
        this.saldo.rec = result.data.sum[0].volume_recicladora
        this.saldo.tr = result.data.sum[0].volume_transportador
        return { data: result.data.rows, totalCount: result.data.count }
      }
    },
    ...mapActions('Crud', ['findMoveResiduo'])
  }
}
</script>

<style scoped lang="scss">
.sum-box { width: 600px;}
.sum-box table {
  border-collapse: collapse;
  border: 2px solid black;
  width: 100%;
}

.sum-box th, td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
.sum-box .value {
  width: 100px;
}
</style>
