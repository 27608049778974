import config from 'config'
import { hasPermission } from '@/util/permissions'
import { getParamsFromLoadOptions } from '@/util/loadOptionsParams'
import storeHelper from '@/helper/storeHelper'
import AppCustomStore from '@/base/AppCustomStore'
import { http } from '@/pluggables/http'
import { confirm } from 'devextreme/ui/dialog'
import { visibleEditButton } from '@/helper/general'

const CustomStore = AppCustomStore

async function getSolicitacoesTransporteSolicitados (loadOptions, loadOnlyCurrentTransportador = true) {
  const params = getParamsFromLoadOptions(loadOptions)
  const baseUrl = config.baseUrl
  params.order = params.order ? params.order : JSON.stringify([['id', 'asc']])

  if (loadOnlyCurrentTransportador) {
    const transportadores = storeHelper.getCurrentPerfisTransportador()

    const situacao = typeof params.where.situacao === 'object' ? params.where.situacao : ['Alocada', 'Enviada', 'Resíduo Rejeitado', 'Cancelamento Recusado', 'Cancelamento Solicitado', 'Solicitada']

    params.where = {
      ...params.where,
      situacao: situacao,
      idTransportador: (transportadores[0] || {}).id
    }
  }
  const res = await http.post(`${baseUrl}/ViewSolicitacaoTransporte/find`, params)
  if (params.format !== 'xlsx') {
    return { data: res.data.data.rows, totalCount: res.data.data.count }
  }
}

const alocarCacambaMixin = {
  data () {
    const transportadores = storeHelper.getCurrentPerfisTransportador()
    const where = {
      situacao: ['Alocada', 'Enviada', 'Resíduo Rejeitado', 'Cancelamento Recusado', 'Cancelamento Solicitado', 'Solicitada'],
      idTransportador: (transportadores[0] || {}).id
    }
    return {
      entity: 'ViewSolicitacaoTransporte',
      gridData: {
        value: {
          visible: true
        },
        filterValue: [['situacao', '=', 'Enviada']],
        columns: this.parseColumns([
          {
            dataField: 'id',
            calculateDisplayValue: 'id',
            caption: 'Nº do CTR',
            editorType: 'dxNumberBox'
          },
          {
            dataField: 'nome_obra',
            calculateDisplayValue: 'nomeObra',
            caption: 'Nome da Obra',
            dataType: 'string'
          },
          {
            dataField: 'dataHoraEnvio',
            caption: 'Data/Hora do Envio',
            format: 'dd/MM/yyyy - HH:mm',
            dataType: 'date',
            editorType: 'dxDateBox'
          },
          {
            dataField: 'endereco',
            calculateDisplayValue: 'endereco',
            caption: 'Endereço da Obra',
            dataType: 'string'
          },
          {
            dataField: 'residuoPredominante',
            calculateDisplayValue: 'residuoPredominante',
            caption: 'Classe de Resíduo / Tipo de Resíduo Predominante',
            editorType: 'dxLookup',
            editorOptions: {
              allowClearing: true,
              showClearButton: true,
              showCancelButton: false,
              displayExpr: 'residuoPredominante',
              valueExpr: 'residuoPredominante',
              dataSource: new CustomStore({
                key: 'residuoPredominante',
                load: (loadOptions) => {
                  return this.$utils.wrapRequestForGrid(this.find({
                    entity: 'ViewSolicitacaoTransporte',
                    params: {
                      attributes: [{ fn: 'DISTINCT', col: 'residuoPredominante', as: 'residuoPredominante' }],
                      subQuery: false,
                      where
                    },
                    loadOptions
                  }))
                },
                byKey: async (key) => {
                  const res = await this.find({
                    entity: 'ViewSolicitacaoTransporte',
                    params: {
                      attributes: [{ fn: 'DISTINCT', col: 'residuoPredominante', as: 'residuoPredominante' }],
                      subQuery: false,
                      where: {
                        ...where,
                        residuoPredominante: key
                      }
                    },
                    rowsOnly: true
                  })
                  return res.length > 0 ? res[0] : null
                }
              })
            }
          },
          {
            dataField: 'numeroIdentificacao',
            calculateDisplayValue: 'numeroIdentificacao',
            caption: 'Nº de Idenfiticação da Caçamba',
            editorType: 'dxLookup',
            editorOptions: {
              allowClearing: true,
              showClearButton: true,
              showCancelButton: false,
              displayExpr: 'numeroIdentificacao',
              valueExpr: 'numeroIdentificacao',
              dataSource: new CustomStore({
                key: 'numeroIdentificacao',
                load: (loadOptions) => {
                  return this.$utils.wrapRequestForGrid(this.find({
                    entity: 'ViewSolicitacaoTransporte',
                    params: {
                      attributes: [{ fn: 'DISTINCT', col: 'numeroIdentificacao', as: 'numeroIdentificacao' }],
                      subQuery: false,
                      where
                    },
                    loadOptions
                  }))
                },
                byKey: async (key) => {
                  const res = await this.find({
                    entity: 'ViewSolicitacaoTransporte',
                    params: {
                      attributes: [{ fn: 'DISTINCT', col: 'numeroIdentificacao', as: 'numeroIdentificacao' }],
                      subQuery: false,
                      where: {
                        ...where,
                        numeroIdentificacao: key
                      }
                    },
                    rowsOnly: true
                  })
                  return res.length > 0 ? res[0] : null
                }
              })
            }
          },
          {
            dataField: 'placa',
            calculateDisplayValue: 'placa',
            caption: 'Veículo(Placa)',
            editorType: 'dxLookup',
            editorOptions: {
              allowClearing: true,
              showClearButton: true,
              showCancelButton: false,
              displayExpr: 'placa',
              valueExpr: 'placa',
              dataSource: new CustomStore({
                key: 'placa',
                load: (loadOptions) => {
                  return this.$utils.wrapRequestForGrid(this.find({
                    entity: 'ViewSolicitacaoTransporte',
                    params: {
                      attributes: [{ fn: 'DISTINCT', col: 'placa', as: 'placa' }],
                      subQuery: false,
                      where
                    },
                    loadOptions
                  }))
                },
                byKey: async (key) => {
                  const res = await this.find({
                    entity: 'ViewSolicitacaoTransporte',
                    params: {
                      attributes: [{ fn: 'DISTINCT', col: 'placa', as: 'placa' }],
                      subQuery: false,
                      where: {
                        ...where,
                        placa: key
                      }
                    },
                    rowsOnly: true
                  })
                  return res.length > 0 ? res[0] : null
                }
              })
            }
          },
          {
            dataField: 'situacao',
            caption: 'Situação',
            filterValue: 'Enviada',
            lookup: {
              allowClearing: true,
              dataSource: this.$utils.getDistinctSituacoes()
            }
          },
          {
            type: 'buttons',
            width: 'auto',
            showInColumnChooser: false,
            buttons: [
              {
                name: 'editCol',
                ...(hasPermission('Solicitar Transporte', 'altera') ? {
                  text: 'Editar',
                  cssClass: 'dx-button dx-button-success dx-button-mode-contained mdi mdi-pencil'
                } : {
                  text: 'Visualizar',
                  cssClass: 'dx-button dx-button-success dx-button-mode-contained mdi mdi-magnify'
                }),
                visible: (result) => {
                  const { row: { data } } = result
                  return ['Alocada', 'Enviada'].includes(data.situacao)
                },
                onClick: (result) => {
                  const { row: { data } } = result
                  this.$router.push(`/alocar-cacamba/edit/${data.id}`)
                }
              },
              {
                name: 'alocarCol',
                text: 'Alocar',
                cssClass: 'dx-button mdi mdi-truck-delivery',
                onClick: (result) => {
                  const { row: { data } } = result
                  this.$router.push(`/alocar-cacamba/edit/${data.id}`)
                },
                visible: (result) => {
                  const { row: { data } } = result
                  return ['Enviada'].includes(data.situacao)
                }
              },
              {
                name: 'solicitarCancelamento',
                text: 'Solic. Cancelamento',
                cssClass: 'dx-button mdi mdi-close-thick',
                hint: 'Solicitar cancelamento da CTR',
                visible: (result) => {
                  const { row: { data } } = result
                  return ['Solicitada', 'Enviada', 'Alocada'].includes(data.situacao)
                },
                onClick: (result) => {
                  const { row: { data } } = result
                  this.popupCancelamentoCtrVisible = {
                    show: true,
                    ctr: data
                  }
                }
              },
              {
                name: 'viewPdf',
                cssClass: 'dx-button mdi mdi-file-document',
                text: 'CTR',
                onClick: data => {
                  window.open(`${config.baseUrl}/FichaCTR/${data.row.key}`)
                }
              },
              ...(hasPermission('Editar CTR', 'altera') ? [{
                name: 'editarSolicitacaoTransporte',
                text: 'Editar CTR',
                cssClass: 'dx-button mdi mdi-pencil',
                hint: 'Editar CTR',
                visible: ({ row: { data } }) => {
                  return visibleEditButton(data)
                },
                onClick: async (result) => {
                  const { row: { data } } = result
                  const isAdmOrSecretaria = await storeHelper.getCurrentPerfis().some(perfil => perfil.idTipoPerfil === 10 || perfil.idTipoPerfil === 4)
                  const confirmed = isAdmOrSecretaria && isAdmOrSecretaria.length ? true : await confirm(`Este CTR só poderá ser modificado 1 (uma) vez por meio do seu cadastro.
                    <br>Caso efetue quaisquer alteração de forma errônea, informe o número do CTR por meio do dúvidas ou sugestões, <br> qual dado deverá ser modificado que os servidores do SLU efetuarão a modificação por mais 1 (uma) vez.
                    <br> Deseja Continuar?`, 'Atenção')
                  if (confirmed) {
                    await this.$router.push(`/editar-solicitacao-transporte/${data.id}`)
                  }
                }
              }] : [])
            ]
          }
        ]),
        dataSource: new CustomStore({
          key: 'id',
          load: loadOptions => {
            return this.$utils.wrapRequestForGrid(getSolicitacoesTransporteSolicitados(loadOptions))
          }
        })
      }
    }
  }
}

export default alocarCacambaMixin
