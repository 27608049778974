import lodash from 'lodash'
import store from '@/store'

const storeHelper = {
  get perfis () {
    return storeHelper.getCurrentPerfis()
  },
  isAdministrador () {
    const currentUser = lodash.get(store, 'state.Login.user', {})
    return !!(currentUser.perfis || [])
      .find(perfil => (perfil.tipoPerfil || {}).descricao === 'Administrador')
  },
  get isSecretaria () {
    const currentUser = lodash.get(store, 'state.Login.user', {})
    return !!(currentUser.perfis || [])
      .find(perfil => (perfil.tipoPerfil || {}).descricao === 'Secretaria')
  },
  get isGerador () {
    const currentUser = lodash.get(store, 'state.Login.user', {})
    return !!(currentUser.perfis || [])
      .find(perfil => (perfil.tipoPerfil || {}).descricao === 'Gerador')
  },
  getCurrentPerfis: () => {
    const currentUser = lodash.get(store, 'state.Login.user', {})
    return currentUser.perfis || []
  },
  getCurrentPerfisAdministrador: (stopFirst = false, perfis = storeHelper.perfis) => {
    return perfis[stopFirst ? 'find' : 'filter'](v => (v.tipoPerfil || {}).descricao === 'Administrador')
  },
  getCurrentPerfisSecretaria: (stopFirst = false, perfis = storeHelper.perfis) => {
    return perfis[stopFirst ? 'find' : 'filter'](v => (v.tipoPerfil || {}).descricao === 'Secretaria')
  },
  getCurrentPerfisTransportador: (stopFirst = false, perfis = storeHelper.perfis) => {
    return perfis[stopFirst ? 'find' : 'filter'](v => (v.tipoPerfil || {}).descricao === 'Transportador')
  },
  getCurrentPerfisGerador: (stopFirst = false, perfis = storeHelper.perfis) => {
    return perfis[stopFirst ? 'find' : 'filter'](v => (v.tipoPerfil || {}).descricao === 'Gerador')
  },
  getCurrentPerfisDestinoFinal: (stopFirst = false, perfis = storeHelper.perfis) => {
    return perfis[stopFirst ? 'find' : 'filter'](v => (v.tipoPerfil || {}).descricao === 'Destino Final')
  },
  getCurrentPerfisFiscalizacao: (stopFirst = false, perfis = storeHelper.perfis) => {
    return perfis[stopFirst ? 'find' : 'filter'](v => (v.tipoPerfil || {}).descricao === 'Fiscalização')
  },
  getCurrentPerfisEntidadePublica: (stopFirst = false, perfis = storeHelper.perfis) => {
    return perfis[stopFirst ? 'find' : 'filter'](v => (v.tipoPerfil || {}).descricao === 'Entidade Pública')
  },
  getCurrentPerfisCidadao: (stopFirst = false, perfis = storeHelper.perfis) => {
    return perfis[stopFirst ? 'find' : 'filter'](v => (v.tipoPerfil || {}).descricao === 'Cidadão')
  },

  hasPerfil (perfilDesc, perfis = storeHelper.perfis) {
    perfilDesc = perfilDesc instanceof Array ? perfilDesc : [perfilDesc]
    return !!perfis.find((perfil) => perfilDesc.includes((perfil || {}).tipoPerfil?.descricao))
  },

  currentUserIs (perfil) {
    const perfis = storeHelper.perfis
    return perfis.filter(p => {
      if (typeof perfil === 'string') {
        return p.tipoPerfil.descricao === perfil
      } else {
        return perfil.includes(p.tipoPerfil.descricao)
      }
    }).length >= 1
  }
}

export default storeHelper
