<template>
  <div class="ocorrencia-details">
    <div class="form-popup">
      <h3>Transporte Irregular</h3>
      <model-file-uploader
        class="fileUploader"
        buttonLabel="Anexar Foto"
        dragAndDropLabel="ou arraste aqui"
        v-model="fotoTransporte"
        accept="image/*"
        attribute="fotoCacamba"
        entity="SolicitacaoTransporte"
        @onRemove="() => { this.attachmentPreview = null }"
      />
      <div v-if="attachmentPreview" style="text-align: center;">
        <img :src="attachmentPreview" alt="preview" class="thumbnail" />
      </div>
      <label>Descrição da irregularidade</label>
      <dx-select-box
        v-model="irregularidadeTextArea"
        :items="['Veículo estacionado em local inadequado', 'Veículo com resíduo inapropriado', 'Veículo com excesso de resíduo', 'Veículo sem CTR']"
        placeholder="Descreva a irregularidade"
        class="texAreaIrregularidade"
      />
      <label>Placa</label>
      <DxLookup
        @input="dxSelectBoxInput"
        @custom-item-creating="onInsertCustomValue"
        :acceptCustomValue="true"
        placeholder="Placa"
        :data-source="fieldsBind.veiculo.dataSource"
        display-expr="placa"
        v-model="placa"
        maxLength="7"
        ref="selectBoxPlaca"
        />
      <label v-if="showExisteVerificadorRadioBox" class="identificadorDxLabel">Existe algum identificador no veículo?</label>
      <DxRadioGroup
        style="width: 152px;"
        v-if="showExisteVerificadorRadioBox"
        :items="typeSelection"
        layout="horizontal"
        @value-changed="identificadorCheckBoxChanged"
        v-model="selectedType"/>
      <DxTextArea
        id="textAreaIdentificacaoTransporte"
        v-show="showIdentificacaoTransporte"
        class="texAreaIrregularidade"
        placeholder="Ex: Nome da transportadora"
        v-model="identificacaoTransporte"/>
      <DxToast
        class="toastOcorrenciaTransporte"
        :displayTime="4000"
        :visible.sync="showToast"
        :type="toastType"
        :message="warningMessage"
        />
      <footer>
        <dx-button :disabled="saveDisableButtonStatus" class="saveTransporteButton" type="success" @click="save">Salvar</dx-button>
      </footer>
    </div>
  </div>

</template>
<script>

import DxButton from 'devextreme-vue/button'

import { DxLookup } from 'devextreme-vue/lookup'
import { doRequest } from '@/pluggables/http'
import { DxTextArea } from 'devextreme-vue'
import DxRadioGroup from 'devextreme-vue/radio-group'
import ModelFileUploader from '@/components/Form/ModelFileUploader'
import { DxToast } from 'devextreme-vue/toast'
import DataSource from 'devextreme/data/data_source'
import DxSelectBox from 'devextreme-vue/select-box'
import * as esriGeocode from 'esri-leaflet-geocoder'

export default {
  name: 'popup-cacamba-irregular',
  components: {
    DxButton,
    DxTextArea,
    DxRadioGroup,
    ModelFileUploader,
    DxToast,
    DxSelectBox,
    DxLookup
  },
  props: {
    marker: {
      type: Object,
      required: true
    },
    userData: {
      type: Object,
      required: true
    },
    find: {
      type: Function,
      required: true
    },
    setLoading: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      address: null,
      toastType: null,
      saveDisableButtonStatus: true,
      fieldsBind: {
        veiculo: {
          dataSource: new DataSource({
            store: {
              type: 'array',
              key: 'id',
              data: []
            }
          })
        }
      },
      local: null,
      warningMessage: null,
      showToast: false,
      maskRule: {
        H: /[a-z]/i
      },
      showExisteVerificadorRadioBox: false,
      placa: null,
      fotoTransporte: [],
      showIdentificacaoTransporte: null,
      identificacaoTransporte: null,
      showNumeroIdentificacao: null,
      numeroIdentificacao: null,
      selectedType: null,
      typeSelection: ['Sim', 'Não'],
      irregularidadeTextArea: null,
      idVeiculo: null,
      attachmentPreview: null
    }
  },
  computed: {
    style () {
      return {
        width: this.fieldSetWidth,
        height: this.fieldSetHeight
      }
    }
  },
  watch: {
    async fotoTransporte (value) {
      if (value && Array.isArray(value) && value[0]) {
        const toBase64 = file => new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = () => resolve(reader.result)
          reader.onerror = error => reject(error)
        })
        this.attachmentPreview = await toBase64(value[0])
      }
    },
    placa (value) {
      if (value.static) {
        this.showExisteVerificadorRadioBox = true
        this.idVeiculo = null
      } else if (!value.static) {
        this.saveDisableButtonStatus = false
        this.idVeiculo = value.id
        this.identificacaoTransporte = null
        this.showExisteVerificadorRadioBox = false
      }
    }
  },
  methods: {
    dxSelectBoxInput (value) {
      this.saveDisableButtonStatus = true
    },
    async onInsertCustomValue (value) {
      this.saveDisableButtonStatus = true
      const nextId = Math.max.apply(Math, this.fieldsBind.veiculo.dataSource.items().map(function (c) { return c.id })) + 1
      const newItem = { id: nextId, placa: value.text, static: true }
      this.fieldsBind.veiculo.dataSource.store().insert(newItem)
      this.fieldsBind.veiculo.dataSource.reload()
      this.placa = newItem
      await this.$nextTick(() => {
        this.$refs.selectBoxPlaca.instance.option('value', newItem)
      })
      this.saveDisableButtonStatus = false
      return newItem
    },
    /**
     * Metodo para obter o geojson da visualizacao atual do mapa.
     * @returns {Object} O geoJson da visualizacao atual do mapa.
     */
    getBoundsGeoJson (latlong) {
      return {
        type: 'Point',
        coordinates: [latlong.lng, latlong.lat],
        srid: '4326',
        crs: { type: 'name', properties: { name: 'EPSG:4326' } }
      }
    },
    async getReverseGeocode () {
      const reverseGeocode = esriGeocode.reverseGeocode().latlng([this.marker._latlng.lat, this.marker._latlng.lng])
      const promise = new Promise((resolve, reject) => {
        reverseGeocode.run((error, result) => {
          if (error) {
            reject(error)
          }
          resolve((result))
        })
      })
      return await promise
    },
    identificadorCheckBoxChanged (payload) {
      if (payload.value === 'Sim') {
        this.showNumeroIdentificacao = false
        this.numeroIdentificacao = null
        this.showIdentificacaoTransporte = true
      }
      if (payload.value === 'Não') {
        this.showNumeroIdentificacao = true
        this.identificacaoTransporte = null
        this.showIdentificacaoTransporte = false
      }
    },
    async save () {
      this.setLoading(true)
      const formData = new FormData()
      const file = (this.fotoTransporte && this.fotoTransporte[0]) || null
      file !== null && formData.set('foto', file)
      if (this.identificacaoTransporte) {
        formData.set('identificacaoVeiculo', this.identificacaoTransporte)
      }
      if (this.idVeiculo) {
        formData.set('idVeiculo', this.idVeiculo)
      }
      formData.set('local', this.address)
      formData.set('irregularidadeCom', 'Transporte')
      formData.set('descricaoIrregularidade', this.irregularidadeTextArea)
      formData.set('placaVeiculo', this.placa.placa)
      formData.set('shape', JSON.stringify(this.getBoundsGeoJson(this.marker._latlng)))
      formData.set('idUsuarioRegistro', this.userData.id)
      let result
      if (this.fotoTransporte.length === 0) {
        this.showToast = true
        this.toastType = 'error'
        this.warningMessage = 'É obrigatório anexar uma foto para a ocorrência'
        this.setLoading(false)
        throw new Error('Anexo inválido')
      }
      if (!this.irregularidadeTextArea) {
        this.showToast = true
        this.toastType = 'error'
        this.warningMessage = 'Insira uma descrição para a irregularidade'
        this.setLoading(false)
        throw new Error('Descrição Inválida')
      }
      if (!this.placa) {
        this.showToast = true
        this.toastType = 'error'
        this.warningMessage = 'Selecione uma placa para o transporte'
        this.setLoading(false)
        throw new Error('Placa Inválida')
      }
      if (!this.selectedType && !this.idVeiculo) {
        this.showToast = true
        this.toastType = 'error'
        this.warningMessage = 'Selecione se há um identificador no veículo'
        this.setLoading(false)
        throw new Error('Tipo identificador inválido')
      }
      if (this.selectedType === 'Sim' && !this.identificacaoTransporte) {
        this.showToast = true
        this.toastType = 'error'
        this.warningMessage = 'O campo de identificador do veículo é obrigatório'
        this.setLoading(false)
        throw new Error('Campo identificador inválido')
      }
      try {
        result = await doRequest({
          method: 'post',
          url: 'Ocorrencia',
          params: formData,
          options: {
            cleanObject: false,
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        })
      } catch (err) {
        throw new Error(err)
      }
      if (!result.success) {
        this.idCacamba = null
        this.showToast = true
        this.toastType = 'error'
        this.warningMessage = 'Erro ao salvar caçamba'
      }
      if (result.success) {
        this.$emit('removeMarker', this.marker)
        this.showToast = true
        this.toastType = 'success'
        this.warningMessage = 'Ocorrência salva'
        this.$emit('taskSaved')
      }
      this.setLoading(false)
    }
  },
  async mounted () {
    const findPlacas = await this.find({
      entity: 'Veiculo',
      params: {
        fields: ['id', 'placa']
      }
    })
    this.fieldsBind.veiculo.dataSource.store()._array = findPlacas.data
    const getReverseGeocode = await this.getReverseGeocode()
    this.address = getReverseGeocode.address.Match_addr
  }
}
</script>

<style scoped lang="scss">
.thumbnail {
  max-width:450px;
  max-height:150px;
  width: auto;
  height: auto;
  border-radius: 15px;
}
//   @import "../../../../global.scss";
// .transporteIrregularIrregularidade {
//   width: 340px;
//   height: 110px;
//   @media only screen and (max-width: 600px) {
//     width: 193px;
//     height: 110px;
//   }
// }
// .transporteFieldSet {
//   width: 362px;
//   height: 367px;
//   overflow: auto;
//   @media only screen and (max-width: 600px) {
//     width: 250px;
//   }
// }
// .fileUploader {
//     width: 280px;
//     @media only screen and (max-width: 600px) {
//       width: 150px;
//     }
//   }
// .leaflet-popup-content-wrapper {
//   @media only screen and (max-width: 600px) {
//     width: 283px;
//     height: 460px;
//   }
//     width: 400px;
//   }
// .saveTransporteButton {
//     display: table;
//     left: 275px;
//     position: relative;
//     top: 6px;
//     @media only screen and (max-width: 600px) {
//       display: table;
//       left: 150px;
//       position: relative;
//       top: 6px;
//     }
//   }
</style>
