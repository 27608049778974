<template>
  <div class="grid-tipo-residuos">
    <template v-if="!readOnly">
      <div class="flex">
        <div class="flex-1">
          <div class="dx-field">
            <div class="dx-field-label-location-top">Tipo de Residuo</div>
            <div class="flex-2 field-select-tipo-residuo">
              <dx-select-box
                v-bind="selectBoxOptions"
                v-model="tipoResiduo.add.selected"
              />
            </div>
          </div>
        </div>
        <div class="flex-1">
          <text-field
            v-model="tipoResiduo.add.volume"
            label="Volume em m³"
            :options="volumeFieldOptions"
            type="number"
            required/>
        </div>
        <div class="add-button">
          <dx-button
            type="success"
            @click="onClickButtonAddTipoResiduo">
            Adicionar
          </dx-button>
        </div>
      </div>
      <div class="flex">
        <message-error class="width-100" :message.sync="errorMessageTipoResiduo"/>
      </div>
    </template>
    <div class="flex">
      <simple-grid
        ref="gridRef"
        :deletable="false"
        :editable="false"
        entity="TipoResiduo"
        :grid-data="gridData"/>
    </div>
  </div>
</template>
<script>
import DxButton from 'devextreme-vue/button'

import SimpleGrid from '../SimpleGrid'
import TextField from '../Form/TextField'
import MessageError from '../MessageError'
import { listClassesMateriais } from './service'
import { getResiduosToGrid, getResiduosFromGrid } from './gridTipoResiduosUtil'
import CustomStore from 'devextreme/data/custom_store'
import { getParamsFromLoadOptions } from '@/util/loadOptionsParams'
import DxSelectBox from 'devextreme-vue/select-box'
import { http } from '@/pluggables/http'
import config from 'config'
import { confirm } from 'devextreme/ui/dialog'

export default {
  name: 'grid-tipo-residuos',
  components: {
    TextField,
    SimpleGrid,
    MessageError,
    DxButton,
    DxSelectBox
  },
  props: {
    /**
     * Se deve apresentar o campo de email.
     */
    showEmail: {
      type: Boolean,
      default: false
    },

    /**
     * Se deve apresentar o campo de velular.
     */
    showCelular: {
      type: Boolean,
      default: false
    },

    /**
     * O objeto com o valor do grid. a props do objeto e 'residuos'.
     */
    value: {
      type: Object,
      required: true
    },

    /**
     * Se os campos de pessoa estao validos, essa prop e mudada em tempo real.
     */
    isValid: {
      type: Boolean,
      default: false
    },

    /**
     * Se o grid e somente para leitura.
     */
    readOnly: {
      type: Boolean,
      default: false
    },
    /**
     * Se deve ou não usar o where do componente model-select
     * caso seja true sempre passa a requisição sem nenhum where, trazendo todos os dados.
     */
    disableWhere: {
      type: Boolean,
      default: false
    }
  },

  /**
   * Metodo do vue para obter o estado inicial do componente.
   * @returns {Object} O estado inicial do componente.
   */
  data () {
    return {
      tipoResiduo: {
        add: {
          selected: null,
          volume: null
        }
      },
      residuosSelecteds: [],
      errorMessageTipoResiduo: null,
      gridData: {
        value: {
          visible: true
        },
        columns: [
          { dataField: 'classe', caption: 'Classe de material', width: '25%' },
          { dataField: 'residuo', caption: 'Material - Tipo de resíduo', width: '25%' },
          { dataField: 'volume', caption: 'Volume em m³', width: '20%' },
          {
            type: 'buttons',
            width: 280,
            showInColumnChooser: false,
            buttons: [
              {
                cssClass: 'dx-button mdi mdi-delete',
                text: 'Remover Item',
                visible: !this.readOnly,
                onClick: ({ row: { data } }) => {
                  const index = this.gridData.dataSource.indexOf(this.gridData.dataSource.find(classeMaterial => classeMaterial.data.classeMaterial.id === data.data.classeMaterial.id))
                  if (index >= 0) {
                    this.gridData.dataSource.splice(index, 1)
                  }
                  this.tipoResiduos = this.gridData.dataSource
                  const allEqual = this.tipoResiduos.every((tipoResiduo, i, arr) => tipoResiduo.data.residuo.codigo === arr[0].data.residuo.codigo)
                  if (allEqual) this.$emit('selectMista', null)
                  this.$refs.gridRef.$refs.gridInstance.instance.refresh()
                }
              }
            ]
          }
        ],
        dataSource: [],
        keyExpr: 'data.residuo.id'
      },
      volumeFieldOptions: {
        min: 0
      },
      selectBoxOptions: {
        allowClearing: true,
        showClearButton: true,
        dataSource: new CustomStore({
          key: 'id',
          load: loadOptions => {
            return this.$utils.wrapRequestForGrid(this._doClasseMaterialRequest(getParamsFromLoadOptions(loadOptions)))
          },
          byKey: key => key
        }),
        displayExpr: 'nome',
        grouped: true,
        label: 'Classe de Material ou Material - Tipo de Residuo',
        minSearchLength: 0,
        openOnFieldClick: true
      }
    }
  },

  computed: {
    /**
     * Valor computado da lista de tipos de residuos do grid.
     */
    tipoResiduos: {
      get () {
        return getResiduosToGrid(this.value && this.value.residuos)
      },
      set (residuos) {
        this.$emit('input', {
          ...this.value,
          residuos: getResiduosFromGrid(residuos)
        })
      }
    }
  },

  watch: {
    async '$parent.value' (value) {
      const residuos = value.residuos
      if (residuos && residuos.length) {
        if (residuos.length > 1) {
          const residuo = {
            classe: 'CLASSE MISTA',
            residuo: 'Resíduo de Construção Civil - Não Segregado',
            residuos: 'Resíduo de Construção Civil - Não Segregado',
            data: {
              residuo: {
                id: 5,
                idClasseMaterial: 6,
                nome: 'Resíduo de Construção Civil - Não Segregado',
                descricao: 'Resíduo de Construção Civil - Não Segregado',
                codigo: '62'
              },
              classeMaterial: {
                id: 6,
                classe: 'CLASSE MISTA',
                descricao: 'Engloba todo tipo de material',
                classificacaoSlu: 'MISTA',
                tipoResiduos: [
                  {
                    id: 5,
                    idClasseMaterial: 6,
                    nome: 'Resíduo de Construção Civil - Não Segregado',
                    descricao: 'Resíduo de Construção Civil - Não Segregado',
                    codigo: '62'
                  }
                ]
              }
            }
          }
          this.$emit('selectMista', residuo)
          this.value.tipoResiduoPredominante = { idTipoResiduo: 5 }
        }
      }
    },
    tipoResiduos: {
      immediate: true,
      /**
       * Metodo de callback de quando ocorre mudancas no valor da prop
       * 'tipoResiduos', com o objetivo de definir a lista de tipo de residuos do
       * datasource do grid.
       * @param {Object[]} tipoResiduos - Alista de tipos de residuos atual.
       */
      async handler (tipoResiduos) {
        this.gridData.dataSource = tipoResiduos
      }
    }
  },

  methods: {
    /**
     * Metodo de callback de quando e clicado no botao de adicionar um tipo de
     * residuo. Com o objetivo de adicionar um tipo de residuo na listagem de
     * tipos de residuos da obra.
     */
    async onClickButtonAddTipoResiduo () {
      this.errorMessageTipoResiduo = null

      const residuoSelected = this.tipoResiduo.add.selected
      const volume = this.tipoResiduo.add.volume

      if (!residuoSelected) {
        this.errorMessageTipoResiduo = 'Não foi escolhido um Tipo de Residuo.'
        return
      }

      if (!volume && volume !== 0) {
        this.errorMessageTipoResiduo = 'Não foi digitado o Volume.'
        return
      }

      const alreadyHasTipoResiduo = this.tipoResiduos.find(val => (val.data.idClasseMaterial | val.data.classeMaterial.id) === residuoSelected.idClasseMaterial)
      if (alreadyHasTipoResiduo) {
        this.errorMessageTipoResiduo = 'A Classe de Material ou Tipo de Resíduo já foi informada.'
        return
      }
      const classeMaterial = {
        ...this.allClassesMateriais.find(classe =>
          classe.id === residuoSelected.idClasseMaterial) || null
      }
      const residuo = {
        classe: classeMaterial.classe,
        residuo: residuoSelected.nome,
        residuos: classeMaterial
          ? classeMaterial.tipoResiduos.map(residuo => residuo.nome).join(', ')
          : '',
        volume,
        data: {
          residuo: residuoSelected,
          volume,
          classeMaterial
        }
      }
      this.tipoResiduo.add.selected = null
      this.tipoResiduo.add.volume = null
      this.confirmMista(residuo)
    },

    confirmMista (residuo) {
      if (this.tipoResiduos.find((value) => value.data.residuo.codigo !== residuo.data.residuo.codigo) && !this.$parent.isClasseMista) {
        const result = confirm('<i>Você está tentando pesar classes de materiais diferentes, será adotada a Classe Mista para todas as CTRs, deseja continuar?</i>', 'Confirmar')
        result.then((dialogResult) => {
          if (dialogResult) {
            let residuoMisto
            this.allClassesMateriais.forEach((classeMaterial) => {
              residuoMisto = classeMaterial.tipoResiduos.find(tipoResiduo => tipoResiduo.id === 5)
              if (residuoMisto) {
                this.$emit('selectMista', residuoMisto)
                this.tipoResiduos = this.tipoResiduos.concat(residuo)
              }
            })
          }
        })
      } else {
        this.tipoResiduos = this.tipoResiduos.concat(residuo)
      }
    },

    /**
     * Metodo para buscar a lista de tipos de residuos agrupados pelas classes
     * de materiais do servidor para o combo de classes de materiais.
     * @param {Object} params - Os parametros da requisicao.
     * @returns {Object[]} A lista de tipos de res.
     */
    async _doClasseMaterialRequest (params) {
      if (this.disableWhere) params.where = {}
      const result = await listClassesMateriais(params)
      if (result.success) {
        this.allClassesMateriais = result.data.rows || []
        return result.data.rows.map(classe => ({
          key: classe.classe,
          items: classe.tipoResiduos
        }))
      } else {
        throw new Error('Falha ao buscar a lista de transportadoras')
      }
    },

    async getListRequest (params) {
      const result = await http.post(`${config.baseUrl}/ClasseMaterial/find`, params)
      if (!result.data.success) {
        throw new Error(`Falha no retorno da requisicao de get ${this.entity}`)
      } else {
        return {
          data: result.data.data.rows || [],
          totalCount: result.data.data.count
        }
      }
    }
  }
}
</script>

<style lang="scss">
.grid-tipo-residuos {
  .flex {
    align-items: center;

    .add-button {
      margin: 24px 6px 6px 6px;
    }
  }

  .error-message-card {
    background-color: #B00020 !important;
    color: white !important;
  }
}
</style>
