<template>
  <div>
    <DxLoadPanel
      :visible.sync="loadingVisible"
      :show-indicator="true"
      :show-pane="true"
      :shading="true"
      message="Enviando Informações..."
      :hide-on-outside-click="false"
      shading-color="rgba(0,0,0,0.4)"
    />
    <div>
      <form>
        <DxForm ref="form">
          <DxGroupItem caption="Dúvidas ou Sugestões">
            <DxSimpleItem v-for="(field, index) in fields" :key="index" v-bind="field" />
          </DxGroupItem>
        </DxForm>
      </form>
    </div>
    <div ref="fileUploaderRef" class="fileuploader-container">
      <dx-file-uploader
        ref="fileUploaderInstance"
        selectButtonText="Adicionar documento(s)"
        :multiple="true"
        :showFileList="false"
        upload-mode="useForm"
        @value-changed="onAddAttachment"
      />
      <div v-if="attachments && attachments.length" style="padding: 20px">
        <div v-for="(attachment,index) in attachments" :key="index">
          <div style="display: flex; margin-top: 5px">
            <span style="margin-right: 12px">{{attachment.name}}</span>
            <span style="margin-right: 12px; font-style: italic">{{ ((attachment.size / (1024*1024)).toFixed(2)) }} MB</span>
            <span class="mdi mdi-download" style="margin-right: 6px; cursor: pointer" @click="onDownloadAttachment(attachment)"/>
            <span class="mdi mdi-delete" style="cursor: pointer" @click="onRemoveAttachment(attachment)"/>
          </div>
        </div>
      </div>
    </div>
    <dx-button
      style="width: 20%; place-self: end"
      text="Enviar"
      type="success"
      @click="sendReclamacao"/>
  </div>
</template>
<script>
import { DxLoadPanel } from 'devextreme-vue/load-panel'
import { DxFileUploader } from 'devextreme-vue/file-uploader'
import DxButton from 'devextreme-vue/button'
import DxForm, {
  DxSimpleItem,
  DxGroupItem
} from 'devextreme-vue/form'
import notify from 'devextreme/ui/notify'
import { http } from '@/pluggables/http'
import config from 'config'

const baseUrl = config.baseUrl
export default {
  name: 'form-usuario',
  components: {
    DxFileUploader,
    DxButton,
    DxSimpleItem,
    DxGroupItem,
    DxForm,
    DxLoadPanel
  },
  data () {
    return {
      fields: [
        {
          dataField: 'tipo',
          caption: 'Tipo',
          editorType: 'dxRadioGroup',
          validationRules: [{ type: 'required' }],
          editorOptions: {
            dataSource: [{ value: 'Dúvida', text: 'Dúvida' }, { value: 'Sugestão', text: 'Sugestão' }],
            valueExpr: 'value',
            layout: 'horizontal'
          }
        },
        {
          dataField: 'titulo',
          caption: 'Título',
          validationRules: [{ type: 'required' }],
          label: {
            text: 'Título',
            location: 'top'
          }
        },
        {
          dataField: 'reclamacao',
          caption: 'Texto',
          editorType: 'dxTextArea',
          validationRules: [{ type: 'required' }],
          label: {
            text: 'Texto',
            location: 'top'
          }
        }
      ],
      attachments: [],
      formData: {},
      loadingVisible: false
    }
  },
  methods: {
    onAddAttachment ({ value }) {
      if (!this.attachments) this.attachments = []
      if (this.attachments.length >= 5) {
        notify('Você atingiu o limite máximo de anexos', 'warning', 5000)
      } else {
        if (value && value.length) {
          if (!this.attachments.find(attach => attach.name === value[0].name && attach.size === value[0].size)) {
            this.attachments.push(value[0])
          }
        }
      }
    },
    async onDownloadAttachment  (attachment) {
      const buffer = await attachment.arrayBuffer()
      const fileBuffer = new Uint8Array(buffer)
      const blob = new Blob([fileBuffer], { type: attachment.type })
      const fileName = attachment.name
      const link = document.createElement('a')
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', fileName)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    },
    onRemoveAttachment (attachment) {
      const index = this.attachments.indexOf(attachment)
      this.attachments.splice(index, 1)
    },
    async sendReclamacao () {
      const isValid = this.$refs.form.instance.validate().isValid
      /* Remove Obrigatoriedade do anexos
      if (!this.attachments.length) {
        this.$refs.fileUploaderRef.classList.remove('fileuploader-container')
        this.$refs.fileUploaderRef.classList.add('fileuploader-container-invalid')
        isValid = false
      } else {
        if (!this.$refs.fileUploaderRef.classList.contains('fileuploader-container')) this.$refs.fileUploaderRef.classList.add('fileuploader-container')
      } */

      if (isValid) {
        const values = this.$refs.form.instance.option().formData
        const formData = new FormData()
        for (const [key, value] of Object.entries(values)) {
          formData.append(key, value)
        }
        for (const attachment of this.attachments) {
          formData.append('file', attachment, attachment.name)
        }
        try {
          this.loadingVisible = true
          await http.post(`${baseUrl}/reclamacao`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          this.loadingVisible = false
          notify(`${values.tipo} enviada com sucesso`, 'success', 5000)
          this.$refs.form.instance.resetValues()
          this.attachments = null
          this.$refs.fileUploaderInstance.instance.reset()
        } catch (err) {
          this.loadingVisible = false
          notify('Não foi possível enviar o formulário. Contacte o administrador pelo email, "atendimento.rcc@slu.df.gov.br"', 'error', 5000)
          console.error(err.message)
        }
      }
    }
  }
}
</script>
<style lang="scss">
.fileuploader-container-invalid {
  border: 1px solid #ffbebe;
  margin-bottom: 20px;
}
.form-usuario {
  display: flex;
  flex-direction: column;
  width: 100%;

  .form-actions {
    margin: 10px 0;
  }

  .form {
    width: 100%;

    > div {
      margin: 0 10px 10px 0;
    }

    .field {
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
