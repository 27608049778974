<template>
  <div class="generic-crud">
    <div class="md-layout">
      <div class="md-layout-item">
        <h2 class="crud-title">Consultar Capacidade Máxima versus o Volume Recebido pela ATT</h2>
      </div>
    </div>
    <simple-grid entity="ConsultaATT" :grid-data="gridData" :deletable="false" :editable="false" :viewable="canView"/>
  </div>
</template>
<script>
import { SimpleGrid } from '@/components'
import CustomStore from 'devextreme/data/custom_store'
import mixinCrud from '../mixin-crud'
import { mapActions } from 'vuex'
import { getParamsFromLoadOptions } from '../../../util/loadOptionsParams'
import { getByKeys } from '../../../util/getByKeys'

export default {
  name: 'ConsultaATT-crud',
  mixins: [
    mixinCrud
  ],
  components: {
    SimpleGrid
  },
  data () {
    return {
      entity: 'Perfil',

      formData: null,
      gridData: {
        value: {
          visible: true
        },
        columns: this.parseColumns([
          {
            dataField: 'solicitacoesTransporte.data_hora_solicitacao',
            caption: 'Periodo',
            calculateDisplayValue: () => {
              return '-'
            },
            format: 'dd/MM/yyyy',
            dataType: 'date',
            editorType: 'dxDateBox'
          },
          {
            dataField: 'pessoa.nome_razao_social',
            caption: 'Nome razao social',
            calculateDisplayValue: (data) => {
              return data.nome_razao_social
            },
            editorType: 'dxLookup',
            editorOptions: {
              allowClearing: true,
              showClearButton: true,
              acceptCustomValue: true,
              dataSource: new CustomStore({
                key: 'id',
                load: (loadOptions) => {
                  return this.$utils.wrapRequestForGrid(this.find({
                    entity: 'Pessoa',
                    params: {
                      fields: ['id', 'nome_razao_social'],
                      where: {
                        '$perfis.id_tipo_perfil$': 3
                      },
                      include: JSON.stringify([
                        {
                          attributes: ['id'],
                          required: true,
                          model: 'Perfil',
                          as: 'perfis'
                        }
                      ])
                    }
                  }))
                },
                byKey: async key => {
                  const res = await getByKeys({
                    urlModel: 'Pessoa',
                    where: {
                      nomeRazaoSocial: key
                    },
                    displayField: 'nomeRazaoSocial'
                  })
                  return res.totalCount > 0 ? res.data[0] : null
                }
              }),
              displayExpr: 'nomeRazaoSocial',
              valueExpr: 'nomeRazaoSocial'
            }
          },
          {
            dataField: 'pessoa.cpf_cnpj',
            caption: 'Cpf/cnpj',
            calculateDisplayValue: (data) => {
              return data.cpf_cnpj
            },
            editorType: 'dxLookup',
            editorOptions: {
              allowClearing: true,
              showClearButton: true,
              acceptCustomValue: true,
              dataSource: new CustomStore({
                key: 'id',
                load: (loadOptions) => {
                  return this.$utils.wrapRequestForGrid(this.find({
                    entity: 'Pessoa',
                    params: {
                      fields: ['id', 'cpf_cnpj'],
                      where: {
                        '$perfis.id_tipo_perfil$': 3
                      },
                      include: JSON.stringify([
                        {
                          attributes: ['id'],
                          required: true,
                          model: 'Perfil',
                          as: 'perfis'
                        }
                      ])
                    }
                  }))
                },
                byKey: async key => {
                  const res = await getByKeys({
                    urlModel: 'Pessoa',
                    where: {
                      cpfCnpj: key
                    },
                    displayField: 'cpfCnpj'
                  })
                  return res.totalCount > 0 ? res.data[0] : null
                }
              }),
              displayExpr: 'cpfCnpj',
              valueExpr: 'cpfCnpj'
            }

          },
          {
            dataField: 'capacidade_maxima_suportada',
            caption: 'Capacidade maxima em m³',
            allowFiltering: false
          },
          {
            dataField: 'volume_aproximado_m3',
            caption: 'Valor Recebido em m³',
            allowFiltering: false
          },
          {
            dataField: 'saldo',
            caption: 'Saldo',
            allowFiltering: false,
            calculateDisplayValue: (data) => {
              const saldo = parseFloat(data.capacidade_maxima_suportada) - parseFloat(data.volume_aproximado_m3 || 0)
              return !Number.isNaN(saldo) ? saldo : '-'
            }
          }
        ]),
        dataSource: new CustomStore({
          key: 'id',
          load: (loadOptions) => {
            return this.$utils.wrapRequestForGrid(this.findATTs(loadOptions))
          }
        })
      }

    }
  },
  methods: {
    /**
     * Metodo para obter o objeto com valores do grid
     * @param {Object} loadOptions - Os parametros de busca do datasource do
     * devextreme.
     * @returns {Object} Os parametros da requisicao.
     */
    async findATTs (loadOptions) {
      const params = getParamsFromLoadOptions(loadOptions, true)
      const result = await this.findAtt(params)
      if (!(params.format === 'xlsx')) {
        return { data: result.data.rows, totalCount: result.data.count }
      }
    },
    ...mapActions('Crud', ['findAtt'])
  }
}
</script>

<style scoped lang="scss">

</style>
