<template>
  <div>
    <div class="flex">
      <div class="flex-col-3">
        <div v-if="value.pessoa && value.pessoa.tipoPessoa === 'F'">
          CPF: <b>{{$utils.formatarCpf(value.pessoa.cpfCnpj)}}</b>
        </div>
        <div v-if="value.pessoa && value.pessoa.tipoPessoa === 'J'">
          CNPJ: <b>{{$utils.formatarCnpj(value.pessoa.cpfCnpj)}}</b>
        </div>
      </div>
      <div v-if="value.pessoa" class="flex-col-3">
        <span>Nome: </span>
        <b>{{value.pessoa.nomeRazaoSocial}}</b>
      </div>
    </div>
    <div style="padding: 10px;">
      <pessoa-search-field
        :is-valid.sync="pessoaIsValid"
        :read-only="readOnly"
        :value="value"
        :ocultar-campos="['cpfCnpjSelect', 'cpfCnpj', 'nomeRazaoSocial']"
        @input="onChangedPessoa"/>

      <dx-form
        :items="obraColumns"
        :form-data="value"
        label-location="top"
        :read-only="readOnly"
        ref="crudForm"
        @field-data-changed="onFieldValueChangeCallback"/>

      <span class="dx-form-group-caption">Endereço da Obra</span>

      <address-fields
        class="address-group"
        :is-valid.sync="addressIsValid"
        :read-only="readOnly"
        :value="value"
        @input="onChangedAddress"/>

      <message-error :message.sync="errorMessage" />

      <md-button
        class="md-raised md-accent next-button"
        @click="onClickNext">
        {{ readOnly ? 'Próximo' : 'Continuar' }}
      </md-button>
      <md-button
        class="md-raised md-primary next-button"
        @click="onClickCancel">
        {{ readOnly ? 'Voltar' : 'Cancelar' }}
      </md-button>
    </div>
  </div>
</template>

<script>
import DxForm from 'devextreme-vue/form'
import _ from 'lodash'
import { mapActions } from 'vuex'
import store from '@/store'
import ObraData from './ObraData'
import { listOnlyObra } from '../service'
import MessageError from '../../../../components/MessageError'
import PessoaSearchField from '../../../../components/Obra/PessoaSearchField'
import AddressFields from '../../../../components/Form/Address/AddressFields'
import notify from 'devextreme/ui/notify'

export default {
  name: 'maintain-step-obra',
  components: {
    MessageError,
    PessoaSearchField,
    DxForm,
    AddressFields
  },
  props: {
    /**
     * A obra que esta sendo esditada.
     */
    value: {
      type: Object,
      required: true
    },

    /**
     * Se os dados da obra sao somente para leitura.
     */
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    pessoaLogada () {
      return store.state?.Login?.user?.pessoa || {}
    }
  },
  /**
   * Metodo do vue para obter o estado inicial do componente.
   * @returns {Object} O estado inicial do componente.
   */
  data () {
    const hasMotivoAlteracao = 'id' in this.$route.params

    return {
      currentDataInicialValue: null,
      obraColumns: [
        {
          itemType: 'group',
          colCount: 2,
          name: 'obra',
          items: [
            {
              dataField: 'id',
              visible: false,
              colSpan: 2,
              caption: 'id'
            },
            {
              dataField: 'motivoAlteracao',
              visible: hasMotivoAlteracao,
              isRequired: hasMotivoAlteracao,
              colSpan: 2,
              label: {
                text: 'Motivo da Alteração dos Dados da Obra'
              }
            },
            {
              dataField: 'nomeObra',
              isRequired: true,
              colSpan: 2
            },
            {
              dataField: 'duracaoInicial',
              editorType: 'dxDateBox',
              isRequired: true,
              colSpan: 1,
              label: {
                text: 'Duração Inicial'
              },
              editorOptions: {
                min: Date.now(),
                onValueChanged: (data) => {
                  this.currentDataInicialValue = data.value
                }
              }
            },
            {
              dataField: 'duracaoFinal',
              editorType: 'dxDateBox',
              isRequired: true,
              colSpan: 1,
              label: {
                text: 'Duração Final'
              },
              editorOptions: {
                onValueChanged: (data) => {
                  if (data.value.getTime() < this.currentDataInicialValue) {
                    this.$refs.crudForm.instance.updateData('duracaoFinal', this.currentDataInicialValue)
                    notify('A data de duração final não pode ser anterior que data de duração inicial', 'warning', 4000)
                  }
                }
              }
            },
            {
              dataField: 'possuiAlvara',
              name: 'possuiAlvara',
              editorType: 'dxRadioGroup',
              isRequired: true,
              editorOptions: {
                layout: 'horizontal',
                items: [
                  { text: 'Sim', value: true },
                  { text: 'Não', value: false }
                ]
              },
              colSpan: 1,
              label: {
                text: 'Possui Alvará'
              }
            },
            {
              dataField: 'numeroAlvara',
              name: 'numeroAlvara',
              colSpan: 1,
              visible: false,
              label: {
                text: 'Número Alvará'
              },
              validationRules: [
                {
                  type: 'custom',
                  validationCallback ({ formItem, value }) {
                    return !formItem.visible ||
                      (formItem.visible && value && value.length)
                  }
                }
              ]
            }
          ],
          label: {
            visible: false
          }
        }
      ],
      inputEmitted: false,
      errorMessage: null,
      onFieldValueChangeCallback: _.debounce(this.onFieldValueChange, 200),
      pessoaIsValid: false,
      addressIsValid: false
    }
  },
  /**
   * Metodo do ciclo de vida do vue de quando o componente foi criado. Com o
   * objetivo de inicializar os valores dos campos de select do form.
   */
  created () {
    const numeroAlvaraData = this.obraColumns[0].items[6]

    numeroAlvaraData.visible = this.value.possuiAlvara || false

    const possuiAlvaraData = this.obraColumns[0].items[5]

    if (possuiAlvaraData.dataField !== 'possuiAlvara') {
      throw new Error('Não foi encontrado a config da coluna de PossuiAlvara')
    }

    this.value.possuiAlvara = possuiAlvaraData.editorOptions.items.find(item =>
      item.value === this.value.possuiAlvara) || null
  },
  methods: {
    /**
     * Metodo de callback de quando foi clicado no botao de continuar. Com o
     * objetivo de avancar para a proxima tela se o formulario estiver valido.
     */
    async onClickCancel () {
      this.$emit('cancel')
    },
    /**
     * Metodo de callback de quando foi clicado no botao de continuar. Com o
     * objetivo de avancar para a proxima tela se o formulario estiver valido.
     */
    async onClickNext () {
      if (this.readOnly) {
        this.$emit('next')
      } else if (await this._validateForm()) {
        const newValue = {
          ...this.value,
          ...this.$refs.crudForm.formData
        }

        if (typeof newValue.municipio === 'object') {
          newValue.municipio = newValue.municipio.nome
        }

        await this._emitInput(newValue)
        this.$emit('next')
      }
    },

    /**
     * Metodo de callback de quando ocorre mudanca no valor de algum campo do
     * formulario.
     * @param {Object} evt - Os dados do evento de mudanca.
     * @param {string} evt.dataField - O campo que foi modificado.
     * @param {*} evt.value - O novo valor do campo.
     */
    onFieldValueChange ({ dataField, value }) {
      if (!this.inputEmitted) {
        const fn = {
          possuiAlvara: this._onChangePossuiAlvara
        }[dataField]

        fn && fn(value)
        this.$emit('change-field-value')
      }
    },

    onChangedPessoa (value) {
      this.$emit('input', value)
    },

    onChangedAddress (value) {
      this.$emit('input', value)
    },

    /**
     * Metodo de callback de quando ocorre mudancas no campo de select de
     * possuiAlvara. Com o objetivo de mostrar o campo de numero de alvara.
     * @param {Object} value - O novo valor do campo de select de possuiAlvara.
     */
    _onChangePossuiAlvara (value) {
      this.$refs.crudForm.instance.itemOption('obra.numeroAlvara', 'visible', value.value)
    },

    /**
     * Metodo de comando, com o objetivo de emitir o evento de 'input' e
     * atualizar a flag de 'inputEmitted' para evitar chamar o callback
     * 'onFieldValueChange'.
     * @param {Object} value - O valor a ser emitido no evento.
     */
    async _emitInput (value) {
      this.inputEmitted = true
      this.$emit('input', new ObraData(value))
      await this.$nextTick()
      this.inputEmitted = false
    },

    /**
     * Metodo de comando, com o objetivo de validar o formulario.
     * @returns {boolean} Se o formulario esta valido.
     */
    async _validateForm () {
      this.errorMessage = null

      const formResult = this.$refs.crudForm.instance.validate()
      if (!formResult.isValid) {
        this.errorMessage = 'Alguns campos obrigatórios não foram preenchidos.'
        return false
      }

      const { formData } = this.$refs.crudForm

      if (this.value.id === null && (await this._hasObraWithName(formData.nomeObra))) {
        this.errorMessage = `A obra ${formData.nomeObra} informada já está cadastrada.`
        return false
      }

      if (formData.duracaoInicial.getTime() > formData.duracaoFinal.getTime()) {
        this.errorMessage = 'A data final informada não pode ser anterior à data inicial.'
        return false
      }

      if (!this.value.shape) {
        this.errorMessage = 'Não foi encontrado o endereço digitado, por favor tente outro.'
        return false
      }

      if (!this.addressIsValid) {
        this.errorMessage = 'Alguns campos obrigatórios do endereço não foram preenchidos.'
        return false
      }

      return true
    },

    /**
     * Metodo de verificacao se ha uma obra ja cadastrada com um nome.
     * @param {string} name - O nome da obra a ser verificada.
     * @returns {boolean} Se exite uma obra com esse nome.
     */
    async _hasObraWithName (name) {
      const res = await listOnlyObra({
        fields: ['id'],
        where: { nomeObra: name },
        limit: 1
      })
      return res.data.count > 0
    },

    ...mapActions('Crud', ['find', 'findId'])
  }
}
</script>

<style lang="scss" scoped>
.dx-form-group-caption {
  font-family: Montserrat, sans-serif;
}

.address-group {
  border-top: 1px solid #ddd;
  padding-top: 19px;
  margin-top: 6px;
}

.next-button {
  float: right;
}

.error-message-card {
  background-color: #B00020 !important;
  color: white !important;
}
</style>
