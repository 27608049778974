import { getParamsFromLoadOptions } from '../../../util/loadOptionsParams'
import { doRequest } from '../../../pluggables/http'
import storeHelper from '@/helper/storeHelper'

/**
 * Metodo de comando, com o objetivo de realizar a requisica de obter uma lista
 * de obras com a lista de pessoas relacionadas a elas.
 * @param {Object} params - Os parametros da requisicao.
 * @param {Object} [params.where={}] - Os parametros de where da busca.
 * @param {Object} [params.loadOptions=null] - Os parametros de busca do
 * datasource do devextreme.
 * @returns {Object} O resultado da requisicao.
 */
export async function getPessoaGeradorObra ({ where = {}, loadOptions = null }) {
  const params = loadOptions !== null ? getParamsFromLoadOptions(loadOptions) : {}
  params.where = {
    ...params.where,
    ...where,
    '$perfis->obras->solicitacaoTransporte.nao_solicitado$': 'S'
  }
  params.include = [{
    model: 'Perfil',
    as: 'perfis',
    attributes: [],
    include: {
      model: 'Obra',
      as: 'obras',
      attributes: [],
      include: {
        model: 'SolicitacaoTransporte',
        as: 'solicitacaoTransporte',
        attributes: []
      }
    }
  }]
  params.fields = ['id', 'nomeRazaoSocial']

  const res = await doRequest({
    method: 'post',
    url: 'Pessoa/find',
    params: {
      ...params,
      subQuery: false
    }
  })

  if (!res.success) {
    throw new Error(res.error)
  }

  return { data: res.data.rows, totalCount: res.data.count }
}

/**
 * Metodo de comando, com o objetivo de realizar a requisica de obter uma lista
 * de obras.
 * @param {Object} params - Os parametros da requisicao.
 * @param {Object} [params.where={}] - Os parametros de where da busca.
 * @param {Object} [params.loadOptions=null] - Os parametros de busca do
 * datasource do devextreme.
 * @returns {Object} O resultado da requisicao.
 */
export async function getObras ({ where = {}, loadOptions = null }) {
  const params = loadOptions !== null ? getParamsFromLoadOptions(loadOptions) : {}
  params.where = {
    ...params.where,
    ...where,
    '$solicitacaoTransporte.nao_solicitado$': 'S'
  }
  params.include = [{
    model: 'SolicitacaoTransporte',
    as: 'solicitacaoTransporte',
    attributes: []
  }]
  params.fields = ['id', 'endereco', 'cep']

  const res = await doRequest({
    method: 'post',
    url: 'Obra/find',
    params: {
      ...params,
      subQuery: false
    }
  })

  if (!res.success) {
    throw new Error(res.error)
  }

  return { data: res.data.rows, totalCount: res.data.count }
}

/**
 * Metodo de comando, com o objetivo de realizar a requisica de obter uma lista
 * de tipos de residuos.
 * @param {Object} params - Os parametros da requisicao.
 * @param {Object} [params.where={}] - Os parametros de where da busca.
 * @param {Object} [params.loadOptions=null] - Os parametros de busca do
 * datasource do devextreme.
 * @returns {Object} O resultado da requisicao.
 */
export async function getResiduosPredominantes ({ where = {}, loadOptions = null }) {
  const params = loadOptions !== null ? getParamsFromLoadOptions(loadOptions) : {}
  params.where = {
    ...params.where,
    ...where
    // naoSolicitado: 'S'
  }
  params.include = [
    {
      model: 'ClasseMaterial',
      as: 'classeMaterial',
      attributes: ['classe']
    },
    {
      model: 'SolicitacaoTransporte',
      as: 'solicitacoesTransporte',
      attributes: [],
      duplicating: false
    }
  ]

  const res = await doRequest({
    method: 'post',
    url: 'TipoResiduo/find',
    params
  })

  if (!res.success) {
    throw new Error(res.error)
  }

  return { data: res.data.rows, totalCount: res.data.count }
}

/**
 * Metodo de comando, com o objetivo de realizar a requisica de obter uma lista
 * de solicitações de transporte com a situacao 'Solicitada'.
 * @param {Object} loadOptions - Os parametros de busca do
 * datasource do devextreme.
 * @param {Boolean} [loadOnlyCurrentTransportador] Flag que estabelece se apenas as solicitações do transportador logado devem retornar
 * @returns {Object} O resultado da requisicao.
 */
export async function getSolicitacoesTransporteSolicitados (loadOptions, loadOnlyCurrentTransportador = true) {
  const params = getParamsFromLoadOptions(loadOptions)
  params.order = params.order ? params.order : JSON.stringify([['id', 'DESC']])

  if (loadOnlyCurrentTransportador) {
    const transportadores = storeHelper.getCurrentPerfisTransportador()

    params.where = {
      ...params.where,
      idTransportador: (transportadores[0] || {}).id
    }
  }

  const res = await doRequest({
    method: 'post',
    url: 'ViewSolicitacaoTransporte/find',
    params
  })

  if (!res.success) {
    throw new Error(res.error)
  }

  return { data: res.data.rows, totalCount: res.data.count }
}

/**
 * Metodo de comando, com o objetivo de realizar a requisica de obter uma lista
 * de cacambas disponiveis.
 * @param {Object} params - Os parametros da requisicao.
 * datasource do devextreme.
 * @returns {Object} O resultado da requisicao.
 */
export async function getAvailableCacambas (params) {
  const defaultWhere = {
    or: [
      { '$solicitacoesTransporte.id_cacamba$': null },
      {
        '$solicitacoesTransporte.situacao$': {
          in: ['Destinada', 'Recusada', 'Cancelada', 'Resíduo Rejeitado']
        }
      }
    ]
  }

  const res = await doRequest({
    method: 'post',
    url: 'Cacamba/find',
    params: {
      subQuery: false,
      fields: ['id', 'numeroIdentificacao'],
      where: {
        or: [
          params.where,
          {
            and: [
              { ...params.where, id: undefined },
              defaultWhere
            ]
          }
        ]
      },
      include: [
        {
          model: 'SolicitacaoTransporte',
          as: 'solicitacoesTransporte',
          required: false
        },
        {
          model: 'Perfil',
          as: 'perfil',
          attributes: [],
          required: true
        }
      ]
    }
  })

  if (!res.success) {
    throw new Error(res.error)
  }

  return { data: res.data.rows, totalCount: res.data.count }
}

/**
 * Metodo de comando, com o objetivo de realizar a requisica de obter uma lista
 * de veiculos disponiveis.
 * @param {Object} params - Os parametros da requisicao.
 * datasource do devextreme.
 * @returns {Object} O resultado da requisicao.
 */
export async function getAvailableVeiculos (params) {
  const defaultWhere = {
    or: [
      { '$solicitacoesTransporte.id_veiculo$': null },
      {
        '$solicitacoesTransporte.situacao$': {
          in: ['Destinada', 'Recusada', 'Cancelada', 'Resíduo Rejeitado']
        }
      }
    ]
  }

  const res = await doRequest({
    method: 'post',
    url: 'Veiculo/find',
    params: {
      subQuery: false,
      where: {
        or: [
          params.where,
          {
            and: [
              { ...params.where, id: undefined },
              defaultWhere
            ]
          }
        ]
      },
      include: [
        {
          model: 'SolicitacaoTransporte',
          as: 'solicitacoesTransporte',
          required: false
        },
        {
          model: 'Perfil',
          as: 'perfil',
          attributes: [],
          required: true
        }
      ],
      fields: ['id', 'placa', 'tipo', 'poliguindaste']
    }
  })

  if (!res.success) {
    throw new Error(res.error)
  }

  return { data: res.data.rows, totalCount: res.data.count }
}

/**
 * Metodo de comando, com o objetivo de realizar a requisicao de salvar uma
 * solicitacao de transporte nao solicitado.
 * @param {Object} params - Os parametros da requisicao.
 * @returns {Object} O resultado da requisicao.
 */
export function createSolicitacaoTransporteNaoSolicitado (params) {
  return doRequest({
    method: 'post',
    url: 'enviar_transporte_nao_solicitado',
    params
  })
}
