<template>
  <dx-form
    class="simple-form"
    :form-data="value"
    :items="formFields"
    :disabled="disabled"
    ref="dxForm"
    label-location="top"
    v-bind="formOptions"></dx-form>
</template>
<script>
import { DxForm } from 'devextreme-vue'

export default {
  name: 'simple-form',
  components: {
    DxForm
  },
  props: {
    formOptions: {
      type: Object,
      default: () => {
        return {
          colCount: 3
        }
      }
    },
    value: {
      type: Object,
      default: () => {
        return {}
      }
    },
    fields: {
      type: Array,
      default: () => {
        return []
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      formFields: [],
      instance: {}
    }
  },
  watch: {
    fields: '_setFormFields'
  },
  mounted () {
    this._setFormFields()
    this.instance = this.$refs.dxForm.instance
    this.$emit('formContentReady', this.instance)
  },
  methods: {
    _setFormFields () {
      this.formFields = []
      for (const col of this.fields) {
        let field = col
        if (col.formItem) {
          field = {
            ...col,
            ...col.formItem
          }
        }
        this.formFields.push(field)
      }
    }
  }
}
</script>
