<template>
  <div class="ocorrencia-details">
    <h2>Visualizar Resposta da Ocorrência</h2>
    <div class="cols-popup">
      <div class="col-popup">
        <span>{{values.numeroIdentificacao}}</span>
        <dl>
          <dt>Nº DA OCORRÊNCIA:</dt> <dd>{{values.id}}</dd>
          <dt>Nº DE IDENTIFICAÇÂO:</dt> <dd>{{values.numeroIdentificacao}}</dd>
          <dt>DATA/HORA DO REGISTRO DA OCORRÊNCIA: </dt><dd>{{ dataHoraRegistro }}</dd>
          <dt>LOCAL DA OCORRÊNCIA: </dt><dd>{{ values.local }}</dd>
          <dt>IRREGULARIDADE COM: </dt><dd>{{ values.irregularidadeCom }}</dd>
          <template v-if="values.cacamba">
            <dt>IDENTIFICADOR NA CAÇAMBA: </dt><dd>{{ values.cacamba.numeroIdentificacao }}</dd>
          </template>
          <template v-if="values.veiculo">
            <dt>IDENTIFICADOR NO VEÍCULO: </dt><dd>{{ values.veiculo.numeroIdentificacao }}</dd>
            <dt>PLACA DO VEÍCULO: </dt><dd>{{ values.veiculo.placa }}</dd>
          </template>
          <dt>DESCRIÇÃO DA IRREGULARIDADE: </dt><dd><blockquote>{{ values.descricaoIrregularidade }}</blockquote></dd>
          <dt>RESPOSTA DO ÓRGÃO FISCALIZADOR - {{ orgao }}: </dt>
          <dd v-if="values.respostaOrgao"><blockquote>{{ values.respostaOrgao }}</blockquote></dd>
            <dd v-else><em>Aguardando resposta</em></dd>
        </dl>
        <hr>
      </div>

      <div class="col-popup">
        <img class="transporteCacambaImageCompact" v-bind:src="'data:image/jpeg;base64,'+image"/>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'

export default {
  name: 'view-ocorrencia',
  /**
   * Metodo do vue, para obter o estado inicial do componente.
   * @returns {Object} O estado inicial do componente.
   */
  data () {
    return {
      dataHoraRegistro: null,
      orgao: null,
      image: null
    }
  },
  props: {
    values: {
      type: Object
    }
  },
  methods: {
    bytesBufferToBase64 (buffer) {
      let binary = ''
      const bytes = new Uint8Array(buffer)
      const len = bytes.byteLength
      for (let i = 0; i < len; i += 1) {
        binary += String.fromCharCode(bytes[i])
      }
      return btoa(binary)
    }
  },
  mounted () {
    this.dataHoraRegistro = moment(this.values.dataHoraRegistro).format('DD/MM/YYYY HH:mm:ss')
    if (this.values.cacamba && this.values.cacamba.perfil) {
      this.orgao = this.values.cacamba.perfil.orgao
    }
    this.image = this.bytesBufferToBase64(this.values.foto.data)
  }
}
</script>
