<template>
  <div class="tutorial">
    <VuexWrapper ref="vuexWrapper" />
    <div class="main">
      <div class="header">
        <h2 class="crud-title">
          Tutorial de Utilização do Sistema RCC
        </h2>
        <span>
          <DxButton
            text="BAIXAR TUTORIAL EM PDF"
            @click="btnPdf"
            type="default"
          />
        </span>
      </div>
      <div v-for="video in fieldsVideos.filter( (video) => storeHelper.hasPerfil(video.perfis))" :key="video.id" class="content">
          <div class="videos-title">
            <h3>
              {{video.title}}
            </h3>
            {{video.subtitle}}
          </div>
          <video
            :src="video.url"
            controls
            class="videos-itens"
            v-show="video.url"
          />
          <div class="videos-description" v-html="video.description">
          </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import VuexWrapper from '@/helper/VuexWrapper'
import { ref } from 'vue'
import { fieldsVideos } from '@/pages/Cruds/Tutorial/fields'
import DxButton from 'devextreme-vue/button'
import storeHelper from '@/helper/storeHelper'

const vuexWrapper = ref()

const btnPdf = () => {
  window.open('tutorial_rcc.pdf', '_blank')
}
</script>

<style>
.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 5px;
  margin-bottom: 10px;
}
@media(max-width: 768px) {
  .header {
    display: flex;
    flex-direction: column;
  }
}
.content {
  margin: 1rem 0;
  max-width: 800px;
}
.videos-title {
  margin: 1rem 0;
  width: 100%;
}
.videos-itens {
  width: 100%;
  height: 800px;
}
.videos-description {
  width: 100%;
  font-size: 1rem;
}
</style>
