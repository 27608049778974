<template>
  <dx-popup
    :hide-on-outside-click="true"
    :drag-enabled="false"
    height="auto"
    :show-title="true"
    :title="title"
    :visible.sync="popupActive">
    <template v-slot:content>
      <div class="solicitacao-update-popup-content">
        <fields-veiculo-cacamba-solicitacao
          :can-edit-cacamba="editable || canEditCacamba"
          :can-edit-veiculo="editable || canEditVeiculo"
          class="solicitacao-update-popup-form"
          :show-only-cacamba="showOnlyCacamba"
          v-model="popupData"/>

        <message-error :message="popupErrorMessage"/>

        <div class="solicitacao-update-popup-buttons">
          <dx-button
            v-if="canEditCacamba || editable"
            type="success"
            @click="onButtonSaveClick">
            Salvar
          </dx-button>
          <dx-button type="normal" @click="popupActive = false">Voltar</dx-button>
        </div>
      </div>
    </template>
  </dx-popup>
</template>
<script>
import { DxButton, DxPopup } from 'devextreme-vue'
import mixinPopup from './mixin-popup'

import {
  getCacamba,
  getVeiculo,
  updateSolicitacaoTransporteNaoSolicitado
} from './service'
import MessageError from '../MessageError'
import FieldsVeiculoCacambaSolicitacao from './FieldsVeiculoCacambaSolicitacao'

export default {
  name: 'popup-update-solicitacao',
  mixins: [
    mixinPopup
  ],
  components: {
    DxButton,
    DxPopup,
    MessageError,
    FieldsVeiculoCacambaSolicitacao
  },
  props: {
    editable: {
      type: Boolean,
      default: () => false
    },
    /**
     * O titulo do popup.
     */
    title: {
      type: String,
      required: true
    },

    /**
     * A solicitacao que sera atualizada.
     */
    solicitacao: {
      type: Object
    },

    /**
     * Se deve mostrar somente o campo de cacamba.
     */
    showOnlyCacamba: {
      type: Boolean,
      default: false
    }
  },
  /**
   * Metodo do vue, para obter o estado inicial do componente.
   * @returns {Object} O estado inicial do componente.
   */
  data () {
    return {
      popupErrorMessage: null,
      popupData: {
        cacamba: null,
        veiculo: null,
        tipo: null,
        poliguindaste: null
      }
    }
  },
  computed: {
    /**
     * Valor computado de se pode ser editada a solicitacao.
     * @returns {boolean}
     */
    canEditVeiculo () {
      return this.solicitacao &&
        this.solicitacao.situacao === 'Enviada'
    },

    /**
     * Valor computado de se pode ser editada a solicitacao.
     * @returns {boolean}
     */
    canEditCacamba () {
      return this.solicitacao && ['Alocada', 'Enviada']
        .includes(this.solicitacao.situacao)
    }
  },
  watch: {
    /**
     * Metodo de callback de mudancas no valor da prop 'active', com o objetivo
     * de buscar os dados de veiculo e cacamba.
     * @param {boolean} active - O valor atual da prop.
     */
    async active (active) {
      const idVeiculo = this.solicitacao.idVeiculo || (this.solicitacao.veiculo ? this.solicitacao.veiculo.id : null)
      const idCacamba = this.solicitacao.idCacamba || (this.solicitacao.cacamba ? this.solicitacao.cacamba.id : null)
      if (this.solicitacao && active) {
        this.$refs.fieldCacamba && this.$refs.fieldCacamba.reload()
        this.$refs.fieldVeiculo && this.$refs.fieldVeiculo.reload()

        const [veiculoResult, cacambaResult] = await Promise.all([
          idVeiculo && !this.showOnlyCacamba
            ? getVeiculo(idVeiculo)
            : null,
          idCacamba ? getCacamba(idCacamba) : null
        ])
        this.popupData = {
          cacamba: (cacambaResult && cacambaResult.success && cacambaResult.data) || null,
          veiculo: (veiculoResult && veiculoResult.success && veiculoResult.data) || null,
          situacao: this.solicitacao.situacao,
          idCacamba: idCacamba,
          idVeiculo: idVeiculo
        }
      }

      if (!active) {
        this.$refs.fieldCacamba && this.$refs.fieldCacamba.close()
        this.$refs.fieldVeiculo && this.$refs.fieldVeiculo.close()
        this.popupData = {
          cacamba: null,
          veiculo: null,
          situacao: this.solicitacao.situacao,
          idCacamba: idCacamba,
          idVeiculo: idVeiculo
        }
      }
    }
  },
  methods: {
    /**
     * Metodo de callback de quando foi clicado no botao de salvar do popup. Com
     * o objetivo de enviar a requisicao de salvar as alteracoes da solicitacao.
     */
    async onButtonSaveClick () {
      this.popupErrorMessage = null

      if (!this.showOnlyCacamba && !this.popupData.veiculo) {
        this.popupErrorMessage = 'Não foi selecionado um veiculo.'
        return
      }

      if (!this.popupData.cacamba && ['Poliguindaste'].includes(this.popupData.veiculo.tipo)) {
        this.popupErrorMessage = 'Não foi selecionada uma caçamba.'
        return
      }
      const res = await updateSolicitacaoTransporteNaoSolicitado({
        ...(!this.showOnlyCacamba ? { idVeiculo: this.popupData.veiculo.id || this.popupData.idVeiculo } : {}),
        idCacamba: this.popupData.idCacamba || (this.popupData.cacamba ? this.popupData.cacamba.id || this.popupData.cacamba : undefined)
      }, this.solicitacao.id)

      if (res.success) {
        this.popupActive = false
        this.$emit('updated-solicitacao')
      } else {
        this.popupErrorMessage = res.error
      }
    }
  }
}
</script>

<style lang="scss">
@import '../../global.scss';

.solicitacao-decline-popup-content {
  display: flex;
  flex-direction: column;

  .solicitacao-decline-popup-buttons {
    display: flex;
    flex-direction: row-reverse;
  }

  > :not(.solicitacao-decline-popup-buttons) {
    flex: 1;
  }

  .error-message-card {
    background-color: #B00020 !important;
    color: white !important;
    width: 100%;
    margin-top: 6px;
    margin-bottom: 16px;
  }
}

.solicitacao-update-popup-content {
  display: flex;
  flex-direction: column;

  > .solicitacao-update-popup-form {
    flex: 1;
  }

  > .solicitacao-update-popup-buttons {
    display: flex;
    flex-direction: row-reverse;
  }

  .error-message-card {
    background-color: #B00020 !important;
    color: white !important;
    width: 100%;
    margin-top: 6px;
    margin-bottom: 16px;
  }
}
</style>
