<template>
  <div class="form-pessoa">
    <div class="form flex">
      <div class="field">
        <b>Tipo Pessoa*:</b>
        <dx-select-box
          :disabled="(!autoCadastro && action !== 'add' || readOnly)"
          v-model="form.tipoPessoaStr"
          :items="[{ id: 'F', tipo: 'Pessoa Física' }, { id: 'J', tipo: 'Pessoa Jurídica' }]"
          value-expr="id"
          display-expr="tipo"
          placeholder="Selecione um tipo de pessoa"
        />
      </div>
    </div>

    <!-- Dados da pessoa empresa -->
    <div v-if="form.tipoPessoaStr === 'J'">
      <fieldset>
        <legend>Dados da empresa</legend>
        <div class="form flex">
          <div class="flex-col-9">
            <b>CNPJ*</b>
            <dx-text-box
              :disabled="(!autoCadastro && action !== 'add' && !isAdministrador()) || readOnly"
              v-model="form.cnpj"
              mask="00.000.000/0000-00"
              :validation-status="formValid.cnpj"
            />
          </div>
          <div class="flex-col-9">
            <b>{{ form.tipoPessoaStr === 'F' ? 'Nome' : 'Razão Social' }}*</b>
            <dx-text-box
              :disabled="readOnly"
              v-model="form.nomeRazaoSocial"
              :validation-status="formValid.nomeRazaoSocial"
            />
          </div>
        </div>
        <div class="form flex" v-if="!editingPjFoundAutoCadastro">
          <form-usuario
            ref="formularioPessoaPj"
            :readOnly="readOnly"
            :usuario-to-edit="form.pessoaJuridica"
            :ocultar-campos="ocultarCamposAutoCadatroPj"
          />
        </div>
      </fieldset>
    </div>

    <!-- Dados da pessoa física ou do usuário responsável -->
    <div v-if="form.tipoPessoaStr">
      <fieldset v-if="!hideAuto">
        <legend>{{ form.tipoPessoaStr === 'F' ? 'Dados de cadastro' : 'Responsáveis' }}</legend>
        <div class="form flex" v-if="form.tipoPessoaStr === 'F'">
          <form-usuario
            :readOnly="readOnly"
            ref="formularioUsuarioPessoaFisica"
            :usuario-to-edit="form.usuarioPessoaFisica"
            :ocultar-campos="autoCadastro
            ? ['rg', 'responsavelAssinaturaContrato' , 'tipoResponsavel', 'profissao', 'cargo', ...($utils.isFinalId(form.id) ? ['confirmarEmail'] : [])]
            : ['rg', 'senha', 'responsavelAssinaturaContrato' , 'tipoResponsavel', 'profissao', 'cargo', ...($utils.isFinalId(form.id) ? ['confirmarEmail'] : [])]"
          />
        </div>
        <div v-else-if="!editingPjFoundAutoCadastro">
          <dx-button type="default" @click="openAdicionarResponsavel" :disabled="readOnly">Adicionar Responsável</dx-button>
          <dx-data-grid
            :disabled="readOnly"
            :data-source="responsaveisComp"
            :columns="responsaveisColumns"
            value-expr="cpf"
            display-expr="nome"
            :allow-deleting="true"
            :confirm-delete="true"
          />
        </div>
      </fieldset>
    </div>

    <!-- Perfis e Usuários -->
    <div v-if="form.tipoPessoaStr" class="form">
      <!-- PERFIL-->
      <fieldset v-if="!hideAuto">
        <legend>Perfis</legend>
        <dx-button
          type="default"
          :disabled="disabledButtonAdicionarPerfilComp || readOnly"
          @click="addPerfil">Adicionar Perfil*
        </dx-button>
        <div class="boxes-perfil">
          <div :key="i" v-for="(perfil, i) in perfisListComp" class="box-perfil">
            <div class="box-acoes">
              <div v-show="perfisListComp.length > 1 && !readOnly" @click="removePerfil(perfil)">
                <md-icon class="mdi mdi-close-circle remover-perfil" title="Remover Perfil"/>
              </div>
            </div>
            <div class="box-form">
              <div class="field">
                <b>Tipo de Perfil*:</b>
                <dx-select-box
                  :disabled="readOnly"
                  v-model="perfil.idTipoPerfil"
                  :items="getTipoPerfilDisponivelList(perfil)"
                  value-expr="id"
                  display-expr="descricao"
                  placeholder="Selecione um tipo de perfil"
                  :validation-status="formValid.perfis[i] || 'valid'"
                />
              </div>

              <div class="field" v-if="isTipoPerfil(perfil, 'Destino Final')">
                <b>Tipo de destino final:</b>
                <dx-select-box
                  :disabled="readOnly"
                  v-model="perfil.tipoDestinoFinal"
                  :items="['Aterro', 'ATT', 'PEV', 'Recicladora']"
                  placeholder="Selecione um tipo de destino final"
                />
              </div>

              <div class="field" v-if="isTipoPerfil(perfil, 'Destino Final')">
                <b>Capacidade máxima em m<sup>3</sup> suportada:</b>
                <dx-number-box v-model="perfil.capacidadeMaximaSuportada"  :disabled="readOnly"/>
              </div>

              <div class="field" v-if="isTipoPerfil(perfil, 'Secretaria') || isTipoPerfil(perfil, 'Administrador')">
                <b>Matrícula</b>
                <dx-text-box v-model="perfil.matricula" :maxLength="30" :disabled="readOnly" />
              </div>

              <div class="field" v-if="isTipoPerfil(perfil, 'Fiscalização')">
                <b>Órgão</b>
                <dx-select-box
                  :disabled="readOnly"
                  :data-source="[
                    { text: 'AGEFIS', type: 'AGEFIS' },
                    { text: 'IBRAM', type: 'IBRAM' },
                    { text: 'SEMA', type: 'SEMA' }
                     ]"
                  display-expr="text"
                  value-expr="type"
                  v-model="perfil.orgao"
                />
                <b>Matrícula</b>
                <dx-text-box v-model="perfil.matricula" :maxLength="30"  :disabled="readOnly" />
              </div>

              <!--  Documentos do Transportador -->
              <div class="form flex" v-if="isTipoPerfil(perfil, 'Transportador')">
                <div class="documents">
                  <div class="flex-col-12">
                    <h3>Forma de cobrança</h3>
                    <dx-select-box
                      :disabled="readOnly"
                      width="280"
                      v-if="perfil && perfil._status === 'insert' ? true : calculateDataAlteracaoFormaCobranca(perfil.dataAlteracaoFormaCobranca).isValid"
                      v-model="perfil.formaCobranca"
                      :items="[{ id: 0, tipo: 'Cobrança por peso' }, { id: 1, tipo: 'Cobrança por caçamba' }]"
                      value-expr="id"
                      display-expr="tipo"
                      placeholder="Seleciona uma forma de cobrança"
                    />
                    <span v-if="perfil && perfil._status !== 'insert'"><b>{{ calculateDataAlteracaoFormaCobranca(perfil.dataAlteracaoFormaCobranca).message }}</b></span>
                    <h3>Documentos</h3>
                  </div>
                  <div class="flex-col-12">
                    <div class="fields-separator" v-for="(index,value) in documentsEnum[form.tipoPessoaStr]" :key="index">
                      <DxTooltip
                        :ref="`tooltip-${value}`"
                        :hide-on-outside-click="false"
                        :target="`#downloadAttachment-${value}`"
                      >
                        <span v-if="perfil && perfil._arquivosIniciaisMetaDados && perfil._arquivosIniciaisMetaDados[documentsEnum[form.tipoPessoaStr][value]]">
                          Baixar documento "{{perfil._arquivosIniciaisMetaDados[documentsEnum[form.tipoPessoaStr][value]].name}}"
                        </span>
                      </DxTooltip>
                      <DxTooltip
                        v-if="!perfil._arquivos[value]"
                        :ref="`tooltip-uploader-${value}`"
                        :hide-on-outside-click="false"
                        :target="`#modelUploaderButton-${value}`"
                      >
                        <span v-if="(perfil && perfil._arquivosIniciaisMetaDados && perfil._arquivosIniciaisMetaDados[documentsEnum[form.tipoPessoaStr][value]] && !readOnly)">
                          Substituir anexo
                        </span>
                        <span v-else-if="!readOnly">
                          Adicionar documento
                        </span>
                      </DxTooltip>
                      <span>{{documentsEnum[form.tipoPessoaStr][value]}}</span>
                      <div style="display: flex">
                        <model-file-uploader
                          :read-only="readOnly"
                          :button-label="perfil._arquivos[value] ? 'Substituir' : perfil && perfil._arquivosIniciaisMetaDados && perfil._arquivosIniciaisMetaDados[documentsEnum[form.tipoPessoaStr][value]] ? 'Substituir' : 'Adicionar'"
                          :button-id="`modelUploaderButton-${value}`"
                          :show-remove-button="false"
                          :icon="perfil && perfil._arquivosIniciaisMetaDados && perfil._arquivosIniciaisMetaDados[documentsEnum[form.tipoPessoaStr][value]] ? 'mdi mdi-swap-horizontal' : 'mdi mdi-upload'"
                          drag-and-drop-label=""
                          v-model="perfil._arquivos[value]"
                          accept=".pdf, image/*"
                          entity="Arquivo"
                          @mouseenter="toggleUploader(value)"
                          @mouseleave="toggleUploader(value)"
                        >
                          <template #customButtons>
                            <div
                              @mouseenter="toggleDefault(value)"
                              @mouseleave="toggleDefault(value)"
                              style="place-self:center"
                              v-if="perfil && perfil._arquivosIniciaisMetaDados && perfil._arquivosIniciaisMetaDados[documentsEnum[form.tipoPessoaStr][value]]">
                              <dx-button
                                :id="`downloadAttachment-${value}`"
                                type="default"
                                icon="mdi mdi-download"
                                @click="downloadAnexo('Arquivo', perfil._arquivosIniciaisMetaDados[documentsEnum[form.tipoPessoaStr][value]].id, 'anexo', perfil._arquivosIniciaisMetaDados[documentsEnum[form.tipoPessoaStr][value]].name)"
                              />
                              <!--                          <span style="place-self: center; font-weight: bold; margin-left: 10px"> {{ perfil._arquivosIniciaisMetaDados[documentsEnum[form.tipoPessoaStr][value]].name }}</span>-->
                            </div>
                          </template>
                        </model-file-uploader>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form flex" v-if="mostrarAnexoLicAmbientalComp(perfil)">
                <div class="flex-col-12">
                  <model-file-uploader
                    :read-only="readOnly"
                    v-model="perfil._arquivos.licenciamentoAmbientalRcc"
                    accept=".pdf"
                    entity="Arquivo"
                    :label="'Anexar Licenciamento Ambiental'"/>
                  <div v-if="perfil._arquivosIniciaisMetaDados && perfil._arquivosIniciaisMetaDados.licenciamentoAmbientalRcc && perfil._arquivosIniciaisMetaDados.licenciamentoAmbientalRcc.id" style="display: inline-flex">
                    <dx-button
                      type="info"
                      text="Baixar Anexo"
                      @click="downloadAnexo('Arquivo', perfil._arquivosIniciaisMetaDados && perfil._arquivosIniciaisMetaDados.licenciamentoAmbientalRcc && perfil._arquivosIniciaisMetaDados.licenciamentoAmbientalRcc.id, 'anexo', 'Certificado_de_licenciamento_de_RCC.pdf')"
                    />
                    <span style="place-self: center; font-weight: bold; margin-left: 10px">{{ perfil._arquivosIniciaisMetaDados.licenciamentoAmbientalRcc.name }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </fieldset>

      <!-- USUARIOS-->
      <fieldset v-if="form.tipoPessoaStr === 'J'">
        <legend>Usuários</legend>
        <div v-if="email && login">
          <!--  -->
          <form-usuario
            :readOnly="readOnly"
            ref="formPopupUsuarioAuto"
            :usuario-to-edit="popupAddUsuario.usuarioToEdit"
            :perfis-selecionaveis="getPerfisSelecionaveis()"
            :ocultar-campos="['rg', 'tipoResponsavel', 'responsavelAssinaturaContrato', 'profissao', 'cargo']"
          />
          <div v-if="!(perfisListComp.length >= 1 && perfisListComp[0].idTipoPerfil)"
               style="font-style: italic; font-size: 0.9em"
          ></div>
        </div>
        <div v-else>
          <dx-button
            type="default"
            :disabled="!(perfisListComp.length >= 1 && perfisListComp[0].idTipoPerfil) || readOnly"
            @click="popupAddUsuario.show = true; popupAddUsuario.action = 'add'">
            Adicionar Usuário
          </dx-button>

          <div v-if="!(perfisListComp.length >= 1 && perfisListComp[0].idTipoPerfil)"
               style="font-style: italic; font-size: 0.9em"
          >
            Para adicionar um usuário, adicione um perfil antes
          </div>

          <div v-if="form.usuariosVinculadoPj.length">
            <dx-data-grid
              :disabled="readOnly"
              :data-source="usuariosVinculadoPjComp"
              :columns="usuarioGridData.columns"
              value-expr="cpf"
              display-expr="nome"
              :allow-deleting="true"
              :confirm-delete="true"
            />
          </div>
        </div>

      </fieldset>
    </div>

    <div class="form-actions">
      <dx-button type="success" @click="salvar" v-if="!readOnly">Salvar</dx-button>
    </div>

    <dx-popup
      title="Formulário adicionar responsável pela empresa"
      :height="500"
      :show-title="true"
      :dragEnabled="false"
      :visible.sync="popupResponsavel.show"
      @hidden="fecharPopupResponsavel"
    >
      <template v-slot:content>
        <dx-scroll-view>
          <form-usuario
            :readOnly="readOnly"
            ref="formPopupResponsavel"
            :usuario-to-edit="popupResponsavel.responsavelToEdit"
            :ocultar-campos="['senha', 'confirmarEmail']"
          />
          <dx-button type="success" @click="salvarPopupResponsavel">Salvar</dx-button>
        </dx-scroll-view>
      </template>
    </dx-popup>

    <dx-popup
      title="Formulário adicionar Usuário"
      :height="500"
      :show-title="true"
      :dragEnabled="false"
      :visible.sync="popupAddUsuario.show"
      @hidden="fecharPopupUsuario"
    >
      <template v-slot:content>
        <dx-scroll-view>
          <form-usuario
            :readOnly="readOnly"
            ref="formPopupUsuario"
            :usuario-to-edit="popupAddUsuario.usuarioToEdit"
            :popup-action="popupAddUsuario.action"
            :perfis-selecionaveis="getPerfisSelecionaveis()"
            :ocultar-campos="autoCadastro ? ['confirmarEmail', 'rg', 'tipoResponsavel', 'responsavelAssinaturaContrato', 'profissao', 'cargo'] : ['senha', 'confirmarEmail', 'rg', 'tipoResponsavel', 'responsavelAssinaturaContrato', 'profissao', 'cargo']"
          />
          <dx-button type="success" @click="salvarPopupUsuario">Salvar</dx-button>
        </dx-scroll-view>
      </template>
    </dx-popup>

  </div>
</template>

<style lang="scss">
.fields-separator {
  padding: 10px;
  border-bottom: 0.1px solid lightgrey;
}
.form-pessoa {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .form-actions {
    margin: 10px 0;
  }

  .form {
    width: 100%;

    > div {
      margin: 0 10px 10px 0;
    }

    .field {
      display: flex;
      flex-direction: column;
    }
  }
  .boxes-perfil {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 10px;

    .box-perfil {
      display: flex;
      flex-direction: column;
      border: 1px solid #ccc;
      margin-bottom: 10px;
      margin-right: 20px;
      position: relative;

      .box-form {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding: 10px;

        > * {
          margin-right: 10px;
        }
      }

      .box-acoes {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        top: 3px;
        right: -10px;

        > * {
          cursor: pointer;
        }

        .remover-perfil {
          color: #069;
          background: #fff;
        }
      }
    }
  }
}
</style>

<script>
import mixinCrud from '@/pages/Cruds/mixin-crud'
import { DxButton, DxDataGrid, DxNumberBox, DxPopup, DxScrollView, DxSelectBox, DxTextBox } from 'devextreme-vue'
import ModelFileUploader from '@/components/Form/ModelFileUploader'
import FormUsuario from '@/pages/Cruds/Pessoa/FormUsuario'
import config from 'config'
import moment from 'moment'
import { http } from '@/pluggables/http'
import { documentosEnum } from '@/pages/Cruds/Pessoa/documentosEnum'
import { DxTooltip } from 'devextreme-vue/tooltip'
import notify from 'devextreme/ui/notify'
import storeHelper from '@/helper/storeHelper'
import { mapGetters } from 'vuex'

const baseUrl = config.baseUrl
export const messageRequiredDefault = (nomeCampo, mensagemOpcional = '') => `O preenchimento do campo: ${nomeCampo} é obrigatório. ${mensagemOpcional}`

export default {
  name: 'form-pessoa',
  props: {
    autoCadastro: {
      type: Boolean,
      default: () => false
    },
    id: {
      type: [Number, String]
    },
    name: {
      type: String,
      default: null
    },
    email: {
      type: String,
      default: null
    },
    password: {
      type: String,
      default: null
    },
    idAuth: {
      type: String,
      default: null
    },
    login: {
      type: Boolean,
      default: false
    },
    modal: {
      type: Boolean,
      default: false
    },
    action: {
      type: String
    }
  },
  components: {
    FormUsuario,
    DxButton,
    DxPopup,
    DxSelectBox,
    DxTextBox,
    DxNumberBox,
    DxDataGrid,
    DxScrollView,
    ModelFileUploader,
    DxTooltip
  },
  mixins: [mixinCrud],
  inject: ['action'],
  data () {
    return {
      readOnly: false,
      entity: 'Usuario',
      formInitialized: false,
      editingPjFoundAutoCadastro: false,
      closeModalClearAll: {
        value: true
      },
      events: {
        save: 'save'
      },
      formValid: {
        cnpj: 'valid',
        nomeRazaoSocial: 'valid',
        perfis: []
      },
      usuarioDadosIniciais: null,
      form: {
        responsaveis: [],
        perfis: [],
        usuariosVinculadoPj: [], // Array<Object> contém lista de usuários vinculados ao Pessoa Jurídica
        tipoPessoaStr: null, // enum(F, J)

        pessoaJuridica: undefined, // Contém as informações de pessoa jurídica
        usuarioPessoaFisica: undefined // Objeto que representa o usuário representante desta pessoa
      },
      dynamicValues: {
        tipoPerfilList: []
      },
      popupResponsavel: {
        show: false,
        responsavelToEdit: undefined
      },
      popupAddUsuario: {
        show: false,
        action: '',
        usuarioToEdit: undefined
      },
      usuarioGridData: {
        columns: [
          {
            dataField: 'cpf',
            caption: 'CPF'
          },
          {
            dataField: 'nome',
            caption: 'Nome'
          },
          {
            dataField: 'email',
            caption: 'Email'
          },
          {
            type: 'buttons',
            width: 100,
            showInColumnChooser: false,
            buttons: [
              {
                hint: 'Remover Usuário',
                icon: 'trash',
                visible: true,
                onClick: ({ row }) => {
                  this.removerUsuario(row.data)
                }
              },
              {
                hint: 'Editar Usuário',
                icon: 'edit',
                visible: true,
                onClick: ({ row }) => {
                  this.popupAddUsuario = {
                    show: true,
                    action: 'edit',
                    usuarioToEdit: row.data
                  }
                  this.$nextTick(() => {
                    if (this.$refs.formPopupUsuario) {
                      this.$refs.formPopupUsuario.startOrReset()
                    }
                  })
                }
              }
            ]
          }
        ]
      },
      responsaveisColumns: [
        {
          dataField: 'nome',
          caption: 'Nome do responsável'
        },
        {
          dataField: 'tipoResponsavel',
          caption: 'Exerce esta função'
        },
        {
          dataField: 'cargo',
          caption: 'Cargo'
        },
        {
          dataField: 'profissao',
          caption: 'Profissão'
        },
        {
          dataField: 'email',
          caption: 'Email'
        },
        {
          type: 'buttons',
          width: 100,
          showInColumnChooser: false,
          buttons: [
            {
              hint: 'Remover Responsável',
              icon: 'trash',
              visible: true,
              onClick: ({ row }) => {
                const responsavel = row.data
                const responsaveis = []
                for (const u of this.form.responsaveis) {
                  if (u.id === responsavel.id) {
                    responsaveis.push({
                      ...responsavel,
                      _status: 'remove'
                    })
                  } else {
                    responsaveis.push(u)
                  }
                }
                this.form = {
                  ...this.form,
                  responsaveis
                }
              }
            },
            {
              hint: 'Editar Responsável',
              icon: 'edit',
              visible: true,
              onClick: ({ row }) => {
                this.popupResponsavel = {
                  show: true,
                  responsavelToEdit: row.data
                }
                this.$nextTick(() => {
                  if (this.$refs.formPopupResponsavel) {
                    this.$refs.formPopupResponsavel.startOrReset()
                  }
                })
              }
            }
          ]
        }
      ],
      hideAuto: false,
      ocultarCamposAutoCadatroPj: ['rg', 'cpf', 'nome', 'cargo', 'profissao', 'tipoResponsavel', 'responsavelAssinaturaContrato', 'senha', 'confirmarEmail'],
      documentsEnum: documentosEnum
    }
  },
  watch: {
    'form.usuarioPessoaFisica': {
      deep: true,
      handler (data) {
        if (this.$refs.formularioUsuarioPessoaFisica) this.$refs.formularioUsuarioPessoaFisica.form = data
      }
    },
    'closeModalClearAll.value' (value) {
      this.form = {
        ...this.form,
        usuarioPessoaFisica: undefined,
        pessoaJuridica: undefined,
        usuariosVinculadoPj: [],
        usuarios: [],
        tipoPessoaStr: null,
        responsaveis: [],
        perfis: [],
        nomeRazaoSocial: null
      }
      this.ocultarCamposAutoCadatroPj = ['cpf', 'nome',
        'cargo', 'profissao', 'tipoResponsavel',
        'responsavelAssinaturaContrato', 'senha',
        'confirmarEmail']
    },
    modal (status) {
      if (this.form.tipoPessoaStr === 'J') {
        this.form.pessoaJuridica = {
          ...this.form.pessoaJuridica,
          email: this.email,
          nome: this.name,
          idAuth: this.idAuth,
          login: this.login
        }
        this.popupAddUsuario.usuarioToEdit = {
          ...this.popupAddUsuario.usuarioToEdit,
          email: this.email,
          confirmarEmail: this.email,
          nome: this.name,
          senha: this.password,
          confirmarSenha: this.password,
          idAuth: this.idAuth,
          login: this.login
        }
        this.form.nomeRazaoSocial = this.name
      } else {
        this.form.usuarioPessoaFisica = {
          ...this.form.usuarioPessoaFisica,
          email: this.email,
          confirmarEmail: this.email,
          nome: this.name,
          senha: this.password,
          confirmarSenha: this.password,
          idAuth: this.idAuth,
          login: this.login
        }
      }
    },
    form: {
      deep: true,
      handler (form) {
        this.formValid.nomeRazaoSocial = typeof form.nomeRazaoSocial === 'string' && !form.nomeRazaoSocial.length ? 'invalid' : 'valid'
      }
    },
    perfisListComp (perfis) {
      this.formValid.perfis = perfis.map((p) => !p.idTipoPerfil ? 'invalid' : 'valid')
    },
    'form.cnpj': {
      async handler (cnpj = '', oldValue) {
        if (this.form.tipoPessoaStr === 'J' && cnpj.length === 14 && oldValue) {
          if (!this.$utils.validateCnpj(cnpj)) {
            this.formValid.cnpj = 'invalid'
            return this.$utils.notifyError(new CustomError('CNPJ inválido'))
          } else {
            this.formValid.cnpj = 'valid'
            this.setLoading(true)
            // const where = this.id ? { id: this.id } : { cpfCnpj: cnpj }
            await this.cpfCnpjExists({ value: cnpj })
            this.setLoading(false)
          }
        }
      }
    },
    'form.tipoPessoaStr': {
      async handler (tipoPessoaStr) {
        if (this.form.tipoPessoaStr === 'J') {
          this.popupAddUsuario.usuarioToEdit = {
            ...this.popupAddUsuario.usuarioToEdit,
            email: this.email,
            confirmarEmail: this.email,
            nome: this.name,
            senha: this.password,
            confirmarSenha: this.password,
            idAuth: this.idAuth,
            login: this.login
          }
          this.form = {
            ...this.form,
            email: this.email ? this.email : this.form.email,
            pessoaJuridica: {
              ...(this.form.pessoaJuridica || {}),
              email: this.email ? this.email : this.form.email
            }
          }
        }

        if (this.formInitialized) {
          this.form.perfis = []
          this.form.usuariosVinculadoPj = []
        }

        let where
        if (this.isAdministrador()) {
          where = tipoPessoaStr === 'F' ? {
            or: [
              { tipo: tipoPessoaStr },
              { id: { in: [3, 4, 5, 6, 10] } }
            ]
          } : {
            or: [
              { tipo: tipoPessoaStr },
              { id: { in: [10] } }
            ]
          }
        } else {
          where = tipoPessoaStr === 'F' ? {
            or: [
              { tipo: tipoPessoaStr },
              { id: { in: [3, 4, 5, 6] } }
            ]
          } : {
            tipo: tipoPessoaStr
          }
        }
        this.putDynamicValue('tipoPerfilList', await this.find({
          entity: 'TipoPerfil',
          rowsOnly: true,
          params: {
            where
          }
        }))

        await this.$nextTick()

        if (this.$refs.formularioUsuarioPessoaFisica) {
          if (this._unwatcherUsuarioPf) {
            this._unwatcherUsuarioPf()
            this._unwatcherUsuarioPf = null
          }
          this._unwatcherUsuarioPf = this.$refs.formularioUsuarioPessoaFisica.$watch('form.cpf', async (cpf) => {
            if (this.form.tipoPessoaStr === 'F' && cpf.length === 11) {
              if (this.$refs.formularioUsuarioPessoaFisica.formValid.cpf === 'valid') {
                this.setLoading(true)
                await this.cpfCnpjExists({ value: cpf })
                this.setLoading(false)
              } else {
                notify('CPF Inválido', 'error')
              }
            }
          })
        }
        // traz o campo de perfil ja aberto quando esta inserindo.
        if (!this.id) {
          this.form.perfis.push({
            _status: 'insert',
            _arquivos: {}
          })
        }
      }
    }
  },
  computed: {
    ...mapGetters('Login', ['isLoggedIn']),
    perfisListComp () {
      return this.form.perfis.filter(({ _status }) => _status !== 'remove')
    },
    responsaveisComp () {
      return this.form.responsaveis.filter(({ _status }) => _status !== 'remove')
    },
    usuariosVinculadoPjComp () {
      return this.form.usuariosVinculadoPj.filter(({ _status }) => _status !== 'remove')
    },
    tipoPerfilDisponivelListComp () {
      return this.dynamicValues.tipoPerfilList.filter((tipoPerfil) => {
        return !this.perfisListComp.find(({ idTipoPerfil }) => tipoPerfil.id === idTipoPerfil)
      })
    },
    disabledButtonAdicionarPerfilComp () {
      return (this.form.tipoPessoaStr === 'J' && (!this.tipoPerfilDisponivelListComp.length || !!this.perfisListComp.find(({ idTipoPerfil }) => !idTipoPerfil)))
    }
  },
  async mounted () {
    this.readOnly = (this.$route.name === 'pessoa-view')
    await this.buildInitialValues()

    setTimeout(() => {
      this.formInitialized = true
    }, 1000)
  },
  methods: {
    isAdministrador () {
      return this.isLoggedIn ? storeHelper.isAdministrador() : false
    },
    openAdicionarResponsavel () {
      this.popupResponsavel = {
        show: true
      }
      this.$nextTick(() => {
        if (this.$refs.formPopupResponsavel) {
          this.$refs.formPopupResponsavel.$data.started = true
          this.$refs.formPopupResponsavel.startOrReset()
        }
      })
    },
    async buildInitialValues (id = this.id) {
      if (id) {
        try {
          this.setLoading(true)
          const pessoa = (await this.find({
            rowsOnly: true,
            entity: 'Pessoa',
            params: {
              where: { id },
              include: JSON.stringify([
                {
                  model: 'Municipio',
                  as: 'municipio'
                },
                {
                  required: false,
                  model: 'Usuario',
                  as: 'usuarios',
                  where: {
                    excluido: 'N'
                  },
                  include: [
                    {
                      model: 'Perfil',
                      as: 'perfis'
                    },
                    {
                      model: 'Municipio',
                      as: 'municipio'
                    }
                  ]
                },
                {
                  model: 'Perfil',
                  as: 'perfis',
                  include: [
                    {
                      model: 'Arquivo',
                      as: 'arquivos',
                      attributes: ['id', 'descricao', 'nomeArquivo'],
                      include: [
                        {
                          required: true,
                          model: 'TipoArquivo',
                          as: 'tipoArquivo'
                        }
                      ]
                    }
                  ]
                },
                {
                  model: 'Responsavel',
                  as: 'responsaveis',
                  include: [
                    {
                      model: 'Municipio',
                      as: 'municipio'
                    }
                  ]
                }
              ])
            }
          }))[0]
          if (pessoa) {
            const normalizePessoaEUsuario = (data = {}) => {
              return {
                ...data,
                numero: data.numero !== undefined ? String(data.numero) : null
              }
            }
            this.form = {
              ...normalizePessoaEUsuario(pessoa), // todos os dados de PF ou PJ na raiz do form
              ...(pessoa.tipoPessoa === 'J' ? {
                cnpj: pessoa.cpfCnpj,
                pessoaJuridica: {
                  ...normalizePessoaEUsuario(pessoa),
                  cnpj: pessoa.cpfCnpj
                }
              } : {}),
              ...(pessoa.tipoPessoa === 'F' ? {
                usuarioPessoaFisica: { ...normalizePessoaEUsuario(pessoa), cpf: pessoa.cpfCnpj, nome: pessoa.nomeRazaoSocial || pessoa.nome, id: pessoa.id },
                nome: pessoa.nome,
                nomeRazaoSocial: pessoa.nome,
                celular: pessoa.celular,
                telefone: pessoa.telefone,
                email: pessoa.email,
                confirmarEmail: pessoa.confirmarEmail,
                senha: pessoa.senha,
                cep: pessoa.cep,
                uf: pessoa.uf,
                codIbge: pessoa.codIbge,
                bairro: pessoa.bairro,
                endereco: pessoa.endereco,
                complemento: pessoa.complemento,
                numero: pessoa.numero
              } : {}),
              perfis: pessoa.perfis.map((perfil) => {
                const _arquivosIniciaisMetaDados = {}
                if (perfil.arquivos) {
                  const arquivos = perfil.arquivos.filter((arquivo) => !!(arquivo.id))
                  for (const arquivo of arquivos) {
                    // const intArray = Uint8Array.from(arquivo.anexo)
                    // const file = new File([intArray.buffer], arquivo.tipoArquivo.descricao)
                    _arquivosIniciaisMetaDados[arquivo.tipoArquivo.descricao] = {
                      id: arquivo.id,
                      fileName: arquivo.tipoArquivo.descricao,
                      name: arquivo.nomeArquivo,
                      contentType: arquivo.contentType
                    }
                  }
                }
                return {
                  ...perfil,
                  ...(perfil.capacidadeMaximaSuportada ? { capacidadeMaximaSuportada: Number(perfil.capacidadeMaximaSuportada) } : {}),
                  _status: this.$utils.isFinalId(perfil.id) && perfil._status === 'insert' ? 'update' : perfil._status,
                  _arquivosIniciaisMetaDados,
                  _arquivos: {}
                }
              }), //
              usuariosVinculadoPj: pessoa.tipoPessoa === 'J' ? pessoa.usuarios.map((user) => {
                const usuario = normalizePessoaEUsuario(user)
                return {
                  ...usuario,
                  _status: 'update',
                  idsTipoDePerfil: usuario.perfis.map(({ idTipoPerfil }) => idTipoPerfil)
                }
              }) : [],
              tipoPessoaStr: pessoa.tipoPessoa
            }
            if (pessoa.tipoPessoa === 'J' && this.autoCadastro) {
              this.editingPjFoundAutoCadastro = true
              this.hideAuto = true
            }
          }
          if (this.form.pessoaJuridica) this.form.pessoaJuridica.email = {}
        } catch (error) {
          this.$utils.notifyError(error)
        } finally {
          this.setLoading(false)
        }
      } else if (this.login || this.idAuth) {
        if (this.form.tipoPessoaStr === 'J') {
          if (this.$refs.formularioPessoaPj) {
            this.$refs.formularioPessoaPj.form.email = this.email
            this.$refs.formularioPessoaPj.form.nomeRazaoSocial = this.name
            this.$refs.formularioPessoaPj.form.idAuth = this.idAuth
            this.$refs.formularioPessoaPj.form.login = this.login
          }

          this.popupAddUsuario.usuarioToEdit.email = this.email
          this.popupAddUsuario.usuarioToEdit.confirmarEmail = this.email
          this.popupAddUsuario.usuarioToEdit.nome = this.name
          this.popupAddUsuario.usuarioToEdit.senha = this.password
          this.popupAddUsuario.usuarioToEdit.confirmarSenha = this.password
          this.popupAddUsuario.usuarioToEdit.idAuth = this.idAuth
          this.popupAddUsuario.usuarioToEdit.login = this.login

          this.form = {
            ...this.form,
            nomeRazaoSocial: this.name,
            email: this.email
          }
        } else {
          if (this.$refs.formularioUsuarioPessoaFisica) {
            // let oldPerfil = this.$refs.formularioUsuarioPessoaFisica.form.perfis
            this.form.perfis.forEach((p) => {
              if (p._status === 'insert') {
                delete p.id
                delete p.situacaoValidacao
                delete p.idPessoa
                delete p.usuarioPerfil
              }
              return p
            })

            this.$refs.formularioUsuarioPessoaFisica.form = {
              ...this.$refs.formularioUsuarioPessoaFisica.form,
              email: this.email,
              confirmarEmail: this.email,
              nome: this.name,
              senha: this.password,
              confirmarSenha: this.password,
              idAuth: this.idAuth,
              login: this.login,
              perfis: this.form.perfis,
              id: undefined,
              idPessoa: undefined,
              ativo: undefined,
              excluido: undefined
            }
          }
          if (this.form.usuarioPessoaFisica) {
            this.form.usuarioPessoaFisica.email = this.email
            this.form.usuarioPessoaFisica.confirmarEmail = this.email
            this.form.usuarioPessoaFisica.nome = this.name
            this.form.usuarioPessoaFisica.senha = this.password
            this.form.usuarioPessoaFisica.confirmarSenha = this.password
            this.form.usuarioPessoaFisica.idAuth = this.idAuth
            this.form.usuarioPessoaFisica.login = this.login
          }
        }
      }
    },
    async salvar () {
      this.form.perfis.forEach(perfil => {
        // Se tipo perfil for Secretaria ou Fiscalização
        const isSecretariaOrFiscalizacaoOrAdministrador = perfil.idTipoPerfil === 4 || perfil.idTipoPerfil === 5 || perfil.idTipoPerfil === 10
        if (!isSecretariaOrFiscalizacaoOrAdministrador) delete perfil.matricula
        // Se tipo perfil for Transportador do tipo Pessoa Jurídica ou Transportador do tipo Pessoa Física
        const isTransportador = perfil.idTipoPerfil === 2 || perfil.idTipoPerfil === 9
        if (!isTransportador) {
          delete perfil.formaCobranca
        } else {
          perfil.dataAlteracaoFormaCobranca = new Date()
        }
      })
      try {
        if (!this.perfisListComp.length) {
          throw new CustomError('Por favor selecione pelo menos um perfil')
        }
        if ((this.email && this.login) && this.form.tipoPessoaStr === 'J') {
          const usuario = await this.salvarPopupUsuario()
          if (usuario.status === false) {
            // this.$utils.notifyError(error)
            throw new CustomError('Usuário: ' + usuario.msg)
          }
        }
        if (!this.form.tipoPessoaStr) {
          throw new CustomError('Selecione um tipo de pessoa.')
        }

        const pessoaPj = this.form.tipoPessoaStr === 'J' && this.$refs.formularioPessoaPj
          ? {
            ...this.$refs.formularioPessoaPj.getDadosFormulario(),
            cnpj: this.form.cnpj,
            nomeRazaoSocial: this.form.nomeRazaoSocial
          }
          : null

        const usuarioPessoaFisica = this.form.tipoPessoaStr === 'F' && this.$refs.formularioUsuarioPessoaFisica
          ? this.$refs.formularioUsuarioPessoaFisica.getDadosFormulario(this.form.tipoPessoaStr)
          : null
        const validationsSet = {
          F: () => {
            const formularioUsuarioPessoaFisica = this.$refs.formularioUsuarioPessoaFisica.getDadosFormulario()
            if (!formularioUsuarioPessoaFisica) {
              throw new CustomError('Existem erros de preenchimento no formulário')
            }
            this.$refs.formularioUsuarioPessoaFisica.validarUsuario()
          },
          J: () => {
            const messageErroPessoaPj = 'Existem erros de preenchimento no formulário de Dados da Empresa'
            if (!this.hideAuto) {
              if (!pessoaPj.cnpj) {
                this.$utils.notifyError(new CustomError(messageRequiredDefault('CNPJ', messageErroPessoaPj)))
                throw new CustomError(messageRequiredDefault('CNPJ', messageErroPessoaPj))
              }

              if (pessoaPj.cnpj && !this.$utils.validateCnpj(pessoaPj.cnpj)) {
                this.$utils.notifyError(new CustomError('CNPJ inválido'))
                throw new CustomError('CNPJ inválido')
              }

              if (!pessoaPj.nomeRazaoSocial) {
                this.$utils.notifyError(new CustomError(messageRequiredDefault('Razão Social', messageErroPessoaPj)))
                throw new CustomError(messageRequiredDefault('Razão Social', messageErroPessoaPj))
              }

              if (!pessoaPj.email) {
                this.$utils.notifyError(new CustomError(messageRequiredDefault('Email', messageErroPessoaPj)))
                throw new CustomError(messageRequiredDefault('Email', messageErroPessoaPj))
              }

              if (!pessoaPj.cep) {
                this.$utils.notifyError(new CustomError(messageRequiredDefault('CEP', messageErroPessoaPj)))
                throw new CustomError(messageRequiredDefault('CEP', messageErroPessoaPj))
              }

              if (!pessoaPj.numero) {
                this.$utils.notifyError(new CustomError(messageRequiredDefault('Numero', messageErroPessoaPj)))
                throw new CustomError(messageRequiredDefault('Numero', messageErroPessoaPj))
              }

              /** celuar obrigatorio */
              if (!pessoaPj.celular || pessoaPj.celular === '') {
                this.$utils.notifyError(new CustomError('O campo de celular é obrigatório'))
                throw new CustomError('O campo de celular é obrigatório')
              }

              if (!this.form.responsaveis.length) {
                this.$utils.notifyError(new CustomError('Registre os responsáveis pela empresa'))
                throw new CustomError('Registre os responsáveis pela empresa')
              }

              this.form.perfis.forEach(item => {
                if (!item.idTipoPerfil) {
                  this.$utils.notifyError(new CustomError('É necessário adicionar um ou mais perfis'))
                  throw new CustomError('É necessário adicionar um ou mais perfis')
                }
              })
              // valida dados da empresa jurídica
              if (this.$refs.formularioPessoaPj) {
                this.$refs.formularioPessoaPj.validarUsuario()
              }
            }
          }
        }

        if (!this.form.perfis.length) {
          throw new CustomError('É necessário adicionar um ou mais perfis')
        }
        validationsSet[this.form.tipoPessoaStr]()

        const payload = {
          pessoaPj, // dados da empresa
          perfisDaPessoa: this.form.perfis.map((perfil) => {
            let _status
            if (perfil._status !== 'remove' && this.$utils.isFinalId(perfil.id)) {
              _status = 'update'
            }
            return {
              ...perfil,
              ...(_status ? { _status } : {})
            }
          }),
          responsaveis: this.form.responsaveis,
          usuariosPj: this.form.usuariosVinculadoPj,
          usuarioPessoaFisica,
          tipoPessoa: this.form.tipoPessoaStr // F ou J
        }
        let validFiles = true
        const filesMissing = []
        const perfilTransportador = payload.perfisDaPessoa.find(perfil => perfil.idTipoPerfil === 9 || perfil.idTipoPerfil === 2)
        if (perfilTransportador) {
          for (const documentId in this.documentsEnum[this.form.tipoPessoaStr]) {
            for (const key in perfilTransportador._arquivos) {
              if (!perfilTransportador._arquivos[key] && !filesMissing.includes(parseInt(documentId))) filesMissing.push(parseInt(documentId))
            }
          }
          for (const arquivoKey in perfilTransportador._arquivos) {
            if (perfilTransportador._arquivos[arquivoKey]) filesMissing.splice(filesMissing.indexOf(filesMissing.find(fileId => fileId === parseInt(arquivoKey))), 1)
            const id = `arquivo:${arquivoKey}|idTipoPessoa:${perfilTransportador.idTipoPerfil}`
            payload[id] = perfilTransportador._arquivos[arquivoKey]
          }
          if (filesMissing.length && this.autoCadastro) validFiles = false
          if (!validFiles) {
            throw new CustomError('É necessário anexar todos os documentos')
          }
        }

        if (this.editingPjFoundAutoCadastro && this.form.usuariosVinculadoPj && this.form.usuariosVinculadoPj.length) {
          const usuario = {
            ...(this.form.usuariosVinculadoPj[this.form.usuariosVinculadoPj.length - 1] || {}),
            id: undefined
          }
          usuario.idPessoa = this.form.id
          usuario._status = 'insert'
          await this.$http.post(this.$http.normalizeUrl('/usuario-api/cadastrar-usuario'), this.$utils.parseToFormData(usuario))
          this.$utils.notifySuccess('Cadastro feito com sucesso')
          this.$emit('update:modal', false)
        } else {
          this.$emit(this.events.save, payload)
        }
      } catch (error) {
        throw new CustomError(error.message)
        // this.$utils.notifyError(error, 'Não foi possível concluir esta solicitação', 4000)
      }
    },
    async downloadAnexo (entity, id, attribute) {
      const { data: file } = await http.get(`${baseUrl}/Arquivo/${id}`)
      const fileBuffer = new Uint8Array(file.data.anexo.data)
      const blob = new Blob([fileBuffer], { type: file.data.contentType })
      const fileName = file.data.nomeArquivo
      const link = document.createElement('a')
      // Browsers that support HTML5 download attribute
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', fileName)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    },
    getPerfisSelecionaveis () {
      return this.form.perfis.map((perfil) => {
        perfil._descricao = (this.dynamicValues.tipoPerfilList.find(({ id }) => id === perfil.idTipoPerfil) || {}).descricao
        return perfil
      })
    },
    fecharPopupResponsavel () {
      this.$refs.formPopupResponsavel.startOrReset()
      this.popupResponsavel = { show: false, responsavelToEdit: undefined }
    },
    fecharPopupUsuario () {
      this.$refs.formPopupUsuario.startOrReset()
      this.popupAddUsuario = { show: false, usuarioToEdit: undefined }
    },
    salvarPopupResponsavel () {
      try {
        const formPopupResponsavel = this.$refs.formPopupResponsavel
        const responsavel = formPopupResponsavel.getDadosFormulario()

        // validar usuário sem considerar os campos ocultos
        formPopupResponsavel.validarUsuario(false)

        if (this.$utils.isFinalId(responsavel.id)) {
          responsavel._status = 'update'
        } else {
          responsavel._status = 'insert'
        }

        if (responsavel.id) {
          const responsaveis = []
          for (const r of this.form.responsaveis) {
            if (r.id === responsavel.id) {
              responsaveis.push(responsavel)
            } else {
              responsaveis.push(r)
            }
          }
          this.form.responsaveis = responsaveis
        } else {
          // cuidado ao mudar esta rotina, pois o backend faz distinção entre ids temporários(como este temp_id) e os ids reais. Veja o command: pessoa/cadastrar.js
          responsavel.id = `temp_id_${Date.now()}`
          this.form.responsaveis.push(responsavel)
        }
        this.fecharPopupResponsavel()
      } catch (error) {
        this.$utils.notifyError(error)
      }
    },
    salvarPopupUsuario () {
      try {
        let formPopupUsuario = ''
        /**  validação quando se faz o cadastro pelo auto cadasto(sem login)
         * se juridica testa tipo de insert para auto ou nao
         *  */
        if (this.form.tipoPessoaStr === 'J') {
          if (this.email && this.login) {
            formPopupUsuario = this.$refs.formPopupUsuarioAuto
          } else {
            formPopupUsuario = this.$refs.formPopupUsuario
          }
        } else {
          formPopupUsuario = this.$refs.formularioUsuarioPessoaFisica
        }
        const usuario = formPopupUsuario.getDadosFormulario()

        // validar usuário sem considerar os campos ocultos
        formPopupUsuario.validarUsuario(true)

        if (formPopupUsuario.$props.perfisSelecionaveis && formPopupUsuario.$props.perfisSelecionaveis.length) {
          if (!usuario.idsTipoDePerfil) {
            throw new CustomError('A seleção mínima de um perfil é obrigatória')
          }
          const setOfPerfisByIdTipoPerfil = {}
          for (const perfil of this.form.perfis) {
            setOfPerfisByIdTipoPerfil[perfil.idTipoPerfil] = perfil
          }
          usuario.perfis = usuario.idsTipoDePerfil.map((idTipoPerfil) => setOfPerfisByIdTipoPerfil[idTipoPerfil])
        }

        if (this.$utils.isFinalId(usuario.id)) {
          usuario._status = 'update'
        } else {
          usuario._status = 'insert'
        }

        if (usuario.id) {
          const usuarios = []
          for (const u of this.form.usuariosVinculadoPj) {
            if (u.id === usuario.id) {
              usuarios.push(usuario)
            } else {
              usuarios.push(u)
            }
          }
          this.form.usuariosVinculadoPj = usuarios
        } else {
          // cuidado ao mudar esta rotina, pois o backend faz distinção entre ids temporários(como este temp_id) e os ids reais. Veja o command: pessoa/cadastrar.js
          usuario.id = `temp_id_${Date.now()}`
          this.form.usuariosVinculadoPj.push(usuario)
        }
        if (!this.email && !this.login) {
          this.fecharPopupUsuario()
        }

        return true
      } catch (error) {
        this.$utils.notifyError(error)
        const user = {
          status: false,
          msg: error
        }
        return user
      }
    },
    mostrarAnexoLicAmbientalComp (perfil) {
      return (this.isTipoPerfil(perfil, 'Gerador') &&
        this.isTipoPerfil(perfil, 'Destino Final') &&
          ['ATT', 'Aterro', 'Recicladora'].includes(perfil.tipoDestinoFinal))
    },
    getTipoPerfilDisponivelList (perfil) {
      const tipoDePerfilSelecionadoIds = this.perfisListComp.map(({ idTipoPerfil }) => idTipoPerfil)
      return this.dynamicValues.tipoPerfilList.filter((tipoPerfil) => {
        return perfil.idTipoPerfil === tipoPerfil.id || !tipoDePerfilSelecionadoIds.includes(tipoPerfil.id)
      })
    },
    removerUsuario (usuario) {
      const usuariosVinculadoPj = []
      for (const u of this.form.usuariosVinculadoPj) {
        if (u.id === usuario.id) {
          usuariosVinculadoPj.push({
            ...usuario,
            _status: 'remove'
          })
        } else {
          usuariosVinculadoPj.push(u)
        }
      }
      this.form = {
        ...this.form,
        usuariosVinculadoPj
      }
    },
    addPerfil () {
      this.form.perfis.unshift({
        _status: 'insert',
        _arquivos: {}
      })
    },
    removePerfil (perfil) {
      try {
        const usuariosPjVinculadosPerfilRemovido = []
        for (const usuarioPj of (this.form.usuariosVinculadoPj || [])) {
          if (usuarioPj._status !== 'remove' && usuarioPj.idsTipoDePerfil.includes(perfil.idTipoPerfil)) {
            usuariosPjVinculadosPerfilRemovido.push(usuarioPj)
          }
        }
        if (usuariosPjVinculadosPerfilRemovido.length) {
          throw new CustomError(`Não foi possível remover este perfil, pois ele está atribuíto ao(s) usuário(s): ${usuariosPjVinculadosPerfilRemovido.map(({ nome }) => nome).join(', ')}!`)
        }
        const perfis = []
        for (const p of this.perfisListComp) {
          if (p.idTipoPerfil === perfil.idTipoPerfil) {
            perfis.push({
              ...p,
              ...perfil,
              _status: 'remove'
            })
            if (this.form.usuariosVinculadoPj) {
              for (const usuario of this.form.usuariosVinculadoPj) {
                const indexPerfil = usuario.perfis.findIndex(({ idTipoPerfil }) => idTipoPerfil === perfil.idTipoPerfil)
                const indexTipoPerfil = usuario.idsTipoDePerfil.indexOf(perfil.idTipoPerfil)
                if (indexPerfil >= 0) {
                  usuario.perfis.splice(indexPerfil, 1)
                }
                if (indexTipoPerfil >= 0) {
                  usuario.idsTipoDePerfil.splice(indexTipoPerfil, 1)
                }
                if (this.$utils.isFinalId(usuario.id) && usuario._status !== 'remove') {
                  usuario._status = 'update'
                }
              }
            }
          } else {
            perfis.push(p)
          }
        }
        this.form = {
          ...this.form,
          perfis
        }
      } catch (error) {
        this.$utils.notifyError(error, 'Não foi possível remover este perfil', 5000)
      }
    },
    isTipoPerfil (perfil, tipoAVerificar = null) {
      const tipoPerfil = this.dynamicValues.tipoPerfilList.find(({ id }) => id === perfil.idTipoPerfil)
      return (tipoPerfil || {}).descricao === tipoAVerificar
    },
    putDynamicValue (key, value) {
      this.dynamicValues = {
        ...this.dynamicValues,
        [key]: value
      }
    },
    calculateDataAlteracaoFormaCobranca (dateString) {
      const datePlus90Days = moment(new Date(dateString)).add(90, 'days')
      if (moment().valueOf() <= datePlus90Days.valueOf()) {
        return {
          isValid: false,
          message: `Atenção, o tipo de cobrança só poderá ser alterado em ${moment().locale('pt-br').to(datePlus90Days, true)}`
        }
      } else {
        return {
          isValid: true,
          message: ''
        }
      }
    },
    toggleDefault (index) {
      if (index && this.$refs[`tooltip-${index}`]) {
        this.$refs[`tooltip-${index}`][0].instance.toggle()
      }
    },
    toggleUploader (index) {
      if (index && this.$refs[`tooltip-uploader-${index}`] && Array.isArray(this.$refs[`tooltip-uploader-${index}`]) && this.$refs[`tooltip-uploader-${index}`].length) {
        this.$refs[`tooltip-uploader-${index}`][0].instance.toggle()
      }
    }
  }
}
</script>
