import _ from 'lodash'
import { parseFilterParams, parseFilterParamsSQL } from './dxDatasourceHelper'

/**
 * Metodo para obter o objeto com os parametros da requisicao apartir dos
 * parametros de busca do devextreme.
 * @param {Object} loadOptions - Os parametros de busca do datasource do
 * devextreme.
 * @returns {Object} Os parametros da requisicao.
 */
export function getParamsFromLoadOptions (loadOptions, sql, parseFilter = true) {
  let params = {}
  if (loadOptions?.customQueryParams) {
    params = { ...params, ...loadOptions.customQueryParams }
  }

  const order = loadOptions && loadOptions.sort && loadOptions.sort.length
    ? loadOptions.sort.map(order =>
      [typeof order.selector === 'function'
        ? order.selector()
        : order.selector, order.desc ? 'DESC' : 'ASC'])
    : undefined
  if (order && order[0] instanceof Array && order[0][0]) {
    params.order = JSON.stringify(order)
  }

  if (!_.isEmpty(loadOptions) && !sql) {
    params.limit = loadOptions.take ? loadOptions.take : 10
    params.offset = loadOptions.skip ? loadOptions.skip : 0

    if (loadOptions.searchOperation && !_.isEmpty(loadOptions.searchExpr) && !_.isEmpty(loadOptions.searchValue)) {
      params.where = parseFilterParams([loadOptions.searchExpr, loadOptions.searchOperation, loadOptions.searchValue]).filterParams
    } else {
      params.where = parseFilter ? loadOptions.filter ? parseFilterParams(loadOptions.filter).filterParams : {} : loadOptions.filter
    }
  } else if (sql) {
    /**
   *  faz request para obter parametros do formato RAW-SQL
   */
    params.limit = loadOptions.take ? loadOptions.take : 10
    params.offset = loadOptions.skip ? loadOptions.skip : 0

    if (loadOptions.searchOperation && !_.isEmpty(loadOptions.searchExpr) && !_.isEmpty(loadOptions.searchValue)) {
      params.where = parseFilterParamsSQL([loadOptions.searchExpr, loadOptions.searchOperation, loadOptions.searchValue])
    } else {
      params.where = loadOptions.filter ? parseFilterParamsSQL(loadOptions.filter) : {}
    }
  }

  return params
}

/**
 * Método responsável por fazer parser no filtro padrão do devextreme para um filtro SQL-LIKE
 */
export function parseRawSqlFilter (filterParam, fields) {
  const parseDevExtremeOperators = (operator, parseLike = false) => {
    const parsedOperator = {
      contains: 'LIKE',
      notcontains: 'NOT LIKE',
      startswith: parseLike ? 'LIKESTARTSWITH' : 'LIKE',
      endswith: parseLike ? 'LIKESENDSWITH' : 'LIKE',
      '=': '=',
      '<>': '<>',
      '<': '<',
      '>': '>',
      '<=': '<=',
      '>=': '>='
    }[operator]
    return parsedOperator
  }
  filterParam = filterParam.flat(Infinity)
  filterParam.forEach((param, index) => {
    if (parseDevExtremeOperators(param)) {
      if (filterParam[index + 1] && typeof filterParam[index + 1] !== 'number') {
        if (parseDevExtremeOperators(filterParam[index], true) === 'NOT LIKE') {
          filterParam[index + 1] = `%${filterParam[index + 1]}%`
        }
        if (parseDevExtremeOperators(filterParam[index], true) === 'LIKE') {
          filterParam[index + 1] = `%${filterParam[index + 1]}%`
        }
        if (parseDevExtremeOperators(filterParam[index], true) === 'LIKESTARTSWITH') {
          filterParam[index + 1] = `${filterParam[index + 1]}%`
          filterParam[index] = 'LIKE'
        }
        if (parseDevExtremeOperators(filterParam[index], true) === 'LIKESENDSWITH') {
          filterParam[index + 1] = `%${filterParam[index + 1]}`
          filterParam[index] = 'LIKE'
        }
        filterParam[index + 1] = `'${filterParam[index + 1]}'`
      }
    }
    /**
     * Troca de camelCase para snake_case para
     * filtrar como no banco de dados
     */
    if (fields[filterParam[index]]) {
      filterParam[index] = fields[filterParam[index]]
    }
    /**
     * Traduz operadores do devextreme para o geoserver
     */
    filterParam[index] = parseDevExtremeOperators(param) ? parseDevExtremeOperators(param) : filterParam[index]
  })
  return filterParam.join(' ')
}
