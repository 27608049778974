import { getParamsFromLoadOptions } from '@/util/loadOptionsParams'
import { doRequest, http } from '../../../pluggables/http'
import config from 'config'
import storeHelper from '@/helper/storeHelper'

/**
 * Metodo de comando, com o objetivo de realizar a requisica de obter uma lista
 * de obras.
 * @param {Object} params - Os parametros da requisicao.
 * @param {Object} [params.where={}] - Os parametros de where da busca.
 * @param {Object} [params.loadOptions=null] - Os parametros de busca do
 * datasource do devextreme.
 * @param {string[]} params.displayFields - Os campos que vao ser usados para
 * apresentar os valores.
 * @returns {Object} O resultado da requisicao.
 */
export async function getEnderecoObras ({ loadOptions = null, loadOnlyCurrentTransportador = true }) {
  const params = getParamsFromLoadOptions(loadOptions)
  const baseUrl = config.baseUrl

  if (loadOnlyCurrentTransportador) {
    params.attributes = [{ fn: 'DISTINCT', col: 'endereco', as: 'endereco' }]
    params.order = JSON.stringify([['endereco', 'asc']])
    params.subQuery = false
    params.where = {
      ...params.where,
      situacao: ['Alocada', 'Enviada', 'Resíduo Rejeitado', 'Cancelamento Recusado', 'Cancelamento Solicitado', 'Solicitada']
    }
  }
  const res = await http.post(`${baseUrl}/ViewSolicitacaoTransporte/find`, params)

  if (!res.data.success) {
    throw new Error(res.error)
  }
  return { data: res.data.data.rows, totalCount: res.data.data.count }
}

/**
 * Metodo de comando, com o objetivo de realizar a requisica de obter uma lista
 * de obras.
 * @param {Object} params - Os parametros da requisicao.
 * @param {Object} [params.where={}] - Os parametros de where da busca.
 * @param {Object} [params.loadOptions=null] - Os parametros de busca do
 * datasource do devextreme.
 * @param {string[]} params.displayFields - Os campos que vao ser usados para
 * apresentar os valores.
 * @returns {Object} O resultado da requisicao.
 */
export async function getNomeObras ({ loadOptions = null, loadOnlyCurrentTransportador = true }) {
  const params = getParamsFromLoadOptions(loadOptions)
  const baseUrl = config.baseUrl

  if (loadOnlyCurrentTransportador) {
    params.attributes = [{ fn: 'DISTINCT', col: 'nomeObra', as: 'nomeObra' }]
    params.order = JSON.stringify([['nomeObra', 'asc']])
    params.subQuery = false
    params.where = {
      ...params.where,
      situacao: ['Alocada', 'Enviada', 'Resíduo Rejeitado', 'Cancelamento Recusado', 'Cancelamento Solicitado', 'Solicitada']
    }
  }
  const res = await http.post(`${baseUrl}/ViewSolicitacaoTransporte/find`, params)

  if (!res.data.success) {
    throw new Error(res.error)
  }
  return { data: res.data.data.rows, totalCount: res.data.data.count }
}

/**
 * Metodo de comando, com o objetivo de realizar a requisica de obter uma lista
 * de cacambas com solicitacoes de transporte que pode retirar a cacamba.
 * @param {Object} params - Os parametros da requisicao.
 * @param {Object} [params.where={}] - Os parametros de where da busca.
 * @param {Object} [params.loadOptions=null] - Os parametros de busca do
 * datasource do devextreme.
 * @returns {Object} O resultado da requisicao.
 */
export async function getCacambasSolicitacao ({ where = {}, loadOptions = null }) {
  const params = loadOptions !== null ? getParamsFromLoadOptions(loadOptions) : {}
  params.where = { ...params.where, ...where }

  const promises = [
    doRequest({
      method: 'post',
      url: 'Cacamba/find',
      params: {
        ...params,
        fields: ['id', 'numeroIdentificacao'],
        include: {
          model: 'SolicitacaoTransporte',
          as: 'solicitacoesTransporte',
          attributes: [],
          required: true,
          where: {
            situacao: {
              in: [
                'Alocada', 'Retirada', 'Cancelamento Solicitado',
                'Cancelamento Recusado', 'Cancelada'
              ]
            }
          }
        }
      }
    }),
    doRequest({
      method: 'post',
      url: 'Veiculo/find',
      params: {
        ...params,
        fields: ['id', 'numeroIdentificacao'],
        subQuery: true,
        include: {
          model: 'SolicitacaoTransporte',
          as: 'solicitacoesTransporte',
          required: false,
          where: {
            situacao: {
              in: [
                'Alocada', 'Retirada', 'Cancelamento Solicitado',
                'Cancelamento Recusado', 'Cancelada'
              ]
            }
          }
        }
      }
    })
  ]
  try {
    const promisesResult = await Promise.all(promises)
    return { data: promisesResult[0].data.rows.concat(promisesResult[1].data.rows), totalCount: promisesResult[0].data.count + promisesResult[1].data.count }
  } catch (error) {
    console.error(error)
    throw new Error(error)
  }
}

/**
 * Metodo de comando, com o objetivo de realizar a requisica de obter uma lista
 * de veiculos com solicitacao de transporte que pode retirar a cacamba.
 * @param {Object} params - Os parametros da requisicao.
 * @param {Object} [params.where={}] - Os parametros de where da busca.
 * @param {Object} [params.loadOptions=null] - Os parametros de busca do
 * datasource do devextreme.
 * @returns {Object} O resultado da requisicao.
 */
export async function getVeiculosSolicitacao ({ where = {}, loadOptions = null }) {
  const params = loadOptions !== null ? getParamsFromLoadOptions(loadOptions) : {}
  params.where = { ...params.where, ...where }

  const res = await doRequest({
    method: 'post',
    url: 'Veiculo/find',
    params: {
      fields: ['id', 'placa', 'numeroIdentificacao'],
      where: params.where,
      include: {
        model: 'SolicitacaoTransporte',
        as: 'solicitacoesTransporte',
        attributes: [],
        required: true,
        where: {
          situacao: {
            in: [
              'Alocada', 'Retirada', 'Cancelamento Solicitado',
              'Cancelamento Recusado', 'Cancelada'
            ]
          }
        }
      }
    }
  })

  if (!res.success) {
    throw new Error(res.error)
  }

  return { data: res.data.rows, totalCount: res.data.count }
}

/**
 * Metodo de comando, com o objetivo de realizar a requisica de obter uma lista
 * de solicitações de transporte que podem retirar a cacamba.
 * @param {Object} loadOptions - Os parametros de busca do
 * datasource do devextreme.
 * @returns {Object} O resultado da requisicao.
 */
export async function getSolicitacoesTransporteComCacamba (loadOptions) {
  const params = getParamsFromLoadOptions(loadOptions)
  const transportadorPerfil = storeHelper.getCurrentPerfisTransportador(true) || {}
  const isSecretaria = storeHelper.isSecretaria
  const isAdministrador = storeHelper.isAdministrador
  params.subQuery = false
  params.where = {
    and: [
      ...(!isSecretaria || !isAdministrador ? [{ idTransportador: transportadorPerfil?.id }] : []),
      {
        situacao: {
          in: [
            'Alocada', 'Retirada', 'Cancelamento Solicitado',
            'Cancelamento Recusado', 'Cancelada'
          ]
        }
      },
      params.where
    ]
  }
  params.include = [
    {
      model: 'Obra',
      as: 'obra',
      attributes: ['id', 'nomeObra', 'endereco', 'cep'],
      duplicating: false,
      include: {
        model: 'Perfil',
        as: 'gerador',
        attributes: ['id'],
        duplicating: false,
        include: {
          model: 'Pessoa',
          as: 'pessoa',
          attributes: ['id', 'nomeRazaoSocial'],
          duplicating: false
        }
      }
    },
    {
      model: 'Cacamba',
      as: 'cacamba',
      attributes: ['numeroIdentificacao']
    },
    {
      model: 'Veiculo',
      as: 'veiculo',
      attributes: ['placa', 'tipo', 'numeroIdentificacao']
    },
    {
      model: 'TipoResiduo',
      as: 'residuoPredominante',
      attributes: ['id', 'nome'],
      include: {
        model: 'ClasseMaterial',
        as: 'classeMaterial',
        required: true,
        attributes: ['id', 'classe']
      }
    }
  ]
  params.fields = [
    'id', 'situacao', 'idObra',
    'idCacamba', 'motivoSolCancelamento', 'dataHoraSolicitacao', 'editado'
  ]

  const res = await doRequest({
    method: 'post',
    url: 'SolicitacaoTransporte/find',
    params
  })

  if (!res.success) {
    throw new Error(res.error)
  }

  return { data: res.data.rows, totalCount: res.data.count }
}

/**
 * Metodo de comando, com o objetivo de realizar a requisica de obter uma
 * solicitacao de transporte que podem retirar a cacamba pelo id.
 * @param {number} id - O id da solicaitacao.
 * @returns {Object} O resultado da requisicao.
 */
export async function getSolicitacaoTransporteById (id) {
  const result = await doRequest({
    method: 'post',
    url: 'SolicitacaoTransporte/find',
    params: {
      include: [
        {
          model: 'Residuo',
          as: 'residuos',
          attributes: ['volumeAproximadoM3', 'idTipoResiduo'],
          required: false,
          include: {
            model: 'TipoResiduo',
            as: 'tipoResiduo',
            attributes: ['id', 'nome'],
            duplicating: false,
            include: {
              model: 'ClasseMaterial',
              as: 'classeMaterial',
              attributes: ['id', 'classe'],
              duplicating: false,
              include: {
                model: 'TipoResiduo',
                as: 'tipoResiduos',
                attributes: ['nome'],
                duplicating: false
              }
            }
          }
        },
        {
          model: 'Perfil',
          as: 'destinoFinal',
          attributes: ['id'],
          include: {
            model: 'Pessoa',
            as: 'pessoa',
            required: false,
            attributes: ['id', 'nomeRazaoSocial']
          }
        },
        {
          model: 'Veiculo',
          as: 'veiculo',
          attributes: ['id', 'placa', 'tipo', 'poliguindaste', 'numeroIdentificacao']
        },
        {
          model: 'TipoResiduo',
          as: 'residuoPredominante',
          attributes: ['id', 'nome']
        }
      ],
      where: { id },
      fields: ['id', 'nomeFotoCacamba', 'observacao', 'situacao', 'dataHoraAlocacao']
    }
  })

  return {
    success: result.success,
    data: result.data.rows[0] || null
  }
}

/**
 * Metodo de comando, com o objetivo de realizar a requisica de obter uma lista
 * de destinos finais disponiveis.
 * @param {Object} params - Os parametros da requisicao.
 * datasource do devextreme.
 * @returns {Object} O resultado da requisicao.
 */
export async function getDestinosFinais (params) {
  const res = await doRequest({
    method: 'post',
    url: 'ViewDestinoFinal/find',
    params: {
      fields: ['id', 'nomeRazaoSocial'],
      where: params.where
    }
  })

  if (!res.success) {
    throw new Error(res.error)
  }

  return { data: res.data.rows, totalCount: res.data.count }
}

/**
 * Metodo de comando, com o objetivo de realizar a requisica de obter uma lista
 * de veiculos disponiveis.
 * @param {Object} params - Os parametros da requisicao.
 * datasource do devextreme.
 * @returns {Object} O resultado da requisicao.
 */
export async function getAvailableVeiculos (params) {
  const defaultWhere = {
    or: [
      { '$solicitacoesTransporte.id_veiculo$': null },
      {
        '$solicitacoesTransporte.situacao$': {
          in: ['Destinada', 'Recusada', 'Cancelada', 'Resíduo Rejeitado']
        }
      }
    ]
  }

  const res = await doRequest({
    method: 'post',
    url: 'Veiculo/find',
    params: {
      subQuery: false,
      where: {
        or: [
          params.where,
          {
            and: [
              { ...params.where, id: undefined },
              defaultWhere
            ]
          }
        ]
      },
      include: [
        {
          model: 'SolicitacaoTransporte',
          as: 'solicitacoesTransporte',
          required: false
        },
        {
          model: 'Perfil',
          as: 'perfil',
          attributes: [],
          required: true
        }
      ],
      fields: ['id', 'placa', 'tipo', 'poliguindaste', 'numeroIdentificacao']
    }
  })

  if (!res.success) {
    throw new Error(res.error)
  }

  return { data: res.data.rows, totalCount: res.data.count }
}

/**
 * Metodo de comando, com o objetivo de realizar a requisicao de salvar uma
 * solicitacao de transporte.
 * @param {Object} params - Os parametros da requisicao.
 * @returns {Object} O resultado da requisicao.
 */
export function saveSolicitacaoTransporte (params = {}) {
  return doRequest({
    method: 'put',
    url: `retirar_cacamba/${params.get('id')}`,
    params,
    options: {
      cleanObject: false,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  })
}
