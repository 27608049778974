<template>
  <div class="alocar-cacamba-list">
    <div class="md-layout">
      <div class="md-layout-item">
        <h2 class="crud-title">Alocações de caçamba</h2>
      </div>
    </div>

    <simple-grid
      ref="simpleGrid"
      :entity="this.entity"
      :grid-data="gridData"
      :editable="false"
      :deletable="false"
    ></simple-grid>

    <popup-cancel-solicitacao
      :active.sync="popupCancelamentoCtrVisible.show"
      :solicitacao="popupCancelamentoCtrVisible.ctr"
      title="Solicitação cancelamento do CTR"
      @updated-solicitacao="onPopupUpdatedSolicitacao"/>

  </div>
</template>
<style lang="scss">
.alocar-cacamba-list{
  .icone-motivo-cancelamento{
    padding: 0 8px;
    margin: 5px;
    border-radius: 50%;
    background: #069;
    color: #fff;
  }
}
</style>

<script>
import alocarCacambaMixin from './aloca-cacamba-mixin'
import mixinCrud from '../mixin-crud'
import { SimpleGrid } from '@/components'
import PopupCancelSolicitacao from '@/components/SolicitacaoTransporte/PopupCancelSolicitacao'

export default {
  components: {
    SimpleGrid,
    PopupCancelSolicitacao
  },
  mixins: [
    alocarCacambaMixin,
    mixinCrud
  ],
  data () {
    return {
      popupCancelamentoCtrVisible: {
        show: false,
        ctr: null
      }
    }
  },
  methods: {
    /**
     * Metodo de callback de quando um popup altera valores nas solicitacoes.
     * Com o objetivo de recarregar a lista de solicitacoes.
     */
    onPopupUpdatedSolicitacao () {
      this.$refs.simpleGrid.$refs.gridInstance.instance.getDataSource().reload()
    }
  }
}
</script>
