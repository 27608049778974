<template>
  <div class="ocorrencia-details">
    <div class="form-popup">
      <h3 class="popup-title">{{title}}</h3>
      <model-file-uploader
        class="fileUploaderCacamba"
        buttonLabel="Anexar Foto"
        dragAndDropLabel="ou arraste aqui"
        v-model="fotoCacamba"
        accept="image/*"
        attribute="fotoCacamba"
        entity="SolicitacaoTransporte"
        @onRemove="() => { this.attachmentPreview = null }"
        />
        <div v-if="attachmentPreview" style="text-align: center;">
          <img :src="attachmentPreview" alt="preview" class="thumbnail" />
        </div>
      <dx-select-box
        v-model="irregularidadeTextArea"
        :items="['Caçamba estacionada em local inadequado', 'Caçamba com resíduo inapropriado', 'Caçamba com excesso de resíduo', 'Caçamba sem CTR']"
        placeholder="Descreva a irregularidade"
        class="texAreaIrregularidade"
      />
        <div class="option">
          <label>Existe algum identificador na lateral da caçamba?</label>
          <DxRadioGroup
            :items="typeSelection"
            layout="horizontal"
            @value-changed="identificadorCheckBoxChanged"
            v-model="selectedType"/>
        </div>
        <DxLookup
          class="showNumeroIdentificacao"
          placeholder="Nº de identificação"
          :data-source="fieldsBind.cacamba.dataSource"
          display-expr="numeroIdentificacao"
          v-model="numeroIdentificacao"
          v-if="showNumeroIdentificacao"
          />
        <DxTextArea
          v-show="showIdentificacaoCacamba"
          class="texAreaIrregularidade"
          placeholder="Ex: Nome da transportadora"
          v-model="identificacaoCacamba"/>
        <DxToast
          :width="600"
          :displayTime="4000"
          :visible.sync="showToast"
          :type="toastType"
          :message="warningMessage"
          />
        <footer>
          <dx-button type="success" @click="save">Salvar</dx-button>
        </footer>
    </div>
  </div>
</template>
<script>

import config from 'config'
import { doRequest, http } from '@/pluggables/http'
import DxButton from 'devextreme-vue/button'
import { DxTextArea, DxLookup, DxSelectBox } from 'devextreme-vue'
import DxRadioGroup from 'devextreme-vue/radio-group'
import ModelFileUploader from '@/components/Form/ModelFileUploader'
import { DxToast } from 'devextreme-vue/toast'
import CustomStore from 'devextreme/data/custom_store'
import * as esriGeocode from 'esri-leaflet-geocoder'

const baseUrl = config.baseUrl
export default {
  name: 'popup-cacamba-irregular',
  components: {
    DxSelectBox,
    DxButton,
    DxTextArea,
    DxRadioGroup,
    ModelFileUploader,
    DxToast,
    DxLookup
  },
  props: {
    title: {
      type: String,
      required: true
    },
    userData: {
      type: Object,
      required: true
    },
    marker: {
      type: Object,
      required: true
    },
    find: {
      type: Function
    },
    setLoading: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      address: null,
      fieldsBind: {
        cacamba: {
          dataSource: {
            store: new CustomStore({
              key: 'id',
              load: (loadOptions) => {
                return this.$utils.wrapRequestForGrid(this.find({
                  entity: 'Cacamba',
                  params: {
                    fields: ['id', 'numeroIdentificacao'],
                    where: {
                      ...(loadOptions.searchValue ? { numeroIdentificacao: { iLike: `%${loadOptions.searchValue}%` } } : {})
                    }
                  },
                  loadOptions
                }))
              },
              byKey: async key => {
                return http.post(`${baseUrl}/Cacamba/find`, {
                  fields: ['id', 'numeroIdentificacao']
                })
              }
            }),
            paginate: true,
            pageSize: 5
          }
        }
      },
      maskRule: {
        H: /[a-z]/i
      },
      toastType: null,
      idCacamba: null,
      warningMessage: null,
      showToast: false,
      fotoCacamba: [],
      showIdentificacaoCacamba: null,
      identificacaoCacamba: null,
      showNumeroIdentificacao: null,
      numeroIdentificacao: null,
      selectedType: null,
      typeSelection: ['Sim', 'Não'],
      irregularidadeTextArea: null,
      attachmentPreview: null
    }
  },
  computed: {
    style () {
      return {
        width: this.fieldSetWidth,
        height: this.fieldSetHeight
      }
    }
  },
  watch: {
    numeroIdentificacao (value) {
      if (value) this.idCacamba = value.id
    },
    async fotoCacamba (value) {
      if (value && Array.isArray(value) && value[0]) {
        const toBase64 = file => new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = () => resolve(reader.result)
          reader.onerror = error => reject(error)
        })
        this.attachmentPreview = await toBase64(value[0])
      }
    }
  },
  methods: {
    /**
     * Metodo para obter o geojson da visualizacao atual do mapa.
     * @returns {Object} O geoJson da visualizacao atual do mapa.
     */
    getBoundsGeoJson (latlong) {
      return {
        type: 'Point',
        coordinates: [latlong.lng, latlong.lat],
        srid: '4326',
        crs: { type: 'name', properties: { name: 'EPSG:4326' } }
      }
    },
    identificadorCheckBoxChanged (payload) {
      if (payload.value === 'Sim') {
        this.showNumeroIdentificacao = true
        this.identificacaoCacamba = null
        this.showIdentificacaoCacamba = false
      }
      if (payload.value === 'Não') {
        this.showNumeroIdentificacao = false
        this.idCacamba = null
        this.numeroIdentificacao = null
        this.showIdentificacaoCacamba = true
      }
    },
    async getReverseGeocode () {
      const reverseGeocode = esriGeocode.reverseGeocode().latlng([this.marker._latlng.lat, this.marker._latlng.lng])
      return new Promise((resolve, reject) => {
        reverseGeocode.run((error, result) => {
          if (error) {
            reject(error)
          }
          resolve((result))
        })
      })
    },
    async save () {
      this.setLoading(true)
      const formData = new FormData()
      const file = (this.fotoCacamba && this.fotoCacamba[0]) || null
      file !== null && formData.set('foto', file)
      await this.$nextTick(() => {
        if (this.identificacaoCacamba) {
          formData.set('identificacaoCacamba', this.identificacaoCacamba)
        }
        if (this.idCacamba) {
          formData.set('idCacamba', this.idCacamba)
        }
        formData.set('local', this.address)
        formData.set('irregularidadeCom', 'Caçamba')
        formData.set('descricaoIrregularidade', this.irregularidadeTextArea)
        formData.set('idUsuarioRegistro', this.userData.id)
        formData.set('shape', JSON.stringify(this.getBoundsGeoJson(this.marker._latlng)))
      })
      let result
      if (this.fotoCacamba.length === 0) {
        this.showToast = true
        this.toastType = 'error'
        this.warningMessage = 'É obrigatório anexar uma foto para a ocorrência'
        this.setLoading(false)
        throw new Error('Anexo inválido')
      }
      if (!this.irregularidadeTextArea) {
        this.showToast = true
        this.toastType = 'error'
        this.warningMessage = 'Insira uma descrição para a irregularidade'
        this.setLoading(false)
        throw new Error('Descrição Inválida')
      }
      if (!this.selectedType) {
        this.showToast = true
        this.toastType = 'error'
        this.warningMessage = 'Selecione se há um identificador na caçamba'
        this.setLoading(false)
        throw new Error('Tipo identificador inválido')
      }
      if (this.selectedType === 'Sim' && !this.numeroIdentificacao) {
        this.showToast = true
        this.toastType = 'error'
        this.warningMessage = 'Insira um número de identificação'
        throw new Error('Campo identificador inválido')
      }
      if (this.selectedType === 'Não' && !this.identificacaoCacamba) {
        this.showToast = true
        this.toastType = 'error'
        this.warningMessage = 'Descreva um identificador para a caçamba'
        this.setLoading(false)
        throw new Error('Campo identificador inválido')
      }
      try {
        result = await doRequest({
          method: 'post',
          url: 'Ocorrencia',
          params: formData,
          options: {
            cleanObject: false,
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        })
      } catch (err) {
        throw new Error(err)
      }
      if (result.success) {
        this.$emit('removeMarker', this.marker)
        this.showToast = true
        this.toastType = 'success'
        this.warningMessage = 'Ocorrência salva'
        this.$emit('taskSaved')
      }
      if (!result.success) {
        this.idCacamba = null
        this.showToast = true
        this.toastType = 'error'
        this.warningMessage = 'Erro ao salvar caçamba'
      }
      this.setLoading(false)
    }
  },
  async mounted () {
    const getReverseGeocode = await this.getReverseGeocode()
    this.address = getReverseGeocode.address.Match_addr
  }
}
</script>

<style scoped lang="scss">
.thumbnail {
  max-width:450px;
  max-height:150px;
  width: auto;
  height: auto;
  border-radius: 15px;
}
</style>
