import { http } from '@/pluggables/http'
import config from 'config'

const loginUrl = config.baseUrl + '/login'

const clearBrowserData = () => {
  // Limpa os cookies
  document.cookie.split(';').forEach(c => {
    document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/')
  })

  localStorage.removeItem('userToken')
  localStorage.removeItem('popupViewed')
}

export default {
  loginAction ({ commit, dispatch }, loginData) {
    clearBrowserData()

    return new Promise((resolve, reject) => {
      commit('auth_request')
      http.post(loginUrl, {
        username: loginData.username,
        password: loginData.password,
        idPessoa: loginData.idPessoa,
        authType: loginData.type,
        idAuth: loginData.idAuth,
        token: loginData.accessToken
      }).then(async res => {
        const { token, user } = res.data.data

        localStorage.setItem('userToken', token)
        document.cookie = `auth-token=${token}; expires=${new Date(Date.now() + 24 * 3600000).toUTCString()}; path=/`

        commit('auth_success', { token, user })
        dispatch('getUserLogged')
        resolve(res)
      }).catch(err => {
        commit('auth_error')
        clearBrowserData()
        reject(err)
      })
    })
  },
  async getUserLogged ({ commit, state }) {
    try {
      commit('auth_request')
      const res = await http.post('/validate-login')
      if (res.data) {
        commit('auth_success', { ...(state.user || {}), user: res.data.user })
      } else {
        throw new Error()
      }
      return res
    } catch (e) {
      commit('auth_error')
      clearBrowserData()
      throw e
    }
  },
  logoutAction ({ commit }) {
    return new Promise(resolve => {
      commit('logout')
      clearBrowserData()
      resolve()
    })
  },
  recoveryAction ({ commit }, param) {
    return new Promise((resolve, reject) => {
      commit('auth_request')
      http.post(config.baseUrl + '/login-recovery',
        {
          username: param.email,
          idPessoa: param.idPessoa
        }
      ).then((res) => {
        commit('auth_success', { res })
        resolve(res)
      }).catch(err => {
        commit('auth_error')
        clearBrowserData()
        reject(err)
      })
    })
  },
  passwordUpadateAction ({ commit }, param) {
    return new Promise((resolve, reject) => {
      commit('auth_request')
      http.post(config.baseUrl + '/login-update', param
      ).then((res) => {
        commit('auth_success', { res })
        resolve(res)
      }).catch(err => {
        commit('auth_error')
        clearBrowserData()
        reject(err)
      })
    })
  },
  confirmedEmailAction ({ commit }, param) {
    return new Promise((resolve, reject) => {
      commit('auth_request')
      http.post(config.baseUrl + '/login-confirme-email', param
      ).then((res) => {
        commit('auth_success', { res })
        resolve(res)
      }).catch(err => {
        commit('auth_error')
        clearBrowserData()
        reject(err)
      })
    })
  }
}
