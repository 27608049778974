<template>
  <div>
    <div class="small-padding-botton">
      <dx-button
        v-if="showCLTRCC()"
        type="primary"
        @click="onClickCLTRCC">
        Gerar CLTRCC
      </dx-button>
    </div>
    <dx-data-grid
      ref="simpleGrid"
      :columns="gridData.columns"
      :data-source="gridData.dataSource"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :remote-operations="true"
      :row-alternation-enabled="true"
      :column-auto-width="true"
    />
    <!-- Editar documentos pendentes  -->
    <dx-popup
      :title="popupDocumento.title"
      :height="popupHeight"
      :width="popupWidth"
      :fullScreen="popupFullScreen"
      :onShowing="popupOnShowing"
      :showCloseButton="true"
      :visible.sync="popupDocumento.visible"
      :hide-on-outside-click="false"
      :drag-enabled="false"
      :show-title="true"
      @hidden="onPopupClosing"
    >
      <DxToolbarItem
        :visible="popupDocumento.saveButtonVisible"
        widget="dxButton"
        toolbar="bottom"
        location="after"
        :options="{ text: !popupDocumento.noFile ? 'Alterar' : 'Adicionar', onClick:saveDocumentos, type: 'primary' }"
      />
      <template #content>
        <dx-scroll-view>
          <div class="fileuploader-container">
            <dx-file-uploader
              ref="fileUploader"
              :selectButtonText="!popupDocumento.noFile ? `Substituir documento` : 'Adicionar Documento'"
              :multiple="false"
              :showFileList="false"
              upload-mode="useForm"
              @value-changed="onChangeAttachment"
            />
            <div v-if="attachment && attachment.length" style="margin-top: 10px; padding: 10px">
              <dx-button
                icon="mdi mdi-download"
                @click="onDownloadAttachment">
                <span class="mdi mdi-download"/> {{ attachment[0].name }}
              </dx-button>
            </div>
          </div>
        </dx-scroll-view>
      </template>
    </dx-popup>
  </div>
</template>
<script>
import { DxDataGrid } from 'devextreme-vue/data-grid'
import CustomStore from 'devextreme/data/custom_store'
import mixinCrud from '@/pages/Cruds/mixin-crud'
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup'
import { DxScrollView } from 'devextreme-vue/scroll-view'
import { DxFileUploader } from 'devextreme-vue/file-uploader'
import DxButton from 'devextreme-vue/button'
import notify from 'devextreme/ui/notify'
import { http } from '@/pluggables/http'
import config from 'config'
import { verifyValidadeDate } from '@/helper/general'
import { mapGetters, mapState } from 'vuex'
import { documentosEnum } from '@/pages/Cruds/Pessoa/documentosEnum'
// import { Device } from 'devextreme/core/devices'

const baseUrl = config.baseUrl
export default {
  name: 'pendencia-documentos',
  components: {
    DxDataGrid,
    DxToolbarItem,
    DxScrollView,
    DxPopup,
    DxFileUploader,
    DxButton
  },
  mixins: [
    mixinCrud
  ],
  data () {
    return {
      popupFullScreen: false,
      entity: 'Arquivo',
      gridReady: false,
      attachment: [],
      popupDocumento: {
        visible: false
      },
      gridData: {
        value: {
          visible: true
        },
        columns: this.parseColumns([
          {
            dataField: 'tipoArquivo.descricao',
            calculateFilterExpression: (transportador, operation) => ['$tipoArquivo.descricao$', operation, transportador],
            caption: 'Descrição',
            dataType: 'string',
            allowFiltering: false
          },
          {
            dataField: 'situacao',
            calculateDisplayValue: (data) => {
              if (data.validade) {
                if (this.validateFunction(new Date(`${data.validade.split('T')[0]}T00:00:00`))) {
                  return `Documento Vencido - ${new Date(`${data.validade.split('T')[0]}T00:00:00`).toLocaleDateString()}`
                } else {
                  return {
                    N: 'Não validado',
                    V: 'Validado',
                    P: 'Pendente'
                  }[data.situacao]
                }
              } else {
                return {
                  N: 'Não validado',
                  V: 'Validado',
                  P: 'Pendente'
                }[data.situacao]
              }
            },
            caption: 'Situação',
            allowFiltering: false,
            dataType: 'string',
            editorOptions: { maxLength: 30 }
          },
          {
            dataField: 'descricaoPendencia',
            caption: 'Pendência',
            dataType: 'string',
            allowFiltering: false
          },
          {
            dataType: 'buttons',
            type: 'buttons',
            fixed: true,
            alignment: 'center',
            buttons: [
              {
                name: 'addDocumento',
                text: 'Adicionar',
                visible: ({ row: { data } }) => !!(data.noFile),
                cssClass: 'dx-button dx-button-primary dx-button-mode-contained dx-widget dx-button-has-text mdi mdi-pencil md-width',
                onClick: ({ row: { data } }) => {
                  this.attachment = []
                  this.popupDocumento = {
                    visible: true,
                    title: data.tipoArquivo.descricao,
                    vencido: null,
                    saveButtonVisible: false,
                    noFile: !!(data.noFile),
                    data
                  }
                }
              },
              {
                name: 'editDocumento',
                text: 'Alterar',
                visible: ({ row }) => {
                  if (row.data.situacao === 'P' || (row.data.validade && this.validateFunction(new Date(`${row.data.validade.split('T')[0]}T00:00:00`)))) {
                    const rowElement = this.$refs.simpleGrid.instance.getRowElement(row.rowIndex)
                    rowElement[0].style.color = 'red'
                    return true
                  }
                },
                cssClass: 'dx-button dx-button-primary dx-button-mode-contained dx-widget dx-button-has-text mdi mdi-pencil md-width',
                onClick: ({ row: { data } }) => {
                  this.attachment = [{ originalName: data.nomeArquivo, name: data.nomeArquivo, id: data.id }]
                  this.popupDocumento = {
                    visible: true,
                    title: data.tipoArquivo.descricao,
                    vencido: data.validade ? this.validateFunction(new Date(`${data.validade.split('T')[0]}T00:00:00`)) : false,
                    saveButtonVisible: false
                  }
                }
              }
            ]
          }
        ]),
        dataSource: new CustomStore({
          key: 'id',
          load: async () => {
            const result = await this.$utils.wrapRequestForGrid(this.find({
              entity: this.entity,
              params: {
                order: JSON.stringify([['situacao', 'desc']]),
                fields: ['id', 'situacao', 'nomeArquivo', 'validade', 'descricaoPendencia', 'idPerfil'],
                include: [
                  {
                    model: 'Perfil',
                    as: 'perfil',
                    required: true,
                    attributes: ['id'],
                    where: {
                      id: this.currentPerfil
                    }
                  },
                  {
                    model: 'TipoArquivo',
                    as: 'tipoArquivo',
                    required: true,
                    attributes: ['id', 'descricao']
                  }
                ]
              }
            }))
            for (const documentoId in documentosEnum[this.userData.pessoa.tipoPessoa]) {
              if (!result.data.find(arquivo => arquivo.tipoArquivo.id === parseInt(documentoId))) {
                result.data.push({
                  id: Math.random().toString(16).slice(2),
                  noFile: true,
                  tipoArquivo: {
                    id: documentoId,
                    descricao: documentosEnum[this.userData.pessoa.tipoPessoa][documentoId]
                  }
                })
              }
            }
            return result
          }
        }),
        onEditingStart: () => {
          this.isEditingData = true
        }
      }
    }
  },
  computed: {
    ...mapGetters('Login', ['userData']),
    ...mapState('Login', ['user']),
    currentPerfil () {
      return this.user.perfis.find(v => v.tipoPerfil.descricao === 'Transportador').id
    },
    validateFunction () {
      return verifyValidadeDate
    }
  },
  methods: {
    popupOnShowing () {
      // const device = Device Device.ios
      // console.log('Device', Device.ios)
      if (window.innerWidth < 600) this.popupFullScreen = true
    },
    popupHeight () {
      if (window.innerWidth > 600) {
        this.popupFullScreen = false
        return 'auto'
      } else {
        this.popupFullScreen = true
      }
      // return window.innerWidth < 600 ? '100%' : 'auto'
    },
    popupWidth () {
      if (window.innerWidth > 600) {
        this.popupFullScreen = false
        return '80%'
      } else {
        this.popupFullScreen = true
      }
      // return window.innerWidth < 600 ? 'auto' : '80%'
    },
    /**
     * Método responsável por salvar os documentos a serem substituídos
     * @returns {Promise<void>}
     */
    async saveDocumentos () {
      if (this.attachment && this.attachment.length) {
        this.setLoading(true)
        const formData = new FormData()
        formData.append('anexo', this.attachment[0], this.attachment[0])
        formData.append('contentType', this.attachment[0].type)
        formData.append('nomeArquivo', this.attachment[0].name)
        formData.append('idUsuario', this.userData.id)
        formData.append('situacao', 'N')
        try {
          if (this.attachment[0].id) {
            formData.append('id', this.attachment[0].id)
            await http.put(`${baseUrl}/Arquivo/${this.attachment[0].id}`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
          } else {
            formData.append('idPerfil', this.currentPerfil)
            formData.append('descricao', '')
            formData.append('idTipoArquivo', this.popupDocumento.data.tipoArquivo.id)
            await http.post(`${baseUrl}/Arquivo`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
          }
          await this.$refs.simpleGrid.instance.refresh()
        } catch (error) {
          console.error(error)
          notify('Não foi possivel salvar este documento, por favor contate um administrador do sistema', 'error', 3500)
        }
      }
      this.popupDocumento = {
        visible: false,
        title: '',
        saveButtonVisible: false
      }
      this.setLoading(false)
    },
    /**
     * Método disparado assim que um attachment é substituído
     * @param value
     */
    onChangeAttachment ({ value }) {
      if (value.length) {
        const id = this.attachment[0] ? this.attachment[0].id : null
        const validade = this.attachment[0] ? this.attachment[0].validade : null
        this.attachment = [value[0]]
        this.attachment[0].id = id
        this.attachment[0].validade = validade
        this.popupDocumento.saveButtonVisible = true
      }
    },
    /**
     * Método para baixar um anexo
     * tanto para os anexos já existentes como os que que foram inseridos no componente
     * @returns {Promise<void>}
     */
    async onDownloadAttachment () {
      const download = async (attachment) => {
        const buffer = attachment.buffer ? attachment.buffer.data : await attachment.arrayBuffer()
        const fileBuffer = new Uint8Array(buffer)
        const blob = new Blob([fileBuffer], { type: attachment.type })
        const fileName = attachment.name
        const link = document.createElement('a')
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob)
          link.setAttribute('href', url)
          link.setAttribute('download', fileName)
          link.style.visibility = 'hidden'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      }
      if (this.attachment.length && typeof this.attachment[0].arrayBuffer === 'function') {
        await download(this.attachment[0])
      } else if (this.attachment.length) {
        try {
          const attachment = await this.findOne({
            entity: 'Arquivo',
            params: {
              where: {
                id: this.attachment[0].id
              },
              attributes: ['id', 'anexo', 'nomeArquivo']
            }
          })
          if (attachment) await download({ buffer: attachment.anexo, name: attachment.nomeArquivo })
        } catch (error) {
          console.error(error)
          notify('Não foi possivel buscar documento(s), por favor contate um administrador do sistema', 'error', 3500)
        }
      }
    },
    /**
     * Método disparado assim que o popup é fechado
     * Responsável por resetar a instância do DxUploader para que não fique lixo no v-model
     */
    onPopupClosing () {
      this.$refs.fileUploader.instance.reset()
    },
    showCLTRCC () {
      const perfilTransportador = this.userData.perfis.find(perfil => perfil.tipoPerfil.id === 9 || perfil.tipoPerfil.id === 2)
      return !!(perfilTransportador.dataAprovacao && perfilTransportador.dataValidadeCltrcc)
    },
    onClickCLTRCC () {
      const perfilTransportador = this.userData.perfis.find(perfil => perfil.tipoPerfil.id === 9 || perfil.tipoPerfil.id === 2)
      window.open(`${baseUrl}/cltrcc/${perfilTransportador.id}`)
    }
  }
}
</script>
