<template>
  <div class="form-usuario" v-show="started">
    <div class="form flex" v-if="!ocultarCampos.includes('tipoResponsavel')">
      <div class="flex-col-4 field">
        <b>Tipo*:</b>
        <dx-select-box
          placeholder="Selecione um tipo"
          v-model="form.tipoResponsavel"
          :items="dynamicValues.tiposResponsavel"
          :validation-status="formValid.tipoResponsavel"
        />
      </div>
      <div class="flex-col-8 field" v-if="!ocultarCampos.includes('responsavelAssinaturaContrato')">
        <b>Responsável pela assinatura do contrato*:</b>
        <dx-radio-group
          v-model="form.responsavelAssinaturaContrato"
          value-expr="value"
          display-expr="text"
          :data-source="[{ value: false, text: 'Não' }, { value: true, text: 'Sim' }]"
        />
      </div>
    </div>
    <div class="form flex">
      <div class="flex-col-3 field" v-if="!ocultarCampos.includes('cpf')">
        <b>CPF*:</b>
        <dx-text-box
          :readOnly="!!(usuarioToEdit.id) && !isAdministrador()"
          v-model="form.cpf"
          mask="000.000.000-00"
          :validation-status="formValid.cpf"
        />
      </div>
      <div class="flex-col-3 field" v-if="!ocultarCampos.includes('rg')">
        <b>RG*:</b>
        <dx-text-box
          v-model="form.rg"
          :maxLength="15"
          :validation-status="formValid.rg"
        />
      </div>
      <div class="flex-col-9 field" v-if="!ocultarCampos.includes('nome')">
        <b>Nome*:</b>
        <dx-text-box
          v-model="form.nome"
          :validation-status="formValid.nome"
          :max-length="150"
        />
      </div>
    </div>
    <div class="form flex">
      <div class="flex-col-6 field" v-if="!ocultarCampos.includes('profissao')">
        <b>Profissão*:</b>
        <dx-text-box
          v-model="form.profissao"
          :validation-status="formValid.profissao"
        />
      </div>
      <div class="flex-col-6 field" v-if="!ocultarCampos.includes('cargo')">
        <b>Cargo*:</b>
        <dx-text-box
          v-model="form.cargo"
          :validation-status="formValid.cargo"
        />
      </div>
    </div>
    <div class="form flex">
      <div class="flex-col-6 field" v-if="!ocultarCampos.includes('celular')">
        <b>Celular*:</b>
        <dx-text-box
          v-model="form.celular"
          mask="(00) 00000-0000"
          :validation-status="formValid.celular"
        />
      </div>
      <div class="flex-col-6 field" v-if="!ocultarCampos.includes('telefone')">
        <b>Telefone Comercial:</b>
        <dx-text-box
          v-model="form.telefone"
          mask="(00) 90000-0000"
        />
      </div>
    </div>
    <div class="form flex">
      <div class="flex-col-6 field" v-if="!ocultarCampos.includes('email')">
        <b>Email*:</b>
        <dx-text-box
          v-model="form.email"
          :max-length="100"
          mode="email"
          :validation-status="formValid.email"
        />
      </div>
      <div class="flex-col-6 field" v-if="!ocultarCampos.includes('confirmarEmail')">
        <b>Confirmar Email*:</b>
        <dx-text-box
          :validation-status="formValid.confirmarEmail"
          :max-length="100"
          mode="email"
          v-model="form.confirmarEmail"
        />
      </div>
    </div>
    <div class="form flex" v-show="!usuarioToEdit.idAuth">
      <div class="flex-col-6 field" v-if="!ocultarCampos.includes('senha')">
        <b>{{ hasFinalId ? 'Redefine a senha' : 'Senha*' }}:</b>
        <dx-text-box
          mode="password"
          :placeholder="hasFinalId ? 'Opcional...' : ''"
          v-model="form.senha"
          :validation-status="formValid.senha"
        />
      </div>
      <div class="flex-col-6 field" v-if="!ocultarCampos.includes('senha')">
        <b>Confirmar Senha*:</b>
        <dx-text-box
          :validation-status="formValid.confirmarSenha"
          mode="password"
          v-model="form.confirmarSenha"
        />
      </div>
    </div>

    <!-- ENDEREÇAMENTO -->
    <div class="form flex">
      <div class="flex-col-4" v-if="!ocultarCampos.includes('cep')">
        <b>CEP*</b>
        <dx-text-box
          v-model="form.cep"
          :validation-status="formValid.cep"
          mask="00.000-000"
        />
      </div>
      <div class="flex-col-4" v-if="!ocultarCampos.includes('uf')">
        <b>UF*</b>
        <dx-select-box
          v-model="form.uf"
          :items="dynamicValues.estados"
          :disabled="true"
          value-expr="uf"
          display-expr="nome"
        />
      </div>
      <div class="flex-col-4" v-if="!ocultarCampos.includes('codIbge')">
        <b>Município*</b>
        <dx-select-box
          v-model="form.codIbge"
          :items="dynamicValues.municipios"
          :disabled="true"
          value-expr="codIbge"
          display-expr="nome"
        />
      </div>
    </div>
    <div class="form flex">
      <div class="flex-col-4" v-if="!ocultarCampos.includes('bairro')">
        <b>Bairro*</b>
        <dx-text-box
          v-model="form.bairro"
          :max-length="80"
        />
      </div>
      <div class="flex-col-8" v-if="!ocultarCampos.includes('endereco')">
        <b>Endereço*</b>
        <dx-text-box v-model="form.endereco"
        />
      </div>
    </div>
    <div class="form flex">
      <div class="flex-col-4" v-if="!ocultarCampos.includes('complemento')">
        <b>Complemento</b>
        <dx-text-box
          v-model="form.complemento"
          :max-length="100"
        />
      </div>
      <div class="flex-col-4" v-if="!ocultarCampos.includes('numero')">
        <b>Numero*</b>
        <dx-text-box
          v-model="form.numero"
          mode="tel"
          :max-length="10"
        />
      </div>
    </div>
    <!-- FIM ENDEREÇAMENTO -->

    <div class="form flex" v-if="perfisSelecionaveis.length && !ocultarCampos.includes('perfil')">
      <div class="flex-col-6 field">
        <b>Perfil*:</b>
        <dx-tag-box
          v-model="form.idsTipoDePerfil"
          :items="perfisSelecionaveis"
          value-expr="idTipoPerfil"
          display-expr="_descricao"
          placeholder="Selecione os perfis deste usuário"
        />
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.form-usuario {
  display: flex;
  flex-direction: column;
  width: 100%;

  .form-actions {
    margin: 10px 0;
  }

  .form {
    width: 100%;

    > div {
      margin: 0 10px 10px 0;
    }

    .field {
      display: flex;
      flex-direction: column;
    }
  }
}
</style>

<script>
import { mapActions } from 'vuex'
import { DxSelectBox, DxTextBox, DxRadioGroup, DxTagBox } from 'devextreme-vue'
import { messageRequiredDefault } from './FormPessoaUpdate.vue'
import mixinCrud from '@/pages/Cruds/mixin-crud'
import storeHelper from '@/helper/storeHelper'

export default {
  name: 'form-usuario',
  components: {
    DxSelectBox,
    DxTextBox,
    DxRadioGroup,
    DxTagBox
  },
  inject: {
    action: {
      default: ''
    },
    autoCadastro: {
      default: false
    }
  },
  mixins: [mixinCrud],
  props: {

    /**
     * Lista de perfis que poderão ser selecionáveis para este usuário.
     * Esta prop será usada na tela de adicionar usuários a uma Pessoa Jurídica
     */
    perfisSelecionaveis: {
      type: Array,
      default: () => []
    },

    /**
     * Prop usada para ser parametrizado um usuário que será editado
     */
    usuarioToEdit: {
      type: Object,
      default: () => ({})
    },

    ocultarCampos: {
      type: Array,
      default: () => []
    },

    popupAction: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      dynamicValues: {
        tiposResponsavel: ['Representante Legal', 'Responsável Técnico', 'Gestor'],
        municipios: [],
        estados: []
      },
      started: false,
      form: {},
      formValid: {}
    }
  },
  watch: {
    form: {
      deep: true,
      handler (form) {
        this.formValid.tipoResponsavel = typeof form.tipoResponsavel === 'string' && !form.tipoResponsavel.length ? 'invalid' : 'valid'
        this.formValid.responsavelAssinaturaContrato = typeof form.responsavelAssinaturaContrato === 'string' && !form.responsavelAssinaturaContrato.length ? 'invalid' : 'valid'
        this.formValid.nome = typeof form.nome === 'string' && !form.nome.length ? 'invalid' : 'valid'
        this.formValid.profissao = typeof form.profissao === 'string' && !form.profissao.length ? 'invalid' : 'valid'
        this.formValid.cargo = typeof form.cargo === 'string' && !form.cargo.length ? 'invalid' : 'valid'

        const celular = (form.celular || '').trim()
        const cpf = (form.cpf || '').trim()
        const rg = (form.rg || '').trim()
        this.formValid.celular = celular && typeof celular === 'string' && celular.length < 11 ? 'invalid' : 'valid'
        this.formValid.cpf = cpf && !this.$utils.validateCpf(cpf) ? 'invalid' : 'valid'
        this.formValid.rg = rg ? 'valid' : 'invalid'

        this.formValid.email = form.email && !/[\w]+@[\w]+/.test(form.email) ? 'invalid' : 'valid'
        this.formValid.confirmarEmail = form.email && form.confirmarEmail && form.confirmarEmail !== form.email ? 'invalid' : 'valid'
        this.formValid.senha = this.hasFinalId ? 'valid' : typeof form.senha === 'string' && !form.senha.length ? 'invalid' : 'valid'
        this.formValid.confirmarSenha = form.senha && form.confirmarSenha && form.confirmarSenha !== form.senha ? 'invalid' : 'valid'
        // this.formValid.numero = typeof form.numero === 'string' && !form.numero.length ? 'valid' : 'valid'
      }
    },
    async 'form.uf' (uf) {
      let municipios = []
      if (uf) {
        municipios = await this.find({
          entity: 'Municipio',
          rowsOnly: true,
          params: {
            where: {
              uf
            }
          }
        })
      }
      this.dynamicValues = {
        ...this.dynamicValues,
        municipios
      }
    },
    async 'form.cep' (cep = '', oldValue) {
      if (typeof oldValue === 'string' && cep !== oldValue) {
        if (cep.length === 8) {
          try {
            this.setLoading(true)
            const enderecamento = await this.$utils.getEnderecamentoPeloCep(cep)
            this.setLoading(false)
            this.form = {
              ...this.form,
              ...enderecamento
            }
            this.formValid.cep = 'valid'
          } catch (error) {
            this.setLoading(false)
            console.error(error)
            this.formValid.cep = 'invalid'
          }
        }
      }
    }
  },
  computed: {
    hasFinalId () {
      return this.form.id && /^\d+$/.test(this.form.id)
    }
  },
  async created () {
    const estados = await this.find({
      entity: 'Estado',
      rowsOnly: true,
      params: {
        fields: JSON.stringify([
          'uf',
          'nome',
          'codIbge'
        ])
      }
    })

    this.dynamicValues = {
      ...this.dynamicValues,
      estados
    }
  },
  mounted () {
    this.startOrReset()
  },
  methods: {
    ...mapActions('Crud', ['find', 'findOne']),
    async startOrReset () {
      const usuarioToEdit = this.usuarioToEdit || {}
      const perfis = usuarioToEdit.perfis || []
      this.formValid = {
        tipoResponsavel: 'valid',
        responsavelAssinaturaContrato: 'valid',
        cpf: 'valid',
        nome: 'valid',
        profissao: 'valid',
        cargo: 'valid',
        celular: 'valid',
        email: 'valid',
        confirmarEmail: 'valid',
        senha: 'valid',
        confirmarSenha: 'valid',
        cep: 'valid',
        numero: 'valid',
        rg: 'valid',
        telefone: 'valid'
      }
      this.form = {
        tipoResponsavel: null,
        profissao: null,
        cargo: null,
        responsavelAssinaturaContrato: false,
        cpf: null, // string
        rg: null, // string
        nome: null, // string
        celular: null, // string
        telefone: null, // string
        email: null, // string
        confirmarEmail: null, // string
        cep: '',
        uf: null,
        codIbge: null,
        bairro: null,
        endereco: null,
        complemento: null,
        idsTipoDePerfil: perfis.map(({ idTipoPerfil }) => idTipoPerfil), // number[] contém os IDS da entity: TipoPerfil, a partir deste ID e usando o ID da pessoa é possível encontrar o perfil correto
        ...usuarioToEdit,
        ...(this.$utils.isFinalId(usuarioToEdit.id) ? { senha: undefined } : {}), // para forçar edição de senha
        ...(this.$utils.isFinalId(usuarioToEdit.id) ? { confirmarSenha: undefined } : {}), // para forçar edição de senha,
        numero: (usuarioToEdit.numero && usuarioToEdit.numero !== 'null') ? String(usuarioToEdit.numero) : null
      }
      this.started = true
      this.$forceUpdate()
      this.form.nome = this.form.nome || this.form.nomeRazaoSocial
      await this.$nextTick()
      this.form.uf = usuarioToEdit.municipio ? usuarioToEdit.municipio.uf : null
    },
    /** maskTelefone
     * Método de uso público que poderá opcionalmente ser usado para fazer a validação padrão deste formulário.
     * Este método é opcional pois prevê o uso deste componente em telas com regras negociais diferentes
     * @public
     * @exception
     **/
    validarUsuario (validarPerfis = false) {
      const ignorarCampos = this.ocultarCampos || []
      const usuario = this.form || {}

      // garante que campos ignorados não sejam validados
      const validateField = (fieldName, messageError) => {
        if (this.usuarioToEdit.idAuth !== null) {
          this.ocultarCampos.push('senha')
        }
        if (!ignorarCampos.includes(fieldName) && !usuario[fieldName]) {
          this.formValid[fieldName] = 'invalid'
          throw new CustomError(messageError)
        }
      }

      if (!ignorarCampos.includes('celular')) {
        validateField('celular', messageRequiredDefault('Celular'))
        const cellValue = (usuario.celular || '').trim()
        if (!cellValue || cellValue.length < 11 || this.formValid.celular === 'invalid') {
          throw new CustomError('O campo de celular não está preenchido corretamente')
        }
      }
      if (!ignorarCampos.includes('cpf')) {
        validateField('cpf', messageRequiredDefault('CPF'))
        const cpfValue = (usuario.cpf || '').trim()
        if (!cpfValue || cpfValue.length < 11 || this.formValid.cpf === 'invalid') {
          throw new CustomError('O campo de cpf não está preenchido corretamente')
        }
      }
      validateField('nome', messageRequiredDefault('Nome'))
      validateField('cargo', messageRequiredDefault('Cargo'))
      validateField('profissao', messageRequiredDefault('Profissão'))
      validateField('tipoResponsavel', messageRequiredDefault('Tipo na área: responsável'))
      validateField('cep', messageRequiredDefault('CEP'))
      // validateField('numero', messageRequiredDefault('Número do endereço'))
      validateField('email', messageRequiredDefault('Email'))
      if (!ignorarCampos.includes('rg')) {
        validateField('rg', messageRequiredDefault('RG'))
      }

      const isFinalId = this.$utils.isFinalId(usuario.id)

      if (!ignorarCampos.includes('email') && !/[\w]+@[\w]+/.test(usuario.email)) {
        this.formValid.email = 'invalid'
        throw new CustomError('O campo de email está incorreto, deve estar no formato: "usuario@servidor"(sem as aspas), por exemplo')
      }

      if (!ignorarCampos.includes('email') && !ignorarCampos.includes('confirmarEmail')) {
        if (!isFinalId && usuario.email !== usuario.confirmarEmail) {
          this.formValid.confirmarEmail = 'invalid'
          throw new CustomError('O campo de email precisa ser igual ao campo de confirmar email')
        }
      }

      if (validarPerfis) {
        if (this.perfisSelecionaveis.length >= 1) {
          if (!usuario.idsTipoDePerfil || !usuario.idsTipoDePerfil.length) {
            const usss = usuario.fo
            throw new CustomError('Selecione um perfil', usss)
          }
        }
      }

      if (!ignorarCampos.includes('senha')) {
        // tem q validar senha quando o campo não está configurado como ignorado/ocultado
        if (!isFinalId || (isFinalId && usuario.senha)) {
          // valida se for um usuário novo não persistido OU se for um usuário já inserido e com senha configurada(neste cenário é opcional configurar senha)
          if (!isFinalId && !usuario.senha) {
            this.formValid.senha = 'invalid'
            throw new CustomError('O campo de senha é obrigatório')
          }
          if (usuario.senha.length < 6 || usuario.senha.length > 15) {
            this.formValid.senha = 'invalid'
            throw new CustomError('O campo de senha deve ter no mínimo 6 digítos, e no máximo 15 digítos')
          }
          if (usuario.senha !== usuario.confirmarSenha) {
            this.formValid.confirmarSenha = 'invalid'
            throw new CustomError('O campo de senha precisa ser igual ao campo de confirmar senha')
          }
        }
      }
    },
    /**
     * Método público responsável por retornar os dados preenchidos no formulário
     * @params tipoPessoa
     */
    getDadosFormulario (tipoPessoa) {
      if (tipoPessoa === 'F') {
        return {
          ...this.form,
          nomeRazaoSocial: this.form.nome
        }
      } else {
        return this.form
      }
    },
    isAdministrador () {
      return storeHelper.isAdministrador()
    }
  }
}
</script>
