<template>
  <div>
    <dx-popup
      :hide-on-outside-click="true"
      :drag-enabled="false"
      height="auto"
      :show-title="true"
      title="Aceitar Solicitação de Transporte"
      :visible.sync="popupAcceptVisible">
      <template v-slot:content>
        <div class="solicitacao-accept-popup-content">
          <div class="solicitacao-accept-popup-form">
            <div class="dx-field">
              <div class="dx-field-label-location-top">Veículo (Placa)</div>
              <div class="dx-field-content">
                <dx-lookup
                  v-bind="fieldsBind.veiculo"
                  :value.sync="popupData.veiculo"
                  @valueChanged="onChangeVeiculo"
                  ref="veiculoLookup"
                />
              </div>
            </div>
            <div class="dx-field">
              <div class="dx-field-label-location-top">Tipo</div>
              <div class="dx-field-content">
                <dx-text-box v-bind="fieldsBind.tipo" :value="popupData.tipo"/>
              </div>
            </div>
            <div class="dx-field" v-if="popupData.tipo === 'Poliguindaste'">
              <div class="dx-field-label-location-top">Poliguindaste</div>
              <div class="dx-field-content">
                <dx-text-box v-bind="fieldsBind.poliguindaste" :value="popupData.poliguindaste"/>
              </div>
            </div>
            <div class="dx-field" v-if="popupData.tipo === 'Poliguindaste'">
              <div class="dx-field-label-location-top">Caçamba (Nº de Identicação)</div>
              <div class="dx-field-content">
                <dx-lookup
                  v-bind="fieldsBind.cacamba"
                  :value.sync="popupData.cacamba"
                  ref="cacambaLookup"/>
              </div>
            </div>
          </div>

          <message-error :message="popupErrorMessage"/>

            <div class="solicitacao-accept-popup-buttons">
              <dx-button type="success" @click="onAvalicaoPopupSave">Salvar</dx-button>
              <dx-button type="normal" @click="popupAcceptVisible = false">Voltar</dx-button>
            </div>
        </div>
      </template>
    </dx-popup>

    <dx-popup
      :hide-on-outside-click="true"
      :drag-enabled="false"
      height="auto"
      :show-title="true"
      title="Recusar Solicitação de Transporte"
      :visible.sync="popupDeclineVisible">
      <template v-slot:content>
        <div class="solicitacao-decline-popup-content">
          <div class="dx-field">
            <div class="dx-field-label-location-top">Justifique a recusa da solicitaçao de transporte</div>
            <div class="dx-field-content">
              <dx-text-area
                :max-length="200"
                :value.sync="popupData.justificativa"/>
            </div>
          </div>

          <message-error :message="popupErrorMessage"/>

            <div class="solicitacao-decline-popup-buttons">
              <dx-button type="success" @click="onAvalicaoPopupSave">Salvar</dx-button>
              <dx-button type="normal" @click="popupDeclineVisible = false">Voltar</dx-button>
            </div>
        </div>
      </template>
    </dx-popup>
  </div>
</template>
<script>
import { DxButton, DxPopup, DxTextArea, DxLookup, DxTextBox } from 'devextreme-vue'
import CustomStore from 'devextreme/data/custom_store'
import {
  saveAvaliacaoSolicitacoesTransporte
} from '@/pages/Cruds/AvaliacaoSolicitacaoTransporte/service'
import { getAvailableVeiculos, getAvailableCacambas } from '@/components/SolicitacaoTransporte/service'

import MessageError from '../MessageError'

export default {
  name: 'popup-avaliar-solicitacao',
  components: {
    DxButton,
    DxPopup,
    DxTextArea,
    DxLookup,
    DxTextBox,
    MessageError
  },
  props: {
    /**
     * A solicitacao que sera cancelada.
     */
    solicitacao: {
      type: Object
    },
    /**
     * Se o popup esta ativo.
     */
    active: {
      type: Boolean,
      required: true
    },
    /**
     * Tipo de popup para abrir
     */
    action: {
      type: String,
      required: true,
      validator: v => ['accept', 'decline'].includes(v)
    }
  },
  watch: {
    popupAcceptVisible (visible) {
      if (!visible && this.$refs.veiculoLookup && this.$refs.cacambaLookup) {
        this.$refs.veiculoLookup.instance.repaint()
        this.$refs.cacambaLookup.instance.repaint()
      }
    },
    active (visible) {
      if (this.action === 'accept') {
        this._clearPopupData()
        if (!visible) {
          this.$refs.cacambaLookup && this.$refs.cacambaLookup.instance.close()
          this.$refs.veiculoLookup && this.$refs.veiculoLookup.instance.close()
        } else {
          this.$refs.cacambaLookup && this.$refs.cacambaLookup.instance.getDataSource().reload()
          this.$refs.veiculoLookup && this.$refs.veiculoLookup.instance.getDataSource().reload()
        }
      } else if (this.action === 'decline') {
        if (!visible) {
          this._clearPopupData()
        }
      }
    }
  },
  /**
   * Metodo do vue, para obter o estado inicial do componente.
   * @returns {Object} O estado inicial do componente.
   */
  data () {
    return {
      popupData: {
        cacamba: null,
        veiculo: null,
        tipo: null,
        poliguindaste: null,
        justificativa: null
      },
      fieldsBind: {
        cacamba: {
          allowClearing: true,
          showClearButton: true,
          showCancelButton: false,
          dropDownOptions: {
            closeOnOutsideClick: true
          },
          dataSource: {
            store: new CustomStore({
              key: 'id',
              load: async loadOptions => {
                const cacambas = await this.$utils.wrapRequestForGrid(getAvailableCacambas({}, { ...loadOptions, paginate: false }))
                if (this.solicitacao.cacamba) {
                  if (this.solicitacao.cacamba && !cacambas.data.find(cacamba => cacamba.id === this.solicitacao.cacamba.id)) cacambas.data.unshift(this.solicitacao.cacamba)
                }
                return { data: cacambas.data, totalCount: cacambas.totalCount }
              },
              byKey: async key => {
                const res = await getAvailableCacambas({ where: { id: key } })
                return res.totalCount > 0 ? res.data[0] : null
              }
            }),
            paginate: false
          },
          displayExpr: 'numeroIdentificacao',
          valueExpr: 'id',
          noDataText: 'Não existem caçambas disponíveis nesse momento.'
        },
        veiculo: {
          allowClearing: true,
          showClearButton: true,
          showCancelButton: false,
          dataSource: {
            store: new CustomStore({
              key: 'id',
              load: async loadOptions => {
                const veiculos = await this.$utils.wrapRequestForGrid(getAvailableVeiculos({}, { ...loadOptions, paginate: false }))
                if (this.solicitacao.veiculo && !veiculos.data.find(veiculo => veiculo.id === this.solicitacao.veiculo.id)) veiculos.data.unshift(this.solicitacao.veiculo)
                return { data: veiculos.data, totalCount: veiculos.totalCount }
              },
              byKey: async key => {
                const res = await getAvailableVeiculos({ where: { id: key } })
                return res.totalCount > 0 ? res.data[0] : null
              }
            }),
            paginate: false
          },
          valueExpr: 'id',
          displayExpr: 'placa',
          noDataText: 'Não existem veículos disponíveis nesse momento.'
        },
        tipo: {
          readOnly: true
        },
        poliguindaste: {
          readOnly: true
        }
      },
      popupErrorMessage: null
    }
  },
  computed: {
    /**
     * Valor computado de se o popup esta ativo.
     * @returns {boolean}
     */
    popupAcceptVisible: {
      get () {
        return this.active && this.action === 'accept'
      },
      set (v) {
        this.$emit('update:active', v)
      }
    },

    /**
     * Valor computado de se o popup esta ativo.
     * @returns {boolean}
     */
    popupDeclineVisible: {
      get () {
        return this.active && this.action === 'decline'
      },
      set (v) {
        this.$emit('update:active', v)
      }
    },

    /**
     * Valor computado dos binds dos campos do popup.
     * @returns {Object}
     */
    fieldsBinds () {
      return {
        justificativa: {
          readOnly: !this.canCancel
        }
      }
    },

    /**
     * Valor computado de se a solicitacao pode ser cancelada.
     * @returns {boolean}
     */
    canCancel () {
      return this.solicitacao && ['Alocada', 'Enviada', 'Solicitada']
        .includes(this.solicitacao.situacao)
    }
  },
  methods: {
    /**
     * Metodo de callback de quando e clicado no botao de acao da solicitacao,
     * com o objetivo de abrir a popup de acao da solicitacao.
     * @param {string} action - A acao que sera feita na solicitacao, 'accept'
     * para aceitar a solicitacao, ou 'decline' para recusar a solicitacao.
     * @param {Object} solicitacao - A solicitacao que foi clicada.
     */
    onSolicitacaoActionClick (action, solicitacao) {
      this.solicitacao = solicitacao
      this[action === 'accept' ? 'popupAcceptVisible' : 'popupDeclineVisible'] = true
      this.popupData = {
        cacamba: null,
        veiculo: null,
        tipo: null,
        poliguindaste: null,
        justificativa: null
      }
    },

    /**
     * Metodo de callback de quando foi alterado o valor do veiculo no popup de
     * aceitar solicitacao. Com o objetivo de preencher o valor dos outros
     * campos do veiculo.
     * @param {Object} params - Os parametros do evento que ocorreu.
     * @param {Object} params.value - O veiculo que foi selecionado.
     */
    onChangeVeiculo ({ value }) {
      if (value) {
        this.popupData.tipo = this.$refs.veiculoLookup.instance._currentSelectedItem().tipo
        this.popupData.poliguindaste = this.$refs.veiculoLookup.instance._currentSelectedItem().poliguindaste
      }
    },

    /**
     * Metodo de callback de quando foi clicado no botao de salvar dos popup de
     * acao da solicitacao. Com o objetivo de salvar a avaliacao da solicitacao.
     */
    async onAvalicaoPopupSave () {
      this.popupErrorMessage = null

      if (this.popupAcceptVisible) {
        if (!this.popupData.veiculo) {
          this.popupErrorMessage = 'O veiculo informado é inválido.'
          return
        }

        if (this.popupData.tipo === 'Poliguindaste' && !this.popupData.cacamba) {
          this.popupErrorMessage = 'A caçamba informada é inválida.'
          return
        }
      } else if (!this.popupData.justificativa || !this.popupData.justificativa.length) {
        this.popupErrorMessage = 'A justificativa informada é inválida.'
        return
      }
      if (this.popupData.tipo === 'Basculante') {
        this.popupData.cacamba = null
      }
      const res = await saveAvaliacaoSolicitacoesTransporte({
        ...this.popupData,
        avaliacao: this.popupAcceptVisible,
        id: this.solicitacao.id
      })

      if (res.success) {
        this[this.popupAcceptVisible ? 'popupAcceptVisible' : 'popupDeclineVisible'] = false
        this._clearPopupData()
        this.$emit('updated-solicitacao')
      } else {
        this.popupErrorMessage = res.error
      }
    },

    /**
     * Metodo de comando, com o objetivo de limpar os dados dos popups de acao
     * da solicitacao.
     */
    _clearPopupData () {
      this.popupData = {
        cacamba: null,
        veiculo: null,
        tipo: null,
        poliguindaste: null,
        justificativa: null
      }
      this.popupErrorMessage = null
    }
  }
}
</script>

<style lang="scss">
@import '../../global.scss';

.solicitacao-cancel-popup-content {
  display: flex;
  flex-direction: column;

  .solicitacao-cancel-popup-form {
    margin-bottom: 16px;
  }

  .numero-ctr {
    margin: 6px 0px 20px 0px;
  }

  > .solicitacao-cancel-popup-buttons {
    display: flex;
    flex-direction: row-reverse;
  }

  .error-message-card {
    background-color: #B00020 !important;
    color: white !important;
    width: 100%;
    margin-top: 6px;
    margin-bottom: 16px;
  }
}
</style>
