import { http } from '@/pluggables/http'
import config from 'config'
import storeHelper from '@/helper/storeHelper'

export const verifyValidadeDate = (date) => {
  if (date.getTime) return date.getTime() <= new Date().getTime()
}

/**
 * Método que verifica na base se um CPF ou CNPJ já existe na base dados
 * @param value
 * @param formItem
 */
export const cpfCnpjExists = ({ value, formItem }) => {
  return http.post(`${config.baseUrl}/findbycpfcnpj`, { cpfCnpj: value })
    .then(() => {
      return true
    }).catch((err) => {
      console.error(err)
      return false
    })
}

/**
 * Método que verifica se um CPF ou CNPJ é válido
 * @param options
 * @param type
 */
export const cpfCnpjValid = (options, type) => {
  let cpfCnpj = `${options.value}`
  if (type === 'CNPJ') {
    //  Caso seja um CNPJ

    // Guarda um array com todos os dígitos do valor
    const match = cpfCnpj.toString().match(/\d/g)
    const numbers = Array.isArray(match) ? match.map(Number) : []

    // Valida a quantidade de dígitos
    if (numbers.length !== 14) return false

    // Elimina inválidos com todos os dígitos iguais
    const items = [...new Set(numbers)]
    if (items.length === 1) return false

    // Cálculo validador
    const calc = (x) => {
      const slice = numbers.slice(0, x)
      let factor = x - 7
      let sum = 0

      for (let i = x; i >= 1; i--) {
        const n = slice[x - i]
        sum += n * factor--
        if (factor < 2) factor = 9
      }

      const result = 11 - (sum % 11)

      return result > 9 ? 0 : result
    }

    // Separa os 2 últimos dígitos de verificadores
    const digits = numbers.slice(12)

    // Valida 1o. dígito verificador
    const digit0 = calc(12)
    if (digit0 !== digits[0]) return false

    // Valida 2o. dígito verificador
    const digit1 = calc(13)
    return digit1 === digits[1]
  } else if (type === 'CPF') {
    //  Caso seja um CPF
    cpfCnpj = cpfCnpj.replace(/[^\d]+/g, '')
    if (cpfCnpj === '') return false
    // Elimina cpfCnpjs invalidos conhecidos
    if (cpfCnpj.length !== 11 ||
      cpfCnpj === '00000000000' ||
      cpfCnpj === '11111111111' ||
      cpfCnpj === '22222222222' ||
      cpfCnpj === '33333333333' ||
      cpfCnpj === '44444444444' ||
      cpfCnpj === '55555555555' ||
      cpfCnpj === '66666666666' ||
      cpfCnpj === '77777777777' ||
      cpfCnpj === '88888888888' ||
      cpfCnpj === '99999999999') { return false }
    // Valida 1o digito
    let add = 0
    for (let i = 0; i < 9; i++) { add += parseInt(cpfCnpj.charAt(i)) * (10 - i) }
    let rev = 11 - (add % 11)
    if (rev === 10 || rev === 11) { rev = 0 }
    if (rev !== parseInt(cpfCnpj.charAt(9))) { return false }
    // Valida 2o digito
    add = 0
    for (let i = 0; i < 10; i++) { add += parseInt(cpfCnpj.charAt(i)) * (11 - i) }
    rev = 11 - (add % 11)
    if (rev === 10 || rev === 11) { rev = 0 }
    if (rev !== parseInt(cpfCnpj.charAt(10))) { return false }
    return true
  }
}

export const getCepGeometry = (geocodeCandidates) => {
  const [{ location = null } = {}] = geocodeCandidates || []

  return location !== null ? {
    type: 'Point',
    coordinates: [location.x, location.y],
    srid: '4326',
    crs: { type: 'name', properties: { name: 'EPSG:4326' } }
  } : null
}

export const debounce = (fn, delay) => {
  let timeoutID = null
  return function () {
    clearTimeout(timeoutID)
    const args = arguments
    const that = this
    timeoutID = setTimeout(function () {
      fn.apply(that, args)
    }, delay)
  }
}

/**
 * Método que verifica se o botão de Editar CTR deve ser exibido
 */
export const visibleEditButton = (ctr) => {
  const isAdmOrSecretaria = (storeHelper.isAdministrador() || storeHelper.isSecretaria)
  const is24h = (new Date() - new Date(ctr.dataHoraSolicitacao)) / 1000 / 60 / 60 <= 24
  const isVisible = (isAdmOrSecretaria && (!['Destinada', 'Cancelada'].includes(ctr.situacao))) || (!isAdmOrSecretaria && is24h && !ctr.editado && (!['Cancelamento Recusado', 'Cancelamento Solicitado', 'Destinada', 'Cancelada'].includes(ctr.situacao)))
  return !!isVisible
}
