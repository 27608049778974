import LocalizarCTR from './LocalizarCTR.vue'

export default {
  path: '/localizar-ctr',
  name: 'localizar-ctr',
  component: LocalizarCTR,
  meta: {
    icon: 'mdi mdi-file-search-outline',
    title: 'Localizar CTR',
    requiresAuth: true,
    permissionType: 'consulta'
  },
  props: true
}
