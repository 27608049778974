<template>
  <div class="map-localizar-cacamba">
    <popup-situacao-cacamba
      :active.sync="showSituacaoCacambaPopup"
      :cacamba="cacambaPopup"/>
    <div class="md-layout">
      <div class="md-layout-item">
        <h2 class="crud-title">Mapa - Localização x Situação das caçambas</h2>
      </div>
    </div>
    <div class="filter-row">
      <cacamba-checkbox-group-filter
        class="group-filter-situacao"
        title="Situação das caçambas"
        v-model="situacoesFilter"/>
      <cacamba-checkbox-group-filter
        class="group-filter-ocorrencia"
        title="Com ocorrência"
        vertical
        v-model="ocorrenciasFilter"/>
    </div>
    <div class="results-row">
      <span class="result-text-total">Total: {{ totalCacambas }}</span>
      <span class="result-text-update-time">Atualizado às: {{ lastUpdateDate }}</span>
      <span class="result-button-update">
        <dx-button type="success" @click="updateCacambasData">Atualizar</dx-button>
      </span>
    </div>
    <leaflet-map :bounds.sync="mapBounds" ref="leafletMap">
      <leaflet-marker
        v-for="(marker, i) in markers"
        :key="i"
        :latlng="marker.latlng"
        @click="onCacambaClick(marker)">
        <div :class="getCacambaIconClass(marker)">
          <md-icon class="mdi mdi-bucket"></md-icon>
        </div>
      </leaflet-marker>
    </leaflet-map>
    <md-card
      v-if="message"
      :class="getMessageClasses(message)">
      <md-card-content>{{ message.message }}</md-card-content>
    </md-card>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import DxButton from 'devextreme-vue/button'
import moment from 'moment'
import { LeafletMap, LeafletMarker } from '@/components/LeafletMap'
import { reverse } from 'reproject'
import CacambaCheckboxGroupFilter from './CacambaCheckboxGroupFilter'
import PopupSituacaoCacamba from './PopupSituacaoCacamba'
import {
  listCacambasBySituacao,
  listCacambasWithShapeWhereSituacao
} from '../service'

export default {
  name: 'map-localizar-cacamba',
  components: {
    LeafletMap,
    LeafletMarker,
    DxButton,
    CacambaCheckboxGroupFilter,
    PopupSituacaoCacamba
  },
  /**
   * Metodo do vue de obter o estado inicial do componente.
   * @returns {Object} O estado inicial do componente.
   */
  data () {
    return {
      markers: [],
      message: null,
      lastUpdateDate: '',
      totalCacambas: 0,
      mapBounds: null,
      situacoesFilter: [
        {
          situacao: 'Enviada',
          value: 0,
          checked: false,
          colorClass: 'cacamba-enviada'
        },
        {
          situacao: 'Alocada',
          value: 0,
          checked: true,
          colorClass: 'cacamba-alocada'
        },
        {
          situacao: 'Retirada',
          value: 0,
          checked: false,
          colorClass: 'cacamba-retirada'
        },
        /* {
          situacao: 'Destinada',
          value: 0,
          checked: false,
          colorClass: 'cacamba-destinada'
        }, */
        {
          situacao: 'Irregular',
          value: 0,
          checked: false,
          colorClass: 'cacamba-irregular'
        }
      ],
      ocorrenciasFilter: [
        {
          situacao: 'Com ocorrência Pessoa Física',
          value: 0,
          checked: false,
          colorClass: 'cacamba-ocorrencia-pf'
        },
        {
          situacao: 'Com ocorrência Pessoa Jurídica',
          value: 0,
          checked: false,
          colorClass: 'cacamba-ocorrencia-pj'
        }
      ],
      cacambaPopup: null,
      showSituacaoCacambaPopup: false
    }
  },

  watch: {
    /**
     * Metodo de callback de quando ocorre mudancas no valor da prop 'mapBounds',
     * com o objetivo de atualizar as cacambas no mapa.
     * @param {number[][]} currentBounds - O valor atual da prop.
     * @param {number[][]} oldBounds - O valor anterior da prop.
     */
    mapBounds (currentBounds, oldBounds) {
      this.updateCacambasMap(true)
    },

    situacoesFilter: 'onChangeFilter',
    ocorrenciasFilter: 'onChangeFilter',

    /**
     * Metodo de callback de quando ocorre mudancas na prop
     * 'showSituacaoCacambaPopup', com o objetivo de limpar os dados da cacamba
     * do popup quando ele e fechado.
     * @param {boolean} popupActive - O valor atual da prop.
     */
    showSituacaoCacambaPopup (popupActive) {
      if (!popupActive) {
        this.cacambaPopup = null
      }
    }
  },

  /**
   * Metodo do ciclo de vida do vue, de quando o componente foi montado na tela.
   * Com o objetivo de buscar os dados das cacambas.
   */
  mounted () {
    this.updateCacambasCounts()
  },

  methods: {
    /**
     * Metodo de comando, com o objetivo de atualizar as countagens de cacambas.
     */
    async updateCacambasCounts () {
      this.setLoading(true)
      this.message = null

      const cacambasBySituacaoResult = await listCacambasBySituacao()

      if (cacambasBySituacaoResult.success) {
        const situacaoCounts = cacambasBySituacaoResult.data.rows.reduce((acc, cacamba) => {
          acc[cacamba.situacaoDistinct] = cacamba.count
          return acc
        }, {});
        [
          this.situacoesFilter,
          this.ocorrenciasFilter
        ].flat()
          .forEach(itemFilter => {
            itemFilter.value = situacaoCounts[itemFilter.situacao] || 0
          })
        this.totalCacambas = cacambasBySituacaoResult.data.rows
          .reduce((acc, cacamba) => acc + parseInt(cacamba.count), 0)
      } else {
        console.error(cacambasBySituacaoResult.error);
        [
          this.situacoesFilter,
          this.ocorrenciasFilter
        ].flat()
          .forEach(itemFilter => {
            itemFilter.value = 0
          })
        this.totalCacambas = 0
        this.message = {
          message: 'Ocorreu uma falha ao buscar a lista de caçambas para o mapa.',
          type: 'error'
        }
      }

      this.lastUpdateDate = moment().format('DD/MM/YYYY - HH:mm:ss')
      this.setLoading(false)
    },

    /**
     * Meoto de comando, com o objetivo de atualizar as cacambas do mapa.
     * @param {boolean} zoomMarkers - Se deve dar zoom nos marcadores, depois de
     * busca-los.
     */
    async updateCacambasMap (bounds) {
      this.message = null
      let shape = null
      if (bounds) {
        shape = this.getBoundsGeoJson()
      }

      const situacoesParam = this.getSituacoesParam()
      if (situacoesParam.length > 0) {
        const cacambasResult = await listCacambasWithShapeWhereSituacao({
          situacoes: this.getSituacoesParam(),
          ids: this.markers.map(marker => marker.cacamba.id),
          shape
        })

        if (cacambasResult.success) {
          if (cacambasResult.data.rows.find(cacamba => !cacamba.shape)) {
            this.message = {
              message: 'Não foi encontrada a localização de uma ou mais cacambas.',
              type: 'info'
            }
          }

          this.createMarkers(cacambasResult.data.rows)
        } else {
          console.error(cacambasResult.error)
          this.markers = []
          this.message = {
            message: 'Ocorreu uma falha ao buscar a lista de caçambas para o mapa.',
            type: 'error'
          }
        }
      } else {
        this.markers = []
        this.$refs.leafletMap.clusterLayer.clearLayers()
      }
    },

    /**
     * Metodo de callback de quando ocorrem mudancas no filtro de situacoes das
     * cacambas, com o objetivo de remover os marcadores atuais e buscar a nova
     * lista de cacambas.
     */
    async onChangeFilter () {
      this.markers = []
      this.$refs.leafletMap.clusterLayer.clearLayers()

      await this.updateCacambasMap()
      this.zoomToMarkers()
    },

    /**
     * Metodo de comando, com o objetivo de atualizar todos os dados da cacamba.
     */
    updateCacambasData () {
      this.markers = []
      this.$refs.leafletMap.clusterLayer.clearLayers()
      this.updateCacambasCounts()
      this.updateCacambasMap(true)
    },

    /**
     * Metodo de callback de quando clica no icone de cacamba, com o objetivo de
     * abrir o popup de situacao da cacamba.
     * @param {Object} marker - Os dados do marcador da cacamba que se quer
     * obter o conteudo do popup.
     */
    onCacambaClick (marker) {
      this.cacambaPopup = marker.cacamba
      this.showSituacaoCacambaPopup = true
    },

    /**
     * Metodo de criacao de markers das cacambas.
     * @param {Object[]} cacambas - As cacambas que serao criados os marcadores.
     */
    createMarkers (cacambas) {
      this.markers = this.markers.concat(cacambas
        .filter(cacamba => cacamba.shape)
        .map(cacamba => ({
          cacamba,
          latlng: reverse(cacamba.shape).coordinates,
          options: {}
        })))
    },

    /**
     * Metodo de comando, com o objetivo de dar zoom no mapa em uma lista de
     * marcadores.
     * @param {Object[]} markers - A lista de marcadores que receberao zoom no
     * mapa.
     */
    zoomToMarkers () {
      this.$nextTick(() => {
        this.$refs.leafletMap.fitExtent()
      })
    },

    /**
     * Metodo para obter a lista de classes para o card de mensagens.
     * @param {Object} message - Os dados da mensagem atual.
     */
    getMessageClasses (message) {
      return {
        error: ['map-message-card', 'error-message'],
        info: ['map-message-card', 'info-message']
      }[message.type] || null
    },

    /**
     * Metodo para obter a lista de classes de um marcador do mapa.
     * @param {Object} marker - O marcador que recebera as classes.
     * @returns {string[]} A lista de classes do marcador.
     */
    getCacambaIconClass (marker) {
      return ['cacamba-icon', {
        Enviada: 'cacamba-enviada',
        Alocada: 'cacamba-alocada',
        Retirada: 'cacamba-retirada',
        Destinada: 'cacamba-destinada',
        Irregular: 'cacamba-irregular',
        'Com ocorrência Pessoa Física': 'cacamba-ocorrencia-pf',
        'Com ocorrência Pessoa Jurídica': 'cacamba-ocorrencia-pj'
      }[marker.cacamba.situacao]]
    },

    /**
     * Metodo para obter o filtro da lista de situacoes como parametro para as
     * buscas.
     * @returns {string[]} A lista de situacoes do filtro.
     */
    getSituacoesParam () {
      return [
        this.situacoesFilter,
        this.ocorrenciasFilter
      ]
        .flat()
        .reduce((acc, situacaoFilter) => {
          if (situacaoFilter.checked) {
            acc.push(situacaoFilter.situacao)
          }
          return acc
        }, [])
    },

    /**
     * Metodo para obter o geojson da visualizacao atual do mapa.
     * @returns {Object} O geoJson da visualizacao atual do mapa.
     */
    getBoundsGeoJson () {
      return {
        type: 'Polygon',
        coordinates: [[
          // ymax, xmin
          [this.mapBounds[1][0], this.mapBounds[0][1]],
          // ymax, xmax
          [this.mapBounds[1][0], this.mapBounds[1][1]],
          // ymin, xmax
          [this.mapBounds[0][0], this.mapBounds[1][1]],
          // ymin, xmin
          [this.mapBounds[0][0], this.mapBounds[0][1]],
          // ymax, xmin
          [this.mapBounds[1][0], this.mapBounds[0][1]]
        ]]
      }
    },

    ...mapActions('Crud', ['setLoading'])
  }
}
</script>

<style lang="scss">
.page-route-localizar-cacamba-map {
  > div {
    display: flex;
  }
}
.leaflet-map-component > .cacamba-icon {
  display: none;
}

.map-localizar-cacamba {
  display: flex;
  flex-direction: column;
  flex: 1;

  .filter-row {
    display: flex;
    margin-bottom: 16px;

    .group-filter-situacao {
      flex: 6;
    }

    .group-filter-ocorrencia {
      flex: 2;
    }
  }

  .results-row {
    display: flex;
    align-items: flex-end;

    .result-text-total {
      flex: 8;
      margin: 0 10px;
    }

    .result-text-update-time {
      margin: 0 10px;
    }

    .result-button-update {
      margin: 0 10px 0 10px;
    }
  }

  .leaflet-map-component {
    flex: 1;
  }

  .map-message-card {
    margin-bottom: 20px;

    &.error-message {
      background-color: #B00020 !important;
      color: white !important;
    }

    &.info-message {
      background-color: #ffce99 !important;
    }
  }

  .cacamba-icon {
    border-radius: 100%;
    width: 36px;
    padding: 4px;
    border: solid 2px white;

    > i {
      color: white !important;
    }
  }

  .cacamba-enviada {
    background: #2CB6FF;
  }

  .cacamba-alocada {
    background: #FFD832;
  }

  .cacamba-retirada {
    background: #4F66FF;
  }

  .cacamba-destinada {
    background: #6DE8A0;
  }

  .cacamba-irregular {
    background: #EA2937;
  }

  .cacamba-ocorrencia-pf {
    background: #9C2E78;
  }

  .cacamba-ocorrencia-pj {
    background: #FF81B4;
  }
}
</style>
