<template>
  <div class="leaflet-map-component">
    <div class="map-container" ref="mapContainer"></div>
    <slot/>
  </div>
</template>

<script>
import * as esriGeocode from 'esri-leaflet-geocoder'
import L from 'leaflet'
import 'leaflet.markercluster'
import 'leaflet/dist/leaflet.css'
import 'esri-leaflet-geocoder/dist/esri-leaflet-geocoder.css'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'

export default {
  name: 'leaflet-map',
  props: {
    /**
     * O centro do mapa quando ele for inicializado.
     */
    center: {
      type: Array,
      default: () => [-16.3, -55.8]
    },

    /**
     * O zoom inicial do mapa.
     */
    zoom: {
      type: Number,
      default: () => 4
    },

    /**
     * O retangulo atual do mapa. No formato: [[latMin, lonMin], [latMax, lonMax]].
     */
    bounds: {
      type: Array
    },

    /**
     * O bbox atual do mapa
     */
    bbox: {
      type: String
    }

  },
  /**
   * Metodo do vue para obter o estado inicial do componente.
   * @returns {Object} O estado inicial do componente.
   */
  data () {
    return {
      mapInstance: null,
      clusterLayer: null,
      baseMap: null
    }
  },
  watch: {
    /**
     * Metodo de callback de quando ocorrer mudancas na propriedade
     * 'mapInstance'. Com o objetivo de remover a instanciar anterior do mapa.
     * @param {Object} newInstance - A nova instancia do mapa.
     * @param {Object} oldInstance - A ultima instancia do mapa.
     */
    mapInstance (newInstance, oldInstance) {
      if (oldInstance) {
        oldInstance.remove()
      }
    },
    clusterLayer (newInstance, oldInstance) {
      if (oldInstance) {
        oldInstance.remove()
      }
    }
  },
  /**
   * Metodo do ciclo de vida do vue, de quando o componente foi montado na tela.
   * Com o objetivo de criar e inicializar o mapa.
   */
  mounted () {
    this.createMap()
    this.createBaseMap()
    this.addGeocodeWidget()
  },
  /**
   * Metodo do ciclo de vida do vue, de antes do componente ser destruido. Com o
   * objetivo de remover a instancia do mapa.
   */
  beforeDestroy () {
    this.mapInstance = null
  },
  methods: {
    /**
     * Metodo de comando, com o objetivo de criar o mapa.
     */
    createMap () {
      this.mapInstance = L.map(this.$refs.mapContainer, {
        center: this.center,
        zoom: this.zoom,
        maxZoom: 19
      })

      this.clusterLayer = L.markerClusterGroup()
      this.mapInstance.addLayer(this.clusterLayer)

      // sincroniza a prop bounds com o mapa
      if (this.bounds) {
        this.mapInstance.fitBounds(this.bounds)
      } else {
        this._updateBounds()
      }

      this.mapInstance.on('moveend', () => this._updateBounds())
    },

    /**
     * Metodo de comando, com o objetivo de criar o mapa base do mapa.
     */
    createBaseMap () {
      this.baseMap = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 19,
        attribution: '<a href="https://openstreetmap.org/copyright">OpenStreetMap</a>'
      }).addTo(this.mapInstance)
    },

    /**
     * Metodo de comando, com o objetivo de adicionar uma camada ao mapa.
     * @param {Object} layer - A camada a ser adicionada no mapa.
     */
    addToMap (layer) {
      if (this.clusterLayer) {
        this.clusterLayer.addLayer(layer)
      } else {
        this.$nextTick(() => this.addToMap(layer))
      }
    },

    /**
     * Centraliza o mapa no extent (bounds) dos markers adicionados
     */
    fitExtent () {
      this.$nextTick(() => {
        if (this.clusterLayer.getBounds()._southWest) {
          this.mapInstance.fitBounds(this.clusterLayer.getBounds())
        }
      })
    },

    /**
     * Método para adicionar o widget de busca de geocode no mapa
     */
    addGeocodeWidget () {
      if (this.mapInstance) {
        const searchControl = esriGeocode.geosearch({
          useMapBounds: false,
          placeholder: 'Procurar endereço',
          position: 'topright'
        }).addTo(this.mapInstance)

        const results = L.layerGroup().addTo(this.mapInstance)
        searchControl.on('results', (data) => {
          results.clearLayers()
          for (let i = data.results.length - 1; i >= 0; i--) {
            results.addLayer(L.marker(data.results[i].latlng))
          }
        })
      } else {
        this.$nextTick(() => this.addGeocodeWidget())
      }
    },

    /**
     * Metodo de comando, com o objetivo de atualizar a prop bounds, com o
     * retangulo atual do mapa.
     */
    _updateBounds () {
      if (this.mapInstance) {
        const mapBounds = this.mapInstance.getBounds()
        this.$emit('update:bbox', mapBounds.toBBoxString())
        const bounds = mapBounds.toBBoxString().split(',').map(v => parseFloat(v))

        this.$emit('update:bounds', [
          [bounds[0], bounds[1]],
          [bounds[2], bounds[3]]
        ])
      }
    },

    /*
     * GeoCode a partir de um texto de pesquisa
     * @param {String} text
     * @param {Object} [options]
     * @param {Boolean} [options.centerOnResult] Centraliza mapa ao encontrar o resultado. `Default: false`
     */
    getLocationFromTextAddress (text, options = {}) {

    },

    /**
     * GeoCode para obter um endereço textual a aprtir de um par de coordenadas
     * @param {Number} lat
     * @param {Number} lng
     * @param {Number} wkid
     * @param {Object} [options]
     */
    getTextAddressFromLocation ({ lat, lng, wkid }, options = {}) {

    }
  }
}
</script>

<style lang="scss">
.leaflet-map-component {
  position: relative;

  .map-container {
    position: relative;
    height: 100%;
    width: 100%;
  }
}

.map-localizar-cacamba {
  display: flex;
  flex-direction: column;
  flex: 1;

  .filter-row {
    display: inline-block;
    margin-bottom: 16px;

    .group-filter-situacao {
      flex: 6;
    }

    .group-filter-ocorrencia {
      flex: 2;
    }
  }

  .results-row {
    display: flex;
    align-items: flex-end;
    @media only screen and (max-width: 600px) {
      position: relative;
      align-items: flex-end;
      display: flex;
      font-size: 10px;
      left: 259px;
    }
    .updateMapButton {
      @media only screen and (max-width: 600px) {
        min-width: 28px;
        width: 20px;
        height: 21px;
        border-radius: 15px
      }
    }

    .result-text-total {
      flex: 8;
      margin: 0 10px;
    }

    .result-text-update-time {
      margin: 0 10px;
    }

    .result-button-update {
      margin: 0 10px 0 10px;
      @media only screen and (max-width: 600px) {
        margin: 18px 10px 0 10px;
      }
    }
  }

  .leaflet-map-component {
    width: 100%;
  }

  .leaflet-popup-content {
    min-width: 300px;

    * {
      max-width: 100%;
    }

  }

  .map-message-card {
    margin-bottom: 20px;

    &.error-message {
      background-color: #B00020 !important;
      color: white !important;
    }

    &.info-message {
      background-color: #ffce99 !important;
    }
  }

  .cacamba-icon {
    border-radius: 100%;
    width: 36px;
    padding: 4px;
    border: solid 2px white;
    background: #2CB6FF;
  }

  .obra-icon {
    border-radius: 100%;
    width: 36px;
    padding: 4px;
    border: solid 2px white;
    background: greenyellow;
  }

  .leaflet-popup {
    bottom: 30px !important;
  }
  .popupControl {
    width: 500px;
    background-color: black;
  }
  .geocoder-control-input leaflet-bar {
    position: relative;
    left: 300%;
    bottom: 63px;
  }
}

.ocorrencia-details {
  .form-popup {
    background-color: #fff;
    width: 100%;
    min-width: 300px;

    > div {
      margin-bottom: 20px;
    }

    textarea, input, div {
      max-width: 100%;
    }

    label {
      font-size: 13px;
      font-weight: 500;
    }
  }

  footer {
    padding-top: 10px;
    margin-top: 10px;
    border-top: 1px solid #DFDFDF;
    display: block;
    width: 100%;

    > .dx-button {
      margin-right: 10px;
    }
  }

  hr {
    border-color: #d3d3d3;
    border-width: 0;
    border-bottom-width: 1px;
    margin: 15px auto;
  }

  dl {
    dt {
      font-weight: bold;
    }

    dd {
      margin-left: 15px;
      margin-bottom: 10px;

      blockquote {
        margin-left: 0px;
        border-left: 2px solid #d3d3d3;
        padding-left: 10px;
        color: #3f3f3f;
      }
    }
  }

  .dx-widget.dx-fileuploader {
    margin: -10px;
  }
}

@media (min-width: 900px) {
  .page-route-acompanhar-ocorrencia-map {
    .leaflet-popup-content {
      min-width: 600px;
    }
  }

  .cols-popup {
    display: block;
    width: 100%;
  }

  .col-popup {
    width: 46%;
    padding: 0 2%;
    display: inline-block;
    vertical-align: top;
  }

  .col-popup:first-child {
    padding-left: 0;
  }
  .col-popup:last-child {
    padding-right: 0;
  }
}

</style>
