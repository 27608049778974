<template>
  <div class="fields-veiculo-cacamba-solicitacao">
    <div class="flex" v-if="!showOnlyCacamba">
      <model-select
        class="flex-col-3"
        :get-list-request="_doAvaliableVeiculosRequest"
        label="Veículo (Placa)"
        label-prop="placa"
        :options="fieldsBinds.veiculo"
        :value="value.veiculo"
        ref="fieldVeiculo"
        @input="onFieldInput('veiculo', $event)"/>
      <text-field
        class="flex-col-3"
        label="Tipo"
        :options="fieldsBinds.tipo"
        :value="value.tipo"
        @input="onFieldInput('tipo', $event)"/>
      <text-field
        v-if="value.tipo === 'Poliguindaste'"
        class="flex-col-3"
        label="Poliguindaste"
        :options="fieldsBinds.poliguindaste"
        :value="value.poliguindaste"
        @input="onFieldInput('poliguindaste', $event)"/>
    </div>
    <div class="flex" v-if="(showOnlyCacamba || (!showOnlyCacamba && value.tipo === 'Poliguindaste'))">
      <div class="cacambas">
        <label>Caçamba (Nº de Identicação)</label>
        <dx-lookup
          v-bind="fieldsBind.cacamba"
          placeholder="Selecione uma Caçamba"
          :value.sync="value.cacamba"
          ref="cacambaLookup"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getAvailableVeiculos, getAvailableCacambas } from '@/components/SolicitacaoTransporte/service'
/* import { DxSelectBox } from 'devextreme-vue' */
import ModelSelect from '../Form/ModelSelect'
import TextField from '../Form/TextField'
import { getParamsFromLoadOptions } from '@/util/loadOptionsParams'
import CustomStore from 'devextreme/data/custom_store'
import { DxLookup } from 'devextreme-vue/lookup'
import mixinCrud from '@/pages/Cruds/mixin-crud'

export default {
  name: 'fields-veiculo-cacamba-solicitacao',
  components: {
    ModelSelect,
    TextField,
    DxLookup
  },
  mixins: [mixinCrud],
  props: {
    /**
     * Os dados solicitacao com os valores dos campos.
     */
    value: {
      type: Object
    },

    /**
     * Se deve mostrar somente o campo de cacamba.
     */
    canEditCacamba: {
      type: Boolean,
      default: true
    },

    /**
     * Se deve mostrar somente o campo de cacamba.
     */
    canEditVeiculo: {
      type: Boolean,
      default: true
    },

    /**
     * Se deve mostrar somente o campo de cacamba.
     */
    showOnlyCacamba: {
      type: Boolean,
      default: false
    },

    /**
     * Se deve mostrar somente o campo de quantidade de cacambas.
     */
    showQtdCacambas: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      datasource: new CustomStore({
        key: this.labelProp,
        load: loadOptions => {
          return this.$utils.wrapRequestForGrid(
            this._doAvaliableCacambasRequest(getParamsFromLoadOptions(loadOptions))
          )
        },
        byKey: async key => {
          try {
            if (this.innerValue && this.innerValue[this.labelProp] === key) {
              return this.innerValue
            } else {
              const res = await this.getListRequest({ where: { [this.labelProp]: key } })
              return res.data[0] || null
            }
          } catch (e) {
            console.error(e)
          }
        }
      }),
      fieldsBind: {
        cacamba: {
          dataSource: {
            store: new CustomStore({
              key: 'id',
              load: loadOptions => this.$utils.wrapRequestForGrid(this.find({
                entity: 'ViewCacambasDisponiveis',
                loadOptions
              })),
              byKey: async key => {
                if (key.numeroIdentificacao) {
                  const res = await this._doAvaliableCacambasRequest({ where: { id: key } })
                  return res.data[0] || null
                }
              }
            }),
            paginate: true,
            pageSize: 10
          },
          valueExpr: 'id',
          displayExpr: 'numeroIdentificacao'
        }
      },
      selectedCacamba: null,
      treeDataSource: null,
      inputValue: null
    }
  },
  computed: {
    /**
     * Valor computado dos binds dos campos do popup.
     * @returns {Object}
     */
    fieldsBinds () {
      return {
        tipo: {
          readOnly: true
        },
        poliguindaste: {
          readOnly: true
        },
        cacamba: {
          readOnly: !this.canEditCacamba
        },
        quantidadeCacambas: {
          items: [1, 2, 3, 4, 5]
        },
        veiculo: {
          readOnly: !this.canEditVeiculo
        },
        numeroIdentificacao: {
          readOnly: true
        }
      }
    }
  },
  watch: {
    /**
     * Metodo de callback de mudancas no valor da prop 'veiculo' dentro do
     * objeto do valor da prop 'value'. Com o objetivo de definir o valor
     * dos campos de tipo e poliguindaste do veiculo.
     * @param {Object} veiculo - O valor atual da prop.
     */
    'value.veiculo' (veiculo) {
      if ((!this.value.veiculo && this.value.tipo && this.value.poliguindaste) ||
        (veiculo &&
          (this.value.tipo !== veiculo.tipo ||
          this.value.poliguindaste !== veiculo.poliguindaste))) {
        if (veiculo) {
          this.$emit('input', {
            ...this.value,
            tipo: veiculo.tipo,
            poliguindaste: veiculo.poliguindaste
          })
        } else {
          this.$emit('input', {
            ...this.value,
            tipo: null,
            poliguindaste: null
          })
        }
      }
    }
  },
  methods: {
    _isArray (value) {
      return value instanceof Array
    },
    onFieldInput (field, value) {
      this.$emit('input', {
        ...this.value,
        [field]: value
      })
    },

    /**
     * Metodo para buscar a lista de cacambas disponiveis para uma solicitacao
     * do servidor para o combo de cacambas.
     * @param {Object} params - Os parametros da requisicao.
     * @returns {Object[]} A lista de cacambas.
     */
    _doAvaliableCacambasRequest (params) {
      params.isTagBox = true
      return getAvailableCacambas({
        ...params,
        where: {
          ...params.where,
          ...(this.value.idCacamba ? { id: this.value.idCacamba } : {})
        }
      })
    },

    /**
     * Metodo para buscar a lista de veiculos disponiveis para uma solicitacao
     * do servidor para o combo de veiculos.
     * @param {Object} params - Os parametros da requisicao.
     * @returns {Object[]} A lista de veiculos.
     */
    _doAvaliableVeiculosRequest (params) {
      params.isTagBox = true
      return getAvailableVeiculos({
        ...params,
        where: {
          ...params.where,
          ...(this.value.idVeiculo ? { id: this.value.idVeiculo } : {})
        }
      })
    }
  }
}
</script>

<style lang="scss">
.fields-veiculo-cacamba-solicitacao {
  flex-wrap: wrap;
}
</style>
