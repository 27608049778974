<template>
  <div class="obra-pessoa-search-field">
    <dx-radio-group
      v-if="!ocultarCampos.includes('cpfCnpjSelect')"
      class="col-12"
      v-bind="cpfCnpjSelectOptions"
      :value.sync="cpfCnpjSelect"/>
    <text-field
      v-model="cpfCnpj"
      v-if="!ocultarCampos.includes('cpfCnpj')"
      class="col-12"
      :options="cpfCnpjOptions"/>
    <text-field
      class="col-12"
      label="Nome:"
      v-if="!ocultarCampos.includes('nomeRazaoSocial')"
      :options="pessoaFieldsOptions"
      :value="value.nome"
      @input="onPessoaFieldInput('nomeRazaoSocial', $event)"/>
    <template v-if="value.pessoa">
      <text-field
        v-if="showEmail"
        class="col-6"
        label="Email:"
        :options="pessoaFieldsOptions"
        :value="value.pessoa.email"
        @input="onPessoaFieldInput('email', $event)"/>
      <text-field
        v-if="showCelular"
        class="col-6"
        label="Celular:"
        :options="celularFieldOptions"
        :value="value.pessoa.celular"
        @input="onPessoaFieldInput('celular', $event)"/>
    </template>
  </div>
</template>
<script>
import DxRadioGroup from 'devextreme-vue/radio-group'

import TextField from '../Form/TextField'
import { getPessoaByCpfCnpj } from './service'

export default {
  name: 'pessoa-search-field',
  components: {
    DxRadioGroup,
    TextField
  },
  props: {
    ocultarCampos: {
      type: Array,
      default: () => ([])
    },
    /**
     * Se deve apresentar o campo de email.
     */
    showEmail: {
      type: Boolean,
      default: false
    },

    /**
     * Se deve apresentar o campo de velular.
     */
    showCelular: {
      type: Boolean,
      default: false
    },

    /**
     * O objeto com os valores dos campos. as props do objeto sao 'cpfCnpj',
     * 'cpfCnpjSelect' e 'pessoa'.
     */
    value: {
      type: Object,
      required: true
    },

    /**
     * Se os campos de pessoa estao validos, essa prop e mudada em tempo real.
     */
    isValid: {
      type: Boolean,
      default: false
    },

    /**
     * Se e possivel criar uma pessoa se nao encontrar uma pessoa pelo cpf/cnpj.
     */
    canCreatePessoa: {
      type: Boolean,
      default: false
    },

    /**
     * Se os campos sao somente para leitura.
     */
    readOnly: {
      type: Boolean,
      default: false
    }
  },

  /**
   * Metodo do vue para obter o estado inicial do componente.
   * @returns {Object} O estado inicial do componente.
   */
  data () {
    return {
      cpfCnpjOptions: {
        disabled: true,
        readOnly: this.readOnly
      },
      cpfCnpjSelectOptions: {
        isValid: true,
        items: [
          { text: 'CPF', value: 'CPF' },
          { text: 'CNPJ', value: 'CNPJ' }
        ],
        layout: 'horizontal',
        name: 'cpfCnpjSelect',
        readOnly: this.readOnly
      },
      pessoaFieldsDisabled: true
    }
  },

  computed: {
    /**
     * O valor do campo de escolha de cpf e cnpj.
     */
    cpfCnpjSelect: {
      get () {
        return (this.value && this.value.cpfCnpjSelect) || null
      },
      set (cpfCnpjSelect) {
        if (!cpfCnpjSelect) {
          this.cpfCnpjSelectOptions.isValid = false
          this.$emit('update:isValid', false)
        } else {
          this.cpfCnpjSelectOptions.isValid = true
          this.$emit('update:isValid', true)
        }

        this.$emit('input', {
          ...this.value,
          cpfCnpjSelect
        })
      }
    },

    /**
     * O valor do campo de cpf e cnpj.
     */
    cpfCnpj: {
      get () {
        return (this.value && this.value.cpfCnpj) || null
      },
      set (cpfCnpj) {
        if (!cpfCnpj || (cpfCnpj.length !== 11 && cpfCnpj.length !== 14)) {
          this.cpfCnpjOptions.isValid = false
          this.$emit('update:isValid', false)
        } else {
          this.cpfCnpjOptions.isValid = true
          this.$emit('update:isValid', true)
        }

        this.$emit('input', {
          ...this.value,
          cpfCnpj
        })
      }
    },

    /**
     * Valor computado das options do devextreme dos campos de pessoa.
     * @returns {Object}
     */
    pessoaFieldsOptions () {
      return {
        disabled: this.pessoaFieldsDisabled,
        readOnly: this.readOnly
      }
    },

    /**
     * Valor computado das options do devextreme do campo de celular.
     * @returns {Object}
     */
    celularFieldOptions () {
      return {
        ...this.pessoaFieldsOptions,
        mask: '(00) 00000-0000',
        maskInvalidMessage: 'O celular deve conter 11 números',
        maxLength: 11,
        readOnly: this.readOnly
      }
    }
  },

  watch: {
    /**
     * Metodo de callback das mudancas no valor da prop 'cpfCnpjSelect', com o
     * objetivo de atualizar a mascara do campo de cpf e cnpj e emitir o evento
     * de atualizar o valor do objeto 'value'.
     * @param {Object} cpfCnpjSelect - O valor da prop atualmente.
     * @param {Object} oldCpfCnpjSelect - O antigo valor da prop.
     */
    cpfCnpjSelect (cpfCnpjSelect, oldCpfCnpjSelect) {
      if (cpfCnpjSelect &&
        (!oldCpfCnpjSelect ||
        cpfCnpjSelect.value !== oldCpfCnpjSelect.value)) {
        this.pessoaFieldsDisabled = true

        this._setCpfCnpjMask()

        this.$emit('input', {
          ...this.value,
          pessoa: null,
          nome: null
        })
        this.onChangeCpfCnpj(this.cpfCnpj)
      }
    },
    cpfCnpj: 'onChangeCpfCnpj',

    /**
     * Metodo de callback de mudancas na prop 'readOnly', com o objetivo de
     * alterar se os campos serao somente para leitura.
     * @param {boolean} readOnly - Se os campos sao somente para leitura.
     */
    readOnly (readOnly) {
      this.cpfCnpjSelectOptions.readOnly = readOnly
      this.cpfCnpjSelect.readOnly = readOnly
    }
  },

  /**
   * Metodo do ciclo de vida do vue de quando o componente foi criado. Com o
   * objetivo de inicializar os valores dos campos de select do form.
   */
  created () {
    const newValue = { ...this.value }
    if (this.cpfCnpjSelect) {
      newValue.cpfCnpjSelect = this.cpfCnpjSelectOptions
        .items[this.cpfCnpjSelect === 'CNPJ' ? 1 : 0]
    }
    this._setCpfCnpjMask()

    if (this.value.pessoa) {
      newValue.nome = this.value.pessoa.nomeRazaoSocial
    }
    this.$emit('input', newValue)
  },

  methods: {
    /**
     * Metodo de callback de mudancas no valor da prop de 'cpfCnpj', com o
     * objetivo de validar o valor do cpf ou cnpj e buscar os dados da pessoa
     * com o cpf ou cnpj.
     * @param {string} value - O valor da prop atualmente.
     */
    async onChangeCpfCnpj (value) {
      this.$emit('input', {
        ...this.value,
        nome: 'Buscando dados da pessoa ...',
        pessoa: null
      })

      const type = this.cpfCnpjSelect.value
      if (value &&
        ((type === 'CPF' && value.length === 11) ||
        value.length === 14)) {
        const result = await getPessoaByCpfCnpj(type, value)

        const newValue = this.canCreatePessoa ? {
          pessoa: {
            email: null,
            celular: null
          },
          nome: ''
        } : {
          pessoa: null,
          nome: null
        }
        if (result.success) {
          newValue.pessoa = result.data.rows[0] || null
          if (newValue.pessoa) {
            newValue.nome = newValue.pessoa.nomeRazaoSocial
          } else {
            if (this.canCreatePessoa) {
              newValue.pessoa = {
                email: null,
                celular: null
              }
            } else {
              newValue.nome = `Não foi encontrado um usuário, com o ${type} '${value}'`
            }
          }
        } else if (!this.canCreatePessoa) {
          newValue.nome = `Falha ao buscar um usuário com o ${type} '${value}'`
          newValue.pessoa = null
        }

        this.pessoaFieldsDisabled = !!(!newValue.pessoa || (newValue.pessoa && newValue.pessoa.id))
        this.cpfCnpjOptions.isValid = !!newValue.pessoa
        this.$emit('update:isValid', !!newValue.pessoa)

        this.$emit('input', {
          ...this.value,
          ...newValue
        })
      } else {
        this.$emit('input', {
          ...this.value,
          nome: typeof value === 'string'
            ? `Falha ao buscar um usuário com o ${type} '${value}'`
            : `${type} inválido, tente outro`,
          pessoa: null
        })
        this.cpfCnpjOptions.isValid = false
        this.$emit('update:isValid', false)
      }
    },

    /**
     * Metodo de callback de quando ocorre o evento de input em um campo de
     * pessoa, com o objetivo de emitir o evento de atualizar os dados da pessoa.
     * @param {string} field - O campo que ocorreu o input.
     * @param {string} value - O valor do campo.
     */
    onPessoaFieldInput (field, value) {
      this.$emit('input', {
        ...this.value,
        pessoa: {
          ...this.value.pessoa,
          [field]: value
        }
      })
    },

    /**
     * Metodo de comando, com o objetivo de definir a mascara do campo de
     * cpfCnpj.
     */
    _setCpfCnpjMask () {
      if (this.cpfCnpjSelect) {
        const valueIsCpf = this.cpfCnpjSelect.value === 'CPF'

        this.cpfCnpjOptions = {
          disabled: false,
          readOnly: this.readOnly,
          ...(valueIsCpf ? {
            mask: '000.000.000-00',
            maskInvalidMessage: 'O CPF deve conter 11 números',
            maxLength: 11
          } : {
            mask: '00.000.000/0000-00',
            maskInvalidMessage: 'O CNPJ deve conter 14 números',
            maxLength: 14
          })
        }
      }
    }
  }
}
</script>

<style lang="scss">
.obra-pessoa-search-field {
  display: flex;
  flex-wrap: wrap;

  > .col-12 {
    padding: 4px;
    width: 100%;
  }

  > .col-6 {
    padding: 4px;
    width: 50%;
  }
}
</style>
