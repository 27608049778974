import ConsultaMoveResiduo from './ConsultaMoveResiduo.vue'

export default {
  path: '/consulta-move-residuo',
  name: 'Consultar Movimentação de Resíduos',
  component: ConsultaMoveResiduo,
  meta: {
    icon: 'mdi mdi-tanker-truck',
    title: 'Consultar Movimentação de Resíduos',
    requiresAuth: true,
    permissionType: 'consulta'
  },
  props: true
}
