<template>
  <div class="generic-crud">
    <div class="generic-crud-form">
      <fieldset>
        <div v-show="loading" style="position: relative; width: 100%; top:0">
          <md-progress-bar class="md-primary" md-mode="indeterminate"></md-progress-bar>
        </div>
      <md-card v-show="showPrestadorWarning" style="border-radius: 20px; margin-bottom: 20px; background-color: #f0ad4e">
        <md-card-content>
          <span style="font-size: 14px; font-weight: bold; color: white">
          Atenção! Este veículo foi cadastrado como de propriedade de outro transportador. Este cadastro será mantido como um veículo de prestador de serviço
          </span>
        </md-card-content>
      </md-card>
      <form v-if="formData" @submit="save">
        <DxForm
          ref="formInstance"
          :read-only="!canEdit"
          :form-data.sync="formData"
          :show-validation-summary="true">
          <DxGroupItem :caption="action === 'edit' ? canEdit ? 'Editar Veículo' : 'Visualizar veículo' : 'Adicionar Veículo'" :col-count="2">
            <DxSimpleItem
              v-for="(veiculoField, index) in veiculoFields"
              :key="index"
              v-bind="veiculoField"
            />
          </DxGroupItem>
          <DxGroupItem :col-count="2">
            <DxSimpleItem :col-span="2">
              <template #default>
                <div class="fileuploader-container">
                  <dx-file-uploader
                    :disabled="disabledDocumentos || !canEdit"
                    :selectButtonText="attachment ? 'Substituir documento' : 'Adicionar documento'"
                    :allow-canceling="true"
                    :value="attachment ? [attachment] : []"
                    upload-mode="useForm"
                    @value-changed="onChangeAttachment"
                  />
                  <div style="padding: 10px" v-if="attachment">
                    <dx-button
                      icon="mdi mdi-download"
                      @click="onDownloadAttachment">
                      <span class="mdi mdi-download"/> Baixar Anexo
                    </dx-button>
                  </div>
                </div>
              </template>
            </DxSimpleItem>
          </DxGroupItem>
          <DxGroupItem :col-count="3">
            <DxButtonItem
              :col-span="1"
              :button-options="{ text: 'Salvar', type: 'success', useSubmitBehavior: true, icon: 'mdi mdi-shape', disabled: loading, visible: canEdit }"
              horizontal-alignment="start"
              vertical-alignment="center"
            />
            <DxButtonItem
              :col-span="1"
              :visible="!!(action === 'edit' && veiculo && veiculo.token)"
              :button-options="{ text: 'Imprimir QRCODE', type: 'primary', onClick: generateQR }"
              horizontal-alignment="start"
              vertical-alignment="center"
            />
            <DxButtonItem
              :col-span="1"
              :visible="!!(action === 'edit' && veiculo && veiculo.token)"
              :button-options="{ text: 'Copiar link de acesso rápido', type: 'primary', onClick: copyAcessoRapido, width: 450 }"
              horizontal-alignment="start"
              vertical-alignment="center"
            />
          </DxGroupItem>
        </DxForm>
      </form>
      <legend v-if="action === 'add'">Veículo</legend>
      <legend v-else>Veículo</legend>
    </fieldset>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { DxButton } from 'devextreme-vue'
import { confirm } from 'devextreme/ui/dialog'
import QRCode from 'qrcode'
import notify from 'devextreme/ui/notify'
import _ from 'lodash'
import config from 'config'
import { DxFileUploader } from 'devextreme-vue/file-uploader'
import { http } from '@/pluggables/http'
import DxForm, { DxGroupItem, DxButtonItem, DxSimpleItem } from 'devextreme-vue/form'
import storeHelper from '@/helper/storeHelper'
import { hasPermission } from '@/util/permissions'

const baseUrl = config.baseUrl
export default {
  name: 'veiculo-crud',
  components: {
    DxButton,
    DxFileUploader,
    DxForm,
    DxGroupItem,
    DxButtonItem,
    DxSimpleItem
  },
  props: {
    action: {
      type: String
    },
    id: {
      type: String
    }
  },
  data () {
    return {
      entity: 'veiculo',
      disabledDocumentos: false,
      showPrestadorWarning: false,
      loading: false,
      formData: null,
      attachment: null,
      veiculo: null,
      veiculoFields: [
        {
          dataField: 'placa',
          label: { text: 'Placa', location: 'top' },
          dataType: 'string',
          validationRules: [{ type: 'required', message: 'Placa é obrigatório' }],
          editorOptions: {
            readOnly: this.action === 'edit',
            mask: 'HHH0A00',
            maskRules: { H: /[a-z]/i },
            maskInvalidMessage: 'Digite uma placa de veículo válida',
            minLength: 7,
            inputAttr: { style: 'text-transform: uppercase' },
            onValueChanged: async ({ component }) => {
              const value = (component.instance().option('value')).toUpperCase()
              // Caso a placa digitada seja diferente da placa original do veiculo sendo editado, no caso da adição sempre vai ser diferente, ja que "veiculo" é um objeto vazio
              if (this.veiculo.placa !== value) {
                this.loading = true
                const { data: veiculos } = await http.post(`${baseUrl}/find-veiculos-prestador`, {
                  placa: value
                })
                this.loading = false
                // Evita comportamento do FocusOut ser emitido em uma outra rota se o usuarío clicar entrar numa outra página ainda com o foco no Input
                if (this.$route.name === 'veiculo-add' || this.$route.name === 'veiculo-edit') {
                /**
                 * Verifica flag "exists", um boolean, que é "true" quando já existe um registro no banco de um veículo
                 * cadastrado com o idPerfil do usuário logado, neste caso retorna um erro
                 */
                  if (veiculos && veiculos.length && veiculos.find(veiculo => veiculo.exists)) {
                    notify('Veículo já cadastrado anteriormente, tente uma outra placa', 'warning', 4000)
                    this.clearData(['tipo', 'placa', 'marca', 'modelo', 'renavam', 'cor', 'ano', 'volumeM3'])
                  } else {
                    const fields = ['tipo', 'marca', 'modelo', 'renavam', 'cor', 'ano', 'volumeM3', 'poliguindaste', 'numeroIdentificacao', 'documento']
                    /**
                   * Caso exista um registro de veículo no banco que não pertenca ao perfil do usuario logado, neste caso emite confirmação
                   * e atualiza os dados do veiculo atual com os dados do veiculo buscado
                   */
                    if (veiculos) {
                      const veiculo = veiculos[0]
                      const dialogResult = await confirm('<i> Prestador de serviço: A cobrança referente a pesagem deste veículo incidirá sobre a empresa em que o mesmo já encontra-se cadastrado, não sendo possível a modificação do tíquete de pesagem, deseja mesmo continuar? </i>', 'Confirmar')
                      if (dialogResult) {
                        this.setPrestador(fields, veiculo)
                      } else {
                      /**
                       * Caso o usuário responda "não" na confirmação
                       */
                        // Caso esteja adicionando um novo veículo, somente limpa os campos
                        if (this.action === 'add') {
                          this.clearData(['tipo', 'placa', 'marca', 'modelo', 'renavam', 'cor', 'ano', 'volumeM3'])
                        } else if (this.action === 'edit') {
                          // Caso esteja editando um novo veículo, recupera campos do veículo que está sendo editado, voltando ao estágio inicial
                          for (const key in this.veiculo) {
                            this.$refs.formInstance.instance.updateData(key, this.veiculo[key])
                          }
                        }
                      }
                    /**
                     * Caso não exista nenhum registro no banco, fluxo normal, limpa os demais dados EXCETO a placa
                     */
                    } else {
                      this.clearData(['tipo', 'marca', 'modelo', 'renavam', 'cor', 'ano', 'volumeM3'])
                    }
                  }
                }
              } else {
                // Caso aonde a placa digitada é igual a placa original do veículo, só reatribui as informações
                for (const key in this.veiculo) {
                  this.$refs.formInstance.instance.updateData(key, this.veiculo[key])
                }
              }
            }
          }
        },
        {
          dataField: 'marca',
          label: { text: 'Marca', location: 'top' },
          dataType: 'string',
          editorOptions: { maxLength: 40 },
          validationRules: [{ type: 'required', message: 'Marca é obrigatório' }]
        },
        {
          dataField: 'modelo',
          label: { text: 'Modelo', location: 'top' },
          dataType: 'string',
          editorOptions: { maxLength: 30 },
          validationRules: [{ type: 'required', message: 'Modelo é obrigatório' }]
        },
        {
          dataField: 'numeroIdentificacao',
          label: { text: 'Número de identificação', location: 'top' },
          visible: this.action === 'edit',
          editorOptions: { readOnly: true }
        },
        {
          dataField: 'renavam',
          label: { text: 'Renavam', location: 'top' },
          editorType: 'dxTextBox',
          dataType: 'string',
          editorOptions: {
            mask: '00000000000',
            maxLength: 11
          },
          validationRules: [{ type: 'required', message: 'Renavam é obrigatório' }]
        },
        {
          dataField: 'cor',
          dataType: 'string',
          label: { text: 'Cor', location: 'top' }
        },
        {
          dataField: 'ano',
          label: { text: 'Ano de fabricação', location: 'top' },
          dataType: 'string',
          editorType: 'dxNumberBox',
          editorOptions: {
            min: 1970,
            max: (new Date().getFullYear()) + 1
          },
          validationRules: [
            {
              type: 'required',
              message: 'Ano é obrigatório'
            },
            {
              type: 'range',
              min: 1970,
              max: (new Date().getFullYear()) + 1,
              message: 'Ano inválido'
            }]
        },
        {
          dataField: 'tipo',
          label: { text: 'Tipo', location: 'top' },
          editorType: 'dxSelectBox',
          editorOptions: {
            items: ['Basculante', 'Poliguindaste'],
            onValueChanged: ({ value }) => {
              this.$refs.formInstance.instance.itemOption('veiculo.poliguindaste', 'visible', value === 'Poliguindaste')
              /**
               * Seta valor de "poliguindaste" nulo quando o tipo é basculante
               */
              if (value === 'Basculante') {
                this.$refs.formInstance.instance.updateData('poliguindaste', null)
              }
            }
          },
          validationRules: [{ type: 'required', message: 'Tipo é obrigatório' }]
        },
        {
          dataField: 'volumeM3',
          label: { text: 'Volume (m³)', location: 'top' },
          dataType: 'number',
          editorType: 'dxNumberBox',
          editorOptions: {
            min: 1,
            max: 100
          },
          validationRules: [
            { type: 'required', message: 'Volume (m³) é obrigatório' },
            { type: 'range', min: 0, max: 100, message: 'Intervalo Volume (m³) inválido, são aceitos apenas valores entre 0 e 100' },
            { type: 'custom', validationCallback: ({ value }) => value > 0, message: 'O Volume precisa ser maior do que 0' }
          ]
        },
        {
          dataField: 'poliguindaste',
          label: { text: 'Poliguindaste', location: 'top' },
          visible: false,
          editorType: 'dxSelectBox',
          editorOptions: {
            items: ['Simples', 'Duplo', 'Triplo']
          },
          validationRules: [{ type: 'required', message: 'Poliguindaste é obrigatório' }]
        },
        {
          dataField: 'token',
          label: { text: 'Token', location: 'top' },
          colSpan: 2,
          dataType: 'string',
          validationRules: [
            { type: 'required', message: 'Token é obrigatório' },
            {
              type: 'custom',
              validationCallback: ({ value }) => {
                if (value) {
                  return value.toString().length === 6
                } else {
                  return true
                }
              },
              message: 'O token deve ter 6 caracteres'
            }
          ],
          editorOptions: {
            mask: '000000',
            maskInvalidMessage: 'O token deve ter 6 caracteres'
          }
        },
        {
          dataField: 'documentosHiddenField',
          label: { text: '', location: 'top' },
          colSpan: 2,
          editorType: 'dxSelectBox',
          cssClass: 'hidden',
          validationRules: [
            {
              type: 'custom',
              reevaluate: true,
              validationCallback: () => !!(this.attachment),
              message: 'Nenhum documento foi anexado a este veículo'
            }
          ]
        }
      ]
    }
  },
  methods: {
    ...mapActions('Crud', ['findOne']),
    setPrestador (fields, veiculo, updateData = true) {
      for (const field of fields) {
        if (updateData) this.$refs.formInstance.instance.updateData(field, veiculo[field])
        this.$refs.formInstance.instance.getEditor(field)?.option('disabled', true)
      }
      if (updateData) {
        this.formData.prestador = 1
        this.formData.idVeiculoOriginal = veiculo.id
      }
      if (this.formData.documento) {
        const fileBuffer = new Uint8Array(this.formData.documento.data)
        const blob = new Blob([fileBuffer], { type: veiculo.contentType || this.attachment.type })
        this.attachment = new File([blob], veiculo.nomeDocumento || this.attachment.name, { type: veiculo.contentType || this.attachment.type })
      }
      this.disabledDocumentos = true
    },
    clearData (fields) {
      for (const field of fields) {
        if (this.$refs.formInstance) {
          this.$refs.formInstance.instance.updateData(field, null)
          this.$refs.formInstance.instance.getEditor(field)?.option('disabled', false)
        }
      }
      this.disabledDocumentos = false
      this.attachment = null
      if (this.formData) {
        this.formData.prestador = 0
        this.formData.idVeiculoOriginal = null
      }
    },
    async save (e) {
      e.preventDefault()
      if (!storeHelper.isSecretaria || !storeHelper.isAdministrador) {
        this.formData.sigla = this.userData.sigla
      } else {
        this.formData.sigla = this.selectedUserSigla
      }
      if (!this.formData.token || !this.formData.token.length) this.formData.token = null
      if (this.formData.placa) this.formData.placa = this.formData.placa.toUpperCase()
      const attachmentFormData = new FormData()
      /**
       * Converte formData para uma instância de FormData
       */
      const data = _.cloneDeep(this.formData)
      for (let [key, value] of Object.entries(data)) {
        if (key !== 'documento') {
          if (!value && value !== 0) value = ''
          attachmentFormData.append(key, value)
        }
      }

      /**
       * Inserção dos documentos
       * @type {Blob}
       */
      attachmentFormData.set('documento', this.attachment, this.attachment.name)
      attachmentFormData.set('contentType', this.attachment.type)
      attachmentFormData.set('nomeDocumento', this.attachment.name)

      if (this.action === 'add') {
        try {
          this.loading = true
          await http.post(`${baseUrl}/${this.entity}`, attachmentFormData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          this.loading = false
          await this.$router.push('/veiculo')
        } catch (err) {
          this.loading = false
          if (err.response && err.response.data && err.response.data.details && err.response.data.details.errorType && err.response.data.details.errorType === 'SequelizeUniqueConstraintError') {
            notify('Um veículo ja foi cadastrado com esta placa e token', 'error', 2000)
            console.error(err.message)
          } else if (err.response && err.response.data && err.response.data.message && err.response.data.message === 'Já existe um veículo com esta placa') {
            notify('Já existe um veículo com esta placa', 'error', 2000)
            console.error(err.message)
          } else if (err.response && err.response.data && err.response.data.message && err.response.data.message === 'Não é possível cadastrar este veículo. Veículo já cadastrado para outro transportador') {
            notify('Não é possível cadastrar este veículo. Veículo já cadastrado para outro transportador', 'error', 2000)
            console.error(err.message)
          } else {
            notify('Não foi possível incluir este veículo, por favor, contate um administrador do sistema', 'error', 2000)
            console.error(err.message)
          }
        }
      } else {
        try {
          this.loading = true
          await http.put(`${baseUrl}/${this.entity}/${this.formData.id}`, attachmentFormData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          this.loading = false
          await this.$router.push('/veiculo')
        } catch (err) {
          this.loading = false
          if (err.response && err.response.data && err.response.data.details && err.response.data.details.errorType && err.response.data.details.errorType === 'SequelizeUniqueConstraintError') {
            notify('Um veículo ja foi cadastrado com esta placa e token', 'error', 2000)
            console.error(err.message)
          } else {
            notify('Não foi possível editar este veículo, por favor, contate um administrador do sistema', 'error', 2000)
            console.error(err.message)
          }
        }
      }
    },
    copyAcessoRapido () {
      navigator.clipboard.writeText(`${config.destinoRapidoUrl}/${this.formData.placa}/${this.formData.token}`)
      notify('Link de acesso copiado com sucesso', 'success')
    },
    async generateQR () {
      try {
        const base64QrCode = await QRCode.toDataURL(`${config.destinoRapidoUrl}/${this.formData.placa}/${this.formData.token}`, { scale: 3 })
        const el = document.createElement('div')
        el.innerHTML = `
        <div ref="printContainer" style="display: flex; height: 100vh">
          <div style="margin:auto">
            <div style="text-align: center">
              <h3 style="text-align: center; margin: 0">Acesso ao destino rápido</h3>
              <img style="height: 200px; width: 200px" src="${base64QrCode}">
              <h3 style="text-align: center; margin: 0">${config.destinoRapidoUrl}/${this.formData.placa}/${this.formData.token}</h3>
            </div>
          </div>
        </div>`
        const pdfWindow = window.open('')
        pdfWindow.document.body.innerHTML = el.innerHTML
        await this.$nextTick()
        pdfWindow.print()
      } catch (err) {
        console.error(err)
      }
    },
    onChangeAttachment ({ value }) {
      if (value.length) {
        this.$refs.formInstance.instance.validate()
        this.attachment = value[0]
      }
    },
    async onDownloadAttachment  () {
      const download = async (buffer, name) => {
        const fileBuffer = new Uint8Array(buffer)
        const blob = new Blob([fileBuffer])
        const fileName = name || this.solicitacaoData.nomeFotoCacamba
        const link = document.createElement('a')
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob)
          link.setAttribute('href', url)
          link.setAttribute('download', fileName)
          link.style.visibility = 'hidden'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      }
      if (this.attachment.isNull) {
        try {
          const veiculoDocumento = await this.findOne({
            entity: 'Veiculo',
            params: {
              attributes: ['documento', 'nomeDocumento'],
              where: {
                id: this.id
              }
            }
          })
          await download(veiculoDocumento.documento.data, veiculoDocumento.nomeDocumento)
        } catch (err) {
          console.error(err)
          notify('Não foi possível buscar este documento, por favor contate um administrador do sistema', 'error', 2000)
        }
      } else {
        const buffer = await this.attachment.arrayBuffer()
        await download(buffer, this.attachment.name)
      }
    }
  },
  computed: {
    ...mapGetters('Login', { userData: 'userData' }),
    ...mapState('Login', ['user']),
    canEdit () {
      return hasPermission('Manter Veículo', 'altera')
    }
  },
  watch: {
    /**
     * Assiste formData para preencher variavel attachment para o anexo
     * @param value
     */
    async formData (value) {
      if (value.nomeDocumento) {
        this.attachment = new File([], value.nomeDocumento, { type: value.contentType })
        this.attachment.isNull = true
      }
      if (value.id && value.prestador === 1) {
        const fields = ['tipo', 'placa', 'marca', 'modelo', 'renavam', 'cor', 'ano', 'volumeM3', 'poliguindaste', 'documento', 'numeroIdentificacao']
        this.showPrestadorWarning = true
        await this.$nextTick()
        this.setPrestador(fields, this.formData, false)
      }
    }
  },
  async mounted () {
    this.loading = true
    if (this.action === 'edit') {
      this.veiculo = await this.findOne({
        entity: 'Veiculo',
        params: {
          attributes: [
            'id',
            'placa', 'marca', 'modelo', 'idPerfil', 'numeroIdentificacao', 'tipo', 'prestador',
            'renavam', 'cor', 'ano', 'volumeM3', 'token', 'poliguindaste', 'nomeDocumento', 'contentType'
          ],
          where: {
            id: this.id
          }
        }
      })
      this.formData = { ...this.veiculo }
      await this.$nextTick()
      this.$refs.formInstance.instance.itemOption('veiculo.poliguindaste', 'visible', this.formData.tipo === 'Poliguindaste')
    } else {
      this.formData = {}
      this.veiculo = {}
    }
    if (storeHelper.currentUserIs('Transportador')) {
      this.formData.idPerfil = storeHelper.getCurrentPerfisTransportador(true).id
    }
    this.loading = false
  }

}
</script>
<style lang="scss">
.fileuploader-container {
  border: 1px solid #d3d3d3;
  margin-bottom: 20px;
}
.veiculo-container {
  display: flex;
  place-content: center;
  height: calc(100vh - 100px);
  align-items: center;
}
</style>
