import { doRequest } from '@/pluggables/http'

/**
 * Metodo de comando, com o objetivo de fazer a requisicao de listar todas as
 * classes de materias para o servidor.
 * @param {Object} params - Os parametros da requisicao.
 * @returns {Object} O resultado da requisicao.
 */
export function listClassesMateriais (params) {
  return doRequest({
    method: 'post',
    url: 'ClasseMaterial/find',
    params: {
      where: params.where && params.where.nome ? {
        or: [
          { '$tipoResiduos.nome$': params.where.nome },
          { classe: params.where.nome }
        ]
      } : params.where,
      include: {
        model: 'TipoResiduo',
        as: 'tipoResiduos',
        required: true
      }
    }
  })
}
