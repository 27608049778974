export const fieldsVideos = [
  {
    id: 1,
    title: 'Cadastro de Veículo:',
    url: 'http://www.notoriun.com.br/download/rcc_videos/rcc_transportador_manter_veiculos.mp4',
    perfis: ['Transportador', 'Secretaria', 'Administrador']
  },
  {
    id: 2,
    title: 'Cadastro de caçambas estacionárias:',
    url: 'http://www.notoriun.com.br/download/rcc_videos/rcc_transportador_manter_cacamba.mp4',
    perfis: ['Transportador', 'Secretaria', 'Administrador']
  },
  {
    id: 3,
    title: 'Avaliar uma solicitação de Transporte (Feita por um gerador que deseja que sua empresa efetue a coleta do resíduo):',
    url: 'http://www.notoriun.com.br/download/rcc_videos/rcc_transportador_avaliar_solicitacao_transporte.mp4',
    perfis: ['Transportador', 'Secretaria', 'Administrador']
  },
  {
    id: 4,
    title: 'Quando tratar-se do transporte com um veículo do tipo POLIGUINDASTE:',
    subtitle: 'Criação de um CTR não solicitado por um cliente (Envio de transporte não solicitado):',
    url: 'http://www.notoriun.com.br/download/rcc_videos/rcc_transportador_enviar_transporte_nao_solicitado.mp4',
    perfis: ['Transportador', 'Secretaria', 'Administrador']
  },
  {
    id: 5,
    title: 'Alocação de um CTR (Quando a caçamba estacionária estiver disposta no endereço do gerador):',
    url: 'http://www.notoriun.com.br/download/rcc_videos/rcc_transportador_alocar_cacamba.mp4',
    perfis: ['Transportador', 'Secretaria', 'Administrador']
  },
  {
    id: 6,
    title: 'Retirada de um CTR (Quando a caçamba estacionária estiver sendo retirada do cliente com destino à Unidade de Disposição Final):',
    url: 'http://www.notoriun.com.br/download/rcc_videos/rcc_transportador_retirar_cacamba.mp4',
    perfis: ['Transportador', 'Secretaria', 'Administrador']
  },
  {
    id: 7,
    title: 'Inclusão do CTR no Destino Rápido do veículo desejado (Quando o veículo já estiver à caminho da Unidade de Disposição Final)',
    url: 'http://www.slu.df.gov.br/wp-content/uploads/2022/07/Destino-rapido.mp4?_=1',
    perfis: ['Transportador', 'Secretaria', 'Administrador']
  },
  {
    id: 8,
    title: 'Quando tratar-se do transporte com um veículo do tipo BASCULANTE:',
    subtitle: 'Criação de um CTR não solicitado por um cliente (Envio de transporte não solicitado): Obs.: Neste caso seleciona-se apenas o veículo, já que trata-se de um basculante.',
    url: 'http://www.notoriun.com.br/download/rcc_videos/rcc_transportador_enviar_transporte_nao_solicitado.mp4',
    perfis: ['Transportador', 'Secretaria', 'Administrador']
  },
  {
    id: 9,
    title: 'Retirada de um CTR (Quando o resíduo estiver sendo retirado do cliente com destino à Unidade de Disposição Final):',
    url: 'http://www.notoriun.com.br/download/rcc_videos/rcc_transportador_retirar_cacamba.mp4',
    perfis: ['Transportador', 'Secretaria', 'Administrador']
  },
  {
    id: 10,
    title: 'Inclusão do CTR no Destino Rápido do veículo desejado (Quando o veículo já estiver à caminho da Unidade de Disposição Final)',
    url: 'http://www.slu.df.gov.br/wp-content/uploads/2022/07/Destino-rapido.mp4?_=1',
    perfis: ['Transportador', 'Secretaria', 'Administrador']
  },
  {
    id: 11,
    title: 'Para Geradores pessoa física e pessoa jurídica:',
    perfis: ['Gerador', 'Secretaria', 'Administrador']
  },
  {
    id: 12,
    title: 'Cadastro de obra:',
    url: 'http://www.notoriun.com.br/download/rcc_videos/rcc_gerador_manter_obra.mp4',
    perfis: ['Gerador', 'Secretaria', 'Administrador']
  },
  {
    id: 13,
    title: 'Solicitar Transporte de um transportador cadastrado no SLU:',
    description: 'Para solicitar o transporte dos seus resíduos, basta preencher o campo <b>NOME DA OBRA</b> com o nome da obra já criada, no campo <b>TRANSPORTADOR</b>, o usuário deve buscar <b>NOMINALMENTE</b> o transportador que deseja contratar para a coleta e então adicioná-lo a obra, no campo <b>TIPO DE RESÍDUO</b> deve ser selecionado o material a ser coletado pelo transportador, sendo, portanto: <b>Classe A "resíduo limpo (SEGREGADO), Classe Mista "resíduo misturado" (RESÍDUO NÃO SEGREGADO) e Classe Poda</b> para podas de árvore e afins. Posteriormente, deve-se definir a <b>QUANTIDADE ESTIMADA</b> de resíduos a ser coletado naquele transporte, após isso, basta salvar a solicitação e acompanhá-la por meio do <b>LOCALIZAR CTR</b>.',
    perfis: ['Gerador', 'Secretaria', 'Administrador']
  },
  {
    id: 14,
    title: 'Editar CTR',
    description: 'Para editar um CTR, basta clicar no botão <b>EDITAR</b> e então alterar os campos desejados, após isso, basta salvar a solicitação e acompanhá-la por meio do <b>LOCALIZAR CTR</b>.',
    url: 'http://www.notoriun.com.br/download/rcc_videos/como_editar_ctr.mp4',
    perfis: ['Transportador', 'Secretaria', 'Administrador']
  }
]
