<template>
  <div class="generic-crud">
    <div v-if="action" class="generic-crud-form">
      <h2 v-if="action === 'add'">Incluir Triagem de Resíduos</h2>
      <fieldset>
        <legend>Informações da triagem</legend>
        <div v-if="currentValuesRow">
          <div class="flex">
            <div class="flex-col-6"><b>Nº da CTR: </b></div>
            <div class="flex-col-8">{{ currentValuesRow.idSolicitacaoTransporte }}</div>
          </div>
          <div class="flex">
            <div class="flex-col-6"><b>Transportador: </b></div>
            <div class="flex-col-8">{{ currentValuesRow.transportadorNome }}</div>
          </div>
          <div class="flex">
            <div class="flex-col-2">
              <b>Enviar Para: </b>
            </div>
            <div class="flex-col-8">
              <div class="dx-field-value">
                <DxSelectBox
                  ref="selectBox"
                  :items="enviarParaValues"
                  v-model="currentValuesRow.enviarPara"
                />
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="flex-col-4">
              <b>Observação: </b>
            </div>
            <div class="flex-col-6">
              <DxTextArea
                class="dx-field-value" :height="80" :width="293"
                :disabled="false"
                v-model="currentValuesRow.observacao"
              />
            </div>
          </div>
        </div>
      </fieldset>
      <fieldset v-if="currentValuesRow && currentValuesRow.idSolicitacaoTransporte">
        <legend>Controle de resíduos triados</legend>
        <grid-residuos ref="gridResiduos" :id="currentValuesRow.idSolicitacaoTransporte"/>
      </fieldset>
      <div style="margin: 10px;">
        <dx-button class="saveButton" type="success" @click="save">Salvar</dx-button>
      </div>
    </div>

    <div v-else>
      <div class="md-layout">
        <div class="md-layout-item">
          <h2 class="crud-title">Controlar Resíduos Triados</h2>
        </div>
      </div>
      <simple-grid entity="SolicitacaoTransporte" ref="simpleGrid" popup-title="Controle de Resíduos"
                   :popup-data="{ maxWidth: 800 }" :editable="false" :deletable="false" :grid-data="gridData"></simple-grid>
    </div>
  </div>
</template>

<script>
import { SimpleGrid } from '@/components'
import CustomStore from 'devextreme/data/custom_store'
import mixinCrud from '../mixin-crud'
import config from 'config'
import { mapActions, mapState } from 'vuex'
import { getByKeys } from '@/util/getByKeys'
import { DxButton, DxTextArea } from 'devextreme-vue'
import DxSelectBox from 'devextreme-vue/select-box'
import Swal from 'sweetalert2'
import GridResiduos from '@/components/ResiduosTriados/GridResiduos'
import lodash from 'lodash'

export default {
  name: 'controle-residuos-triados',
  mixins: [
    mixinCrud
  ],
  components: {
    SimpleGrid,
    DxTextArea,
    DxButton,
    DxSelectBox,
    GridResiduos
  },
  computed: {
    ...mapState('Login', ['user'])
  },
  watch: {
    '$route.params.id': {
      async handler (idSolicitacaoTransporte) {
        const solicitaoTransporte = (await this.find({
          entity: 'SolicitacaoTransporte',
          rowsOnly: true,
          params: {
            where: JSON.stringify({
              id: idSolicitacaoTransporte
            }),
            take: 1,
            include: JSON.stringify([
              {
                model: 'Perfil',
                as: 'transportador',
                include: [
                  {
                    model: 'Pessoa',
                    as: 'pessoa'
                  }
                ]
              },
              {
                model: 'Triagem',
                as: 'triagem'
              }
            ])
          }
        }))[0]
        this.currentValuesRow = {
          idSolicitacaoTransporte,
          transportadorNome: lodash.get(solicitaoTransporte, 'transportador.pessoa.nomeRazaoSocial', ''),
          idTriagem: lodash.get(solicitaoTransporte, 'triagem.id', null),
          observacao: lodash.get(solicitaoTransporte, 'triagem.observacao', ''),
          enviarPara: lodash.get(solicitaoTransporte, 'triagem.enviarPara', '')
        }
      }
    }
  },
  methods: {
    _currentUserIs (perfil) {
      return this.user.perfis.filter(p => {
        if (typeof perfil === 'string') {
          return p.tipoPerfil.descricao === perfil
        } else {
          return perfil.includes(p.tipoPerfil.descricao)
        }
      }).length >= 1
    },
    ...mapActions('Crud', ['updateRow', 'insertRow', 'removeRow', 'setLoading']),

    /**
     * Método save() que é acionado quando botão "salvar" é acionado na tela de edição de alguma triagem
     * responsável mandar a requisição com os parametros configurados.
     */
    async save () {
      try {
        if (!this.currentValuesRow.enviarPara) {
          throw new CustomError('Você deve selecionar um destino para a triagem')
        }
        const triagensResiduos = this.$refs.gridResiduos.triagem.triagemResiduo
        if (!triagensResiduos || !triagensResiduos.length) {
          throw new CustomError('Você deve adicionar uma triagem de resíduo abaixo')
        }
        if (this.currentValuesRow.idTriagem) {
          await this.updateRow({
            entity: 'Triagem',
            key: this.currentValuesRow.idTriagem,
            values: {
              idSolicitacaoTransporte: this.currentValuesRow.idSolicitacaoTransporte,
              enviarPara: this.currentValuesRow.enviarPara,
              observacao: this.currentValuesRow.observacao
            }
          })
          const promises = []

          for (const triagemResiduo of triagensResiduos) {
            if (triagemResiduo._status === 'update') {
              promises.push(
                this.updateRow({
                  entity: 'TriagemResiduo',
                  key: triagemResiduo.id,
                  values: {
                    idTriagem: this.currentValuesRow.idTriagem,
                    idTipoResiduo: triagemResiduo.idTipoResiduo,
                    volumeAproximadoM3: triagemResiduo.volumeAproximadoM3
                  }
                })
              )
            } else if (triagemResiduo._status === 'insert') {
              promises.push(
                this.insertRow({
                  entity: 'TriagemResiduo',
                  values: {
                    idTriagem: this.currentValuesRow.idTriagem,
                    idTipoResiduo: triagemResiduo.idTipoResiduo,
                    volumeAproximadoM3: triagemResiduo.volumeAproximadoM3
                  }
                })
              )
            } else if (triagemResiduo._status === 'remove') {
              promises.push(
                this.removeRow({
                  entity: 'TriagemResiduo',
                  key: triagemResiduo.id
                })
              )
            }
          }

          await Promise.all(promises)
        } else {
          const { data } = await this.$http.post(this.$http.normalizeUrl('/residuos-triados-api/cadastrar'), {
            dadosTriagem: {
              idSolicitacaoTransporte: this.currentValuesRow.idSolicitacaoTransporte,
              enviarPara: this.currentValuesRow.enviarPara,
              observacao: this.currentValuesRow.observacao
            },
            residuosTriados: triagensResiduos
          })
          this.currentValuesRow = {
            ...this.currentValuesRow,
            idTriagem: data.id
          }
        }

        this.$router.push({ path: '/controle-residuos-triados/' })
      } catch (error) {
        let message = 'Verifique os campos e tente novamente'
        if (error instanceof CustomError) {
          message = error.message
        }
        Swal.fire({
          title: 'Erro ao salvar dados',
          icon: 'error',
          text: message
        })
        console.error(error)
      } finally {
        this.setLoading(false)
      }
    }
  },
  data () {
    return {
      entity: 'SolicitacaoTransporte',
      formData: null,
      enviarParaValues: ['Aterro', 'Recicladora'],
      currentValuesRow: null,
      currentFilter: [],
      solicitacaoValues: null,
      gridData: {
        value: {
          visible: true
        },
        columns: this.parseColumns([
          {
            dataField: 'id',
            caption: 'Nº do CTR',
            formItem: {
              visible: false
            },
            sortOrder: 'desc',
            dataType: 'number',
            cellTemplate: (template, data) => {
              template.innerHTML = `<a href="${config.baseUrl}/FichaCTR/${data.data.id}" target="_blank">#${data.data.id}</a>`
            }
          },
          {
            dataField: '$triagem.enviarPara$',
            visible: false,
            caption: 'Enviar Para:',
            editorType: 'dxLookup',
            editorOptions: {
              searchEnabled: false,
              showCancelButton: false,
              allowClearing: true,
              showClearButton: true,
              dataSource: [{
                name: 'Aterro',
                value: 'Aterro'
              }, {
                name: 'Recicladora',
                value: 'Recicladora'
              }],
              valueExpr: 'value',
              displayExpr: 'name',
              inputAttr: {
                id: 'tipoValue'
              }
            },
            calculateSortValue: 'triagem.enviarPara',
            validationRules: [{
              type: 'required'
            }]
          },
          {
            dataField: 'dataHoraSolicitacao',
            caption: 'Data/Hora da Solicitação',
            formItem: {
              visible: false
            },
            format: 'dd/MM/yyyy',
            dataType: 'date',
            editorType: 'dxDateBox'
          },
          {
            dataField: '$transportador.id$',
            calculateSortValue: 'transportador.pessoa.nomeRazaoSocial',
            caption: 'Transportador',
            editorType: 'dxLookup',
            editorOptions: {
              dataSource: {
                store: new CustomStore({
                  load: (loadOptions) => {
                    return this.$utils.wrapRequestForGrid(this.find({
                      entity: 'ViewTransportador',
                      loadOptions
                    }))
                  },
                  byKey: key => key || null
                }),
                sort: [
                  { selector: 'nomeRazaoSocial', asc: true }
                ],
                paginate: true,
                pageSize: 5
              },
              displayExpr: 'nomeRazaoSocial',
              valueExpr: 'id'
            },
            calculateDisplayValue: 'transportador.pessoa.nomeRazaoSocial',
            validationRules: [{
              type: 'required'
            }]
          },
          {
            dataField: '$transportador->pessoa.cpfCnpj$',
            allowFiltering: false,
            caption: 'CPF/CNPJ',
            formItem: {
              visible: false
            },
            calculateDisplayValue: 'transportador.pessoa.cpfCnpj',
            editorType: 'dxLookup',
            editorOptions: {
              allowClearing: true,
              showClearButton: true,
              acceptCustomValue: true,
              dataSource: {
                store: new CustomStore({
                  key: 'cpfCnpj',
                  load: (loadOptions) => {
                    return this.$utils.wrapRequestForGrid(this.find({
                      entity: 'Pessoa',
                      params: {
                        fields: ['id', 'cpfCnpj'],
                        where: {
                          situacao: 'Ativo',
                          tipoPessoa: 'J'
                        }
                      }
                    }))
                  },
                  byKey: async key => {
                    const res = await getByKeys({
                      urlModel: 'Pessoa',
                      params: {
                        where: { cpfCnpj: key }
                      }
                    })
                    return res.totalCount > 0 ? res.data[0] : null
                  }
                }),
                paginate: true,
                pageSize: 5
              },
              displayExpr: 'cpfCnpj',
              valueExpr: 'cpfCnpj'
            }
          },
          {
            dataField: '$residuoPredominante->classeMaterial.id$',
            caption: 'Classe de Material',
            calculateSortValue: 'residuoPredominante.classeMaterial.classe',
            editorType: 'dxLookup',
            editorOptions: {
              allowClearing: true,
              showClearButton: true,
              showCancelButton: false,
              dataSource: {
                store: new CustomStore({
                  key: 'id',
                  load: (loadOptions) => {
                    return this.$utils.wrapRequestForGrid(this.find({
                      entity: 'ClasseMaterial',
                      loadOptions
                    }))
                  },
                  byKey: key => {
                    return this.findId({
                      entity: 'ClasseMaterial',
                      id: key
                    })
                  }
                }),
                paginate: true,
                pageSize: 5
              },
              displayExpr: 'classe',
              valueExpr: 'id'
            },
            calculateDisplayValue: 'residuoPredominante.classeMaterial.classe'
          },
          {
            dataField: '$tipoResiduo.nome$',
            calculateSortValue: 'tipoResiduo.nome',
            caption: 'Material - Tipo de Resíduo',
            visible: false,
            editorType: 'dxLookup',
            editorOptions: {
              maxLength: 100,
              allowClearing: true,
              showClearButton: true,
              showCancelButton: false,
              dataSource: {
                store: new CustomStore({
                  key: 'id',
                  load: (loadOptions) => {
                    return this.$utils.wrapRequestForGrid(this.find({
                      entity: 'TipoResiduo',
                      loadOptions
                    }))
                  },
                  byKey: key => {
                    return this.findId({
                      entity: 'TipoResiduo',
                      id: key
                    })
                  }
                }),
                paginate: true,
                pageSize: 5
              },
              displayExpr: 'nome',
              valueExpr: 'id'
            },
            calculateDisplayValue: 'tipoResiduo.nome'
          },
          {
            dataField: 'idResiduoPredominante',
            caption: 'Resíduo Predominante',
            visible: false,
            label: { text: 'Tipo de Resíduo Predominante' },
            editorType: 'dxLookup',
            editorOptions: {
              maxLength: 100,
              acceptCustomValue: true,
              dataSource: {
                store: new CustomStore({
                  key: 'id',
                  load: async (loadOptions) => this.$utils.wrapRequestForGrid(this.find({
                    entity: 'ObraTipoResiduo',
                    params: {
                      include: JSON.stringify([{
                        model: 'TipoResiduo',
                        as: 'tipoResiduo',
                        required: true,
                        include: [
                          {
                            model: 'ClasseMaterial',
                            as: 'classeMaterial',
                            required: true
                          }]
                      }])
                    },
                    loadOptions
                  })),
                  byKey: key => {
                    return this.findId({
                      entity: 'ObraTipoResiduo',
                      params: {
                        include: JSON.stringify([{
                          model: 'TipoResiduo',
                          as: 'tipoResiduo',
                          required: true,
                          include: [
                            {
                              model: 'ClasseMaterial',
                              as: 'classeMaterial',
                              required: true
                            }]
                        }])
                      },
                      id: key
                    })
                  }
                }),
                paginate: true,
                pageSize: 5
              },
              displayExpr: 'tipoResiduo.nome',
              valueExpr: 'id'
            }
          },
          {
            dataField: '$triagem->triagemResiduo.volumeAproximadoM3$',
            caption: 'Volume Aproximado (m³)',
            calculateSortValue: 'triagem.triagemResiduo.volumeAproximadoM3',
            visible: false,
            dataType: 'number',
            editorType: 'dxNumberBox',
            editorOptions: {
              maxLength: 7,
              min: 0,
              allowClearing: true,
              showClearButton: true,
              showCancelButton: false,
              dataSource: {
                store: new CustomStore({
                  key: 'id',
                  load: (loadOptions) => {
                    return this.$utils.wrapRequestForGrid(this.find({
                      entity: 'TriagemResiduo',
                      loadOptions
                    }))
                  },
                  byKey: key => {
                    return this.findId({
                      entity: 'TriagemResiduo',
                      id: key
                    })
                  }
                }),
                paginate: true,
                pageSize: 5
              },
              displayExpr: 'volumeAproximadoM3',
              valueExpr: 'id'
            },
            calculateDisplayValue: 'triagem.triagemResiduo.volumeAproximadoM3',
            validationRules: [
              { type: 'required' }
            ]
          },
          {
            dataField: '$triagem.observacao$',
            caption: 'Observação',
            calculateSortValue: 'triagem.observacao',
            visible: false,
            editorOptions: {
              maxLength: 500,
              allowClearing: true,
              showClearButton: true,
              showCancelButton: false,
              dataSource: {
                store: new CustomStore({
                  key: 'id',
                  load: (loadOptions) => {
                    return this.$utils.wrapRequestForGrid(this.find({
                      entity: 'Triagem',
                      loadOptions
                    }))
                  },
                  byKey: key => {
                    return this.findId({
                      entity: 'Triagem',
                      id: key
                    })
                  }
                }),
                paginate: true,
                pageSize: 5
              },
              displayExpr: 'observacao',
              valueExpr: 'id'
            },
            calculateDisplayValue: 'triagem.observacao'
          },
          {
            dataField: '$veiculo.placa$',
            caption: 'Placa',
            formItem: {
              visible: false
            },
            editorType: 'dxLookup',
            editorOptions: {
              dataSource: {
                store: new CustomStore({
                  key: 'placa',
                  load: (loadOptions) => this.$utils.wrapRequestForGrid(this.find({
                    entity: 'Veiculo',
                    ...this._currentUserIs('Transportador') ? {
                      params: { where: { idPessoa: this.user.idPessoa } }
                    } : {},
                    loadOptions
                  })),
                  byKey: async (key) => {
                    const result = await this.find({
                      entity: 'Veiculo',
                      id: key,
                      params: {
                        where: {
                          placa: key
                        }
                      }
                    })
                    return result.data[0]
                  }
                }),
                paginate: true,
                pageSize: 5
              },
              displayExpr: 'placa',
              valueExpr: 'placa'
            },
            calculateDisplayValue: 'veiculo.placa'
          },
          {
            dataField: '$cacamba.numero_identificacao$',
            caption: 'Número de identificação da Caçamba',
            formItem: {
              visible: false
            },
            editorType: 'dxLookup',
            editorOptions: {
              dataSource: {
                store: new CustomStore({
                  key: 'numeroIdentificacao',
                  load: (loadOptions) => this.$utils.wrapRequestForGrid(this.find({
                    entity: 'Cacamba',
                    ...this._currentUserIs('Transportador') ? {
                      params: { where: { idPessoa: this.user.idPessoa } }
                    } : {},
                    loadOptions
                  })),
                  byKey: async (key) => {
                    const result = await this.find({
                      entity: 'Cacamba',
                      id: key,
                      params: {
                        where: {
                          numeroIdentificacao: key
                        }
                      }
                    })
                    return result.data[0]
                  }
                }),
                paginate: true,
                pageSize: 5
              },
              displayExpr: 'numeroIdentificacao',
              valueExpr: 'numeroIdentificacao'
            },
            calculateDisplayValue: (data) => {
              if (data.cacamba) {
                return data.cacamba.numeroIdentificacao
              } else {
                return ''
              }
            },
            xlsxObjPath: 'cacamba.numeroIdentificacao'
          },
          {
            dataField: 'idPerfil',
            calculateSortValue: 'destinoFinal.pessoa.nomeRazaoSocial',
            caption: 'Destino Final',
            editorType: 'dxLookup',
            editorOptions: {
              dataSource: {
                store: new CustomStore({
                  key: 'id',
                  load: (loadOptions) => this.$utils.wrapRequestForGrid(this.find({
                    entity: 'ViewDestinoFinal',
                    ...this._currentUserIs('Destino Final') ? {
                      params: { where: { idPessoa: this.user.idPessoa } }
                    } : {},
                    loadOptions
                  })),
                  byKey: key => {
                    return this.findId({
                      entity: 'ViewDestinoFinal',
                      id: key
                    })
                  }
                }),
                paginate: true,
                pageSize: 5
              },
              displayExpr: 'nomeRazaoSocial',
              valueExpr: 'id'
            },
            calculateDisplayValue: 'destinoFinal.pessoa.nomeRazaoSocial'
          },
          {
            dataField: 'situacao',
            calculateDisplayValue: 'situacao',
            caption: 'Situação',
            formItem: {
              visible: false
            },
            editorType: 'dxLookup',
            editorOptions: {
              searchEnabled: false,
              showCancelButton: false,
              dataSource: {
                store: new CustomStore({
                  key: 'distinctSituacao',
                  load: (loadOptions) => {
                    return this.$utils.wrapRequestForGrid(this.find({
                      entity: 'SolicitacaoTransporte',
                      params: {
                        attributes: [{ fn: 'DISTINCT', col: 'situacao', as: 'distinctSituacao' }],
                        subQuery: false
                      },
                      loadOptions
                    }))
                  },
                  byKey: async (key) => {
                    const res = await this.find({
                      entity: 'SolicitacaoTransporte',
                      params: {
                        attributes: [{ fn: 'DISTINCT', col: 'situacao', as: 'distinctSituacao' }],
                        subQuery: false,
                        where: {
                          situacao: key
                        }
                      },
                      rowsOnly: true
                    })

                    return res.length > 0 ? res[0] : null
                  }
                }),
                paginate: true,
                pageSize: 5
              },
              displayExpr: 'distinctSituacao',
              valueExpr: 'distinctSituacao'
            }
          },
          {
            type: 'buttons',
            showInColumnChooser: false,
            buttons: [
              {
                name: 'viewPdf',
                cssClass: 'dx-button mdi mdi-file-document',
                text: 'CTR',
                onClick: data => {
                  window.open(`${config.baseUrl}/FichaCTR/${data.row.key}`)
                }
              },
              {
                name: 'edit',
                text: 'Triagem',
                cssClass: 'dx-button dx-button-success dx-button-mode-contained mdi mdi-pencil',
                onClick: (data) => {
                  this.$router.push({ path: `/controle-residuos-triados/edit/${data.row.key}` })
                },
                visible: (result) => {
                  const { row: { data } } = result
                  return ['Destinada'].includes(data.situacao)
                }
              }
            ]
          }
        ]),
        dataSource: new CustomStore({
          key: 'id',
          load: async (loadOptions) => {
            this.currentFilter = loadOptions.filter ? loadOptions.filter : []
            this.solicitacaoValues = await this.$utils.wrapRequestForGrid(this.find({
              entity: this.entity,
              params: {
                fields: ['id', 'dataHoraSolicitacao', 'situacao'],
                include: [
                  {
                    model: 'Perfil',
                    as: 'destinoFinal',
                    required: false,
                    attributes: ['id'],
                    include: [{ model: 'Pessoa', as: 'pessoa', attributes: ['id', 'nomeRazaoSocial', 'cpfCnpj'] }]
                  },
                  {
                    model: 'Perfil',
                    as: 'transportador',
                    attributes: ['id'],
                    required: true,
                    include: [{ model: 'Pessoa', as: 'pessoa', attributes: ['id', 'nomeRazaoSocial', 'cpfCnpj'] }]
                  },
                  {
                    model: 'TipoResiduo',
                    as: 'residuoPredominante',
                    attributes: ['id', 'nome'],
                    include: [
                      {
                        model: 'ClasseMaterial',
                        as: 'classeMaterial',
                        attributes: ['id', 'descricao', 'classe']
                      }
                    ]
                  },
                  {
                    model: 'Triagem',
                    as: 'triagem',
                    include: [
                      {
                        model: 'TriagemResiduo',
                        as: 'triagemResiduo'
                      }
                    ]
                  },
                  {
                    model: 'Veiculo',
                    as: 'veiculo',
                    attributes: ['id', 'placa', 'numeroIdentificacao']
                  },
                  {
                    model: 'Cacamba',
                    as: 'cacamba',
                    attributes: ['id', 'numeroIdentificacao']
                  }
                ],
                where: {
                  ...this._currentUserIs('Destino Final') ? {
                    idDestinoFinal: this.user.idPessoa
                  } : {},
                  ...this._currentUserIs('Transportador') ? {
                    idTransportador: this.user.idPessoa
                  } : {}
                }
              },
              loadOptions
            }))
            return this.solicitacaoValues
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dx-field-value-static, .dx-field-value:not(.dx-switch):not(.dx-checkbox):not(.dx-button) {
  margin-bottom: 10px;
}

.dx-field-label {
  margin-bottom: 10px;
}
</style>
