import Login from './login'
import Crud from './crud'
import Layout from '../layout/store'

export default {
  modules: {
    Login,
    Crud,
    Layout
  }
}
