import ConsultaResiduoRegiao from './ConsultaResiduoRegiao.vue'

export default {
  path: '/consulta-residuo-regiao',
  name: 'Consultar volume de resíduos enviados por região para o destino final',
  component: ConsultaResiduoRegiao,
  meta: {
    icon: 'mdi mdi-select-place',
    title: 'Consultar Volume de Resíduos por Região',
    requiresAuth: true,
    permissionType: 'consulta'
  },
  props: true
}
