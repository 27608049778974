<template>
  <div>
    <fields-veiculo-cacamba-solicitacao
      :can-edit-veiculo="false"
      :can-edit-cacamba="false"
      :value="value"
      @input="onFieldsVeiculoCacambaInput"/>

    <grid-residuos
      read-only
      :value="value"
      @input="onGridResiduosInput"/>

    <text-area-field
      class="flex-col-6"
      label="Observações"
      :options="observacoesFieldOptions"
      :value="value.observacao"
      @input="onFieldObservacaoInput"/>
  </div>
</template>
<script>
import FieldsVeiculoCacambaSolicitacao from '../../../components/SolicitacaoTransporte/FieldsVeiculoCacambaSolicitacao'
import GridResiduos from '../../../components/SolicitacaoTransporte/GridResiduos'
import TextAreaField from '../../../components/Form/TextAreaField'

export default {
  name: 'form-rejeitar-recebimento',
  components: {
    FieldsVeiculoCacambaSolicitacao,
    GridResiduos,
    TextAreaField
  },
  props: {
    /**
     * Os dados da solicitacao para o form de rejeitar.
     */
    value: {
      type: Object
    }
  },
  /**
   * Metodo do vue, para obter o estado inicial do componente.
   * @returns {Object} O estado inicial do componente.
   */
  data () {
    return {
      observacoesFieldOptions: {
        maxLength: 500
      }
    }
  },
  methods: {
    /**
     * Metodo de callback de quando foi alterado o valor dos campos de veiculo e
     * cacamba, com o objetivo de emitir o evento de atualizar os dados da
     * solicitacao.
     * @param {Object} e - O novo valor da solicitacao.
     */
    onFieldsVeiculoCacambaInput (e) {
      this.$emit('input', e)
    },

    /**
     * Metodo de callback de quando foi alterado o valor dos campos de residuos,
     * com o objetivo de emitir o evento de atualizar os dados da solicitacao.
     * @param {Object} e - O novo valor da solicitacao.
     */
    onGridResiduosInput (e) {
      this.$emit('input', e)
    },

    /**
     * Metodo de callback de quando foi alterado o valor do campo de observacao,
     * com o objetivo de emitir o evento de atualizar os dados da solicitacao.
     * @param {Object} e - O novo valor da observacao.
     */
    onFieldObservacaoInput (e) {
      this.$emit('input', {
        ...this.value,
        observacao: e
      })
    }
  }
}
</script>

<style lang="scss">
</style>
