<template>
  <div class="generic-crud">
    <div class="md-layout">
      <div class="md-layout-item">
        <h2 class="crud-title">Localizar CTR</h2>
      </div>
      <div class="md-layout-item" style="text-align: right;">
        <dx-button icon="pdffile" type="default" styling-mode="contained" @click="pdfAll" text="Gerar PDF de todos os CTRs"></dx-button>
      </div>
    </div>

    <popup-update-solicitacao
      :active.sync="popupUpdateVisible"
      :solicitacao="solicitacaoPopup"
      title="Alterar solicitação de transporte"
      :show-only-cacamba="popupUpdateCacamba"
      :editable="true"
      @updated-solicitacao="onPopupUpdatedSolicitacao"/>

    <popup-cancel-solicitacao
      :active.sync="popupCancelVisible"
      :solicitacao="solicitacaoPopup"
      title="Cancelar solicitação de transporte"
      @updated-solicitacao="onPopupUpdatedSolicitacao"/>

    <popup-avaliar-solicitacao
      :active.sync="popupAvaliarSolicitacaoVisible"
      :action="popupAvaliarSolicitacaoAction"
      :solicitacao="solicitacaoPopup"
      @updated-solicitacao="onPopupUpdatedSolicitacao"/>

    <popup-alterar-transportador
      :active.sync="popupAlterarTransportadorVisible"
      :solicitacao="solicitacaoPopup"
      title="Alterar Transportador"
      @updated-solicitacao="onPopupUpdatedSolicitacao"/>

    <simple-grid entity="SolicitacaoTransporte" ref="simpleGrid" :editable="false" :deletable="false" edit-path="localizar-ctr" :grid-data="gridData"></simple-grid>
  </div>
</template>
<script>
import config from 'config'
import { DxButton } from 'devextreme-vue'
import CustomStore from 'devextreme/data/custom_store'
import { mapGetters, mapState } from 'vuex'
import { confirm } from 'devextreme/ui/dialog'

import { SimpleGrid } from '@/components'
import mixinCrud from '../mixin-crud'
import PopupUpdateSolicitacao from '@/components/SolicitacaoTransporte/PopupUpdateSolicitacao'
import PopupCancelSolicitacao from '@/components/SolicitacaoTransporte/PopupCancelSolicitacao'
import PopupAvaliarSolicitacao from '@/components/SolicitacaoTransporte/PopupAvaliarSolicitacao'
import PopupAlterarTransportador from '@/components/SolicitacaoTransporte/PopupAlterarTransportador'
import { doRequest } from '@/pluggables/http'
import { hasPermission } from '@/util/permissions'
import { visibleEditButton } from '@/helper/general'

export default {
  name: 'localizar-ctr',
  mixins: [
    mixinCrud
  ],
  components: {
    PopupCancelSolicitacao,
    PopupAvaliarSolicitacao,
    PopupUpdateSolicitacao,
    PopupAlterarTransportador,
    SimpleGrid,
    DxButton
  },
  computed: {
    ...mapState('Login', ['user']),
    ...mapGetters('Login', { userData: 'userData' })
  },
  data () {
    return {
      entity: 'SolicitacaoTransporte',
      formData: null,
      popupAvaliarSolicitacaoAction: 'accept',
      popupAvaliarSolicitacaoVisible: false,
      popupAlterarTransportadorVisible: false,
      popupCancelVisible: false,
      popupUpdateVisible: false,
      popupUpdateCacamba: false,
      solicitacaoPopup: null,
      currentFilter: [],
      gridData: {
        value: {
          visible: true
        },
        columns: this.parseColumns([
          {
            dataField: 'id',
            caption: 'Nº do CTR',
            dataType: 'number',
            sortIndex: '0',
            sortOrder: 'desc',
            cellTemplate: (template, data) => {
              template.innerHTML = `<a href="${config.baseUrl}/FichaCTR/${data.data.id}" target="_blank">${data.data.id}</a>`
            }
          },
          {
            dataField: '$cacamba.numero_identificacao$',
            calculateDisplayValue: 'cacamba.numeroIdentificacao',
            calculateSortValue: 'cacamba.numeroIdentificacao',
            caption: 'Caçamba',
            dataType: 'string'
          },
          {
            dataField: '$veiculo.placa$',
            calculateDisplayValue: 'veiculo.placa',
            calculateSortValue: 'veiculo.placa',
            caption: 'Placa do veículo',
            dataType: 'string'
          },
          {
            dataField: '$veiculo.tipo$',
            calculateDisplayValue: 'veiculo.tipo',
            calculateSortValue: 'veiculo.tipo',
            caption: 'Tipo do veículo',
            dataType: 'string'
          },
          {
            dataField: '$obra.nome_obra$',
            dataType: 'string',
            caption: 'Nome da Obra',
            calculateDisplayValue: 'obra.nomeObra',
            calculateSortValue: 'obra.nomeObra'
          },
          {
            dataField: '$obra.endereco$',
            caption: 'Endereço',
            dataType: 'string',
            calculateDisplayValue: 'obra.endereco',
            calculateSortValue: 'obra.endereco'
          },
          {
            dataField: '$obra.cep$',
            caption: 'Cep',
            dataType: 'string',
            calculateDisplayValue: 'obra.cep',
            calculateSortValue: 'obra.cep'
          },
          {
            dataField: '$obra.bairro$',
            caption: 'Bairro',
            dataType: 'string',
            calculateDisplayValue: 'obra.bairro',
            calculateSortValue: 'obra.bairro'
          },
          {
            dataField: '$transportador.id$',
            calculateSortValue: 'transportador.pessoa.nomeRazaoSocial',
            caption: 'Transportador',
            editorType: 'dxLookup',
            editorOptions: {
              dataSource: new CustomStore({
                key: 'id',
                load: (loadOptions) => this.$utils.wrapRequestForGrid(this.find({
                  entity: 'ViewTransportador',
                  loadOptions
                })),
                byKey: key => {
                  return this.findId({
                    entity: 'ViewTransportador',
                    id: key
                  })
                }
              }),
              displayExpr: 'nomeRazaoSocial',
              valueExpr: 'id'
            },
            calculateDisplayValue: 'transportador.pessoa.nomeRazaoSocial',
            validationRules: [{
              type: 'required'
            }]
          },
          {
            dataField: '$residuoPredominante.classeMaterial.id$',
            calculateFilterExpression: (classeMaterial, operation) => {
              return ['$residuoPredominante->classeMaterial.id$', operation, classeMaterial]
            },
            xlsxObjPath: '{residuoPredominante.classeMaterial.classe}({residuoPredominante.nome})',
            caption: 'Classe de Resíduo / Tipo de Resíduo Predominante',
            calculateDisplayValue (data) {
              return `${data.residuoPredominante.classeMaterial.classe} (${data.residuoPredominante.nome})`
            },
            editorType: 'dxLookup',
            editorOptions: {
              allowClearing: true,
              showClearButton: true,
              showCancelButton: false,
              displayExpr: (data) => {
                if (data && data.classeMaterial) return `${data.classeMaterial.classe} (${data.nome})`
                else return ''
              },
              valueExpr: 'classeMaterial.id',
              dataSource: {
                key: 'id',
                store: new CustomStore({
                  load: async () => {
                    this.showProgressBarLoading = true
                    const result = await doRequest({
                      method: 'post',
                      url: 'TipoResiduo/find',
                      params: {
                        include: [
                          {
                            model: 'ClasseMaterial',
                            as: 'classeMaterial',
                            required: true,
                            attributes: ['id', 'classe']
                          }
                        ],
                        subQuery: false
                      }
                    })
                    this.showProgressBarLoading = false
                    return result.data.rows
                  },
                  byKey: key => key
                }),
                paginate: true,
                pageSize: 10,
                reshapeOnPush: true
              }
            }
          },
          {
            dataField: 'volumeEstimadoM3',
            caption: 'Volume estimado a ser calculado',
            dataType: 'number',
            editorType: 'dxNumberBox'
          },
          {
            dataField: '$destinoFinal.id$',
            calculateSortValue: 'destinoFinal.pessoa.nomeRazaoSocial',
            caption: 'Destino Final',
            editorType: 'dxLookup',
            editorOptions: {
              dataSource: new CustomStore({
                key: 'id',
                load: (loadOptions) => this.$utils.wrapRequestForGrid(this.find(
                  {
                    entity: 'ViewDestinoFinal',
                    loadOptions
                  }
                )),
                byKey: key => {
                  return this.findId({
                    entity: 'ViewDestinoFinal',
                    id: key
                  })
                }
              }),
              displayExpr: 'nomeRazaoSocial',
              valueExpr: 'id'
            },
            calculateDisplayValue: 'destinoFinal.pessoa.nomeRazaoSocial',
            validationRules: [{ type: 'required' }]
          },
          {
            dataField: 'situacao',
            caption: 'Situação',
            lookup: {
              allowClearing: true,
              dataSource: ['Alocada', 'Cancelada', 'Cancelamento Recusado', 'Cancelamento Solicitado', 'Em Pesagem', 'Enviada', 'Destinada', 'Recusada', 'Resíduo Rejeitado', 'Retirada', 'Solicitada']
            }
          },
          {
            dataField: 'dataHoraSolicitacao',
            caption: 'Data/Hora da Solicitação',
            format: 'dd/MM/yyyy - HH:mm',
            dataType: 'date',
            editorType: 'dxDateBox'
          },
          {
            type: 'buttons',
            showInColumnChooser: false,
            buttons: [
              {
                name: 'viewPdf',
                cssClass: 'dx-button mdi mdi-file-document',
                text: 'CTR',
                onClick: data => {
                  window.open(`${config.baseUrl}/FichaCTR/${data.row.key}`)
                }
              },
              ...(hasPermission('Solicitar Cancelamento de CTR', 'altera') ? [{
                name: 'solicitarCancelamento',
                text: 'Solic. Cancelamento',
                cssClass: 'dx-button mdi mdi-close-thick',
                hint: 'Solicitar cancelamento da CTR',
                visible: ({ row: { data } }) => {
                  // RN17.7
                  const perfisTransportadores = this.userData.perfis.filter(perfil => [2, 9].includes(perfil.idTipoPerfil))
                  return ['Solicitada', 'Enviada', 'Alocada', 'Retirada']
                    .includes(data.situacao) && perfisTransportadores.some(({ id }) => id === data.idTransportador)
                },
                onClick: data => {
                  this.solicitacaoPopup = data.row.data
                  this.popupCancelVisible = true
                }
              }] : []),
              ...(hasPermission('Alocar Caçamba', 'altera') ? [{
                text: 'alocar',
                cssClass: 'dx-button mdi mdi-truck-delivery',
                onClick: (result) => {
                  const { row: { data } } = result
                  this.$router.push(`/alocar-cacamba/edit/${data.id}`)
                },
                visible: (result) => {
                  const { row: { data } } = result
                  return ['Enviada'].includes(data.situacao)
                }
              }] : []),
              ...(hasPermission('Avaliar Solicitação de Transporte', 'altera') ? [{
                name: 'aceitar',
                text: 'Aceitar',
                cssClass: 'dx-button mdi mdi-thumb-up',
                hint: 'Aceitar Solicitação de Transporte',
                visible: ({ row }) => {
                  // RN17.7
                  return ['Solicitada'].includes(row.data.situacao)
                },
                onClick: data => {
                  this.popupAvaliarSolicitacaoAction = 'accept'
                  this.popupAvaliarSolicitacaoVisible = true
                  this.solicitacaoPopup = data.row.data
                }
              },
              {
                name: 'recusar',
                text: 'Recusar',
                cssClass: 'dx-button mdi mdi-thumb-down',
                visible: ({ row }) => {
                  // RN17.7
                  return ['Solicitada'].includes(row.data.situacao)
                },
                onClick: data => {
                  this.popupAvaliarSolicitacaoAction = 'decline'
                  this.popupAvaliarSolicitacaoVisible = true
                  this.solicitacaoPopup = data.row.data
                }
              }] : []),
              ...(hasPermission('Retirar Caçamba', 'altera') ? [{
                name: 'retirar',
                text: 'Retirar',
                cssClass: 'dx-button mdi mdi-dump-truck',
                visible: ({ row }) => {
                  // RN17.7
                  return ['Alocada'].includes(row.data.situacao)
                },
                onClick: ({ row: { data } }) => {
                  this.$router.push({ path: `/retirar-cacamba/retirar/${data.id}` })
                  // this.solicitacaoPopup = data.row.data
                  // this.popupUpdateCacamba = true
                  // this.popupUpdateVisible = true
                }
              }] : []),
              ...(hasPermission('Analisar Solicitação de Cancelamento de CTR', 'altera') ? [{
                name: 'analisar',
                text: 'Analisar Solicitação de Cancelamento',
                cssClass: 'dx-button mdi mdi-eye',
                onClick: (result) => {
                  const { row: { data } } = result
                  this.$router.push(`/cancelamento-ctr/edit/${data.id}`)
                },
                visible: (result) => {
                  const { row: { data } } = result
                  return ['Cancelamento Solicitado'].includes(data.situacao)
                }
              }] : []),
              ...(hasPermission('Prosseguir com CTR', 'altera') ? [{
                name: 'prosseguir',
                text: 'Prosseguir com CTR',
                cssClass: 'dx-button mdi mdi-arrow-right',
                onClick: async (data) => {
                  // const confirmed = await this.$utils.showConfirm('Deseja prosseguir com CTR?')
                  // if (confirmed) {
                  await this.$http.put(`SolicitacaoTransporteProsseguir/${data.row.data.id}`)
                  this.$refs.simpleGrid.$refs.gridInstance.instance.getDataSource().reload()
                  // }
                },
                visible: (result) => {
                  const { row: { data } } = result
                  return ['Cancelamento Recusado'].includes(data.situacao)
                }
              }] : []),
              ...(hasPermission('Corrigir Dados da CTR', 'altera') ? [{
                name: 'corrigir',
                text: 'Corrigir Dados',
                cssClass: 'dx-button mdi mdi-lead-pencil',
                onClick: data => {
                  this.solicitacaoPopup = data.row.data
                  this.popupUpdateCacamba = false
                  this.popupUpdateVisible = true
                },
                visible: (result) => {
                  const { row: { data } } = result
                  return ['Resíduo Rejeitado'].includes(data.situacao)
                }
              }] : []),
              ...(hasPermission('Alterar Transportador', 'altera') ? [{
                name: 'corrigir',
                text: 'Alterar Transportador',
                cssClass: 'dx-button mdi mdi-account-switch',
                onClick: data => {
                  this.solicitacaoPopup = data.row.data
                  this.popupAlterarTransportadorVisible = true
                },
                visible: (result) => {
                  const { row: { data } } = result
                  return ['Recusada'].includes(data.situacao)
                }
              }] : []),
              ...(hasPermission('Editar CTR', 'altera') ? [{
                name: 'editarSolicitacaoTransporte',
                text: 'Editar CTR',
                cssClass: 'dx-button mdi mdi-pencil',
                hint: 'Editar CTR',
                visible: ({ row: { data } }) => {
                  return visibleEditButton(data)
                },
                onClick: async (data) => {
                  const isAdmOrSecretaria = await this.userData.perfis.filter(perfil => [4, 10].includes(perfil.idTipoPerfil))
                  const confirmed = isAdmOrSecretaria && isAdmOrSecretaria.length ? true : await confirm(`Este CTR só poderá ser modificado 1 (uma) vez por meio do seu cadastro.
                    <br>Caso efetue quaisquer alteração de forma errônea, informe o número do CTR por meio do dúvidas ou sugestões, <br> qual dado deverá ser modificado que os servidores do SLU efetuarão a modificação por mais 1 (uma) vez.
                    <br> Deseja Continuar?`, 'Atenção')
                  if (confirmed) {
                    await this.$router.push(`/editar-solicitacao-transporte/${data.row.data.id}`)
                  }
                }
              }] : [])
            ]
          }
        ]),
        dataSource: new CustomStore({
          key: 'id',
          load: (loadOptions) => {
            const currentFilter = loadOptions.filter ? loadOptions.filter : []

            return this.$utils.wrapRequestForGrid(this.find({
              entity: this.entity,
              params: {
                subQuery: false,
                attributes: ['id', 'dataHoraSolicitacao', 'idDestinoFinal', 'idGerador', 'idTransportador', 'situacao', 'volumeEstimadoM3', 'editado'],
                include: JSON.stringify([
                  {
                    model: 'Veiculo',
                    as: 'veiculo',
                    required: currentFilter.find(e => String(e).indexOf('veiculo.') > -1),
                    attributes: ['id', 'placa', 'tipo']
                  },
                  {
                    model: 'Cacamba',
                    as: 'cacamba',
                    required: currentFilter.find(e => String(e).indexOf('cacamba.') > -1),
                    attributes: ['numeroIdentificacao', 'id']
                  },
                  {
                    model: 'Perfil',
                    as: 'destinoFinal',
                    required: currentFilter.find(e => String(e).indexOf('destinoFinal.') > -1),
                    attributes: ['id'],
                    include: [{
                      model: 'Pessoa',
                      as: 'pessoa',
                      attributes: ['id', 'nomeRazaoSocial']
                    }]
                  },
                  {
                    model: 'Perfil',
                    as: 'transportador',
                    attributes: ['id'],
                    required: true,
                    include: [{
                      model: 'Pessoa',
                      as: 'pessoa',
                      attributes: ['id', 'nomeRazaoSocial']
                    }]
                  },
                  {
                    required: true,
                    model: 'Obra',
                    as: 'obra',
                    attributes: ['id', 'nomeObra', 'endereco', 'cep', 'bairro']
                  },
                  {
                    model: 'TipoResiduo',
                    as: 'residuoPredominante',
                    required: true,
                    include: [{
                      model: 'ClasseMaterial',
                      as: 'classeMaterial',
                      attributes: ['id', 'classe']
                    }]
                  }
                ])
              },
              loadOptions
            }))
          },
          insert: (values) => this.insertRow({ entity: this.entity, values }),
          update: (key, values) => this.updateRow({ entity: this.entity, values, key }),
          remove: (key, values) => this.removeRow({ entity: this.entity, key })
        })
      }
    }
  },
  methods: {
    /**
     * Metodo de callback de quando um popup altera valores nas justificativas.
     * Com o objetivo de recarregar a lista de solicitacoes.
     */
    onPopupUpdatedSolicitacao () {
      this.$refs.simpleGrid.$refs.gridInstance.instance.getDataSource().reload()
    },
    pdfAll () {
      const keys = this.$refs.simpleGrid.$refs.gridInstance.instance.getVisibleRows().map(v => v.key).join(',')
      window.open(`${config.baseUrl}/FichaCTR/${keys}`)
    }
  }
}
</script>
