export default {
  updateUser (state, { user }) {
    for (const key in user) {
      if (user[key]) {
        state.user[key] = user[key]
      }
    }
  },
  updateUserPessoa (state, { pessoa }) {
    for (const key in pessoa) {
      if (pessoa[key]) {
        state.user.pessoa[key] = pessoa[key]
      }
    }
  },
  auth_request (state) {
    state.status = 'loading'
  },
  auth_success (state, { token = localStorage.getItem('userToken'), user }) {
    state.status = 'success'
    state.token = token
    state.user = user
  },
  auth_error (state) {
    state.status = 'error'
    state.token = null
    state.user = null
  },
  logout (state) {
    state.status = ''
    state.token = null
    state.user = null
  }
}
