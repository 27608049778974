<template>
  <div class="model-file-uploader">
    <div :class="{ 'dx-field': !readOnly }">
      <div v-if="label" class="dx-field-label-location-top" style="font-weight: bold">{{ label }}</div>
      <div style="display: flex">
        <div v-if="!readOnly" class="dx-field-content">
          <dx-file-uploader
            ref="fileUploaderRef"
            :selectButtonText="buttonLabel"
            :labelText="dragAndDropLabel"
            :accept="accept"
            :allow-canceling="true"
            upload-mode="useForm"
            @content-ready="onContentReady"
            :value.sync="innerValue"/>
        </div>
        <div ref="customButtonSlot">
          <slot name="customButtons" />
        </div>
      </div>
    </div>
    <div v-if="innerValue.length" class="flex">
      <div v-if="showRemoveButton" class="flex-1">{{ value.name }}</div>
<!--      <a :href="downloadUrl" target="_blank">
        <dx-button type="normal">Download</dx-button>
      </a>-->

      <div v-if="showRemoveButton && !readOnly">
        <dx-button type="danger" @click="_onClickRemove">
          Remover
        </dx-button>
      </div>
    </div>
  </div>
</template>

<script>
import DxFileUploader from 'devextreme-vue/file-uploader'
import DxButton from 'devextreme-vue/button'

import { http } from '@/pluggables/http'
import config from 'config'

export default {
  name: 'model-file-uploader',
  components: {
    DxFileUploader,
    DxButton
  },
  props: {
    /**
     * Label de drag&drop
     */
    dragAndDropLabel: {
      type: String,
      default: 'Ou Soltar arquivo aqui'
    },
    /**
     * A label do botão de selecionar um anexo
     */
    buttonLabel: {
      type: String,
      default: 'Selecionar Arquivo'
    },
    /**
     * A label do campo
     */
    label: {
      type: String
    },
    /**
     * A entidade que tem o arquivo.
     */
    entity: {
      type: String
    },
    /**
     * O atributo do arquivo na entidade.
     */
    attribute: {
      type: String
    },
    /**
     * O Id da entidade que sera buscado o arquivo.
     */
    id: {
      type: [String, Number, null, undefined],
      default: null
    },
    /**
     * O nome do arquivo.
     */
    fileName: {
      type: String
    },
    /**
     * Metodo para verificar se há algum arquivo na entidade.
     */
    existsFile: {
      type: Function,
      async default () {
        if (this.getDefaultImage) {
          const result = await http.get(`${config.baseUrl}/${this.entity}/${this.id}/${this.attribute}`, {
            params: {
              format: 'count'
            }
          })
          if (result.status !== 200) {
            throw new Error(`Falha no retorno da requisicao de get file de ${this.entity}`)
          } else {
            return result.data.value
          }
        } else {
          return () => ({})
        }
      }
    },
    /**
     * A lista com o arquivo escolhido. Caso o id for null esse valor é iniciado
     * com uma lista vazia, caso não for null, e feita utilizado o metodo de
     * verificar se existe o arquivo e caso nao exista e iniciado com uma list
     *  vazia tambem.
     */
    value: {
      type: [Array, Object, File]
    },
    /**
     * Os tipos de arquivos aceitos.
     */
    accept: {
      type: String
    },

    /**
     * Se os campos sao somente para leitura.
     */
    readOnly: {
      type: Boolean,
      default: false
    },

    showRemoveButton: {
      type: Boolean,
      default: true
    },
    getDefaultImage: {
      type: Boolean,
      default: true
    },
    icon: {
      type: String,
      default: ''
    },
    buttonId: {
      type: String,
      default: ''
    }
  },
  computed: {
    /**
     * Valor computado do valor do campo de arquivo.
     */
    innerValue: {
      get () {
        return this.value
          ? Array.isArray(this.value) ? this.value : [this.value]
          : []
      },
      set (value) {
        this.$emit('input', Array.isArray(this.value) ? value : value[0])
      }
    },
    /**
     * Valor computado da url de download do arquivo.
     */
    downloadUrl () {
      return this.id
        ? `${config.baseUrl}/${this.entity}/${this.id}/${this.attribute}?format=file&fileName=${this.fileName}`
        : window.URL.createObjectURL(this.innerValue[0])
    }
  },
  /**
   * Metodo do ciclo de vida do vue de quando o componente foi montado na tela.
   * Com o objetivo de verificar se tem arquivo na entidade e inicializar o
   * campo de valor.
   */
  async mounted () {
    try {
      if (this.id === null || !(await this.existsFile())) {
        this.innerValue = []
      }
    } catch (e) {
      console.error(e)
      this.innerValue = []
    }
    if (this.$refs.fileUploaderRef) {
      this.$refs.fileUploaderRef.$el.querySelector('.dx-fileuploader-button').parentElement.insertBefore(
        this.$refs.customButtonSlot,
        this.$refs.fileUploaderRef.$el.querySelector('.dx-fileuploader-button')
      )
      this.$refs.fileUploaderRef.$el.querySelector('.dx-fileuploader-button').style.marginLeft = '20px'
    }
  },
  methods: {
    /**
     * Metodo de callback de quando e clicado no botao de remover o arquivo. Com
     * o objetivo de remover o arquivo.
     */
    _onClickRemove () {
      this.innerValue = []
      this.$emit('onRemove')
    },
    onContentReady (component) {
      if (this.icon) {
        component.component._selectButton.element().onmouseenter = () => {
          this.$emit('mouseenter')
        }
        component.component._selectButton.element().onmouseleave = () => {
          this.$emit('mouseleave')
        }
        component.component._selectButton.option('icon', this.icon)
        component.component._selectButton.option('min-width', '45px')
        // component.component._selectButton.element().style.marginLeft = '40%'
        component.component._selectButton.repaint()
      }
      if (this.buttonId) {
        component.component._selectButton.element().id = this.buttonId
      }
    }
  }
}
</script>

<style lang="scss">
$flex-values: 1, 2, 3, 4, 5;

.model-file-uploader {
  font-family: 'Helvetica Neue','Segoe UI',Helvetica,Verdana,sans-serif;
  font-weight: 400;

  div.dx-fileuploader-files-container {
    display: none;
  }

  .flex > a {
    padding: 8px;
  }
}
</style>
