<template>
  <div style="max-width: 800px">
    <h2>Parâmetros do sistema</h2>

    <dx-form :items="columns" col-count="1" label-location="top" ref="settingsForm" :form-data="formData"></dx-form>
    <dx-button
      v-if="!readOnly"
      type="success"
      @click="saveItem">
      Salvar
    </dx-button>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { DxForm, DxButton } from 'devextreme-vue'
import _ from 'lodash'

import { hasPermission } from '../util/permissions'

export default {
  name: 'configuracoes',
  components: {
    DxForm,
    DxButton
  },
  data () {
    const readOnly = !hasPermission('Configurações do Sistema', 'altera')

    return {
      hasRow: false,
      formData: null,
      id: null,
      columns: [
        {
          caption: 'Servidor',
          colCount: 3,
          itemType: 'group',
          items: [
            {
              dataField: 'servidorSmtp',
              label: { text: 'Servidor SMTP' },
              editorOptions: {
                maxLength: 255,
                readOnly
              },
              validationRules: [{ type: 'required' }]
            },
            {
              dataField: 'portaSmtp',
              label: { text: 'Porta SMTP' },
              validationRules: [{ type: 'required' }],
              editorType: 'dxSelectBox',
              editorOptions: {
                items: [
                  587,
                  465,
                  25,
                  2525
                ],
                readOnly
              }
            },
            {
              dataField: 'criptografiaSmtp',
              label: { text: 'Criptografia SMTP' },
              validationRules: [{ type: 'required' }],
              editorType: 'dxSelectBox',
              editorOptions: {
                items: [
                  'Nenhuma',
                  'SSL/TLS'
                ],
                readOnly
              }
            },
            {
              dataField: 'loginSmtp',
              label: { text: 'Login SMTP' },
              editorOptions: {
                maxLength: 80,
                readOnly
              },
              validationRules: [{ type: 'required' }, { type: 'email' }]
            },
            {
              dataField: 'passSmtp',
              label: { text: 'Senha SMTP' },
              editorOptions: {
                maxLength: 80,
                mode: 'password',
                readOnly
              },
              validationRules: [{ type: 'required' }]
            },
            {
              dataField: 'emailEnvioSmtp',
              label: { text: 'Email de envio' },
              editorOptions: {
                maxLength: 150,
                readOnly
              },
              validationRules: [{ type: 'required' }, { type: 'email' }]
            }
          ]
        },
        {
          caption: 'Tempo de expiração de caçambas - Sem Contar Sábados e Domingos',
          colCount: 2,
          itemType: 'group',
          items: [
            {
              dataField: 'qtdDiasLogradouros',
              editorType: 'dxNumberBox',
              editorOptions: {
                readOnly
              },
              label: { text: 'Qtd. de dias de expiração' },
              validationRules: [{ type: 'required' }]
            },
            {
              dataField: 'qtdHorasTransporteCacamba',
              editorType: 'dxNumberBox',
              editorOptions: {
                readOnly
              },
              label: { text: 'Qtd. de horas para transporte de cacamba' },
              validationRules: [{ type: 'required' }]
            },
            {
              dataField: 'qtdHorasTransporteRcc',
              editorType: 'dxNumberBox',
              editorOptions: {
                maxLength: 150,
                readOnly
              },
              label: { text: 'Qtd. de horas para transporte de RCC em caminhão basculante' },
              validationRules: [{ type: 'required' }]
            }
          ]
        },
        {
          caption: 'Envio de email para o SLU',
          colCount: 2,
          itemType: 'group',
          items: [
            {
              dataField: 'emailDuvidas',
              label: { text: 'Dúvidas' },
              editorOptions: {
                maxLength: 150,
                readOnly
              },
              validationRules: [{ type: 'required' }, { type: 'email' }]
            },
            {
              dataField: 'emailSugestoes',
              label: { text: 'Sugestões' },
              editorOptions: {
                maxLength: 150,
                readOnly
              },
              validationRules: [{ type: 'required' }, { type: 'email' }]
            },
            {
              dataField: 'emailReclamacoes',
              label: { text: 'Reclamações' },
              editorOptions: {
                maxLength: 150,
                readOnly
              },
              validationRules: [{ type: 'required' }, { type: 'email' }]
            }
          ]
        }
      ],
      readOnly
    }
  },
  methods: {
    ...mapActions('Crud', ['find', 'insertRow', 'updateRow', 'setLoading']),
    async saveItem () {
      try {
        const formData = this.$refs.settingsForm.formData
        const { isValid } = this.$refs.settingsForm.instance.validate()
        if (isValid === false) {
          return
        }

        if (!this.hasRow) {
          await this.insertRow({ entity: 'parametro', values: _.cloneDeep(formData) })
        } else {
          formData.id = this.id
          await this.updateRow({ entity: 'parametro', values: _.cloneDeep(formData), key: this.id })
        }
        this.$utils.notifySuccess('Configurações alteradas com sucesso!')
      } catch (error) {
        this.$utils.notifyError(error)
      }
    },
    async setFormData () {
      try {
        this.setLoading(true)
        const res = await this.find({ entity: 'parametro', rowsOnly: true })

        if (res !== null && Array.isArray(res) && res.length > 0) {
          res[0].passSmtp = '**********'
          this.formData = res[0]
          this.id = res[0].id
          if (this.$refs.settingsForm) {
            this.$refs.settingsForm.instance.resetValues()
            this.$refs.settingsForm.instance.updateData(res[0])
            this.$refs.settingsForm.instance.repaint()
          }
          this.$forceUpdate()
          this.hasRow = true
        } else {
          this.formData = {}
          this.hasRow = false
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoading(false)
      }
    }
  },
  async mounted () {
    await this.setFormData()
  }
}
</script>
