import SolicitacaoTransporte from './SolicitacaoTransporte.vue'

export default {
  path: '/solicitacao-transporte',
  name: 'solicitacao-transporte',
  component: SolicitacaoTransporte,
  meta: {
    icon: 'mdi mdi-truck-delivery',
    title: 'Solicitar Transporte',
    permissionType: 'consulta'
  },
  children: [
    {
      path: '/solicitacao-transporte/:action',
      name: 'solicitacao-transporte-add',
      component: SolicitacaoTransporte,
      meta: {
        title: 'Solicitar Transporte',
        requiresAuth: true,
        permissionType: 'inclui'
      },
      props: true
    }
  ],
  props: true
}
