<template>
  <div class="form-atribuicao-perfil">
    <span class="dx-form-group-caption">Atribuições do Perfil {{ perfilTitle}}</span>

    <text-field
      class="field-funcionalidade"
      label="Funcionalidade"
      :options="{}"
      :value="filterFuncionalidade"
      @input="onFilterInput"/>

    <table v-if="permissionData" class="perfil-permissions-table">
      <thead>
        <tr>
          <th>Funcionalidade</th>
          <th>Consulta</th>
          <th>Inclui</th>
          <th>Altera</th>
          <th>Exclui</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="funcionalidade in funcionalidadesVisible" :key="funcionalidade.id">
          <td>
            <strong>Funcionalidade: </strong>
            <span>{{ funcionalidade.descricao }}</span>
          </td>
          <td>
            <strong>Consulta: </strong>
            <dx-check-box
              v-if="funcionalidade.consultaDisponivel === 'S'"
              :disabled="!canEdit"
              :value.sync="permissionData[funcionalidade.id].consulta"/>
            <span v-else>---</span>
          </td>
          <td>
            <strong>Inclui: </strong>
            <dx-check-box
              v-if="funcionalidade.incluiDisponivel === 'S'"
              :disabled="!canEdit"
              :value.sync="permissionData[funcionalidade.id].inclui"/>
            <span v-else>---</span>
          </td>
          <td>
            <strong>Altera: </strong>
            <dx-check-box
              v-if="funcionalidade.alteraDisponivel === 'S'"
              :disabled="!canEdit"
              :value.sync="permissionData[funcionalidade.id].altera"/>
            <span v-else>---</span>
          </td>
          <td>
            <strong>Exclui: </strong>
            <dx-check-box
              v-if="funcionalidade.excluiDisponivel === 'S'"
              :disabled="!canEdit"
              :value.sync="permissionData[funcionalidade.id].exclui"/>
            <span v-else>---</span>
          </td>
        </tr>
      </tbody>

      <tfoot>
        <tr>
          <th>Funcionalidade</th>
          <th>Consulta</th>
          <th>Inclui</th>
          <th>Altera</th>
          <th>Exclui</th>
        </tr>
      </tfoot>
    </table>

    <message-error :message.sync="errorMessage"/>

    <div>
      <dx-button
        v-if="permissionData && canEdit"
        class="float-right button-save-solicitacao"
        type="success"
        @click="onButtonSaveClick">
        Salvar
      </dx-button>
      <dx-button
        class="float-right"
        type="normal"
        @click="goToList">Cancelar</dx-button>
    </div>
  </div>
</template>
<script>
import DxButton from 'devextreme-vue/button'
import DxCheckBox from 'devextreme-vue/check-box'
import { mapActions } from 'vuex'
import _ from 'lodash'

import {
  saveAtribuicoes,
  getPerfilById,
  getFuncionalidades
} from '../service'
import SaveAtribuicoesDto from './SaveAtribuicoesDto'
import MessageError from '@/components/MessageError'
import TextField from '@/components/Form/TextField'
import { hasPermission } from '@/util/permissions'

export default {
  name: 'form-atribuicao-perfil',
  components: {
    DxButton,
    DxCheckBox,
    MessageError,
    TextField
  },
  /**
   * Metodo do vue, para obter o estado inicial do componente.
   * @returns {Object} O estado inicial do componente.
   */
  data () {
    return {
      perfil: null,
      funcionalidades: null,
      observacoesFieldOptions: {
        maxLength: 200
      },
      errorMessage: null,
      filterFuncionalidade: null,
      onFilterInput: _.debounce(this._onFilterInput)
    }
  },
  computed: {
    /**
     * Valor computado dos dados de permissao.
     * @returns {Object}
     */
    permissionData: {
      get () {
        if (!this.perfil) {
          return null
        }

        const permissonsByFuncionalidade = this.perfil.permissoes.reduce((acc, val) => {
          acc[val.idFuncionalidade] = val
          return acc
        }, {})
        return this.funcionalidades.reduce((acc, val) => {
          if (val.id in permissonsByFuncionalidade) {
            const perm = permissonsByFuncionalidade[val.id]

            acc[val.id] = {
              id: perm.id,
              consulta: perm.consulta === 'S',
              inclui: perm.inclui === 'S',
              altera: perm.altera === 'S',
              exclui: perm.exclui === 'S',
              idFuncionalidade: val.id
            }
          } else {
            acc[val.id] = {
              consulta: false,
              inclui: false,
              altera: false,
              exclui: false,
              idFuncionalidade: val.id
            }
          }

          return acc
        }, {})
      },
      set (val) {
        this.perfil.permissoes = Object.values(val)
      }
    },

    /**
     * Valor computado da lista de funcionalidades filtradas pelo campo de
     * filtro de funcionalidades.
     * @returns {Object[]}
     */
    funcionalidadesVisible () {
      if (this.filterFuncionalidade && this.filterFuncionalidade.length) {
        const regex = new RegExp(this.filterFuncionalidade, 'ig')
        return (this.funcionalidades || []).filter(f => f.descricao.match(regex))
      } else {
        return (this.funcionalidades || [])
      }
    },

    /**
     * Valor computado do perfil que aparece no titulo da pagina.
     * @returns {string}
     */
    perfilTitle () {
      return this.perfil ? this.perfil.descricao : ''
    },

    /**
     * Valor computado de se o usuario logado tem permissao de edicao.
     * @returns {boolean}
     */
    canEdit () {
      return hasPermission('Gerenciar permissões', 'altera')
    }
  },

  /**
   * Metodo do ciclo de vida do vue, de quando o componente foi montado na tela.
   * Com o objetivo de buscar os dados da tela.
   */
  async mounted () {
    try {
      this.setLoading(true)
      const [funcionalidadesResult, perfilResult] = await Promise.all([
        getFuncionalidades(),
        getPerfilById(this.$route.params.id)
      ])

      this.funcionalidades = funcionalidadesResult.data.rows
      this.perfil = perfilResult.data
    } catch (e) {
      console.error(e)
      this.errorMessage = 'Ocorreu uma falha ao buscar os dados da solicitação de transporte, tente novamente.'
    } finally {
      this.setLoading(false)
    }
  },
  methods: {
    /**
     * Metodo de callback de quando foi clicado no botao de salvar as
     * atribuicoes. Com o objetivo de enviar a requisicao de salvar as
     * atribuicoes.
     */
    async onButtonSaveClick () {
      this.setLoading(true)
      this.errorMessage = null

      const params = new SaveAtribuicoesDto(this.permissionData, this.perfil)
      const res = await saveAtribuicoes(params.toRequestParams())

      if (res.success) {
        this.goToList()
      } else {
        this.errorMessage = res.error
      }

      this.setLoading(false)
    },

    /**
     * Metodo de comando, com o objetivo de ir para a pagina de listagem de
     * perfis.
     */
    goToList () {
      this.$router.push({ path: '/atribuicao-perfil' })
    },

    /**
     * Metodo de callback de quando ocorre o evento de input no campo de filtro
     * de funcionalidades.
     * @param {string} e - O novo valor do campo de filtro.
     */
    _onFilterInput (e) {
      this.filterFuncionalidade = e
    },

    ...mapActions('Crud', ['setLoading'])
  }
}
</script>

<style lang="scss">
@import '../../../../global.scss';

.form-atribuicao-perfil {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  > * {
    margin-bottom: 20px;
  }

  > .dx-form-group-caption {
    font-family: Montserrat, sans-serif;
  }

  > .perfil-permissions-table {
    flex: 1;
    border-spacing: 0px;

    tbody {
      overflow-y: auto;

      tr {
        transition: background-color .2s;

        &:hover {
          background-color: #eee;
        }

        td {
          text-align: center;
          padding: 2px;

          strong {
            display: none;
          }
        }
      }
    }
  }

  .button-save-solicitacao {
    margin-left: 12px;
  }

  > .error-message-card {
    margin-bottom: 16px;
  }

  > .field-funcionalidade {
    min-height: 60px;
  }
}

@media screen and (max-width: 767px) {
  .form-atribuicao-perfil {
    > .perfil-permissions-table {
      thead {
        display: none;
      }

      tbody tr {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        box-shadow: 0 0 5px #cdcdcd;
        padding: 8px;
        border-radius: 8px;

        td {
          display: flex;
          margin-bottom: 4px;

          strong {
            display: inline-block;
            flex: 1;
          }

          :not(strong) {
            flex: 3;
          }
        }
      }
    }
  }
}
</style>
