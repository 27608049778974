<template>
  <div class="residuos-grid">
    <form
      @submit="addResiduo">
      <DxForm
        ref="formInstance"
        :form-data.sync="formData"
        :show-validation-summary="true">
        <DxGroupItem :col-count="4">
          <DxSimpleItem v-bind="tipoResiduo"/>
          <DxSimpleItem v-bind="numeroM3"/>
          <DxButtonItem
            :col-span="1"
            :button-options="{ text: 'Adicionar', type: 'success', useSubmitBehavior: true, icon: 'mdi mdi-shape' }"
            cssClass="inserir-residuo-button"
            horizontal-alignment="start"
            vertical-alignment="center"
          />
          <DxEmptyItem :col-span="1"/>
          <DxSimpleItem :col-span="4">
            <template #default>
              <div style="padding-top: 25px; padding-bottom: 25px">
                <dx-data-grid
                  ref="residuosGrid"
                  :data-source="residuosDataSource"
                  :columns="residuosColumns"
                  :allow-deleting="false"
                  :confirm-delete="false"
                />
              </div>
            </template>
          </DxSimpleItem>
          <DxSimpleItem v-bind="idResiduoPredominante"/>
        </DxGroupItem>
      </DxForm>
    </form>
  </div>
</template>
<script setup>
import { DxDataGrid } from 'devextreme-vue/data-grid'
import { ref, reactive, watch } from 'vue'
import CustomStore from 'devextreme/data/custom_store'
import { listClassesMateriais } from '@/components/Obra/service'
import { getParamsFromLoadOptions } from '@/util/loadOptionsParams'
import DxForm, { DxGroupItem, DxButtonItem, DxSimpleItem, DxEmptyItem } from 'devextreme-vue/form'
import { confirm } from 'devextreme/ui/dialog'

const emit = defineEmits(['change'])

const formData = reactive({
  idResiduoPredominante: null,
  tipoResiduo: {
    classe: {
      nome: null
    },
    nome: null
  },
  volumeM3: null
})
const formInstance = ref()
const residuosGrid = ref()
const residuosDataSource = ref([])
const residuosColumns = ref([
  {
    dataField: 'classeMaterial',
    caption: 'Classe de Material'
  },
  {
    dataField: 'materialTipoResiduo',
    caption: 'Material - Tipo de Resíduo'
  },
  {
    dataField: 'volumeM3',
    caption: 'Volume em M³'
  },
  {
    type: 'buttons',
    fixedPosition: 'left',
    fixed: true,
    buttons: [
      {
        cssClass: 'medium-icon mdi mdi-delete',
        onClick: ({ row: { data } }) => {
          const index = residuosDataSource.value.indexOf(data)
          residuosDataSource.value.splice(index, 1)
        }
      }
    ]
  }
])
const tipoResiduo = {
  dataField: 'tipoResiduo',
  editorType: 'dxSelectBox',
  label: { text: 'Tipo Resíduo', location: 'top' },
  colSpan: 1,
  validationRules: [
    {
      ruleName: 'tipoResiduoValid',
      type: 'custom',
      validationCallback: () => {
        const data = formInstance.value.instance.getEditor('tipoResiduo').option('value')
        return !!(data && data.nome)
      },
      message: 'Por favor, insira um tipo de resíduo'
    },
    {
      ruleName: 'tipoResiduoAlreadyExists',
      type: 'custom',
      validationCallback: () => {
        return !residuosDataSource.value.find(tipoResiduo => tipoResiduo.id === formInstance.value.instance.getEditor('tipoResiduo').option('value').id)
      },
      message: 'Resíduo já selecionado'
    }
  ],
  editorOptions: {
    allowClearing: true,
    showClearButton: true,
    dataSource: new CustomStore({
      key: 'id',
      load: async (loadOptions) => {
        const residuos = await listClassesMateriais(getParamsFromLoadOptions(loadOptions))
        let result
        if (residuos.success) {
          result = residuos.data.rows.map(classe => {
            classe.tipoResiduos.forEach(tipoResiduo => {
              tipoResiduo.classe = { nome: classe.classe, descricao: classe.descricao }
            })
            return {
              key: classe.classe,
              items: classe.tipoResiduos
            }
          })
        } else {
          throw new Error('Falha ao buscar a lista de transportadoras')
        }
        result.totalCount = residuos.data.count
        return result
      },
      byKey: key => key
    }),
    displayExpr: 'nome',
    grouped: true,
    label: 'Classe de Material ou Material - Tipo de Residuo',
    minSearchLength: 0,
    openOnFieldClick: true
  }
}
const numeroM3 = {
  dataField: 'volumeM3',
  editorType: 'dxNumberBox',
  colSpan: 1,
  dataType: 'number',
  validationRules: [
    {
      type: 'custom',
      message: 'Valor de Volume M³ inválido',
      validationCallback: ({ value }) => value > 0
    }
  ],
  label: { text: 'Volume M³', location: 'top' }
}
const idResiduoPredominante = reactive({
  dataField: 'idResiduoPredominante',
  label: { text: 'Tipo de resíduo predominante', location: 'top' },
  editorType: 'dxSelectBox',
  editorOptions: {
    readOnly: true,
    displayExpr: 'materialTipoResiduo',
    valueExpr: 'id'
  }
})
const addResiduo = async (e, parsedResiduo = null, showConfirmation = true) => {
  if (e) e.preventDefault()
  parsedResiduo = !parsedResiduo ? {
    id: formData.tipoResiduo.id,
    classeMaterial: formData.tipoResiduo.classe.nome,
    materialTipoResiduo: formData.tipoResiduo.nome,
    volumeM3: formData.volumeM3
  } : parsedResiduo
  const row = residuosDataSource.value.find(residuo => residuo.id === formData.tipoResiduo.id)
  if (row) {
    const index = residuosDataSource.value.indexOf(row)
    residuosDataSource.value[index] = parsedResiduo
    residuosGrid.value.instance.refresh()
  } else {
    /**
     * Caso de resíduo MISTO
     */
    if (residuosDataSource.value.length >= 1) {
      const result = showConfirmation ? await confirm('<i>Você está tentando adicionar resíduos diferentes, desta forma será adotada a Classe Mista, deseja continuar?</i>', 'Confirmar') : true
      if (result) {
        residuosDataSource.value.push(parsedResiduo)
        const classeMista = formInstance.value.instance.getEditor('tipoResiduo').option('items').length
          ? formInstance.value.instance.getEditor('tipoResiduo').option('items').find(item => item.key === 'CLASSE MISTA').items[0]
          : { id: 5, classe: { nome: 'CLASSE MISTA' }, nome: 'Resíduo de Construção Civil - Não Segregado', volumeM3: undefined }
        formData.idResiduoPredominante = classeMista.id
        idResiduoPredominante.editorOptions = {
          ...idResiduoPredominante.editorOptions,
          items: [{
            id: classeMista.id,
            classeMaterial: classeMista.classe.nome,
            materialTipoResiduo: classeMista.nome,
            volumeM3: classeMista.volumeM3
          }]
        }
      }
    } else {
      /**
       * Adição de resíduo normal no grid
       */
      residuosDataSource.value.push(parsedResiduo)
      formData.idResiduoPredominante = residuosDataSource.value[0].id
      idResiduoPredominante.editorOptions = {
        ...idResiduoPredominante.editorOptions,
        items: residuosDataSource.value
      }
    }
  }
}

watch(residuosDataSource, () => {
  if (residuosDataSource.value.length === 1) {
    formData.idResiduoPredominante = residuosDataSource.value[0].id
    idResiduoPredominante.editorOptions = {
      ...idResiduoPredominante.editorOptions,
      items: residuosDataSource.value
    }
  } else if (!residuosDataSource.value.length) {
    formData.idResiduoPredominante = null
  }
  emit('change', { residuos: residuosDataSource, idResiduoPredominante: formData.idResiduoPredominante })
}, { deep: true })
defineExpose({ residuosDataSource, formData, addResiduo })
</script>
<style>

.residuos-grid {
  padding-top: 30px;
  border-bottom: solid lightgray 0.1px;
  border-top: solid lightgray 0.1px;
}

.inserir-residuo-button {
  position: relative;
  padding-top: 20px;
}

</style>
