/**
 * Classe responsavel por parsear os dados que vem do server para o modelo da
 * tela.
 */
export default class GetSolicitacaoCacambaDto {
  constructor (solicitacao) {
    this.id = solicitacao.id
    this.tipoResiduoPredominante = { tipoResiduo: solicitacao.residuoPredominante }
    this.destinoFinal = solicitacao.destinoFinal
      ? solicitacao.destinoFinal.pessoa
      : null
    this.veiculo = solicitacao.veiculo
    this.observacao = solicitacao.observacao
    this.fotoCacamba = solicitacao.fotoCacamba ? [solicitacao.fotoCacamba] : []
    this.nomeFotoCacamba = solicitacao.nomeFotoCacamba
    this.tipo = solicitacao.veiculo ? solicitacao.veiculo.tipo : null
    this.poliguindaste = solicitacao.veiculo
      ? solicitacao.veiculo.poliguindaste
      : null
    this.residuos = solicitacao.residuos.map(residuo => ({
      idTipoResiduo: residuo.idTipoResiduo,
      volume: residuo.volumeAproximadoM3,
      tipoResiduo: residuo.tipoResiduo
    }))
    this.dataHoraAlocacao = solicitacao.dataHoraAlocacao
  }
}
