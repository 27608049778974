<template>
  <div class="list-usuarios">
    <div class="flex" style="align-items: flex-end">
      <div class="flex-col-5">
        <b>Este filtro aplicado, exibirá: </b>
        <dx-select-box
          :data-source="[
            { text: 'Pessoas com usuário pendente de autorização', type: 'USUARIOS_PENDENTES' },
             { text: 'Pessoas com perfil pendente de autorização', type: 'PERFIS_PENDENTES' },
             { text: 'Todos os registros', type: 'ALL' }
             ]"
          display-expr="text"
          value-expr="type"
          v-model="filterValueAll"
        />
      </div>
      <div class="flex-col-7">
        <div class="flex">
          <div>
            <dx-button type="default" @click="applyFilterValueAll()">Aplicar filtro</dx-button>
          </div>
          <div>
            <dx-button v-if="canAdd" type="success" @click="$router.push({ path: 'pessoa/add' })">Cadastrar Nova Pessoa</dx-button>
          </div>
        </div>
      </div>
    </div>
    <div v-show="loading" style="position: relative; width: 100%; padding-bottom: 10px; top: 0">
      <md-progress-bar class="md-primary" md-mode="indeterminate"></md-progress-bar>
    </div>
    <simple-grid
      entity="ViewPessoa"
      ref="simpleGrid"
      :grid-data="gridData"
      :deletable="false"
      :editable="false"
      :get-edit-path="getEditPath"
    />
    <dx-popup
      :hide-on-outside-click="true"
      :drag-enabled="false"
      :height="popupHeightValidacaoCadastro"
      :width="popupWidth"
      :showCloseButton="true"
      :show-title="true"
      title="Validação do cadastro da pessoa"
      :visible.sync="popupValidacao.visible">
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="after"
        :visible="popupValidacao.situacao !== 'Inativo'"
        :options="inativarButton"
      />
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="after"
        :options="{text: 'Salvar', onClick:salvarPopupValidacao, type: 'success'}"
      />
      <template #content>
        <dx-scroll-view>
          <div style="margin-bottom: 10px;">Usuário: <b>{{popupValidacao.nomeRazaoSocial}}</b></div>
          <div style="margin-bottom: 10px;">Situação: <b>{{popupValidacao.situacao}}</b></div>
          <div>
            <div v-if="showJustificativa()" style="margin-top: 10px">
              <label>Justificativa de validação</label>
              <dx-text-area v-model="popupValidacao.justificativaValidacao" :width="'100%'" />
              <br />
            </div>
            <div v-if="popupValidacao.perfilTransportador && Array.isArray(popupValidacao.perfilTransportador.arquivos) && popupValidacao.perfilTransportador.arquivos.length"
                 style="margin-top: 20px">
              <h3>Validação de Documentos</h3>
              <div class="separator" v-for="(arquivo,index) in popupValidacao.perfilTransportador.arquivos" :key="index">
                <div style="display: flex;flex-wrap: wrap; justify-content: space-between; padding: 5px">
                  <div style="display: grid">
                    <span style="font-style: italic">{{arquivo.tipoArquivo.descricao}}</span>
                    <div v-if="arquivo.validade" style="display: grid">
                      <span
                        v-if="validateFunction(new Date(`${arquivo.validade.split('T')[0]}T00:00:00`))"
                        style="font-weight: bold; color:red; font-size: 16px">
                        Atenção! Documento vencido
                      </span>
                      <span>
                        Data Validade:
                        <span style="font-weight: bold">
                          {{ new Date(`${arquivo.validade.split('T')[0]}T00:00:00`).toLocaleDateString() }}
                        </span>
                      </span>
                    </div>
                    <div>
                      <span v-show="arquivo.situacao === 'V' || arquivo.situacao === 'P'">
                        Usuário responsável pela validação:
                        <span style="font-weight: bold">
                          {{ arquivo.usuarioValidacao?.nome }}
                        </span>
                      </span>
                    </div>
                  </div>
                  <DxSelectBox
                    v-if="!arquivo.noFile"
                    v-model="arquivo.situacao"
                    displayExpr="label"
                    valueExpr="value"
                    @value-changed="onArquivoSituacaoChanged($event, arquivo)"
                    :items="[
                      { value: 'N', label: 'Não Validado' },
                      { value: 'V', label: 'Validado' },
                      { value: 'P', label: 'Pendente' }
                    ]"
                  />
                </div>
                <dx-button
                  v-if="!arquivo.noFile"
                  style="margin-left: 5px"
                  type="info"
                  text="Baixar Documento"
                  @click="downloadAnexo('Arquivo', arquivo.id, 'anexo', arquivo.name)"
                />
                <dx-file-uploader
                  v-else
                  ref="fileUploader"
                  selectButtonText="Adicionar Documento"
                  :multiple="false"
                  :showFileList="false"
                  upload-mode="useForm"
                  @value-changed="onChangeAttachment($event, arquivo)"
                />
                <div style="margin-top: 10px">
                  <dx-text-area
                    v-show="arquivo.situacao !== 'V' && !arquivo.noFile"
                    :read-only="arquivo.situacao !== 'P'"
                    v-model="arquivo.descricaoPendencia"
                    />
                </div>
              </div>
            </div>
          </div>
          <br />
        </dx-scroll-view>
      </template>
    </dx-popup>

    <!-- Autorizar Perfis -->
    <dx-popup
      title="Autorizar Perfis Pendentes"
      :height="popupHeight"
      :width="popupWidth"
      :showCloseButton="true"
      :visible.sync="popupValidacaoPerfis.visible"
      :hide-on-outside-click="true"
      :drag-enabled="false"
      :show-title="true"
    >
      <template #content>
        <dx-scroll-view>
          <div style="margin-bottom: 10px;">
            {{popupValidacaoPerfis.pessoa.tipoPessoa === 'J' ? 'Razão Social:' : 'Nome da pessoa:'}}
            <b>{{popupValidacaoPerfis.pessoa.nomeRazaoSocial}}</b>
          </div>
          <dx-data-grid
            width="100%"
            :column-hiding-enabled="true"
            ref="dataGridValidacaoPerfis"
            :data-source="popupValidacaoPerfis.pessoa.perfis"
            :columns="popupValidacaoPerfisColumns"
          />
        </dx-scroll-view>
      </template>
    </dx-popup>

    <!-- Inativar Pessoa -->
    <dx-popup
      title="Inativar Pessoa"
      height="auto"
      :visible.sync="popupInativar.visible"
      :hide-on-outside-click="true"
      :drag-enabled="false"
      :show-title="true"
    >
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="after"
        :options="{text: 'Salvar', onClick:salvarPopupInativar, type: 'success'}"
      />
      <template #content>
        <dx-scroll-view>
          <dx-text-area v-model="popupInativar.justificativaValidacao" :width="'100%'" placeholder="Justificativa de inativação"/>
        </dx-scroll-view>
      </template>
    </dx-popup>

    <!-- Autorizar Usuarios -->
    <dx-popup
      title="Autorizar Usuários"
      :height="popupHeight"
      :width="popupWidth"
      :showCloseButton="true"
      :visible.sync="popupValidacaoUsuarios.visible"
      :hide-on-outside-click="true"
      :drag-enabled="false"
      :show-title="true"
    >
      <template #content>
        <dx-scroll-view>
          <div style="margin-bottom: 10px;">
            {{popupValidacaoUsuarios.pessoa.tipoPessoa === 'J' ? 'Razão Social:' : 'Nome da pessoa:'}}
            <b>{{popupValidacaoUsuarios.pessoa.nomeRazaoSocial}}</b>
          </div>
          <div style="margin-bottom: 10px;">
            {{popupValidacaoUsuarios.pessoa.tipoPessoa === 'J' ? 'CNPJ:' : 'CPF:'}}
            <b>{{popupValidacaoUsuarios.pessoa.cpfCnpj}}</b>
          </div>
          <dx-data-grid
            width="100%"
            :column-hiding-enabled="true"
            ref="dataGridValidacaoPerfis"
            :data-source="popupValidacaoUsuarios.pessoa.usuarios"
            :columns="popupValidacaoUsuariosColumns"
          />
        </dx-scroll-view>
      </template>
    </dx-popup>
  </div>
</template>
<style lang="scss">
.separator {
  border-bottom: 0.1px solid lightgrey;
  padding-bottom: 10px;
  margin-bottom: 11px;
  width: 99%;
}
</style>

<script>
import CustomStore from 'devextreme/data/custom_store'
import { DxButton, DxSelectBox, DxTextArea, DxScrollView, DxDataGrid } from 'devextreme-vue'
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup'
import { SimpleGrid } from '@/components'
import mixinCrud from '@/pages/Cruds/mixin-crud'
import notify from 'devextreme/ui/notify'
import { hasPermission } from '@/util/permissions'
import { mapActions, mapGetters } from 'vuex'
import config from 'config'
import { http } from '@/pluggables/http'
import moment from 'moment'
import { confirm, alert, custom } from 'devextreme/ui/dialog'
import { verifyValidadeDate } from '@/helper/general'
import { documentosEnum } from '@/pages/Cruds/Pessoa/documentosEnum'
import { DxFileUploader } from 'devextreme-vue/file-uploader'

const baseUrl = config.baseUrl
export default {
  mixins: [mixinCrud],
  components: {
    SimpleGrid,
    DxButton,
    DxPopup,
    DxSelectBox,
    DxTextArea,
    DxScrollView,
    DxDataGrid,
    DxToolbarItem,
    DxFileUploader
  },
  computed: {
    ...mapGetters('Login', ['userData']),
    validateFunction () {
      return verifyValidadeDate
    },
    /**
     * Valor computado para verificar se o usuario logado e uma secretaria.
     * @returns {Boolean}
     */
    isSecretaria () {
      return this.verifyProfile('Secretaria')
    },
    isAdministrador () {
      return this.verifyProfile('Administrador')
    },

    canAdd () {
      return hasPermission('Cadastro de Pessoas', 'inclui')
    }

    /* canEdit () {
      return hasPermission('Cadastro de Pessoas', 'altera')
    } */
  },
  data () {
    const datasourcePessoa = new CustomStore({
      key: 'id',
      load: (loadOptions) => {
        const filterConfigAll = this.filterConfigAll || {}
        return this.$utils.wrapRequestForGrid(this.find({
          entity: this.entity,
          loadOptions,
          ...(filterConfigAll ? {
            params: {
              where: { ...filterConfigAll }
            }
          } : {})
        }))
      },
      insert: (values) => this.insertRow({ entity: this.entity, values }),
      update: (key, values) => this.updateRow({ entity: this.entity, values, key }),
      remove: (key, values) => this.removeRow({ entity: this.entity, key })
    })

    return {
      entity: 'ViewPessoa',
      formData: null,
      filterValueAll: 'ALL',
      filterConfigAll: null,
      inativarButton: {
        text: 'Inativar',
        onClick: async (data) => {
          this.popupInativar = {
            visible: true,
            pessoa: { id: this.popupValidacao.idPessoa, perfis: [this.popupValidacao.perfilTransportador] },
            justificativaValidacao: ''
          }
        },
        type: 'primary'
      },
      popupValidacao: {
        visible: false,
        idPessoa: null,
        nomeRazaoSocial: null,
        situacao: 'Pendente',
        justificativaValidacao: null
      },
      popupValidacaoPerfisColumns: [
        {
          dataField: 'id',
          caption: 'ID',
          width: 'auto'
        },
        {
          dataField: 'tipoPerfil.descricao',
          caption: 'Tipo Perfil',
          width: 150
        },
        {
          dataField: 'situacaoValidacao',
          caption: 'Situação Validação',
          calculateSortValue: 'situacaoValidacao',
          calculateDisplayValue: ({ situacaoValidacao }) => {
            return {
              P: 'Pendente de Autorização',
              A: 'Ativo',
              I: 'Inativo'
            }[situacaoValidacao]
          }
        },
        {
          type: 'buttons',
          buttons: [
            {
              name: 'ativar', // id único pro botão (não pode ser 'edit' ou 'delete')
              text: 'Ativar', // label do botão
              cssClass: 'dx-button mdi mdi-check', // ícone que o botão usa + classe de botão
              visible: (result) => {
                const { row: { data } } = result
                return (['Ativo', 'Aguardando validação', 'Pendente'].includes(this.popupValidacaoPerfis.pessoa.situacao)) && (['I', 'P'].includes(data.situacaoValidacao.toUpperCase()))
              },
              onClick: async (result) => {
                try {
                  const { row: { data } } = result
                  const situacaoValidacao = 'A'
                  await this.updatePerfil(data.id, { situacaoValidacao })
                  data.situacaoValidacao = situacaoValidacao
                  this.$refs.dataGridValidacaoPerfis.$_instance.refresh()
                } catch (e) {
                  console.error(e)
                }
              }
            },
            {
              name: 'inativar', // id único pro botão (não pode ser 'edit' ou 'delete')
              text: 'Inativar', // label do botão
              cssClass: 'dx-button mdi mdi-check', // ícone que o botão usa + classe de botão
              visible: (result) => {
                const { row: { data } } = result
                return ['A', 'P'].includes(data.situacaoValidacao.toUpperCase())
              },
              onClick: async (result) => {
                try {
                  const { row: { data } } = result
                  const situacaoValidacao = 'I'
                  await this.updatePerfil(data.id, { situacaoValidacao })
                  data.situacaoValidacao = situacaoValidacao
                  this.$refs.dataGridValidacaoPerfis.$_instance.refresh()
                } catch (e) {

                }
              }
            },
            {
              name: 'pendente', // id único pro botão (não pode ser 'edit' ou 'delete')
              text: 'Pendente', // label do botão
              cssClass: 'dx-button mdi mdi-check', // ícone que o botão usa + classe de botão
              visible: (result) => {
                const { row: { data } } = result
                return ['A'].includes(data.situacaoValidacao.toUpperCase()) && data.tipoPerfil.descricao === 'Transportador'
              },
              onClick: async (result) => {
                try {
                  const { row: { data } } = result
                  const situacaoValidacao = 'P'
                  await this.updatePerfil(data.id, { situacaoValidacao })
                  data.situacaoValidacao = situacaoValidacao
                  this.$refs.dataGridValidacaoPerfis.$_instance.refresh()
                  if (data.tipoPerfil.descricao === 'Transportador') {
                    this.$refs.dataGridValidacaoPerfis.$_instance.option('dataSource').forEach((perfil) => {
                      perfil.situacaoValidacao = 'P'
                    })
                  }
                } catch (e) {

                }
              }
            }
          ]
        }
      ],
      popupValidacaoUsuariosColumns: [
        {
          dataField: 'id',
          caption: 'ID'
        },
        {
          dataField: 'nome',
          caption: 'Nome'
        },
        {
          dataField: 'cpf',
          caption: 'CPF'
        },
        {
          dataField: 'email',
          caption: 'E-mail'
        },
        {
          dataField: 'ativo',
          caption: 'Status',
          calculateDisplayValue: ({ ativo }) => {
            return {
              N: 'Inativo',
              S: 'Ativo'
            }[ativo]
          }
        },
        {
          type: 'buttons',
          buttons: [
            {
              name: 'ativar', // id único pro botão (não pode ser 'edit' ou 'delete')
              text: 'Ativar', // label do botão
              cssClass: 'dx-button mdi mdi-check', // ícone que o botão usa + classe de botão
              visible: (result) => {
                const { row: { data } } = result
                return !data.ativo || data.ativo === 'N'
              },
              onClick: async (result) => {
                try {
                  const { row: { data } } = result
                  const ativo = 'S'
                  await this.updateUsuario(data.id, { ativo: ativo })
                  data.ativo = ativo
                  this.$refs.dataGridValidacaoPerfis.$_instance.refresh()
                } catch (e) {
                  console.error(e)
                }
              }
            },
            {
              name: 'inativar', // id único pro botão (não pode ser 'edit' ou 'delete')
              text: 'Inativar', // label do botão
              cssClass: 'dx-button mdi mdi-check', // ícone que o botão usa + classe de botão
              visible: (result) => {
                const { row: { data } } = result
                return data.ativo === 'S'
              },
              onClick: async (result) => {
                try {
                  const { row: { data } } = result
                  const ativo = 'N'
                  await this.updateUsuario(data.id, { ativo: ativo })
                  data.ativo = ativo
                  this.$refs.dataGridValidacaoPerfis.$_instance.refresh()
                } catch (e) {
                  console.error(e)
                }
              }
            },
            {
              name: 'linkRecuperarSenha', // id único pro botão (não pode ser 'edit' ou 'delete')
              text: 'Recuperar Senha', // label do botão
              hint: 'Gerar link de recuperação de senha para o usuário',
              cssClass: 'dx-button mdi mdi-check', // ícone que o botão usa + classe de botão
              visible: (result) => {
                const { row: { data } } = result
                return data && data.ativo === 'S' && this.popupValidacaoUsuarios.pessoa && (['Ativo', 'Pendente'].includes(this.popupValidacaoUsuarios.pessoa.situacao)) && hasPermission('Gerar Link de Recuperação', 'consulta')
              },
              onClick: async (result) => {
                const { row: { data } } = result
                const confirmDialog = custom({
                  title: 'Confirmação',
                  messageHtml: '<b>Ao continuar será gerado um link que permite a alteração de senha do usuário. Esta ação será registrada em seu nome nos logs do sistema, deseja realmente continuar?</b>',
                  buttons: [{
                    text: 'Gerar link copiável',
                    onClick: (e) => {
                      return { buttonText: e.component.option('text') }
                    }
                  },
                  {
                    text: 'Enviar link no email do usuário',
                    onClick: (e) => {
                      return { buttonText: e.component.option('text') }
                    }
                  },
                  {
                    text: 'Cancelar',
                    onClick: (e) => {
                      return { buttonText: e.component.option('text') }
                    }
                  }
                  ]
                })
                confirmDialog.show().then(async ({ buttonText }) => {
                  if (buttonText === 'Gerar link copiável') {
                    try {
                      const { data: { linkSenha } } = await http(`${baseUrl}/getLinkRecovery/${data.id}`)

                      if (linkSenha) {
                        this.popupValidacaoUsuarios.visible = false
                        try {
                          await navigator.clipboard.writeText(linkSenha)
                        } finally {
                          await alert(`Copie o link de recuperação abaixo: <br> <strong>${linkSenha}</strong>`, 'Link gerado com sucesso')
                        }
                      }
                    } catch (error) {
                      console.error(error)
                      // notify('Não foi possível gerar o link de recuperação de senha', 'error')
                    }
                  } else if (buttonText === 'Enviar link no email do usuário') {
                    await this.recoveryAction({ email: data.email, idPessoa: this.popupValidacaoUsuarios.pessoa.id }).then(async () => {
                      this.showProgressBarLoading = false
                      confirmDialog.hide()
                      notify(`Uma nova senha foi enviada para o usuário ${data.nome}`, 'success', 4000)
                    })
                  }
                })
              }
            }
          ]
        }
      ],
      popupValidacaoUsuarios: {
        visible: false,
        pessoa: null
      },
      popupValidacaoPerfis: {
        visible: false,
        pessoa: null
      },
      popupInativar: {
        visible: false,
        pessoa: null
      },
      gridData: {
        value: {
          visible: true
        },
        columns: this.parseColumns([
          {
            dataField: 'nomeRazaoSocial',
            caption: 'Nome'
          },
          {
            dataField: 'endereco',
            caption: 'Endereço',
            visible: false
          },
          {
            dataField: 'bairro',
            caption: 'Bairro',
            visible: false
          },
          {
            dataField: 'cep',
            caption: 'CEP',
            visible: false
          },
          {
            dataField: 'municipio',
            caption: 'Município',
            visible: false
          },
          {
            dataField: 'email',
            caption: 'E-mail'
          },
          {
            calculateDisplayValue: ({ tipoPessoa }) => {
              return tipoPessoa === 'J'
                ? 'Pessoa Jurídica'
                : 'Pessoa Física'
            },
            calculateSortValue: 'tipoPessoa',
            caption: 'Tipo Pessoa',
            editorType: 'dxLookup',
            dataField: 'tipoPessoa',
            editorOptions: {
              allowClearing: true,
              showClearButton: true,
              showCancelButton: false,
              displayExpr: 'alias',
              valueExpr: 'tipoPessoa',
              dataSource: [{ alias: 'Pessoa Física', tipoPessoa: 'F' }, { alias: 'Pessoa Jurídica', tipoPessoa: 'J' }]
            }
          },
          {
            calculateDisplayValue: ({ transportador }) => {
              return transportador === 'S'
                ? 'Sim'
                : 'Não'
            },
            calculateSortValue: 'transportador',
            caption: 'Transportador',
            editorType: 'dxLookup',
            dataField: 'transportador',
            editorOptions: {
              allowClearing: true,
              showClearButton: true,
              showCancelButton: false,
              displayExpr: 'alias',
              valueExpr: 'value',
              dataSource: [{ alias: 'Sim', value: 'S' }, { alias: 'Não', value: 'N' }]
            }
          },
          {
            calculateDisplayValue: ({ gerador }) => {
              return gerador === 'S'
                ? 'Sim'
                : 'Não'
            },
            calculateSortValue: 'gerador',
            caption: 'Gerador',
            editorType: 'dxLookup',
            dataField: 'gerador',
            editorOptions: {
              allowClearing: true,
              showClearButton: true,
              showCancelButton: false,
              displayExpr: 'alias',
              valueExpr: 'value',
              dataSource: [{ alias: 'Sim', value: 'S' }, { alias: 'Não', value: 'N' }]
            }
          },
          {
            calculateDisplayValue: ({ tipoPessoa, cpfCnpj }) => {
              return tipoPessoa === 'J'
                ? this.$utils.formatarCnpj(cpfCnpj)
                : this.$utils.formatarCpf(cpfCnpj)
            },
            caption: 'CPF / CNPJ',
            dataField: 'cpfCnpj'
          },
          {
            caption: 'Situação',
            editorType: 'dxLookup',
            dataField: 'situacao',
            width: 200,
            editorOptions: {
              allowClearing: true,
              showClearButton: true,
              showCancelButton: false,
              dataSource: [
                'Aguardando validação',
                'Ativo',
                'Inativo',
                'Pendente'
              ]
            }
          },
          {
            type: 'buttons',
            showInColumnChooser: false,
            alignment: 'right',
            buttons: [
              {
                name: 'validar', // id único pro botão (não pode ser 'edit' ou 'delete')
                text: 'Validar', // label do botão
                cssClass: 'dx-button mdi mdi-check', // ícone que o botão usa + classe de botão
                visible: (result) => {
                  const { row: { data } } = result
                  return (data.transportador === 'S' && hasPermission('Arquivo', 'inclui') && hasPermission('Arquivo', 'altera') && hasPermission('Arquivo', 'exclui') && hasPermission('Cadastro de Pessoas', 'altera'))
                  /* return /valida|pendente/i.test(data.situacao) */
                },
                onClick: async (result) => {
                  this.loading = true
                  const data = await this.findOne({
                    entity: 'Pessoa',
                    params: {
                      attributes: ['id', 'nomeRazaoSocial', 'situacao', 'tipoPessoa'],
                      where: {
                        id: result.row.data.id
                      },
                      include: JSON.stringify([
                        {
                          model: 'Usuario',
                          as: 'usuarios'
                        },
                        {
                          model: 'Perfil',
                          as: 'perfis',
                          include: [
                            {
                              model: 'Arquivo',
                              as: 'arquivos',
                              attributes: ['id', 'descricao', 'nomeArquivo', 'situacao', 'validade', 'descricaoPendencia', 'idUsuarioValidacao'],
                              include: [
                                {
                                  model: 'TipoArquivo',
                                  as: 'tipoArquivo'
                                },
                                {
                                  model: 'Usuario',
                                  attributes: ['id', 'nome'],
                                  as: 'usuarioValidacao'
                                }
                              ]
                            },
                            {
                              model: 'TipoPerfil',
                              as: 'tipoPerfil'
                            }
                          ]
                        }
                      ])
                    }
                  })
                  const perfilTransportador = data.perfis.find(perfil => perfil.tipoPerfil.id === 9 || perfil.tipoPerfil.id === 2)
                    ? JSON.parse(JSON.stringify(data.perfis.find(perfil => perfil.tipoPerfil.id === 9 || perfil.tipoPerfil.id === 2)))
                    : null
                  const documentosEnumIds = Object.keys(documentosEnum[data.tipoPessoa]).map((id) => parseInt(id))
                  for (const documentoId in documentosEnum[data.tipoPessoa]) {
                    if (!perfilTransportador.arquivos.find(arquivo => arquivo.tipoArquivo.id === parseInt(documentoId))) {
                      perfilTransportador.arquivos.push({
                        id: Math.random().toString(16).slice(2),
                        noFile: true,
                        tipoArquivo: {
                          id: documentoId,
                          descricao: documentosEnum[data.tipoPessoa][documentoId]
                        }
                      })
                    }
                  }

                  /**
                   * Ignora arquivos que não estejam no ENUM de documentos
                   */
                  perfilTransportador.arquivos.forEach(arquivo => {
                    if (!documentosEnumIds.includes(arquivo.tipoArquivo.id) && !arquivo.noFile) {
                      arquivo.ignoreFile = true
                    }
                  })
                  this.popupValidacao = {
                    visible: true,
                    idPessoa: data.id,
                    nomeRazaoSocial: data.nomeRazaoSocial,
                    perfilTransportador,
                    situacao: data.situacao,
                    justificativaValidacao: perfilTransportador.justificativaValidacao,
                    perfilPessoa: perfilTransportador
                  }
                  this.loading = false
                }
              },
              {
                name: 'ativar', // id único pro botão (não pode ser 'edit' ou 'delete')
                text: 'Ativar', // label do botão
                cssClass: 'dx-button mdi mdi-check', // ícone que o botão usa + classe de botão
                visible: (result) => {
                  const { row: { data } } = result
                  return (data.transportador === 'N') && (data.situacao.toUpperCase() === 'INATIVO' || data.situacao.toUpperCase() === 'AGUARDANDO VALIDAÇÃO') && (hasPermission('Cadastro de Pessoas', 'inclui') && hasPermission('Cadastro de Pessoas', 'altera'))
                },
                onClick: async (result) => {
                  const { row: { data } } = result
                  try {
                    this.setLoading(true)
                    const pessoaPerfis = await this.find({
                      entity: 'Perfil',
                      params: {
                        where: {
                          idPessoa: data.id
                        },
                        attributes: ['id', 'justificativaValidacao']
                      }
                    })
                    const motivoInativo = pessoaPerfis.data.map(perfil => perfil.justificativaValidacao)[0]
                    this.setLoading(false)
                    const confirmHtml = motivoInativo
                      ? ` <span style="font-size: 16px">Tem certeza que deseja ativar este usuário ?</span>
                    </br>
                    <i>Motivo pelo qual usuário foi inativado: <b>"${motivoInativo}"</b></i>`
                      : `
                    <span style="font-size: 16px">Tem certeza que deseja ativar este usuário ?</span>
                    </br>`
                    const dialogResult = confirm(confirmHtml, 'Confirmar')
                    dialogResult.then(async (dialogResult) => {
                      if (dialogResult) {
                        const promises = []
                        if (Array.isArray(pessoaPerfis.data) && pessoaPerfis.data.length) {
                          pessoaPerfis.data.forEach(perfil => {
                            promises.push(this.$http.put(`${baseUrl}/Perfil/${perfil.id}`, { situacaoValidacao: 'A' }))
                          })
                        }
                        promises.push(this.$http.put(`${baseUrl}/Pessoa/${data.id}`, { situacao: 'Ativo' }))
                        await Promise.all(promises)
                        this.$refs.simpleGrid.$refs.gridInstance.instance.getDataSource().reload()
                      }
                    })
                  } catch (e) {
                    this.setLoading(false)
                    console.error(e)
                  }
                }
              },
              {
                name: 'toggleToken', // id único pro botão (não pode ser 'edit' ou 'delete')
                text: 'Desabilitar acesso API', // label do botão
                cssClass: 'dx-button mdi mdi-check', // ícone que o botão usa + classe de botão
                visible: ({ row: { data } }) => {
                  return (!!(data.acessoApi) && (hasPermission('Cadastro de Pessoas', 'inclui') && hasPermission('Cadastro de Pessoas', 'altera')))
                },
                onClick: async ({ row: { data } }) => {
                  try {
                    const dialogResult = await confirm('Tem certeza que deseja desabilitar o acesso desse usuário a API?', 'Confirmar')
                    if (dialogResult) {
                      await http.post(`${baseUrl}/toggle-acesso-api`, { id: data.id })
                      await this.$refs.simpleGrid.refresh()
                    }
                  } catch (e) {
                    notify('Não foi executar esta ação, por favor, contate um administrador do sistema', 'error')
                    console.error(e)
                  }
                }
              },
              {
                name: 'toggleToken', // id único pro botão (não pode ser 'edit' ou 'delete')
                text: 'Habilitar acesso API', // label do botão
                cssClass: 'dx-button mdi mdi-check', // ícone que o botão usa + classe de botão
                visible: ({ row: { data } }) => {
                  return (!data.acessoApi && data.situacao.toUpperCase() === 'ATIVO' && (hasPermission('Cadastro de Pessoas', 'inclui') && hasPermission('Cadastro de Pessoas', 'altera')))
                },
                onClick: async ({ row: { data } }) => {
                  try {
                    const dialogResult = await confirm('Tem certeza que deseja habilitar/desabilitar o acesso desse usuário a API?', 'Confirmar')
                    if (dialogResult) {
                      await http.post(`${baseUrl}/toggle-acesso-api`, { id: data.id })
                      await this.$refs.simpleGrid.refresh()
                    }
                  } catch (e) {
                    notify('Não foi executar esta ação, por favor, contate um administrador do sistema', 'error')
                    console.error(e)
                  }
                }
              },
              {
                name: 'inativar', // id único pro botão (não pode ser 'edit' ou 'delete')
                text: 'Inativar', // label do botão
                cssClass: 'dx-button mdi mdi-check', // ícone que o botão usa + classe de botão
                visible: (result) => {
                  const { row: { data } } = result
                  return (data.transportador === 'N') && data.situacao.toUpperCase() === 'ATIVO' && (hasPermission('Cadastro de Pessoas', 'inclui') && hasPermission('Cadastro de Pessoas', 'altera'))
                },
                onClick: async (result) => {
                  const { row: { data } } = result
                  try {
                    this.setLoading(true)
                    const pessoaPerfis = await this.findOne({
                      entity: 'Pessoa',
                      params: {
                        attributes: ['id'],
                        where: {
                          id: data.id
                        },
                        include: [{
                          model: 'Perfil',
                          as: 'perfis',
                          attributes: ['id']
                        }]
                      }
                    })
                    this.setLoading(false)
                    this.popupInativar = {
                      visible: true,
                      pessoa: pessoaPerfis,
                      justificativaValidacao: ''
                    }
                  } catch (e) {
                    this.setLoading(false)
                    console.error(e)
                  }
                }
              },
              {
                name: 'cltrcc', // id único pro botão (não pode ser 'edit' ou 'delete')
                text: 'CLTRCC', // label do botão
                cssClass: 'dx-button mdi mdi-file', // ícone que o botão usa + classe de botão
                visible: (result) => {
                  const { row: { data } } = result
                  if ((data.transportador === 'S') && data.situacao === 'Ativo') {
                    return (data.dataAprovacao && data.dataValidadeCltrcc)
                  }
                },
                onClick: async ({ row: { data } }) => {
                  try {
                    this.setLoading(true)
                    const perfilTransportador = await this.findOne({
                      entity: 'Perfil',
                      params: {
                        where: {
                          idPessoa: data.id,
                          idTipoPerfil: data.tipoPessoa === 'J' ? 2 : 9
                        },
                        attributes: ['id']
                      }
                    })
                    this.setLoading(false)
                    window.open(`${baseUrl}/cltrcc/${perfilTransportador.id}`)
                  } catch (e) {
                    this.setLoading(false)
                    console.error(e)
                  }
                }
              },
              {
                name: 'autorizar_perfil', // id único pro botão (não pode ser 'edit' ou 'delete')
                text: 'Autorizar Perfil', // label do botão
                cssClass: 'dx-button mdi mdi-account-group', // ícone que o botão usa + classe de botão
                visible: () => {
                  return (hasPermission('Perfil', 'altera'))
                },
                onClick: async (result) => {
                  try {
                    this.setLoading(true)
                    const { row: { data } } = result
                    const pessoaPerfis = await this.findOne({
                      entity: 'Pessoa',
                      params: {
                        attributes: ['id', 'nomeRazaoSocial', 'situacao', 'tipoPessoa'],
                        where: {
                          id: data.id
                        },
                        include: [
                          {
                            model: 'Perfil',
                            as: 'perfis',
                            attributes: ['id', 'situacaoValidacao'],
                            include: [
                              {
                                model: 'TipoPerfil',
                                as: 'tipoPerfil',
                                attributes: ['id', 'descricao']
                              }
                            ]
                          }
                        ]
                      }
                    })
                    this.setLoading(false)
                    this.showPopupPerfis(pessoaPerfis || [])
                  } catch (e) {
                    this.setLoading(false)
                    console.error(e)
                  }
                }
              },
              {
                name: 'autorizar_usuarios', // id único pro botão (não pode ser 'edit' ou 'delete')
                text: 'Autorizar Usuário', // label do botão
                cssClass: 'dx-button mdi mdi-account', // ícone que o botão usa + classe de botão
                visible: (result) => {
                  const { row: { data } } = result
                  return ((data.possuiUsuario === 'S') && hasPermission('Manter Usuário', 'altera'))
                },
                onClick: async (result) => {
                  try {
                    this.setLoading(true)
                    const { row: { data } } = result
                    const pessoaUsuarios = await this.findOne({
                      entity: 'Pessoa',
                      params: {
                        attributes: ['id', 'nomeRazaoSocial', 'situacao', 'cpfCnpj'],
                        where: {
                          id: data.id
                        },
                        include: [
                          {
                            model: 'Usuario',
                            as: 'usuarios',
                            attributes: ['id', 'nome', 'cpf', 'email', 'ativo']
                          }
                        ]
                      }
                    })
                    this.showPopupUsuarios(pessoaUsuarios)
                  } catch (e) {
                    this.setLoading(false)
                    console.error(e)
                  }
                }
              },
              {
                name: 'criar-usuario', // id único pro botão (não pode ser 'edit' ou 'delete')
                text: 'Criar Usuário', // label do botão
                cssClass: 'dx-button mdi mdi-account', // ícone que o botão usa + classe de botão
                visible: ({ row: { data } }) => ((data.possuiUsuario === 'N') && data.tipoPessoa === 'F' && hasPermission('Manter Usuário', 'inclui')),
                onClick: async ({ row: { data } }) => {
                  const dialogResult = await confirm('Tem certeza que deseja criar um novo usuário para esta pessoa?', 'Confirmar')
                  if (dialogResult) {
                    try {
                      const dadosPessoa = await this.findOne({
                        entity: 'Pessoa',
                        params: {
                          attributes: ['id', 'nomeRazaoSocial', 'cpfCnpj', 'codIbge', 'celular', 'email', 'cep', 'endereco', 'bairro', 'complemento', 'numero'],
                          where: {
                            id: data.id
                          },
                          include: [
                            {
                              model: 'Perfil',
                              as: 'perfis',
                              attributes: ['id']
                            }
                          ]
                        }
                      })

                      const dadosUsuario = {
                        nome: dadosPessoa.nomeRazaoSocial,
                        idPessoa: dadosPessoa.id,
                        emailValidado: 'S',
                        ativo: 'S',
                        excluido: 'N',
                        cpf: dadosPessoa.cpfCnpj,
                        codIbge: dadosPessoa.codIbge,
                        celular: dadosPessoa.celular,
                        email: dadosPessoa.email,
                        cep: dadosPessoa.cep,
                        endereco: dadosPessoa.endereco,
                        bairro: dadosPessoa.bairro,
                        complemento: dadosPessoa.complemento,
                        numero: dadosPessoa.numero
                      }
                      const { data: usuario } = await http.post(`${baseUrl}/Usuario`, dadosUsuario)
                      for (const perfil of dadosPessoa.perfis) {
                        await http.post(`${baseUrl}/UsuarioPerfil`, {
                          idUsuario: usuario.data.id,
                          idPerfil: perfil.id
                        })
                      }
                      await this.$refs.simpleGrid.refresh()
                      notify('Usuário criado com sucesso', 'success')
                    } catch (err) {
                      notify('Não foi possível criar este usuário, por favor, contate um administrador do sistema', 'error')
                      console.error(err)
                    }
                  }
                }
              },
              {
                name: 'Visualizar', // id único pro botão (não pode ser 'edit' ou 'delete')
                text: 'visualizar', // label do botão
                cssClass: 'dx-button mdi mdi-eye', // ícone que o botão usa + classe de botão
                visible: () => {
                  return (hasPermission('Cadastro de Pessoas', 'consulta') && !hasPermission('Cadastro de Pessoas', 'altera'))
                },
                onClick: async ({ row: { data } }) => {
                  await this.$router.push({ path: `/pessoa-view/${data.id}` })
                }
              },
              {
                name: 'EDITAR', // id único pro botão (não pode ser 'edit' ou 'delete')
                text: 'EDITAR', // label do botão
                cssClass: 'dx-button dx-button-success dx-button-mode-contained mdi mdi-pencil', // ícone que o botão usa + classe de botão
                visible: () => {
                  return (hasPermission('Cadastro de Pessoas', 'altera'))
                },
                onClick: async ({ row: { data } }) => {
                  await this.$router.push({ path: `/pessoa-edit/${data.id}` })
                }
              }
            ]
          }
        ]),
        dataSource: datasourcePessoa
      },
      loading: false
    }
  },
  methods: {
    popupHeightValidacaoCadastro () {
      return window.innerWidth < 600 ? '100%' : '80%'
    },
    popupHeight () {
      return window.innerWidth < 600 ? '100%' : 'auto'
    },
    popupWidth () {
      return window.innerWidth < 600 ? 'auto' : '80%'
    },
    ...mapActions('Crud', ['findOne', 'find']),
    ...mapActions('Login', ['recoveryAction']),
    async refreshGrid (reloadData = false) {
      if (reloadData) {
        await this.gridData.dataSource.load()
      }
      await this.$refs.simpleGrid.refresh()
    },
    async applyFilterValueAll () {
      const setOfTypes = {
        ALL: () => ({}),
        USUARIOS_PENDENTES: () => ({
          usuarioPendentes: 'S'
        }),
        PERFIS_PENDENTES: () => ({
          perfilPendente: 'S'
        })
      }
      if (setOfTypes[this.filterValueAll]) {
        try {
          this.filterConfigAll = (setOfTypes[this.filterValueAll])()
          await this.refreshGrid()
        } catch (error) {
          console.error(error)
        }
      }
    },
    showPopupUsuarios (pessoa = {}) {
      if (pessoa.usuarios instanceof Array) {
        this.popupValidacaoUsuarios = {
          visible: true,
          pessoa
        }
      } else {
        this.popupValidacaoUsuarios = {
          visible: false,
          pessoa: null
        }
      }
    },
    showPopupPerfis (pessoa) {
      if (pessoa.perfis instanceof Array) {
        this.popupValidacaoPerfis = {
          visible: true,
          perfis: pessoa.perfis,
          pessoa
        }
      } else {
        this.popupValidacaoPerfis = {
          visible: false,
          perfis: null,
          pessoa: null
        }
      }
    },
    async toggleSituacao (id, entity = 'Pessoa', values, notifyError = true, arquivos, perfilPessoa) {
      const perfilValues = this.popupValidacao.justificativaValidacao ? { justificativaValidacao: this.popupValidacao.justificativaValidacao } : {}
      const pessoaValues = values
      let arquivosInvalid

      const ignoredFiles = []
      if (Array.isArray(arquivos) && arquivos.length) {
        /**
         * Remove arquivos ignorados do array para enviar para o backend
         */
        arquivos.forEach((arquivo, index) => {
          if (arquivo.ignoreFile) {
            arquivos.splice(index, 1)
            ignoredFiles.push(arquivo)
          }
        })
        /**
         * Todos documentos não validados
         */
        if (arquivos.every(arquivo => arquivo.situacao === 'N')) {
          pessoaValues.situacao = 'Aguardando validação'
          perfilValues.situacaoValidacao = 'P'
        }
        // Remove condições que mudava a "situacao" para "Pendente" e "situacaoValidacao" para "P", pois não deve mudar a situação da pessoa para pendente mesmo com um ou mais documentos pendentes
        // no backend a um hook em "Perfil" que verifica se "situacaoValidacao" = "P" se for muda a "situacao" da pessoa para "Pendente"
        /**
         * Algum documento validado e os demais não validados
         */
        // if (arquivos.find(arquivo => arquivo.situacao === 'V') && arquivos.find(arquivo => arquivo.situacao === 'N')) {
        //   pessoaValues.situacao = 'Pendente'
        //   perfilValues.situacaoValidacao = 'P'
        // }
        // /**
        //  * Um documento pendente
        //  */
        // if (arquivos.find(arquivo => arquivo.situacao === 'P')) {
        //   pessoaValues.situacao = 'Pendente'
        //   perfilValues.situacaoValidacao = 'P'
        // }

        /**
         * Todos documentos validados
         */
        if (arquivos.every(arquivo => arquivo.situacao === 'V')) {
          pessoaValues.situacao = 'Ativo'
          perfilValues.situacaoValidacao = 'A'
          perfilValues.dataAprovacao = new Date().toISOString()
          perfilValues.dataValidadeCltrcc = moment(perfilValues.data).add(1, 'year').toDate().toISOString()
          perfilValues.justificativaValidacao = null
        }
      }
      if (!arquivosInvalid) {
        try {
          if (Array.isArray(arquivos) && arquivos.length) {
            for (const arquivo of arquivos) {
              if (!arquivo.noFile) {
                await this.$http.put(`${baseUrl}/Arquivo/${arquivo.id}`, {
                  situacao: arquivo.situacao, descricaoPendencia: arquivo.descricaoPendencia, idUsuario: this.userData.id
                })
              }
            }
            for (const ignoredArquivo of ignoredFiles) {
              await this.$http.put(`${baseUrl}/Arquivo/${ignoredArquivo.id}`, {
                situacao: ignoredArquivo.situacao, descricaoPendencia: ignoredArquivo.descricaoPendencia, idUsuario: this.userData.id
              })
            }
          }
          /**
           * Pessoa
           */
          await this.$http.put(`${baseUrl}/${entity}/${id}`, pessoaValues)
          /**
           * Perfil
           */
          if (Object.entries(perfilValues).length) {
            await this.$http.put(`${baseUrl}/Perfil/${perfilPessoa.id}`, perfilValues)
          }
          this.resetPopupValidacao()
        } catch (error) {
          console.error(error)
          if (notifyError) {
            notify('Não foi possível mudar a situação desta pessoa', 'error')
          }
          throw error
        }
      }
      this.$refs.simpleGrid.$refs.gridInstance.instance.getDataSource().reload()
    },
    async salvarPopupValidacao () {
      try {
        const { situacao, idPessoa, justificativaValidacao, perfilTransportador, perfilPessoa } = this.popupValidacao
        await this.toggleSituacao(
          idPessoa,
          'Pessoa',
          {
            situacao: situacao,
            justificativaValidacao
          },
          true,
          perfilTransportador && perfilTransportador.arquivos ? perfilTransportador.arquivos : null,
          perfilPessoa
        )
      } catch (error) {
        console.error(error)
        let message = 'Não foi possível validar esta pessoa'
        if (error instanceof CustomError) {
          message = error.message
        }
        notify(message, 'error')
      }
    },
    resetPopupValidacao () {
      this.popupValidacao = {
        visible: false,
        idPessoa: null,
        situacao: null,
        justificativaValidacao: null,
        perfilTransportador: null
      }
    },
    async downloadAnexo (entity, id, attribute) {
      const { data: file } = await http.get(`${baseUrl}/Arquivo/${id}`)
      const fileBuffer = new Uint8Array(file.data.anexo.data)
      const blob = new Blob([fileBuffer], { type: file.data.contentType })
      const fileName = file.data.nomeArquivo
      const link = document.createElement('a')
      // Browsers that support HTML5 download attribute
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', fileName)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    },
    showJustificativa () {
      if ((this.popupValidacao.perfilTransportador && Array.isArray(this.popupValidacao.perfilTransportador.arquivos) && this.popupValidacao.perfilTransportador.arquivos.find(arquivo => arquivo.situacao === 'P')) || this.popupValidacao.situacao === 'Inativo') {
        return true
      } else {
        this.popupValidacao.justificativaValidacao = null
        return false
      }
    },
    onArquivoSituacaoChanged (value, arquivo) {
      if (arquivo.situacao !== 'P') {
        arquivo.descricaoPendencia = null
      }
    },
    verifyValidadeDocumento (date) {
      if (date.getTime) return date.getTime() <= new Date().getTime()
    },
    async salvarPopupInativar () {
      try {
        this.setLoading(true)
        await this.$http.put(`${baseUrl}/Pessoa/${this.popupInativar.pessoa.id}`, { situacao: 'Inativo' })
        if (Array.isArray(this.popupInativar.pessoa.perfis)) {
          for (const perfil of this.popupInativar.pessoa.perfis) {
            await this.$http.put(`${baseUrl}/Perfil/${perfil.id}`, {
              justificativaValidacao: this.popupInativar.justificativaValidacao, situacaoValidacao: 'I'
            })
          }
        }
        await this.resetPopupValidacao()
        this.popupInativar = {
          visible: false,
          pessoa: null,
          justificativaValidacao: null
        }
        this.setLoading(false)
        await this.$refs.simpleGrid.refresh()
      } catch (e) {
        this.setLoading(false)
        console.error(e)
      }
    },
    async onChangeAttachment ({ value }, arquivo) {
      if (value.length) {
        const formData = new FormData()
        formData.append('idPerfil', this.popupValidacao.perfilTransportador.id)
        formData.append('situacao', 'N')
        formData.append('descricao', '')
        formData.append('idUsuario', this.userData.id)
        formData.append('idTipoArquivo', arquivo.tipoArquivo.id)
        formData.append('anexo', value[0], value[0])
        formData.append('contentType', value[0].type)
        formData.append('nomeArquivo', value[0].name)

        const { data: file } = await http.post(`${baseUrl}/Arquivo`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        const index = this.popupValidacao.perfilTransportador.arquivos.indexOf(this.popupValidacao.perfilTransportador.arquivos.find(file => file.id === arquivo.id))
        const { data: foundFile } = await http.get(`${baseUrl}/Arquivo/${file.data.id}`, {
          params: {
            attributes: ['id', 'validade']
          }
        })

        this.popupValidacao.perfilTransportador.arquivos[index].validade = foundFile.data.validade
        this.popupValidacao.perfilTransportador.arquivos[index].descricao = file.data.descricao
        this.popupValidacao.perfilTransportador.arquivos[index].descricaoPendencia = file.data.descricaoPendencia
        this.popupValidacao.perfilTransportador.arquivos[index].id = file.data.id
        this.popupValidacao.perfilTransportador.arquivos[index].nomeArquivo = file.data.nomeArquivo
        this.popupValidacao.perfilTransportador.arquivos[index].situacao = file.data.situacao
        this.popupValidacao.perfilTransportador.arquivos[index].tipoArquivo = {
          id: arquivo.tipoArquivo.id,
          descricao: arquivo.tipoArquivo.descricao
        }
        delete this.popupValidacao.perfilTransportador.arquivos[index].noFile

        await this.refreshGrid()
      }
    },
    getEditPath ({ data }) {
      return `/pessoa-update/${data.id}`
    },
    async updateUsuario (id, value) {
      try {
        this.setLoading(true)
        await this.$http.put(this.$http.normalizeUrl(`Usuario/${id}`), {
          ...value
        })
        this.resetPopupValidacao()
        this.setLoading(false)
        this.$refs.simpleGrid.$refs.gridInstance.instance.getDataSource().reload()
      } catch (e) {
        this.setLoading(false)
        return e
      }
    },
    async updatePerfil (id, value) {
      try {
        this.setLoading(true)
        await this.$http.put(this.$http.normalizeUrl(`Perfil/${id}`), {
          ...value
        })
        this.resetPopupValidacao()
        this.setLoading(false)
        this.$refs.simpleGrid.$refs.gridInstance.instance.getDataSource().reload()
      } catch (e) {
        this.setLoading(false)
        return e
      }
    }
  }
}
</script>
