<template>
  <div class="list-usuarios">
    <div class="md-layout">
      <div class="md-layout-item">
        <h2 class="crud-title">Cadastro de Usuário</h2>
      </div>

      <div class="md-layout-item" style="text-align: right;">
        <dx-button type="success" @click="$router.push({ path: 'usuario/add' })">Cadastrar Novo Usuário</dx-button>
      </div>
    </div>
    <simple-grid
      entity="Usuario"
      ref="simpleGrid"
      :grid-data="gridData"
      :deletable="isSecretaria || isAdministrador"
    />
    <dx-popup
      :hide-on-outside-click="true"
      :drag-enabled="false"
      height="auto"
      :width="500"
      :show-title="true"
      title="Validação do cadastro do usuário"
      :visible.sync="popupValidacao.visible">
      <template v-slot:content>
        <div>
          <div style="margin-bottom: 10px;">Usuário: <b>{{popupValidacao.nomeRazaoSocial}}</b></div>
          <div>
            <label>Validação do cadastro(dados/documentos): </label>
            <dx-select-box
              v-model="popupValidacao.situacao"
              :items="['Ativo', 'Inativo', 'Pendente']"
              placeholder="Situação"
            />
          </div>
          <br />
          <div v-if="['Inativo', 'Pendente'].includes(popupValidacao.situacao)">
            <label>Justifique</label>
            <dx-text-area v-model="popupValidacao.justificacao" :width="'100%'" />
            <br />
          </div>
          <div class="popup-validacao-buttons">
            <dx-button type="success" @click="salvarPopupValidacao">Salvar</dx-button>
            <dx-button type="normal" @click="resetPopupValidacao">Voltar</dx-button>
          </div>
        </div>
      </template>
    </dx-popup>
  </div>
</template>
<style lang="scss">
.popup-validacao-buttons{
  > div{
    margin-right: 10px;
  }
}
</style>

<script>
import CustomStore from 'devextreme/data/custom_store'
import { DxButton, DxPopup, DxSelectBox, DxTextArea } from 'devextreme-vue'
import { SimpleGrid } from '@/components'
import mixinCrud from '@/pages/Cruds/mixin-crud'
import notify from 'devextreme/ui/notify'

export default {
  name: 'list-usuario',
  mixins: [mixinCrud],
  components: {
    SimpleGrid,
    DxButton,
    DxPopup,
    DxSelectBox,
    DxTextArea
  },
  computed: {
    /**
     * Valor computado para verificar se o usuario logado e uma secretaria.
     * @returns {Boolean}
     */
    isSecretaria () {
      return this.verifyProfile('Secretaria')
    },
    isAdministrador () {
      return this.verifyProfile('Administrador')
    }
  },
  data () {
    const datsourceUsuario = new CustomStore({
      key: 'id',
      load: () => {
        return this.$utils.wrapRequestForGrid(this.find({
          entity: this.entity,
          params: {
            include: JSON.stringify([
              {
                model: 'Pessoa',
                as: 'pessoa'
              },
              {
                model: 'UsuarioPerfil',
                as: 'usuarioPerfis',
                include: [
                  {
                    required: true,
                    model: 'Perfil',
                    as: 'perfil',
                    include: [
                      {
                        required: true,
                        model: 'TipoPerfil',
                        as: 'tipoPerfil'
                      }
                    ]
                  }
                ]
              }
            ])
          }
        }))
      },
      insert: (values) => this.insertRow({ entity: this.entity, values }),
      update: (key, values) => this.updateRow({ entity: this.entity, values, key }),
      remove: (key, values) => this.removeRow({ entity: this.entity, key })
    })

    const datsourcePerfil = new CustomStore({
      key: 'id',
      load: () => {
        return this.$utils.wrapRequestForGrid(this.find({
          entity: 'Perfil',
          params: {
            include: JSON.stringify([
              {
                required: true,
                model: 'TipoPerfil',
                as: 'tipoPerfil'
              }
            ])
          }
        }))
      },
      byKey: async (id) => {
        const res = await this.find({
          entity: 'Perfil',
          params: {
            where: JSON.stringify({
              id
            }),
            include: JSON.stringify([
              {
                required: true,
                model: 'TipoPerfil',
                as: 'tipoPerfil'
              }
            ])
          }
        })
        return res.count > 0 ? res.rows[0].id : null
      }
    })

    return {
      entity: 'Usuario',
      formData: null,
      popupValidacao: {
        visible: false,
        idPessoa: null,
        nomeRazaoSocial: null,
        situacao: 'Pendente',
        justificacao: null
      },
      gridData: {
        value: {
          visible: true
        },
        columns: this.parseColumns([
          {
            caption: 'Perfil',
            calculateDisplayValue: (data) => {
              return (data.usuarioPerfis || []).map(({ perfil }) => perfil.tipoPerfil.descricao).join(', ')
            },
            // calculateFilterExpression: (perfil, operation) => {
            //   return ['id', operation, perfil.id]
            // },
            dataField: 'perfil.id',
            editorType: 'dxLookup',
            editorOptions: {
              // allowClearing: true,
              // showClearButton: true,
              // showCancelButton: false,
              displayExpr: 'tipoPerfil.descricao',
              valueExpr: 'id',
              dataSource: datsourcePerfil
            }
          },
          {
            dataField: 'pessoa.nomeRazaoSocial',
            caption: 'Nome'
          },
          {
            calculateDisplayValue: ({ pessoa }) => {
              return pessoa.tipoPessoa === 'J'
                ? 'Pessoa Jurídica'
                : 'Pessoa Física'
            },
            caption: 'Tipo Pessoa',
            editorType: 'dxLookup'
          },
          {
            calculateDisplayValue: ({ pessoa }) => {
              return pessoa.tipoPessoa === 'J'
                ? this.$utils.formatarCnpj(pessoa.cpfCnpj)
                : this.$utils.formatarCpf(pessoa.cpfCnpj)
            },
            caption: 'CPF / CNPJ'
          },
          {
            dataField: 'pessoa.situacao',
            caption: 'Situação',
            editorType: 'dxLookup'
          },
          {
            type: 'buttons',
            showInColumnChooser: false,
            buttons: [
              {
                name: 'validar', // id único pro botão (não pode ser 'edit' ou 'delete')
                text: 'Validar', // label do botão
                cssClass: 'dx-button mdi mdi-check', // ícone que o botão usa + classe de botão
                visible: (result) => {
                  const { row: { data } } = result
                  return /valida/i.test(data.pessoa.situacao)
                },
                onClick: async (result) => {
                  const { row: { data } } = result
                  this.popupValidacao = {
                    visible: true,
                    idPessoa: data.pessoa.id,
                    nomeRazaoSocial: data.pessoa.nomeRazaoSocial
                  }
                }
              },
              {
                name: 'ativar', // id único pro botão (não pode ser 'edit' ou 'delete')
                text: 'Ativar', // label do botão
                cssClass: 'dx-button mdi mdi-check', // ícone que o botão usa + classe de botão
                visible: (result) => {
                  const { row: { data } } = result
                  return data.pessoa.situacao.toUpperCase() === 'INATIVO'
                },
                onClick: async (result) => {
                  const { row: { data } } = result
                  await this.toggleSituacao(data.pessoa.id, 'Ativo')
                }
              },
              {
                name: 'inativar', // id único pro botão (não pode ser 'edit' ou 'delete')
                text: 'Inativar', // label do botão
                cssClass: 'dx-button mdi mdi-check', // ícone que o botão usa + classe de botão
                visible: (result) => {
                  const { row: { data } } = result
                  return data.pessoa.situacao.toUpperCase() === 'ATIVO'
                },
                onClick: async (result) => {
                  const { row: { data } } = result
                  await this.toggleSituacao(data.pessoa.id, 'Inativo')
                }
              }
            ]
          }
        ]),
        dataSource: datsourceUsuario
      }
    }
  },
  methods: {
    async toggleSituacao (idPessoa, situacao, notifyError = true) {
      try {
        await this.updateRow({
          entity: 'Pessoa',
          key: idPessoa,
          values: {
            situacao
          }
        })
      } catch (error) {
        if (notifyError) {
          notify('Não foi possível mudar a situação desta pessoa', 'error')
        }
        throw error
      }
      this.$refs.simpleGrid.$refs.gridInstance.instance.getDataSource().reload()
    },
    async salvarPopupValidacao () {
      try {
        const { situacao, idPessoa, justificacao } = this.popupValidacao
        if (!situacao) {
          throw new CustomError('Escolha uma opção como será validado esta pessoa')
        }
        if (!justificacao && ['Inativo', 'Pendente'].includes(situacao)) {
          throw new CustomError('Justifique')
        }
        await this.toggleSituacao(
          idPessoa,
          situacao === 'Pendente' ? 'Aguardando validação' : situacao,
          false
        )
        this.resetPopupValidacao()
      } catch (error) {
        let message = 'Não foi possível validar esta pessoa'
        if (error instanceof CustomError) {
          message = error.message
        }
        notify(message, 'error')
      }
    },
    resetPopupValidacao () {
      this.popupValidacao = {
        visible: false,
        idPessoa: null,
        situacao: null,
        justificacao: null
      }
    }
  }
}
</script>
