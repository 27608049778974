var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"md-layout md-gutter md-alignment-center-center",staticStyle:{"height":"100vh !important"}},[_c('md-card',{staticClass:"md-layout-item md-size-25 md-medium-size-25 md-small-size-80 md-elevation-3",staticStyle:{"padding":"16px"}},[_c('md-card-header',{staticStyle:{"padding":"0 !important"}},[_c('md-card-header-text',[_c('div',{staticClass:"md-layout md-gutter md-alignment-center-center"},[_c('img',{attrs:{"id":"slu_logo","src":"slu_logo.jpg","width":"250px"}})]),_c('div',{staticStyle:{"padding-left":"16px"}},[_c('div',{staticStyle:{"margin":"10px 0","font-weight":"bold","font-family":"Roboto","font-size":"1rem","font-style":"italic"}},[_vm._v("Destino Rápido")]),_c('div',{staticClass:"md-subhead",staticStyle:{"font-style":"italic","font-size":"0.9rem","margin-bottom":"1.5rem"}},[_vm._v(" Acesso rápido para pesagem na URE ")])])])],1),_c('md-card-content',[_c('form',{on:{"submit":_setup.onSubmit}},[_c(_setup.DxForm,{attrs:{"form-data":_setup.formData,"label-location":"top","show-validation-summary":true}},[_c(_setup.DxSimpleItem,{attrs:{"data-field":"placa","editor-type":"dxTextBox","editor-options":{
              stylingMode:'underlined',
              mask:'LLL-0A00',
              onKeyUp: _setup.onKeyUpPlaca,
            },"label":{ text: 'Placa do veículo' },"validation-rules":[{ type: 'required', message: 'placa é obrigatoria' },]}}),_c(_setup.DxSimpleItem,{attrs:{"data-field":"token","editor-type":"dxTextBox","editor-options":{
              stylingMode:'underlined',
            },"label":{ text: 'Token de acesso' },"validation-rules":[{ type: 'required', message: 'token é obrigatorio' }, { type: 'stringLength', max: 6 }]}}),_c(_setup.DxButtonItem,{attrs:{"horizontal-alignment":"right","button-options":{ text: 'Entrar', useSubmitBehavior: true },"cssClass":"btn-submit"}})],1)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }