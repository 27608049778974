<template>
  <div>
    <router-view>
      <h1>RCC</h1>
    </router-view>
  </div>
</template>

<script>
export default {
  name: 'home'
}
</script>
