export default {
  props: {
    /**
     * O rotulo do campo.
     */
    label: {
      type: String
    },

    /**
     * O valor atual do campo, essa prop e atualizada em tempo real.
     */
    value: {
      type: [String, Number, Object]
    },

    /**
     * As opcoes do campo do devextreme.
     */
    options: {
      type: Object,
      required: true
    },

    /**
     * Se o campo e obrigatorio.
     */
    required: {
      type: Boolean
    }
  },
  computed: {
    /**
     * Valor computado do value do campo.
     */
    innerValue: {
      get () {
        if (this.type === 'number') {
          const value = parseFloat(this.value || null)
          return isNaN(value) ? null : value
        }
        return this.value || null
      },
      set (v) {
        if (this.required) {
          this.options.isValid = Boolean(typeof v === 'string'
            ? v && v.length > 0
            : !isNaN(v))
        } else {
          this.options.isValid = false
        }

        this.$emit('input', v)
      }
    }
  },
  watch: {
    /**
     * Metodo de callback de quando e alterado o valor da prop 'required', com o
     * objetivo de verificar se o campo esta valido.
     * @param {boolean} required - O valor atual da prop.
     */
    required (required) {
      this.options.isValid = Boolean(!required || this.value)
    }
  }
}
