/**
 * Classe responsavel por parsear os dados que vem do server para o modelo da
 * tela.
 */
export default class GetSolicitacaoToAvaliarDto {
  constructor (solicitacao) {
    this.id = solicitacao.id
    this.dataHoraSolicitacao = solicitacao.dataHoraSolicitacao
    this.obra = solicitacao.obra
    this.transportador = solicitacao.transportador
    this.tipoResiduoPredominante = solicitacao.residuoPredominante
    this.veiculo = solicitacao.veiculo
    this.idVeiculo = solicitacao.idVeiculo
    this.cacamba = solicitacao.cacamba
    this.idCacamba = solicitacao.idCacamba
    this.observacao = solicitacao.observacao
    this.tipo = solicitacao.veiculo ? solicitacao.veiculo.tipo : null
    this.poliguindaste = solicitacao.veiculo
      ? solicitacao.veiculo.poliguindaste
      : null
    this.residuos = solicitacao.residuos.map(residuo => ({
      idTipoResiduo: residuo.idTipoResiduo,
      volume: residuo.volumeAproximadoM3,
      tipoResiduo: residuo.tipoResiduo
    }))
  }
}
