<template>
  <div class="fields-destino-final-veiculo flex">
    <model-select
      :value="value.destinoFinal"
      class="flex-col-6"
      :get-list-request="_doDestinoFinalRequest"
      label="Destino Final"
      label-prop="nomeRazaoSocial"
      @input="onChangeDestinoFinal"/>
    <model-select
      ref="veiculoPlacaRef"
      :value="value.veiculo"
      class="flex-col-6"
      :get-list-request="_doAvaliableVeiculosRequest"
      label="Veículo (Placa)"
      label-prop="placa"
      @input="onChangeVeiculo"/>
    <text-field
      :value="value.tipo"
      class="flex-col-6"
      label="Tipo"
      :options="fieldsOptions.tipo"/>
    <text-field
      :value="value.poliguindaste"
      class="flex-col-6"
      label="Poliguindaste"
      :options="fieldsOptions.poliguindaste"/>
  </div>
</template>
<script>
import { getDestinosFinais } from '../service'
import { getAvailableVeiculos } from '@/components/SolicitacaoTransporte/service'
import ModelSelect from '../../../../components/Form/ModelSelect'
import TextField from '../../../../components/Form/TextField'

export default {
  name: 'fields-destino-final-veiculo',
  components: {
    ModelSelect,
    TextField
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  /**
   * Metodo do vue, para obter o estado inicial do componente.
   * @returns {Object} O estado inicial do componente.
   */
  data () {
    return {
      fieldsOptions: {
        tipo: {
          readOnly: true
        },
        poliguindaste: {
          readOnly: true
        }
      }
    }
  },
  methods: {
    onChangeDestinoFinal (value) {
      this.$emit('input', {
        ...this.value,
        destinoFinal: value
      })
    },

    onChangeVeiculo (value) {
      if (value) {
        this.$emit('input', {
          ...this.value,
          veiculo: value,
          tipo: value.tipo,
          poliguindaste: value.poliguindaste
        })
      }
    },

    /**
     * Metodo para buscar a lista de cacambas disponiveis para uma solicitacao
     * do servidor para o combo de cacambas.
     * @returns {Object[]} A lista de cacambas.
     */
    _doDestinoFinalRequest (params) {
      return getDestinosFinais(params)
    },

    /**
     * Metodo para buscar a lista de veiculos disponiveis para uma solicitacao
     * do servidor para o combo de veiculos.
     * @returns {Object[]} A lista de veiculos.
     */
    _doAvaliableVeiculosRequest (params) {
      if (this.value.veiculo && this.value.veiculo.tipo) {
        params.where = {
          tipo: this.value.veiculo.tipo
        }
      }
      return getAvailableVeiculos(params)
    }
  }
}
</script>

<style lang="scss">
@import '../../../../global.scss';

.fields-destino-final-veiculo {
  margin-bottom: 20px;
  flex-wrap: wrap;
}
</style>
