<template>
  <div class="generic-crud">
    <div v-if="action && (canAdd || canEdit || canView)" class="generic-crud-form">
      <h2 v-if="action === 'add'">Incluir Tipo de  Resíduo</h2>
      <h2 v-else-if="canEdit">Editar Tipo de Resíduo</h2>
      <h2 v-else>Visualizar Tipo de Resíduo</h2>
      <fieldset>
        <legend v-if="action === 'add'">Tipo de Resíduo</legend>
        <legend v-else>Tipo de Resíduo</legend>
        <simple-form
          v-model="formData"
          id="group"
          :fields="gridData.columns"
          :form-options="formOptions"
          ref="crudForm"/>

        <dx-button
          v-if="!isReadOnly"
          text="Salvar"
          type="success"
          @click="saveItem"/>
      </fieldset>
    </div>

    <div v-else>
      <div class="md-layout">
        <div class="md-layout-item">
          <h2 class="crud-title">Cadastro de Tipos de Resíduos</h2>
        </div>
        <div v-if="canAdd"
          class="md-layout-item"
          style="text-align: right;">
          <dx-button type="success" @click="$router.push({ path: 'tipo-residuo/add' })" text="Adicionar Novo"></dx-button>
        </div>
      </div>
      <simple-grid
        :deletable="canDelete"
        :editable="canEdit"
        entity="TipoResiduo"
        :grid-data="gridData"
        :viewable="canView && !canEdit"
      />
    </div>
  </div>
</template>
<script>
import { SimpleForm, SimpleGrid } from '@/components'
import CustomStore from 'devextreme/data/custom_store'
import mixinCrud from '../mixin-crud'
import { DxButton } from 'devextreme-vue'
import { hasPermission } from '@/util/permissions'

export default {
  name: 'tipo-residuo-crud',
  mixins: [
    mixinCrud
  ],
  components: {
    SimpleGrid,
    SimpleForm,
    DxButton
  },
  data () {
    return {
      entity: 'TipoResiduo',
      permissionName: 'Manter Tipo de Resíduo',
      formData: null,
      gridData: {
        value: {
          visible: true
        },
        columns: this.parseColumns([
          {
            dataField: 'nome',
            caption: 'Material - Tipo de Resíduo',
            editorOptions: { maxLength: 100 },
            validationRules: [{ type: 'required' }]
          },
          {
            dataField: 'descricao',
            caption: 'Descrição',
            editorOptions: { maxLength: 500 },
            validationRules: [{ type: 'required' }]
          },
          {
            dataField: 'idClasseMaterial',
            caption: 'Classe de Material',
            validationRules: [{ type: 'required' }],
            editorType: 'dxLookup',
            editorOptions: {
              allowClearing: true,
              showClearButton: true,
              showCancelButton: false,
              dataSource: new CustomStore({
                key: 'id',
                load: (loadOptions) => {
                  return this.$utils.wrapRequestForGrid(this.find({
                    entity: 'ClasseMaterial',
                    loadOptions
                  }))
                },
                byKey: key => {
                  return this.findId({
                    entity: 'ClasseMaterial',
                    id: key
                  })
                }
              }),
              displayExpr: 'classe',
              valueExpr: 'id'
            },
            calculateDisplayValue: 'classeMaterial.classe'
          }
        ]),
        dataSource: new CustomStore({
          key: 'id',
          load: (loadOptions) => this.$utils.wrapRequestForGrid(this.find({
            entity: this.entity,
            params: {
              include: JSON.stringify([
                {
                  model: 'ClasseMaterial',
                  as: 'classeMaterial',
                  required: true
                }
              ])
            },
            loadOptions
          })),
          insert: (values) => this.insertRow({ entity: this.entity, values }),
          update: (key, values) => this.updateRow({ entity: this.entity, values, key }),
          remove: (key, values) => this.removeRow({ entity: this.entity, key })
        }),
        onEditingStart: () => {
          this.isEditingData = true
        }
      },
      isEditingData: false
    }
  },
  computed: {
    canAdd () {
      return hasPermission('Manter Tipo de Resíduo', 'inclui')
    },

    canEdit () {
      return hasPermission('Manter Tipo de Resíduo', 'altera')
    },

    canDelete () {
      return hasPermission('Manter Tipo de Resíduo', 'exclui')
    },

    canView () {
      return hasPermission('Manter Tipo de Resíduo', 'consulta')
    },
    isReadOnly () {
      return (!this.canAdd && this.action === 'add') ||
        (!this.canEdit && this.action === 'edit')
    }
  }
}
</script>

<style scoped lang="scss">
</style>
