import ListPerfis from './List/ListPerfis.vue'
import FormAtribuicaoPerfil from './Form/FormAtribuicaoPerfil.vue'

export default [
  {
    path: '/atribuicao-perfil',
    name: 'atribuicao-perfil',
    component: ListPerfis,
    meta: {
      icon: 'mdi mdi-truck',
      title: 'Gerenciar permissões',
      requiresAuth: true,
      permissionType: 'consulta'
    },
    props: true
  },
  {
    path: '/atribuicao-perfil/:id',
    name: 'atribuicao-perfil-update',
    component: FormAtribuicaoPerfil,
    meta: {
      requiresAuth: true,
      title: 'Gerenciar permissões',
      permissionType: 'consulta'
    }
  }
]
