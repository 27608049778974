import Tutorial from '@/pages/Cruds/Tutorial/Tutorial.vue'

export default [
  {
    path: '/tutorial',
    name: 'tutorial',
    component: Tutorial,
    meta: {
      icon: 'mdi mdi-account-question',
      title: 'Tutorial',
      requiresAuth: true,
      permissionType: 'consulta'
    },
    props: true
  }
]
