import { doRequest } from '@/pluggables/http'

/**
 * Metodo de comando, com o objetivo de realizar a requisicao de buscar uma
 * pessoa apartir do Cpf ou Cnpj.
 * @param {string} type - Se sera feita a busca pelo 'CPF' ou pelo 'CNPJ'.
 * @returns {Object} O resultado da requisicao.
 */
export function getPessoaByCpfCnpj (type, value) {
  return doRequest({
    method: 'post',
    url: 'Pessoa/find',
    params: {
      where: {
        cpfCnpj: value,
        tipoPessoa: type === 'CPF' ? 'F' : 'J'
      },
      include: [{
        model: 'Perfil',
        as: 'perfis',
        required: true,
        include: [
          {
            model: 'TipoPerfil',
            as: 'tipoPerfil'
          }
        ]
      }]
    }
  })
}

/**
 * Metodo de comando, com o objetivo de fazer a requisicao de listar todas as
 * classes de materias para o servidor.
 * @param {Object} params - Os parametros da requisicao.
 * @returns {Object} O resultado da requisicao.
 */
export function listClassesMateriais (params) {
  return doRequest({
    method: 'post',
    url: 'ClasseMaterial/find',
    params: {
      subQuery: false,
      where: params.where && params.where.nome ? {
        or: [
          { '$tipoResiduos.nome$': params.where.nome },
          { classe: params.where.nome }
        ]
      } : params.where,
      include: {
        model: 'TipoResiduo',
        as: 'tipoResiduos',
        required: true
      }
    }
  })
}
