import ListUsuario from './List/ListUsuario.vue'
import FormUsuario from './List/FormUsuario.vue'

export default [
  {
    path: '/usuario',
    name: 'usuario',
    component: ListUsuario,
    meta: {
      icon: 'mdi mdi-account-circle',
      title: 'Cadastro de Usuário',
      requiresAuth: true,
      userType: ['Secretaria', 'Administrador']
    },
    props: true
  },
  {
    path: '/usuario/:action/:id?',
    name: 'usuario-add-edit',
    component: FormUsuario,
    meta: {
      title: 'Formulário Usuário',
      requiresAuth: true,
      hideFromMenu: true,
      userType: ['ALL']
    },
    props: true
  }
]
