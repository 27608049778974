import AlocarCacambaList from './AlocarCacambaList.vue'
import AlocarCacambaForm from './AlocarCacambaForm.vue'

export default [
  {
    path: '/alocar-cacamba',
    name: 'alocar-cacamba',
    component: AlocarCacambaList,
    meta: {
      icon: 'mdi mdi-truck-delivery',
      title: 'Alocar Caçamba',
      requiresAuth: true,
      permissionType: 'consulta'
    },
    props: true
  },
  {
    path: '/alocar-cacamba/:action/:id?',
    name: 'alocar-cacamba-add',
    component: AlocarCacambaForm,
    meta: {
      title: 'Alocar Caçamba',
      requiresAuth: true,
      permissionType: 'consulta'
    },
    props: true
  }
]
