import ListAvaliarRecebimentoSolicitacao from './ListAvaliarRecebimentoSolicitacao.vue'

export default [
  {
    path: '/avaliar-recebimento-solicitacao',
    name: 'avaliar-recebimento-solicitacao',
    component: ListAvaliarRecebimentoSolicitacao,
    meta: {
      requiresAuth: true,
      title: 'Analisar Recebimento de Resíduos',
      permissionType: 'consulta'
    }
  }
]
