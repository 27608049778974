import ControleResiduosTriados from './ControleResiduosTriados.vue'

export default {
  path: '/controle-residuos-triados',
  name: 'controle-residuos-triados',
  component: ControleResiduosTriados,
  meta: {
    icon: 'mdi mdi-truck-fast',
    title: 'Controlar Resíduos Triados',
    requiresAuth: true,
    permissionType: 'consulta'
  },
  props: true,
  children: [
    {
      path: '/controle-residuos-triados/:action/:id',
      name: 'controle-residuos-triados-edit',
      component: ControleResiduosTriados,
      meta: {
        title: 'Controlar Resíduos Triados',
        requiresAuth: true,
        permissionType: 'altera'
      },
      props: true
    }
  ]
}
