import ClasseMaterial from './ClasseMaterial.vue'

export default {
  path: '/classe-material',
  name: 'classe-material',
  component: ClasseMaterial,
  meta: {
    icon: 'mdi mdi-shape',
    title: 'Manter Classe de Material',
    requiresAuth: true,
    permissionType: 'consulta'
  },
  children: [
    {
      path: '/classe-material/:action',
      name: 'classe-material-add',
      component: ClasseMaterial,
      meta: {
        title: 'Manter Classe de Material',
        requiresAuth: true,
        permissionType: 'inclui'
      },
      props: true
    },
    {
      path: '/classe-material/:action/:id',
      name: 'classe-material-edit',
      component: ClasseMaterial,
      meta: {
        title: 'Manter Classe de Material',
        requiresAuth: true,
        permissionType: 'consulta'
      },
      props: true
    }
  ],
  props: true
}
