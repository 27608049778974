<template>
  <dx-popup
    :hide-on-outside-click="true"
    :drag-enabled="false"
    height="auto"
    :show-title="true"
    :title="title"
    :visible.sync="popupActive">
    <template v-slot:content>
      <div class="solicitacao-cancel-popup-content">
        <span class="numero-ctr">
          Nº do CTR: {{ solicitacao ? solicitacao.id : '' }}
        </span>
        <div class="solicitacao-cancel-popup-form">
          <dx-text-area
            v-model="popupData.justificativa"
            class="flex-col-6"
            label="Justifique sua solicitação de cancelamento do(s) CTR (´s)"
            @input="onTextAreaValueChanged"
            :readOnly="!canCancel"
            :options="fieldsBinds.justificativa"
            :max-length="255"
          />
          <span class="solicitacao-cancel-char-count">({{textAreaCount}} / 255)</span>
        </div>
        <div class="warning-text">
          <span>
            <b>
            ESTEJA ATENTO(A) QUANTO A JUSTIFICATIVA APRESENTADA NA SOLICITAÇÃO, TEXTOS COMO: <br> "ERRO DE CTR", "ERRO DE LOGÍSTICA", "CTR ERRADO" DENTRE OUTRAS JUSTIFICATIVAS SEM UM MOTIVO OBJETIVO, SERÃO RECUSADAS!
            </b>
          </span>
        </div>

        <message-error :message="popupErrorMessage"/>

        <div class="solicitacao-cancel-popup-buttons">
          <dx-button
            v-if="canCancel"
            type="success"
            @click="onButtonSaveClick">
            Salvar
          </dx-button>
          <dx-button type="normal" @click="popupActive = false">Voltar</dx-button>
        </div>
      </div>
    </template>
  </dx-popup>
</template>
<script>
import { DxButton, DxPopup } from 'devextreme-vue'
import mixinPopup from './mixin-popup'

import { cancelSolicitacaoTransporte } from './service'
import MessageError from '../MessageError'
import DxTextArea from 'devextreme-vue/text-area'

export default {
  name: 'popup-cancel-solicitacao',
  mixins: [
    mixinPopup
  ],
  components: {
    DxButton,
    DxPopup,
    MessageError,
    DxTextArea
  },
  props: {
    /**
     * O titulo do popup.
     */
    title: {
      type: String,
      required: true
    },

    /**
     * A solicitacao que sera cancelada.
     */
    solicitacao: {
      type: Object
    }
  },
  /**
   * Metodo do vue, para obter o estado inicial do componente.
   * @returns {Object} O estado inicial do componente.
   */
  data () {
    return {
      popupErrorMessage: null,
      textAreaCount: 0,
      popupData: {
        justificativa: null
      }
    }
  },
  computed: {
    /**
     * Valor computado dos binds dos campos do popup.
     * @returns {Object}
     */
    fieldsBinds () {
      return {
        justificativa: {
          readOnly: !this.canCancel
        }
      }
    },

    /**
     * Valor computado de se a solicitacao pode ser cancelada.
     * @returns {boolean}
     */
    canCancel () {
      return this.solicitacao && ['Alocada', 'Enviada', 'Solicitada', 'Retirada']
        .includes(this.solicitacao.situacao)
    }
  },
  watch: {
    /**
     * Metodo de callback de mudancas no valor da prop 'active', com o objetivo
     * de buscar os dados de veiculo e cacamba.
     * @param {boolean} active - O valor atual da prop.
     */
    async active (active) {
      if (active) {
        this.popupData = {
          justificativa: (this.solicitacao &&
            this.solicitacao.motivoSolCancelamento) ||
            null
        }
      } else {
        this.popupData = {
          justificativa: null
        }
      }
    }
  },
  methods: {
    /**
     * Metodo de callback de quando foi clicado no botao de salvar do popup. Com
     * o objetivo de enviar a requisicao de salvar as alteracoes da solicitacao.
     */
    async onButtonSaveClick () {
      this.popupErrorMessage = null

      if (!this.popupData.justificativa || !this.popupData.justificativa.length) {
        this.popupErrorMessage = 'A justificativa informada é inválida.'
        return
      }

      if (this.textAreaCount < 7) {
        this.popupErrorMessage = 'A justificativa deve ter no mínimo 7 caracteres.'
        return
      }

      const res = await cancelSolicitacaoTransporte({
        motivoSolCancelamento: this.popupData.justificativa
      }, this.solicitacao.id)

      if (res.success) {
        this.popupActive = false
        this.$emit('updated-solicitacao')
      } else {
        this.popupErrorMessage = res.error
      }
    },

    onTextAreaValueChanged ({ component }) {
      this.textAreaCount = component.option('text').length
    }
  }
}
</script>

<style lang="scss">
@import '../../global.scss';
.solicitacao-cancel-char-count {
  font-style: italic;
  color: #bfbfbf;
  padding-right: 0.55em;
  padding-top: 0.55em;
  float: right
}
.solicitacao-cancel-popup-content {
  display: flex;
  flex-direction: column;

  .solicitacao-cancel-popup-form {
    margin-bottom: 16px;
  }

  .numero-ctr {
    margin: 6px 0px 20px 0px;
  }

  > .solicitacao-cancel-popup-buttons {
    display: flex;
    flex-direction: row-reverse;
  }

  .error-message-card {
    background-color: #B00020 !important;
    color: white !important;
    width: 100%;
    margin-top: 6px;
    margin-bottom: 16px;
  }
}
.warning-text {
  text-align: center;
  padding-bottom: 1em;
  color: red;
}
</style>
