<template>
  <div class="generic-crud">
    <div v-if="(action || onCreatingGraphic)" class="generic-crud-form">
      <h2 v-if="action === 'add'">Incluir Solicitação transporte</h2>
      <fieldset v-if="!onCreatingGraphic && canEditAnalise">
        <legend>Solicitação de Transporte</legend>
        <div class="dx-fieldset">
          <div class="dx-fieldset-header">O cancelamento do CTR não poderá ser revertido!</div>
          <div class="dx-field-label">Nº do CTR:</div>
          <div class="dx-field-value-static"><b>{{ id }}</b></div>
          <div class="dx-field-label">Data/Hora da solicitação:</div>
          <div v-if="formData" class="dx-field-value-static">{{ onEditCTRPage.dataHoraSolicitacao }}</div>
          <div class="dx-field-label">Nome/Razão Social (Solicitante):</div>
          <div v-if="currentEditingValues" class="dx-field-value-static">
            <b>{{ currentEditingValues.transportador.pessoa.nomeRazaoSocial }}</b></div>
          <div class="solCancelamentoBox">Motivo da Solicitação de Cancelamento:
            <DxTextArea
              class="dx-field-value" :height="80" :width="400"
              :readOnly="true"
              v-model="onEditCTRPage.motivoCancelamento"/>
          </div>
        </div>

        <DxRadioGroup
          class="cancelamentoRadioBox"
          v-model="onEditCTRPage.selectedCTRGroup"
          :items="radioGroupsItems"
          :value="radioGroupsItems[1]"
          layout="horizontal"
        />
        <div class="recusarArea" style="height: 150px;" v-show="onEditCTRPage.selectedCTRGroup === 'Recusar'">
          <DxTextArea
            placeholder="Informe o Motivo da Recusa"
            class="recusarFieldBox"
            v-show="onEditCTRPage.selectedCTRGroup === 'Recusar'"
            v-model="onEditCTRPage.motivoRecusa"/>
        </div>
      </fieldset>

      <fieldset class="graficoFieldSet" v-if="onCreatingGraphic && canEdit">
        <legend v-if="action === 'add'">Solicitação transporte</legend>
        <legend v-else>Gerar Gráfico</legend>
        <div>
          <div style="display: block; width: 100%" class="dx-field-label">
            <DxCheckBox
              :acceptCustomValue="false"
              @value-changed="graphicCtrPorAnoCheckBox"
              :value.sync="onCreateGraphicPage.ctrPorAno.checkboxValue"
              text="CTR's Cancelados por Ano  "/>
            <DxDateBox
              :acceptCustomValue="false"
              placeholder="Ano"
              style="display: inline-block;"
              display-format="yyyy"
              :calendar-options="{
                  zoomLevel: 'year',
                  minZoomLevel: 'century',
                  maxZoomLevel: 'decade'
                }"
              :disabled.sync="!onCreateGraphicPage.ctrPorAno.checkboxValue"
              :value.sync="onCreateGraphicPage.ctrPorAno.startValue"
              :max.sync="onCreateGraphicPage.ctrPorAno.endValue"
              width="180"
              type="date"/>
            <DxDateBox
              :acceptCustomValue="false"
              placeholder="Ano"
              style="display: inline-block; left: 6px;"
              display-format="yyyy"
              :calendar-options="{
                  zoomLevel: 'year',
                  minZoomLevel: 'century',
                  maxZoomLevel: 'decade'
                }"
              :disabled.sync="!onCreateGraphicPage.ctrPorAno.checkboxValue"
              :value.sync="onCreateGraphicPage.ctrPorAno.endValue"
              :min.sync="onCreateGraphicPage.ctrPorAno.startValue"
              width="180"
              type="date"/>
          </div>

          <div style="display: block; width: 100%" class="dx-field-label">
            <DxCheckBox
              :acceptCustomValue="false"
              @value-changed="graphicCtrPorMesCheckBox"
              :value.sync="onCreateGraphicPage.ctrPorMes.checkboxValue"
              text="CTR's Cancelados por Mês  "/>
            <DxDateBox
              :acceptCustomValue="false"
              placeholder="Mês/ano"
              style="display: inline-block;"
              display-format="monthAndYear"
              :calendar-options="{
                    zoomLevel: 'year',
                    minZoomLevel: 'decade',
                    maxZoomLevel: 'year'
                  }"
              :disabled.sync="!onCreateGraphicPage.ctrPorMes.checkboxValue"
              :value.sync="onCreateGraphicPage.ctrPorMes.startValue"
              :max.sync="onCreateGraphicPage.ctrPorMes.endValue"
              width="170"
              type="date"/>

            <DxDateBox
              :acceptCustomValue="false"
              placeholder="Mês/ano"
              style="display: inline-block; left: 6px;"
              display-format="monthAndYear"
              :calendar-options="{
                  zoomLevel: 'year',
                  minZoomLevel: 'decade',
                  maxZoomLevel: 'year'
                }"
              :disabled.sync="!onCreateGraphicPage.ctrPorMes.checkboxValue"
              :value.sync="onCreateGraphicPage.ctrPorMes.endValue"
              :min.sync="onCreateGraphicPage.ctrPorMes.startValue"
              width="170"
              type="date"/>
          </div>

          <div style="display: block; width: 100%" class="dx-field-label">
            <DxCheckBox
              :acceptCustomValue="false"
              @value-changed="graphicCtrPorSemanaCheckBox"
              :value.sync="onCreateGraphicPage.ctrPorSemana.checkboxValue"
              text="CTR's Cancelados por Semana  "/>
            <DxDateBox
              :acceptCustomValue="false"
              placeholder="Dia da semana"
              style="display: inline-block;"
              @value-changed="onAddOneWeek"
              :disabled.sync="!onCreateGraphicPage.ctrPorSemana.checkboxValue"
              :value.sync="onCreateGraphicPage.ctrPorSemana.startValue"
              :max.sync="onCreateGraphicPage.ctrPorSemana.endValue"
              width="180"
              type="date"/>
            <DxDateBox
              :acceptCustomValue="false"
              placeholder="Dia da semana"
              style="display: inline-block;left: 6px;"
              v-model="onCreateGraphicPage.ctrPorSemana.endValue"
              :disabled="true"
              width="180"
              type="date"/>
          </div>

          <div class="searchCPFCNPJGroup">
            <DxRadioGroup
              style="display: inline-block; right: 99px;"
              v-model="onCreateGraphicPage.selected"
              :items="onCreateGraphicPage.cpfOrCnpj"
              layout="horizontal"
            />
            <DxTextBox
              v-model="onCreateGraphicPage.cpfOrCnpjValue"
              :key="onCreateGraphicPage.cpfCnpjComponentKey"
              style="display: inline-block; position: relative; left: 9px;"
              v-if="onCreateGraphicPage.selected === 'CPF'" validationMessageMode="auto"
              mask-invalid-message="CPF inválido" mask='000.000.000-00'/>
            <DxTextBox
              v-model="onCreateGraphicPage.cpfOrCnpjValue"
              :key="onCreateGraphicPage.cpfCnpjComponentKey"
              style="display: inline-block; position: relative; left: 9px;"
              v-if="onCreateGraphicPage.selected === 'CNPJ'" mask-invalid-message="CNPJ inválido"
              mask='00.000.000/0000-00'/>
            <dx-button class="searchButton" v-if="onCreatingGraphic" type="success" @click="getPessoa">Procurar
            </dx-button>
            <dx-button class="searchButton" v-if="onCreatingGraphic" type="success" @click="onCreateGraphicPage.cpfOrCnpjValue = null;
                  onCreateGraphicPage.findSolicitanteValue = null">Limpar
            </dx-button>
          </div>

          <div class="nomeSolicianteResult"> Nome do solicitante:
            <DxTextBox
              :disabled="true"
              v-model="onCreateGraphicPage.findSolicitanteValue"
              style="display: inline-block; position: relative; left: 15px;"
              width=250
              placeholder="Nome do solicitante"
            >
            </DxTextBox>
          </div>

        </div>
        <DxToast
          :width="600"
          :displayTime="4000"
          :visible.sync="warning"
          type="warning"
          :message="warningMessage"
        />
        <dx-button v-if="onCreatingGraphic" type="success" style="background-color: #FF8402;" @click="onGoBack">Voltar
        </dx-button>
        <dx-button v-if="onCreatingGraphic" type="success" style="position: relative; left: 10px;"
                   @click="onClearGraphics">Limpar Gráficos
        </dx-button>
        <dx-button
          v-if="onCreatingGraphic && (this.onCreateGraphicPage.ctrPorAno.checkboxValue || this.onCreateGraphicPage.ctrPorMes.checkboxValue || this.onCreateGraphicPage.ctrPorSemana.checkboxValue)"
          type="success" style="position: relative; left: 20px;" @click="onGenerateGraphics">Gerar Gráficos
        </dx-button>
      </fieldset>

      <div v-if="onCreateGraphicPage.chartsGenerated && onCreateGraphicPage.ctrPorAno.chartReady"
           class="graficosPorAno">
        <DxChart
          :data-source="onCreateGraphicPage.ctrPorAno.dataSource"
          title="CTR Canceladas por ano">
          <DxValueAxis
            :allowDecimals="false"
          >
            <DxTitle text="Quantidade"/>
          </DxValueAxis>
          <DxCommonSeriesSettings
            argument-field="year"
            type="stackedbar"/>
          <DxSeries
            value-field="total"
            :name="`Período: ${onCreateGraphicPage.ctrPorAno.startValue.getFullYear()} a ${onCreateGraphicPage.ctrPorAno.endValue.getFullYear()}`"
            stack="male"/>
          <DxExport :enabled="true"/>
          <DxTooltip :enabled="true"/>
        </DxChart>
      </div>

      <div v-if="onCreateGraphicPage.chartsGenerated && onCreateGraphicPage.ctrPorMes.chartReady"
           class="graficosPorMes">
        <DxChart
          :data-source="onCreateGraphicPage.ctrPorMes.dataSource"
          title="CTR Canceladas por mês">
          <DxValueAxis
            :allowDecimals="false"
          >
            <DxTitle text="Quantidade"/>
          </DxValueAxis>
          <DxCommonSeriesSettings
            argument-field="month"
            type="stackedbar"/>

          <DxSeries
            value-field="total"
            :name="onCreateGraphicPage.ctrPorMes.range"
            stack="month"/>
          <DxExport :enabled="true"/>
          <DxTooltip :enabled="true"/>
        </DxChart>
      </div>

      <div v-if="onCreateGraphicPage.chartsGenerated && onCreateGraphicPage.ctrPorSemana.chartReady"
           class="graficosPorSemana">
        <DxChart
          :data-source="onCreateGraphicPage.ctrPorSemana.dataSource"
          title="CTR Canceladas por semana">
          <DxValueAxis
            :allowDecimals="false"
          >
            <DxTitle text="Quantidade"/>
          </DxValueAxis>
          <DxCommonSeriesSettings
            value-field="week"
            argument-field="week"
            type="stackedbar"/>
          <DxSeries
            value-field="total"
            :name="onCreateGraphicPage.ctrPorSemana.range"
            stack="month"/>
          <DxExport :enabled="true"/>
          <DxTooltip :enabled="true"/>
        </DxChart>
      </div>
      <dx-button
        v-if="!onCreatingGraphic && canEditAnalise"
        class="saveButton"
        type="success"
        @click="save">
        Salvar
      </dx-button>
    </div>
    <div v-else>
      <div class="md-layout">
        <div class="md-layout-item">
          <h2 class="crud-title">Consultar Solicitação de Cancelamento de CTR</h2>
        </div>

      </div>
      <simple-grid ref="simpleGrid"
                   @gridReady="onGridReady"
                   entity="SolicitacaoTransporte"
                   popup-title="Solicitação transporte"
                   :grid-data="gridData"
                   :popup-data="popupData"
                   :editable="false"
                   :deletable="false"
      ></simple-grid>
      <div class="md-layout-item" style="text-align: right;">
        <dx-button type="success" @click="createGraphics">Criar Gráficos Estatísticos</dx-button>
      </div>
    </div>
    <md-card v-if="errorMessage" class="error-message-card">
      <md-card-content>{{ errorMessage }}</md-card-content>
    </md-card>
  </div>
</template>
<script>
import {
  DxChart,
  DxCommonSeriesSettings,
  DxSeries,
  DxValueAxis,
  DxTitle,
  DxTooltip,
  DxExport
} from 'devextreme-vue/chart'
import { DxToast } from 'devextreme-vue/toast'
import CustomStore from 'devextreme/data/custom_store'
import mixinCrud from '../mixin-crud'
import { DxCheckBox } from 'devextreme-vue/check-box'
import DataSource from 'devextreme/data/data_source'
import { DxButton, DxTextArea, DxRadioGroup, DxDateBox, DxTextBox } from 'devextreme-vue'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import config from 'config'
import Swal from 'sweetalert2'

import { SimpleGrid } from '@/components'
import { hasPermission } from '../../../util/permissions'
// import { parseFilterParams, parseSortParams } from '../../util/dxDatasourceHelper'
import { http } from '@/pluggables/http'

export default {
  name: 'solicitar-cancelamento-ctr-crud',
  mixins: [
    mixinCrud
  ],
  components: {
    SimpleGrid,
    DxTextArea,
    DxButton,
    DxRadioGroup,
    DxCheckBox,
    DxDateBox,
    DxChart,
    DxValueAxis,
    DxTitle,
    DxTooltip,
    DxExport,
    DxCommonSeriesSettings,
    DxSeries,
    DxTextBox,
    DxToast

  },
  data () {
    const canEditAnalise = hasPermission('Analisar Solicitação de Cancelamento de CTR', 'altera')

    return {
      solicitantesId: [],
      permissionName: 'Solicitar Cancelamento de CTR',
      warningMessage: null,
      warning: false,
      customFilter: null,
      onCreateGraphicPage: {
        cpfCnpjValidationStatus: null,
        cpfCnpjComponentKey: 0,
        cpfOrCnpjValue: null,
        findSolicitanteValue: null,
        findSolicitanteDatasource: {
          datasource: new CustomStore({
            key: 'id',
            load: () => {
              return this.$utils.wrapRequestForGrid(this.find({
                entity: 'Pessoa'
              }))
            },
            byKey: key => {
              return this.find({
                entity: 'Pessoa'
              })
            }
          })
        },
        chartsGenerated: false,
        calendarOptions: {
          max: 'year',
          min: 'century'
        },
        selected: 'CPF',
        cpfOrCnpj: ['CPF', 'CNPJ'],
        ctrPorAno: {
          isValid: false,
          chartReady: false,
          dataSource: null,
          checkboxValue: false,
          startValue: null,
          endValue: null
        },
        ctrPorMes: {
          isValid: false,
          chartReady: false,
          dataSource: null,
          checkboxValue: false,
          startValue: null,
          endValue: null,
          ano: null,
          range: null
        },
        ctrPorSemana: {
          isValid: false,
          chartReady: false,
          dataSource: null,
          checkboxValue: false,
          startValue: null,
          endValue: null,
          range: null
        }
      },

      onEditCTRPage: {
        selectedCTRGroup: null,
        motivoCancelamento: null,
        motivoRecusa: null
      },

      radioGroupsItems: ['Aceitar', 'Recusar', 'Destinar'],
      errorMessage: null,
      solicitacaoTransporteValues: null,
      currentEditingValues: null,
      onCreatingGraphic: false,
      entity: 'SolicitacaoTransporte',
      formData: null,
      gridData: {
        value: {
          visible: true
        },
        columns: this.parseColumns([
          {
            dataField: 'id',
            dataType: 'number',
            visible: true,
            width: 100,
            caption: 'Nº do CTR',
            editorType: 'dxNumberBox'
          },
          {
            dataField: 'dataHoraSolicitacao',
            visible: true,
            caption: 'Data/Hora da solicitação',
            dataType: 'date',
            editorType: 'dxDateBox',
            editorOptions: {
              dataSource: new CustomStore({
                key: 'id',
                load: (loadOptions) => {
                  return this.$utils.wrapRequestForGrid(this.find({
                    entity: this.entity,
                    params: {
                      fields: ['dataHoraSolicitacao', 'id']
                    },
                    loadOptions
                  }))
                },
                byKey: (key) => {
                  return this.findId({
                    entity: this.entity,
                    params: {
                      fields: ['dataHoraSolicitacao', 'id']
                    },
                    id: key
                  })
                }
              }),
              displayExpr: 'dataHoraSolicitacao',
              valueExpr: 'id'
            },
            width: 230,
            calculateSortValue: 'dataHoraSolicitacao',
            calculateDisplayValue: (data) => {
              return moment(data.dataHoraSolicitacao).format('DD/MM/YYYY HH:ss')
            }
          },
          /* {
            dataField: '$obra->gerador->tipoPerfil.id$',
            visible: true,
            dataType: 'string',
            caption: 'Perfil',
            editorType: 'dxLookup',
            editorOptions: {
              width: 800,
              dataSource: new CustomStore({
                key: 'id',
                load: (loadOptions) => {
                  return this.$utils.wrapRequestForGrid(this.find({
                    entity: 'TipoPerfil',
                    params: {
                      fields: ['descricao', 'id']
                    },
                    loadOptions
                  }))
                },
                byKey: (key) => {
                  return this.findId({
                    entity: 'TipoPerfil',
                    params: {
                      fields: ['descricao', 'id']
                    },
                    id: key
                  })
                }
              }),
              displayExpr: 'descricao',
              valueExpr: 'id'
            },
            xlsxObjPath: 'obra.gerador.tipoPerfil.descricao',
            calculateDisplayValue: 'obra.gerador.tipoPerfil.descricao'
          }, */
          {
            dataField: '$transportador->pessoa.id$',
            caption: 'Nome razão social (Solicitante)',
            dataType: 'string',
            editorType: 'dxLookup',
            editorOptions: {
              acceptCustomValue: true,
              dataSource: {
                store: new CustomStore({
                  key: 'id',
                  load: (loadOptions) => {
                    return this.$utils.wrapRequestForGrid(this.find({
                      entity: 'Pessoa',
                      loadOptions,
                      params: {
                        fields: ['id', 'nomeRazaoSocial']
                      }
                    }))
                  },
                  byKey: (key) => {
                    return this.findId({
                      entity: 'Pessoa',
                      params: {
                        fields: ['id', 'nomeRazaoSocial']
                      },
                      id: key
                    })
                  }
                }),
                paginate: true,
                pageSize: 10
              },
              displayExpr: 'nomeRazaoSocial',
              valueExpr: 'id'
            },
            validationRules: [{ type: 'required' }],
            calculateDisplayValue: 'transportador.pessoa.nomeRazaoSocial'
          },
          {
            dataField: '$obra->gerador->pessoa.cpf_cnpj$',
            visible: true,
            dataType: 'string',
            caption: 'CPF/CNPJ',
            width: 300,
            xlsxObjPath: 'obra.gerador.pessoa.cpfCnpj',
            editorOptions: {
              acceptCustomValue: true,
              dataSource: new CustomStore({
                key: 'id',
                load: (loadOptions) => {
                  return this.$utils.wrapRequestForGrid(this.find({
                    entity: 'Pessoa',
                    params: {
                      fields: ['id', 'cpfCnpj']
                    }
                  }))
                },
                byKey: (key) => {
                  return this.findId({
                    entity: 'Pessoa',
                    params: {
                      fields: ['id', 'cpfCnpj']
                    },
                    id: key
                  })
                }
              }),
              displayExpr: 'cpfCnpj',
              valueExpr: 'cpfCnpj'
            },
            calculateDisplayValue: (data) => {
              if (data.obra && data.obra.gerador && data.obra.gerador.pessoa) {
                return data.obra.gerador.pessoa.cpfCnpj
              } else {
                return ''
              }
            }
          },
          {
            type: 'buttons',
            buttons: [
              {
                name: 'viewCtr',
                text: 'CTR',
                cssClass: 'dx-button mdi mdi-file-document',
                onClick: data => {
                  window.open(`${config.baseUrl}/FichaCTR/${data.row.key}`)
                }
              },
              ...(canEditAnalise ? [{
                name: 'edit',
                text: 'Editar',
                cssClass: 'dx-button dx-button-success dx-button-mode-contained mdi mdi-pencil',
                visible: () => !!this.isSecretaria || !!this.isAdministrador,
                onClick: (data) => {
                  this.$router.push({ path: `/cancelamento-ctr/edit/${data.row.key}` })
                }
              }] : [])
            ]
          }
        ]),
        dataSource: new CustomStore({
          key: 'id',
          load: async (loadOptions) => {
            let customSearchFilter
            if (loadOptions.filter && loadOptions.filter.length > 0 && loadOptions.filter.filter(filter => typeof filter[0] === 'function').length > 0) {
              customSearchFilter = [
                ['$obra->gerador->tipoPerfil.descricao$', 'contains', JSON.stringify(loadOptions.filter[0].filterValue)],
                'or',
                ['$obra->gerador->pessoa.nome_razao_social$', 'contains', JSON.stringify(loadOptions.filter[0].filterValue)],
                'or',
                ['$obra->gerador->pessoa.cpf_cnpj$', 'contains', JSON.stringify(loadOptions.filter[0].filterValue)],
                'or',
                ['situacao', 'contains', JSON.stringify(loadOptions.filter[0].filterValue)]
              ]
            }
            this.solicitacaoTransporteValues = await this.$utils.wrapRequestForGrid(this.find({
              entity: this.entity,
              loadOptions,
              customSearchFilter,
              params: {
                fields: ['id', 'dataHoraSolicitacao', 'situacao', 'motivoSolCancelamento'],
                where: {
                  situacao: 'Cancelamento Solicitado'
                },
                include: [
                  {
                    model: 'Obra',
                    as: 'obra',
                    attributes: ['id'],
                    include: {
                      model: 'Perfil',
                      as: 'gerador',
                      attributes: ['id'],
                      include: [
                        {
                          model: 'Pessoa',
                          as: 'pessoa',
                          attributes: ['id', 'nomeRazaoSocial', 'cpfCnpj']
                        },
                        {
                          model: 'TipoPerfil',
                          as: 'tipoPerfil'
                        }
                      ]
                    }
                  },
                  {
                    model: 'Perfil',
                    as: 'transportador',
                    attributes: ['id'],
                    include:
                      {
                        model: 'Pessoa',
                        as: 'pessoa',
                        attributes: ['id', 'nomeRazaoSocial']
                      }
                  }
                ]
              }
            }))
            return this.solicitacaoTransporteValues
          },
          insert: (values) => this.insertRow({ entity: this.entity, values }),
          remove: (key, values) => this.removeRow({ entity: this.entity, key })
        })
      },
      canEditAnalise
    }
  },
  computed: {
    /**
     * Propieade que computa e retorna se o usuario logado é do tipo "Secretaria"
     *
     */
    isSecretaria () {
      return !!this.userData.userTypes.find(userType => userType === 'Secretaria')
    },
    isAdministrador () {
      return !!this.userData.userTypes.find(userType => userType === 'Administrador')
    },

    ...mapGetters('Login', ['userData'])
  },
  watch: {
    /**
     * Observa a propiedade "action" que é devolve o status atual da página, se é edição, adição ou no modo grid
     * Caso o status seja "edit" atribui os dados da linha selecionada no grid para a propiedade "currentEditingValues" alem de setar
     * propiedades motivoCancelamento e formatar a propiedade dataHoraSolicitação para mostrar na tela de edição
     * @param {String} value
     */
    action: {
      immediate: true,
      async handler (value) {
        if (value === 'edit') {
          try {
            if (!this.solicitacaoTransporteValues) {
              await this.gridData.dataSource.load()
            }
          } catch (error) {
            Swal.fire({
              title: 'Algo deu errado!',
              text: 'Por favor, contate um administrador do sistema',
              icon: 'warning'
            }).then((result) => {
              if (result.isConfirmed) {
                this.$router.go(-1)
              }
            })
            console.error(error)
          }
          if (this.id) this.currentEditingValues = this.solicitacaoTransporteValues.data.find(val => val.id === parseInt(this.id))
          this.onEditCTRPage.motivoCancelamento = this.currentEditingValues.motivoSolCancelamento
          this.onEditCTRPage.dataHoraSolicitacao = moment(this.currentEditingValues.dataHoraSolicitacao).format('DD/MM/YYYY HH:mm')
        }
      }
    }
  },
  methods: {
    ...mapActions('Crud', ['updateRow', 'generatePdf', 'calculateCTRCanceladas', 'setLoading']),

    /**
     * Método onGridReady que é disparado com a instancia de DxDataGrid do simpleGrid é incializada
     * Chama o método setFilterValue do gridSimple que é responsável setar o filtro da coluna situação
     */
    onGridReady (instance) {
      // this.$refs.simpleGrid.setFilterValue([
      //   'situacao',
      //   '=',
      //   'Cancelamento Solicitado'
      // ])
    },
    /**
     * Método onGoBack acionado quando o botão de "Voltar" é acionado na tela de gerador de gráficos
     * É responsável por limpar os gráficos existentes
     */
    onGoBack () {
      this.onClearGraphics()
      this.onCreatingGraphic = false
    },

    /**
     * Método getPessoa acionado quando o botão de "Procurar" é acionado na tela de gerador de gráficos
     * Este método faz uma requisição procurando por uma pessoa que seja compativel com o valor de CPF ou CNPJ passados
     * Seta o array de IDS de pessoas encontradas, caso encontre alguma
     */
    async getPessoa () {
      this.solicitantesId = []
      if (this.onCreateGraphicPage.cpfOrCnpjValue === '' || this.onCreateGraphicPage.cpfOrCnpjValue === null) {
        this.onCreateGraphicPage.findSolicitanteValue = null
      } else {
        this.setLoading(true)
        const result = await this.find({
          entity: this.entity,
          params: {
            fields: ['id'],
            include: JSON.stringify([{
              model: 'Obra',
              as: 'obra',
              required: true,
              attributes: ['id'],
              include: {
                model: 'Perfil',
                as: 'gerador',
                required: true,
                attributes: ['id'],
                include: {
                  model: 'Pessoa',
                  as: 'pessoa',
                  required: true,
                  attributes: ['id', 'nomeRazaoSocial'],
                  where: {
                    cpfCnpj: this.onCreateGraphicPage.cpfOrCnpjValue || undefined
                  }
                }
              }
            }])
          }
        })
        if (result.data.length > 0) {
          this.setLoading(false)
          for (const solicitante of result.data) {
            this.solicitantesId.push(solicitante.id)
          }
          this.onCreateGraphicPage.findSolicitanteValue = result.data[0].obra.gerador.pessoa.nomeRazaoSocial
        } else {
          this.setLoading(false)
          this.onCreateGraphicPage.cpfCnpjValidationStatus = 'invalid'
          this.onCreateGraphicPage.findSolicitanteValue = 'Solicitante não encontrado'
          this.onCreateGraphicPage.solicitantesId = []
        }
      }
    },

    /**
     * Método getYears responsável por trazer todos os anos entre uma data e outra
     * Este método é chamado pelo outro metodo onGenerateGraphics no momento de gerar um gráfico
     * @param {Date} startDate data incial
     * @param {Date} stopDate data final
     * @returns {array} dateArray array contendo o intervalo dos anos entre a data incial e data final
     */
    getYears (startDate, stopDate) {
      const dateArray = []
      let currentDate = moment(startDate)
      const stop = moment(stopDate)
      while (currentDate <= stop) {
        dateArray.push((currentDate.year()))
        currentDate = moment(currentDate).add(1, 'years')
      }
      return dateArray
    },

    /**
     * Método getMonths responsável por trazer todos os meses/ano entre uma data e outra
     * Este método é chamado pelo outro metodo onGenerateGraphics no momento de gerar um gráfico
     * @param {Date} startDate data incial
     * @param {Date} stopDate data final
     * @returns {array} dateArray array contendo o intervalo dos meses/ano entre a data incial e data final
     */
    getMonths (startDate, stopDate) {
      const dateArray = []
      let currentDate = moment(startDate)
      const stop = moment(stopDate)
      while (currentDate <= stop) {
        dateArray.push((currentDate.format('M')) + '/' + currentDate.format('YYYY'))
        currentDate = moment(currentDate).add(1, 'months')
      }
      return dateArray
    },

    /**
     * Método getDays responsável por trazer todos os dias entre uma data e outra
     * Este método é chamado pelo outro metodo onGenerateGraphics no momento de gerar um gráfico
     * @param {Date} startDate data incial
     * @param {Date} stopDate data final
     * @returns {array} dateArray array contendo o intervalo dos dias entre a data incial e data final
     */
    getDays (startDate, stopDate) {
      const dateArray = []
      let currentDate = moment(startDate)
      const stop = moment(stopDate)
      while (currentDate <= stop) {
        dateArray.push((currentDate.day() + 1))
        currentDate = moment(currentDate).add(1, 'days')
      }
      return dateArray
    },

    /**
     * Método onClearGraphics responsável por limpar todos os dados dos graficos previamentes criados
     * Este método é acionado quando o botão "Limpar" ou o botão "Voltar" é acionado na tela de gerador de gráficos
     */
    onClearGraphics () {
      this.warning = false
      this.onCreateGraphicPage.cpfCnpjComponentKey += 1
      this.onCreateGraphicPage.chartsGenerated = false
      this.onCreateGraphicPage.cpfOrCnpjValue = null
      this.onCreateGraphicPage.findSolicitanteValue = null
      this.onCreateGraphicPage.ctrPorAno = {
        chartReady: false,
        dataSource: null,
        checkboxValue: false,
        startValue: null,
        endValue: null
      }
      this.onCreateGraphicPage.ctrPorMes = {
        chartReady: false,
        dataSource: null,
        checkboxValue: false,
        startValue: null,
        endValue: null,
        ano: null
      }
      this.onCreateGraphicPage.ctrPorSemana = {
        chartReady: false,
        dataSource: null,
        checkboxValue: false,
        startValue: null,
        endValue: null
      }
    },

    /**
     * Método onAddOneWeek responsável adicionar 7 dias no campo de data final de CTR cancelados por semana
     * Este método é acionado quando o botão "Limpar" ou o botão "Voltar" é acionado na tela de gerador de gráficos
     * @param {Date} data objeto do tipo data contendo a data do campo inicial
     */
    onAddOneWeek (data) {
      this.onCreateGraphicPage.ctrPorSemana.endValue = new Date(moment(data.value).add(7, 'd').format('YYYY/MM/DD'))
    },

    /**
     * Método onGenerateGraphics responsável por criar os gráficos de acordo com os parametros passados
     * Gera gráficos de CTR por intervalo de ano, de semana ou de mês
     * Este método é acionado quando o botão "Gerar Gráficos" é acionado na tela de gerador de gráficos
     */
    async onGenerateGraphics () {
      /**
       * Caso o gráfico seja por ano
       */
      if (this.onCreateGraphicPage.ctrPorAno.checkboxValue) {
        if (!this.onCreateGraphicPage.ctrPorAno.startValue) {
          this.warning = true
          this.warningMessage = 'Não foi possível gerar gráfico de CTRs por ano, o campo de data incial é obrigatório'
        } else if (!this.onCreateGraphicPage.ctrPorAno.endValue) {
          this.warning = true
          this.warningMessage = 'Não foi possível gerar gráfico de CTRs por ano, o campo de data final é obrigatório'
        } else {
          try {
            this.setLoading(true)

            /**
             * Chamada do command calculateCTR responsável por calcular ctr canceladas dado um intervalo de tempo
             * parametro startDate parametro de data inicial
             * parametro endDate parametro de data final
             * parametro type parametro do tipo de retorno dos intervalos ('year' , 'month' ou 'weekDays')
             * parametro solicitante contendo array de ids dos soliciantes caso encontre algum no campo de busca CPF ou CNPJ
             */
            const res = await this.calculateCTRCanceladas({
              startDate: `${moment(this.onCreateGraphicPage.ctrPorAno.startValue).format('YYYY')}-01-01`,
              endDate: `${moment(this.onCreateGraphicPage.ctrPorAno.endValue).format('YYYY')}-12-31`,
              type: 'year',
              solicitante: (this.onCreateGraphicPage.findSolicitanteValue && this.onCreateGraphicPage.findSolicitanteValue !== 'Solicitante não encontrado') ? this.solicitantesId : undefined
            })

            const years = res.data.data.rows.map(value => {
              return {
                year: value.ano,
                total: value.total
              }
            })
            this.onCreateGraphicPage.chartsGenerated = true
            const dataRangeValues = []

            /**
             * Alimenta o array dataRangeValues que contem o ano e o total de cada ano
             */
            for (const year of this.getYears(this.onCreateGraphicPage.ctrPorAno.startValue, this.onCreateGraphicPage.ctrPorAno.endValue)) {
              if (years.filter(value => value.year === year).length > 0) {
                dataRangeValues.push({
                  year: JSON.stringify(year),
                  total: parseInt(years.filter(value => value.year === year)[0].total)
                })
              } else {
                dataRangeValues.push({
                  year: JSON.stringify(year),
                  total: 0
                })
              }
            }

            /**
             * Constroi datasource com array dateRangeValues correspondente
             */
            this.onCreateGraphicPage.ctrPorAno.dataSource = new DataSource({
              store: {
                type: 'array',
                key: 'id',
                data: dataRangeValues
              }
            })

            this.setLoading(false)
            this.onCreateGraphicPage.ctrPorAno.chartReady = true
          } catch (error) {
            this.setLoading(false)
            Swal.fire({
              title: 'Erro ao gerar gráfico',
              icon: 'error',
              text: 'Tente novamente'
            })
          }
        }
      }

      /**
       * Caso o gráfico seja por mes
       */
      if (this.onCreateGraphicPage.ctrPorMes.checkboxValue) {
        if (!this.onCreateGraphicPage.ctrPorMes.startValue) {
          this.warning = true
          this.warningMessage = 'Não foi possível gerar gráfico de CTRs por mês, o campo de data incial é obrigatório'
        } else if (!this.onCreateGraphicPage.ctrPorMes.endValue) {
          this.warning = true
          this.warningMessage = 'Não foi possível gerar gráfico de CTRs por mês, o campo de data final é obrigatório'
        } else {
          try {
            this.setLoading(true)

            /**
             * Chamada do command calculateCTR responsável por calcular ctr canceladas dado um intervalo de tempo
             * parametro startDate parametro de data inicial
             * parametro endDate parametro de data final
             * parametro type parametro do tipo de retorno dos intervalos ('year' , 'month' ou 'weekDays')
             * parametro solicitante contendo array de ids dos soliciantes caso encontre algum no campo de busca CPF ou CNPJ
             */
            const res = await this.calculateCTRCanceladas({
              startDate: `${moment(this.onCreateGraphicPage.ctrPorMes.startValue).format('YYYY')}-01-01`,
              endDate: `${moment(this.onCreateGraphicPage.ctrPorMes.endValue).format('YYYY-MM')}-${moment(this.onCreateGraphicPage.ctrPorMes.endValue).endOf('month').format('DD')}`,
              type: 'month',
              solicitante: (this.onCreateGraphicPage.findSolicitanteValue && this.onCreateGraphicPage.findSolicitanteValue !== 'Solicitante não encontrado') ? this.solicitantesId : undefined
            })
            const months = res.data.data.rows.map(value => {
              return {
                month: `${value.mes}/${value.ano}`,
                total: value.total
              }
            })

            this.onCreateGraphicPage.ctrPorMes.range =
              `De ${moment(this.onCreateGraphicPage.ctrPorMes.startValue).locale('pt-br').format('MMMM')} de
            ${this.onCreateGraphicPage.ctrPorMes.startValue.getFullYear()} até
            ${moment(this.onCreateGraphicPage.ctrPorMes.endValue).locale('pt-br').format('MMMM')} de
            ${this.onCreateGraphicPage.ctrPorMes.endValue.getFullYear()}`

            this.onCreateGraphicPage.chartsGenerated = true
            const dataRangeValues = []

            /**
             * Alimenta o array dataRangeValues que contem o mês e o total de cada mês
             */
            for (const month of this.getMonths(this.onCreateGraphicPage.ctrPorMes.startValue, this.onCreateGraphicPage.ctrPorMes.endValue)) {
              if (months.filter(value => value.month === month).length > 0) {
                dataRangeValues.push({
                  month: month,
                  total: parseInt(months.filter(value => value.month === month)[0].total)
                })
              } else {
                dataRangeValues.push({
                  month: month,
                  total: 0
                })
              }
            }

            /**
             * Constroi datasource com array dateRangeValues correspondente
             */
            this.onCreateGraphicPage.ctrPorMes.dataSource = new DataSource({
              store: {
                type: 'array',
                key: 'id',
                data: dataRangeValues
              }
            })
            this.setLoading(false)
            this.onCreateGraphicPage.ctrPorMes.chartReady = true
          } catch (error) {
            this.setLoading(false)
            Swal.fire({
              title: 'Erro ao gerar gráfico',
              icon: 'error',
              text: 'Tente novamente'
            })
          }
        }
      }

      /**
       * Caso o gráfico seja por semana
       */
      if (this.onCreateGraphicPage.ctrPorSemana.checkboxValue) {
        if (!this.onCreateGraphicPage.ctrPorSemana.startValue) {
          this.warning = true
          this.warningMessage = 'Não foi possível gerar gráfico de CTRs por semana, o campo de data incial é obrigatório'
        } else if (!this.onCreateGraphicPage.ctrPorSemana.endValue) {
          this.warning = true
          this.warningMessage = 'Não foi possível gerar gráfico de CTRs por semana, o campo de data final é obrigatório'
        } else {
          try {
            this.setLoading(true)

            /**
             * Chamada do command calculateCTR responsável por calcular ctr canceladas dado um intervalo de tempo
             * parametro startDate parametro de data inicial
             * parametro endDate parametro de data final
             * parametro type parametro do tipo de retorno dos intervalos ('year' , 'month' ou 'weekDays')
             * parametro solicitante contendo array de ids dos soliciantes caso encontre algum no campo de busca CPF ou CNPJ
             */
            const res = await this.calculateCTRCanceladas({
              startDate: `${moment(this.onCreateGraphicPage.ctrPorSemana.startValue).format('YYYY-MM-DD')}`,
              endDate: `${moment(this.onCreateGraphicPage.ctrPorSemana.endValue).format('YYYY-MM-DD')}`,
              type: 'weekDays',
              solicitante: (this.onCreateGraphicPage.findSolicitanteValue && this.onCreateGraphicPage.findSolicitanteValue !== 'Solicitante não encontrado') ? this.solicitantesId : undefined
            })

            this.onCreateGraphicPage.ctrPorSemana.range = `${moment(this.onCreateGraphicPage.ctrPorSemana.startValue).format('DD/MM/YYYY')} até ${moment(this.onCreateGraphicPage.ctrPorSemana.endValue).format('DD/MM/YYYY')}`

            const dates = res.data.data.rows.map(value => {
              return {
                date: new Date(`${value.ano}-${value.mes}-${value.dia}`),
                total: parseInt(value.total)
              }
            })

            const dataRangeValues = []
            const weeks = ['Seg', 'Ter', 'Quar', 'Qui', 'Sex', 'Sáb', 'Dom']

            /**
             * Alimenta o array dataRangeValues que contém todos os dias de semana com valor total iniciado
             */
            for (const week of weeks) {
              dataRangeValues.push({
                week,
                total: 0
              })
            }

            /**
             * Alimenta a propiedade "total" do array de objetos dates caso encontre um CTR cancelada naquele dia específico
             */
            dates.forEach(value => {
              if (moment(value.date).format('dddd') === 'Monday') {
                dataRangeValues.find(date => date.week === 'Seg').total = value.total
              }
              if (moment(value.date).format('dddd') === 'Tuesday') {
                dataRangeValues.find(date => date.week === 'Ter').total = value.total
              }
              if (moment(value.date).format('dddd') === 'Wednesday') {
                dataRangeValues.find(date => date.week === 'Quar').total = value.total
              }
              if (moment(value.date).format('dddd') === 'Thursday') {
                dataRangeValues.find(date => date.week === 'Qui').total = value.total
              }
              if (moment(value.date).format('dddd') === 'Friday') {
                dataRangeValues.find(date => date.week === 'Sex').total = value.total
              }
              if (moment(value.date).format('dddd') === 'Saturday') {
                dataRangeValues.find(date => date.week === 'Sáb').total = value.total
              }
              if (moment(value.date).format('dddd') === 'Sunday') {
                dataRangeValues.find(date => date.week === 'Dom').total = value.total
              }
            })
            this.onCreateGraphicPage.chartsGenerated = true
            this.onCreateGraphicPage.ctrPorSemana.dataSource = new DataSource({
              store: {
                type: 'array',
                key: 'id',
                data: dataRangeValues
              }
            })
            this.setLoading(false)
            this.onCreateGraphicPage.ctrPorSemana.chartReady = true
          } catch (error) {
            this.setLoading(false)
            Swal.fire({
              title: 'Erro ao gerar gráfico',
              icon: 'error',
              text: 'Tente novamente'
            })
          }
        }
      }
    },

    /**
     * Método graphicCtrPorAnoCheckBox que é acionado quando o checkbox de CTR por ano é alterado
     * Caso o checkbox esteja desativado remove o gráfico correspondente
     * @params {Object} data objeto contendo os valores do evento nativo do devextreme
     */
    graphicCtrPorAnoCheckBox (data) {
      if (!data.value) {
        this.onCreateGraphicPage.ctrPorAno = {
          isValid: false,
          chartReady: false,
          dataSource: null,
          checkboxValue: false,
          startValue: null,
          endValue: null
        }
      }
    },

    /**
     * Método graphicCtrPorSemanaCheckBox que é acionado quando o checkbox de CTR por semana é alterado
     * Caso o checkbox esteja desativado remove o gráfico correspondente
     * @params {Object} data objeto contendo os valores do evento nativo do devextreme
     */
    graphicCtrPorSemanaCheckBox (data) {
      if (!data.value) {
        this.onCreateGraphicPage.ctrPorSemana = {
          isValid: false,
          chartReady: false,
          dataSource: null,
          checkboxValue: false,
          startValue: null,
          endValue: null,
          range: null
        }
      }
    },

    /**
     * Método graphicCtrPorMesCheckBox que é acionado quando o checkbox de CTR por mês é alterado
     * Caso o checkbox esteja desativado remove o gráfico correspondente
     * @params {Object} data objeto contendo os valores do evento nativo do devextreme
     */
    graphicCtrPorMesCheckBox (data) {
      if (!data.value) {
        this.onCreateGraphicPage.ctrPorMes = {
          isValid: false,
          chartReady: false,
          dataSource: null,
          checkboxValue: false,
          startValue: null,
          endValue: null,
          ano: null,
          range: null
        }
      }
    },

    /**
     * Método createGraphics que é acionado quando botão "Criar Gráficos Estatísticos" é acionado na tela de grid
     * responsável por mudar para o modo de geração de gráfico
     */
    createGraphics () {
      this.onCreatingGraphic = true
    },

    /**
     * Método save que é acionado quando botão "salvar" é acionado na tela de edição de alguma CTR com cancelamento solicitado
     * responsável mandar a requisição com os parametros configurados.
     */
    async save () {
      const doRequest = async (situacao) => {
        try {
          let motivoRecSolCancelamento
          const values = {
            situacao
          }
          if (situacao === 'Cancelamento Recusado') {
            motivoRecSolCancelamento = this.onEditCTRPage.motivoRecusa
          }
          if (values.situacao === 'Cancelada') {
            await http.put(`/SolicitacaoTransporteAceitar/${this.id}`)
          } else if (values.situacao === 'Cancelamento Recusado') {
            await http.put(`/SolicitacaoTransporteRecusar/${this.id}`, { motivoRecSolCancelamento })
          } else if (values.situacao === 'Destinada') {
            await http.put(`/SolicitacaoTransporteDestinar/${this.id}`)
          }
          // await this.updateRow({
          //   entity: this.entity,
          //   values,
          //   key: this.id
          // })
          this.$router.push({ path: '/cancelamento-ctr/' })
          this.onEditCTRPage.motivoCancelamento = null
          this.onEditCTRPage.motivoRecusa = null
        } catch (error) {
          this.onEditCTRPage.motivoCancelamento = null
          this.onEditCTRPage.motivoRecusa = null
          Swal.fire({
            title: 'Erro ao salvar dados',
            icon: 'error',
            text: 'Verifique os campos e tente novamente'
          })
        }
      }
      if (!this.onEditCTRPage.selectedCTRGroup) {
        Swal.fire({
          title: 'Campos inválidos',
          icon: 'error',
          text: 'É preciso aceitar, recusar ou destinar a CTR'
        })
      }
      if (!this.onEditCTRPage.motivoRecusa && this.onEditCTRPage.selectedCTRGroup === 'Recusar') {
        Swal.fire({
          title: 'Campos inválidos',
          icon: 'error',
          text: 'Insira um motivo para a recusa do cancelamento'
        })
      }

      if (this.onEditCTRPage.selectedCTRGroup) {
        let status
        if (this.onEditCTRPage.selectedCTRGroup === 'Aceitar') {
          status = 'Cancelada'
          doRequest(status)
        }
        if (this.onEditCTRPage.selectedCTRGroup === 'Recusar') {
          status = 'Cancelamento Recusado'
          if (this.onEditCTRPage.motivoRecusa) {
            doRequest(status)
          }
        }
        if (this.onEditCTRPage.selectedCTRGroup === 'Destinar') {
          status = 'Destinada'
          doRequest(status)
        }
      }
    }
  },
  async mounted () {
    await this.$nextTick(() => {
      /* const gridInstance = this.$refs.simpleGrid.getDataGridInstance()
      gridInstance.filter([
        ['situacao', '=', 'Cancelada']
      ]) */
    })
  }
}
</script>

<style scoped lang="scss">
.graficoFieldSet {
  width: 100%;
}

.cancelamentoCtrBox {
  width: 250px;
  padding-top: 27px;
  padding-left: 25px;
}

.recusarArea {
  padding-left: 23px;
}

.recusarFieldBox {
  height: 80px;
  align-items: center;
  position: relative;
  align-content: center;
  top: 27px;
}

.searchButton {
  position: relative;
  left: 20px;
  top: -2px;
  background-color: rgb(255, 132, 2);
}

.searchButton:not(:first-child) {
  margin-right: 10px
}

.cancelamentoRadioBox {
  width: 390px;
  padding-left: 25px;
}

.solCancelamentoBox {
  display: inline-grid;
}

.saveButton {
  position: relative;
  left: 709px;
  top: 15px;
}

.addClasseForm {
  padding: 11px;
  column-count: 2;
}

.error-message-card {
  background-color: #B00020 !important;
  color: white !important;
}

.md-app-content .md-card {
  margin-right: 16px;
  margin-left: 2px;
  overflow: visible;
  top: 20px;
}

.auxDataGrid {
  padding: 17px;
}

.md-button.md-theme-default.md-raised:not([disabled]).md-accent {
  position: relative;
  left: 85%;
  color: #fff;
  color: var(--md-theme-default-text-primary-on-accent, #fff);
  background-color: #008E32;
  background-color: var(--md-theme-default-accent, #008E32);
}

.searchCPFCNPJGroup {
  display: block;
  width: 100%;
  padding-top: 180px;
  padding-left: 40px;
}

.nomeSolicianteResult {
  display: block;
  width: 100%;
  padding-left: 36px;
  padding-top: 15px;
  padding-bottom: 15px;
}
</style>
