import { getParamsFromLoadOptions } from '../../../util/loadOptionsParams'
import { doRequest } from '../../../pluggables/http'

/**
 * Metodo de comando, com o objetivo de realizar a requisica de obter uma lista
 * de transportadores relacionados a cacambas.
 * @param {Object} params - Os parametros da requisicao.
 * @param {Object} params.loadOptions - Os parametros de busca do
 * @param {string[]} params.fields - Os campos que vao ser retornados para
 * apresentar os valores.
 * datasource do devextreme.
 * @returns {Object} O resultado da requisicao.
 */
export async function getTransportadoresCacambas ({ subQuery = false, loadOptions, fields, where = {} }) {
  const params = getParamsFromLoadOptions(loadOptions)
  params.where = { ...params.where }

  const res = await doRequest({
    method: 'post',
    url: 'Pessoa/find',
    params: {
      ...params,
      subQuery,
      attributes: fields,
      where: {
        ...where,
        ...params.where
      },
      include: [{
        model: 'Perfil',
        as: 'perfis',
        attributes: [],
        required: true,
        include: {
          model: 'SolicitacaoTransporte',
          as: 'solicitacoesTransporte',
          attributes: [],
          required: true,
          include: {
            model: 'Cacamba',
            as: 'cacamba',
            attributes: [],
            required: true
          }
        }
      }]
    }
  })

  if (!res.success) {
    throw new Error(res.error)
  }
  return { data: res.data.rows, totalCount: res.data.count }
}

/**
 * Metodo de comando, com o objetivo de realizar a requisica de obter uma lista
 * de cacambas com solicitacoes.
 * @param {Object} params - Os parametros da requisicao.
 * @param {Object} [params.loadOptions=null] - Os parametros de busca do
 * datasource do devextreme.
 * @param {string[]} params.fields - Os campos que vao ser retornados para
 * apresentar os valores.
 * @returns {Object} O resultado da requisicao.
 */
export async function getCacambasSolicitacao ({ loadOptions, fields, subQuery }) {
  const params = getParamsFromLoadOptions(loadOptions)
  params.where = { ...params.where }

  const res = await doRequest({
    method: 'post',
    url: 'ViewSituacaoCacamba/find',
    params: {
      ...params,
      fields,
      subQuery,
      where: params.where
    }
  })

  if (!res.success) {
    throw new Error(res.error)
  }

  return { data: res.data.rows, totalCount: res.data.count }
}

/**
 * Metodo de comando, com o objetivo de realizar a requisica de obter uma lista
 * de cacambas.
 * @param {Object} loadOptions - Os parametros de busca do
 * datasource do devextreme.
 * @returns {Object} O resultado da requisicao.
 */
export async function getCacambas (loadOptions) {
  const params = getParamsFromLoadOptions(loadOptions)
  params.include = JSON.stringify([
    {
      model: 'Perfil',
      as: 'transportador',
      attributes: ['id'],
      include: {
        model: 'Pessoa',
        as: 'pessoa',
        attributes: ['id', 'nomeRazaoSocial', 'endereco', 'cep'],
        duplicating: false
      }
    }
  ])
  params.fields = ['id', 'situacao', 'numeroIdentificacao', 'capacidadeM3', 'nomeRazaoSocial', 'idSolicitacaoTransporte', 'enderecoDeposito', 'cepDeposito', 'endereco']
  const res = await doRequest({
    method: 'post',
    url: 'ViewSituacaoCacamba/find',
    params
  })

  if (!res.success) {
    throw new Error(res.error)
  }

  return { data: res.data.rows, totalCount: res.data.count }
}

/**
 * Metodo de comando, com o objetivo de realizar a requisica de obter uma lista
 * de quantidade de cacambas categorizadas pela situacao.
 * @returns {Object} O resultado da requisicao.
 */
export function listCacambasBySituacao () {
  return doRequest({
    method: 'post',
    url: 'ViewSituacaoCacamba/find',
    params: {
      attributes: [
        { fn: 'DISTINCT', col: 'situacao', as: 'situacaoDistinct' },
        { fn: 'COUNT', col: 'id', as: 'count' }
      ],
      group: ['situacao'],
      subQuery: false
    }
  })
}

/**
 * Metodo de comando, com o objetivo de realizar a requisica de obter uma lista
 * de cacambas com o shape da cacamba.
 * @param {Object} params - Os parametros da requisicao.
 * @returns {Object} O resultado da requisicao.
 */
export function listCacambasWithShapeWhereSituacao ({ situacoes, ids, shape }) {
  return doRequest({
    method: 'post',
    url: 'CacambaLocation/find',
    params: {
      where: {
        situacoes,
        ids,
        shape
      }
    }
  })
}

/**
 * Metodo de comando, com o objetivo de realizar a requisica de obter os dados
 * da situacao de uma cacamba.
 * @param {number} id - O id da cacamba.
 * @returns {Object} O resultado da requisicao.
 */
export function getSituacaoCacambaById (id, idObra) {
  return doRequest({
    single: true,
    method: 'post',
    url: 'ViewSituacaoCacamba/find',
    params: {
      where: {
        id,
        idObra
      },
      include: JSON.stringify([
        {
          model: 'Obra',
          as: 'obra',
          attributes: ['id'],
          include: {
            model: 'Perfil',
            as: 'gerador',
            attributes: ['id'],
            include: {
              model: 'Pessoa',
              as: 'pessoa',
              attributes: ['nomeRazaoSocial']
            }
          }
        },
        {
          model: 'Perfil',
          as: 'transportador',
          attributes: ['id'],
          include: {
            model: 'Pessoa',
            as: 'pessoa',
            attributes: ['nomeRazaoSocial']
          }
        }
      ])
    }
  })
}
