<template>
  <div>
    <span/>
  </div>
</template>
<script>
import { mapMutations, mapState, mapActions } from 'vuex'

export default {
  name: 'VuexWrapper.vue',
  data () {
    return {}
  },
  computed: {
    ...mapState('Login', ['user'])
  },
  methods: {
    ...mapMutations('Login', ['updateUser', 'updateUserPessoa']),
    ...mapActions('Crud', ['find', 'findId', 'findOne', 'insertRow', 'removeRow', 'updateRow', 'setLoading']),
    ...mapActions('Login', ['getUserLogged'])
  }
}
</script>

<style scoped>

</style>
