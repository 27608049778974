
/**
 * Método para criar objeto com os parâmetros de ordenação a partir dos
 * parametros de ordem do devextreme
 * @param {Array} sort - Os parametros de ordenação do devextreme.
 */
export function parseSortParams (sort) {
  const result = []
  if (sort && sort.length) {
    for (const col of sort) {
      if (typeof col.selector === 'string') {
        let sortParams = col.selector.split('.')
        sortParams.push(col.desc ? 'DESC' : 'ASC')

        sortParams = sortParams.map(p => {
          if (p.match('{')) {
            p = JSON.parse(p)
          }
          return p
        })

        result.push(sortParams)
      }
    }
  }

  return JSON.stringify(result)
}

/**
 * Metodo para criar o objeto com os parametros da requisicao apartir dos
 * parametros de busca do devextreme.
 * @param {Object} filter - Os parametros de busca do datasource do devextreme.
 * @param {Object} [options] - Opcoes do parser.
 * @param {boolean} [options.invertOperators=false] - Se deve inverter os
 * operadores.
 */
export function parseFilterParams (filter, { invertOperators = false } = {}) {
  let result = {}
  let subQuery = true

  if (filter) {
    if (filter.length === 3 && !Array.isArray(filter[0])) {
      if (filter[1] !== 'contains') {
        const operators = !invertOperators ? {
          '=': 'eq',
          '<>': 'ne',
          '<': 'lt',
          '<=': 'lte',
          '>': 'gt',
          '>=': 'gte',
          startswith: 'startsWith',
          endswith: 'endsWith'
        } : {
          '=': 'ne',
          '<>': 'eq',
          '<': 'gte',
          '<=': 'gt',
          '>': 'lte',
          '>=': 'lt',
          startswith: 'startsWith',
          endswith: 'endsWith'
        }

        result = {
          [filter[0]]: {
            [operators[filter[1]] ? operators[filter[1]] : filter[1]]: filter[2]
          }
        }
      } else {
        result = {
          [filter[0]]: {
            [invertOperators ? 'notILike' : 'iLike']: `%${filter[2]}%`
          }
        }
      }
    } else if (filter.includes('and') || filter.includes('or')) {
      subQuery = false
      const grouping = !invertOperators
        ? (filter.includes('and') ? 'and' : 'or')
        : (filter.includes('and') ? 'or' : 'and')

      result[grouping] = []
      for (const cond of filter) {
        if (Array.isArray(cond)) {
          result[grouping].push(parseFilterParams(cond, { invertOperators }).filterParams)
        }
      }
    } else if (filter.length === 2 && filter[0] === '!' && Array.isArray(filter[1])) {
      result = parseFilterParams(filter[1], { invertOperators: true }).filterParams
    }
  }

  return {
    filterParams: result,
    subQuery: subQuery
  }
}

/**
 * Metodo para criar o objeto com os parametros da requisicao apartir dos
 * parametros de busca do devextreme, para consultas RAW SQL
 * @param {Object} filter - Os parametros de busca do datasource do devextreme.
 */
export function parseFilterParamsSQL (filter) {
  let result = {}

  const formatDate = (datas) => {
    const data = new Date(datas)
    const dia = data.getDate().toString()
    const diaF = (dia.length === 1) ? '0' + dia : dia
    const mes = (data.getMonth() + 1).toString()
    const mesF = (mes.length === 1) ? '0' + mes : mes
    const anoF = data.getFullYear()
    return anoF + '-' + mesF + '-' + diaF
  }

  if (filter && filter.length === 3 && !Array.isArray(filter[0])) {
    /**
   * funcao para tratar data para formato aceito pelo banco
   */
    if (filter[2].toString().match(/[0-9]{2}:[0-9]{2}:[0-9]{2}/)) {
      filter[2] = formatDate(filter[2])
    }
    const op = filter[1] ? filter[1] : filter[1]
    if (op !== 'contains') {
      result = filter[0] + ' ' + [op] + ' \'' + filter[2] + '\''
    } else {
      result = filter[0] + ' Like \'%' + filter[2] + '%\''
    }
  } else if (filter.includes('and') || filter.includes('or')) {
    result = ''
    for (const cond of filter) {
      if (Array.isArray(cond)) {
        result = result + ' ' + parseFilterParamsSQL(cond)
      } else {
        result = result + ' ' + cond
      }
    }
  }
  return result
}
