<template>
  <dx-popup
    :hide-on-outside-click="true"
    :drag-enabled="false"
    height="auto"
    min-width="800px"
    width="80vw"
    :show-title="true"
    title="Dados da situação da caçamba"
    :visible.sync="popupActive">
    <template v-slot:content>
      <div v-if="cacambaData" class="cacamba-situacao-popup-content">
        <span class="cacamba-situacao">
          <strong>Situacao: </strong>
          {{ cacambaData.situacao }}
        </span>
        <span class="cacamba-num-ctr">
          <strong>Nº do CTR: </strong>
          {{ cacambaData.idSolicitacaoTransporte }}
        </span>
        <span class="cacamba-num-identificacao">
          <strong>Nº de Identificação: </strong>
          {{ cacambaData.numeroIdentificacao }}
        </span>
        <span class="cacamba-solicitante">
          <strong>Solicitante: </strong>
          {{ solicitante }}
        </span>
        <span class="cacamba-transportador">
          <strong>Transportador: </strong>
          {{ transportador }}
        </span>

        <!-- Data do envio -->
        <span class="cacamba-data-envio-title">
          <strong>Data do envio da caçamba para o solicitante: </strong>
        </span>
        <span class="cacamba-data-envio-date">{{ dataEnvio }}</span>
        <span class="cacamba-data-envio-days">{{ diasEnvio }}</span>

        <template v-if="isRetiradaOrDestinada">
          <!-- Data da retirada -->
          <span class="cacamba-data-retirada-title">
            <strong>Data de retirada da caçamba do solicitante: </strong>
          </span>
          <span class="cacamba-data-retirada-date">{{ dataRetirada }}</span>
          <span class="cacamba-data-retirada-days">{{ diasRetirada }}</span>

          <!-- Data do destino final -->
          <span class="cacamba-data-destino-final-title">
            <strong>Data do destino final da caçamba: </strong>
          </span>
          <span class="cacamba-data-destino-final-date">{{ dataDestinoFinal }}</span>
          <span class="cacamba-data-destino-final-days">{{ diasDestinoFinal }}</span>
        </template>
      </div>
      <message-error v-else-if="popupErrorMessage" :message.sync="popupErrorMessage"/>
    </template>
  </dx-popup>
</template>
<script>
import { DxPopup } from 'devextreme-vue'
import _ from 'lodash'
import moment from 'moment'
import { mapActions } from 'vuex'

import { getSituacaoCacambaById } from '../service'
import MessageError from '../../../../components/MessageError'

export default {
  name: 'popup-cancel-solicitacao',
  components: {
    DxPopup,
    MessageError
  },
  props: {
    /**
     * Se o popup esta ativo.
     */
    active: {
      type: Boolean,
      required: true
    },

    /**
     * A cacamba que sera mostrada.
     */
    cacamba: {
      type: Object
    }
  },
  /**
   * Metodo do vue, para obter o estado inicial do componente.
   * @returns {Object} O estado inicial do componente.
   */
  data () {
    return {
      cacambaData: null,
      popupErrorMessage: null
    }
  },
  computed: {
    /**
     * Valor computado de se o popup esta ativo.
     * @returns {boolean}
     */
    popupActive: {
      get () {
        return this.active
      },
      set (v) {
        this.$emit('update:active', v)
      }
    },

    /**
     * Valor computado do solicitante da cacamba.
     */
    solicitante () {
      return _.get(this.cacambaData, 'obra.gerador.pessoa.nomeRazaoSocial')
    },

    /**
     * Valor computado do transportador da cacamba.
     */
    transportador () {
      return _.get(this.cacambaData, 'transportador.pessoa.nomeRazaoSocial')
    },

    /**
     * Valor computado da data de envio da cacamba.
     */
    dataEnvio () {
      return this.cacambaData && this.cacambaData.dataHoraEnvio
        ? moment(this.cacambaData.dataHoraEnvio).format('DD/MM/YYYY - HH:mm')
        : '-'
    },

    /**
     * Valor computado da quantidade de dias que a cacamba foi enviada.
     */
    diasEnvio () {
      if (!this.cacambaData || !this.cacambaData.dataHoraSolicitacao || !this.cacambaData.dataHoraEnvio) {
        return '-'
      }

      const dataSolicitacao = moment(this.cacambaData.dataHoraSolicitacao)
      const dataEnvio = moment(this.cacambaData.dataHoraEnvio)
      return dataEnvio.diff(dataSolicitacao, 'days') + ' dias'
    },

    /**
     * Valor computado da data de retirada da cacamba.
     */
    dataRetirada () {
      return this.cacambaData && this.cacambaData.dataHoraRetirada
        ? moment(this.cacambaData.dataHoraRetirada).format('DD/MM/YYYY - HH:mm')
        : '-'
    },

    /**
     * Valor computado da quantidade de dias que a cacamba foi retirada.
     */
    diasRetirada () {
      if (!this.cacambaData || !this.cacambaData.dataHoraAlocacao || !this.cacambaData.dataHoraRetirada) {
        return '-'
      }

      const dataAlocacao = moment(this.cacambaData.dataHoraAlocacao)
      const dataRetirada = moment(this.cacambaData.dataHoraRetirada)
      return dataRetirada.diff(dataAlocacao, 'days') + ' dias'
    },

    /**
     * Valor computado da data que a cacamba foi para o destino final.
     */
    dataDestinoFinal () {
      return this.cacambaData && this.cacambaData.dataHoraRecebimento
        ? moment(this.cacambaData.dataHoraRecebimento).format('DD/MM/YYYY - HH:mm')
        : '-'
    },

    /**
     * Valor computado da quantidade de dias em que a cacamba chegou no destino
     * final.
     */
    diasDestinoFinal () {
      if (!this.cacambaData || !this.cacambaData.dataHoraRetirada || !this.cacambaData.dataHoraRecebimento) {
        return '-'
      }

      const dataRetirada = moment(this.cacambaData.dataHoraRetirada)
      const dataRecebimento = moment(this.cacambaData.dataHoraRecebimento)
      return dataRecebimento.diff(dataRetirada, 'days') + ' dias'
    },

    /**
     * Valor computado de se a cacamba foi retirada ou destinada.
     */
    isRetiradaOrDestinada () {
      return this.cacambaData &&
        ['Retirada', 'Destinada'].includes(this.cacambaData.situacao)
    }
  },
  watch: {
    /**
     * Metodo de callback de mudancas no valor da prop 'active', com o objetivo
     * de buscar os dados de veiculo e cacamba.
     * @param {boolean} active - O valor atual da prop.
     */
    async cacamba (cacamba) {
      this.setLoading(true)
      this.popupErrorMessage = null
      this.cacambaData = null

      if (cacamba) {
        const cacambaResult = await getSituacaoCacambaById(cacamba.id, cacamba.idObra)

        if (cacambaResult.success) {
          this.cacambaData = cacambaResult.data
        } else {
          this.popupErrorMessage = cacambaResult.error
        }
      } else {
        this.cacambaData = null
      }
      this.setLoading(false)
    }
  },
  methods: {
    ...mapActions('Crud', ['setLoading'])
  }
}
</script>

<style lang="scss">
@import "../../../../global.scss";

.cacamba-situacao-popup-content {
  display: grid;
  grid-template:
  "situacao . ." 1fr
  "numero-ctr numero-identificacao ." 1fr
  "solicitante . ." 1fr
  "transportador . ." 1fr
  "data-envio-title data-envio-value data-envio-days" 1fr
  "data-retirada-title data-retirada-value data-retirada-days" 1fr
  "data-destino-title data-destino-value data-destino-days" 1fr
  / 2fr 1fr 2fr;

  .cacamba-situacao {
    grid-area: situacao;
  }

  .cacamba-num-ctr {
    grid-area: numero-ctr;
  }

  .cacamba-num-identificacao {
    grid-area: numero-identificacao;
  }

  .cacamba-solicitante {
    grid-area: solicitante;
  }

  .cacamba-transportador {
    grid-area: transportador;
  }

  .cacamba-data-envio-title {
    grid-area: data-envio-title;
  }

  .cacamba-data-envio-date {
    grid-area: data-envio-value;
  }

  .cacamba-data-envio-days {
    grid-area: data-envio-days;
  }

  .cacamba-data-retirada-title {
    grid-area: data-retirada-title;
  }

  .cacamba-data-retirada-date {
    grid-area: data-retirada-value;
  }

  .cacamba-data-retirada-days {
    grid-area: data-retirada-days;
  }

  .cacamba-data-destino-final-title {
    grid-area: data-destino-title;
  }

  .cacamba-data-destino-final-date {
    grid-area: data-destino-value;
  }

  .cacamba-data-destino-final-days {
    grid-area: data-destino-days;
  }

  .error-message-card {
    background-color: #B00020 !important;
    color: white !important;
    width: 100%;
    margin-top: 6px;
    margin-bottom: 16px;
  }
}
</style>
