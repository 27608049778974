
import { getParamsFromLoadOptions } from '../../../util/loadOptionsParams'
import { doRequest } from '../../../pluggables/http'

/**
 *TODO
 */
export function generateGraphics ({ starValue, endValue, responsavel, situacaoOcorrencia, irregularidadeCom }) {
  return doRequest({
    method: 'post',
    url: 'acompanhar-ocorrencia/generateGraphics',
    params: {
      starValue,
      endValue,
      responsavel,
      situacaoOcorrencia,
      irregularidadeCom
    }
  })
}
/**
 *TODO
 */
export function sendEmail ({ resposta, email }) {
  return doRequest({
    method: 'post',
    url: 'acompanhar-ocorrencia/sendEmail',
    params: {
      email,
      resposta
    }
  })
}
/**
 *TODO
 */
export function enviarRespostaOcorrencia ({ resposta, id, improcedente }) {
  return doRequest({
    method: 'put',
    url: `Ocorrencia/${id}`,
    params: {
      respostaOrgao: resposta,
      improcedente,
      situacao: 'Respondida'
    }
  })
}

/**
 *TODO
 */
export function salvarRespostaOcorrencia ({ resposta, id, improcedente }) {
  return doRequest({
    method: 'put',
    url: `Ocorrencia/${id}`,
    params: {
      respostaOrgao: resposta,
      improcedente,
      situacao: 'Em andamento'
    }
  })
}

export function findAllUserCidadao () {
  return doRequest({
    method: 'post',
    url: 'ViewPerfilUsuario/find',
    params: {
      fields: ['idPessoa', 'nome', 'idUsuario', 'idPerfil'],
      where: {
        idTipoPerfil: 7
      }
    }
  })
}

export function findAllUserFiscalizacao () {
  return doRequest({
    method: 'post',
    url: 'ViewPerfilUsuario/find',
    params: {
      fields: ['idPessoa', 'nome', 'idUsuario', 'idPerfil'],
      where: {
        idTipoPerfil: 5
      }
    }
  })
}
/**
 * Metodo de comando, com o objetivo de realizar a requisica de obter uma lista
 * de cacambas com o shape da cacamba.
 * @param {Object} params - Os parametros da requisicao.
 * @returns {Object} O resultado da requisicao.
 */
export function findOcorrencias ({ ids, shape, idUsersCidadao }) {
  return doRequest({
    method: 'post',
    url: 'acompanhar-ocorrencia/find-ocorrencias',
    params: {
      shape,
      idUsersCidadao
    }
  })
}
/**
 * Metodo de comando, com o objetivo de realizar a requisica de obter uma lista
 * de cacambas com o shape da cacamba.
 * @param {Object} params - Os parametros da requisicao.
 * @returns {Object} O resultado da requisicao.
 */
export function saveOcorrenciaCacamba ({ ids, shape }) {
  return doRequest({
    method: 'post',
    url: 'registrar-ocorrencia/find-cacambas',
    params: {
      shape
    }
  })
}

/**
 *
 *
 *
 *
 *
 * Metodo de comando, com o objetivo de realizar a requisica de obter uma lista
 * de veiculos com solicitacao de transporte que pode retirar a cacamba.
 * @param {Object} params - Os parametros da requisicao.
 * @param {Object} [params.loadOptions=null] - Os parametros de busca do
 * datasource do devextreme.
 * @param {string[]} params.fields - Os campos que vao ser retornados para
 * apresentar os valores.
 * @returns {Object} O resultado da requisicao.
 */
export async function getFotoOcorrencia (ocorrencia) {
  const params = getParamsFromLoadOptions()
  params.fields = ['foto']
  params.where = {
    ...params.where,
    id: ocorrencia.id
  }

  const res = await doRequest({
    method: 'post',
    url: 'Ocorrencia/find',
    params
  })

  return { data: res.data.rows, totalCount: res.data.count }
}

/**
 *
 *
 *
 *
 *
 * Método com o objetivo de obter a lista de ocorrências
 * @param {Object} loadOptions - Os parametros de busca do
 * datasource do devextreme.
 * @returns {Object} O resultado da requisicao.
 */
export async function getOcorrencias (loadOptions, userData, utils) {
  const params = getParamsFromLoadOptions(loadOptions)
  params.fields = ['id', 'idUsuarioRegistro', 'nomeUsuarioRegistro', 'dataHoraRegistro', 'irregularidadeCom', 'situacao', 'local', 'respostaOrgao', 'descricaoIrregularidade', 'idCacamba', 'cpfCnpj', 'improcedente', 'nomeRazaoSocial', 'emailUsuarioRegistro', 'shape', 'numeroIdentificacao']
  params.order = params.order ? params.order : JSON.stringify([['id', 'DESC']])
  const res = await doRequest({
    method: 'post',
    url: 'ViewOcorrencia/find',
    params
  })
  return { data: res.data.rows, totalCount: res.data.count }
}
