import store from '../store/index'

/**
 * Metodo de verificacao de se o usuario logado tem um tipo de permissao em uma
 * descrição.
 * @param {string} descricao - A descrição a ser verificada.
 * @param {string} permissionType - O tipo de permissao a ser verificada.
 * @returns {boolean} Se o usuario tem a permissao.
 */
export function hasPermission (descricao, permissionType) {
  const userData = store.getters['Login/userData']
  const transportadorValidRoutes = ['Editar meus dados', 'Documentos']
  const perfilAtivo = Array.isArray(userData.perfis) ? userData.perfis.some(perfil => perfil.situacaoValidacao === 'A' || perfil.situacaoValidacao === 'P') : false
  const permission = Boolean(descricao &&
        permissionType &&
        store.getters['Login/userPermissons'].find(perm =>
          perm.funcionalidade.descricao === descricao &&
          perm[permissionType] === 'S'))
  return (permission && perfilAtivo) || (Array.isArray(userData.userTypes) ? (userData.userTypes.includes('Transportador') && transportadorValidRoutes.includes(descricao)) : false)
}
