<template>
  <div class="generic-crud">
    <div>
      <div class="md-layout">
        <div class="md-layout-item">
          <h2 class="crud-title">Consultar volume de resíduos enviados por região para o destino final</h2>
        </div>
      </div>
      <div v-if="!onCreatingGraphic">
        <simple-grid entity="Pessoa" popup-title="Pessoa" :grid-data="gridData" :deletable="false" :editable="false"></simple-grid>
        <div class="md-layout-item" style="text-align: right;">
          <dx-button type="success" @click="createGraphics">Criar Gráficos Estatísticos </dx-button>
        </div>
      </div>
      <fieldset class="graficoFieldSet" v-if="onCreatingGraphic">
        <legend>Gerar Gráfico do Volume em M³ por</legend>
        <div>
            <div style="display: block; width: 100%" class="dx-field-label">
              <DxCheckBox
                :acceptCustomValue="false"
                @value-changed="graphicporAnoCheckBox"
                :value.sync="onCreateGraphicPage.porAno.checkboxValue"
                text="Ano "/>
              <DxDateBox
                :acceptCustomValue="false"
                placeholder="Ano"
                style="display: inline-block;"
                display-format="yyyy"
                :calendar-options="{
                  zoomLevel: 'year',
                  minZoomLevel: 'century',
                  maxZoomLevel: 'decade'
                }"
                :disabled.sync="!onCreateGraphicPage.porAno.checkboxValue"
                :value.sync="onCreateGraphicPage.porAno.startValue"
                :max.sync="onCreateGraphicPage.porAno.endValue"
                width="180"
                type="date"/>
              <DxDateBox
                :acceptCustomValue="false"
                placeholder="Ano"
                style="display: inline-block; left: 6px;"
                display-format="yyyy"
                :calendar-options="{
                  zoomLevel: 'year',
                  minZoomLevel: 'century',
                  maxZoomLevel: 'decade'
                }"
                :disabled.sync="!onCreateGraphicPage.porAno.checkboxValue"
                :value.sync="onCreateGraphicPage.porAno.endValue"
                :min.sync="onCreateGraphicPage.porAno.startValue"
                width="180"
                type="date"/>
            </div>

            <div style="display: block; width: 100%" class="dx-field-label">
              <DxCheckBox
                :acceptCustomValue="false"
                @value-changed="graphicporMesCheckBox"
                :value.sync="onCreateGraphicPage.porMes.checkboxValue"
                text="Mês "/>
              <DxDateBox
                :acceptCustomValue="false"
                placeholder="Mês/ano"
                style="display: inline-block;"
                display-format="monthAndYear"
                  :calendar-options="{
                    zoomLevel: 'year',
                    minZoomLevel: 'decade',
                    maxZoomLevel: 'year'
                  }"
                :disabled.sync="!onCreateGraphicPage.porMes.checkboxValue"
                :value.sync="onCreateGraphicPage.porMes.startValue"
                :max.sync="onCreateGraphicPage.porMes.endValue"
                width="170"
                type="date"/>

              <DxDateBox
                :acceptCustomValue="false"
                placeholder="Mês/ano"
                style="display: inline-block; left: 6px;"
                display-format="monthAndYear"
                :calendar-options="{
                  zoomLevel: 'year',
                  minZoomLevel: 'decade',
                  maxZoomLevel: 'year'
                }"
                :disabled.sync="!onCreateGraphicPage.porMes.checkboxValue"
                :value.sync="onCreateGraphicPage.porMes.endValue"
                :min.sync="onCreateGraphicPage.porMes.startValue"
                width="170"
                type="date"/>
            </div>

            <div style="display: block; width: 100%" class="dx-field-label">
              <DxCheckBox
                :acceptCustomValue="false"
                @value-changed="graphicporSemanaCheckBox"
                :value.sync="onCreateGraphicPage.porSemana.checkboxValue"
                text="Semana  "/>
              <DxDateBox
                :acceptCustomValue="false"
                placeholder="Dia da semana"
                style="display: inline-block;"
                @value-changed="onAddOneWeek"
                :disabled.sync="!onCreateGraphicPage.porSemana.checkboxValue"
                :value.sync="onCreateGraphicPage.porSemana.startValue"
                :max.sync="onCreateGraphicPage.porSemana.endValue"
                width="180"
                type="date"/>
              <DxDateBox
                :acceptCustomValue="false"
                placeholder="Dia da semana"
                style="display: inline-block;left: 6px;"
                v-model="onCreateGraphicPage.porSemana.endValue"
                :disabled="true"
                width="180"
                type="date"/>
            </div>

            <div class="block-input">
              <div> Região Administrativa:
                <DxLookup
                  :data-source="findRADatasource"
                  display-expr="bairro"
                  v-model="onCreateGraphicPage.findRAValue"
                  width=250
                >
                  <DxDropDownOptions
                    :hide-on-outside-click="true"
                    :show-title="false"
                  />
                </DxLookup>
              </div>
            </div>
        </div>
        <DxToast
          :width="600"
          :displayTime="4000"
          :visible.sync="warning"
          type="warning"
          :message="warningMessage"
        />
        <dx-button v-if="onCreatingGraphic" type="success"
         style="background-color: #FF8402;"
         @click="onGoBack">Voltar</dx-button>
        <dx-button v-if="onCreatingGraphic" type="success"
         style="position: relative; left: 10px;"
         @click="onClearGraphics">Limpar Gráficos</dx-button>
        <dx-button v-if="onCreatingGraphic && (this.onCreateGraphicPage.porAno.checkboxValue || this.onCreateGraphicPage.porMes.checkboxValue || this.onCreateGraphicPage.porSemana.checkboxValue)"
          type="success" style="position: relative; left: 20px;"
          @click="onGenerateGraphics">Gerar Gráficos
        </dx-button>
      </fieldset>
      <div class="graficos">
        <div v-if="onCreateGraphicPage.chartsGenerated && onCreateGraphicPage.porAno.chartReady" class="graficosPorAno">
          <DxChart
            :data-source="onCreateGraphicPage.porAno.dataSource"
            title="Volume em M³ por ano">
          <DxValueAxis
            :allowDecimals="false"
          >
            <DxTitle text="Quantidade"/>
          </DxValueAxis>
          <DxCommonSeriesSettings
            argument-field="year"
            type="stackedbar"/>
          <DxSeries
            value-field="total"
            :name="`Período: ${onCreateGraphicPage.porAno.startValue.getFullYear()} a ${onCreateGraphicPage.porAno.endValue.getFullYear()}`"
            stack="male"/>
            <DxExport :enabled="true"/>
            <DxTooltip :enabled="true"/>
          </DxChart>
        </div>

        <div v-if="onCreateGraphicPage.chartsGenerated && onCreateGraphicPage.porMes.chartReady" class="graficosPorMes">
          <DxChart
            :data-source="onCreateGraphicPage.porMes.dataSource"
            title="Volume em M³ por mês">
          <DxValueAxis
            :allowDecimals="false"
          >
            <DxTitle text="Quantidade"/>
          </DxValueAxis>
          <DxCommonSeriesSettings
            argument-field="month"
            type="stackedbar"/>

          <DxSeries
            value-field="total"
            :name="onCreateGraphicPage.porMes.range"
            stack="month"/>
            <DxExport :enabled="true"/>
            <DxTooltip :enabled="true"/>
          </DxChart>
        </div>

        <div v-if="onCreateGraphicPage.chartsGenerated && onCreateGraphicPage.porSemana.chartReady" class="graficosPorSemana">
          <DxChart
            :data-source="onCreateGraphicPage.porSemana.dataSource"
            title="Volume em M³ por semana">
          <DxValueAxis
            :allowDecimals="false"
          >
            <DxTitle text="Quantidade"/>
          </DxValueAxis>
          <DxCommonSeriesSettings
            value-field="week"
            argument-field="week"
            type="stackedbar"/>
          <DxSeries
            value-field="total"
            :name="onCreateGraphicPage.porSemana.range"
            stack="month"/>
            <DxExport :enabled="true"/>
            <DxTooltip :enabled="true"/>
          </DxChart>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { SimpleGrid } from '@/components'
import CustomStore from 'devextreme/data/custom_store'
import mixinCrud from '../mixin-crud'
import { mapActions } from 'vuex'
import { getParamsFromLoadOptions } from '../../../util/loadOptionsParams'
import { getByKeys } from '../../../util/getByKeys'

import { DxLookup, DxDropDownOptions } from 'devextreme-vue/lookup'
import { DxCheckBox } from 'devextreme-vue/check-box'
import { DxButton, DxDateBox } from 'devextreme-vue'
import DataSource from 'devextreme/data/data_source'
import moment from 'moment'
import Swal from 'sweetalert2'
import { DxChart, DxCommonSeriesSettings, DxSeries, DxValueAxis, DxTitle, DxTooltip, DxExport } from 'devextreme-vue/chart'
import { DxToast } from 'devextreme-vue/toast'

export default {
  name: 'pessoa-crud',
  mixins: [
    mixinCrud
  ],
  components: {
    SimpleGrid,
    DxLookup,
    DxDropDownOptions,
    DxButton,
    DxCheckBox,
    DxDateBox,
    DxChart,
    DxCommonSeriesSettings,
    DxSeries,
    DxValueAxis,
    DxTitle,
    DxTooltip,
    DxExport,
    DxToast
  },
  data () {
    return {
      entity: 'Pessoa',
      permissionName: 'Consultar Volume de Resíduos por Região',
      formData: null,
      warningMessage: null,
      warning: false,
      customFilter: null,
      onCreatingGraphic: false,
      findRADatasource: new CustomStore({
        key: 'id',
        load: () => {
          return this.$utils.wrapRequestForGrid(this.find({
            entity: 'Pessoa',
            params: {
              fields: ['id', 'bairro'],
              where: {
                '$perfis.id_tipo_perfil$': 3
              },
              include: JSON.stringify([
                {
                  model: 'Perfil',
                  as: 'perfis',
                  required: true,
                  attributes: ['id']
                }
              ])
            }
          }))
        },
        byKey: async key => {
          const res = await getByKeys({
            urlModel: 'Pessoa',
            where: { bairro: key },
            displayField: 'bairro'
          })
          return res.totalCount > 0 ? res.data[0] : null
        }
      }),
      onCreateGraphicPage: {
        findRAValue: null,
        chartsGenerated: false,
        calendarOptions: {
          max: 'year',
          min: 'century'
        },
        porAno: {
          isValid: false,
          chartReady: false,
          dataSource: null,
          checkboxValue: false,
          startValue: null,
          endValue: null
        },
        porMes: {
          isValid: false,
          chartReady: false,
          dataSource: null,
          checkboxValue: false,
          startValue: null,
          endValue: null,
          ano: null,
          range: null
        },
        porSemana: {
          isValid: false,
          chartReady: false,
          dataSource: null,
          checkboxValue: false,
          startValue: null,
          endValue: null,
          range: null
        }
      },

      gridData: {
        value: {
          visible: true
        },
        columns: this.parseColumns([
          {
            dataField: 'id',
            visible: false,
            caption: 'id'
          },
          {
            dataField: 'solicitacoesTransporte.data_hora_solicitacao',
            caption: 'Período',
            calculateDisplayValue: () => {
              return '-'
            },
            format: 'dd/MM/yyyy',
            dataType: 'date',
            editorType: 'dxDateBox'
          },
          {
            dataField: 'pessoa.bairro',
            caption: 'REGIÃO ADMINISTRATIVA',
            calculateDisplayValue: 'regiao',
            editorType: 'dxLookup',
            editorOptions: {
              allowClearing: true,
              showClearButton: true,
              acceptCustomValue: true,
              dataSource: new CustomStore({
                key: 'id',
                load: (loadOptions) => {
                  return this.$utils.wrapRequestForGrid(this.find({
                    entity: 'Pessoa',
                    params: {
                      fields: ['id', 'bairro'],
                      where: {
                        '$perfis.id_tipo_perfil$': 3
                      },
                      include: JSON.stringify([
                        {
                          model: 'Perfil',
                          as: 'perfis',
                          required: true,
                          attributes: ['id']
                        }
                      ])
                    }
                  }))
                },
                byKey: async key => {
                  const res = await getByKeys({
                    urlModel: 'Pessoa',
                    where: { bairro: key },
                    displayField: 'bairro'
                  })
                  return res.totalCount > 0 ? res.data[0] : null
                }
              }),
              displayExpr: 'bairro',
              valueExpr: 'bairro'
            }
          },
          {
            dataField: 'volume_aproximado_m3',
            caption: 'Valor Recebido em m³',
            allowFiltering: false
          }
        ]),
        dataSource: new CustomStore({
          key: 'id',
          load: (loadOptions) => {
            return this.$utils.wrapRequestForGrid(this.findResiduosRegioes(loadOptions))
          }
        })
      }
    }
  },

  methods: {
    /**
    * Método createGraphics que é acionado quando botão "Criar Gráficos Estatísticos" é acionado na tela de grid
    * responsável por mudar para o modo de geração de gráfico
    */
    createGraphics () {
      this.onCreatingGraphic = true
    },
    /**
    * Método onGoBack acionado quando o botão de "Voltar" é acionado na tela de gerador de gráficos
    * É responsável por limpar os gráficos existentes
    */
    onGoBack () {
      this.onClearGraphics()
      this.onCreatingGraphic = false
    },
    /**
    * Método onClearGraphics responsável por limpar todos os dados dos graficos previamentes criados
    * Este método é acionado quando o botão "Limpar" ou o botão "Voltar" é acionado na tela de gerador de gráficos
    */
    onClearGraphics () {
      this.warning = false
      this.onCreateGraphicPage.chartsGenerated = false
      this.onCreateGraphicPage.findRAValue = null
      this.onCreateGraphicPage.porAno = {
        chartReady: false,
        dataSource: null,
        checkboxValue: false,
        startValue: null,
        endValue: null
      }
      this.onCreateGraphicPage.porMes = {
        chartReady: false,
        dataSource: null,
        checkboxValue: false,
        startValue: null,
        endValue: null,
        ano: null
      }
      this.onCreateGraphicPage.porSemana = {
        chartReady: false,
        dataSource: null,
        checkboxValue: false,
        startValue: null,
        endValue: null
      }
    },
    /**
    * Método onGenerateGraphics responsável por criar os gráficos de acordo com os parametros passados
    * Gera gráficos por intervalo de ano, de semana ou de mês
    * Este método é acionado quando o botão "Gerar Gráficos" é acionado na tela de gerador de gráficos
    */
    async onGenerateGraphics () {
      /**
      * Caso o gráfico seja por ano
      */
      if (this.onCreateGraphicPage.porAno.checkboxValue) {
        if (!this.onCreateGraphicPage.porAno.startValue) {
          this.warning = true
          this.warningMessage = 'Não foi possível gerar gráfico Volume em M³ por ano, o campo de data incial é obrigatório'
        } else if (!this.onCreateGraphicPage.porAno.endValue) {
          this.warning = true
          this.warningMessage = 'Não foi possível gerar gráfico Volume em M³ por ano, o campo de data final é obrigatório'
        } else {
          try {
            this.setLoading(true)

            /**
              * Chamada do command calculate responsável por dado um intervalo de tempo
              * parametro startDate parametro de data inicial
              * parametro endDate parametro de data final
              * parametro type parametro do tipo de retorno dos intervalos ('year' , 'month' ou 'weekDays')
              * parametro RA contendo array de ids caso encontre algum no campo de busca
              */
            const res = await this.chartResiduoRegiao({
              startDate: `${moment(this.onCreateGraphicPage.porAno.startValue).format('YYYY')}-01-01`,
              endDate: `${moment(this.onCreateGraphicPage.porAno.endValue).format('YYYY')}-12-31`,
              type: 'year',
              ra: this.onCreateGraphicPage.findRAValue ? this.onCreateGraphicPage.findRAValue.bairro : undefined
            })

            const years = res.data.data.rows.map(value => {
              return {
                year: value.ano,
                total: value.total
              }
            })
            this.onCreateGraphicPage.chartsGenerated = true
            const dataRangeValues = []

            /**
            * Alimenta o array dataRangeValues que contem o ano e o total de cada ano
            */
            for (const year of this.getYears(this.onCreateGraphicPage.porAno.startValue, this.onCreateGraphicPage.porAno.endValue)) {
              if (years.filter(value => value.year === year).length > 0) {
                dataRangeValues.push({
                  year: JSON.stringify(year),
                  total: parseInt(years.filter(value => value.year === year)[0].total)
                })
              } else {
                dataRangeValues.push({
                  year: JSON.stringify(year),
                  total: 0
                })
              }
            }

            /**
            * Constroi datasource com array dateRangeValues correspondente
            */
            this.onCreateGraphicPage.porAno.dataSource = new DataSource({
              store: {
                type: 'array',
                key: 'id',
                data: dataRangeValues
              }
            })

            this.setLoading(false)
            this.onCreateGraphicPage.porAno.chartReady = true
          } catch (error) {
            this.setLoading(false)
            Swal.fire({
              title: 'Erro ao gerar gráfico por ano',
              icon: 'error',
              text: 'Tente novamente'
            })
          }
        }
      }

      /**
      * Caso o gráfico seja por mes
      */
      if (this.onCreateGraphicPage.porMes.checkboxValue) {
        if (!this.onCreateGraphicPage.porMes.startValue) {
          this.warning = true
          this.warningMessage = 'Não foi possível gerar gráfico Volume em M³ por mês, o campo de data incial é obrigatório'
        } else if (!this.onCreateGraphicPage.porMes.endValue) {
          this.warning = true
          this.warningMessage = 'Não foi possível gerar gráfico Volume em M³ por mês, o campo de data final é obrigatório'
        } else {
          try {
            this.setLoading(true)

            /**
              * Chamada do command calculate responsável por canceladas dado um intervalo de tempo
              * parametro startDate parametro de data inicial
              * parametro endDate parametro de data final
              * parametro type parametro do tipo de retorno dos intervalos ('year' , 'month' ou 'weekDays')
              * parametro RA contendo array de ids caso encontre algum no campo de busca
              */
            const res = await this.chartResiduoRegiao({
              startDate: `${moment(this.onCreateGraphicPage.porMes.startValue).format('YYYY')}-01-01`,
              endDate: `${moment(this.onCreateGraphicPage.porMes.endValue).format('YYYY-MM')}-${moment(this.onCreateGraphicPage.porMes.endValue).endOf('month').format('DD')}`,
              type: 'month',
              ra: this.onCreateGraphicPage.findRAValue ? this.onCreateGraphicPage.findRAValue.bairro : undefined
            })
            const months = res.data.data.rows.map(value => {
              return {
                month: `${value.mes}/${value.ano}`,
                total: value.total
              }
            })

            this.onCreateGraphicPage.porMes.range =
            `De ${moment(this.onCreateGraphicPage.porMes.startValue).locale('pt-br').format('MMMM')} de
            ${this.onCreateGraphicPage.porMes.startValue.getFullYear()} até
            ${moment(this.onCreateGraphicPage.porMes.endValue).locale('pt-br').format('MMMM')} de
            ${this.onCreateGraphicPage.porMes.endValue.getFullYear()}`

            this.onCreateGraphicPage.chartsGenerated = true
            const dataRangeValues = []

            /**
            * Alimenta o array dataRangeValues que contem o mês e o total de cada mês
            */
            for (const month of this.getMonths(this.onCreateGraphicPage.porMes.startValue, this.onCreateGraphicPage.porMes.endValue)) {
              if (months.filter(value => value.month === month).length > 0) {
                dataRangeValues.push({
                  month: month,
                  total: parseInt(months.filter(value => value.month === month)[0].total)
                })
              } else {
                dataRangeValues.push({
                  month: month,
                  total: 0
                })
              }
            }

            /**
            * Constroi datasource com array dateRangeValues correspondente
            */
            this.onCreateGraphicPage.porMes.dataSource = new DataSource({
              store: {
                type: 'array',
                key: 'id',
                data: dataRangeValues
              }
            })
            this.setLoading(false)
            this.onCreateGraphicPage.porMes.chartReady = true
          } catch (error) {
            this.setLoading(false)
            Swal.fire({
              title: 'Erro ao gerar gráfico por mes',
              icon: 'error',
              text: 'Tente novamente'
            })
          }
        }
      }

      /**
      * Caso o gráfico seja por semana
      */
      if (this.onCreateGraphicPage.porSemana.checkboxValue) {
        if (!this.onCreateGraphicPage.porSemana.startValue) {
          this.warning = true
          this.warningMessage = 'Não foi possível gerar gráfico Volume em M³ por semana, o campo de data incial é obrigatório'
        } else if (!this.onCreateGraphicPage.porSemana.endValue) {
          this.warning = true
          this.warningMessage = 'Não foi possível gerar gráfico Volume em M³ por semana, o campo de data final é obrigatório'
        } else {
          try {
            this.setLoading(true)

            /**
              * Chamada do command calculate responsável por calcular dado um intervalo de tempo
              * parametro startDate parametro de data inicial
              * parametro endDate parametro de data final
              * parametro type parametro do tipo de retorno dos intervalos ('year' , 'month' ou 'weekDays')
              * parametro RA contendo array de ids caso encontre algum no campo de busca
              */
            const res = await this.chartResiduoRegiao({
              startDate: `${moment(this.onCreateGraphicPage.porSemana.startValue).format('YYYY-MM-DD')}`,
              endDate: `${moment(this.onCreateGraphicPage.porSemana.endValue).format('YYYY-MM-DD')}`,
              type: 'weekDays',
              ra: this.onCreateGraphicPage.findRAValue ? this.onCreateGraphicPage.findRAValue.bairro : undefined
            })

            this.onCreateGraphicPage.porSemana.range = `${moment(this.onCreateGraphicPage.porSemana.startValue).format('DD/MM/YYYY')} até ${moment(this.onCreateGraphicPage.porSemana.endValue).format('DD/MM/YYYY')}`

            const dates = res.data.data.rows.map(value => {
              return {
                date: new Date(`${value.ano}-${value.mes}-${value.dia}`),
                total: parseInt(value.total)
              }
            })

            const dataRangeValues = []
            const weeks = ['Seg', 'Ter', 'Quar', 'Qui', 'Sex', 'Sáb', 'Dom']

            /**
            * Alimenta o array dataRangeValues que contém todos os dias de semana com valor total iniciado
            */
            for (const week of weeks) {
              dataRangeValues.push({
                week,
                total: 0
              })
            }

            /**
            * Alimenta a propiedade "total" do array de objetos dates caso encontre um naquele dia específico
            */
            dates.forEach(value => {
              if (moment(value.date).format('dddd') === 'Monday') {
                dataRangeValues.find(date => date.week === 'Seg').total = value.total
              }
              if (moment(value.date).format('dddd') === 'Tuesday') {
                dataRangeValues.find(date => date.week === 'Ter').total = value.total
              }
              if (moment(value.date).format('dddd') === 'Wednesday') {
                dataRangeValues.find(date => date.week === 'Quar').total = value.total
              }
              if (moment(value.date).format('dddd') === 'Thursday') {
                dataRangeValues.find(date => date.week === 'Qui').total = value.total
              }
              if (moment(value.date).format('dddd') === 'Friday') {
                dataRangeValues.find(date => date.week === 'Sex').total = value.total
              }
              if (moment(value.date).format('dddd') === 'Saturday') {
                dataRangeValues.find(date => date.week === 'Sáb').total = value.total
              }
              if (moment(value.date).format('dddd') === 'Sunday') {
                dataRangeValues.find(date => date.week === 'Dom').total = value.total
              }
            })
            this.onCreateGraphicPage.chartsGenerated = true
            this.onCreateGraphicPage.porSemana.dataSource = new DataSource({
              store: {
                type: 'array',
                key: 'id',
                data: dataRangeValues
              }
            })
            this.setLoading(false)
            this.onCreateGraphicPage.porSemana.chartReady = true
          } catch (error) {
            this.setLoading(false)
            Swal.fire({
              title: 'Erro ao gerar gráfico dia',
              icon: 'error',
              text: 'Tente novamente'
            })
          }
        }
      }
    },
    /**
    * Método onAddOneWeek responsável adicionar 7 dias no campo de data final de por semana
    * Este método é acionado quando o botão "Limpar" ou o botão "Voltar" é acionado na tela de gerador de gráficos
    * @param {Date} data objeto do tipo data contendo a data do campo inicial
    */
    onAddOneWeek (data) {
      this.onCreateGraphicPage.porSemana.endValue = new Date(moment(data.value).add(7, 'd').format('YYYY/MM/DD'))
    },
    /**
    * Método graphicporAnoCheckBox que é acionado quando o checkbox por ano é alterado
    * Caso o checkbox esteja desativado remove o gráfico correspondente
    * @params {Object} data objeto contendo os valores do evento nativo do devextreme
    */
    graphicporAnoCheckBox (data) {
      if (!data.value) {
        this.onCreateGraphicPage.porAno = {
          isValid: false,
          chartReady: false,
          dataSource: null,
          checkboxValue: false,
          startValue: null,
          endValue: null
        }
      }
    },
    /**
    * Método graphicporMesCheckBox que é acionado quando o checkbox por mês é alterado
    * Caso o checkbox esteja desativado remove o gráfico correspondente
    * @params {Object} data objeto contendo os valores do evento nativo do devextreme
    */
    graphicporMesCheckBox (data) {
      if (!data.value) {
        this.onCreateGraphicPage.porMes = {
          isValid: false,
          chartReady: false,
          dataSource: null,
          checkboxValue: false,
          startValue: null,
          endValue: null,
          ano: null,
          range: null
        }
      }
    },
    /**
    * Método graphicporSemanaCheckBox que é acionado quando o checkbox por semana é alterado
    * Caso o checkbox esteja desativado remove o gráfico correspondente
    * @params {Object} data objeto contendo os valores do evento nativo do devextreme
    */
    graphicporSemanaCheckBox (data) {
      if (!data.value) {
        this.onCreateGraphicPage.porSemana = {
          isValid: false,
          chartReady: false,
          dataSource: null,
          checkboxValue: false,
          startValue: null,
          endValue: null,
          range: null
        }
      }
    },
    /**
    * Método getYears responsável por trazer todos os anos entre uma data e outra
    * Este método é chamado pelo outro metodo onGenerateGraphics no momento de gerar um gráfico
    * @param {Date} startDate data incial
    * @param {Date} stopDate data final
    * @returns {array} dateArray array contendo o intervalo dos anos entre a data incial e data final
    */
    getYears (startDate, stopDate) {
      const dateArray = []
      let currentDate = moment(startDate)
      const stop = moment(stopDate)
      while (currentDate <= stop) {
        dateArray.push((currentDate.year()))
        currentDate = moment(currentDate).add(1, 'years')
      }
      return dateArray
    },

    /**
    * Método getMonths responsável por trazer todos os meses/ano entre uma data e outra
    * Este método é chamado pelo outro metodo onGenerateGraphics no momento de gerar um gráfico
    * @param {Date} startDate data incial
    * @param {Date} stopDate data final
    * @returns {array} dateArray array contendo o intervalo dos meses/ano entre a data incial e data final
    */
    getMonths (startDate, stopDate) {
      const dateArray = []
      let currentDate = moment(startDate)
      const stop = moment(stopDate)
      while (currentDate <= stop) {
        dateArray.push((currentDate.format('M')) + '/' + currentDate.format('YYYY'))
        currentDate = moment(currentDate).add(1, 'months')
      }
      return dateArray
    },

    /**
    * Método getDays responsável por trazer todos os dias entre uma data e outra
    * Este método é chamado pelo outro metodo onGenerateGraphics no momento de gerar um gráfico
    * @param {Date} startDate data incial
    * @param {Date} stopDate data final
    * @returns {array} dateArray array contendo o intervalo dos dias entre a data incial e data final
    */
    getDays (startDate, stopDate) {
      const dateArray = []
      let currentDate = moment(startDate)
      const stop = moment(stopDate)
      while (currentDate <= stop) {
        dateArray.push((currentDate.day() + 1))
        currentDate = moment(currentDate).add(1, 'days')
      }
      return dateArray
    },

    /**
     * Metodo para obter o objeto com valores do grid
     * @param {Object} loadOptions - Os parametros de busca do datasource do
     * devextreme.
     * @returns {Object} Os parametros da requisicao.
     */
    async findResiduosRegioes (loadOptions) {
      const params = getParamsFromLoadOptions(loadOptions, true)
      const result = await this.findResiduoRegiao(params)
      if (!(params.format === 'xlsx')) return { data: result.data.rows, totalCount: result.data.count }
    },
    ...mapActions('Crud', ['findResiduoRegiao', 'chartResiduoRegiao'])
  }
}
</script>

<style scoped lang="scss">
.graficoFieldSet{
  width: 100%;
}
.block-input div {
  margin-bottom: 5px;
}
</style>
