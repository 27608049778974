<template>
  <div class="generic-crud">
    <div v-if="action && (canAdd || canEdit || canView)" class="generic-crud-form">
      <h2 v-if="action === 'add'">Incluir Caçamba</h2>
      <h2 v-else-if="canEdit">Editar Caçamba</h2>
      <h2 v-else>Visualizar Caçamba</h2>
      <fieldset>
        <legend v-if="action === 'add'">Caçamba</legend>
        <legend v-else>Caçamba</legend>
        <simple-form
          :disabled="formDisabled"
          v-model="formData"
          id="group"
          :fields="gridData.columns"
          :form-options="formOptions"
          ref="crudForm"/>

        <dx-load-panel
          :visible.sync="cepLoadingVisible"
          :position="position"
        />

        <dx-button
          v-if=" !isReadOnly"
          text="Salvar"
          type="success"
          @click="saveFormItem"/>
      </fieldset>
    </div>
    <div v-else>
      <div class="md-layout">
        <div class="md-layout-item">
          <h2 class="crud-title">Cadastrar Caçamba</h2>
        </div>

        <div
          v-if="canAdd"
          class="md-layout-item"
          style="text-align: right;">
          <dx-button type="success" @click="$router.push({ path: 'cacamba/add' })" text="Adicionar Novo"></dx-button>
        </div>
      </div>
      <simple-grid
        ref="simpleGrid"
        :deletable="false"
        :editable="false"
        entity="Cacamba"
        :grid-data="gridData"
        popup-title="Cacamba"
        :viewable="canView && !canEdit"/>
    </div>
  </div>
</template>
<script>
import CustomStore from 'devextreme/data/custom_store'
import { DxButton } from 'devextreme-vue'
import { DxLoadPanel } from 'devextreme-vue/load-panel'
import { mapActions, mapState, mapGetters } from 'vuex'
import notify from 'devextreme/ui/notify'
import { SimpleGrid, SimpleForm } from '@/components'
import mixinCrud from '../mixin-crud'
import { hasPermission } from '../../../util/permissions'
import _ from 'lodash'
import { doRequest } from '@/pluggables/http'
import { confirm } from 'devextreme/ui/dialog'

export default {
  name: 'cacamba-crud',
  mixins: [
    mixinCrud
  ],
  components: {
    SimpleGrid,
    SimpleForm,
    DxButton,
    DxLoadPanel
  },
  data () {
    return {
      entity: 'Cacamba',
      formDisabled: false,
      formData: null,
      cepLoadingVisible: false,
      position: { of: '#group' },
      lookupResult: null,
      selectedUserSigla: null,
      gridData: {
        value: {
          visible: true
        },
        columns: this.parseColumns([
          {
            dataField: 'idPerfil',
            calculateSortValue: 'perfil.pessoa.nomeRazaoSocial',
            calculateDisplayValue: 'perfil.pessoa.nomeRazaoSocial',
            caption: 'Transportador',
            editorType: 'dxLookup',
            editorOptions: {
              dataSource: new CustomStore({
                key: 'id',
                load: async (loadOptions) => {
                  this.lookupResult = await this.$utils.wrapRequestForGrid(this.find({
                    entity: 'ViewTransportador',
                    loadOptions
                  }))
                  return this.lookupResult
                },
                byKey: key => {
                  return this.findId({
                    entity: 'ViewTransportador',
                    id: key
                  })
                }
              }),
              displayExpr: 'nomeRazaoSocial',
              valueExpr: 'id',
              onSelectionChanged: (data) => {
                if (data.selectedItem) {
                  this.selectedUserSigla = data.selectedItem.siglaNome
                }
              }
            },
            validationRules: [{
              type: 'required'
            }]
          },
          {
            dataField: 'capacidadeM3',
            caption: 'Capacidade da Caçamba em M³',
            label: { text: 'Capacidade da Caçamba em M³' },
            validationRules: [{ type: 'required' }],
            editorType: 'dxNumberBox',
            editorOptions: { maxLength: 2 }
          },
          {
            dataField: 'quantidade',
            caption: 'Quantidade (Máxima 50)',
            validationRules: [{ type: 'required' }],
            editorType: 'dxNumberBox',
            allowEditing: false,
            visible: false,
            formItem: {
              visible: false
            },
            editorOptions: {
              min: 1,
              max: 50,
              maxLength: 3
            }
          },
          {
            dataField: 'numeroIdentificacao',
            caption: 'Nº de Identificação',
            label: { text: 'Nº de Identificação' },
            formItem: {
              visible: () => {
                return false
              }
            },
            editorOptions: {
              disabled: false
            },
            validationRules: [{ type: 'required' }]
          },
          {
            dataField: 'numeroInterno',
            caption: 'Número Interno',
            editorType: 'dxNumberBox',
            formItem: {
              visible: true
            }
          },
          {
            itemType: 'group',
            caption: 'Local da Caçamba',
            colCount: 2,
            calculateDisplayValue: (data) => {
              const value = `CEP: ${data.cep}, ${data.municipio.nome} - ${data.bairro}`
              return value
            },
            items: [
              {
                dataField: 'cep',
                caption: 'cep',
                validationRules: [{ type: 'required' }],
                editorOptions: {
                  maxLength: 8,
                  // width: 500,
                  mask: '00000-000',
                  maskRules: { X: /[02-9]/ },
                  onContentReady: (value) => {
                    const node = document.createElement('span')
                    const textnode = document.createTextNode('Endereço do depósito onde a caçamba é guardada')
                    node.setAttribute('class', 'tooltiptext')
                    node.appendChild(textnode)
                    document.getElementsByClassName('dx-form-group-caption')[0].appendChild(node)
                  },
                  onFocusOut: async (value) => {
                    if (this.formData.cep) await this.getAddress(this.formData.cep)
                  }
                }
              },
              {
                dataField: 'codIbge',
                calculateDisplayValue: 'municipio.nome',
                calculateSortValue: 'municipio.nome',
                caption: 'Município',
                label: { text: 'Município' },
                editorType: 'dxLookup',
                dataType: 'text',
                showInColumnChooser: false,
                editorOptions: {
                  allowClearing: true,
                  showClearButton: true,
                  onItemClick: async (item) => {
                    this.$refs.crudForm.instance.updateData('uf', item.itemData.uf)
                    this.$refs.crudForm.instance.updateData('codIbge', item.itemData.codIbge)
                  },
                  // width: 300,
                  dataSource: new CustomStore({
                    key: 'codIbge',
                    load: (loadOptions) => this.$utils.wrapRequestForGrid(this.find({
                      entity: 'Municipio',
                      loadOptions
                    })),
                    byKey: async (key) => {
                      const result = await this.find({
                        entity: 'Municipio',
                        params: {
                          where: {
                            codIbge: key
                          }
                        }
                      })

                      if (document.querySelector('.dx-form')) {
                        setTimeout(() => {
                          if (this.$refs.crudForm.instance) {
                            this.$refs.crudForm.instance.updateData('uf', result.data[0].uf)
                          }
                        }, 450)
                      }
                      return result.data[0]
                    }
                  }),
                  displayExpr: 'nome',
                  valueExpr: 'codIbge'
                },
                validationRules: [{ type: 'required' }]
              },
              {
                dataField: 'bairro',
                caption: 'Bairro',
                editorOptions: {
                  // width: 500
                },
                validationRules: [{ type: 'required' }]
              },

              {
                dataField: 'uf',
                caption: 'UF',
                editorType: 'dxLookup',
                showInColumnChooser: false,
                editorOptions: {
                  onItemClick: async (item) => {
                    this.$refs.crudForm.instance.updateData('uf', item.itemData.uf)
                    this.$refs.crudForm.instance.updateData('codIbge', null)
                  },
                  // width: 300,
                  dataSource: new CustomStore({
                    key: 'uf',
                    load: (loadOptions) => this.$utils.wrapRequestForGrid(this.find({
                      entity: 'Estado',
                      loadOptions
                    })),
                    byKey: async (key) => {
                      const result = await this.find({
                        entity: 'Estado',
                        params: {
                          where: {
                            uf: key
                          }
                        }
                      })
                      return result.data[0]
                    }
                  }),
                  displayExpr: 'uf',
                  valueExpr: 'uf'
                }
              },
              {
                dataField: 'endereco',
                caption: 'Endereço',
                label: { text: 'Endereço' },
                editorOptions: {
                  // width: 400,
                  onFocusOut: async (value) => {
                    await this.getAddress({
                      endereco: this.formData.endereco
                    })
                  }
                },
                validationRules: [{ type: 'required' }]
              },
              {
                dataField: 'complemento',
                caption: 'Complemento',
                editorOptions: {
                  // width: 500
                }
              },
              {
                dataField: 'numero',
                caption: 'Número',
                label: { text: 'Número' },
                editorType: 'dxTextBox',
                editorOptions: {
                  // width: 200
                }
              },
              {
                dataField: 'shape',
                caption: 'Shape',
                visible: false
              }
            ]
          },
          {
            dataField: 'excluido',
            caption: 'Excluído',
            editorType: 'dxLookup',
            sortOrder: 'asc',
            width: 120,
            calculateSortValue: 'excluido',
            filterValue: 0,
            calculateDisplayValue: (data) => {
              return {
                0: 'Não',
                1: 'Sim'
              }[data.excluido]
            },
            editorOptions: {
              items: [{ value: 0, label: 'Não' }, { value: 1, label: 'Sim' }],
              displayExpr: 'label',
              valueExpr: 'value'
            },
            formItem: {
              visible: false
            }
          },
          {
            type: 'buttons',
            width: 325,
            showInColumnChooser: false,
            buttons: [
              {
                cssClass: 'dx-button dx-button-success dx-button-mode-contained dx-widget dx-button-has-text mdi mdi-pencil',
                text: 'Editar',
                onClick: ({ row: { data } }) => {
                  this.$router.push(`/cacamba/edit/${data.id}`)
                },
                visible: ({ row: { data } }) => {
                  return (hasPermission('Manter Caçamba', 'altera') && data.excluido === 0)
                }
              },
              {
                cssClass: 'dx-link dx-button dx-button-danger dx-button-mode-contained dx-widget dx-link-icon mdi mdi-delete',
                text: 'Excluir',
                onClick: ({ row: { data } }) => {
                  const result = confirm('Tem certeza que deseja excluir esta caçamba?', 'Atenção!')
                  result.then(async (dialogResult) => {
                    if (dialogResult) {
                      await this.$http.put(`cacamba/${data.id}`, {
                        excluido: 1
                      })
                      this.$refs.simpleGrid.$refs.gridInstance.instance.refresh()
                    }
                  })
                },
                visible: ({ row: { data } }) => {
                  return (hasPermission('Manter Caçamba', 'exclui') && data.excluido === 0)
                }
              },
              {
                cssClass: 'dx-button dx-button-success dx-button-mode-contained dx-widget dx-button-has-text mdi mdi-restore',
                text: 'Restaurar',
                onClick: ({ row: { data } }) => {
                  const result = confirm('Tem certeza que deseja restaurar esta caçamba?', 'Atenção!')
                  result.then(async (dialogResult) => {
                    if (dialogResult) {
                      await this.$http.put(`cacamba/${data.id}`, {
                        excluido: 0
                      })
                      this.$refs.simpleGrid.$refs.gridInstance.instance.refresh()
                    }
                  })
                },
                visible: ({ row: { data } }) => {
                  return (hasPermission('Manter Caçamba', 'altera') && data.excluido === 1 && this.isAdministrador)
                }
              }
            ]
          }
        ]),
        dataSource: new CustomStore({
          key: 'id',
          load: (loadOptions) => {
            return this.$utils.wrapRequestForGrid(this.find({
              entity: this.entity,
              params: {
                attributes: ['id', 'capacidadeM3', 'endereco', 'numeroIdentificacao', 'codIbge', 'bairro', 'cep', 'excluido', 'numeroInterno'],
                include: JSON.stringify([{
                  model: 'Perfil',
                  as: 'perfil',
                  required: true,
                  attributes: ['id', 'idPessoa'],
                  include: [
                    {
                      model: 'Pessoa',
                      as: 'pessoa',
                      attributes: ['id', 'nomeRazaoSocial']
                    }
                  ]
                },
                {
                  model: 'Municipio',
                  as: 'municipio',
                  required: true,
                  attributes: ['uf', 'nome']
                }
                ])
              },
              loadOptions
            }))
          },
          insert: (values) => this.insertRow({ entity: this.entity, values }),
          update: (key, values) => this.updateRow({ entity: this.entity, values, key }),
          remove: (key, values) => this.removeRow({ entity: this.entity, key })
        })
      }
    }
  },
  computed: {
    /**
     * Valor computado para verificar se o usuario logado e uma secretaria.
     * @returns {Boolean}
     */
    isSecretaria () {
      return !!this.userData.userTypes.find(userType => userType === 'Secretaria')
    },
    isAdministrador () {
      return !!this.userData.userTypes.find(userType => userType === 'Administrador')
    },
    currentPerfil () {
      return this.user.perfis.find(v => v.tipoPerfil.descricao === 'Transportador').id
    },

    /**
     * Valor computado de se o usuario logado pode incluir cacambas.
     * @returns {boolean}
     */
    canAdd () {
      return hasPermission('Manter Caçamba', 'inclui')
    },

    /**
     * Valor computado de se o usuario logado pode editar cacambas.
     * @returns {boolean}
     */
    canEdit () {
      return hasPermission('Manter Caçamba', 'altera')
    },

    /**
     * Valor computado de se o usuario logado pode excluir cacambas.
     * @returns {boolean}
     */
    canDelete () {
      return hasPermission('Manter Caçamba', 'exclui')
    },

    /**
     * Valor computado de se o usuario logado tem permissao de consultar
     * cacamba.
     * @returns {boolean}
     */
    canView () {
      return hasPermission('Manter Caçamba', 'consulta')
    },

    /**
     * Valor computado de se o formulario e somente para leitura.
     * @returns {boolean}
     */
    isReadOnly () {
      if (this.formDisabled) {
        return true
      } else {
        return (!this.canAdd && this.action === 'add') ||
          (!this.canEdit && this.action === 'edit')
      }
    },

    /**
     * Valor computado das opcoes do devextreme do componente de form.
     * @returns {Object}
     */
    formOptions () {
      return {
        colCount: 1,
        readOnly: this.isReadOnly
      }
    },

    ...mapState('Login', ['user']),
    ...mapState('Crud', { requestAddress: 'requestAddress' }),
    ...mapGetters('Login', { userData: 'userData' })
  },
  watch: {
    async action (value) {
      if (value === 'edit' || value === 'add') {
        await this.$nextTick()
        if (this.formData) this.formData.quantidade = 1
        if (this._currentUserIs('Transportador')) {
          this.$refs.crudForm.instance.updateData('idPerfil', this.currentPerfil)
          this.$refs.crudForm.instance.itemOption('idPerfil', 'visible', false)
        }
        if (value === 'edit') {
          await this.$nextTick(async () => {
            this.formDisabled = true
            const { data: result } = await doRequest({
              method: 'post',
              url: 'SolicitacaoTransporte/find',
              params: {
                fields: ['situacao'],
                where: {
                  idCacamba: this.id,
                  situacao: { in: ['Enviada', 'Alocada', 'Retirada', 'Em Pesagem'] }
                }
              }
            })
            if (Array.isArray(result.rows) && result.rows.length > 0) {
              this.formDisabled = true
            } else {
              this.formDisabled = false
            }
            this.$refs.crudForm.instance.itemOption('quantidade', 'disabled', true)

            this.$refs.crudForm.instance.itemOption('numeroIdentificacao', 'visible', true)
            this.$refs.crudForm.instance.getEditor('numeroIdentificacao').option('disabled', (!(this.isAdministrador)))
          })
        } else if (value === 'add') {
          await this.$nextTick(() => {
            this.$refs.crudForm.instance.updateData('capacidadeM3', 0)
            this.$refs.crudForm.instance.itemOption('quantidade', 'disabled', false)

            this.$refs.crudForm.instance.itemOption('quantidade', 'visible', true)
            this.$refs.crudForm.instance.itemOption('numeroIdentificacao', 'visible', false)
            this.$refs.crudForm.instance.itemOption('numeroIdentificacao', 'disabled', true)
          })
        }
      }
    }
  },

  methods: {
    async saveFormItem () {
      if (!this.isSecretaria || !this.isAdministrador) {
        this.formData.sigla = this.userData.sigla
      } else {
        this.formData.sigla = this.selectedUserSigla
      }
      if (this.action === 'add') {
        try {
          await this.insertRow({ entity: this.entity, values: _.cloneDeep(this.formData) })
          this.$router.push('/cacamba')
        } catch (err) {
          notify('Atenção, alguns campos obrigatórios não foram preenchidos', 'error', 800)
          console.error(err.message)
        }
      } else {
        try {
          await this.saveItem({ redirect: '/cacamba' })
        } catch (err) {
          notify('Atenção, alguns campos obrigatórios não foram preenchidos', 'error', 800)
          console.error(err.message)
        }
      }
      if (this.formData.shape && !this.formData.shape.wkid) {
        this.formData.shape.wkid = '4326'
        this.formData.shape.crs = { type: 'name', properties: { name: 'EPSG:4326' } }
      }
    },
    _currentUserIs (perfil) {
      return this.user.perfis.filter(p => {
        if (typeof perfil === 'string') {
          return p.tipoPerfil.descricao === perfil
        } else {
          return perfil.includes(p.tipoPerfil.descricao)
        }
      }).length >= 1
    },
    getCepGeometry (geocodeCandidates) {
      const [{ location = null } = {}] = geocodeCandidates || []

      return location !== null ? {
        type: 'Point',
        coordinates: [location.x, location.y],
        srid: '4326',
        crs: { type: 'name', properties: { name: 'EPSG:4326' } }
      } : null
    },
    async getAddress (value) {
      this.cepLoadingVisible = true
      if (value.endereco) {
        try {
          await this.findAddress({
            address: value.endereco
          })
        } catch (err) {
          this.cepLoadingVisible = false
          notify('CEP inválido', 'error', 600)
        }
      } else {
        try {
          await this.findAddress({
            cep: value
          })
        } catch (err) {
          this.cepLoadingVisible = false
          notify('CEP inválido', 'error', 600)
        }
      }
      if (this.requestAddress.data.info) {
        this.cepLoadingVisible = false
        const endereco = this.requestAddress.data.info.endereco
        const geometries = this.requestAddress.data.info.geocodeCandidates
        if (Array.isArray(geometries)) {
          if (geometries.length > 0) {
            this.$refs.crudForm.instance.updateData('shape', this.getCepGeometry(geometries))
          }
        }
        if ((endereco && endereco.erro) || (!endereco && !value.endereco)) {
          this.cepLoadingVisible = false
          notify('CEP não encontrado', 'error', 600)
        } else if (!value.endereco) {
          const fullEndereco = endereco.logradouro + ', ' + endereco.bairro + ', ' + endereco.localidade + ', ' + endereco.uf + ', ' + endereco.cep
          this.$refs.crudForm.instance.updateData('bairro', endereco.bairro)
          this.$refs.crudForm.instance.updateData('uf', endereco.uf)
          this.$refs.crudForm.instance.updateData('complemento', endereco.complemento)
          this.$refs.crudForm.instance.updateData('endereco', fullEndereco)
          this.$refs.crudForm.instance.updateData('codIbge', endereco.ibge)
        }
      } else {
        this.cepLoadingVisible = false
        notify('CEP inválido', 'error', 600)
      }
    },
    ...mapActions('Crud', ['findAddress'])
  },
  async mounted () {
    await this.$nextTick()
    if (this.formData && this.formData.quantidade) this.formData.quantidade = 1
    if (this.$refs.simpleGrid && (this._currentUserIs('Secretaria') || this._currentUserIs('Administrador'))) {
      this.$refs.simpleGrid.getDataGridInstance().columnOption('idPerfil', 'visible', true)
    } else {
      this.$refs.simpleGrid.getDataGridInstance().columnOption('idPerfil', 'visible', false)
    }
    if (this.$refs.crudForm) {
      if (this._currentUserIs('Transportador')) {
        this.$refs.crudForm.instance.updateData('idPerfil', this.currentPerfil)
        this.$refs.crudForm.instance.itemOption('idPerfil', 'visible', false)
      }

      if (this.action === 'edit') {
        this.$refs.crudForm.instance.itemOption('quantidade', 'disabled', true)

        this.$refs.crudForm.instance.itemOption('numeroIdentificacao', 'visible', true)
        this.$refs.crudForm.instance.itemOption('numeroIdentificacao', 'disabled', true)
      } else if (this.action === 'add') {
        this.$refs.crudForm.instance.itemOption('quantidade', 'disabled', false)

        this.$refs.crudForm.instance.itemOption('quantidade', 'visible', true)
        this.$refs.crudForm.instance.itemOption('numeroIdentificacao', 'visible', false)
        this.$refs.crudForm.instance.itemOption('numeroIdentificacao', 'disabled', true)
      }
      this.$refs.crudForm.instance.itemOption('idPerfil', 'visible', false)
    }
  }
}
</script>

<style>

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.dx-form-group-caption .tooltiptext {
    position: relative;
    left: -214px;
    top: -29px;
    visibility: hidden;
    font-size: 12px;
    width: 120px;
    background-color: rgb(194, 194, 194);
    color: #fff;
    text-align: center;
    border-radius: 3px;
    padding: -2px 0;
    z-index: 1;
    margin-left: 8px;
    opacity: 0;
    transition: opacity 0.3s;
}

.dx-form-group-caption .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}
.dx-form-group-caption:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
</style>
