import { mapActions, mapState } from 'vuex'
import _ from 'lodash'
import Swal from 'sweetalert2'
import { hasPermission } from '@/util/permissions'
import { http } from '@/pluggables/http'
import config from 'config'

export default {
  props: {
    action: {
      type: String
    },
    id: {
      type: String
    }
  },
  data () {
    return {
      formData: null
    }
  },
  computed: {
    ...mapState('Login', ['user']),
    /**
     * Valor computado de se o usuario logado tem permissao de adicionar
     * @returns {boolean}
     */
    canAdd () {
      return this.permissionName ? hasPermission(this.permissionName, 'inclui') : false
    },

    /**
     * Valor computado de se o usuario logado tem permissao de alterar
     * @returns {boolean}
     */
    canEdit () {
      return this.permissionName ? hasPermission(this.permissionName, 'altera') : false
    },

    /**
     * Valor computado de se o usuario logado tem permissao de remover
     * @returns {boolean}
     */
    canDelete () {
      return this.permissionName ? hasPermission(this.permissionName, 'exclui') : false
    },

    /**
     * Valor computado de se o usuario logado tem permissao de consulta
     * @returns {boolean}
     */
    canView () {
      return this.permissionName ? hasPermission(this.permissionName, 'consulta') : false
    },

    /**
     * Valor computado das opcoes do formulario.
     * @returns {Object}
     */
    formOptions () {
      return {
        disabled: !this.canAdd && !this.canEdit,
        onInitialized: form => {
          if (this.isEditingData && !this.canEdit) {
            form.component.option('disabled', true)
          }
        }
      }
    },

    /**
     * Valor computado dos dados do popup.
     * @returns {Object}
     */
    popupData () {
      return {
        maxHeight: 450,
        maxWidth: 800,
        ...(!this.canAdd && !this.canEdit ? {
          toolbarItems: []
        } : {}),
        onShowing: popup => {
          if (this.isEditingData && !this.canEdit) {
            popup.component.option('toolbarItems', [])
            this.isEditingData = false
          }
        }
      }
    }
  },
  methods: {
    ...mapActions('Crud', ['find', 'findId', 'findOne', 'insertRow', 'removeRow', 'updateRow', 'setLoading']),
    parseColumns (columns) {
      for (const col of columns) {
        if (col.editorType && col.editorType === 'dxLookup') {
          col.editorOptions = {
            allowClearing: true,
            showClearButton: true,
            showCancelButton: false,
            dropDownOptions: {
              closeOnOutsideClick: true
            },
            ...col.editorOptions
          }

          col.lookup = col.editorOptions
        }

        if (col.caption && !col.label) {
          col.label = {
            text: col.caption
          }
        }
      }
      return columns
    },
    async saveItem ({ redirect }) {
      // const formData = this.$refs.crudForm.formData
      const formData = this.formData
      const { isValid } = this.$refs.crudForm.instance.validate()
      if (isValid === false) {
        return
      }

      let result = false
      if (this.action && this.action === 'add') {
        try {
          result = await this.insertRow({ entity: this.entity, values: _.cloneDeep(formData) })
        } catch (err) {
          console.error(err)
        }
      } else {
        result = await this.updateRow({ entity: this.entity, values: _.cloneDeep(formData), key: this.id })
      }

      if (!result) {
        Swal.fire({
          title: 'Erro ao salvar dados',
          icon: 'error',
          text: 'Verifique os campos e tente novamente'
        })
      } else {
        this.$refs.crudForm.instance.resetValues()
        if (redirect) {
          await this.$router.push(redirect)
        } else {
          await this.$router.push({ path: `/${_.kebabCase(this.entity)}` })
        }
      }
    },

    async btnBackClick () {
      this.$router.push({ path: `/${_.kebabCase(this.entity)}` })
    },
    async setFormData () {
      if (this.id) {
        this.setLoading(true)
        const res = await this.find({
          entity: this.entity,
          params: {
            where: {
              id: this.id,
              ...this.restrictTo ? this.restrictTo : {}
            }
          },
          rowsOnly: true
        })

        if (res !== null && res.length > 0) {
          this.formData = res[0]
          // if (this.$refs.crudForm) {
          //   this.$refs.crudForm.instance.resetValues()
          //   this.$refs.crudForm.instance.updateData(res)
          //   this.$refs.crudForm.instance.repaint()
          // }
          this.$forceUpdate()
        }
        this.setLoading(false)
      } else {
        // this.$nextTick(() => {
        //   if (this.$refs.crudForm && this.$refs.crudForm.instance) {
        //     this.$refs.crudForm.instance.resetValues()
        //     this.$refs.crudForm.instance.repaint()
        //   }
        // })

        this.formData = {}
        this.setCustomFormData()
      }
    },
    setCustomFormData () {},
    verifyProfile (profile) {
      return !!_.get(this.user, 'perfis', []).find(p =>
        String(p.tipoPerfil.descricao).toUpperCase() === String(profile).toUpperCase()
      )
    },
    cpfCnpjExists ({ value, formItem }) {
      return http.post(`${config.baseUrl}/findbycpfcnpj`, { cpfCnpj: value })
        .then(() => {
          return true
        }).catch((err) => {
          console.error(err)
          return false
        })
    }
  },
  watch: {
    async action () {
      await this.setFormData()
    }
  },
  async mounted () {
    if (this.action && this.action !== '') {
      await this.setFormData()
    }
  }
}
