import Mensagens from './Mensagens.vue'

export default {
  path: '/manter-mensagem',
  name: 'manter-mensagem',
  component: Mensagens,
  meta: {
    title: 'Manter Mensagens',
    icon: 'md-list-item-text',
    requiresAuth: true,
    permissionType: 'consulta'
  },
  children: [
    {
      path: '/mensagens/:action',
      name: 'mensagens-add',
      component: Mensagens,
      meta: {
        title: 'Manter Mensagens',
        requiresAuth: true,
        permissionType: 'inclui'
      },
      props: true
    },
    {
      path: '/mensagens/:action/:id',
      name: 'mensagens-edit',
      component: Mensagens,
      meta: {
        title: 'Manter Mensagens',
        requiresAuth: true,
        permissionType: 'consulta'
      },
      props: true
    }
  ],
  props: true
}
