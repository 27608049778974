import PaginaListaPessoa from './PaginaListaPessoa.vue'
import PaginaFormPessoa from './PaginaFormPessoa.vue'
import PaginaEditarDadosUsuario from './PaginaEditarDadosUsuario.vue'
import PaginaFormPessoaUpdate from '@/pages/Cruds/Pessoa/Form/PaginaFormPessoaUpdate.vue'

export default [
  {
    path: '/pessoa',
    name: 'pessoa',
    component: PaginaListaPessoa,
    meta: {
      icon: 'mdi mdi-account-circle',
      title: 'Cadastro de Pessoas',
      requiresAuth: true,
      permissionType: 'consulta'
    },
    props: true
  },
  {
    path: '/editar-dados-usuario',
    name: 'editar-dados-usuario',
    component: PaginaEditarDadosUsuario,
    meta: {
      icon: 'mdi mdi-account-circle',
      title: 'Editar meus dados',
      hideFromMenu: false,
      requiresAuth: true,
      permissionType: 'consulta'
    },
    props: true
  },
  {
    path: '/pessoa/:action/:id?',
    name: 'usuario-add-edit',
    component: PaginaFormPessoa,
    meta: {
      title: 'Cadastro de Pessoas',
      requiresAuth: true,
      hideFromMenu: false,
      permissionType: 'altera'
    },
    props: true
  },
  {
    path: '/pessoa-edit/:id',
    name: 'pessoa-update',
    component: PaginaFormPessoaUpdate,
    meta: {
      title: 'Cadastro de Pessoas',
      requiresAuth: true,
      hideFromMenu: false,
      permissionType: 'altera'
    },
    props: true
  },
  {
    path: '/pessoa-view/:id?',
    name: 'pessoa-view',
    component: PaginaFormPessoa,
    meta: {
      title: 'Cadastro de Pessoas',
      requiresAuth: true,
      hideFromMenu: false,
      permissionType: 'consulta'
    },
    props: true
  }
]
