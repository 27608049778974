<template>
  <div class="grid-residuos-solicitacao">
    <grid-tipo-residuos
      :read-only="readOnly"
      :value="value"
      @input="onChangeResiduos"
      @selectMista="onSelectMista"/>

    <select-field v-if="tipoResiduoPredominanteOptions.items !== undefined  && !isClasseMista"
      class="flex-col-6"
      label="Tipo de resíduo predominante"
      :options="tipoResiduoPredominanteOptions"
      required
      :value="idTipoResiduoPredominante"
      @input="onChangeResiduoPredominante"/>
    <div v-else-if="isClasseMista" style="display:grid">
      <span class="flex-col-6">Tipo de resíduo predominante</span>
      <DxTextBox value="Resíduo da Construção Civil - Não Segregado" :disabled="true"/>
    </div>
    <text-field v-if="tipoResiduoPredominanteOptions.items === undefined && value.tipoResiduoPredominante"
      class="flex-col-6"
      label="Tipo de resíduo predominante"
      required
      :options="fieldsBinds.tipoResiduoPredominante"
      :value="value.tipoResiduoPredominante.nome"/>

  </div>
</template>
<script>
import SelectField from '../Form/SelectField'
import TextField from '../Form/TextField'
import GridTipoResiduos from '../Obra/GridTipoResiduos'
import DxTextBox from 'devextreme-vue/text-box'

export default {
  name: 'grid-residuos',
  components: {
    SelectField,
    TextField,
    GridTipoResiduos,
    DxTextBox
  },
  props: {
    /**
     * Um objeto com os dados dos residuos na prop 'residuos' e os dados do tipo
     * de residuo predominante na prop 'tipoResiduoPredominante'.
     */
    value: {
      type: Object,
      required: true
    },

    /**
     * Se os campos vao ser somente de leitura.
     */
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  /**
   * Metodo do vue, para obter o estado inicial do componente.
   * @returns {Object} O estado inicial do componente.
   */
  data () {
    return {
      isClasseMista: false,
      tipoResiduoPredominanteOptions: {
        displayExpr: 'tipoResiduo.nome',
        items: [],
        noDataText: 'Não foi cadastrado nenhum tipo de resíduo.',
        readOnly: this.readOnly,
        valueExpr: 'idTipoResiduo'
      }
    }
  },
  computed: {
    /**
     * Valor computado do id do tipo de residuo predominante.
     * @returns {number} O id do tipo de residuo ou null.
     */
    idTipoResiduoPredominante () {
      return (this.value.tipoResiduoPredominante &&
        this.value.tipoResiduoPredominante.idTipoResiduo) || null
    },
    /**
     * Valor computado dos binds dos campos do popup.
     * @returns {Object}
     */
    fieldsBinds () {
      return {
        tipoResiduoPredominante: {
          readOnly: true
        }
      }
    }
  },
  watch: {
    /**
     * Metodo de callback de mudancas na prop 'residuos'. Com o objetivo de
     * preencher o campo tipo de residuo predominante quando for adicionado o
     * primeiro tipo de residuo.
     * @param {Object} veiculo - O valor atual da prop
     */
    'value.residuos' (residuos) {
      this.tipoResiduoPredominanteOptions.items = residuos
      if (residuos && residuos.length === 1) {
        this.isClasseMista = false
        if (residuos[0].id !== (this.value.tipoResiduoPredominante && this.value.tipoResiduoPredominante.id)) {
          this.onChangeResiduoPredominante(residuos[0])
        }
      }
    }
  },
  /**
   * Metodo do ciclo de vida do vue de quando o componente foi montado na tela,
   * com o objetivo de definir o valor correto para o tipo de residuo
   * predominante.
   */
  mounted () {
    if (Array.isArray(this.value.residuos) && this.value.residuos.length && this.value.tipoResiduoPredominante) {
      this.$emit('input', {
        ...this.value,
        tipoResiduoPredominante: this._getResiduoPredominanteById(this.value.residuos[0].tipoResiduo.id, this.value.residuos)
      })
    } else if (!this.value.residuos && this.value.tipoResiduoPredominante) {
      this.$emit('input', {
        ...this.value,
        tipoResiduoPredominante: null
      })
    }
  },
  methods: {
    /**
     * Metodo de callback de quando e alterado o valor do campo de residuo
     * predominante. Com o objetivo de emitir o evento de atualizar o valor da
     * prop 'value'.
     * @param {Object} value - O novo residuo predominante escolhido.
     */
    onChangeResiduoPredominante (value) {
      this.$emit('input', {
        ...this.value,
        tipoResiduoPredominante: value
      })
    },

    /**
     * Metodo de callback de quando e alterada a lista de residuos no grid. Com
     * o objetivo de emitir o evento de atualizar o valor da prop 'value'.
     * @param {Object} value - A nova lista de residuos.
     */
    onChangeResiduos (value) {
      value.tipoResiduoPredominante = value.residuos.length === 1 &&
        !value.tipoResiduoPredominante
        ? value.residuos[0]
        : value.tipoResiduoPredominante
          ? this._getResiduoPredominanteById(value.tipoResiduoPredominante.idTipoResiduo, value.residuos)
          : null
      if (this.isClasseMista) value.tipoResiduoPredominante = { ...this.isClasseMista, idTipoResiduo: this.isClasseMista.id }
      this.$emit('input', value)
    },

    onSelectMista (classeMista) {
      this.isClasseMista = classeMista
    },

    /**
     * Metodo para obter um residuo de uma lista de residuos pelo id.
     * @param {number} residuoId - O id do residuo a ser encontrado.
     * @param {Object[]} residuos - A lista de residuos onde sera buscado.
     * @returns {Object} O residuo encontrado ou null.
     */
    _getResiduoPredominanteById (residuoId, residuos) {
      return residuos.find(residuo =>
        residuo.tipoResiduo.id === residuoId) ||
            null
    }
  }
}
</script>

<style lang="scss">
@import '../../global.scss';

.grid-residuos-solicitacao {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
</style>
