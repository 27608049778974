<template>
  <div class="map-obras">
    <div class="md-layout map-header">
      <div class="md-layout-item">
        <h2 class="crud-title">Localização das Obras no DF</h2>
      </div>
    </div>
    <leaflet-map ref="leafletMap">
      <leaflet-marker
        v-for="(marker, i) in markers"
        :key="i"
        :latlng="marker.latlng"
        bind-popup
        :get-popup-value="() => getPopupValue(marker)">
        <div class="obra-icon">
          <md-icon>map</md-icon>
        </div>
      </leaflet-marker>
    </leaflet-map>
    <md-card
      v-if="message"
      :class="getMessageClasses(message)">
      <md-card-content>{{ message.message }}</md-card-content>
    </md-card>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { LeafletMap, LeafletMarker } from '@/components/LeafletMap'
import { getObrasWithShape } from '../service'
import { reverse } from 'reproject'

export default {
  name: 'map-obras',
  components: {
    LeafletMap,
    LeafletMarker
  },
  /**
   * Metodo do vue de obter o estado inicial do componente.
   * @returns {Object} O estado inicial do componente.
   */
  data () {
    return {
      markers: [],
      message: null
    }
  },
  computed: {
    ...mapState('Login', ['user'])
  },
  /**
   * Metodo do ciclo de vida do vue, de quando o componente foi montado na tela.
   * Com o objetivo de buscar a lista de obras a serem mostradas no mapa.
   */
  async mounted () {
    const res = await getObrasWithShape(this.$route.params.ids
      ? {
        id: { in: this.$route.params.ids }
      }
      : {})

    if (res.success) {
      if (res.data.rows.find(obra => !obra.shape)) {
        this.message = {
          message: 'Não foi encontrada a localização de uma ou mais obras.',
          type: 'info'
        }
      }

      this.createMarkers(res.data.rows)

      if (res.data.count > 0) {
        this.zoomToMarkers(this.markers)
      } else {
        this.message = {
          message: `Não foram encontradas obras em que o usuário '${this.user.pessoa.nomeRazaoSocial}' seja o gerador.`,
          type: 'info'
        }
      }
    } else {
      console.error(res.error)
      this.markers = []
      this.message = {
        message: 'Ocorreu uma falha ao buscar a lista de obras.',
        type: 'error'
      }
    }
  },
  methods: {
    /**
     * Metodo para obter o valor do conteudo do popup de um marcador da obra.
     * @param {Object} marker - Os dados do marcador da obra que se quer obter o
     * conteudo do popup.
     * @returns {string} A string a ser mostrada no conteudo do popup.
     */
    getPopupValue (marker) {
      return marker.obra.nomeObra
    },

    /**
     * Metodo de criacao de markers das obras.
     * @param {Object[]} obras - As obras que serao criados os marcadores.
     */
    createMarkers (obras) {
      this.markers = obras
        .filter(obra => obra.shape)
        .map(obra => {
          return {
            obra,
            latlng: reverse(obra.shape).coordinates,
            options: {}
          }
        })
    },

    /**
     * Metodo de comando, com o objetivo de dar zoom no mapa em uma lista de
     * marcadores.
     * @param {Object[]} markers - A lista de marcadores que receberam zoom no
     * mapa.
     */
    zoomToMarkers (markers) {
      if (markers.length > 0) {
        this.$refs.leafletMap.mapInstance.fitBounds([
          markers.map(marker => marker.latlng)
        ])
      }
    },

    /**
     * Metodo para obter a lista de classes para o card de mensagens.
     * @param {Object} message - Os dados da mensagem atual.
     */
    getMessageClasses (message) {
      return {
        error: ['map-message-card', 'error-message'],
        info: ['map-message-card', 'info-message']
      }[message.type] || null
    }
  }
}
</script>

<style lang="scss">
.page-route-obra-map,
.page-route-obra-cacamba-map {
  > div {
    display: flex;
  }
}
.map-obras {
  display: flex;
  flex-direction: column;
  flex: 1;
  .map-header {
    flex: 1;
  }

  .leaflet-map-component {
    flex: 6;
    margin: 10px;
  }

  .map-message-card {
    margin-bottom: 20px;

    &.error-message {
      background-color: #B00020 !important;
      color: white !important;
    }

    &.info-message {
      background-color: #ffce99 !important;
    }
  }

  .obra-icon {
    border-radius: 100%;
    background: #008E32;
    width: 32px;
    padding: 4px;

    > i {
      color: white !important;
    }
  }
}
</style>
