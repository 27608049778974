<template>
  <div>
    <grid-tipo-residuos
      :read-only="readOnly"
      :value="value"
      :disable-where="true"
      @input="onChangeTipoResiduos"/>
    <hr />
    <div v-if="!readOnly" class="flex">
      <div class="flex-4">
        <model-select
          :get-list-request="_doTransportadoraRequest"
          label="Nome da Transportadora"
          label-prop="nomeRazaoSocial"
          name="nomeTransp"
          v-model="transportador.add.selected"/>
      </div>
      <div class="flex-1">
        <md-button
          class="md-raised md-accent float-right"
          @click="onClickButtonAddTransportadora">
          Adicionar
        </md-button>
      </div>
    </div>
    <div class="flex">
      <message-error :message="errorMessageTransportadora" />
    </div>
    <div class="flex">
      <simple-grid
        entity="Obra"
        popup-title="Obra"
        :grid-data="transportador.gridData"
        :editable="false"
        ref="transportadoraGrid"/>
    </div>
    <hr />

    <div class="flex">
      <div class="flex-1">
        <div class="dx-field">
          <div class="dx-field-label-location-top">Observação</div>
          <div class="dx-field-content">
            <dx-text-area
              :max-length="500"
              :read-only="readOnly"
              :value.sync="observacao"/>
          </div>
        </div>
      </div>
    </div>

    <model-file-uploader
      v-model="filePgrcc"
      accept=".doc, .docx, .xls, .xlsx, .pdf, .ppt"
      attribute="pgrcc"
      entity="Obra"
      :showRemoveButton="false"
      :file-name="pgrccFilename"
      :id="value.id"
      :label="fileLabel"
      :read-only="readOnly"/>
    <div v-if="value.pgrccNomeArquivo || filePgrcc.length" style="display: inline-flex">
      <dx-button
        style="margin-right: 10px"
        type="info"
        text="Baixar"
        @click="downloadAnexo()"
      />
      <dx-button
        type="info"
        text="Remover"
        @click="removerAnexo()"
      />
      <span style="place-self: center; font-weight: bold; margin-left: 10px">{{ filePgrcc[0] && filePgrcc[0].name || value.pgrccNomeArquivo }}</span>
    </div>

    <message-error :message="errorMessage" />

    <md-button
      v-if="!readOnly"
      class="md-raised md-accent float-right"
      @click="onClickButtonSave">
      Salvar
    </md-button>
    <md-button
      class="md-raised md-accent float-right"
      @click="onClickButtonBack">
      {{ readOnly ? 'Anterior' : 'Voltar' }}
    </md-button>
    <md-button
      class="md-raised md-primary float-right"
      @click="onClickButtonCancel">
      {{ readOnly ? 'Voltar' : 'Cancelar' }}
    </md-button>
  </div>
</template>

<script>
import DxTextArea from 'devextreme-vue/text-area'
import { mapState } from 'vuex'

import { SimpleGrid } from '@/components'
import ModelSelect from '@/components/Form/ModelSelect'
import ModelFileUploader from '@/components/Form/ModelFileUploader'
import ObraData from './ObraData'
import { listTransportadoras } from '../service'
import MessageError from '../../../../components/MessageError'
import GridTipoResiduos from '../../../../components/Obra/GridTipoResiduos'
import DxButton from 'devextreme-vue/button'
import { doRequest } from '@/pluggables/http'
import { confirm } from 'devextreme/ui/dialog'
import notify from 'devextreme/ui/notify'

export default {
  name: 'maintain-step-material-transportador',
  components: {
    MessageError,
    DxTextArea,
    SimpleGrid,
    ModelSelect,
    ModelFileUploader,
    GridTipoResiduos,
    DxButton
  },
  props: {
    /**
     * O objeto com os dados da obra que esta sendo mantida.
     */
    value: {
      type: Object,
      required: true
    },

    /**
     * Se os dados da obra sao somente para leitura.
     */
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  /**
   * Metodo do vue, com o objetivo de obter o estado inicial do componente.
   * @returns {Object} O estado inicial do componente.
   */
  data () {
    return {
      tipoResiduo: {
        add: {
          selected: null,
          volume: null
        },
        gridData: {
          value: {
            visible: true
          },
          columns: [
            { dataField: 'classe', caption: 'Classe de material', width: 200 },
            { dataField: 'residuo', caption: 'Material - Tipo de resíduo', width: 120 },
            { dataField: 'residuos', caption: 'Materiais - Tipos de resíduos da mesma classe', width: 120 },
            { dataField: 'volume', caption: 'Volume em m³', width: 100 }
          ],
          dataSource: []
        }
      },
      transportador: {
        add: {
          selected: null
        },
        gridData: {
          value: {
            visible: true
          },
          columns: [
            { dataField: 'nome', caption: 'Transportadora', width: '33.3%' },
            { dataField: 'cpfCnpj', caption: 'CPF/CNPJ', width: '33.3%' },
            { dataField: 'telefone', caption: 'Telefone', width: '33.3%' }
          ],
          dataSource: [],
          keyExpr: 'id'
        }
      },
      observacao: '',
      allClassesMateriais: [],
      filePgrcc: [],
      errorMessage: '',
      errorMessageTransportadora: '',
      errorMessageTipoResiduo: ''
    }
  },
  computed: {
    /**
     * Valor computado do nome do arquivo PGRCC.
     * @returns {string}
     */
    pgrccFilename () {
      return this.filePgrcc.length ? this.filePgrcc[0].name : undefined
    },

    /**
     * Valor computado do rotulo do campo de arquivo PGRCC.
     * @returns {string}
     */
    fileLabel () {
      return this.readOnly
        ? 'PGRCC'
        : this.filePgrcc.length > 0
          ? 'Substituir PGRCC'
          : 'Anexar PGRCC'
    },
    ...mapState('Login', ['user'])
  },
  watch: {
    value: {
      immediate: true,
      /**
       * Metodo de callback de quando ocorre mudancas no valor da prop 'value'.
       * Com o objetivo de definir os novos valores para os campos da tela.
       * @param {Object} value - O novo valor da prop 'value'.
       */
      handler (value) {
        this.tipoResiduo.gridData.dataSource = value.residuos || []
        this.transportador.gridData.dataSource = value.transportadores || []
        this.observacao = value.observacao || ''
        this.filePgrcc = value.filePgrcc || []
      }
    }
  },
  methods: {
    /**
     * Metodo de callback de quando e clicado no botao de salvar. Com o objetivo
     * de emitir o evento de input, para atualizar os dados da obra e depois
     * emitir o evento de salvar os dados da obra, caso o formulario de obra
     * estiver valido.
     */
    onClickButtonCancel () {
      this.$emit('cancel')
    },
    /**
     * Metodo de callback de quando e clicado no botao de salvar. Com o objetivo
     * de emitir o evento de input, para atualizar os dados da obra e depois
     * emitir o evento de salvar os dados da obra, caso o formulario de obra
     * estiver valido.
     */
    onClickButtonBack () {
      this.$emit('back')
    },
    /**
     * Metodo de callback de quando e clicado no botao de salvar. Com o objetivo
     * de emitir o evento de input, para atualizar os dados da obra e depois
     * emitir o evento de salvar os dados da obra, caso o formulario de obra
     * estiver valido.
     */
    onClickButtonSave () {
      if (this._validateForm()) {
        if (this.value.shape && !this.value.shape.wkid) {
          this.value.shape.wkid = '4326'
          this.value.shape.crs = { type: 'name', properties: { name: 'EPSG:4326' } }
        }

        this.$emit('input', new ObraData({
          ...this.value,
          transportadores: this.transportador.gridData.dataSource,
          residuos: this.tipoResiduo.gridData.dataSource,
          observacao: this.observacao,
          filePgrcc: this.filePgrcc
        }))
        this.$emit('save')
      }
    },

    /**
     * Metodo de callback de quando e clicado no botao de adicionar
     * transportadora. Com o objetivo de adicionar uma transportadora na lista
     * de transportadoras da obra.
     */
    async onClickButtonAddTransportadora () {
      this.errorMessageTransportadora = null

      const data = this.transportador.add.selected

      if (!data) {
        this.errorMessageTransportadora = 'Não foi escolhido uma Transportadora.'
        return
      }

      const alreadyHasTransportadora = this.transportador.gridData.dataSource
        .find(val => {
          const idA = `${(val.data.nome || val.data.nomeRazaoSocial)}_${val.data.cpfCnpj}`
          const idB = `${(data.nome || data.nomeRazaoSocial)}_${data.cpfCnpj}`
          return idA === idB
        })

      if (alreadyHasTransportadora) {
        this.errorMessageTransportadora = `O transportador ${data.nomeRazaoSocial} já foi informado.`
        return
      }

      const transportadora = {
        id: data.id,
        nome: data.nomeRazaoSocial,
        cpfCnpj: data.cpfCnpj,
        telefone: data.telefone,
        data
      }
      this.transportador.add.selected = null
      this.transportador.gridData.dataSource.push(transportadora)
    },

    /**
     * Metodo de callback de quando e clicado no botao de adicionar um tipo de
     * residuo. Com o objetivo de adicionar um tipo de residuo na listagem de
     * tipos de residuos da obra.
     */
    onClickButtonAddTipoResiduo () {
      this.errorMessageTipoResiduo = null

      const residuoSelected = this.tipoResiduo.add.selected
      const volume = this.tipoResiduo.add.volume

      if (!residuoSelected) {
        this.errorMessageTipoResiduo = 'Não foi escolhido um Tipo de Residuo.'
        return
      }

      if (!volume && volume !== 0) {
        this.errorMessageTipoResiduo = 'Não foi digitado o Volume.'
        return
      }

      const alreadyHasTipoResiduo = this.tipoResiduo.gridData.dataSource
        .find(val => val.data.residuo.id === residuoSelected.id)
      if (alreadyHasTipoResiduo) {
        this.errorMessageTipoResiduo = 'A Classe de Material ou Tipo de Resíduo já foi informada.'
        return
      }

      const classeMaterial = this.allClassesMateriais.find(classe =>
        classe.id === residuoSelected.idClasseMaterial) || null
      const residuo = {
        classe: classeMaterial.classe,
        residuo: residuoSelected.nome,
        residuos: classeMaterial
          ? classeMaterial.tipoResiduos.map(residuo => residuo.nome).join(', ')
          : '',
        volume,
        data: {
          residuo: residuoSelected,
          volume,
          classeMaterial
        }
      }
      this.tipoResiduo.add.selected = null
      this.tipoResiduo.add.volume = null
      this.tipoResiduo.gridData.dataSource.push(residuo)
    },

    onChangeTipoResiduos (value) {
      this.$emit('input', {
        ...this.value,
        ...value
      })
    },

    /**
     * Metodo para buscar a lista de transportadoras do servidor para o combo de
     * transportadoras.
     * @returns {Object[]} A lista de transportadoras.
     */
    async _doTransportadoraRequest (params) {
      const result = await listTransportadoras(params)

      if (result.success) {
        return result.data.rows || []
      } else {
        throw new Error('Falha ao buscar a lista de transportadoras')
      }
    },

    /**
     * Metodo de comando, com o objetivo de validar o formulario.
     * @returns {Boolean} Se o formulario esta valido ou nao.
     */
    _validateForm () {
      this.errorMessage = null

      if (this.transportador.gridData.dataSource.length === 0) {
        this.errorMessage = 'Não há nenhuma transportadora.'
        return false
      }

      if (this.filePgrcc.length) {
        const [file] = this.filePgrcc
        if (file.size > 1000000) {
          this.errorMessage = 'Só é permitido anexar um arquivo com tamanho máximo: 1MB.'
          return false
        }

        if (!file.name.match(/\.(doc(x?)|xls(x?)|p(df|pt))$/i)) {
          this.errorMessage = 'Só é permitido anexar um arquivo com uma das extensões: doc, docx, xls, xlsx, pdf, ppt.'
          return false
        }
      }

      const residuosRepeted = Object.entries(this.tipoResiduo.gridData.dataSource
        // Conta quantas vezes cada id aparece
        .reduce((acc, { tipoResiduo }) => {
          if (!(tipoResiduo.id in acc)) {
            acc[tipoResiduo.id] = { count: 0, tipoResiduo }
          }
          acc[tipoResiduo.id].count++
          return acc
        }, {}))
        // Filtra os que sao maiores que um
        .filter(v => v[1].count > 1)
        // Pega os nomes
        .map(v => v[1].tipoResiduo.nome)
      if (residuosRepeted.length) {
        this.errorMessage = `O(s) tipo(s) de resíduos ${residuosRepeted.join(', ')} está(ão) repetido(s).`
        return false
      }

      if ('id' in this.$route.params && !this.value.motivoAlteracao) {
        this.errorMessage = 'Na edição de obras o campo \'Motivo da Alteração dos Dados da Obra\' é obrigatório.'
        return false
      }

      return true
    },

    async removerAnexo () {
      if (this.value.pgrccNomeArquivo) {
        const dialogResult = await confirm('<i>Tem certeza que deseja remover este anexo?</i>', 'Confirmar')
        if (dialogResult) {
          try {
            await doRequest({
              method: 'put',
              url: `Obra/${this.value.id}`,
              params: {
                pgrcc: null,
                pgrccNomeArquivo: null,
                removePgrcc: true
              }
            })
            this.value.pgrccNomeArquivo = null
          } catch (error) {
            notify('Não foi possível remover este anexo, por favor contate um administrador do sistema', 'error')
            console.error(error)
          }
        }
      }
      this.filePgrcc = []
    },
    async downloadAnexo () {
      const download = async (buffer, name) => {
        const fileBuffer = new Uint8Array(buffer)
        const blob = new Blob([fileBuffer])
        const fileName = name || this.value.pgrccNomeArquivo
        const link = document.createElement('a')
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob)
          link.setAttribute('href', url)
          link.setAttribute('download', fileName)
          link.style.visibility = 'hidden'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      }
      const { data: obra } = await doRequest({
        method: 'post',
        url: 'Obra/find',
        params: {
          fields: ['pgrcc'],
          where: {
            id: this.value.id
          }
        }
      })
      if (Array.isArray(obra.rows) && obra.rows.length && obra.rows[0].pgrcc) {
        const row = obra.rows[0] && obra.rows[0].pgrcc ? obra.rows[0].pgrcc : null
        await download(row.data)
      } else if (this.filePgrcc.length) {
        const buffer = await this.filePgrcc[0].arrayBuffer()
        await download(buffer, this.filePgrcc[0].name)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.error-message-card {
  background-color: #B00020 !important;
  color: white !important;
  width: 100%;
}
</style>
