import AvaliacaoSolicitacaoTransporte from './AvaliacaoSolicitacaoTransporte.vue'

export default {
  path: '/avaliar-solicitacao-transporte',
  name: 'avaliar-solicitacao-transporte',
  component: AvaliacaoSolicitacaoTransporte,
  meta: {
    icon: 'mdi mdi-truck-check-outline',
    title: 'Avaliar Solicitação de Transporte',
    requiresAuth: true,
    permissionType: 'consulta'
  },
  props: true
}
