/**
 * Classe responsavel por transformar os dados retornados do servidor para a
 * estrutura utilizada no formulario de mater obra.
 *
 * @property {Object} obra - Os dados da obra retornada pelo servidor ou null se
 * nao foi possível obter esses dados.
 */
export default class FindObraResponseDto {
  /**
   * Construtor da classe.
   * @param {Object} data - Os dados das obras retornados do servidor.
   */
  constructor (data) {
    this.obra = data.count > 0 ? data.rows[0] : null
  }

  /**
   * Metodo para obter um objeto com os parametros para a classe ObraData com os
   * dados da obra retornada pelo servidor.
   * @returns {Object} Os parametros para a classe ObraData ou null se os dados
   * da obra estiverem invalidos.
   */
  toObraDataParams () {
    const { pessoa = {} } = this.obra.gerador
    return {
      id: this.obra.id,
      cpfCnpjSelect: pessoa.tipoPessoa === 'J' ? 'CNPJ' : 'CPF',
      cpfCnpj: pessoa ? pessoa.cpfCnpj : null,
      nome: pessoa ? pessoa.nomeRazaoSocial : null,
      pessoa,
      nomeObra: this.obra.nomeObra,
      duracaoInicial: this._getDate(this.obra.duracaoInicial),
      duracaoFinal: this._getDate(this.obra.duracaoFinal),
      possuiAlvara: !!this.obra.possuiAlvara,
      numeroAlvara: this.obra.numeroAlvara,
      cep: this.obra.cep,
      municipio: this.obra.municipio ? this.obra.municipio.nome : null,
      uf: this.obra.municipio ? this.obra.municipio.uf : null,
      bairro: this.obra.bairro,
      complemento: this.obra.complemento,
      numero: this.obra.numero,
      codIbge: this.obra.codIbge,
      endereco: this.obra.endereco,
      transportadores: this._getTransportadores(this.obra),
      residuos: this.obra.obraTipoResiduos,
      observacao: this.obra.observacao,
      motivoAlteracao: this.obra.motivoAlteracao,
      filePgrcc: [],
      filePgrcc2: [],
      pgrccNomeArquivo: this.obra.pgrccNomeArquivo,
      shape: {
        ...this.obra.shape,
        crs: {
          type: 'name',
          properties: {
            name: 'EPSG:4326'
          }
        }
      }
    }
  }

  /**
   * Metodo para obter a data apartir de uma data em string.
   * @param {String} dateStr - A data em string.
   * @returns {Date} A data obtida da string ou null se ocorrer um erro.
   */
  _getDate (dateStr) {
    if (typeof dateStr === 'string') {
      return new Date(`${dateStr}T00:00:00`)
    } else {
      return null
    }
  }

  /**
   * Metodo para obter a lista com o arquivo do PGRCC da obra.
   * @param {Object} pgrcc - Os dados do arquivo retornados pelo servidor.
   * @param {string} filename - O nome do arquivo.
   * @returns {File[]} A lista com o arquivo PGRCC.
   */
  _getFilePgrcc (pgrcc, filename) {
    if (pgrcc && pgrcc.type === 'Buffer') {
      const intArray = Uint8Array.from(pgrcc.data)
      return [new File([intArray.buffer], filename)]
    } else {
      return []
    }
  }

  /**
   * Metodo para obter a lista de transportadores da obra.
   * @param {Object} obra - Os dados da obra retornada do servidor.
   * @returns {Object[]} A lista de transportadores da obra.
   */
  _getTransportadores (obra) {
    return (obra.transportadores || [])
      .map(transportador => {
        const { pessoa } = transportador

        if (!pessoa) {
          return null
        }

        return {
          id: pessoa.id,
          nome: pessoa.nomeRazaoSocial,
          cpfCnpj: pessoa.cpfCnpj,
          telefone: pessoa.telefone,
          data: {
            idPerfil: transportador.id,
            nomeRazaoSocial: pessoa.nomeRazaoSocial,
            cpfCnpj: pessoa.cpfCnpj
          }
        }
      })
      .filter(transportador => transportador !== null)
  }
}
