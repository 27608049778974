export default {
  set_loading (state, isLoading) {
    state.isLoading = isLoading
  },
  set_data (state, { entity, data }) {
    state.data[entity] = data
  },
  set_request_address (state, { response }) {
    state.requestAddress = response
  },
  set_request_att (state, { response }) {
    state.requestAtt = response
  },
  set_request_ctr (state, { response }) {
    state.requestCTR = response
  },
  set_request_move_residuo (state, { response }) {
    state.requestMoveResiduo = response
  },
  set_request_veiculo_cacamba (state, { response }) {
    state.requestVeiculoCacamba = response
  },
  set_request_residuo_regiao (state, { response }) {
    state.requestResiduoRegiao = response
  }
}
