<template>
 <ViewOcorrencia
   v-if="sendValues"
   :values="sendValues"
   :compactMode="true"
   @ocorrencia-saved="onOcorrenciaSaved"
 >
 </ViewOcorrencia>
</template>
<script>

import config from 'config'
import { doRequest, http } from '@/pluggables/http'
import CustomStore from 'devextreme/data/custom_store'
import * as esriGeocode from 'esri-leaflet-geocoder'
import ViewOcorrencia from '@/pages/Cruds/AcompanharOcorrencia/View/viewOcorrencia'
import { getFotoOcorrencia } from '@/pages/Cruds/AcompanharOcorrencia/service'

const baseUrl = config.baseUrl
export default {
  name: 'popup-ocorrencia',
  components: {
    ViewOcorrencia
  },
  props: {
    values: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    userData: {
      type: Object,
      required: true
    },
    marker: {
      type: Object,
      required: true
    },
    find: {
      type: Function
    },
    setLoading: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      address: null,
      sendValues: null,
      fieldsBind: {
        cacamba: {
          dataSource: new CustomStore({
            key: 'id',
            load: (loadOptions) => this.$utils.wrapRequestForGrid(this.find({
              entity: 'Cacamba',
              params: {
                fields: ['id', 'numeroIdentificacao']
              },
              loadOptions
            })),
            paginate: true,
            pageSize: 5,
            byKey: async key => {
              return http.post(`${baseUrl}/Cacamba/find`, {
                fields: ['id', 'numeroIdentificacao']
              })
            }
          })
        }
      },
      maskRule: {
        H: /[a-z]/i
      },
      toastType: null,
      idCacamba: null,
      warningMessage: null,
      showToast: false,
      fotoCacamba: [],
      showIdentificacaoCacamba: null,
      identificacaoCacamba: null,
      showNumeroIdentificacao: null,
      numeroIdentificacao: null,
      selectedType: null,
      typeSelection: ['Sim', 'Não'],
      irregularidadeTextArea: null
    }
  },
  computed: {
    style () {
      return {
        width: this.fieldSetWidth,
        height: this.fieldSetHeight
      }
    }
  },
  watch: {
    numeroIdentificacao (value) {
      if (value) this.idCacamba = value.id
    }
  },
  methods: {
    onOcorrenciaSaved () {
      this.$emit('taskSaved')
    },
    /**
     * Metodo para obter o geojson da visualizacao atual do mapa.
     * @returns {Object} O geoJson da visualizacao atual do mapa.
     */
    getBoundsGeoJson (latlong) {
      return {
        type: 'Point',
        coordinates: [latlong.lng, latlong.lat],
        srid: '4326',
        crs: { type: 'name', properties: { name: 'EPSG:4326' } }
      }
    },
    identificadorCheckBoxChanged (payload) {
      if (payload.value === 'Sim') {
        this.showNumeroIdentificacao = true
        this.identificacaoCacamba = null
        this.showIdentificacaoCacamba = false
      }
      if (payload.value === 'Não') {
        this.showNumeroIdentificacao = false
        this.idCacamba = null
        this.numeroIdentificacao = null
        this.showIdentificacaoCacamba = true
      }
    },
    async getReverseGeocode () {
      const reverseGeocode = esriGeocode.reverseGeocode().latlng([this.marker._latlng.lat, this.marker._latlng.lng])
      return new Promise((resolve, reject) => {
        reverseGeocode.run((error, result) => {
          if (error) {
            reject(error)
          }
          resolve((result))
        })
      })
    },
    async save () {
      this.setLoading(true)
      const formData = new FormData()
      const file = (this.fotoCacamba && this.fotoCacamba[0]) || null
      file !== null && formData.set('foto', file)
      if (this.identificacaoCacamba) {
        formData.set('identificacaoCacamba', this.identificacaoCacamba)
      }
      if (this.idCacamba) {
        formData.set('idCacamba', this.idCacamba)
      }
      formData.set('local', this.address)
      formData.set('irregularidadeCom', 'Caçamba')
      formData.set('descricaoIrregularidade', this.irregularidadeTextArea)
      formData.set('idUsuarioRegistro', this.userData.id)
      formData.set('shape', JSON.stringify(this.getBoundsGeoJson(this.marker._latlng)))
      let result
      if (this.fotoCacamba.length === 0) {
        this.showToast = true
        this.toastType = 'error'
        this.warningMessage = 'É obrigatório anexar uma foto para a ocorrência'
        this.setLoading(false)
        throw new Error('Anexo inválido')
      }
      if (!this.irregularidadeTextArea) {
        this.showToast = true
        this.toastType = 'error'
        this.warningMessage = 'Insira uma descrição para a irregularidade'
        this.setLoading(false)
        throw new Error('Descrição Inválida')
      }
      if (!this.selectedType) {
        this.showToast = true
        this.toastType = 'error'
        this.warningMessage = 'Selecione se há um identificador na caçamba'
        this.setLoading(false)
        throw new Error('Tipo identificador inválido')
      }
      if (this.selectedType === 'Sim' && !this.numeroIdentificacao) {
        this.showToast = true
        this.toastType = 'error'
        this.warningMessage = 'Insira um número de identificação'
        this.setLoading(false)
        throw new Error('Campo identificador inválido')
      }
      if (this.selectedType === 'Não' && !this.identificacaoCacamba) {
        this.showToast = true
        this.toastType = 'error'
        this.warningMessage = 'Descreva um identificador para a caçamba'
        this.setLoading(false)
        throw new Error('Campo identificador inválido')
      }
      try {
        result = await doRequest({
          method: 'post',
          url: 'Ocorrencia',
          params: formData,
          options: {
            cleanObject: false,
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        })
      } catch (err) {
        throw new Error(err)
      }
      if (result.success) {
        this.$emit('removeMarker', this.marker)
        this.showToast = true
        this.toastType = 'success'
        this.warningMessage = 'Ocorrência salva'
        this.$emit('taskSaved')
      }
      if (!result.success) {
        this.idCacamba = null
        this.showToast = true
        this.toastType = 'error'
        this.warningMessage = 'Erro ao salvar caçamba'
      }
      this.setLoading(false)
    }
  },
  async mounted () {
    const fotoOcorrencia = await getFotoOcorrencia(this.values)
    if (Array.isArray(fotoOcorrencia.data) && fotoOcorrencia.data.length) {
      this.values.foto = fotoOcorrencia.data[0].foto
    }
    this.sendValues = this.values

    const getReverseGeocode = await this.getReverseGeocode()
    this.address = getReverseGeocode.address.Match_addr
  }
}
</script>

<style lang="scss">
//   @import "../../../../../global.scss";
// .fieldSet {
//   width: 362px;
//   height: 400px;
//   overflow: auto;
//   @media only screen and (max-width: 600px) {
//     width: 250px;
//     height: 433px;
//   }
// }
// .fileUploaderCacamba {
//   width: 152px;
//   @media (max-width: 600px) {
//     width: 152px;
//   }
// }
//
// .leaflet-popup-content-wrapper {
//   width: 400px;
//   @media only screen and (max-width: 600px) {
//     width: 258px;
//   }
// }
// .leaflet-popup-content p {
//     margin: 14px 0;
// }
// .saveCacambaIrregularButton {
//   position: absolute;
//   bottom: 20px;
//   left: 71%;
//   @media only screen and (max-width: 600px) {
//     left: 59%;
//   }
// }
//   .texAreaIrregularidade {
//     width: 340px;
//     height: 110px;
//     @media only screen and (max-width: 600px) {
//       width: 200px;
//     }
//   }
//   .identificadorLabel {
//     @media only screen and (max-width: 600px) {
//       width: 200px;
//     }
//     width: 300px;
//   }
//   .showNumeroIdentificacao {
//     width: 340px;
//     @media only screen and (max-width: 600px) {
//       width: 220px;
//     }
//   }
//   .texAreaIrregularidade {
//     width: 340px;
//     height: 75px;
//     @media only screen and (max-width: 600px) {
//       width: 220px;
//     }
//   }
</style>
