<template>
  <div class="transportadores-wrapper">
    <div ref="overlayDiv" class="overlay-div" @click="closeOverlayDiv" />
    <div class="transportadores-page">
      <header class="transportadores-header">TRANSPORTADORES DE RCC LICENCIADOS PELO SLU</header>
      <div v-show="loading" class="transportadores-loading">
        <md-progress-bar class="md-primary" md-mode="indeterminate"></md-progress-bar>
      </div>
      <div class="transportadores-search-bar">
        <TransitionGroup name="bounce" class="search-inputs">

          <input class="styled-input" ref="styleInput" v-model="searchByNome" key="searchByNome" placeholder="Buscar por nome" v-show="showSearchBar" />
          <div key="questionMarkNome" class="custom-tooltip" v-show="!isMobile">
            <md-icon class="md-primary">contact_support</md-icon>
            <span class="custom-tooltiptext">Use este campo para localizar um transportador pelo o nome</span>
          </div>

          <input class="styled-input" ref="styleInput" v-model="searchByBairro" key="searchByBairro" placeholder="Buscar por bairro" v-show="showSearchBar"/>
          <div key="questionMarkBairro" class="custom-tooltip" v-show="!isMobile">
            <md-icon class="md-primary">contact_support</md-icon>
            <span class="custom-tooltiptext">Use este campo para localizar um transportador pelo o bairro</span>
          </div>
        </TransitionGroup>
      </div>

      <div class="card-grid" v-if="items.length">
        <md-card v-for="(item,index) in items" :key="index" class="transportadores-card">
          <div @click="clickCard($event)" style="height: 100%; display: flex;align-items: center;">
            <md-card-content>
              <div id="nomeRazaoSocialField">
                <div class="three-dots-base">
                  <span class="three-dots-text"><b>{{item.pessoa.nomeRazaoSocial}}</b></span> <br>
                </div>
              </div>
              {{item.pessoa.endereco}} - {{item.pessoa.numero}},
              {{item.pessoa.complemento}}, {{item.pessoa.bairro}},
              {{item.pessoa.municipio.nome}} - {{item.pessoa.municipio.uf}} - {{formatCep(item.pessoa.cep)}} <br>
              <a :href="`mailto:${item.pessoa.email}`">{{item.pessoa.email}}</a><br>
              {{formatPhoneNumber(item.pessoa.celular)}}
              {{formatPhoneNumber(item.pessoa.telefone)}} <br>
            </md-card-content>
          </div>
        </md-card>
      </div>

      <div v-else class="transportadores-not-found">
        <div>
          <span>Nenhum transportador encontrado</span>
        </div>
      </div>

      <div class="pagination-wrapper">
        <md-button class="md-icon-button" @click="firstPage($event)">
          <md-icon class="md-primary">keyboard_double_arrow_left</md-icon>
        </md-button>
        <md-button class="md-icon-button" @click="previousPage($event)">
          <md-icon class="md-primary">chevron_left</md-icon>
        </md-button>

        <div class="pagination">
          <md-button class="md-icon-button" v-for="index in pages" :key="index" :id="`page-${index}`" @click="pageChange($event.target.parentElement, index)">
            {{index}}
          </md-button>
        </div>

        <md-button class="md-icon-button" @click="nextPage($event)">
          <md-icon class="md-primary">chevron_right</md-icon>
        </md-button>
        <md-button class="md-icon-button" @click="lastPage($event)">
          <md-icon class="md-primary">keyboard_double_arrow_right</md-icon>
        </md-button>
      </div>
    </div>
  </div>
</template>

<script>
import mixinCrud from '@/pages/Cruds/mixin-crud'
import { http } from '@/pluggables/http'
import config from 'config'
import { debounce } from '@/helper/general'

export default {
  name: 'Transportadores',
  mixins: [
    mixinCrud
  ],
  data () {
    return {
      currentPage: 1,
      itemsPerPage: null,
      pages: 0,
      items: [],
      currentActivePageEl: null,
      activeCard: null,
      showSearchBar: false,
      searchByNome: null,
      searchByBairro: null,
      searchParams: {},
      loading: false,
      showBairroTooltip: false,
      isMobile: false
    }
  },
  async beforeMount () {
    await this.getData()
    await this.$nextTick()
    // The above lines are important to set the viewport properly on android devices and resolves the web browser address bar issue with 100vh viewports, don't remove
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
    window.addEventListener('resize', () => {
      vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    })

    new ResizeObserver((cb) => {
      if (cb[0].contentRect.width <= 900) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
    }).observe(document.body)
    this.showSearchBar = true
    // This is only to apply the style to the first page when the page is first loaded.
    if (this.currentPage === 1) {
      const firstPageEl = document.getElementById('page-1')
      this.currentActivePageEl = firstPageEl
      firstPageEl.style.color = 'white'
      firstPageEl.style.backgroundColor = 'var(--md-theme-default-primary)'
    }
  },
  watch: {
    /**
     * Watches the "nomeRazaoSocial" input to fetch data from server and populate the grid
     */
    searchByNome: debounce(async function (newVal) {
      this.searchParams.nomeRazaoSocial = newVal
      await this.pageChange(document.getElementById('page-1'), (1))
    }, 650),

    /**
     * Watches the "bairro" input to fetch data from server and populate the grid
     */
    searchByBairro: debounce(async function (newVal) {
      this.searchParams.bairro = newVal
      await this.pageChange(document.getElementById('page-1'), (1))
    }, 650)
  },
  methods: {
    formatPhoneNumber (phoneNumberString) {
      if (!phoneNumberString || phoneNumberString === '') return null
      const cleaned = phoneNumberString.replace(/\D/g, '').replace(/-/g, '')
      const match = cleaned.match(/^(\d{2})(\d{4})(\w+)$/)
      if (match && phoneNumberString.indexOf('999999') === -1) {
        if (phoneNumberString.length > 9) { return `(${match[1]}) ${match[2]}-${match[3]}` } else { return `(61) ${match[1]}${match[2]}-${match[3]}` }
      }
      return null
    },
    formatCep (cep) {
      if (!cep || cep === '') return null
      const cleaned = cep.replace(/\D/g, '').replace(/-/g, '')
      const match = cleaned.match(/^(\d{5})(\w+)$/)
      if (match) {
        return `${match[1]}-${match[2]}`
      }
      return null
    },
    /**
     * Unused method, but it will be useful in the future
     */
    closeOverlayDiv () {
      this.$refs.overlayDiv.style.display = 'none'
      this.activeCard.classList.remove('fullscreen-card')
      this.activeCard.classList.add('transportadores-card')
      this.activeCard.querySelector('#nomeRazaoSocialField').children[0].classList.add('three-dots-base')
    },
    /**
     * Unused method, but it will be useful in the future
     */
    clickCard (el) {
      if (!this.isMobile) {
        this.activeCard = el.currentTarget.parentElement
        if (this.activeCard.style.position !== 'fixed') {
          this.activeCard.classList.add('fullscreen-card')
          this.activeCard.classList.remove('transportadores-card')
          this.activeCard.querySelector('#nomeRazaoSocialField').children[0].classList.remove('three-dots-base')
          this.$refs.overlayDiv.style.display = 'block'
          document.addEventListener('keydown', (e) => {
            if (e.code === 'Escape') {
              this.closeOverlayDiv()
            }
          })
        }
      }
    },

    /**
     * Fires when a page is changed, fetch data from the backend and also changes the style of the currentPage
     * @param el
     * @param page
     * @returns {Promise<void>}
     */
    async pageChange (el, page) {
      if (page > 0) {
        if (this.currentActivePageEl) {
          this.currentActivePageEl.style.color = 'unset'
          this.currentActivePageEl.style.backgroundColor = 'unset'
        }
        this.currentActivePageEl = el
        this.currentPage = page
        if (el) {
          el.scrollIntoView({ inline: 'center' })
          el.style.color = 'white'
          el.style.backgroundColor = 'var(--md-theme-default-primary)'
        }
        await this.getData(null, null, this.searchParams)
        window.scrollTo(0, 0)
        await this.$nextTick()
      }
    },

    /**
     * Fires when the user clicks on the nextPage (arrow) button
     * @returns {Promise<void>}
     */
    async nextPage () {
      if (this.currentPage !== this.pages) {
        await this.pageChange(document.getElementById(`page-${this.currentPage + 1}`), (this.currentPage + 1))
      }
    },

    /**
     * Fires when the user clicks on the previousPage (arrow) button
     * @returns {Promise<void>}
     */
    async previousPage () {
      await this.pageChange(document.getElementById(`page-${this.currentPage - 1}`), (this.currentPage - 1))
    },

    /**
     * Fires when the user clicks on the lastPage (double arrow) button
     * @returns {Promise<void>}
     */
    async lastPage () {
      await this.pageChange(document.getElementById(`page-${this.pages}`), (this.pages))
    },

    /**
     * Fires when the user clicks on the firstPage (double arrow) button
     * @returns {Promise<void>}
     */
    async firstPage () {
      await this.pageChange(document.getElementById('page-1'), (1))
    },

    /**
     * Gets the data from the backend
     * Also handles the search params and the limit and offset of the current page
     * @param limit
     * @param offset
     * @param searchParams
     * @returns {Promise<void>}
     */
    async getData (limit = null, offset = null, searchParams = null) {
      if (window.innerHeight > 1080) {
        this.itemsPerPage = Math.ceil(window.innerHeight / 75)
      } else {
        this.itemsPerPage = 12
      }
      const dataLimit = limit || this.itemsPerPage
      const dataOffset = offset || (this.currentPage - 1) * this.itemsPerPage
      this.loading = true
      const { data: { data } } = await http.post(`${config.baseUrl}/transportadores/find`, {
        limit: dataLimit,
        offset: dataOffset,
        ...searchParams
      })
      this.items = data.rows
      this.pages = Math.ceil(data.count / this.itemsPerPage)
      this.loading = false
    }
  }
}
</script>

<style scoped>
.three-dots-base {
  display: flex;
  width: 400px;
}
.three-dots-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.transportadores-wrapper {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100); /* Used for mobile, specially android to calculate the entire viewport, dont remove this line */
  display: flex;
  justify-content: center;
}
.transportadores-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1366px;
  width: 100%;
  place-content: space-between;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
  height: 98%;
  align-self: center;
}
.pagination {
  position: relative;
  display: flex;
  overflow: hidden;
}
.pagination-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: auto;
  margin-bottom: 1%;
}
@media screen and (max-width: 900px) {
  .search-inputs {
    flex-direction: column !important;
    width: 100%;
  }
  .three-dots-base {
    width: 300px;
  }
  .transportadores-card {
    overflow-x: clip;
  }
  .card-grid {
    display: flex !important;
    flex-direction: column !important;
    overflow: auto;
    width: 100%;
    padding: 10px;
  }
  .transportadores-header {
    font-size: 12pt !important;
    text-align: center;
  }
  .transportadores-page {
    position: relative;
    width: 100%;
    border: unset;
    height: 100%;
  }
  .transportadores-search-bar {
    flex-direction: row;
    align-self: start !important;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
  }
  .styled-input {
    width: 100% !important;
  }
}
.card-grid:hover {
  overflow-x: hidden;
}
.card-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  overflow: auto;
  gap: 10px;
  width: 100%;
  padding: 10px;
}
.transportadores-card {
  transition: transform 0.6s;
  background-color: #f7f7f7 !important;
}
.transportadores-card:hover {
  cursor: pointer;
  transform: scale(1.03);
}
.transportadores-header {
  display: flex;
  width: 100%;
  height: 65px;
  place-items: center;
  place-content: center;
  font-weight: bold;
  background-color: var(--md-theme-default-primary);
  color: white;
  font-size: 20pt;
}
.transportadores-search-bar {
  display: flex;
  align-items: center;
  align-self: flex-start;
  padding-top: 5px;
  padding-left: 10px;
}
.styled-input {
  padding: 10px;
  height: 40px;
  border-radius: 4px;
  border: solid #ebebeb 1px;
  font-size: 14px;
  width: 250px;
}
.overlay-div {
  display: none;
  height: 100vh;
  width: 100vw;
  backdrop-filter: blur(5px);
  position: fixed;
  z-index: 100;
}
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
.transportadores-not-found {
  align-items: center;
  height: 100%;
  display: flex;
  font-size: 26pt;
  font-weight: 100;
  text-align: center;
}
.search-inputs {
  display: flex;
  gap: 10px
}
.transportadores-loading {
  position: relative;
  width: 100%;
  top: 1px;
}
.fullscreen-card {
  border-radius: 20px;
  transition: all 0.6s;
  position: fixed;
  width: 750px;
  height: 250px;
  z-index: 8000;
  top: 50%;
  left: 50%;
  position: fixed;
  transform: translate(-50%, -50%);
}

.custom-tooltip {
  position: relative;
  display: inline-block;
  align-self: center;
  cursor: pointer;
}

.custom-tooltip .custom-tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -60px;
  z-index: 200;
}

.custom-tooltip:hover .custom-tooltiptext {
  visibility: visible;
}
</style>
