export default [
  {
    path: '/tutorial',
    icon: 'mdi mdi-account-question',
    title: 'Tutorial'
  },
  {
    path: '/editar-dados-usuario',
    icon: 'mdi mdi-account',
    title: 'Editar meus dados'
  },
  {
    path: '/pendencia-documentos',
    icon: 'mdi mdi-file',
    title: 'Documentos'
  },
  {
    path: '/pessoa',
    icon: 'mdi mdi-account-circle',
    title: 'Cadastro de Pessoas'
  },
  {
    path: '/cacamba',
    icon: 'mdi mdi-bucket',
    title: 'Manter Caçamba'
  },
  {
    path: '/veiculo',
    icon: 'mdi mdi-truck',
    title: 'Manter Veículo'
  },
  {
    path: '/obra',
    icon: 'mdi mdi-excavator',
    title: 'Manter Obra'
  },
  {
    icon: 'mdi mdi-card-bulleted',
    title: 'CTR',
    children: [
      {
        path: '/localizar-ctr',
        icon: 'mdi mdi-file-search-outline',
        title: 'Localizar CTR'
      },
      {
        path: '/solicitacao-transporte/add',
        icon: 'mdi mdi-truck-delivery',
        title: 'Solicitar Transporte'
      },
      {
        path: '/avaliar-solicitacao-transporte',
        icon: 'mdi mdi-truck-check-outline',
        title: 'Avaliar Solicitação de Transporte'
      },
      {
        path: '/alocar-cacamba',
        icon: 'mdi mdi-truck-delivery',
        title: 'Alocar Caçamba'
      },
      {
        path: '/retirar-cacamba',
        icon: 'mdi mdi-truck-delivery-outline',
        title: 'Retirar Caçamba'
      },
      {
        path: '/enviar-transporte-nao-solicitado/add',
        icon: 'mdi mdi-truck-delivery',
        title: 'Enviar Transporte não Solicitado'
      },
      {
        path: '/cancelamento-ctr',
        icon: 'mdi mdi-card-bulleted-off',
        // title: 'Cancelamento de CTR',
        title: 'Analisar Solicitação de Cancelamento de CTR'
      },
      {
        path: '/consultar-empresa-ctr',
        icon: 'mdi mdi-home-search-outline',
        title: 'Consultar Empresa Ativa com CTR'
      }
    ]
  },
  {
    path: '/avaliar-recebimento-solicitacao',
    icon: 'mdi mdi-beaker-check',
    title: 'Analisar Recebimento de Resíduos'
  },
  {
    path: '/classe-material',
    icon: 'mdi mdi-shape',
    title: 'Manter Classe de Material'
  },
  {
    path: '/controle-residuos-triados',
    icon: 'mdi mdi-truck-fast',
    title: 'Controlar Resíduos Triados'
    // userType: ['Secretaria', 'Fiscalização', 'Entidade Pública', 'Transportador', 'Gerador', 'Destino Final']
  },
  {
    path: '/tipo-residuo',
    icon: 'mdi mdi-delete-variant',
    title: 'Manter Tipo de Resíduo'
  },
  {
    path: '/localizar-cacamba',
    icon: 'mdi mdi-truck',
    title: 'Localizar Caçamba'
  },
  {
    path: '/registrar-ocorrencia',
    icon: 'mdi mdi-clipboard-alert',
    title: 'Registrar Ocorrência'
  },
  {
    path: '/acompanhar-ocorrencia',
    icon: 'mdi mdi-clipboard-text',
    title: 'Acompanhar Ocorrência'
  },
  {
    path: '/consultar-att',
    icon: 'mdi mdi-delete-circle-outline',
    title: 'Consultar Capacidade da ATT'
  },
  {
    path: '/consulta-move-residuo',
    icon: 'mdi mdi-truck-fast-outline',
    title: 'Consultar Movimentação de Resíduos'
  },
  {
    path: '/consulta-residuo-regiao',
    icon: 'mdi mdi-select-place',
    title: 'Consultar Volume de Resíduos por Região'
  },
  {
    path: '/consulta-veiculo-cacamba',
    icon: 'mdi mdi-dump-truck',
    title: 'Consultar Veículo e Caçambas do Transportador'
  },
  {
    path: '/manter-mensagem',
    icon: 'mdi mdi-message-alert',
    title: 'Manter Mensagens'
  },
  {
    path: '/atribuicao-perfil',
    icon: 'mdi mdi-account-check-outline',
    title: 'Gerenciar permissões'
  },
  {
    path: '/configuracoes',
    icon: 'mdi mdi-cog',
    title: 'Configurações do Sistema'
  }
]
