/**
 * Classe responsavel por transformar os dados retornados do servidor para a
 * estrutura utilizada na listagem de obras.
 *
 * @property {Object[]} obras - A lista de obras retornadas pelo servidor.
 * @property {Number} count - A quantidade de obras retornadas pelo servidor.
 */
export default class ListObraResponseDto {
  /**
   * Construtor da classe.
   * @param {Object} data - A lista de obras retornados do servidor.
   */
  constructor (data) {
    this.obras = data.rows
    this.count = data.count
  }

  /**
   * Metodo para obter uma lista de objetos para a listagem de obras, com os
   * dados da obra retornada pelo servidor.
   * @returns {Object} A lista de obras para a listagem ou null se os dados das
   * obras estiverem invalidos.
   */
  toObraListObjects () {
    return this._isObrasValids(this.obras)
      ? this.obras.map(obra => this._getObraListObject(obra))
      : null
  }

  /**
   * Metodo para obter um objeto da obra para a listagem de obras, com um dos
   * dados de obra retornado pelo servidor.
   * @param {Object} obra - Os dados ja validados da obra retornada pelo
   * servidor.
   * @returns {Object} O objeto da obra para a listagem.
   */
  _getObraListObject (obra) {
    const { pessoa = {} } = obra.gerador
    return {
      id: obra.id,
      nomeObra: obra.nomeObra,
      cep: obra.cep,
      endereco: obra.endereco,
      duracaoInicial: obra.duracaoInicial,
      duracaoFinal: obra.duracaoFinal,
      cpfCnpj: pessoa.cpfCnpj,
      nomeRazaoSocial: pessoa.nomeRazaoSocial,
      pgrccNomeArquivo: obra.pgrccNomeArquivo
    }
  }

  /**
   * Metodo para verificar se uma lista de obras estao validas.
   * @param {Object[]} obras - A lista de obras a ser verificada.
   * @returns {Boolean} Se a lista esta valida.
   */
  _isObrasValids (obras) {
    return obras.every(obra => this._isObraValid(obra))
  }

  /**
   * Metodo para verificar se uma obra e valida.
   * @param {Object} obra - A obra a ser verificada.
   * @returns {Boolean} Se a obra e valida.
   */
  _isObraValid (obra) {
    return obra && obra.gerador && obra.gerador.pessoa
  }
}
