import ListRegistrarOcorrencia from './List/ListRegistrarOcorrencia.vue'
import MapRegistrarOcorrencia from './Map/MapRegistrarOcorrencia.vue'

export default [
  {
    path: '/registrar-ocorrencia',
    name: 'registrar-ocorrencia',
    component: ListRegistrarOcorrencia,
    meta: {
      requiresAuth: true,
      title: 'Registrar Ocorrência',
      hideFromMenu: true,
      permissionType: 'consulta'
    }
  },
  {
    path: '/registrar-ocorrencia/map',
    name: 'registrar-ocorrencia-map',
    component: MapRegistrarOcorrencia,
    props: true,
    meta: {
      requiresAuth: true,
      title: 'Registrar Ocorrência',
      hideFromMenu: true,
      permissionType: 'inclui'
    }
  }
]
