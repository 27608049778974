<template>
  <div class="generic-crud">
    <div v-if="action && (canAdd || canEdit || canView)" class="generic-crud-form">
      <h2 v-if="action === 'add'">Incluir Classe de Material</h2>
      <h2 v-else-if="canEdit">Editar Classe de Material</h2>
      <h2 v-else>Visualizar Classe de Material</h2>
      <fieldset>
        <legend>Classe de Material</legend>
        <simple-form
          v-model="formData"
          id="group"
          :fields="gridData.columns"
          :form-options="formOptions"
          ref="crudForm"/>

        <dx-button
          v-if="!isReadOnly"
          text="Salvar"
          type="success"
          @click="saveItem"/>
      </fieldset>
    </div>
    <div v-else>
      <div class="md-layout">
        <div class="md-layout-item">
          <h2 class="crud-title">Cadastro de Classe de Material</h2>
        </div>
        <div
          v-if="canAdd"
          class="md-layout-item"
          style="text-align: right;">
          <dx-button type="success" @click="$router.push({ path: 'classe-material/add' })"
                     text="Adicionar Novo"></dx-button>
        </div>
        <simple-grid
          entity="ClasseMaterial"
          :grid-data="gridData"
          :insertable="canAdd"
          :editable="canEdit"
          :deletable="canDelete"
          :viewable="canView && !canEdit"/>
      </div>
    </div>
  </div>
</template>
<script>
import CustomStore from 'devextreme/data/custom_store'
import { SimpleGrid, SimpleForm } from '@/components'
import mixinCrud from '../mixin-crud'
import { DxButton } from 'devextreme-vue'
import { hasPermission } from '@/util/permissions'

export default {
  name: 'classe-material-crud',
  mixins: [
    mixinCrud
  ],
  components: {
    SimpleGrid,
    SimpleForm,
    DxButton
  },
  data () {
    return {
      entity: 'ClasseMaterial',
      permissionName: 'Manter Classe de Material',
      formData: null,
      gridData: {
        value: {
          visible: true
        },
        columns: this.parseColumns([
          {
            dataField: 'classe',
            caption: 'Classe',
            dataType: 'string',
            validationRules: [{ type: 'required' }],
            editorOptions: { maxLength: 100 }
          },
          {
            dataField: 'descricao',
            caption: 'Descrição',
            dataType: 'string',
            editorType: 'dxTextArea',
            editorOptions: {
              maxLength: 500
            },
            validationRules: [{ type: 'required' }]
          }
        ]),
        dataSource: new CustomStore({
          key: 'id',
          load: (loadOptions) => {
            return this.$utils.wrapRequestForGrid(this.find({
              entity: this.entity,
              loadOptions
            }))
          },
          insert: (values) => this.insertRow({
            entity: this.entity,
            values
          }),
          update: (key, values) => this.updateRow({
            entity: this.entity,
            values,
            key
          }),
          remove: (key, values) => this.removeRow({
            entity: this.entity,
            key
          })
        }),
        onEditingStart: () => {
          this.isEditingData = true
        }
      },
      isEditingData: false
    }
  },
  computed: {
    canAdd () {
      return hasPermission('Manter Classe de Material', 'inclui')
    },
    canEdit () {
      return hasPermission('Manter Classe de Material', 'altera')
    },
    canDelete () {
      return hasPermission('Manter Classe de Material', 'exclui')
    },
    canView () {
      return hasPermission('Manter Classe de Material', 'consulta')
    },
    isReadOnly () {
      return (!this.canAdd && this.action === 'add') ||
        (!this.canEdit && this.action === 'edit')
    }
  }
}
</script>
