<template>
  <div class="registrar-ocorrencia">

    <viewRespostaOcorrencia
      v-if="showRespostaOcorrencia"
      :values="currentRowData"
    >
    </viewRespostaOcorrencia>
    <DxTooltip
      ref="tooltip"
      :visible="false"
    >
      <p>{{toolTipContent}}</p>
    </DxTooltip>
    <dx-button
      class="voltarButton"
      v-show="showRespostaOcorrencia"
      type="success"
      @click="showRespostaOcorrencia = false">Voltar</dx-button>
    <div v-if="!showRespostaOcorrencia">
      <div class="md-layout">
        <div class="md-layout-item">
          <h2 class="crud-title">Registrar Ocorrência</h2>
        </div>

        <div class="md-layout-item" style="text-align: right;">
          <dx-button type="success" @click="openCacambasMap">Registrar Nova Ocorrência</dx-button>
        </div>
      </div>
      <simple-grid
        entity="ocorrencia"
        :editable="false"
        :deletable="false"
        :excludable="false"
        :grid-data="gridData"
        ref="simpleGrid"/>
    </div>
  </div>
</template>
<script>
import DxButton from 'devextreme-vue/button'
import { mapGetters } from 'vuex'
import viewRespostaOcorrencia from '../View/ViewRespostaOcorrencia'
import { SimpleGrid } from '@/components'
import CustomStore from 'devextreme/data/custom_store'
import {
  convertDMS
} from '@/helper/coordinates'
import {
  getFotoOcorrencia
} from '../service'
import mixinCrud from '../../mixin-crud'
import { DxTooltip } from 'devextreme-vue/tooltip'
import { parseSortParams } from '@/util/dxDatasourceHelper'

export default {
  name: 'list-registrar-ocorrencia',
  components: {
    SimpleGrid,
    DxButton,
    viewRespostaOcorrencia,
    DxTooltip
  },
  mixins: [mixinCrud],
  /**
   * Metodo do vue, para obter o estado inicial do componente.
   * @returns {Object} O estado inicial do componente.
   */
  data () {
    return {
      toolTipContent: null,
      showRespostaOcorrencia: false,
      currentRowData: null,
      gridData: {
        value: {
          visible: true
        },
        columns: this.parseColumns([
          {
            dataField: 'id',
            sortOrder: 'desc',
            caption: 'Nº da Ocorrência',
            dataType: 'number',
            editorType: 'dxNumberBox',
            width: 180
          },
          {
            dataField: 'dataHoraRegistro',
            caption: 'Data/Hora do registro da ocorrência',
            format: 'dd/MM/yyyy - HH:mm',
            dataType: 'date',
            editorType: 'dxDateBox',
            width: 350
          },
          {
            dataField: 'local',
            caption: 'Local da Ocorrência',
            dataType: 'string',
            width: 200
          },
          {
            dataField: 'irregularidadeCom',
            caption: 'Irregularidade com',
            width: 200,
            dataType: 'string',
            editorType: 'dxLookup',
            editorOptions: {
              items: [
                'Transporte',
                'Caçamba'
              ]
            }
          },
          {
            dataField: '$cacamba->perfil.orgao$',
            caption: 'Órgão Fiscalizador',
            width: 200,
            dataType: 'string',
            editorType: 'dxLookup',
            editorOptions: {
              dataSource: new CustomStore({
                key: 'distinctOrgao',
                load: (loadOptions) => {
                  return this.$utils.wrapRequestForGrid(this.find({
                    entity: 'ViewFiscalizacao',
                    params: {
                      attributes: [{ fn: 'DISTINCT', col: 'orgao', as: 'distinctOrgao' }],
                      subQuery: false
                    },
                    loadOptions
                  }))
                },
                byKey: async (key) => {
                  const res = await this.find({
                    entity: 'ViewFiscalizacao',
                    params: {
                      attributes: [{ fn: 'DISTINCT', col: 'orgao', as: 'distinctOrgao' }],
                      subQuery: false,
                      where: {
                        orgao: key
                      }
                    },
                    rowsOnly: true
                  })

                  return res.length > 0 ? res[0] : null
                }
              }),
              displayExpr: 'distinctOrgao',
              valueExpr: 'distinctOrgao'
            },
            calculateDisplayValue: (data) => {
              if (data.veiculo && data.veiculo.perfil) {
                return data.veiculo.perfil.orgao
              }
              if (data.cacamba && data.cacamba.perfil) {
                return data.cacamba.perfil.orgao
              }
            }
          },
          {
            dataField: 'situacao',
            caption: 'Situação',
            dataType: 'string',
            editorType: 'dxLookup',
            editorOptions: {
              items: [
                'Respondida',
                'Em andamento',
                'Aguardando resposta',
                'Improcedente'
              ]
            }
          },
          {
            dataField: 'usuario.nome',
            caption: 'Usuário',
            dataType: 'string',
            calculateDisplayValue: (data) => {
              return data.usuario.nome
            },
            calculateFilterExpression: (filterValue, selectedFilterOperation) => {
              const filterExpression = [
                ['_usuario', 'nome', selectedFilterOperation, filterValue]
              ]
              return filterExpression
            }
          },
          {
            type: 'buttons',
            showInColumnChooser: false,
            buttons: [
              {
                cssClass: 'dx-button mdi mdi-eye',
                text: 'Visualizar',
                visible: (data) => {
                  if (data.row.values.find(value => value === 'Respondida')) {
                    return true
                  } else {
                    return false
                  }
                },
                onClick: ({ row: { data } }) => {
                  this.onSoliticaoActionClick('view', data)
                }
              }
            ]
          }
        ]),
        onCellPrepared: async (e) => {
          if (e.rowType === 'data' && e.columnIndex === 2) {
            await this.$nextTick(() => {
              e.cellElement.onmouseenter = () => {
                this.$refs.tooltip.instance.option({
                  target: e.cellElement,
                  visible: true
                })
                const toolTipText = convertDMS(e.data.shape.coordinates[1], e.data.shape.coordinates[0])
                this.toolTipContent = toolTipText
              }
            })
          }
        },
        dataSource: new CustomStore({
          key: 'id',
          load: async (loadOptions) => {
            const userFilter = this.$utils.parseFilterValues(loadOptions.filter, '_usuario')
            const isCidadao = !!this.userData.userTypes.find(userType => userType === 'Cidadão')
            return this.$utils.wrapRequestForGrid(this.find({
              entity: 'Ocorrencia',
              params: {
                order: parseSortParams(loadOptions.sort),
                limit: loadOptions?.take || 10,
                offset: loadOptions?.skip,
                ...loadOptions.customQueryParams,
                fields: ['id', 'idUsuarioRegistro', 'dataHoraRegistro', 'irregularidadeCom', 'situacao', 'local', 'respostaOrgao', 'descricaoIrregularidade', /* 'foto', */ 'idCacamba', 'shape'],
                include: JSON.stringify(
                  [
                    {
                      model: 'Usuario',
                      as: 'usuario',
                      attributes: ['id', 'nome', 'idPessoa'],
                      required: true,
                      where: {
                        ...(isCidadao ? {
                          idPessoa: this.userData.pessoa.id
                        } : {}),
                        ...userFilter
                      }
                    },
                    {
                      model: 'Cacamba',
                      as: 'cacamba',
                      attributes: ['id', 'idPerfil', 'numeroIdentificacao'],
                      include: {
                        model: 'Perfil',
                        as: 'perfil'
                      }
                    },
                    {
                      model: 'Veiculo',
                      as: 'veiculo',
                      attributes: ['placa', 'numeroIdentificacao'],
                      include: [{
                        model: 'Perfil',
                        as: 'perfil',
                        attributes: ['id', 'orgao']
                      }]
                    }
                  ]
                )
              }
            }))
          }
        })
      }
    }
  },
  computed: {
    ...mapGetters('Login', { userData: 'userData' })
  },
  methods: {
    /**
     * Metodo de callback de quando e clicado no botao de acao da solicitacao,
     * com o objetivo de abrir a popup de acao da solicitacao.
     * @param {string} action - A acao que sera feita na solicitacao, 'view'
     * para visualizar a solicitacao, 'update' para atualizar a solicitacao, ou
     * 'cancel' para solicitar o cancelamento da solicitacao.
     * @param {Object} solicitacao - A solicitacao que foi clicada.
     */
    async onSoliticaoActionClick (action, ocorrencia) {
      if (action === 'view') {
        const fotoOcorrencia = await getFotoOcorrencia(ocorrencia)
        if (Array.isArray(fotoOcorrencia.data) && fotoOcorrencia.data.length) {
          ocorrencia.foto = fotoOcorrencia.data[0].foto
        }
        this.currentRowData = ocorrencia
        this.showRespostaOcorrencia = true
      }
    },

    /**
     * Metodo de comando, com o objetivo de abrir a tela de mapa de localizar
     * cacambas.
     */
    openCacambasMap () {
      this.$router.push({ path: '/registrar-ocorrencia/map' })
    }
  }
}
</script>

<style lang="scss">
@import '../../../../global.scss';

.voltarButton {
  position: relative;
  top: 5px;
  @media only screen and (max-width: 1648px) {
    position: relative;
    top: 5px;
  }
}
</style>
