import { getParamsFromLoadOptions } from '../../../util/loadOptionsParams'
import { doRequest } from '../../../pluggables/http'
import GetSolicitacaoToAvaliarDto from './GetSolicitacaoToAvaliarDto'

/**
 * Metodo de comando, com o objetivo de realizar a requisica de obter uma lista
 * de transportadores relacionados as solicitacoes a receber.
 * @param {Object} params - Os parametros da requisicao.
 * @param {Object} params.loadOptions - Os parametros de busca do
 * @param {string[]} params.fields - Os campos que vao ser retornados para
 * apresentar os valores.
 * datasource do devextreme.
 * @param {Object=} includeFields - Fields para filtro dos includes
 * @param {Boolean=} includePerfil - Se faz o include de pessoa ou nao
 * @param {Boolean=} includeSolicitacaoTransporte - Se faz o include de solicitação transporte ou nao
 * @param {Boolean=} includeDestinoFinal - Se faz o include de destino final transporte ou nao
 * @returns {Object} O resultado da requisicao.
 */
export async function getTransportadoresSolicitacoesReceber ({ loadOptions, fields }, includeFields = {}, includePerfil = true, includeSolicitacaoTransporte = true, includeDestinoFinal = true) {
  const params = getParamsFromLoadOptions(loadOptions)
  params.where = {
    ...params.where,
    '$perfis->solicitacoesTransporte.situacao$': {
      in: ['Retirada', 'Destinada', 'Resíduo Rejeitado']
    }
  }

  const perfil = {
    model: 'Perfil',
    as: 'perfis',
    attributes: fields.perfil,
    required: true,
    include: includeSolicitacaoTransporte ? {
      model: 'SolicitacaoTransporte',
      as: 'solicitacoesTransporte',
      attributes: fields.solicitacoesTransporte || [],
      required: true,
      include: includeDestinoFinal ? {
        model: 'Perfil',
        as: 'destinoFinal',
        attributes: fields.destinoFinal || []
      } : undefined
    } : []
  }
  const includes = []
  if (includePerfil) includes.push(perfil)
  const res = await doRequest({
    method: 'post',
    url: 'Pessoa/find',
    params: {
      limit: params.limit,
      offset: params.offset,
      order: params.order,
      attributes: fields,
      where: params.where,
      include: includes
    }
  })

  if (!res.success) {
    throw new Error(res.error)
  }

  return { data: res.data.rows, totalCount: res.data.count }
}

/**
 * Metodo de comando, com o objetivo de realizar a requisica de obter uma lista
 * de residuos predominantes relacionados as solicitacoes a receber.
 * @param {Object} params - Os parametros da requisicao.
 * @param {Object} [params.loadOptions=null] - Os parametros de busca do
 * datasource do devextreme.
 * @param {Object=} fields - Fields para filtro dos includes
 * @param {Boolean=} includeSolicitacaoTransporte - Se faz o include de solicitação transporte ou nao
 * @param {Boolean=} includeDestinoFinal - Se faz o include de destino final transporte ou nao
 * @returns {Object} O resultado da requisicao.
 */
export async function getResiduosPredominantesSolicitacao ({ loadOptions }, fields = {}, includeSolicitacaoTransporte = true, includeDestinoFinal = true) {
  const params = getParamsFromLoadOptions(loadOptions)
  params.where = {
    ...params.where,
    '$solicitacoesTransporte.situacao$': {
      in: ['Retirada', 'Destinada', 'Resíduo Rejeitado']
    }
  }
  const solicitacaoTransporte = {
    model: 'SolicitacaoTransporte',
    as: 'solicitacoesTransporte',
    attributes: fields.solicitacoesTransporte || [],
    required: true,
    include: includeDestinoFinal ? {
      model: 'Perfil',
      as: 'destinoFinal',
      attributes: fields.destinoFinal || []
    } : undefined
  }
  const includes = []
  if (includeSolicitacaoTransporte) includes.push(solicitacaoTransporte)

  const res = await doRequest({
    method: 'post',
    url: 'TipoResiduo/find',
    params: {
      ...params,
      fields: ['id', 'nome'],
      where: params.where,
      include: includes
    }
  })

  if (!res.success) {
    throw new Error(res.error)
  }

  return { data: res.data.rows, totalCount: res.data.count }
}

/**
 * Metodo de comando, com o objetivo de realizar a requisica de obter uma lista
 * de cacambas relacionadas as solicitacoes a receber.
 * @param {Object} params - Os parametros da requisicao.
 * @param {Object=} [params.loadOptions=null] - Os parametros de busca do
 * datasource do devextreme.
 * @param {Object=} fields - Fields para filtro dos includes
 * @param {Boolean=} includeSolicitacaoTransporte - Se faz o include de solicitação transporte ou nao
 * @param {Boolean=} includeDestinoFinal - Se faz o include de destino final transporte ou nao
 * @returns {Object} O resultado da requisicao.
 */
export async function getCacambasSolicitacao ({ loadOptions }, fields = {}, includeSolicitacaoTransporte = true, includeDestinoFinal = true) {
  const params = getParamsFromLoadOptions(loadOptions)
  params.where = {
    ...params.where,
    '$solicitacoesTransporte.situacao$': {
      in: ['Retirada', 'Destinada', 'Resíduo Rejeitado']
    }
  }
  const solicitacaoTransporte = {
    model: 'SolicitacaoTransporte',
    as: 'solicitacoesTransporte',
    attributes: fields.solicitacoesTransporte || [],
    required: true,
    include: includeDestinoFinal ? {
      model: 'Perfil',
      as: 'destinoFinal',
      attributes: fields.destinoFinal || []
    } : undefined
  }
  const includes = []
  if (includeSolicitacaoTransporte) includes.push(solicitacaoTransporte)

  const promises = [
    doRequest({
      method: 'post',
      url: 'Cacamba/find',
      params: {
        ...params,
        fields: ['id', 'numeroIdentificacao'],
        where: params.where,
        include: includes
      }
    }),
    doRequest({
      method: 'post',
      url: 'Veiculo/find',
      params: {
        ...params,
        fields: ['id', 'numeroIdentificacao'],
        where: params.where,
        include: includes
      }
    })
  ]

  try {
    const promisesResult = await Promise.all(promises)
    return { data: promisesResult[0].data.rows.concat(promisesResult[1].data.rows), totalCount: promisesResult[0].data.count + promisesResult[1].data.count }
  } catch (error) {
    console.error(error)
    throw new Error(error)
  }
}

/**
 * Metodo de comando, com o objetivo de realizar a requisica de obter uma lista
 * de veiculos relacionados as solicitacoes a receber.
 * @param {Object} params - Os parametros da requisicao.
 * @param {Object} [params.where={}] - Os parametros de where da busca.
 * @param {Object} [params.loadOptions=null] - Os parametros de busca do
 * datasource do devextreme.
 * @param {Object=} fields - Fields para filtro dos includes
 * @param {Boolean=} includeSolicitacaoTransporte - Se faz o include de solicitação transporte ou nao
 * @param {Boolean=} includeDestinoFinal - Se faz o include de destino final transporte ou nao
 * @returns {Object} O resultado da requisicao.
 */
export async function getVeiculosSolicitacao ({ loadOptions }, fields = {}, includeSolicitacaoTransporte = true, includeDestinoFinal = true) {
  const params = getParamsFromLoadOptions(loadOptions)
  params.where = {
    ...params.where,
    '$solicitacoesTransporte.situacao$': {
      in: ['Retirada', 'Destinada', 'Resíduo Rejeitado']
    }
  }

  const solicitacaoTransporte = {
    model: 'SolicitacaoTransporte',
    as: 'solicitacoesTransporte',
    attributes: fields.solicitacoesTransporte || [],
    required: true,
    include: includeDestinoFinal ? {
      model: 'Perfil',
      as: 'destinoFinal',
      attributes: fields.destinoFinal || []
    } : undefined
  }
  const includes = []
  if (includeSolicitacaoTransporte) includes.push(solicitacaoTransporte)

  const res = await doRequest({
    method: 'post',
    url: 'Veiculo/find',
    params: {
      ...params,
      fields: ['id', 'placa'],
      where: params.where,
      include: includes
    }
  })

  if (!res.success) {
    throw new Error(res.error)
  }

  return { data: res.data.rows, totalCount: res.data.count }
}

/**
 * Metodo de comando, com o objetivo de realizar a requisica de obter uma lista
 * de solicitacoes a receber.
 * @param {Object} loadOptions - Os parametros de busca do
 * datasource do devextreme.
 * @returns {Object} O resultado da requisicao.
 */
export async function getSolicitacoesAvaliarRecebimento (loadOptions) {
  const params = getParamsFromLoadOptions(loadOptions)
  params.include = [
    {
      model: 'Perfil',
      as: 'transportador',
      attributes: ['id'],
      duplicating: false,
      include: {
        model: 'Pessoa',
        as: 'pessoa',
        attributes: ['nomeRazaoSocial', 'cpfCnpj'],
        duplicating: false
      }
    },
    {
      model: 'TipoResiduo',
      as: 'residuoPredominante',
      attributes: ['nome']
    },
    {
      model: 'Cacamba',
      as: 'cacamba',
      attributes: ['numeroIdentificacao']
    },
    {
      model: 'Veiculo',
      as: 'veiculo',
      attributes: ['placa', 'numeroIdentificacao']
    },
    {
      model: 'Perfil',
      as: 'destinoFinal',
      attributes: ['id'],
      include: {
        model: 'Pessoa',
        as: 'pessoa',
        attributes: ['id', 'nomeRazaoSocial', 'cpfCnpj']
      }
    }
  ]
  params.fields = ['id', 'dataHoraSolicitacao', 'situacao']
  params.subQuery = false

  const res = await doRequest({
    method: 'post',
    url: 'SolicitacaoTransporte/find',
    params
  })

  if (!res.success) {
    throw new Error(res.error)
  }

  return { data: res.data.rows, totalCount: res.data.count }
}

/**
 * Metodo de comando, com o objetivo de realizar a requisica de obter os dados
 * de uma solicitacao.
 * @param {number} id - O id da solicitacao.
 * @returns {Object} O resultado da requisicao.
 */
export async function getSolicitacaoById (id) {
  const res = await doRequest({
    method: 'post',
    url: 'SolicitacaoTransporte/find',
    params: {
      where: {
        id: id
      },
      attributes: ['id', 'dataHoraSolicitacao', 'observacao', 'idVeiculo', 'idCacamba'],
      include: [
        {
          model: 'Veiculo',
          as: 'veiculo',
          attributes: ['placa', 'tipo', 'poliguindaste', 'numeroIdentificacao']
        },
        {
          model: 'Cacamba',
          as: 'cacamba',
          attributes: ['numeroIdentificacao', 'capacidadeM3']
        },
        {
          model: 'Residuo',
          as: 'residuos',
          attributes: ['volumeAproximadoM3', 'idTipoResiduo'],
          required: false,
          include: {
            model: 'TipoResiduo',
            as: 'tipoResiduo',
            attributes: ['id', 'nome'],
            duplicating: false,
            include: {
              model: 'ClasseMaterial',
              as: 'classeMaterial',
              attributes: ['classe'],
              duplicating: false,
              include: {
                model: 'TipoResiduo',
                as: 'tipoResiduos',
                attributes: ['nome'],
                duplicating: false
              }
            }
          }
        },
        {
          model: 'TipoResiduo',
          as: 'residuoPredominante',
          attributes: ['nome']
        },
        {
          model: 'Obra',
          as: 'obra',
          attributes: ['id', 'endereco', 'cep'],
          include: {
            model: 'Perfil',
            as: 'gerador',
            attributes: ['id'],
            include: {
              model: 'Pessoa',
              as: 'pessoa',
              attributes: ['nomeRazaoSocial', 'cpfCnpj']
            }
          }
        },
        {
          model: 'Perfil',
          as: 'transportador',
          attributes: ['id'],
          include: {
            model: 'Pessoa',
            as: 'pessoa',
            attributes: ['nomeRazaoSocial', 'cpfCnpj']
          }
        }
      ]
    }
  })

  return {
    data: res.success
      ? Array.isArray(res.data.rows) && res.data.rows.length
        ? new GetSolicitacaoToAvaliarDto(res.data.rows[0])
        : null
      : null,
    success: res.success,
    error: res.error
  }
}

/**
 * Metodo de comando, com o objetivo de realizar a requisica de avaliar os dados
 * de recebimento de uma solicitacao.
 * @param {Object} params - Os parametros da requisicao.
 * @param {Object} params.solicitacao - Os dados da solicitacao.
 * @param {boolean} params.avaliacao - A avaliacao do recebimento.
 * @returns {Object} O resultado da requisicao.
 */
export function avaliarRecebimento ({ solicitacao, avaliacao }) {
  return doRequest({
    method: 'put',
    url: `RecebimentoResiduo/${solicitacao.id}`,
    params: {
      observacao: solicitacao.observacao,
      avaliacao
    }
  })
}
