export const documentosEnum = {
  J: {
    24: 'TERMO DE ADESÃO DE PRESTAÇÃO DE SERVIÇOS ESPECIAIS DE GERENCIAMENTO DE RESÍDUOS DA CONSTRUÇÃO CIVIL',
    6: 'CPF DO RESPONSÁVEL PELO CADASTRO',
    3: 'CADASTRO NACIONAL DE PESSOA JURÍDICA (CNPJ)',
    16: 'FORMULÁRIO DE RELAÇÃO DOS VEÍCULOS',
    15: 'FORMULÁRIO DE RELAÇÃO DOS EQUIPAMENTOS REMOVÍVEIS (CAÇAMBAS)',
    10: 'DECLARAÇÃO DO CONHECIMENTO DA LEGISLAÇÃO AMBIENTAL',
    11: 'DOCUMENTO COM A IDENTIFICAÇÃO DOS SÓCIOS',
    20: 'NÚMERO DO CADASTRO FISCAL DO DF',
    14: 'DOCUMENTO SIMPLIFICADO DE ORIENTAÇÕES AO USUÁRIO DE SEUS EQUIPAMENTOS',
    18: 'LICENCIAMENTO AMBIENTAL (RLE)',
    8: 'DECLARAÇÃO CAPACIDADE TÉCNICA VEÍCULOS',
    25: 'CERTIDÃO NEGATIVA DO DF',
    26: 'CERTIDÃO NEGATIVA DA RECEITA FEDERAL'
  },
  F: {
    24: 'TERMO DE ADESÃO DE PRESTAÇÃO DE SERVIÇOS ESPECIAIS DE GERENCIAMENTO DE RESÍDUOS DA CONSTRUÇÃO CIVIL',
    19: 'NÚMERO DE CADASTRO DE PESSOA FÍSICA (CPF)',
    16: 'FORMULÁRIO DE RELAÇÃO DOS VEÍCULOS',
    15: 'FORMULÁRIO DE RELAÇÃO DOS EQUIPAMENTOS REMOVÍVEIS (CAÇAMBAS)',
    10: 'DECLARAÇÃO DO CONHECIMENTO DA LEGISLAÇÃO AMBIENTAL',
    14: 'DOCUMENTO SIMPLIFICADO DE ORIENTAÇÕES AO USUÁRIO DE SEUS EQUIPAMENTOS',
    18: 'LICENCIAMENTO AMBIENTAL (RLE)',
    8: 'DECLARAÇÃO CAPACIDADE TÉCNICA VEÍCULOS',
    25: 'CERTIDÃO NEGATIVA DO DF',
    26: 'CERTIDÃO NEGATIVA DA RECEITA FEDERAL'
  }
}
