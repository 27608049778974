import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueMaterial from 'vue-material'
import utilsPlugin from './pluggables/utils'
import httpPLugin from './pluggables/http'

import 'vue-material/dist/vue-material.min.css'
import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.light.css'
// import 'devextreme/dist/css/dx.material.orange.light.compact.css'
import '@mdi/font/css/materialdesignicons.css'
import './global.scss'
import { loadMessages, locale } from 'devextreme/localization'
import { ptBR } from './locale/devextreme/pt-br'

loadMessages(ptBR)
locale('pt')

window.CustomError = class CustomError extends Error {}

Vue.config.productionTip = false
Vue.use(VueMaterial)
Vue.use(httpPLugin)
Vue.use(utilsPlugin)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
