import actions from './actions'
import mutations from './mutations'

export default {
  namespaced: true,
  actions,
  mutations,
  state: {
    isLoading: false,
    data: {
    },
    requestAddress: null
  }
}
