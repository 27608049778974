import { render, staticRenderFns } from "./ConsultaMoveResiduo.vue?vue&type=template&id=6b23036d&scoped=true&"
import script from "./ConsultaMoveResiduo.vue?vue&type=script&lang=js&"
export * from "./ConsultaMoveResiduo.vue?vue&type=script&lang=js&"
import style0 from "./ConsultaMoveResiduo.vue?vue&type=style&index=0&id=6b23036d&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b23036d",
  null
  
)

export default component.exports