<template>
  <div class="generic-crud">
    <div>
      <div class="md-layout">
        <div class="md-layout-item">
          <h2 class="crud-title">Consulta Empresa Ativa com CTR</h2>
        </div>
      </div>
      <simple-grid entity="Pessoa" popup-title="Pessoa" :grid-data="gridData" :deletable="false" :editable="false" :viewable="false"></simple-grid>
    </div>
  </div>
</template>
<script>
import { SimpleGrid } from '@/components'
import CustomStore from 'devextreme/data/custom_store'
import mixinCrud from '../mixin-crud'
import { mapActions } from 'vuex'
import { getParamsFromLoadOptions, parseRawSqlFilter } from '../../../util/loadOptionsParams'
import { getByKeys } from '../../../util/getByKeys'

export default {
  name: 'pessoa-crud',
  mixins: [
    mixinCrud
  ],
  components: {
    SimpleGrid
  },
  data () {
    return {
      entity: 'Pessoa',
      permissionName: 'Consultar Empresa Ativa com CTR',
      formData: null,
      gridData: {
        value: {
          visible: true
        },
        columns: this.parseColumns([
          {
            dataField: 'id',
            visible: false,
            caption: 'id'
          },
          {
            dataField: 'Pessoa.nome_razao_social',
            caption: 'Nome razão social',
            calculateDisplayValue: 'nome_razao_social',
            editorType: 'dxLookup',
            editorOptions: {
              dataSource: {
                store: new CustomStore({
                  load: (loadOptions) => {
                    return this.$utils.wrapRequestForGrid(this.find({
                      entity: 'ViewTransportador',
                      loadOptions,
                      params: {
                        fields: ['id', 'idPerfil', 'nomeRazaoSocial', 'situacaoValidacao'],
                        where: {
                          situacaoValidacao: 'A',
                          idTipoPerfil: '2'
                        }
                      }
                    }))
                  },
                  byKey: key => key || null
                }),
                sort: [
                  { selector: 'nomeRazaoSocial', asc: true }
                ],
                paginate: true,
                pageSize: 10
              },
              displayExpr: 'nomeRazaoSocial',
              valueExpr: 'nomeRazaoSocial'
            }
          },
          {
            dataField: 'Pessoa.cpf_cnpj',
            caption: 'cnpj',
            calculateDisplayValue: 'cpf_cnpj',
            editorType: 'dxLookup',
            editorOptions: {
              dataSource: {
                store: new CustomStore({
                  load: (loadOptions) => this.$utils.wrapRequestForGrid(this.find({
                    entity: 'ViewTransportador',
                    loadOptions
                  })),
                  byKey: key => key || null
                }),
                sort: [
                  { selector: 'cpfCnpj', asc: true }
                ],
                paginate: true,
                pageSize: 10
              },
              displayExpr: 'cpfCnpj',
              valueExpr: 'cpfCnpj'
            }
          },
          {
            dataField: 'tipoPerfil.descricao',
            calculateSortValue: 'descricao',
            caption: 'Perfil',
            calculateDisplayValue: (data) => {
              return data.descricao
            },
            editorType: 'dxLookup',
            editorOptions: {
              allowClearing: true,
              showClearButton: true,
              acceptCustomValue: true,
              dataSource: {
                store: new CustomStore({
                  key: 'id',
                  load: (loadOptions) => {
                    return this.$utils.wrapRequestForGrid(this.find({
                      entity: 'Pessoa',
                      loadOptions,
                      params: {
                        fields: ['id'],
                        where: {
                          situacao: 'Ativo',
                          tipoPessoa: 'J'
                        },
                        include: JSON.stringify([
                          {
                            model: 'Perfil',
                            as: 'perfis',
                            attributes: ['id'],
                            include: [
                              {
                                model: 'TipoPerfil',
                                as: 'tipoPerfil',
                                attributes: ['id', 'descricao']
                              }
                            ]
                          }
                        ])
                      }
                    }))
                  },
                  byKey: async key => {
                    const res = await getByKeys({
                      urlModel: 'Pessoa',
                      where: {
                        situacao: 'Ativo',
                        tipoPessoa: 'J',
                        '$perfis->tipoPerfil.descricao$': key
                      },
                      paramsIncludes: {
                        model: 'Perfil',
                        as: 'perfis',
                        attributes: ['id'],
                        include: [
                          {
                            model: 'TipoPerfil',
                            as: 'tipoPerfil',
                            attributes: ['id', 'descricao']
                          }
                        ]
                      }
                    })
                    return res.totalCount > 0 ? res.data[0] : null
                  }
                }),
                paginate: true,
                pageSize: 5
              },
              displayExpr: (perfis) => {
                if (perfis) {
                  return perfis[0].tipoPerfil.descricao
                }
              },
              valueExpr: (perfis) => {
                if (perfis) {
                  return perfis[0].tipoPerfil.descricao
                }
              }
            }
          },
          {
            dataField: 'ctr',
            caption: 'Quantidade de CTR\'s Gerados',
            allowFiltering: false
          }
        ]),
        dataSource: new CustomStore({
          key: 'id',
          load: (loadOptions) => {
            return this.$utils.wrapRequestForGrid(this.findCTRs(loadOptions))
          }
        })
      }
    }
  },

  methods: {
    /**
     * Metodo para obter o objeto com valores do grid
     * @param {Object} loadOptions - Os parametros de busca do datasource do
     * devextreme.
     * @returns {Object} Os parametros da requisicao.
     */
    async findCTRs (loadOptions) {
      const params = getParamsFromLoadOptions(loadOptions, null, false)
      if (Array.isArray(params.where) && params.where.length) {
        const fields = {
          nomeRazaoSocial: 'nome_razao_social'
        }
        params.where = parseRawSqlFilter(params.where, fields)
      }
      const result = await this.findCTR(params)

      return { data: result.rows, totalCount: result.count }
    },
    ...mapActions('Crud', ['findCTR'])
  }
}
</script>

<style scoped lang="scss">
</style>
