<template>
  <md-card v-if="visible" class="error-message-card">
    <md-card-content>
      <div style="display: flex; width: 100%; justify-content: space-between">
        <div style="margin-right: 10px;">
          {{ message }}
        </div>
        <md-button
          style="margin: 0;"
          class="md-primary md-icon" @click="onClickClose()">
          close
        </md-button>
      </div>
    </md-card-content>
  </md-card>
</template>
<script>
export default {
  name: 'message-error',
  props: {
    message: {
      type: String
    }
  },
  data () {
    return {
      visible: false
    }
  },
  watch: {
    message: {
      immediate: true,
      handler (message) {
        this.visible = !!message
      }
    },
    visible (visible) {
      if (!visible) {
        this.$emit('update:message', null)
      }
    }
  },
  methods: {
    onClickClose () {
      this.visible = false
      this.$emit('close', this.message)
    }
  }
}
</script>

<style lang="scss">
.error-message-card {
  background-color: #B00020 !important;
  color: white !important;
}
</style>
