<template>
  <div class="retirar-cacamba">
    <div>
      <popup-update-solicitacao
        :active.sync="popupUpdateVisible"
        :solicitacao="solicitacaoPopup"
        title="Alterar solicitação de transporte"
        show-only-cacamba
        @updated-solicitacao="onPopupUpdatedSolicitacao"/>

      <popup-cancel-solicitacao
        :active.sync="popupCancelVisible"
        :solicitacao="solicitacaoPopup"
        title="Cancelar solicitação de transporte"
        @updated-solicitacao="onPopupUpdatedSolicitacao"/>

      <div class="md-layout">
        <div class="md-layout-item">
          <h2 class="crud-title">Retirada da caçamba</h2>
        </div>
      </div>
      <simple-grid
        entity="SolicitacaoTransporte"
        :editable="false"
        :deletable="false"
        :grid-data="gridData"
        ref="simpleGrid"/>
    </div>
  </div>
</template>
<script>
import { SimpleGrid } from '@/components'
import AppCustomStore from '@/base/AppCustomStore'
import config from 'config'

import {
  getSolicitacoesTransporteComCacamba,
  getCacambasSolicitacao,
  getVeiculosSolicitacao
} from '../service'
import mixinCrud from '../../mixin-crud'
import PopupUpdateSolicitacao from '@/components/SolicitacaoTransporte/PopupUpdateSolicitacao'
import PopupCancelSolicitacao from '@/components/SolicitacaoTransporte/PopupCancelSolicitacao'
import { hasPermission } from '@/util/permissions'
import { doRequest } from '@/pluggables/http'
import storeHelper from '@/helper/storeHelper'
import { confirm } from 'devextreme/ui/dialog'
import { visibleEditButton } from '@/helper/general'

const CustomStore = AppCustomStore

export default {
  name: 'list-solicitacoes-com-cacamba',
  components: {
    SimpleGrid,
    PopupUpdateSolicitacao,
    PopupCancelSolicitacao
  },
  mixins: [mixinCrud],
  /**
   * Metodo do vue, para obter o estado inicial do componente.
   * @returns {Object} O estado inicial do componente.
   */
  data () {
    return {
      gridData: {
        value: {
          visible: true
        },
        columns: this.parseColumns([
          {
            dataField: 'id',
            caption: 'Nº do CTR',
            editorType: 'dxNumberBox'
          },
          {
            dataField: '$obra.nome_obra$',
            calculateDisplayValue: 'obra.nomeObra',
            calculateSortValue: 'obra.nomeObra',
            caption: 'Nome da obra',
            dataType: 'string'
          },
          {
            dataField: '$obra.endereco$',
            calculateDisplayValue: 'obra.endereco',
            calculateSortValue: 'obra.endereco',
            caption: 'Endereço',
            dataType: 'string'
          },
          {
            dataField: '$residuoPredominante.classeMaterial.id$',
            calculateDisplayValue (data) {
              return `${data.residuoPredominante.classeMaterial.classe} (${data.residuoPredominante.nome})`
            },
            calculateFilterExpression: (classeMaterial, operation) => {
              return ['$residuoPredominante->classeMaterial.id$', operation, classeMaterial]
            },
            xlsxObjPath: '{residuoPredominante.classeMaterial.classe}({residuoPredominante.nome})',
            caption: 'Classe de Resíduo / Tipo de Resíduo Predominante',
            editorType: 'dxLookup',
            editorOptions: {
              allowClearing: true,
              showClearButton: true,
              showCancelButton: false,
              displayExpr: (data) => {
                if (data && data.classeMaterial) return `${data.classeMaterial.classe} (${data.nome})`
                else return ''
              },
              valueExpr: 'classeMaterial.id',
              dataSource: {
                key: 'id',
                store: new CustomStore({
                  load: async () => {
                    this.showProgressBarLoading = true
                    const result = await doRequest({
                      method: 'post',
                      url: 'TipoResiduo/find',
                      params: {
                        include: [
                          {
                            model: 'ClasseMaterial',
                            as: 'classeMaterial',
                            required: true,
                            attributes: ['id', 'classe']
                          }
                        ],
                        subQuery: false
                      }
                    })
                    this.showProgressBarLoading = false
                    return result.data.rows
                  },
                  byKey: key => key
                }),
                paginate: true,
                pageSize: 10,
                reshapeOnPush: true
              }
            }
          },
          {
            dataField: '$cacamba.id$',
            calculateDisplayValue: (data) => {
              return (data.cacamba && data.cacamba.numeroIdentificacao) ? data.cacamba.numeroIdentificacao : (data.veiculo ? data.veiculo.numeroIdentificacao : '')
            },
            calculateFilterExpression: (transportador, operation) => {
              return [['$cacamba.id$', '=', transportador], 'or', ['$veiculo.id$', '=', transportador]]
            },
            calculateSortValue: 'idCacamba',
            caption: 'Nº de Identificação da Caçamba',
            editorType: 'dxLookup',
            editorOptions: {
              allowClearing: true,
              showClearButton: true,
              showCancelButton: false,
              dataSource: {
                store: new CustomStore({
                  key: 'id',
                  load: loadOptions => getCacambasSolicitacao({ loadOptions }),
                  byKey: async key => {
                    const res = await getCacambasSolicitacao({ where: { id: key } })
                    return res.totalCount > 0 ? res.data[0] : null
                  }
                }),
                paginate: true,
                pageSize: 10
              },
              displayExpr: 'numeroIdentificacao',
              valueExpr: 'id'
            }
          },
          {
            dataField: '$veiculo.id$',
            calculateDisplayValue: 'veiculo.placa',
            calculateSortValue: 'idVeiculo',
            caption: 'Veículo (Placa)',
            editorType: 'dxLookup',
            editorOptions: {
              allowClearing: true,
              showClearButton: true,
              showCancelButton: false,
              dataSource: new CustomStore({
                key: 'id',
                load: loadOptions => getVeiculosSolicitacao({ loadOptions }),
                byKey: async key => {
                  const res = await getVeiculosSolicitacao({ where: { id: key } })
                  return res.totalCount > 0 ? res.data[0] : null
                }
              }),
              displayExpr: 'placa',
              valueExpr: 'id'
            }
          },
          {
            dataField: 'dataHoraSolicitacao',
            caption: 'Data/Hora da Solicitação',
            format: 'dd/MM/yyyy - HH:mm',
            dataType: 'date',
            editorType: 'dxDateBox'
          },
          {
            dataField: 'situacao',
            caption: 'Situação',
            editorType: 'dxLookup',
            filterValue: 'Alocada',
            editorOptions: {
              allowClearing: true,
              dataSource: this.$utils.getDistinctSituacoes()
            }
          },
          {
            type: 'buttons',
            showInColumnChooser: false,
            buttons: [
              {
                cssClass: 'dx-button dx-button-primary mdi mdi-file-document',
                text: 'CTR',
                onClick: ({ row: { data } }) => {
                  this.onSoliticaoActionClick('view', data)
                }
              },
              ...(hasPermission('Editar CTR', 'altera') ? [{
                cssClass: 'dx-button dx-button-success dx-button-mode-contained mdi mdi-pencil',
                text: 'EDITAR',
                onClick: async ({ row: { data } }) => {
                  const isAdmOrSecretaria = await storeHelper.getCurrentPerfis().some(perfil => perfil.idTipoPerfil === 10 || perfil.idTipoPerfil === 4)
                  const confirmed = isAdmOrSecretaria && isAdmOrSecretaria.length ? true : await confirm(`Este CTR só poderá ser modificado 1 (uma) vez por meio do seu cadastro.
                    <br>Caso efetue quaisquer alteração de forma errônea, informe o número do CTR por meio do dúvidas ou sugestões, <br> qual dado deverá ser modificado que os servidores do SLU efetuarão a modificação por mais 1 (uma) vez.
                    <br> Deseja Continuar?`, 'Atenção')
                  if (confirmed) {
                    await this.$router.push(`/editar-solicitacao-transporte/${data.id}`)
                  }
                },
                visible ({ row: { data } }) {
                  return visibleEditButton(data)
                }
              }] : []),
              ...(hasPermission('Retirar Caçamba', 'altera') ? [{
                cssClass: 'dx-button dx-button-primary mdi mdi-bucket-outline',
                text: 'RETIRAR',
                onClick: ({ row: { data } }) => {
                  this.onSoliticaoActionClick('remove', data)
                },
                visible ({ row: { data } }) {
                  return data.situacao === 'Alocada'
                }
              }] : []),
              ...(hasPermission('Solicitar Cancelamento de CTR', 'altera') ? [{
                cssClass: 'dx-button dx-button-primary mdi mdi-close-thick',
                text: 'CANCELAR',
                onClick: ({ row: { data } }) => {
                  this.onSoliticaoActionClick('cancel', data)
                },
                visible ({ row: { data } }) {
                  return ['Alocada', 'Enviada', 'Solicitada'].includes(data.situacao)
                }
              }] : []),
              {
                cssClass: 'dx-button dx-button-primary mdi mdi-map',
                text: 'MAPA',
                onClick: ({ row: { data } }) => {
                  this.onSoliticaoActionClick('map', data)
                }
              }
            ]
          }
        ]),
        dataSource: {
          store: new CustomStore({
            key: 'id',
            load: async loadOptions => getSolicitacoesTransporteComCacamba(loadOptions)
          })
        }
      },
      popupUpdateVisible: false,
      popupCancelVisible: false,
      solicitacaoPopup: null
    }
  },
  methods: {
    /**
     * Metodo de callback de quando e clicado no botao de acao da solicitacao,
     * com o objetivo de abrir a popup de acao da solicitacao.
     * @param {string} action - A acao que sera feita na solicitacao, 'view'
     * para visualizar a solicitacao, 'update' para atualizar a solicitacao, ou
     * 'cancel' para solicitar o cancelamento da solicitacao.
     * @param {Object} solicitacao - A solicitacao que foi clicada.
     */
    onSoliticaoActionClick (action, solicitacao) {
      switch (action) {
        case 'view':
          window.open(`${config.baseUrl}/FichaCTR/${solicitacao.id}`, '_blank')
          break
        case 'update':
          this.popupUpdateVisible = true
          this.solicitacaoPopup = solicitacao
          break
        case 'remove':
          this.$router.push({ path: `/retirar-cacamba/retirar/${solicitacao.id}` })
          break
        case 'cancel':
          this.popupCancelVisible = true
          this.solicitacaoPopup = solicitacao
          break
        case 'map':
          this.$router.push({
            name: 'obra-cacamba-map',
            params: { ids: [solicitacao.idObra] }
          })
          break
      }
    },

    /**
     * Metodo de callback de quando um popup altera valores nas solicitacoes.
     * Com o objetivo de recarregar a lista de solicitacoes.
     */
    onPopupUpdatedSolicitacao () {
      this.$refs.simpleGrid.$refs.gridInstance.instance.getDataSource().reload()
    },

    /**
     * Metodo para obter o atribute 'title' da celula de situacao da solicitacao.
     * @param {Object} solicitacao - A solicitacao que se deseja obter o title
     * da situacao.
     * @returns {string|undefined} O title da situacao atual.
     */
    _getTitleSituacao ({ situacao, motivoSolCancelamento, motivoRecSolCancelamento, motivoRecSolicitacao }) {
      switch (situacao) {
        case 'Cancelamento Solicitado':
          return motivoSolCancelamento
        case 'Cancelamento Recusado':
          return motivoRecSolCancelamento
        case 'Resíduo Rejeitado':
          return motivoRecSolicitacao
        default:
          return ''
      }
    }
  }
}
</script>

<style lang="scss">
@import '../../../../global.scss';

.solicitacao-decline-popup-content {
  display: flex;
  flex-direction: column;

  .solicitacao-decline-popup-buttons {
    display: flex;
    flex-direction: row-reverse;
  }

  > :not(.solicitacao-decline-popup-buttons) {
    flex: 1;
  }

  .error-message-card {
    background-color: #B00020 !important;
    color: white !important;
    width: 100%;
    margin-top: 6px;
    margin-bottom: 16px;
  }
}

.solicitacao-accept-popup-content {
  display: flex;
  flex-direction: column;

  > .solicitacao-accept-popup-form {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 16px;
  }

  > .solicitacao-accept-popup-buttons {
    display: flex;
    flex-direction: row-reverse;
  }

  .error-message-card {
    background-color: #B00020 !important;
    color: white !important;
    width: 100%;
    margin-top: 6px;
    margin-bottom: 16px;
  }
}
</style>
