<template>
  <div class="dx-field width-100">
    <div class="dx-field-label-location-top">{{ label }}</div>
    <div class="dx-field-content">
      <dx-autocomplete
        v-bind="innerOptions"
        :value="inputValue"
        ref="autocomplete"
        @selection-changed="onSelectionChanged"
        @key-down="onKeyDown"/>
    </div>
  </div>
</template>

<script>
import DxAutocomplete from 'devextreme-vue/autocomplete'
import CustomStore from 'devextreme/data/custom_store'

import { http } from '@/pluggables/http'
import { getParamsFromLoadOptions } from '../../util/loadOptionsParams'
import config from 'config'

export default {
  name: 'model-select',
  components: {
    DxAutocomplete
  },
  props: {
    /**
     * A label do campo de select.
     */
    label: {
      type: String,
      required: true
    },
    /**
     * A entidade que tera os valores listados.
     */
    entity: {
      type: String
    },
    /**
     * Metodo para realizar a requisicao de obter a lista de objetos da entidade.
     * Essa funcao deve retornar a lista de objetos.
     */
    getListRequest: {
      type: Function,
      async default (params) {
        const result = await http.post(`${config.baseUrl}/${this.entity}/find`, params)
        if (!result.data.success) {
          throw new Error(`Falha no retorno da requisicao de get ${this.entity}`)
        } else {
          return {
            data: result.data.data.rows || [],
            totalCount: result.data.data.count
          }
        }
      }
    },
    /**
     * O valor selecionado do campo.
     */
    value: {
      type: [Object, String, Number]
    },
    /**
     * A propriedade dos objetos que sera utilizada para a label no select.
     */
    labelProp: {
      type: String,
      default: 'label'
    },
    /**
     * O nome do campo.
     */
    name: {
      type: String,
      default () {
        return this.label
      }
    },
    /**
     * Se a lista de valores deve ser agrupada. Caso for true, a lista de
     * valores deve estar no formato
     * '[{ key: '<nome do grupo>', items: [<a lista de itens do grupo>] }]'.
     */
    grouped: {
      type: Boolean,
      default: false
    },
    /**
     * As opcoes do autocomplete do dev-extreme.
     */
    options: {
      type: Object,
      default: () => ({})
    }
  },
  /**
   * Metodo vue para obter o estado inicial do componente.
   * @returns {Object} O estado inicial do componente.
   */
  data () {
    return {
      datasource: new CustomStore({
        key: this.labelProp,
        load: async (loadOptions) => {
          const result = await this.$utils.wrapRequestForGrid(this.getListRequest(getParamsFromLoadOptions(loadOptions)))
          if (this.value && !result.data.find(data => data.id === this.value.id)) {
            result.data = [...result.data, this.value] // Adiciona o propio elemento ao array de opções
          }
          return result
        },
        byKey: async key => {
          try {
            if (this.innerValue && this.innerValue[this.labelProp] === key) {
              return this.innerValue
            } else {
              const res = await this.getListRequest({ where: { [this.labelProp]: key } })
              return res.data[0] || null
            }
          } catch (e) {
            console.error(e)
          }
        }
      })
    }
  },
  computed: {
    /**
     * Valor computado do valor do campo para o dev-extreme.
     */
    innerValue: {
      get () {
        return this.value
      },
      set (value) {
        if (typeof value === 'object') {
          this.$emit('input', value)
        } else if (!value) {
          this.$emit('input', null)
        }
      }
    },

    /**
     * Valor computado do campo de input de autocomplete.
     * @returns {string}
     */
    inputValue () {
      return this.innerValue && typeof this.innerValue === 'object'
        ? this.innerValue[this.labelProp]
        : this.innerValue ? String(this.innerValue) : ''
    },

    /**
     * Valor computado das options do dev-extreme do campo de autocomplete.
     * @returns {Object}
     */
    innerOptions () {
      return {
        allowClearing: false,
        showClearButton: false,
        dataSource: this.datasource,
        disabled: false,
        displayExpr: this.labelProp,
        grouped: this.grouped,
        minSearchLength: 0,
        name: this.name,
        openOnFieldClick: true,
        valueExpr: this.labelProp,
        ...this.options
      }
    }
  },
  methods: {
    /**
     * Metodo de callback de quando e selecionado um item, com o objetivo de
     * atualizar o valor da prop 'value'.
     * @param {Object} e - Os dados do evento que ocorreu.
     * @param {Object} e.selectedItem - O item que foi selecionado.
     */
    onSelectionChanged (payload) {
      this.innerValue = payload.selectedItem
    },

    /**
     * Metodo de comando, com o objetivo de atualizar a lista de itens do select.
     */
    reload () {
      this.$refs.autocomplete.instance.getDataSource().reload()
    },

    /**
     * Metodo de comando, com o objetivo de fechar a lista de itens do select.
     */
    close () {
      this.$refs.autocomplete.instance.close()
    },

    /**
     * Metodo de callback de quando foi pessinada uma tecla no input, com o
     * objetivo de limpar o valor se foi apertado o backspace.
     * @param {Object} evt - Os dados do evento que ocorreu.
     * @param {Event} evt.event - O evento de keydown do navegador.
     */
    onKeyDown ({ event }) {
      if (event.keyCode === 8 && this.innerValue) {
        this.innerValue = null
      }
    }
  }
}
</script>

<style lang="scss">
.dx-field {
  font-family: Montserrat, sans-serif;
}
</style>
