import { render, staticRenderFns } from "./TipoResiduo.vue?vue&type=template&id=212a5ae0&scoped=true&"
import script from "./TipoResiduo.vue?vue&type=script&lang=js&"
export * from "./TipoResiduo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "212a5ae0",
  null
  
)

export default component.exports