import Reclamacoes from './Reclamacoes.vue'

export default {
  path: '/reclamacoes',
  name: 'reclamacoes',
  component: Reclamacoes,
  meta: {
    title: 'Dúvidas ou Sugestões'
  },
  props: true
}
