<template>
  <div class="dx-field width-100">
    <div v-if="label" class="dx-field-label-location-top">
      <span class="dx-field-item-label-text">{{ label }}</span>
      <span v-if="required" class="dx-field-item-required-mark">*</span>
    </div>
    <div class="dx-field-content">
      <dx-text-area
        v-bind="options"
        :value.sync="innerValue"/>
    </div>
  </div>
</template>

<script>
import DxTextArea from 'devextreme-vue/text-area'

import GenericField from './GenericField'

export default {
  name: 'text-area-field',
  components: {
    DxTextArea
  },
  extends: GenericField
}
</script>

<style lang="scss">
.dx-field {
  font-family: Montserrat, sans-serif;
}
</style>
