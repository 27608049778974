/**
 * Classe responsavel por estruturar os dados da tela de permissoes para a
 * requisicao de atribuicoes do perfil.
 */
export default class SaveAtribuicaoDto {
  constructor (permissionData, tipoPerfil) {
    this.permissionData = permissionData
    this.tipoPerfil = tipoPerfil
  }

  /**
   * Metodo para obter os dados de permissoes no formato de parametro de
   * requisicao.
   * @returns {Object} Os dados para a requisicao.
   */
  toRequestParams () {
    const permissionProps = [
      'consulta', 'inclui', 'altera',
      'exclui'
    ]

    return Object.values(this.permissionData)
      .reduce((acc, val) => {
        // Se tem pelo uma prop true
        const hasOnePropTrue = permissionProps.find(prop => val[prop])

        // Nao tem id, entao nao ta salvo no banco
        if (!('id' in val)) {
          hasOnePropTrue && acc.adds.push({
            consulta: val.consulta ? 'S' : 'N',
            inclui: val.inclui ? 'S' : 'N',
            altera: val.altera ? 'S' : 'N',
            exclui: val.exclui ? 'S' : 'N',
            idFuncionalidade: val.idFuncionalidade,
            idTipoPerfil: this.tipoPerfil.id
          })
        // Tem id, e tem pelo menos uma prop true, entao atualiza
        } else if (hasOnePropTrue) {
          acc.updates.push({
            id: val.id,
            consulta: val.consulta ? 'S' : 'N',
            inclui: val.inclui ? 'S' : 'N',
            altera: val.altera ? 'S' : 'N',
            exclui: val.exclui ? 'S' : 'N',
            idFuncionalidade: val.idFuncionalidade,
            idTipoPerfil: this.tipoPerfil.id
          })
        // Tem id e nao tem prop true, entao remove a row do banco
        } else {
          acc.removes.push(val.id)
        }

        return acc
      }, { adds: [], updates: [], removes: [] })
  }
}
