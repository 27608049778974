import CustomStore from 'devextreme/data/custom_store'
import { staticMethodsUtil } from '@/pluggables/utils'

export default class AppCustomStore extends CustomStore {
  constructor (...args) {
    super(...args)
    const [options] = args
    this._loadFunc = (...loadArgs) => {
      const fnLoad = (options || {}).load || (() => [])
      return staticMethodsUtil.wrapRequestForGrid(
        fnLoad(...loadArgs)
      )
    }
  }
}
