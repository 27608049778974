import ListSolicitacoesComCacamba from './List/ListSolicitacoesComCacamba.vue'
import FormRetirarCacamba from './Form/FormRetirarCacamba.vue'

export default [
  {
    path: '/retirar-cacamba',
    name: 'retirar-cacamba',
    component: ListSolicitacoesComCacamba,
    meta: {
      icon: 'mdi mdi-truck',
      title: 'Retirar Caçamba',
      permissionType: 'consulta'
    },
    props: true
  },
  {
    path: '/retirar-cacamba/retirar/:id',
    name: 'retirar-cacamba-retirar',
    component: FormRetirarCacamba,
    meta: {
      requiresAuth: true,
      title: 'Retirar Caçamba',
      permissionType: 'altera'
    }
  }
]
