<!-- eslint-disable vue/no-v-model-argument -->
<template>
  <div>
    <VuexWrapper ref="vuexWrapper" />
    <DxPopup v-bind="configMensagemPopup" :hide-on-outside-click="true" :drag-enabled="false" height="auto"
      :show-title="true" :visible.sync="viewMensagemPopup">
      <template v-slot:content>
        <DxHtmlEditor :read-only="true" v-model:value="configMensagemPopup.mensagem" height="380px" />
      </template>
    </DxPopup>
    <div v-if="props.action">
      <!-- ADD OR EDIT -->
      <div v-if="props.action === 'add' || 'edit'">
        <div class="form-header-wrapper">
          <div>
            <dx-button text="Voltar" type="success" @click="resetToHome" />
          </div>
        </div>
        <form @submit="saveForm">
          <DxForm :form-data.sync="messageData" :read-only="false" :show-colon-after-label="true"
            :show-validation-summary="false" validationGroup="mensagem">
            <DxGroupItem caption="Dados da mensagem" :col-count="1">
              <DxSimpleItem v-for="(field) in formItems" :key="field.dataField" v-bind="field" />
              <DxSimpleItem>
                <template #default>
                  <div id="htmlEditorWrapper">
                    <DxHtmlEditor v-model:value="messageData.mensagem" height="380px">
                      <DxMediaResizing :enabled="true" />
                      <DxImageUpload :tabs="['file']" file-upload-mode="base64" />
                      <DxToolbar :multiline="true">
                        <DxItem name="undo" />
                        <DxItem name="redo" />
                        <DxItem name="separator" />
                        <DxItem name="size" :accepted-values="sizeValues" />
                        <DxItem name="font" :accepted-values="fontValues" />
                        <DxItem name="separator" />
                        <DxItem name="bold" />
                        <DxItem name="italic" />
                        <DxItem name="strike" />
                        <DxItem name="underline" />
                        <DxItem name="separator" />
                        <DxItem name="alignLeft" />
                        <DxItem name="alignCenter" />
                        <DxItem name="alignRight" />
                        <DxItem name="alignJustify" />
                        <DxItem name="separator" />
                        <DxItem name="orderedList" />
                        <DxItem name="bulletList" />
                        <DxItem name="separator" />
                        <DxItem name="image" />
                      </DxToolbar>
                    </DxHtmlEditor>
                  </div>
                </template>
              </DxSimpleItem>

              <DxButtonItem :button-options="{
                text: `${action === 'edit' ? 'Atualizar' : 'Criar'}`,
                type: 'success',
                useSubmitBehavior: true,
                icon: `${action === 'edit' ? 'mdi mdi-refresh' : 'mdi mdi-content-save'}`
              }" cssClass="salvar-pessoa-icon" horizontal-alignment="right" />
            </DxGroupItem>
          </DxForm>
        </form>
      </div>
    </div>
    <!-- GRID -->
    <div v-else>
      <DxButton type="success" @click="onAddRow()" text="Adicionar Nova" v-if="hasPermission('Manter Mensagens', 'inclui')"/>
      <SimpleGrid ref="simpleGridRef" :showMasterDetail="true" :editable="false" :viewable="false" :deletable="false"
        entity="Mensagens" :grid-data="gridData">
        <template #masterDetailSlot="{ formData }">
          <SimpleGrid ref="simpleGridMasterDetailRef" :deletable="false" :editable="false" entity="leituraMensagem"
            :grid-data="gridDataMasterDetailGenerator(formData.row.data)" />
        </template>
      </SimpleGrid>
    </div>
  </div>
</template>
<script setup>
import { hasPermission } from '@/util/permissions'
import { SimpleGrid } from '@/components'
import DxForm, { DxGroupItem, DxSimpleItem, DxButtonItem } from 'devextreme-vue/form'
import CustomStore from 'devextreme/data/custom_store'
import { staticMethodsUtil } from '@/pluggables/utils'
import { parseColumns } from '@/helper/grid'
import { ref } from 'vue'
import VuexWrapper from '@/helper/VuexWrapper'
import { DxButton } from 'devextreme-vue'
import {
  DxImageUpload,
  DxHtmlEditor,
  DxToolbar,
  DxMediaResizing,
  DxItem
} from 'devextreme-vue/html-editor'
import { doRequest } from '@/pluggables/http'
import { DxPopup } from 'devextreme-vue/popup'
import notify from 'devextreme/ui/notify'

const sizeValues = ref(['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'])

const fontValues = ref(['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana'])

const simpleGridRef = ref()

const perfisMensagem = ref([
  {
    id: 2, label: 'Transportador (Pessoa Júridica)'
  },
  {
    id: 9, label: 'Transportador (Pessoa Física)'
  }

])

const onAddRow = () => {
  messageData.value.ativa = 'S'
  messageData.value.idTipoPerfil = [2, 9]
  vuexWrapper.value.$router.push({ path: 'mensagens/add' })
}

const onEditRow = (row) => {
  for (const [key, value] of Object.entries(row)) {
    messageData.value[key] = value
  }
  vuexWrapper.value.$router.push('/mensagens/edit')
}

const resetToHome = () => {
  vuexWrapper.value.$router.push('/manter-mensagem')
  messageData.value = {}
}

const saveForm = async (e) => {
  e.preventDefault()
  vuexWrapper.value.setLoading(true)
  const action = props.action === 'add' ? 'adicionar' : 'editar'
  try {
    if (props.action === 'add') {
      await doRequest({
        method: 'post',
        url: 'mensagem',
        params: messageData.value
      })
    } else {
      await doRequest({
        method: 'put',
        url: `mensagem/${messageData.value.id}`,
        params: messageData.value
      })
    }
    notify(`Sucesso ao ${action} mensagem`, 'success')
    resetToHome()
  } catch (err) {
    notify(`Ocorreu um erro ao ${action} a mensagem`, 'warning')
  }
  vuexWrapper.value.setLoading(false)
}

const configMensagemPopup = ref({
})

const viewMensagemPopup = ref(false)

const messageData = ref({})

const props = defineProps({ action: { type: String, default: null } })

const vuexWrapper = ref()

const gridDataMasterDetailGenerator = (row) => {
  const data = {
    value: {
      visible: true
    },
    columns: parseColumns([
      {
        dataField: 'usuarioPerfil.usuario.pessoa.nomeRazaoSocial',
        caption: 'Empresa'
      },
      {
        dataField: 'usuarioPerfil.usuario.pessoa.cpfCnpj',
        caption: 'Cpf/Cnpj'
      },
      {
        dataField: 'usuarioPerfil.usuario.nome',
        caption: 'Pessoa que leu'
      },
      {
        dataField: 'usuarioPerfil.usuario.cpf',
        caption: 'Cpf'
      },
      {
        dataField: 'createdAt',
        calculateDisplayValue: (value) => {
          const date = value.createdAt
          return (!isNaN(new Date(date).getTime())
            ? new Date(date).toLocaleString('pt-br') : undefined).slice(0, -3)
        },
        caption: 'Lido em'
      }
    ]),
    dataSource: new CustomStore({
      key: 'id',
      load: (loadOptions) => staticMethodsUtil.wrapRequestForGrid(vuexWrapper.value.find({
        entity: 'leituraMensagem',
        params: {
          where: JSON.stringify({
            idMensagem: row.id
          }),
          include: JSON.stringify([
            {
              model: 'UsuarioPerfil',
              as: 'usuarioPerfil',
              required: true,
              attributes: ['id'],
              include: [
                {
                  model: 'Usuario',
                  as: 'usuario',
                  required: true,
                  attributes: ['id', 'nome', 'cpf'],
                  include: [{
                    model: 'Pessoa',
                    as: 'pessoa',
                    required: true,
                    attributes: ['id', 'nomeRazaoSocial', 'cpfCnpj']
                  }]
                }
              ]
            }
          ])
        },
        loadOptions
      }))
    }),
    onEditingStart: () => {
      this.isEditingData = true
    }
  }
  return data
}

gridDataMasterDetailGenerator()

const formItems = ref([
  {
    dataField: 'titulo',
    caption: 'Titulo',
    editorOptions: { maxLength: 100 }
  },
  {
    dataField: 'idTipoPerfil',
    label: { text: 'Tipos de perfis', location: 'left' },
    editorType: 'dxTagBox',
    validationRules: [{ type: 'required' }],
    editorOptions: {
      placeholder: 'Mostrar para',
      valueExpr: 'id',
      dataSource: perfisMensagem.value,
      displayExpr: 'label'
    }
  },
  {
    dataField: 'ativa',
    label: { text: 'Mensagem ativa', location: 'left' },
    editorType: 'dxSelectBox',
    validationRules: [{ type: 'required' }],
    editorOptions: {
      valueExpr: 'id',
      dataSource: [
        {
          id: 'S',
          label: 'Sim'
        },
        {
          id: 'N',
          label: 'Não'
        }
      ],
      displayExpr: 'label'
    }
  },
  {
    dataField: 'validacaoMensagem',
    label: { text: '', location: 'top' },
    editorType: 'dxSelectBox',
    cssClass: 'hidden',
    validationRules: [
      {
        type: 'async',
        reevaluate: true,
        validationCallback: () => new Promise(resolve => {
          const mensagemNotExists = !!messageData.value.mensagem
          if (!mensagemNotExists) document.querySelector('#htmlEditorWrapper').classList.add('flash')
          resolve(mensagemNotExists)
        }),
        message: 'É necessário inserir ao menos um usuário'
      }
    ],
    editorOptions: {
      items: []
    }
  }
])

const gridData = ref({
  value: {
    visible: true
  },
  columns: parseColumns([
    {
      dataField: 'titulo',
      caption: 'Título'
    },
    {
      dataField: 'createdAt',
      dataType: 'date',
      format: 'dd/MM/yyyy - HH:mm',
      caption: 'Criado em'
    },
    {
      dataField: 'totalLido',
      caption: 'Visualizadores'
    },
    {
      dataField: 'ativa',
      dataType: 'string',
      calculateDisplayValue: (data) => data.ativa === 'S' ? 'Sim' : 'Não',
      calculateSortValue: 'ativa',
      caption: 'Mensagem ativa'
    },
    {
      type: 'buttons',
      name: 'actions-column',
      showInColumnChooser: false,
      alignment: 'right',
      buttons: [
        {
          name: 'visualizar',
          text: 'Visualizar',
          cssClass: 'dx-button mdi mdi-book',
          onClick: async (result) => {
            configMensagemPopup.value.title = result.row.data.titulo
            configMensagemPopup.value.mensagem = result.row.data.mensagem
            viewMensagemPopup.value = true
          }
        },
        {
          name: 'custom-edit',
          text: 'Editar',
          cssClass: 'dx-button dx-button-success dx-button-mode-contained dx-widget dx-button-has-text mdi mdi-pencil',
          onClick: async (result) => {
            onEditRow(result.row.data)
          },
          visible: () => {
            return hasPermission('Manter Mensagens', 'altera')
          }
        }]
    }
  ]),
  dataSource: new CustomStore({
    key: 'id',
    load: (loadOptions) => staticMethodsUtil.wrapRequestForGrid(vuexWrapper.value.find({
      entity: 'mensagem',
      loadOptions: loadOptions.sort?.length > 1 ? {
        ...loadOptions,
        sort: [
          ...loadOptions.sort,
          {
            selector: 'ativa',
            desc: true
          },
          {
            selector: 'createdAt',
            desc: true
          }
        ]
      } : {
        ...loadOptions,
        sort: [
          {
            selector: 'ativa',
            desc: true
          },
          {
            selector: 'createdAt',
            desc: true
          }
        ]
      }
    }))
  })
})

</script>

<style scoped lang="scss">
.flash {
  border: solid red 1px !important;
  border-radius: 5px;
  transform: scale(1.01);
  transition: all 1s;
}

.hidden {
  display: none
}

.form-header-wrapper {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px
}
</style>
