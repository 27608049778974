
import { getParamsFromLoadOptions } from '../../../util/loadOptionsParams'
import { doRequest } from '../../../pluggables/http'

/**
 * Metodo de comando, com o objetivo de realizar a requisica de obter uma lista
 * de cacambas com o shape da cacamba.
 * @param {Object} params - Os parametros da requisicao.
 * @returns {Object} O resultado da requisicao.
 */
export function findCacambas ({ ids, shape }) {
  return doRequest({
    method: 'post',
    url: 'registrar-ocorrencia/find-cacambas',
    params: {
      shape
    }
  })
}
/**
 * Metodo de comando, com o objetivo de realizar a requisica de obter uma lista
 * de cacambas com o shape da cacamba.
 * @param {Object} params - Os parametros da requisicao.
 * @returns {Object} O resultado da requisicao.
 */
export function saveOcorrenciaCacamba ({ ids, shape }) {
  return doRequest({
    method: 'post',
    url: 'registrar-ocorrencia/find-cacambas',
    params: {
      shape
    }
  })
}

/**
 *
 *
 *
 *
 *
 * Metodo de comando, com o objetivo de realizar a requisica de obter uma lista
 * de veiculos com solicitacao de transporte que pode retirar a cacamba.
 * @param {Object} params - Os parametros da requisicao.
 * @param {Object} [params.loadOptions=null] - Os parametros de busca do
 * datasource do devextreme.
 * @param {string[]} params.fields - Os campos que vao ser retornados para
 * apresentar os valores.
 * @returns {Object} O resultado da requisicao.
 */
export async function getFotoOcorrencia (ocorrencia) {
  const params = getParamsFromLoadOptions()
  params.fields = ['foto']
  params.where = {
    ...params.where,
    id: ocorrencia.id
  }

  const res = await doRequest({
    method: 'post',
    url: 'Ocorrencia/find',
    params
  })

  return { data: res.data.rows, totalCount: res.data.count }
}
