<template>
  <div class="generic-crud">
    <div class="generic-crud-form">
      <fieldset>
        <legend>{{ !id ? 'Cadatrar' : 'Editar' }} Usuário</legend>
        <div class="form flex" v-if="formData">

          <div class="field">
            <b>Tipo Pessoa*:</b>
            <dx-select-box
              v-model="form.tipoPessoaStr"
              :items="[{ id: 'F', tipo: 'Pessoa Física' }, { id: 'J', tipo: 'Pessoa Jurídica' }]"
              value-expr="id"
              display-expr="tipo"
              placeholder="Selecione um tipo de pessoa"
            />
          </div>

          <div class="field" v-if="form.tipoPessoaStr">
            <b>Tipo de Pessoa {{ form.tipoPessoaStr === 'F' ? 'Física' : 'Jurídica' }}*:</b>
            <dx-select-box
              v-model="form.idTipoPerfil"
              :items="dynamicValues.tipoPerfilList"
              value-expr="id"
              display-expr="descricao"
              placeholder="Selecione um tipo de pessoa"
            />
          </div>

          <div class="field" v-if="!!getTipoPerfil('Destino Final')">
            <b>Tipo de destino final:</b>
            <dx-select-box
              v-model="form.tipoDestinoFinal"
              :items="['Aterro', 'ATT', 'PEV', 'Recicladora']"
              placeholder="Selecione um tipo de destino final"
            />
          </div>
        </div>
      </fieldset>

      <div v-if="form.tipoPessoaStr && form.idTipoPerfil && (!getTipoPerfil(['Gerador', 'Destino Final']) || (!!form.tipoDestinoFinal))">
        <fieldset v-if="form.tipoPessoaStr === 'F'">
          <legend>Cadastro de Pessoa Física</legend>

          <div class="form flex" v-if="formData">
            <div class="flex-col-12">
              <b>CPF*</b>
              <dx-text-box
                v-model="form.cpf"
                mask="000.000.000-00"
              />
            </div>
          </div>
          <div class="form flex">
            <div class="flex-col-12">
              <b>Email*</b>
              <dx-text-box
                v-model="form.email"
              />
            </div>
          </div>
          <div class="form flex">
            <div class="flex-col-12">
              <b>Confirmar Email*</b>
              <div v-if="form.confirmarEmail !== form.email">
                <i style="color: #900">Email e validação precisam estar iguais</i>
              </div>
              <dx-text-box
                :disabled="confirmarEmailDisable"
                v-model="form.confirmarEmail"
                :is-valid="form.confirmarEmail === form.email"
              />
            </div>
          </div>
          <div class="form flex">
            <div class="flex-col-12">
              <b>Nome*</b>
              <dx-text-box
                v-model="form.nome"
              />
            </div>
          </div>

          <div class="form flex">
            <div class="flex-col-6">
              <b>Celular*</b>
              <dx-text-box
                v-model="form.celular"
                mask="(00) 0 0000-0000"
              />
            </div>
            <div class="flex-col-6">
              <b>Fixo</b>
              <dx-text-box
                v-model="form.telefoneFixo"
                mask="(00) 0000-0000"
              />
            </div>
          </div>

          <div class="form flex">
            <div class="flex-col-4">
              <b>CEP*</b>
              <dx-text-box
                v-model="form.cep"
                mask="00.000-000"
              />
            </div>
            <div class="flex-col-4">
              <b>UF*</b>
              <dx-select-box
                v-model="form.uf"
                :items="dynamicValues.estados"
                value-expr="uf"
                display-expr="nome"
              />
            </div>
            <div class="flex-col-4">
              <b>Município*</b>
              <dx-select-box
                v-model="form.codIbge"
                :items="dynamicValues.municipios"
                value-expr="codIbge"
                display-expr="nome"
              />
            </div>
          </div>

          <div class="form flex">
            <div class="flex-col-4">
              <b>Bairro*</b>
              <dx-text-box
                v-model="form.bairro"
              />
            </div>
            <div class="flex-col-8">
              <b>Endereço*</b>
              <dx-text-box
                v-model="form.endereco"
              />
            </div>
          </div>

          <div class="form flex">
            <div class="flex-col-4">
              <b>Complemento</b>
              <dx-text-box
                v-model="form.complemento"
              />
            </div>
            <div class="flex-col-4">
              <b>Numero*</b>
              <dx-text-box
                v-model="form.numero"
              />
            </div>
          </div>

          <div class="form flex" v-if="getTipoPerfil('Transportador')">
            <div class="flex-col-12">
              <model-file-uploader
                v-model="form.certificadoLicenciamentoRcc"
                accept=".pdf"
                entity="Arquivo"
                :file-name="certificadoLicenciamentoRccComp"
                :label="'Certificado de licenciamento de RCC'"/>
            </div>
          </div>

          <div class="form flex" v-if="getTipoPerfil('Gerador')">
            <div class="flex-col-12">
              <model-file-uploader
                v-model="form.licenciamentoAmbientalRcc"
                accept=".pdf"
                entity="Arquivo"
                :file-name="licenciamentoAmbientalRccComp"
                :label="'Anexar Licenciamento Ambiental'"/>
            </div>
          </div>

          <div class="form flex" v-if="getTipoPerfil('Gerador')">
            <div class="flex-col-12">
              <model-file-uploader
                v-model="form.documentoAnaliseCadastro"
                accept=".pdf"
                entity="Arquivo"
                :file-name="documentoAnaliseCadastroComp"
                :label="'Anexar documentos para análise de cadastro'"/>
            </div>
          </div>

        </fieldset>
      </div>
    </div>

    {{ form }}

    <br><br>
    <!--    {{dynamicValues}}-->
  </div>
</template>

<style lang="scss">
.form {
  width: 100%;

  > div {
    margin: 0 10px 10px 0;
  }

  .field {
    display: flex;
    flex-direction: column;
  }
}
</style>

<script>
/* eslint-disable vue/no-unused-components */

import mixinCrud from '@/pages/Cruds/mixin-crud'
import { DxButton, DxPopup, DxSelectBox, DxTextArea, DxTextBox } from 'devextreme-vue'
import ModelFileUploader from '@/components/Form/ModelFileUploader'

export default {
  props: {
    id: {
      type: Number
    }
  },
  components: {
    DxButton,
    DxPopup,
    DxSelectBox,
    DxTextArea,
    DxTextBox,
    ModelFileUploader
  },
  mixins: [mixinCrud],
  data () {
    return {
      entity: 'Usuario',
      usuarioDadosIniciais: null,
      form: {
        tipoPessoaStr: null, // enum(F, J)
        idTipoPerfil: null, // number
        tipoDestinoFinal: null, // string(aterro, etc),

        email: null, // string
        nome: null, // string
        celular: null, // string
        telefoneFixo: null, // string
        confirmarEmail: null, // string, na edição este já virá preenchido, este campo sempre ficará desabilitado até q se mude o campo de email
        cpf: null, // string,
        bairro: null, // string
        uf: null, // string
        complemento: null, // string
        endereco: null, // string
        codIbge: null, // string,
        certificadoLicenciamentoRcc: null, // object
        licenciamentoAmbientalRcc: null, // object
        documentoAnaliseCadastro: null // object
      },
      dynamicValues: {
        tipoPerfilList: [],
        municipios: [],
        estados: []
      },
      confirmarEmailDisable: true
    }
  },
  watch: {
    'form.email' () {
      this.confirmarEmailDisable = false
    },
    async 'form.uf' (uf) {
      let municipios = []
      if (uf) {
        municipios = await this.find({
          entity: 'Municipio',
          rowsOnly: true,
          params: {
            where: {
              uf
            }
          }
        })
      }
      this.dynamicValues = {
        ...this.dynamicValues,
        municipios
      }
    },
    async 'form.cep' (cep = '') {
      if (cep.length === 8) {
        const enderecamento = await this.$utils.getEnderecamentoPeloCep(cep)
        this.form = {
          ...this.form,
          ...enderecamento
        }
      }
    },
    'form.idTipoPerfil' () {
      this.form.tipoDestinoFinal = null
    },
    'form.tipoPessoaStr': {
      async handler (tipoPessoaStr) {
        this.putDynamicValue('tipoPerfilList',
          await this.find({
            entity: 'TipoPerfil',
            rowsOnly: true,
            params: {
              where: {
                tipo: tipoPessoaStr
              }
            }
          })
        )
        this.form.idTipoPerfil = null
      }
    }
  },
  computed: {
    documentoAnaliseCadastroComp () {
      return this.documentoAnaliseCadastro && this.documentoAnaliseCadastro.length ? this.documentoAnaliseCadastro[0].name : undefined
    },
    certificadoLicenciamentoRccComp () {
      return this.certificadoLicenciamentoRcc && this.certificadoLicenciamentoRcc.length ? this.certificadoLicenciamentoRcc[0].name : undefined
    },
    licenciamentoAmbientalRccComp () {
      return this.licenciamentoAmbientalRcc && this.licenciamentoAmbientalRcc.length ? this.licenciamentoAmbientalRcc[0].name : undefined
    }
  },
  async mounted () {
    const estados = await this.find({
      entity: 'Estado',
      rowsOnly: true,
      params: {
        fields: JSON.stringify([
          'uf',
          'nome',
          'codIbge'
        ])
      }
    })

    this.dynamicValues = {
      ...this.dynamicValues,
      estados
    }
  },
  methods: {
    /**
     * Este método irá retornar o tipoPerfil selecionado quando nada for parametrizado, ou retornará o tipo selecionado apenas se este for igual ao `tipoAVerificar`
     * @param {Array|String} tipoAVerificar
     * @return {Object|null}
     */
    getTipoPerfil (tipoAVerificar = null) {
      const equals = (descricao) => {
        if (tipoAVerificar instanceof Array) {
          return tipoAVerificar.includes(descricao)
        } else {
          return descricao === tipoAVerificar
        }
      }
      const tipoPerfil = (this.dynamicValues.tipoPerfilList.find(({ id }) => id === this.form.idTipoPerfil) || {})
      return tipoPerfil && (tipoAVerificar === null || equals(tipoPerfil.descricao)) ? tipoPerfil : null
    },
    putDynamicValue (key, value) {
      this.dynamicValues = {
        ...this.dynamicValues,
        [key]: value
      }
    }
  }
}
</script>
