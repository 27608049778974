export default class SaveSolicitacaoTransporteComCacambaDto {
  constructor (solicitacaoData) {
    this.id = solicitacaoData.id
    this.idResiduoPredominante = solicitacaoData.tipoResiduoPredominante.tipoResiduo ? solicitacaoData.tipoResiduoPredominante.tipoResiduo.id : solicitacaoData.tipoResiduoPredominante.idTipoResiduo
    this.idDestinoFinal = solicitacaoData.destinoFinal.id
    this.idVeiculo = solicitacaoData.veiculo.id
    this.situacao = 'Retirada'
    this.observacao = solicitacaoData.observacao
    if (solicitacaoData.veiculo.tipo === 'Basculante') this.idCacamba = null
    const fotoCacamba = solicitacaoData.fotoCacamba[0] || undefined
    if (fotoCacamba) this.fotoCacamba = fotoCacamba
    if (fotoCacamba && fotoCacamba.name) this.nomeFotoCacamba = (fotoCacamba && fotoCacamba.name)

    this.residuos = JSON.stringify(solicitacaoData.residuos.map(residuo => ({
      idTipoResiduo: residuo.idTipoResiduo,
      volume: residuo.volume,
      id: residuo.id || residuo.idTipoResiduo
    })))
  }

  /**
   * Metodo para obter os dados da retirada de camcaba no formato de parametro
   * de requisicao.
   * @returns {FormData} Os dados para a requisicao.
   */
  toRequestParams () {
    const formData = new FormData()
    const fields = [
      'id', 'idResiduoPredominante', 'idDestinoFinal',
      'idVeiculo', 'situacao', 'observacao',
      'fotoCacamba', 'nomeFotoCacamba', 'residuos',
      'idCacamba'
    ]
    const acceptNullValues = ['idCacamba']
    fields.forEach(field => {
      if (this[field] || acceptNullValues.includes(field)) {
        formData.set(field, this[field])
      }
    })

    return formData
  }
}
