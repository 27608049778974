<template>
  <div id="app" class="page-container">
    <DxLoadPanel
      :position="{ of: 'body' }"
      :visible="isLoading || gettingUser"
      :show-indicator="true"
      :show-pane="true"
      :shading="true"
      :hide-on-outside-click="false"
      shading-color="rgba(0,0,0,0.4)"
    />
    <template v-if="!gettingUser && !hideLayout">
      <router-view v-if="$route.meta.publicRoute"/>
      <app-home v-else-if="isLoggedIn"/>
      <app-login v-else/>
    </template>
    <router-view v-if="hideLayout"/>
    <PopupMensagens v-if="user" />
  </div>
</template>

<script>
import AppHome from './layout/home/HomeLayout'
import AppLogin from './pages/Login'
import { mapGetters, mapActions, mapState } from 'vuex'
import { DxLoadPanel } from 'devextreme-vue/load-panel'
import PopupMensagens from './components/PopupMensagens/PopupMensagens.vue'

export default {
  components: {
    DxLoadPanel,
    'app-home': AppHome,
    'app-login': AppLogin,
    PopupMensagens
  },
  computed: {
    ...mapGetters('Login', ['isLoggedIn']),
    ...mapState('Login', ['user'])
  },
  data () {
    return {
      tempLoadingValue: false,
      lockLoading: false,
      isLoading: false,
      gettingUser: false,
      hideLayout: false,
      mensagens: [],
      popupViewed: localStorage.getItem('popupViewed')
    }
  },
  methods: {
    ...mapActions('Login', ['logoutAction', 'getUserLogged'])
  },
  mounted () {
    this.$store.watch((state) => {
      const isLoading = state.Crud.isLoading
      this.tempLoadingValue = isLoading
      if (!this.lockLoading && isLoading) {
        this.isLoading = true
        this.lockLoading = true
        const timer = setInterval(() => {
          if (this.tempLoadingValue === false) {
            this.isLoading = false
            this.lockLoading = false
            clearInterval(timer)
          }
        }, 2000)
      }
    })
  },
  watch: {
    '$route' (value) {
      this.hideLayout = value.meta.hideLayout
    }
  },
  async created () {
    try {
      this.isLoading = true
      this.gettingUser = true
      await this.getUserLogged()
    } catch (e) {
    } finally {
      this.isLoading = false
      this.gettingUser = false
    }
  }
}
</script>

<style lang="scss">
@import "~vue-material/dist/theme/engine"; // Import the theme engine
@import './global.scss';

@include md-register-theme("default", (
  primary: $primary,
  accent: $accent,
  text-primary-on-primary: #fff
  // primary: md-get-palette-color(blue, A200), // The primary color of your application
  // accent: md-get-palette-color(red, A200) // The accent or secondary color
));

@import "~vue-material/dist/theme/all"; // Apply the theme

.dx-form-validation-summary {
  display: flex !important;
  gap: 10px;
  margin-left: 20px;
  flex-flow: wrap;
}
.dx-validationsummary {
  display: flex !important;
  gap: 10px;
  margin-left: 20px;
  flex-flow: wrap;
}

.dx-item-content.dx-validationsummary-item-content:hover {
  color: red;
  transform: scale(1.05);
  transition: all 100ms linear
}
.large-icon {
  font-size: 24px;
}
.medium-icon {
  font-size: 18px
}

.dx-button.dx-button-primary {
  background-color: #FF8402 !important;
  color: white !important;
}

.dx-button.dx-button-accent {
  background-color: #4697d3 !important;
  color: white !important;
}

.dx-button-mode-contained.dx-button-primary .dx-icon {
  color: white !important;
}
.dx-button-mode-contained.dx-button-accent .dx-icon {
  color: white !important;
}

.dx-button.dx-button-accent.dx-state-hover {
  background-color: #408bc4 !important;
}

.dx-button.dx-button-primary.dx-state-hover {
  background-color: #ff9b37 !important;
}

.dx-button.dx-button-primary.dx-state-hover {
  background-color: #ff9b37;
}

.crud-title {
  text-transform: uppercase;
}

body, #app .dx-widget {
  font-family: Poppins, sans-serif;
}

.md-list {
  font-family: Montserrat, sans-serif;
}

h1, h2, h3, h4 {
  color: #2F3F52;
  text-transform: uppercase;
}

.dx-popup-normal {
  .dx-button + .dx-button {
    margin-right: 10px;
  }
}

.popup-scroll .dx-popup-content {
  overflow: auto !important; // overflow para autoCadastro-Login
}

#app {
  a.dx-button {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  .dx-form {
    margin-bottom: 20px;

    .dx-field-item-help-text {
      font-style: italic;
      white-space: normal;
      font-size: 75%;
      font-weight: normal;
      color: #7b7b7b;
    }
  }

  .dx-datagrid-headers {
    color: #2F3F52;
    font-weight: bold;
    text-transform: uppercase;
  }

  .dx-editor-outlined {
    border-color: #C1C1C1;
    border-width: 2px;
  }

  .dx-box-item-content {
    font-family: Montserrat, sans-serif;
  }

  .dx-button {
    font-weight: 600;
    font-size: 90%;
    text-transform: uppercase;
    min-width: 90px;

    .dx-link-icon {
      vertical-align: middle;
    }
  }
}

.generic-crud-form {
  max-width: 800px;

  fieldset {
    padding: 20px 20px;
    border-color: #eeeeee;
    border-style: solid;

    legend {
      color: #464646;
      background-color: white;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

.swal2-container {
  z-index: 10000000000;
}

.dx-button .dx-button-content {
  line-height: 15px;
}

.hidden {
  display: none
}

.md-width {
  width: 8.5rem !important;
}

.full-width {
  width: 100% !important;
}

.small-padding {
  padding: 0.5rem;
}

.small-padding-botton {
  padding-bottom: 0.5rem;
}
</style>
