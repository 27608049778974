<template>
  <div class="cacamba-checkbox-group-filter">
    <span class="group-title">{{ title }}</span>
    <div :class="groupClasses">
      <div
        v-for="item in value"
        :key="item.situacao"
        :class="{ 'card-item': true, 'card-checked': item.checked }"
        @click="onCardClick(item)">
        <div :class="['color-bar', item.colorClass]"></div>

        <div class="icon-container">
          <md-icon v-if="item.checked" class="mdi mdi-checkbox-marked"/>
          <md-icon v-else class="mdi mdi-checkbox-blank-outline"/>
        </div>

        <div class="card-content">
          <span class="value-text">{{ item.value }}</span>

          <span class="item-title-text">{{ item.situacao }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'cacamba-checkbox-group-filter',
  props: {
    vertical: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    },
    value: {
      type: Array,
      required: true
    }
  },

  computed: {
    groupClasses () {
      return {
        'cacamba-card-group': true,
        'vertical-group': this.vertical
      }
    }
  },

  methods: {
    onCardClick (itemClicked) {
      this.$emit('input', this.value.map(item => ({
        ...item,
        checked: item === itemClicked ? !item.checked : item.checked
      })))
    }
  }
}
</script>

<style lang="scss">
@import "../../../../global.scss";

.cacamba-checkbox-group-filter {
  display: flex;
  flex-direction: column;

  .cacamba-card-group {
    display: flex;
    flex: 1;

    &.vertical-group {
      flex-direction: column;

      .card-item {
        display: flex;
        flex-direction: row-reverse;
        align-items: flex-start;
        justify-items: flex-start;

        .color-bar {
          width: 6px;
          height: 100%;
          position: absolute;
          left: 0;
        }

        .card-content {
          flex-direction: row;
          flex: 1;

          > .value-text {
            flex: unset;
            margin: 0px 12px;
          }

          > .item-title-text {
            flex: 1;
          }
        }
      }
    }

    &:not(.vertical-group) .card-item .icon-container {
      position: absolute;
      top: 8px;
      right: 4px;
    }

    .card-item {
      box-shadow: 0 0 5px #cdcdcd;
      margin: 5px;
      flex: 1;
      box-sizing: border-box;
      cursor: pointer;
      position: relative;
      transition: color .4s cubic-bezier(.4,0,.2,1);

      &.card-checked > .icon-container i {
        color: $primary;
      }

      &:not(.card-checked) {
        color: #aaa;

        > .color-bar {
          background: #aaa;
        }
      }

      .color-bar {
        height: 6px;
        transition: background .4s cubic-bezier(.4,0,.2,1);
      }

      .icon-container > i {
        font-size: 18px !important;
        height: 18px;
        width: 18px;
      }

      .card-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;

        .value-text {
          line-height: normal;
          font-size: 2rem;
          display: flex;
          flex: 1;
          margin-top: 26px;
        }

        .item-title-text {
          margin-bottom: 10px;
          font-size: 0.85rem;
        }
      }
    }

    .result-text-update-time {
      margin: 0 10px;
    }

    .result-button-update {
      margin: 0 10px 0 10px;
    }
  }

  .leaflet-map-component {
    flex: 1;
    margin: 30px 10px 10px;
  }

  .map-message-card {
    margin-bottom: 20px;

    &.error-message {
      background-color: #B00020 !important;
      color: white !important;
    }

    &.info-message {
      background-color: #ffce99 !important;
    }
  }

  .cacamba-icon {
    border-radius: 100%;
    width: 32px;
    padding: 4px;

    &.cacamba-enviada {
      background: #008E32;
    }

    &.cacamba-alocada {
      background: #638e00;
    }

    &.cacamba-retirada {
      background: #8e8000;
    }

    &.cacamba-destinada {
      background: #8e4500;
    }

    &.cacamba-irregular {
      background: #8e0000;
    }

    &.cacamba-ocorrencia-pf {
      background: #8e0082;
    }

    &.cacamba-ocorrencia-pj {
      background: #36008e;
    }

    > i {
      color: white !important;
    }
  }
}
</style>
