import { doRequest } from '../pluggables/http'
import { getParamsFromLoadOptions } from '../util/loadOptionsParams'

/**
   * Metodo de comando, com o objetivo de realizar a requisica de obter uma lista
   * de obras com a lista de pessoas relacionadas a elas.
   * @param {Object} params - Os parametros da requisicao.
   * @param {string} params.urlModel - Parametro de definicao da model a ser reuisitada.
   * @param {Object} [params.where={}] - Os parametros de where da busca.
   * @param {Object} [params.loadOptions=null] - Os parametros de busca do
   * datasource do devextreme (opicional).
   * @param {string} params.displayField - O campo que vai ser usado para
   * apresentar os valores da query principal (opicional).
   * @param {Object} params.paramsIncludes - Tras os includes para requisicao gerar os
   * relacionamentos da query (opicional).
   * @returns {Object} O resultado da requisicao.
   */
export async function getByKeys ({ urlModel, where = {}, loadOptions = null, displayField, paramsIncludes }) {
  const params = loadOptions !== null ? getParamsFromLoadOptions(loadOptions) : {}
  params.where = {
    ...params.where,
    ...where
  }
  if (paramsIncludes) {
    params.include = paramsIncludes
  }
  if (displayField) {
    params.fields = ['id', displayField]
  } else {
    params.fields = ['id']
  }

  const res = await doRequest({
    method: 'post',
    url: urlModel + '/find',
    params
  })

  return { data: res.data.rows, totalCount: res.data.count }
}
