<template>
  <div>
    <form-pessoa
      :auto-cadastro="autoCadastro"
      :id="id"
      :modal.sync="showModal"
      @save="onSave"
    />
  </div>
</template>
<script>
import FormPessoa from '@/pages/Cruds/Pessoa/FormPessoa'
import mixinCrud from '@/pages/Cruds/mixin-crud'
export default {
  props: {
    autoCadastro: {
      type: Boolean,
      default: () => false
    },
    id: {
      type: [Number, String]
    },
    name: {
      type: String,
      default: () => null
    },
    email: {
      type: String,
      default: () => null
    },
    password: {
      type: String,
      default: () => null
    },
    idAuth: {
      type: String,
      default: () => null
    },
    login: {
      type: Boolean,
      default: () => false
    },
    modal: {
      type: Boolean,
      default: () => false
    },
    action: {
      type: String
    }
  },
  mixins: [mixinCrud],
  data () {
    return {
      showModal: this.modal
    }
  },
  provide () {
    return { action: this.action, autoCadastro: this.autoCadastro }
  },
  watch: {
    modal (modal) {
      this.showModal = modal
    },
    showModal (showModal) {
      this.$emit('update:modal', showModal)
    }
  },
  components: { FormPessoa },
  methods: {
    async onSave (payload = {}) {
      try {
        const getFormData = () => this.$utils.parseToFormData(payload)
        let message = null
        let result = null
        const flagToEdit = (payload.tipoPessoa === 'J' && this.$utils.isFinalId(payload.pessoaPj.id)) || (payload.tipoPessoa === 'F' && this.$utils.isFinalId(payload.usuarioPessoaFisica.id))
        if (flagToEdit) {
          if (this.login) {
            if (payload.pessoaPj && payload.pessoaPj.id) {
              for (const usuario of payload.usuariosPj) {
                if (usuario._status === 'insert') {
                  delete usuario.id
                  usuario.idPessoa = payload.pessoaPj.id
                  result = (await this.$http.post(this.$http.normalizeUrl('/usuario-api/cadastrar-usuario'), this.$utils.parseToFormData(usuario))).data

                  message = 'Cadastro feito com sucesso'
                  break
                }
              }
            } else {
              this.$utils.notifyError(new CustomError('Não foi possível concluir esta solicitação.'))
            }
          } else {
            result = (await this.$http.put(this.$http.normalizeUrl('/pessoa-api/editar'), getFormData())).data
            message = 'Edições feitas com sucesso'
          }
        } else {
          if (payload.usuarioPessoaFisica) {
            const pessoa = await this.cpfCnpjExists({ value: payload.usuarioPessoaFisica.cpf })
            if (!pessoa) throw new CustomError(`Não é possível realizar este cadastros. O CPF ou CNPJ ${payload.usuarioPessoaFisica.cpf} já está cadastrado no sistema. Altere os dados de usuário ou tente recuperar sua senha.`)
          } else if (payload.pessoaPj.cnpj) {
            const usuarios = payload.usuariosPj.filter(usuario => usuario._status !== 'remove')
            if (!usuarios.length) throw new CustomError('É necessário cadastrar ao menos um usuário para a pessoa jurídica.')
            const pessoa = await this.cpfCnpjExists({ value: payload.pessoaPj.cnpj })
            if (!pessoa) throw new CustomError(`Não é possível realizar este cadastros. O CPF ou CNPJ ${payload.pessoaPj.cnpj} já está cadastrado no sistema. Altere os dados de usuário ou tente recuperar sua senha.`)
          }

          result = (await this.$http.post(this.$http.normalizeUrl('/pessoa-api/cadastrar'), getFormData())).data
          message = 'Cadastro feito com sucesso'
        }
        this.$utils.notifySuccess(message)
        this.$emit('save-sucess', result)
        if (!this.email) this.$router.push('/pessoa')
      } catch (error) {
        if (!error.message.includes("Cannot read properties of undefined (reading 'data')")) {
          this.$utils.notifyError(error)
        }
      }
    }
  }
}
</script>
