
import menuConfig from '../menuConfig'
import { hasPermission } from '../../util/permissions'

/**
 * Metodo para obter a lista de rotas para o menu da aplicacao.
 * @returns {Object[]}
 */
export function menuRoutes () {
  return menuConfig.reduce((acc, route) => {
    if (route.children) {
      const visibleChildren = route.children.filter(_menuItemIsVisible)
      return visibleChildren.length
        ? acc.concat({
          ...route,
          children: visibleChildren
        })
        : acc
    } else if (_menuItemIsVisible(route)) {
      return acc.concat(route)
    }
    return acc
  }, [])
}

function _menuItemIsVisible (menuItemConfig) {
  return hasPermission(menuItemConfig.title, 'consulta')
}
