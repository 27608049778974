import Veiculo from './Veiculo.vue'
import FormVeiculo from './FormVeiculo.vue'

export default [
  {
    path: '/veiculo',
    name: 'veiculo',
    component: Veiculo,
    meta: {
      icon: 'mdi mdi-dump-truck',
      title: 'Manter Veículo',
      requiresAuth: true,
      permissionType: 'consulta'
    },
    props: true
  },
  {
    path: '/veiculo/:action',
    name: 'veiculo-add',
    component: FormVeiculo,
    meta: {
      title: 'Manter Veículo',
      requiresAuth: true,
      permissionType: 'inclui'
    },
    props: true
  },
  {
    path: '/veiculo/:action/:id',
    name: 'veiculo-edit',
    component: FormVeiculo,
    meta: {
      title: 'Manter Veículo',
      requiresAuth: true,
      permissionType: 'consulta'
    },
    props: true
  }
]
