import SolicitarCancelamentoCTR from './SolicitarCancelamentoCTR.vue'

export default {
  path: '/cancelamento-ctr',
  name: 'cancelamento-ctr',
  component: SolicitarCancelamentoCTR,
  meta: {
    icon: 'mdi mdi-newspaper-variant-outline',
    title: 'Analisar Solicitação de Cancelamento de CTR',
    requiresAuth: true,
    permissionType: 'consulta'
  },
  children: [
    {
      path: '/cancelamento-ctr/:action',
      name: 'cancelamento-ctr-add',
      component: SolicitarCancelamentoCTR,
      meta: {
        title: 'Analisar Solicitação de Cancelamento de CTR',
        requiresAuth: true,
        permissionType: 'consulta'
      },
      props: true
    },
    {
      path: '/cancelamento-ctr/:action/:id',
      name: 'cancelamento-ctr-edit',
      component: SolicitarCancelamentoCTR,
      meta: {
        title: 'Analisar Solicitação de Cancelamento de CTR',
        requiresAuth: true,
        permissionType: 'consulta'
      },
      props: true
    }
  ],
  props: true
}
