export function isLoggedIn (state) {
  return state && !!state.token
}

export function authStatus (state) {
  return state && state.status
}

export function userData ({ user }) {
  const userData = {
    image: null,
    name: null,
    login: null,
    userType: null,
    id: null,
    sigla: null
  }
  if (user) {
    if (user.pessoa) {
      userData.id = user.id
      userData.name = user.pessoa.nomeRazaoSocial
      userData.login = user.pessoa.email
      userData.sigla = user.pessoa.siglaNome
      userData.pessoa = user.pessoa
      userData.isSecretaria = !!user.perfis
        .find(perfil => (perfil.tipoPerfil || {}).descricao === 'Secretaria')
      userData.isAdministrador = !!user.perfis
        .find(perfil => (perfil.tipoPerfil || {}).descricao === 'Administrador')
      userData.userTypes = user.perfis
        .map(perfil => (perfil.tipoPerfil || {}).descricao || null)
        .filter(v => v !== null)
      userData.perfis = user.perfis
    } else if (user[0]) {
      userData.sigla = user[0].siglaNome
      if (!userData.sigla) {
        userData.sigla = user[0].siglanome
      }
      userData.id = user[0].id
      userData.name = user[0].nomerazaosocial
      userData.login = user[0].email
      userData.userTypes = [user[0].perfildescricao]
    }
  }
  return userData
}

/**
 * Metodo para obter a lista de permissoes do usuario logado.
 * @returns {Object[]}
 */
export function userPermissons ({ user }) {
  return ((user && user.perfis) || []).reduce((acc, perfil) =>
    acc.concat(perfil.tipoPerfil.permissoes), [])
}
