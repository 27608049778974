<template>
  <md-app md-waterfall md-mode="fixed">
    <md-app-toolbar class="md-primary">
      <md-button class="md-icon-button" @click="menuVisible = !menuVisible">
        <md-icon class="md-primary">menu</md-icon>
      </md-button>
      <span class="md-title">{{ $route.meta.title }}</span>
      <div class="md-layout md-gutter md-layout-item md-alignment-space-between-right">
        <md-button class="md-icon-button" @click="$router.go(-1)">
          <md-icon class="md-primary">keyboard_backspace</md-icon>
        </md-button>
        <img id="slu_logo" style="height: 45px;" src="logo_header.jpg">
      </div>
    </md-app-toolbar>
    <md-app-drawer md-persistent="full" class="sidebar" :md-active.sync="menuVisible">
      <md-list>
        <md-list-item>
          <md-icon class="md-primary" style="margin-right: 15px;">person</md-icon>
          <span class="md-list-item-text user-title">{{ userData.name }}</span>
        </md-list-item>
      </md-list>
      <md-divider></md-divider>
      <md-list class="router-list">
        <md-list-item
          v-for="(item, index) in menuRoutes"
          :key="index"
          :to="item.path"
          :md-expand="Boolean(item.children && item.children.length > 0)">
          <md-icon :class="item.icon"></md-icon>
          <span class="md-list-item-text menu-router-item-text">{{ item.title }}</span>
          <md-list
            v-if="Boolean(item.children && item.children.length > 0)"
            slot="md-expand">
            <md-list-item
              v-for="(subItem, subIndex) in item.children"
              :key="subIndex"
              :to="subItem.path">
              <md-icon :class="subItem.icon"></md-icon>
              <span class="md-list-item-text menu-router-item-text">{{ subItem.title }}</span>
            </md-list-item>
          </md-list>
        </md-list-item>
      </md-list>
      <md-divider></md-divider>
      <md-list>
        <md-list-item @click="onReclamacoesButtonClick">
          <md-icon style="margin-right: 15px;">chat</md-icon>
          <span class="md-list-item-text">Dúvidas ou Sugestões</span>
        </md-list-item>
        <md-list-item @click="onLogoutBtnClick">
          <md-icon style="margin-right: 15px;">logout</md-icon>
          <span class="md-list-item-text">Sair</span>
        </md-list-item>
      </md-list>
    </md-app-drawer>
    <md-app-content class="main-panel" :class="`page-route-${$route.name || 'default'}`">
      <!-- <top&#45;navbar></top&#45;navbar> -->
      <router-view></router-view>
    </md-app-content>
  </md-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import routes from '@/router/routes'
import { hasPermission } from '../../util/permissions'

export default {
  data () {
    return {
      routes,
      menuVisible: true
    }
  },
  computed: {
    ...mapGetters('Login', { userData: 'userData' }),
    ...mapGetters('Layout', ['menuRoutes'])
  },
  methods: {
    ...mapActions('Login', ['logoutAction']),
    onLogoutBtnClick () {
      this.logoutAction()
        .then(() => {
          if (this.$route.path !== '/') {
            this.$router.push('/')
          }
        })
    },
    toggleSidebar () {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false)
      }
    },
    _menuItemIsVisible (menuItemConfig) {
      return menuItemConfig.userType
        .find(requiredUserType => requiredUserType === 'ALL' ||
          this.userData.userTypes
            .find(userType => userType === requiredUserType))
    },
    onReclamacoesButtonClick () {
      this.$router.push({ path: '/reclamacoes' })
    }
  },
  watch: {
    userData () {
      if (hasPermission('Localizar CTR', 'consulta')) {
        this.$router.push('/localizar-ctr')
      }
    }
  }
}

</script>

<style lang="scss">
@import '../../global.scss';

.md-app {
  height: 100vh;
}
.md-drawer.md-theme-default, .md-list.md-theme-default, .sidebar {
  background-color: #EDEDED !important;
  width: 270px;
  max-width: calc(100vw - 125px);
}

.user-title {
  font-weight: bold;
  font-size: 110%;
  color: #6A6A6A;
  white-space: normal !important;
}

.router-list {
  .md-list-item-content > .md-icon:first-child {
    margin-left: 10px;
    margin-right: 15px !important;
  }

  .md-list.md-theme-default .md-list-item-container {
    color: #6A6A6A;
  }

  .menu-router-item-text {
    white-space: normal;
  }
}
.md-list.md-theme-default .md-list-item-container.active {
  background: var(--md-theme-default-divider-on-background, rgba(0, 0, 0, 0.07));

  .md-list-item-text, .md-icon {
    color: $primary;
  }
}

.md-toolbar.md-theme-default.md-primary .md-icon {
  font-weight: bold;
}

.sidebar {
  display: flex;
  flex-direction: column;
}
.router-list {
  flex-grow: 1;
}

.main-panel {
  display: flex;
  flex-direction: column;

  > div {
    flex: 1;
  }
}
</style>
