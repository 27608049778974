/**
 * Metodo para obter a lista de tipo de residuos para o grid.
 * @param {Object} residuos - Os dados de residuos retornado do servidor.
 * @returns {Object[]} A lista de tipos de residuos da obra.
 */
export function getResiduosToGrid (residuos) {
  return (residuos || [])
    .map(obraTipoResiduo => {
      const { tipoResiduo } = obraTipoResiduo

      if (!tipoResiduo) {
        return null
      }

      const { classeMaterial } = tipoResiduo

      if (!classeMaterial) {
        return null
      }

      classeMaterial.tipoResiduos = classeMaterial.tipoResiduos.map(_tipoResiduo => ({
        descricao: _tipoResiduo.descricao,
        id: _tipoResiduo.id,
        idClasseMaterial: _tipoResiduo.idClas,
        nome: _tipoResiduo.nome
      }))

      return {
        classe: classeMaterial.classe,
        residuo: tipoResiduo.nome,
        residuos: classeMaterial.tipoResiduos.map(residuo => residuo.nome).join(', '),
        volume: obraTipoResiduo.volume,
        data: {
          residuo: tipoResiduo,
          volume: obraTipoResiduo.volume,
          classeMaterial
        }
      }
    })
    .filter(tipoResiduo => tipoResiduo !== null)
}

/**
 * Metodo para obter a lista de residuos do grid, no modelo do banco.
 * @param {Object[]} residuos - A lista de residuos do grid.
 * @returns {Object[]} A lista de residuo no modelo do banco.
 */
export function getResiduosFromGrid (residuos) {
  return residuos.map(residuo => ({
    volume: residuo.volume,
    idTipoResiduo: residuo.data.residuo.id,
    tipoResiduo: {
      ...residuo.data.residuo,
      classeMaterial: residuo.data.classeMaterial
    }
  }))
}
