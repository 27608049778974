<script>
import L from 'leaflet'

export default {
  name: 'leaflet-marker',
  props: {
    /**
     * Nome da classe no elemento de icone.
     */
    iconClassName: {
      type: String,
      default: ''
    },
    /**
     * Latitude e longitude do marcador.
     */
    latlng: {
      type: Array,
      default: () => [0, 0]
    },
    /**
     * Opcoes de criacao do marcador.
     */
    markerOptions: {
      type: Object,
      default: () => ({})
    },
    /**
     * Se deve fazer o bind do popup para o marcador.
     */
    bindPopup: {
      type: Boolean,
      default: false
    },
    /**
     * A funcao para obter o valor passado no parametro 'content' do metodo
     * 'bindPopup' do marcador.
     */
    getPopupValue: {
      type: Function
    }
  },
  /**
   * Metodo do vue de obter o estado inicial do componente.
   * @returns {Object} O estado inicial do componente.
   */
  data () {
    return {
      marker: null,
      icon: null
    }
  },
  /**
   * Metodo do ciclo de vida do vue, de quando o componente foi montado na tela.
   * Com o objetivo de criar o marcador.
   */
  mounted () {
    const hasClass = this.$el.classList.contains('display-none')

    !hasClass && this.$el.classList.add('display-none')

    this.icon = L.divIcon({
      className: this.iconClassName,
      html: this.$el
    })
    this.marker = L.marker(this.latlng, {
      ...this.markerOptions,
      icon: this.icon
    })
    this.$parent.addToMap(this.marker)

    if (this.bindPopup) {
      this.marker.bindPopup(this.getPopupValue({ marker: this.marker }))
    }
    this.marker.on('click', () => this.$emit('click', this.marker))

    !hasClass && this.$el.classList.remove('display-none')
  },
  /**
   * Metodo do vue para renderizar o componente.
   * @returns {Object} O VNode do elemento do componente.
   */
  render () {
    return this.$scopedSlots.default({})
  }
}
</script>
