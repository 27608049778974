import { doRequest } from '@/pluggables/http'

/**
 * Metodo de comando, com o objetivo de realizar a requisicao de obter os dados
 * de um endereco.
 * @param {string} params - Os parametros da requisicao
 * @returns {Object} O resultado da requisica.
 */
export async function getEndereco (params) {
  function getCepGeometry (geocodeCandidates) {
    const [{ location = null } = {}] = geocodeCandidates || []

    return location !== null ? {
      type: 'Point',
      coordinates: [location.x, location.y],
      srid: '4326',
      crs: { type: 'name', properties: { name: 'EPSG:4326' } }
    } : null
  }

  const res = await doRequest({
    method: 'post',
    url: 'findAddress',
    params
  })
  return res.success &&
    res.data.info.geocodeCandidates &&
    res.data.info.geocodeCandidates.length
    ? {
      success: true,
      data: {
        ...(res.data.info.endereco ? {
          bairro: res.data.info.endereco.bairro,
          uf: res.data.info.endereco.uf,
          municipio: res.data.info.endereco.localidade,
          endereco: res.data.info.endereco.logradouro,
          codIbge: res.data.info.endereco.ibge
        } : {}),
        shape: getCepGeometry(res.data.info.geocodeCandidates)
      },
      error: null
    }
    : {
      success: false,
      data: null,
      error: res.data
    }
}
