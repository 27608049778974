import TipoResiduo from './TipoResiduo.vue'

export default {
  path: '/tipo-residuo',
  name: 'tipo-residuo',
  component: TipoResiduo,
  meta: {
    icon: 'mdi mdi-delete-variant',
    title: 'Manter Tipo de Resíduo',
    requiresAuth: true,
    permissionType: 'consulta'
  },
  children: [
    {
      path: '/tipo-residuo/:action',
      name: 'tipo-residuo-add',
      component: TipoResiduo,
      meta: {
        title: 'Manter Tipo de Resíduo',
        requiresAuth: true,
        permissionType: 'inclui'
      },
      props: true
    },
    {
      path: '/tipo-residuo/:action/:id',
      name: 'tipo-residuo-edit',
      component: TipoResiduo,
      meta: {
        title: 'Manter Tipo de Resíduo',
        requiresAuth: true,
        permissionType: 'consulta'
      },
      props: true
    }
  ],
  props: true
}
