<template>
  <div class="atribuicao-perfil">
    <div>
      <div class="md-layout">
        <div class="md-layout-item">
          <h2 class="crud-title">Parametrização das Atribuições dos Perfis</h2>
        </div>
      </div>
      <simple-grid
        entity="TipoPerfil"
        :deletable="false"
        :editable="false"
        :get-edit-path="getEditPath"
        :grid-data="gridData"
        ref="simpleGrid"/>
    </div>
  </div>
</template>

<script>
import { SimpleGrid } from '@/components'
import CustomStore from 'devextreme/data/custom_store'

import { getPerfis } from '../service'
import mixinCrud from '../../mixin-crud'
import { hasPermission } from '../../../../util/permissions'

export default {
  name: 'list-solicitacoes-com-cacamba',
  components: {
    SimpleGrid
  },
  mixins: [mixinCrud],
  /**
   * Metodo do vue, para obter o estado inicial do componente.
   * @returns {Object} O estado inicial do componente.
   */
  data () {
    return {
      gridData: {
        value: {
          visible: true
        },
        columns: this.parseColumns([
          {
            dataField: 'descricao',
            caption: 'Perfil',
            lookup: {
              allowClearing: true,
              dataSource: [
                'Gerador', 'Transportador', 'Destino Final',
                'Secretaria', 'Fiscalização', 'Entidade Pública',
                'Cidadão', 'Administrador', 'Órgão Público'
              ]
            }
          },
          {
            dataField: 'tipo',
            calculateDisplayValue (data) {
              return data.tipo === 'F' ? 'PF' : 'PJ'
            },
            calculateSortValue: 'tipo',
            lookup: {
              allowClearing: true,
              dataSource: [
                { label: 'PJ', value: 'J' },
                { label: 'PF', value: 'F' }
              ],
              displayExpr: 'label',
              valueExpr: 'value'
            },
            caption: 'Tipo de Pessoa'
          },
          {
            type: 'buttons',
            showInColumnChooser: false,
            buttons: [
              {
                ...(hasPermission('Gerenciar permissões', 'altera') ? {
                  cssClass: 'dx-button dx-button-success dx-button-mode-contained mdi mdi-pencil',
                  text: 'EDITAR'
                } : {
                  cssClass: 'dx-button dx-button-success dx-button-mode-contained mdi mdi-magnify',
                  text: 'VISUALIZAR'
                }),
                onClick: ({ row: { data } }) => {
                  this.$router.push({ path: `/atribuicao-perfil/${data.id}` })
                }
              }
            ]
          }
        ]),
        dataSource: new CustomStore({
          key: 'id',
          load: loadOptions => this.$utils.wrapRequestForGrid(getPerfis(loadOptions))
        })
      }
    }
  },
  methods: {
    /**
     * Metodo para obter o path de edicao de uma linha do grid.
     * @param {Object} row - Os dados da linha a obter o path.
     * @returns {string} O path de edicao da linha.
     */
    getEditPath ({ data }) {
      return `atribuicao-perfil/${data.id}`
    }
  }
}
</script>
