
export const parseColumns = (columns) => {
  for (const col of columns) {
    if (col.editorType && col.editorType === 'dxLookup') {
      col.editorOptions = {
        allowClearing: true,
        showClearButton: true,
        showCancelButton: false,
        dropDownOptions: {
          closeOnOutsideClick: true
        },
        ...col.editorOptions
      }

      col.lookup = col.editorOptions
    }

    if (col.caption && !col.label) {
      col.label = {
        text: col.caption
      }
    }
  }
  return columns
}
