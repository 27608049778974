import ConsultaATT from './ConsultaATT.vue'

export default {
  path: '/consultar-att',
  name: 'consulta-att',
  component: ConsultaATT,
  meta: {
    icon: 'mdi mdi-perspective-more',
    title: 'Consultar Capacidade da ATT',
    requiresAuth: true,
    permissionType: 'consulta'
  },
  props: true
}
