import { doRequest } from '@/pluggables/http'
import { getParamsFromLoadOptions } from '@/util/loadOptionsParams'

/**
 * Metodo de comando, com o objetivo de realizar a requisica de obter uma lista
 * de cacambas disponiveis.
 * @param {Object} params - Os parametros da requisicao.
 * @param {Object} loadOptions - Parâmetros padrões do devextreme loadOptions
 * datasource do devextreme.
 * @returns {Object} O resultado da requisicao.
 */
export async function getAvailableCacambas (params, loadOptions = {}) {
  let loadOptionsParams = getParamsFromLoadOptions(loadOptions)
  if (!loadOptions.paginate) {
    loadOptionsParams = {
      ...loadOptionsParams,
      limit: undefined,
      offset: undefined
    }
  }
  const res = await doRequest({
    method: 'get',
    url: 'ViewCacambasDisponiveis',
    params: {
      params: {
        ...loadOptionsParams,
        limit: undefined,
        offset: undefined,
        order: JSON.stringify([['numeroIdentificacao', 'asc']])
      }
    }
  })
  return { data: res.data.rows, totalCount: res.data.count }
}

/**
 * Metodo de comando, com o objetivo de realizar a requisica de obter uma lista
 * de veiculos disponiveis.
 * @param {Object} params - Os parametros da requisicao.
 * @param {Object} loadOptions - Parâmetros padrões do devextreme loadOptions
 * datasource do devextreme.
 * @returns {Object} O resultado da requisicao.
 */
export async function getAvailableVeiculos (params, loadOptions = {}) {
  let loadOptionsParams = getParamsFromLoadOptions(loadOptions)
  if (!loadOptions.paginate) {
    loadOptionsParams = {
      ...loadOptionsParams,
      limit: undefined,
      offset: undefined,
      order: JSON.stringify([['placa', 'asc']])
    }
  }
  const res = await doRequest({
    method: 'get',
    url: 'ViewVeiculosDisponiveis',
    params: {
      params: {
        ...loadOptionsParams,
        where: { ...loadOptionsParams.where, ...params.where },
        order: JSON.stringify([['placa', 'asc']])
      }
    }
  })
  return { data: res.data.rows, totalCount: res.data.count }
}

/**
 * Metodo de comando, com o objetivo de realizar a requisica de obter uma
 * cacamba pelo seu id.
 * @param {Object} id - O id da cacamba.
 * @returns {Object} O resultado da requisicao.
 */
export function getCacamba (id) {
  return doRequest({
    method: 'get',
    url: `Cacamba/${id}`
  })
}

/**
 * Metodo de comando, com o objetivo de realizar a requisica de obter um veiculo
 * pelo seu id.
 * @param {Object} id - O id da veiculo.
 * @returns {Object} O resultado da requisicao.
 */
export function getVeiculo (id) {
  return doRequest({
    method: 'get',
    url: `Veiculo/${id}`
  })
}

/**
 * Metodo de comando, com o objetivo de realizar a requisicao de atualizar uma
 * solicitacao de transporte nao solicitado.
 * @param {Object} params - Os parametros da requisicao.
 * @returns {Object} O resultado da requisicao.
 */
export function updateSolicitacaoTransporteNaoSolicitado (params, id) {
  return doRequest({
    method: 'put',
    url: `updateSolicitacaoTransporte/${id}`,
    params
  })
}
/**
 * Metodo de comando, com o objetivo de realizar a requisicao de atualizar uma
 * solicitacao de transporte nao solicitado.
 * @param {Object} params - Os parametros da requisicao.
 * @returns {Object} O resultado da requisicao.
 */
export function cancelSolicitacaoTransporte (params, id) {
  return doRequest({
    method: 'put',
    url: `SolicitarCancelamentoSolicitacao/${id}`,
    params
  })
}

/**
 * Metodo de comando, com o objetivo de realizar a requisicao de atualizar uma
 * solicitacao de transporte nao solicitado.
 * @param {Object} params - Os parametros da requisicao.
 * @returns {Object} O resultado da requisicao.
 */
export function alterarTransportador (params, id) {
  return doRequest({
    method: 'put',
    url: `SolicitacaoTransporte/${id}`,
    params
  })
}
