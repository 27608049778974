import moment from 'moment'
import lodash from 'lodash'
import storeHelper from '@/helper/storeHelper'

export default class CreateSolicitacaoTransporteNaoSolicitadoDto {
  constructor (solicitacaoData) {
    const transportadores = storeHelper.getCurrentPerfisTransportador()
    const gerador = lodash.get(solicitacaoData.obra, 'pessoa.perfis', [])
      .find(({ tipoPerfil: { descricao } }) => descricao === 'Gerador')

    this.idTransportador = (transportadores[0] || {}).id
    this.idGerador = (gerador || {}).id
    this.obra = {
      idGerador: this.idGerador,
      nomeObra: solicitacaoData.obra.nomeObra,
      cep: solicitacaoData.obra.cep,
      bairro: solicitacaoData.obra.bairro,
      complemento: solicitacaoData.obra.complemento,
      numero: solicitacaoData.obra.numero,
      shape: solicitacaoData.obra.shape,
      endereco: solicitacaoData.obra.endereco,
      municipio: solicitacaoData.obra.municipio,
      uf: solicitacaoData.obra.uf,
      codIbge: solicitacaoData.obra.codIbge,
      duracaoInicial: solicitacaoData.obra.duracaoInicial ? moment(solicitacaoData.obra.duracaoInicial, 'YYYY-MM-DD').format('YYYY-MM-DD') : null,
      duracaoFinal: solicitacaoData.obra.duracaoFinal ? moment(solicitacaoData.obra.duracaoFinal, 'YYYY-MM-DD').format('YYYY-MM-DD') : null,
      possuiAlvara: solicitacaoData.obra.possuiAlvara,
      numeroAlvara: solicitacaoData.obra.numeroAlvara,
      residuos: solicitacaoData.residuos.map(residuo => ({
        volume: residuo.volume,
        idTipoResiduo: residuo.idTipoResiduo
      })),
      transportadores: [{ data: { idPerfil: transportadores.map(({ id }) => id) } }],
      pessoa: solicitacaoData.obra.pessoa.id ? solicitacaoData.obra.pessoa : {
        situacao: 'Ativo',
        tipoPessoa: solicitacaoData.obra.cpfCnpjSelect.value === 'CPF'
          ? 'F'
          : 'J',
        codIbge: solicitacaoData.obra.codIbge,
        cpfCnpj: solicitacaoData.obra.cpfCnpj,
        nomeRazaoSocial: solicitacaoData.obra.pessoa.nomeRazaoSocial,
        celular: solicitacaoData.obra.pessoa.celular,
        email: solicitacaoData.obra.pessoa.email,
        cep: solicitacaoData.obra.cep,
        endereco: solicitacaoData.obra.endereco,
        bairro: solicitacaoData.obra.bairro,
        complemento: solicitacaoData.obra.complemento,
        numero: solicitacaoData.obra.numero,
        shape: solicitacaoData.obra.shape
      }
    }
    this.idResiduoPredominante = solicitacaoData.tipoResiduoPredominante.tipoResiduo ? solicitacaoData.tipoResiduoPredominante.tipoResiduo.id : solicitacaoData.tipoResiduoPredominante.idTipoResiduo
    this.idVeiculo = solicitacaoData.veiculo.id

    let idCacamba = null
    if (solicitacaoData?.veiculo?.tipo === 'Poliguindaste') {
      idCacamba = solicitacaoData.cacamba.id || solicitacaoData.cacamba
    }
    this.idCacamba = idCacamba
    // this.dataHoraSolicitacao = new Date()
    // this.dataHoraEnvio = new Date()
    this.quantidadeCacambas = solicitacaoData.quantidadeCacambas
    this.naoSolicitado = 'S'
  }
}
