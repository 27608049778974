<template>
  <div class="generic-crud">
    <div v-if="action" class="generic-crud-form form-solicitacao-transporte">
      <h2 v-if="action === 'add'">Incluir Solicitação transporte</h2>
      <div style="margin-bottom: 10px;">
        <dx-button type="info" @click="$router.push('/localizar-ctr')">Ver Solicitações</dx-button>
      </div>

      <fieldset>
        <legend v-if="action === 'add'">Solicitação transporte</legend>
        <legend v-else>Solicitação transporte</legend>
        <simple-form :fields="gridData.columns" :form-options="{ colCount: 1 }" ref="crudForm" v-model="formData"></simple-form>

        <grid-residuos v-model="formData"/>

        <dx-button type="success" @click="save">Salvar</dx-button>
      </fieldset>
    </div>
    <div v-else>
      <div class="md-layout">
        <div class="md-layout-item">
          <h2 class="crud-title">Cadastro de Solicitação transportes</h2>
        </div>

        <div
          v-if="canAdd"
          class="md-layout-item"
          style="text-align: right;">
          <dx-button styling-mode="contained" type="success" @click="$router.push({ path: 'solicitacao-transporte/add' })" text="Adicionar Novo"></dx-button>
        </div>
      </div>
      <simple-grid entity="SolicitacaoTransporte" popup-title="Solicitação transporte" :grid-data="gridData" :editable="false" :deletable="false"></simple-grid>
    </div>
      <md-card v-if="errorMessage" class="error-message-card">
        <md-card-content>{{ errorMessage }}</md-card-content>
      </md-card>
  </div>
</template>
<script>
import { SimpleGrid, SimpleForm } from '@/components'
import CustomStore from 'devextreme/data/custom_store'
import mixinCrud from '../mixin-crud'
import { DxButton } from 'devextreme-vue'
import { mapGetters } from 'vuex'
import { confirm } from 'devextreme/ui/dialog'

import GridResiduos from '../../../components/SolicitacaoTransporte/GridResiduos'
import { hasPermission } from '../../../util/permissions'
import storeHelper from '@/helper/storeHelper'

export default {
  name: 'solicitacao-transporte-crud',
  mixins: [
    mixinCrud
  ],
  components: {
    SimpleGrid,
    SimpleForm,
    DxButton,
    GridResiduos
  },
  data () {
    return {
      errorMessage: null,
      toRemoveResiduos: [],
      tipoResiduo: {
        add: {
          selected: null,
          volume: null
        }
      },
      entity: 'SolicitacaoTransporte',
      formData: { residuos: [] },
      gridData: {
        value: {
          visible: true
        },
        columns: this.parseColumns([
          {
            dataField: 'id',
            visible: false,
            caption: 'id',
            editorType: 'dxNumberBox'
          },
          {
            dataField: 'idObra',
            caption: 'Nome da Obra',
            label: { text: 'Nome da Obra' },
            dataType: 'string',
            editorType: 'dxLookup',
            validationRules: [{ type: 'required' }],
            editorOptions: {
              width: 800,
              acceptCustomValue: true,
              dataSource: new CustomStore({
                key: 'id',
                load: (loadOptions) => {
                  const geradores = storeHelper.getCurrentPerfisGerador()
                  return this.$utils.wrapRequestForGrid(this.find({
                    entity: 'Obra',
                    params: {
                      attributes: ['id', 'nomeObra', 'idGerador'],
                      ...this.isGerador ? {
                        where: {
                          idGerador: (geradores[0] || {}).id
                        }
                      } : {}
                    },
                    loadOptions
                  }))
                },
                byKey: key => {
                  return this.findId({
                    entity: 'Obra',
                    id: key
                  })
                }
              }),
              displayExpr: 'nomeObra',
              valueExpr: 'id',
              maxLength: 100,
              onSelectionChanged: async () => {
                await this.$nextTick(() => {
                  this.$refs.crudForm.instance.itemOption('idTransportador', 'editorOptions', { ...this.$refs.crudForm.instance.editorOptions, disabled: false })
                })
              },
              onItemClick: async (value) => {
                this.$refs.crudForm.instance.updateData('idTransportador', null)
                this.$refs.crudForm.instance.getEditor('idTransportador').getDataSource().load()
                this.$refs.crudForm.instance.itemOption('idTransportador', 'editorOptions', { ...this.$refs.crudForm.instance.editorOptions, disabled: false })

                this.$refs.crudForm.instance.updateData('idGerador', null)

                const { data } = await this.find({
                  entity: 'ObraTipoResiduo',
                  params: {
                    where: {
                      idObra: value.itemData.id
                    },
                    include: JSON.stringify([{
                      model: 'TipoResiduo',
                      as: 'tipoResiduo',
                      required: true,
                      include: [
                        {
                          model: 'ClasseMaterial',
                          as: 'classeMaterial',
                          required: true,
                          include: {
                            model: 'TipoResiduo',
                            as: 'tipoResiduos',
                            required: true
                          }
                        }
                      ]
                    }])
                  }
                })
                this.formData = {
                  ...this.formData,
                  idGerador: value.itemData.idGerador,
                  residuos: data.map(result => ({
                    id: result.id,
                    idTipoResiduo: result.idTipoResiduo,
                    tipoResiduo: result.tipoResiduo,
                    volume: result.volume
                  }))
                }
              }
            }
          },

          {
            dataField: 'idGerador',
            caption: 'Id Gerador',
            label: { text: 'Gerador' },
            visible: false
          },
          {
            dataField: 'idTransportador',
            caption: 'Id Transportador',
            label: { text: 'Transportador' },
            validationRules: [{ type: 'required' }],
            editorType: 'dxLookup',
            editorOptions: {
              activeStateEnabled: true,
              disabled: true,
              noDataText: 'Nenhum transportador cadastrado nesta obra',
              width: 700,
              allowClearing: true,
              showCancelButton: true,
              dataSource: new CustomStore({
                key: 'id',
                load: (loadOptions) => this.$utils.wrapRequestForGrid(this.find({
                  entity: 'ObraTransportador',
                  params: {
                    where: {
                      idObra: this.formData.idObra
                    },
                    include: JSON.stringify([{
                      model: 'Perfil',
                      as: 'perfil',
                      required: true,
                      attributes: ['id'],
                      include: [
                        {
                          model: 'Pessoa',
                          as: 'pessoa',
                          required: true,
                          attributes: ['nomeRazaoSocial', 'cpfCnpj'],
                          duplicating: false
                        }]
                    }])
                  },
                  loadOptions
                })),
                byKey: async (key) => {
                  const result = await this.findId({
                    entity: 'ViewTransportador',
                    id: key
                  })
                  return result
                }

              }),
              displayExpr: 'perfil.pessoa.nomeRazaoSocial',
              valueExpr: 'perfil.id'
            },
            calculateDisplayValue: (data) => {
              return data.idTransportador
            }
          },

          {
            dataField: 'observacao',
            caption: 'Observações',
            label: { text: 'Observações' },
            editorType: 'dxTextArea',
            editorOptions: {
              maxLength: 200
            }
          },

          {
            dataField: 'quantidadeCacamba',
            caption: 'Quantidade de caçambas',
            label: { text: 'Quantidade de caçambas' },
            editorType: 'dxSelectBox',
            visible: false,
            editorOptions: {
              width: 300,
              items: [1, 2, 3, 4, 5],
              value: 1
            }
          },

          {
            dataField: 'idResiduoPredominante',
            caption: 'Resíduo Predominante',
            label: { text: 'Tipo de Resíduo Predominante' },
            editorType: 'dxLookup',
            validationRules: [{ type: 'required' }],
            editorOptions: {
              width: 800,
              acceptCustomValue: true,
              dataSource: new CustomStore({
                key: 'id',
                load: async (loadOptions) => this.$utils.wrapRequestForGrid(this.find({
                  entity: 'ObraTipoResiduo',
                  params: {
                    include: JSON.stringify([{
                      model: 'TipoResiduo',
                      as: 'tipoResiduo',
                      required: true,
                      include: [
                        {
                          model: 'ClasseMaterial',
                          as: 'classeMaterial',
                          required: true
                        }]
                    }])
                  },
                  loadOptions
                })),
                byKey: key => {
                  return this.findId({
                    entity: 'ObraTipoResiduo',
                    params: {
                      include: JSON.stringify([{
                        model: 'TipoResiduo',
                        as: 'tipoResiduo',
                        required: true,
                        include: [
                          {
                            model: 'ClasseMaterial',
                            as: 'classeMaterial',
                            required: true
                          }]
                      }])
                    },
                    id: key
                  })
                }
              }),
              displayExpr: 'tipoResiduo.nome',
              valueExpr: 'id'
            },
            formItem: {
              visible: false
            }
          }
        ]),
        dataSource: new CustomStore({
          key: 'id',
          load: (loadOptions) => this.$utils.wrapRequestForGrid(this.find({ entity: this.entity, loadOptions })),
          insert: (values) => this.insertRow({ entity: this.entity, values }),
          update: (key, values) => this.updateRow({ entity: this.entity, values, key }),
          remove: (key, values) => this.removeRow({ entity: this.entity, key })
        })
      }
    }
  },
  computed: {
    isGerador () {
      return !!this.userData.userTypes.find(userType => userType === 'Gerador')
    },

    /**
     * Valor computado de se o usuario logado pode adicionar uma solicitacao de
     * transporte.
     * @returns {boolean}
     */
    canAdd () {
      return hasPermission('Solicitar Transporte', 'inclui')
    },

    ...mapGetters('Login', ['userData'])
  },
  watch: {
    async action (value) {
      if (value === 'edit') {
        this.$refs.crudForm.instance.itemOption('quantidadeCacamba', 'visible', true)
        await this.refreshGridResiduos()
      }
    }
  },
  methods: {
    save () {
      this.$nextTick(() => {
        const result = confirm('Tem certeza que deseja cadastrar esta solicitação?', 'Atenção!')
        result.then((dialogResult) => {
          if (dialogResult) {
            this.errorMessage = null
            if (!this.formData.quantidadeCacamba) {
              this.formData.quantidadeCacamba = 1
            }
            if (this.isGerador && this.formData.quantidadeCacamba > 1) {
              if (this.formData.residuos.length === 0) {
                this.errorMessage = 'É obrigatório preencher pelo menos um resíduo'
                return
              }

              if (this.formData.residuos.find(r => !r.volume)) {
                this.errorMessage = 'O campo "Volume em M³ é obrigatório'
                return
              }
            }
            if (this.formData.tipoResiduoPredominante && this.formData.tipoResiduoPredominante.idTipoResiduo) {
              this.formData.idResiduoPredominante = this.formData.tipoResiduoPredominante.idTipoResiduo
            } else {
              this.errorMessage = 'Não foi informado o campo de tipo de resíduo predominante.'
              return
            }

            this.errorMessage === null && this.saveItem({ redirect: '/localizar-ctr' })
          }
        })
      })
    },
    async refreshGridResiduos () {
      const residuos = await this.find({
        entity: 'Residuo',
        params: {
          where: {
            id_solicitacao_transporte: parseInt(this.$route.params.id)
          },
          include: JSON.stringify([{
            model: 'TipoResiduo',
            as: 'tipoResiduo',
            required: true,
            include: [
              {
                model: 'ClasseMaterial',
                as: 'classeMaterial',
                required: true
              }]
          }])
        }
      })
      this.formData.residuos = residuos.data.map(residuo => ({
        id: residuo.id,
        idTipoResiduo: residuo.idTipoResiduo,
        tipoResiduo: residuo.tipoResiduo,
        volume: residuo.volume
      }))
    }
  },
  async mounted () {
    if (this.isGerador) {
      this.$refs.crudForm.instance.itemOption('quantidadeCacamba', 'visible', true)
    }
    await this.refreshGridResiduos()
  }
}
</script>

<style scoped lang="scss">
.form-solicitacao-transporte .grid-residuos-solicitacao {
  max-width: 800px;
}

.addClasseForm {
  padding: 11px;
  column-count: 2;
}
.error-message-card {
  background-color: #B00020 !important;
  color: white !important;
}
.md-app-content .md-card {
    margin-right: 16px;
    margin-left: 2px;
    overflow: visible;
    top: 20px;
}
.auxDataGrid{
  padding: 17px;
}
.md-button.md-theme-default.md-raised:not([disabled]).md-accent {
    position: relative;
    left: 85%;
    color: #fff;
    color: var(--md-theme-default-text-primary-on-accent, #fff);
    background-color: #008E32;
    background-color: var(--md-theme-default-accent, #008E32);
}
</style>
