import { getParamsFromLoadOptions } from '../../../util/loadOptionsParams'
import { doRequest } from '../../../pluggables/http'

/**
 * Metodo de comando, com o objetivo de realizar a requisica de obter uma lista
 * de perfis do sistema.
 * @param {Object} loadOptions - Os parametros de busca do datasource do
 * devextreme.
 * @returns {Object} O resultado da requisicao.
 */
export async function getPerfis (loadOptions) {
  const params = getParamsFromLoadOptions(loadOptions)
  params.fields = ['id', 'descricao', 'tipo']

  const res = await doRequest({
    method: 'post',
    url: 'TipoPerfil/find',
    params
  })

  if (!res.success) {
    throw new Error(res.error)
  }

  return { data: res.data.rows, totalCount: res.data.count }
}

/**
 * Metodo de comando, com o objetivo de realizar a requisica de obter um perfil
 * e suas permissoes.
 * @param {number} id - O id do perfil.
 * @returns {Object} O resultado da requisicao.
 */
export async function getPerfilById (id) {
  const result = await doRequest({
    method: 'get',
    url: `TipoPerfil/${id}`,
    params: {
      params: {
        include: JSON.stringify({
          model: 'Permissao',
          as: 'permissoes',
          attributes: [
            'id', 'idFuncionalidade', 'consulta',
            'inclui', 'altera', 'exclui'
          ],
          required: false
        }),
        fields: ['id', 'descricao']
      }
    }
  })

  if (!result.success) {
    throw new Error(result.error)
  }

  return result
}

/**
 * Metodo de comando, com o objetivo de realizar a requisica de obter uma lista
 * de funcionalidades.
 * @returns {Object} O resultado da requisicao.
 */
export async function getFuncionalidades () {
  const res = await doRequest({
    method: 'post',
    url: 'Funcionalidade/find',
    params: {
      subQuery: false,
      where: {
        publico: 'N'
      },
      order: JSON.stringify([['descricao', 'asc']]),
      fields: JSON.stringify([
        'id', 'descricao', 'consultaDisponivel',
        'incluiDisponivel', 'alteraDisponivel', 'excluiDisponivel', 'publico',
        'consultaDisponivel'
      ])
    }
  })

  if (!res.success) {
    throw new Error(res.error)
  }

  return res
}

/**
 * Metodo de comando, com o objetivo de realizar a requisica de salvar as
 * atribuicoes do perfil.
 * @param {Object} params - Os parametros da requisicao.
 * @returns {Object} O resultado da requisicao.
 */
export function saveAtribuicoes (params) {
  return doRequest({
    method: 'put',
    url: 'AtribuicaoPerfil',
    params
  })
}
