import store from '@/store'
import moment from 'moment'

/**
 * Classe responsavel pela definicao da estrutura dos dados de Obra utilizada no
 * formulario de obra.
 */
export default class ObraData {
  constructor (obraServer) {
    const obraParams = obraServer || {}
    this.id = obraParams.id || null
    this.cpfCnpjSelect = obraParams.cpfCnpjSelect || null
    this.cpfCnpj = obraParams.cpfCnpj || null
    this.nome = obraParams.nome || null
    this.pessoa = obraParams.pessoa || null
    this.nomeObra = obraParams.nomeObra || null
    this.duracaoInicial = obraParams.duracaoInicial || null
    this.duracaoFinal = obraParams.duracaoFinal || null
    this.possuiAlvara = 'possuiAlvara' in obraParams
      ? obraParams.possuiAlvara
      : null
    this.numeroAlvara = obraParams.numeroAlvara || null
    this.cep = obraParams.cep || null
    this.uf = obraParams.uf || null
    this.municipio = obraParams.municipio || null
    this.bairro = obraParams.bairro || null
    this.endereco = obraParams.endereco || null
    this.complemento = obraParams.complemento || null
    this.numero = obraParams.numero || null
    this.codIbge = obraParams.codIbge || null
    this.endereco = obraParams.endereco || null
    this.transportadores = obraParams.transportadores || null
    this.residuos = obraParams.residuos || null
    this.observacao = obraParams.observacao || null
    this.shape = obraParams.shape || null
    this.filePgrcc = obraParams.filePgrcc || null
    this.pgrccNomeArquivo = obraParams.pgrccNomeArquivo || null
    this.motivoAlteracao = obraParams.motivoAlteracao || null

    if (!this.pessoa || !obraParams.id) {
      this.pessoa = store.state?.Login?.user?.pessoa || {}
      this.pessoa.perfis = store.state?.Login?.user?.perfis
    }
  }

  /**
   * Metodo para obter os dados da obra no formato de parametro de requisicao.
   * @returns {FormData} Os dados para a requisicao.
   */
  async toRequestParams () {
    const formData = new FormData()

    const file = (this.filePgrcc && this.filePgrcc[0]) || null
    file !== null && formData.set('pgrcc', file)
    file !== null && formData.set('pgrccNomeArquivo', file.name)

    if (this.id !== null) {
      formData.set('id', this.id)
    }

    const perfil = await this._getPerfil()
    perfil && formData.set('idGerador', perfil)
    formData.set('nomeObra', this.nomeObra)
    formData.set('cep', this.cep)
    formData.set('bairro', this.bairro)
    formData.set('codIbge', this.codIbge)
    formData.set('endereco', this.endereco)
    this.complemento && formData.set('complemento', this.complemento)
    this.numero && formData.set('numero', this.numero)
    formData.set('duracaoInicial', this.duracaoInicial ? moment(this.duracaoInicial).valueOf() : null)
    formData.set('duracaoFinal', this.duracaoFinal ? moment(this.duracaoFinal).valueOf() : null)
    formData.set('possuiAlvara', (this.possuiAlvara && this.possuiAlvara.value) ? 1 : 0)
    this.numeroAlvara && formData.set('numeroAlvara', this.numeroAlvara)
    this.observacao && formData.set('observacao', this.observacao)
    this.shape && formData.set('shape', JSON.stringify(this.shape))
    this.motivoAlteracao && formData.set('motivoAlteracao', this.motivoAlteracao)
    formData.set('obraTipoResiduos', JSON.stringify(this.residuos.map(residuo => ({
      volume: residuo.volume,
      idTipoResiduo: residuo.idTipoResiduo
    }))))
    formData.set('obraTransportadores', JSON.stringify(this.transportadores
      .map(transportador => ({
        idPerfil: transportador.data.idPerfil
      }))))
    return formData
  }

  /**
   * Metodo para obter o id do perfil da pessoa.
   * @returns {number} O id do perfil ou null se nao houver
   */
  async _getPerfil () {
    if (this.pessoa) {
      const perfil = this.pessoa.perfis.find(perfil => perfil.idTipoPerfil === 8 || perfil.idTipoPerfil === 1)
      return perfil.id || null
    } else {
      return null
    }
  }
}
