<template>
  <div class="ocorrencia-details">
    <div :class="compactMode ? 'responderOcorrenciaFieldSetCompact' : 'responderOcorrenciaFieldSet'">
      <DxTooltip
        ref="tooltip"
        :visible="false"
      >
        <p>{{toolTipContent}}</p>
      </DxTooltip>
      <h2>Visualizar Resposta da Ocorrência</h2>
      <div class="cols-popup">
        <div class="col-popup">
          <img class="transporteCacambaImageCompact" v-bind:src="'data:image/jpeg;base64,'+image"/>
          <hr>
          <dl>
            <dt>Nº DA OCORRÊNCIA:</dt> <dd>{{values.id}}</dd>
            <dt>Nº DE IDENTIFICAÇÂO:</dt> <dd>{{values.numeroIdentificacao}}</dd>
            <dt>DATA/HORA DO REGISTRO DA OCORRÊNCIA: </dt><dd>{{ dataHoraRegistro }}</dd>
            <dt>LOCAL DA OCORRÊNCIA: </dt><dd>{{ values.local }}</dd>
            <dt>IRREGULARIDADE COM: </dt><dd>{{ values.irregularidadeCom }}</dd>
            <template v-if="values.cacamba">
              <dt>IDENTIFICADOR NA CAÇAMBA: </dt><dd>{{ values.cacamba.numeroIdentificacao }}</dd>
            </template>
            <template v-if="values.veiculo">
              <dt>IDENTIFICADOR NO VEÍCULO: </dt><dd>{{ values.veiculo.numeroIdentificacao }}</dd>
              <dt>PLACA DO VEÍCULO: </dt><dd>{{ values.veiculo.placa }}</dd>
            </template>
          </dl>
          <hr>
        </div>

        <div class="col-popup">
          <div class="form-popup" :class="compactMode ? 'irregularidadeERespostaOrgaoCompact' : 'irregularidadeERespostaOrgao'">
            <label v-if="!compactMode">Descrição da Irregularidade: </label>
            <label v-else>Descrição da Irregularidade: </label>
            <DxTextArea
              class="texAreaIrregularidade"
              :disabled="true"
              :value="values.descricaoIrregularidade"/>

            <label>Improcedente:</label>
            <DxRadioGroup style="width: 140px;"
                          ref="radioGroup"
                          :disabled="values.situacao === 'Respondida' ? true : false"
                          :items="improcedenteValues"
                          v-model="improcedenteSelectedValue"
                          layout="horizontal"
                          />

            <label>Resposta</label>
            <DxTextArea
              :disabled="values.situacao === 'Respondida' ? true : false"
              class="texAreaIrregularidade"
              placeholder="Resposta"
              max-length="500"
              v-model="respostaOcorrencia"/>
          </div>
          <footer>
            <dx-button v-if="!compactMode"
                       class="backButton"
                       @click="$emit('ocorrencia-saved')">
              Voltar
            </dx-button>
            <dx-button v-if="!compactMode"
                       class="limparOcorrenciaRespostaButton"
                       type="danger"
                       @click="clearFields">Limpar
            </dx-button>
            <dx-button
              v-show="values.situacao !== 'Respondida'"
              class="enviarRespostaButton"
              style="background-color: #FF8402;"
              type="success"
              @click="onEnviarRespostaOcorrencia">Enviar resposta
            </dx-button>
            <dx-button
              v-show="values.situacao !== 'Respondida'"
              :class="compactMode ? 'salvarRespostaButton' : 'salvarRespostaButtonCompact'"
              type="success"
              @click="onSalvarRespostaOcorrencia">Salvar
            </dx-button>
          </footer>
        </div>
      </div>
      <DxToast
        class="toastOcorrenciaTransporte"
        :displayTime="4000"
        :visible.sync="showToast"
        :type="toastType"
        :message="warningMessage"
      />
    </div>

  </div>
</template>
<script>
import moment from 'moment'
import DxRadioGroup from 'devextreme-vue/radio-group'
import { DxTextArea } from 'devextreme-vue'
import DxButton from 'devextreme-vue/button'
import { DxToast } from 'devextreme-vue/toast'
import { DxTooltip } from 'devextreme-vue/tooltip'
import {
  enviarRespostaOcorrencia,
  salvarRespostaOcorrencia,
  sendEmail
} from '../service'

export default {
  name: 'view-ocorrencia',
  components: {
    DxTextArea,
    DxRadioGroup,
    DxButton,
    DxToast,
    DxTooltip
  },
  /**
   * Metodo do vue, para obter o estado inicial do componente.
   * @returns {Object} O estado inicial do componente.
   */
  data () {
    return {
      toolTipContent: null,
      dataHoraRegistro: null,
      orgao: null,
      image: null,
      improcedenteValues: ['Sim', 'Não'],
      improcedenteSelectedValue: null,
      respostaOcorrencia: null,
      showToast: null,
      toastType: null,
      warningMessage: null
    }
  },
  props: {
    values: {
      type: Object,
      required: true
    },
    compactMode: {
      type: Boolean
    }
  },
  methods: {
    createToolTipEvents () {
      this.$refs.radioGroup.$el.onmouseenter = () => {
        this.$refs.tooltip.instance.option({
          target: this.$refs.radioGroup.$el,
          visible: true
        })
        this.toolTipContent = 'Caso a ocorrência seja infundada,impossível de compreender o texto ou contenha ofensas, marque a opção “Sim". No entanto, é necessário registrar uma resposta.'
        setTimeout(() => {
          if (this.$refs.tooltip) {
            this.$refs.tooltip.instance.option({
              visible: false
            })
          }
        }, 7000)
      }
      this.$refs.radioGroup.$el.onmouseleave = () => {
        this.$refs.tooltip.instance.option({
          visible: false
        })
      }
      this.$refs.radioGroup.$el.ontouchstart = () => {
        this.$refs.tooltip.instance.option({
          target: this.$refs.radioGroup.$el,
          visible: true
        })
        this.toolTipContent = 'Caso a ocorrência seja infundada \n,impossível de compreender o texto ou contenha ofensas, marque a opção “Sim". No entanto, é necessário registrar uma resposta.'
        setTimeout(() => {
          if (this.$refs.tooltip) {
            this.$refs.tooltip.instance.option({
              visible: false
            })
          }
        }, 7000)
      }
      this.$refs.radioGroup.$el.ontouchcancel = () => {
        this.$refs.tooltip.instance.option({
          visible: false
        })
      }
    },
    clearFields () {
      if (confirm('Tem certeza que deseja limpar os campos?')) {
        this.respostaOcorrencia = null
        this.improcedenteSelectedValue = null
      }
    },
    async onEnviarRespostaOcorrencia () {
      let improcedenteValue
      if (this.improcedenteSelectedValue === 'Sim') {
        improcedenteValue = 'S'
      }
      if (this.improcedenteSelectedValue === 'Não') {
        improcedenteValue = 'N'
      }
      try {
        await sendEmail({
          resposta: this.respostaOcorrencia,
          email: this.values.emailUsuarioRegistro
        })
        const result = await enviarRespostaOcorrencia({
          resposta: this.respostaOcorrencia,
          improcedente: improcedenteValue,
          id: this.values.id
        })
        if (result.success) {
          this.showToast = true
          this.toastType = 'success'
          this.warningMessage = 'Resposta enviada'
          this.respostaOcorrencia = null
          this.improcedenteSelectedValue = null
          this.$emit('ocorrencia-saved')
        }
      } catch (err) {
        this.$utils.notifyError(err, 'Não foi possível enviar uma resposta')
        console.error(err)
      }
    },

    async onSalvarRespostaOcorrencia () {
      let improcedenteValue
      if (this.improcedenteSelectedValue === 'Sim') {
        improcedenteValue = 'S'
      }
      if (this.improcedenteSelectedValue === 'Não') {
        improcedenteValue = 'N'
      }
      try {
        const result = await salvarRespostaOcorrencia({
          resposta: this.respostaOcorrencia,
          improcedente: improcedenteValue,
          id: this.values.id
        })
        if (result.success) {
          this.showToast = true
          this.toastType = 'success'
          this.warningMessage = 'Resposta salva'
          this.$emit('ocorrencia-saved')
        }
      } catch (err) {
        this.$utils.notifyError(err, 'Não foi possível salvar uma resposta')
        console.error(err)
      }
    },

    bytesBufferToBase64 (buffer) {
      let binary = ''
      const bytes = new Uint8Array(buffer)
      const len = bytes.byteLength
      for (let i = 0; i < len; i += 1) {
        binary += String.fromCharCode(bytes[i])
      }
      return btoa(binary)
    }
  },
  async mounted () {
    this.createToolTipEvents()
    this.dataHoraRegistro = moment(this.values.dataHoraRegistro).format('DD/MM/YYYY HH:mm:ss')
    if (this.values.cacamba && this.values.cacamba.perfil) {
      this.orgao = this.values.cacamba.perfil.orgao
    }
    this.image = this.bytesBufferToBase64(this.values.foto.data)
    if (this.values.respostaOrgao) {
      this.respostaOcorrencia = this.values.respostaOrgao
    }
    let improcedenteValue
    if (this.values.improcedente) {
      if (this.values.improcedente === 'S') {
        improcedenteValue = 'Sim'
      }
      if (this.values.improcedente === 'N') {
        improcedenteValue = 'Não'
      }
      this.improcedenteSelectedValue = improcedenteValue
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../../global.scss';
.dx-popover-wrapper .dx-popup-content {
  white-space: normal!important;
}
.dx-button {
  min-width: 70px !important;
}
</style>
