import Vue from 'vue'
import Router from 'vue-router'

import routes from './routes'
import store from '@/store'
import { hasPermission } from '../util/permissions'

Vue.use(Router)

const router = new Router({
  linkActiveClass: 'active',
  routes
})

const allowedPublicRoutes = ['/transportadores', '/destino-rapido']
router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.state.Login.token === null) {
      next({
        path: '/',
        params: { nextUrl: to.fullPath }
      })
    } else if (hasPermission(to.meta.title, to.meta.permissionType)) {
      next()
    } else {
      // @TODO: homepage
      next({ path: '/' })
    }
  } else if (to.matched.some(record => record.meta.guest)) {
    if (store.state.Login.token === null || allowedPublicRoutes.includes(to.path)) {
      next()
    } else if (['Destino Rapido'].includes(to.name)) {
      next()
    } else {
      next({ path: '/' })
    }
  } else {
    next()
  }
})

export default router
