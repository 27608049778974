<template>
  <div>
    <h2>{{ canEdit ? title : 'Visualizar Obra' }}</h2>
    <md-steppers
      v-if="obra !== null"
      :md-active-step.sync="steps.current"
      md-linear>
      <md-step
        id="obra"
        md-label="Obra"
        :md-done.sync="steps.obraDone">
        <maintain-step-obra
          v-model="obra"
          :read-only="!canEdit"
          @next="onObraClickNext"
          @cancel="onObraClickCancel"
          @save="onObraClickSave"
          @change-field-value="onObraStepChangeFieldValue"/>
      </md-step>

      <md-step
        id="materialTransportador"
        md-label="Material e Transportador"
        :md-done.sync="steps.materialTransportadorDone">
        <maintain-step-material-transportador
          v-model="obra"
          :read-only="!canEdit"
          @back="onObraClickBack"
          @cancel="onObraClickCancel"
          @save="onMaterialTransportadorClickNext"/>
      </md-step>

    </md-steppers>
    <md-card v-if="errorMessage" class="error-message-card">
      <md-card-content>{{ errorMessage }}</md-card-content>
    </md-card>
  </div>
</template>

<script>
import MaintainStepObra from './MaintainStepObra'
import MaintainStepMaterialTransportador from './MaintainStepMaterialTransportador'
import ObraData from './ObraData'
import { getObraById, saveObra } from '../service'
import { hasPermission } from '../../../../util/permissions'

import { mapActions } from 'vuex'

export default {
  name: 'maintain-obra',
  components: {
    MaintainStepObra,
    MaintainStepMaterialTransportador
  },
  /**
   * Metodo do vue para obter o estado inicial do componente.
   * @returns {Object} O estado inicial do componente.
   */
  data () {
    return {
      steps: {
        current: 'obra',
        obraDone: !!this.$route.params.id,
        materialTransportadorDone: !!this.$route.params.id
      },
      obra: null,
      errorMessage: null
    }
  },
  computed: {
    /**
     * Valor computado do titulo da pagina.
     * @returns {String}
     */
    title () {
      return !this.$route.params.id ? 'Adicionar Obra' : 'Editar Obra'
    },

    /**
     * Valor computado de se o usuario logado tem permissao para editar obras.
     * @returns {boolean}
     */
    canEdit () {
      return (hasPermission('Manter Obra', 'altera') && this.$route.name === 'obra-edit') ||
        (hasPermission('Manter Obra', 'inclui') && this.$route.name === 'obra-add')
    }
  },
  /**
   * Metodo do ciclo de vida do vue, de quando o componente foi montado na tela.
   * Com o objetivo de inicializar os dados da obra.
   */
  mounted () {
    this._setObraData()
  },
  methods: {
    /**
     * Metodo de callback, de quando na aba de obra e clicado no botao de ir
     * para a proxima pagina. Com o objetivo de mudar o componente de step para
     * a proxima pagina.
     */
    onObraClickBack () {
      this.steps.current = 'obra'
    },

    /**
     * Metodo de callback de quando e clicado no botao de cancelar. Com o
     * objetivo de voltar para a tela de listagem de obras.
     */
    onObraClickCancel () {
      if (this.canEdit) {
        const dialogResult = confirm('Tem certeza que deseja cancelar?', 'Atenção!')
        if (dialogResult) {
          this.$router.push({ path: '/obra' })
        }
      } else {
        this.$router.push({ path: '/obra' })
      }
    },
    /**
     * Metodo de callback, de quando na aba de obra e clicado no botao de ir
     * para a proxima pagina. Com o objetivo de mudar o componente de step para
     * a proxima pagina.
     */
    onObraClickNext () {
      this.steps.current = 'materialTransportador'
      this.steps.obraDone = true
    },

    /**
     * Metodo de callback, de quando na aba de obra e clicado no botao de salvar.
     * Com o objetivo de salvar os dados da obra.
     */
    onObraClickSave () {
      this._saveObraData()
    },

    /**
     * Metodo de callback, de quando na aba de material e transportador e
     * clicado no botao de salvar. Com o objetivo de salvar os dados da obra.
     */
    onMaterialTransportadorClickNext () {
      this._saveObraData()
    },

    /**
     * Metodo de callback, de quando ocorre mudanca de valor de algum campo na
     * aba de obra. Com o objetivo de alterar a flag de aba concluida para false.
     */
    onObraStepChangeFieldValue () {
      this.steps.materialTransportadorDone = false
    },

    /**
     * Metodo de comando, com o objetivo de salvar os dados da obra.
     */
    async _saveObraData () {
      this.errorMessage = null
      this.setLoading(true)
      const res = await saveObra(await this.obra.toRequestParams())

      if (res.success) {
        this.$router.push({ path: '/obra' })
      } else {
        this.errorMessage = res.error
      }
      this.setLoading(false)
    },

    /**
     * Metodo de comando, com o objetivo de inicializar os dados da obra.
     */
    async _setObraData () {
      this.errorMessage = null
      this.setLoading(true)
      const { id } = this.$route.params
      if (id) {
        try {
          const res = await getObraById(id)

          if (!res.success) {
            throw new Error(res.error)
          }

          if (!res.data || !res.data.obra) {
            throw new Error('Não foi encontrada a obra para edição')
          }

          this.obra = new ObraData(res.data.toObraDataParams())
        } catch (e) {
          console.error(e)
          this.errorMessage = e.message
        }
      } else {
        this.obra = new ObraData()
      }
      this.setLoading(false)
    },

    ...mapActions('Crud', ['setLoading'])
  }
}
</script>
