<template>
  <div class="md-layout md-gutter md-alignment-center">
    <md-card style="margin: 30px auto;" class="md-layout-item md-size-50 md-medium-size-50 md-small-size-90" novalidate ref="loginForm">
      <div v-show="loginCheck">
        <div class="md-layout md-gutter md-layout-item md-alignment-space-between-right">
          <a href="https://www.slu.df.gov.br" target="_blank"><img id="slu_logo" style="height: 85px;" src="logo_rcc.jpg"></a>
        </div>
        <md-card-header>
          <div class="md-title">Login</div>
        </md-card-header>
        <md-card-content>
          <md-field :class="formError ? 'md-invalid' : ''">
            <label for="inputUser" class="sr-only">E-mail</label>
            <md-input
              @keyup.prevent.enter="login"
              ref="userInput" type="text"
              id="inputUser"
              v-model="username"
              autofocus
              class="form-control"
              placeholder="Seu e-mail"
              required
              @focusout="emailFocusOut"/>
          </md-field>

          <md-field :class="formError ? 'md-invalid' : ''">
            <label for="inputPassword" class="sr-only">Senha</label>
            <md-input
              @keyup.prevent.enter="login"
              ref="passwordInput"
              type="password"
              v-model="password"
              id="inputPassword"
              class="form-control"
              placeholder="Senha"
              required
            />
          </md-field>

          <div class="md-layout-item">
            <label>Acessar como:</label>
            <dx-lookup
              ref="pessoaLookup"
              placeholder="Acessar como"
              v-model="selectedPessoa"
              v-bind="pessoaLookup"
            />
          </div>
          <md-snackbar :md-duration="8000" :md-active="updateS">Senha Atualizada con sucesso.</md-snackbar>
        </md-card-content>

        <md-card-actions>
          <md-button :disabled="!selectedPessoa" class="md-raised md-primary" @click="login">Entrar</md-button>
        </md-card-actions>
        <div class="md-layout md-gutter md-layout-item md-alignment-space-between-center">
          <md-button class="md-primary" @click="passwordRecovery" mat-button color="primary" style="cursor: pointer">Esqueci minha senha</md-button>
          <md-button class="md-primary" @click="CadastrarAction" style="cursor: pointer">Ainda não sou cadastrado</md-button>
          <md-button class="md-primary" @click="DestinoRapidoAction" style="cursor: pointer">Acesso ao destino rápido</md-button>
        </div>

      </div>

      <div v-show="passwordRecoveryCheck">
        <md-card-header>
          <div class="md-title">Recuperação de Senha</div>
        </md-card-header>
        <md-card-content>
          <md-field :class="formError ? 'md-invalid' : ''">
            <label for="inputUserRecovery" class="sr-only">Usuário (CPF ou e-mail):</label>
            <md-input
              ref="userInput"
              type="text"
              id="inputUserRecovery"
              v-model="username"
              class="form-control"
              placeholder="Seu e-mail"
              required
              autofocus
              @focusout="emailRecoveryFocusOut"/>
          </md-field>
          <div class="md-layout-item">
            <label>Acessar como:</label>
            <dx-lookup
              ref="pessoaRecoveryLookup"
              placeholder="Acessar como"
              v-model="selectedPessoa"
              v-bind="pessoaLookup"
            />
          </div>
          <md-snackbar :md-duration="8000" :md-active="formErrorR">
            Não foi possível encontrar o Usuário
          </md-snackbar>
          <md-snackbar :md-duration="8000" :md-active="formErrorRu">
            Por favor digite o E-mail ou CPF para recuperar a senha.
          </md-snackbar>
        </md-card-content>

        <md-card-actions>
          <md-button :disabled="!selectedPessoa" class="md-raised md-primary" @click="passwordRecoveryAction">Recuperar</md-button>
          <md-button class="md-raised md-primary" @click="cancelAction">Cancelar</md-button>
        </md-card-actions>
      </div>

      <div v-show="passwordUpdateCheck">
        <md-card-header>
          <div class="md-title">Atualização de Senha</div>
        </md-card-header>
        <md-card-content>
          <md-field :class="formError ? 'md-invalid' : ''">
            <label for="passwordUpdate" class="sr-only">Senha:</label>
            <md-input ref="passwordInput" type="password" v-model="password" id="passwordUpdate" class="form-control"
                      placeholder="Senha" required autofocus></md-input>
          </md-field>

          <md-field :class="formError ? 'md-invalid' : ''">
            <label for="confirmPassword" class="sr-only">Confirma Senha:</label>
            <md-input ref="passwordInput" type="password" v-model="confirmPassword" id="confirmPassword"
                      class="form-control" placeholder="Confirma Senha" required></md-input>
          </md-field>

          <md-snackbar :md-duration="8000" :md-active="formErrorUp">
            Verifique os dados para atualização.
          </md-snackbar>
          <md-snackbar :md-duration="8000" :md-active="formErrorPC">
            A senha inserida nao conferem.
          </md-snackbar>
          <md-snackbar :md-duration="8000" :md-active="formErrorUe">
            Não foi possível atualizar a senha.
          </md-snackbar>
        </md-card-content>

        <md-card-actions>
          <md-button class="md-raised md-primary" @click="passwordUpdateAction">Recuperar</md-button>
          <md-button class="md-raised md-primary" @click="cancelAction">Cancelar</md-button>
        </md-card-actions>
      </div>

      <div v-show="confirmEmailCheck">
        <md-card-header>
          <div class="md-title">Confirmação de E-mail</div>
        </md-card-header>
        <md-card-content>
          <div>
            <p v-show="confirmedEmail">
              Olá, seu email foi confirmado e seus dados serão validados para que você possa acessar o sistema.
            </p>
            <p v-show="confirmedEmailError">
              Olá, email não pode ser confirmado.
            </p>
            <p v-show="confirmedEmailWait">
              Olá, estamos confirmando o E-mail.
            </p>
          </div>
        </md-card-content>

        <md-card-actions>
          <md-button class="md-raised md-primary" @click="cancelAction">Login</md-button>
        </md-card-actions>
      </div>

      <div v-show="confirmedMsgCheck">
        <md-card-header>
          <div class="md-title">Aguardando confirmação de E-mail</div>
        </md-card-header>
        <md-card-content>
          <div>
            <p>
              Olá, você receberá um email para confirmação do seu cadastros. Por favor verifique sua caixa de email.
            </p>
          </div>
        </md-card-content>

        <md-card-actions>
          <md-button class="md-raised md-primary" @click="cancelAction">Login</md-button>
        </md-card-actions>
      </div>

    </md-card>
    <div v-if="showModal">
      <dx-popup
        :show-title="true"
        width="90vw"
        height="90vh"
        title="Informações Complementares"
        :hide-on-outside-click="false"
        :drag-enabled="false"
        :visible.sync="showModal"
      >
        <dx-scroll-view>
          <auto-cadastro
            :auto-cadastro="true"
            :login="loginValue"
            :modal.sync="showModal"
            @save-sucess="returnSave($event)"/>
        </dx-scroll-view>
      </dx-popup>
    </div>
    <div v-show="showProgressBarLoading" style="position: fixed; width: 100%; top:0">
      <md-progress-bar class="md-primary" md-mode="indeterminate"></md-progress-bar>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import config from 'config'
import { mapActions, mapGetters } from 'vuex'
import { DxPopup, DxScrollView } from 'devextreme-vue'
import AutoCadastro from '@/pages/Cruds/Pessoa/PaginaFormPessoa'
import Swal from 'sweetalert2'
import notify from 'devextreme/ui/notify'
import mixinCrud from '@/pages/Cruds/mixin-crud'
import { DxLookup } from 'devextreme-vue/lookup'
import CustomStore from 'devextreme/data/custom_store'
import { http } from '@/pluggables/http'

export default {
  name: 'login',
  mixins: [
    mixinCrud
  ],
  components: {
    AutoCadastro,
    DxPopup,
    DxScrollView,
    DxLookup
    /*   GoogleLogin,
    VFacebookLogin */
  },
  provide () {
    return { closeModalClearAll: this.clearModal }
  },
  props: {},
  data () {
    return {
      clearModal: {
        value: true
      },
      loginValue: true,
      formError: false,
      formErrorR: false,
      formErrorRu: false,
      formErrorC: false,
      formErrorS: false,
      formErrorUp: false,
      formErrorPC: false,
      formErrorUe: false,
      confirmedEmail: false,
      confirmedEmailError: false,
      confirmedEmailWait: false,
      confirmedMSGEmail: false,
      updateS: false,
      showModal: false,
      username: '',
      password: '',
      userEmail: '',
      confirmPassword: '',
      idAuth: '',
      warningMessage: '',
      warningType: '',
      loginCheck: true,
      createLoginCheck: false,
      passwordRecoveryCheck: false,
      passwordUpdateCheck: false,
      confirmEmailCheck: false,
      confirmedMsgCheck: false,
      saveValues: null,
      paramsGoogle: {
        client_id: null,
        hl: null
      },
      googleValues: {},
      faceBookID: null,
      faceBookVersion: null,
      FB: {},
      showProgressBarLoading: false,
      selectedPessoa: null,
      pessoaLookup: {
        stylingMode: 'outlined',
        dataSource: {
          key: 'id',
          store: new CustomStore({
            load: async () => {
              this.showProgressBarLoading = true
              const { data: pessoas } = await http.post(`${config.baseUrl}/find-available-pessoa`, { email: this.username })
              this.showProgressBarLoading = false
              return pessoas.rows
            },
            byKey: key => key
          }),
          paginate: true,
          pageSize: 10,
          reshapeOnPush: true
        },
        dropDownOptions: {
          closeOnOutsideClick: true
        },
        displayExpr: (pessoa) => {
          if (pessoa) {
            const tipoPessoa = {
              F: 'Pessoa Física',
              J: 'Pessoa Jurídica'
            }[pessoa.tipo_pessoa]
            return tipoPessoa ? `${pessoa.nome_razao_social} (${tipoPessoa})` : `${pessoa.nome_razao_social}`
          }
        },
        valueExpr: 'id',
        noDataText: 'Nenhuma pessoa encontrada, por favor, verifique o e-mail'
      },
      loadingVisible: false
    }
  },
  watch: {
    showModal (value) {
      this.clearModal.value = value
    }
  },
  computed: {
    isSignedIn () {
      const result = this.$gapi.isSignedIn()
      return result
    },
    ...mapGetters('Login', { userData: 'userData' })
  },
  beforeMount () {
    /**
     * Metodo responsavel pela setar configuracao para o Google.
     */
    // console.log(config)
    this.paramsGoogle.client_id = config.login.google.client_id
    this.paramsGoogle.hl = config.login.google.hl
    this.paramsGoogle.discoveryDocs = config.login.google.discoveryDocs
    this.paramsGoogle.scope = config.login.google.scope
    this.paramsGoogle.refreshToken = true

    /**
     * Metodo responsavel pela setar configuracao para o Google.
     */
    this.faceBookID = config.login.facebook.client_id
    this.faceBookVersion = config.login.facebook.version
  },
  async mounted () {
    await this.init()
  },
  methods: {
    async init () {
      Vue.component('modal', { template: '#modal-template' })

      await this.logoutAction()
      if (this.$route.name === 'recovery') {
        /**
         * Metodo responsavel por verificar
         *  link de recuperar senha.
         */
        this.passwordUpdateCheck = true
        this.confirmEmailCheck = false
        this.loginCheck = false
      } else if (this.$route.name === 'confirmed') {
        /**
         * Metodo responsavel por confirmar o
         *  E-mail.
         */
        this.confirmEmailCheck = true
        this.passwordUpdateCheck = false
        this.loginCheck = false
        this.confirmedEmailWait = true

        this.confirmedEmailAction({
          hash: this.$route.params.hash,
          id: this.$route.params.id
        }).then(() => {
          this.confirmEmailCheck = true
          this.confirmedEmail = true
          this.confirmedEmailError = false
          this.confirmedEmailWait = false
          // this.cancelAction()
          setTimeout(() => {
            this.$router.push('/')
            this.$router.go()
          }, 4000)
        })
          .catch(err => {
            this.confirmedEmail = false
            this.confirmedEmailError = true
            this.confirmedEmailWait = false
            console.error(err)
          })
      }
    },
    async login (param) {
      const paramCheckLogin = {
        username: this.username,
        password: this.password,
        idPessoa: this.selectedPessoa,
        type: null,
        idAuth: null,
        accessToken: null
      }

      switch (param.type) {
        case 'google' :
          paramCheckLogin.username = param.username
          paramCheckLogin.type = param.type
          paramCheckLogin.idAuth = param.Da
          paramCheckLogin.accessToken = param.accessToken
          break
        case 'facebook' :
          paramCheckLogin.username = param.username
          paramCheckLogin.type = param.type
          paramCheckLogin.idAuth = param.id ? param.id : param.Da
          paramCheckLogin.accessToken = param.accessToken
          break
        default:
          if (!this.password || !this.username) {
            Swal.fire({
              icon: 'error',
              text: 'Usuário ou senha não conferem ou seus dados ainda não foram validados'
            })
            this.formError = true
            return
          }
      }
      try {
        if (!this.selectedPessoa) {
          Swal.fire({
            icon: 'warning',
            text: 'Por favor selecione uma pessoa'
          })
        } else {
          await this.loginAction(paramCheckLogin)
        }
      } catch (err) {
        if (param.type === 'google' || param.type === 'facebook') {
          // this.username = param.username
          this.userEmail = param.username
          this.idAuth = param.type === 'google' ? param.Da : param.id
          this.createLogin()
          this.showModal = true
        } else {
          this.formError = true
          Swal.fire({
            icon: 'error',
            text: 'Usuário ou senha não conferem ou seus dados ainda não foram validados'
          })
          this.password = null
          // eslint-disable-next-line
          console.error(err)
        }
      }
    },
    createLogin () {
      this.formError = false
      this.loginCheck = false
      this.createLoginCheck = true
    },
    passwordRecovery () {
      this.formError = false
      this.loginCheck = false
      this.passwordRecoveryCheck = true
      this.selectedPessoa = null
      this.username = null
    },
    cancelAction () {
      this.formError = false
      this.loginCheck = true
      this.passwordRecoveryCheck = false
      this.passwordUpdateCheck = false
      this.createLoginCheck = false
      this.confirmedMsgCheck = false
      this.confirmEmailCheck = false
      this.username = null
      this.password = null
      this.userEmail = null
      this.selectedPessoa = null
    },

    confirmedMsg () {
      this.confirmedMsgCheck = true
      this.passwordRecoveryCheck = false
      this.passwordUpdateCheck = false
      this.createLoginCheck = false
      this.loginCheck = false
      this.showModal = false
    },
    resertErrorMSG () {
      this.formError = false
      this.formErrorR = false
      this.formErrorRu = false
      this.formErrorC = false
      this.formErrorS = false
      this.formErrorUp = false
      this.formErrorPC = false
      this.formErrorUe = false
    },

    returnSave (values) {
      /**
       *  valor vindo apos salvar - auto cadastro
       * obs: verificar os valores obtidos e setar em
       * saveValues = {id, email, username}
       * esse objeto e utilizado para enviar email de confirmação
       * criar aqui retorno de msg caso seja necessario
       * chama o metodo para enviar o email de confirmação
       * @param {object} param passar o {id, email, username}
       */
      if (values) {
        if (values.pessoa !== undefined) {
          if (this.idAuth && values.pessoa.tipoPessoa === 'F') {
            const valuesLogin = {}
            valuesLogin.username = this.userEmail
            valuesLogin.email = this.userEmail
            valuesLogin.Da = this.idAuth
            const reg = /^[a-z0-9.]+@gmail.com?$/i
            if (this.googleValues && reg.test(values.pessoa.email)) {
              valuesLogin.accessToken = this.googleValues.access_token
              valuesLogin.type = 'google'
            } else {
              valuesLogin.accessToken = this.FB.token
              valuesLogin.type = 'facebook'
            }
            setTimeout(() => {
              this.login(valuesLogin)
            }, 2000)
          } else {
            /** chamar tela de msg confirmacao */
            this.confirmedMsg()
            // this.$router.go()
          }
        } else if (values.ativo === 'N') {
          this.confirmedMsg()
          // this.$router.go()
        }
      }
    },
    CadastrarAction () {
      /* chamar componete de auto cadastro #autoCadastro
      * feature/uc-001-usuarios
      * e apos chamar o emvio de email */
      this.showModal = true // modal de auto cadastro
    },
    DestinoRapidoAction () {
      this.$router.push('/destino-rapido')
    },
    async passwordRecoveryAction () {
      if (!this.username) {
        this.formError = true
        this.formErrorRu = true
        setTimeout(() => {
          this.resertErrorMSG()
        }, 8000)
        return
      }
      this.showProgressBarLoading = true
      if (!this.selectedPessoa) {
        Swal.fire({
          icon: 'warning',
          text: 'Por favor selecione uma pessoa'
        })
      } else {
        await this.recoveryAction({ email: this.username, idPessoa: this.selectedPessoa }).then(async () => {
          this.showProgressBarLoading = false
          notify(`Uma nova senha foi enviada para o usuário ${this.username}`, 'success', 4000)
          setTimeout(() => {
            this.$router.push('/')
            this.$router.go()
          }, 1300)
        })
          .catch(err => {
            this.showProgressBarLoading = false
            this.formError = true
            this.formErrorR = true
            console.error(err)
            setTimeout(() => {
              this.resertErrorMSG()
            }, 8000)
          })
      }
    },
    async passwordUpdateAction () {
      if (!this.password || !this.confirmPassword) {
        this.formError = true
        this.formErrorUp = true
        setTimeout(() => {
          this.resertErrorMSG()
        }, 8000)
      }
      if (
        (this.password === this.confirmPassword) &&
        (this.password && this.password !== '')
      ) {
        /**  metodo para atualizar senha  */
        const params = {
          hash: this.$route.params.hash,
          id: this.$route.params.id,
          password: this.password
        }
        await this.passwordUpadateAction(params).then(() => {
          this.updateS = true
          this.cancelAction()
          this.$router.push('/')
          this.$router.go()
        })
          .catch(err => {
            this.formError = true
            this.formErrorUe = true
            console.error(err)
            setTimeout(() => {
              this.resertErrorMSG()
            }, 8000)
          })
      } else {
        this.formError = true
        this.formErrorPC = true
        setTimeout(() => {
          this.resertErrorMSG()
        }, 8000)
      }
    },
    async emailFocusOut () {
      if (this.username.length) {
        this.selectedPessoa = null
        const pessoas = await this.$refs.pessoaLookup.instance.getDataSource().load()
        if (pessoas.length === 1) {
          this.selectedPessoa = pessoas[0].id
        }
      }
    },
    async emailRecoveryFocusOut () {
      if (this.username.length) {
        this.selectedPessoa = null
        const pessoas = await this.$refs.pessoaRecoveryLookup.instance.getDataSource().load()
        if (pessoas.length === 1) {
          this.selectedPessoa = pessoas[0].id
        }
      }
    },
    ...mapActions('Login', ['loginAction', 'logoutAction', 'recoveryAction', 'passwordUpadateAction', 'confirmedEmailAction'])
  }
}
</script>

<style lang="scss" scoped>
html,
body {
  height: 100%;
}

body {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}

.is-invalid {
  border-color: red;
}

.invalid-feedback {
  display: none;

  &.is-invalid {
    display: block;
    color: red;
  }
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}

.form-signin .form-control:focus {
  z-index: 2;
}

.form-signin input[type="text"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border: 1px solid #c4c4c4;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border: 1px solid #c4c4c4;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

hr {
  margin: 0;
}

.link {
  display: block;
  width: 100%;
}

.md-card-content:last-of-type {
  padding-bottom: 16px;
}

div.or-divider {
  display: flex;
  align-items: center;
  text-align: center;
}

div.or-divider::before,
div.or-divider::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #e1e1e1;
  margin: 24px 0;
}

div.or-divider::before {
  margin-right: 16px;
}

div.or-divider::after {
  margin-left: 16px;
}

.signin-button {
  width: 120px;
  height: 38px;
  border-radius: 4px;
  padding: 4px 10px;
  background-color: #fff;
  color: #303030;
  white-space: nowrap;
  border: 1px solid #dbdbdb;
  line-height: 23px;
}

.signin-button img {
  margin-left: -16px;
  margin-right: 12px;
}

.signin-button span {
  font-size: 14px;
  font-weight: 500;
}

.flex-container {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}

.flex-container > div {
  width: 50%;
  margin: 10px;
  text-align: center;
  font-size: 30px;
}

</style>
