<template>
  <div class="enviar-solicitacao-transporte-nao-solicitado">
    <VuexWrapper ref="vuexWrapper" />
    <DxButton
      text="Ver Transportes Não solicitados"
      @click="$router.push('/enviar-transporte-nao-solicitado')"
    />
    <DxLoadPanel
      :visible.sync="loading.visible"
      :show-indicator="true"
      :show-pane="true"
      :shading="true"
      :message="loading.message"
      :hide-on-outside-click="false"
      shading-color="rgba(0,0,0,0.4)"
    />
    <form @submit="sendSolicitacaoTransporte">
      <DxForm
        validationGroup="transporte"
        :form-data.sync="formData"
        :read-only="false"
        :show-colon-after-label="true"
        :show-validation-summary="false"
        ref="formInstance">
        <DxGroupItem caption="Gerador" :col-count="4">
          <DxSimpleItem
            v-for="(geradorField,index) in geradorFields"
            :key="index"
            v-bind="geradorField"
          />
        </DxGroupItem>
        <DxGroupItem caption="Obra" :col-count="4">
          <DxSimpleItem
            v-for="(selectObraField) in selectObraFields"
            :key="selectObraField.dataField"
            v-bind="selectObraField"
          />
          <DxSimpleItem
            v-for="(enderecoField) in enderecoFields"
            :key="enderecoField.dataField"
            v-bind="enderecoField"
          />
          <DxSimpleItem
            v-for="(obraField) in obraFields"
            :key="obraField.dataField"
            v-bind="obraField"
          />
        </DxGroupItem>
        <DxGroupItem caption="" :col-count="4">
          <DxSimpleItem
            v-for="(solicitacaoTransporteField) in solicitacaoTransporteFields"
            :key="solicitacaoTransporteField.dataField"
            v-bind="solicitacaoTransporteField"
          />
        </DxGroupItem>
        <DxGroupItem :col-count="2">
          <DxSimpleItem :col-span="2" v-bind="hiddenDataGridField" />
          <DxSimpleItem :col-span="2">
            <template #default>
              <div>
                <grid-residuos-new ref="gridResiduos" @change="gridResiduosChange"/>
              </div>
            </template>
          </DxSimpleItem>
        </DxGroupItem>
        <DxGroupItem :col-count="4">
          <DxSimpleItem
            v-for="(solicitacaoTransporteField) in veiculoFields"
            :key="solicitacaoTransporteField.dataField"
            v-bind="solicitacaoTransporteField"
          />
        </DxGroupItem>
        <DxGroupItem caption=" " :col-count="1">
          <DxSimpleItem :visible="showProgressBarLoading">
            <template #default>
              <div v-show="showProgressBarLoading" style="position: fixed; width: 90%; left: 50%; transform: translateX(-50%);">
                <md-progress-bar class="md-primary" md-mode="indeterminate"></md-progress-bar>
              </div>
            </template>
          </DxSimpleItem>
          <DxButtonItem
            :button-options="{
              text: 'Salvar',
              type: 'success',
              useSubmitBehavior: true,
              icon: 'hidden-icon mdi mdi-truck-delivery-outline',
              onClick: clickEnviarTransporte,
              disabled: showProgressBarLoading
            }"
            cssClass="enviar-transporte-icon"
            horizontal-alignment="right"
          />
        </DxGroupItem>
      </DxForm>
    </form>
    <DxValidationSummary @itemClick="clickValidationError" validationGroup="transporte"/>
  </div>
</template>
<script setup>
import { nextTick, reactive, ref } from 'vue'
import DxForm, { DxButtonItem, DxGroupItem, DxSimpleItem } from 'devextreme-vue/form'
import { cpfCnpjValid, getCepGeometry } from '@/helper/general'
import { doRequest, http } from '@/pluggables/http'
import config from 'config'
import GridResiduosNew from '@/components/General/GridResiduosNew'
import CustomStore from 'devextreme/data/custom_store'
import { getParamsFromLoadOptions } from '@/util/loadOptionsParams'
import storeHelper from '@/helper/storeHelper'
import { DxLoadPanel } from 'devextreme-vue/load-panel'
import notify from 'devextreme/ui/notify'
import DxValidationSummary from 'devextreme-vue/validation-summary'
import DxButton from 'devextreme-vue/button'
import VuexWrapper from '@/helper/VuexWrapper'

const vuexWrapper = ref()
const showProgressBarLoading = ref(false)
/**
 * TODO
 * @param data
 */
const clickValidationError = (data) => {
  let element = data.itemData.validator.$element()[0]
  if (data.itemData.text === 'Nenhum resíduo foi inserido') {
    element = document.getElementsByClassName('residuos-grid')[0].querySelector('.dx-datagrid')
  }
  element.classList.add('flash')
  setTimeout(() => {
    element.classList.remove('flash')
  }, 1200)
}
const gridResiduos = ref()
const loading = ref({ message: 'Carregando...', visible: false })

const transportadores = storeHelper.getCurrentPerfisTransportador()

const formData = ref()
const createFormData = () => {
  formData.value = {
    obraExistente: false,
    tipoPessoa: 'CPF',
    quantidadeCacambas: 1,
    veiculo: {
      tipo: null,
      poliguindaste: null
    },
    idResiduoPredominante: null,
    naoSolicitado: 'S',
    selectedObra: null,
    obra: {
      codIbge: null,
      municipio: null,
      endereco: null,
      bairro: null,
      complemento: null,
      numero: null,
      nomeObra: null,
      duracaoInicial: null,
      duracaoFinal: null,
      possuiAlvara: 0,
      pessoa: {
        bairro: null,
        celular: null,
        cep: null,
        codIbge: null,
        complemento: null,
        cpfCnpj: null,
        email: null,
        endereco: null,
        nomeRazaoSocial: null,
        numero: null,
        shape: null,
        tipoPessoa: 'F'
      },
      transportadores: [{ data: { idPerfil: transportadores.map(({ id }) => id) } }]
    },
    // dataHoraSolicitacao: new Date().toISOString(),
    // dataHoraEnvio: new Date().toISOString(),
    idTransportador: (transportadores[0] || {}).id
  }
}
createFormData()

/**
 * TODO
 * @param e
 * @returns {Promise<void>}
 */
const sendSolicitacaoTransporte = async (e) => {
  e.preventDefault()
  try {
    showProgressBarLoading.value = true
    await doRequest({
      method: 'post',
      url: 'enviar_transporte_nao_solicitado',
      params: formData.value
    })
    notify('Nova Solicitação de Transporte enviada com sucesso', 'success', 3500)
    const router = await import('@/router')
    await router.default.push('/localizar-ctr')
    showProgressBarLoading.value = false
  } catch (err) {
    showProgressBarLoading.value = false
    console.error(err)
    // notify(err.message ? err.message : 'Não foi possivel enviar esta solicitação, por favor contate um administrador do sistema', 'error', 3500)
  }
}

const cpfCnpj = reactive({
  dataField: 'obra.pessoa.cpfCnpj',
  name: 'cpfCnpj',
  colSpan: 4,
  label: { text: 'CPF', location: 'top' },
  cssClass: 'cpf-field',
  validationRules: [
    {
      type: 'required',
      message: 'CPF/CNPJ não foi preenchido'
    },
    {
      ruleName: 'cpfCnpjValid',
      type: 'custom',
      validationCallback: ($event) => {
        loading.value.message = 'Validando CPF/CNPJ...'
        loading.value.visible = true
        const res = cpfCnpjValid($event, $event.formItem.label.text)
        loading.value.visible = false
        cpfCnpj.validationRules.find(rule => rule.ruleName === 'cpfCnpjValid').message = `${$event.formItem.label.text} Inválido`
        return res
      },
      message: 'CPF/CNPJ Inválido'
    }
  ],
  editorOptions: {
    maxLength: 14,
    mask: '000.000.000-00',
    maskInvalidMessage: 'CPF/CNPJ Incompleto',
    onValueChanged: async ({ value }) => {
      nome.editorOptions.disabled = true
      email.editorOptions.disabled = true
      celular.editorOptions.disabled = true
      formData.value.obra.pessoa.nomeRazaoSocial = null
      formData.value.obra.pessoa.email = null
      formData.value.obra.pessoa.celular = null
      /**
       * Sempre seta Combo de 'Utilizar uma obra existente' com valor desabilitado, só é habilitado no find de pessoa
       */
      formInstance.value.instance.getEditor('obraExistente').option('items',
        [{ label: 'Criar uma nova obra', value: false }, { label: 'Utilizar uma obra existente', value: true, disabled: false }])
      formInstance.value.instance.updateData('obraExistente', false)

      loading.value.message = 'Buscando CPF/CNPJ...'
      loading.value.visible = true
      const { data: { data: pessoa } } = await http.post(`${config.baseUrl}/Pessoa/find`, {
        where: {
          cpfCnpj: value,
          excluido: 'N'
        },
        attributes: ['id', 'cpfCnpj', 'tipoPessoa', 'nomeRazaoSocial', 'celular', 'email', 'cep', 'endereco', 'bairro', 'complemento', 'numero', 'codIbge'],
        include: [{
          model: 'Perfil',
          as: 'perfis',
          include: [
            {
              model: 'Obra',
              as: 'obras',
              order: JSON.stringify([['id', 'desc']]),
              limit: 1,
              separate: true,
              required: false,
              attributes: ['id', 'nomeObra', 'cep', 'bairro', 'complemento', 'numero', 'endereco', 'duracaoFinal', 'duracaoInicial', 'numeroAlvara', 'possuiAlvara', 'cod_ibge'],
              include: [{
                model: 'Municipio',
                as: 'municipio'
              },
              {
                model: 'ObraTransportador',
                as: 'obraTransportadores',
                required: false
              }]
            },
            {
              model: 'TipoPerfil',
              as: 'tipoPerfil'
            }]
        },
        {
          model: 'Municipio',
          as: 'municipio'
        }]
      })
      /**
       * Request de pessoa para preenchimento automatico de dados da pessoa e do endereço.
       * Ordem de precedência:
       * 1 - Pessoa com perfil gerador que possua obras vinculadas: preencher com a mais recente.
       * 2 - Se a pessoa não possuir uma obra, preencher com os dados da pessoa.
       * 3 - Caso não encontre uma pessoa, realizar uma nova request em Solicitacao transporte pelo id do transportador vinculado na CTR e preencher.
       * 4 - Caso não tenha, deixar em branco para preenchimento normal.
       */
      if (pessoa.rows.length) {
        if (!formData.value.obra.pessoa.nomeRazaoSocial) formInstance.value.instance.updateData('obra.pessoa.nomeRazaoSocial', pessoa.rows[0]?.nomeRazaoSocial || null)
        if (!formData.value.obra.pessoa.email) formInstance.value.instance.updateData('obra.pessoa.email', formData.value.obra.pessoa.email = pessoa.rows[0]?.email || null)
        if (!formData.value.obra.pessoa.celular) formInstance.value.instance.updateData('obra.pessoa.celular', formData.value.obra.pessoa.celular = pessoa.rows[0]?.celular || null)
        if (!formData.value.obra.pessoa.id) formInstance.value.instance.updateData('obra.pessoa.id', formData.value.obra.pessoa.id = pessoa.rows[0]?.id || null)

        const findPerfil = pessoa.rows[0]?.perfis.find(perfil => perfil.tipoPerfil.id === 1 || perfil.tipoPerfil.id === 8)
        if (findPerfil) {
          formData.value.idGerador = pessoa.rows[0]?.perfis.find(perfil => perfil.tipoPerfil.id === 1 || perfil.tipoPerfil.id === 8).id
          const findObraTransportador = findPerfil.obras[0]?.obraTransportadores.find((obra) => obra.idPerfil === formData.value.idTransportador)
          if (findObraTransportador) {
            formInstance.value.instance.updateData('obraExistente', true)
            formInstance.value.instance.updateData('selectedObra', findPerfil.obras[0])
            formInstance.value.instance.updateData('obra', findPerfil.obras[0])
            formInstance.value.instance.updateData('obra.pessoa.perfis', pessoa.rows[0].perfis)
            const obraFields = ['obra.nomeObra', 'obra.duracaoInicial', 'obra.duracaoFinal', 'obra.cep', 'obra.endereco', 'obra.bairro', 'obra.numero', 'obra.complemento', 'obra.uf', 'obra.municipio']
            obraFields.forEach(dataField => {
              if (formInstance.value.instance.getEditor(dataField)) formInstance.value.instance.getEditor(dataField).option('disabled', value)
            })
          } else {
            formInstance.value.instance.updateData('obra.cep', pessoa.rows[0]?.cep)
            formInstance.value.instance.updateData('obra.endereco', pessoa.rows[0]?.endereco)
            formInstance.value.instance.updateData('obra.bairro', pessoa.rows[0]?.bairro)
            formInstance.value.instance.updateData('obra.complemento', pessoa.rows[0]?.complemento)
            formInstance.value.instance.updateData('obra.numero', pessoa.rows[0]?.numero)
            formInstance.value.instance.updateData('obra.codIbge', pessoa.rows[0]?.municipio.codIbge)
            formInstance.value.instance.updateData('obra.uf', pessoa.rows[0]?.municipio.uf)
            formInstance.value.instance.updateData('obra.municipio', pessoa.rows[0]?.municipio.nome)
          }
        }
      } else {
        formInstance.value.instance.getEditor('obraExistente').option('disabled', true)
        formInstance.value.instance.getEditor('obra.pessoa.nomeRazaoSocial').option('disabled', false)
        formInstance.value.instance.getEditor('obra.pessoa.email').option('disabled', false)
        formInstance.value.instance.getEditor('obra.pessoa.celular').option('disabled', false)
      }
      loading.value.visible = false
    }
  }
})
const tipoPessoa = reactive({
  dataField: 'obra.pessoa.tipoPessoa',
  colSpan: 4,
  label: { visible: false },
  editorType: 'dxRadioGroup',
  editorOptions: {
    layout: 'horizontal',
    items: [{ label: 'CPF', value: 'F' }, { label: 'CNPJ', value: 'J' }],
    displayExpr: 'label',
    valueExpr: 'value',
    onValueChanged: ({ value }) => {
      if (value === 'F') {
        formInstance.value.instance.itemOption('pessoa.cpfCnpj', 'label', { text: 'CPF', location: 'top' })
        formInstance.value.instance.getEditor('obra.pessoa.cpfCnpj').option('mask', '000.000.000-00')
      } else {
        formInstance.value.instance.itemOption('pessoa.cpfCnpj', 'label', { text: 'CNPJ', location: 'top' })
        formInstance.value.instance.getEditor('obra.pessoa.cpfCnpj').option('mask', '00.000.000/0000-00')
      }
      gridResiduos.value.residuosDataSource = []
      gridResiduos.value.formData.idResiduoPredominante = null
      createFormData()
      formData.value.obra.pessoa.tipoPessoa = value
    }
  }
})

/**
 * TODO:
 */
const hiddenDataGridField = reactive({
  dataField: 'obra.residuos',
  label: { text: '', location: 'top' },
  editorType: 'dxSelectBox',
  cssClass: 'hidden',
  validationRules: [
    {
      type: 'custom',
      reevaluate: true,
      validationCallback: () => !!(formData.value.obra.residuos && Array.isArray(formData.value.obra.residuos) && formData.value.obra.residuos.length),
      message: 'Nenhum resíduo foi inserido'
    }
  ],
  editorOptions: {
    items: []
  }
})
const nome = reactive({
  dataField: 'obra.pessoa.nomeRazaoSocial',
  colSpan: 1,
  label: { text: 'Nome', location: 'top' },
  validationRules: [{ type: 'required', message: 'Nome não foi preenchido' }],
  editorOptions: {
    disabled: true,
    maxLength: 150
  }
})
const email = reactive({
  dataField: 'obra.pessoa.email',
  colSpan: 2,
  label: { text: 'E-mail', location: 'top' },
  validationRules: [{ type: 'email', message: 'E-mail inválido' }, { type: 'required', message: 'E-mail não foi preenchido' }],
  editorOptions: {
    disabled: true,
    maxLength: 100
  }
})
const celular = reactive({
  dataField: 'obra.pessoa.celular',
  colSpan: 1,
  label: { text: 'Celular', location: 'top' },
  validationRules: [{ type: 'required', message: 'Celular não foi preenchido' }],
  editorOptions: {
    disabled: true,
    mask: '(00) 00000-0000',
    maxLength: 11
  }
})

const possuiAlvara = reactive({
  dataField: 'obra.possuiAlvara',
  colSpan: 2,
  label: { text: 'Possui Alvará?', location: 'top' },
  editorType: 'dxRadioGroup',
  editorOptions: {
    items: [{ label: 'Sim', value: 1 }, { label: 'Não', value: 0 }],
    displayExpr: 'label',
    valueExpr: 'value',
    onValueChanged: ({ value }) => {
      numeroAlvara.visible = value === 1
    }
  }
})
const numeroAlvara = reactive({
  dataField: 'obra.numeroAlvara',
  colSpan: 2,
  label: { text: 'Número Alvará', location: 'top' },
  validationRules: [
    {
      type: 'required',
      message: 'Número Alvará não foi preenchido'
    }
  ],
  visible: false
})

/**
 * TODO
 *
 */
const geradorFields = ref([
  tipoPessoa,
  cpfCnpj,
  nome,
  email,
  celular
])

/**
 * Array de campos de endereçamento
 */
const enderecoFields = ref([
  {
    dataField: 'obra.cep',
    colSpan: 1,
    label: { text: 'CEP', location: 'top' },
    validationRules: [{ type: 'required', message: 'CEP não foi preenchido' }],
    cssClass: 'cep-field',
    editorOptions: {
      maxLength: 8,
      disabled: false,
      mask: '00000-000',
      maskInvalidMessage: 'CEP Incompleto',
      onFocusOut: async ({ component }) => {
        loading.value.message = 'Buscando Endereço...'
        loading.value.visible = true
        try {
          // Busca endereco
          if (formData.value.obra.cep && component.instance().option('isValid')) {
            const { data: { info: { endereco, geocodeCandidates } } } = await http.post(`${config.baseUrl}/findAddress`, {
              cep: formData.value.obra.cep
            })
            if (endereco) {
              // Atualiza dados de endereço
              formInstance.value.instance.updateData('obra.uf', endereco.uf)
              formInstance.value.instance.updateData('obra.municipio', endereco.localidade)
              formInstance.value.instance.updateData('obra.endereco', endereco.logradouro)
              formInstance.value.instance.updateData('obra.bairro', endereco.bairro)
              formInstance.value.instance.updateData('obra.complemento', endereco.complemento)
              formInstance.value.instance.updateData('obra.codIbge', endereco.ibge)
              // Atualiza dados de pessoa
              if (!formData.value.obra.pessoa.id) {
                formData.value.obra.pessoa.bairro = endereco.bairro
                formData.value.obra.pessoa.cep = formData.value.obra.cep
                formData.value.obra.pessoa.codIbge = endereco.ibge
                formData.value.obra.pessoa.complemento = endereco.complemento
                formData.value.obra.pessoa.endereco = endereco.logradouro
                formData.value.obra.pessoa.numero = formData.value.obra.numero
                formData.value.obra.pessoa.shape = getCepGeometry(geocodeCandidates)
                formData.value.obra.shape = getCepGeometry(geocodeCandidates)
              }
            }
          }
        } catch (err) {
          console.error(err)
        }
        await nextTick()
        loading.value.visible = false
      }
    }
  },
  {
    dataField: 'obra.uf',
    colSpan: 1,
    editorOptions: {
      disabled: true,
      maxLength: 2
    },
    label: { text: 'UF', location: 'top' }
  },
  {
    dataField: 'obra.codIbge',
    colSpan: 1,
    visible: false,
    label: { text: 'UF', location: 'top' },
    editorOptions: {
      maxLength: 7
    }
  },
  {
    dataField: 'obra.municipio',
    colSpan: 2,
    editorOptions: {
      disabled: true,
      maxLength: 120
    },
    label: { text: 'Município', location: 'top' }
  },
  {
    dataField: 'obra.endereco',
    colSpan: 2,
    editorOptions: {
      disabled: false,
      maxLength: 100
    },
    label: { text: 'Endereço', location: 'top' },
    validationRules: [{ type: 'required', message: 'Endereço não foi preenchido' }]
  },
  {
    dataField: 'obra.bairro',
    colSpan: 2,
    label: { text: 'Bairro', location: 'top' },
    editorOptions: {
      disabled: false,
      maxLength: 80
    },
    validationRules: [{ type: 'required', message: 'Bairro não foi preenchido' }]
  },
  {
    dataField: 'obra.complemento',
    colSpan: 2,
    editorOptions: {
      disabled: false,
      maxLength: 100
    },
    label: { text: 'Complemento', location: 'top' }
  },
  {
    dataField: 'obra.numero',
    colSpan: 1,
    dataType: 'number',
    editorType: 'dxTextBox',
    label: { text: 'Número', location: 'top' },
    editorOptions: {
      disabled: false,
      maxLength: 10
    },
    validationRules: [{ type: 'required', message: 'Número não foi preenchido' }]
  },
  {
    itemType: 'empty',
    colSpan: 1
  }
])

const selectObraFields = ref([
  {
    dataField: 'obraExistente',
    colSpan: 4,
    label: { visible: false },
    editorType: 'dxRadioGroup',
    editorOptions: {
      layout: 'horizontal',
      items: [{ label: 'Criar uma nova obra', value: false }, { label: 'Utilizar uma obra existente', value: true, disabled: false }],
      displayExpr: 'label',
      valueExpr: 'value',
      onValueChanged: ({ value }) => {
        formInstance.value.instance.getEditor('obraExistente').option('disabled', false)
        formInstance.value.instance.itemOption('obra.selectedObra', 'visible', value)
        formInstance.value.instance.itemOption('Obra.obra-empty-field', 'visible', value)
        const fields = ['obra.nomeObra', 'obra.duracaoInicial', 'obra.duracaoFinal', 'obra.cep', 'obra.endereco', 'obra.bairro', 'obra.numero', 'obra.complemento']
        /**
         * Ao trocar de valor, limpa dados de obra e seta valores para disabled dependendo do valor selecionado
         */
        fields.forEach(dataField => {
          if (formInstance.value.instance.getEditor(dataField)) formInstance.value.instance.getEditor(dataField).option('disabled', value)
          formInstance.value.instance.updateData(dataField, null)
        })
        formInstance.value.instance.updateData('obra.uf', null)
        formInstance.value.instance.updateData('obra.municipio', null)
        formInstance.value.instance.updateData('obra.codIbge', null)
        formInstance.value.instance.updateData('obra.id', null)
        formInstance.value.instance.updateData('selectedObra', null)
        gridResiduos.value.residuosDataSource = []
        gridResiduos.value.formData.idResiduoPredominante = null
        formData.value.obra.residuos = []
        formData.value.obraExistente = value
        /**
         * Força PossuiAlvara e Número Alvara para valor true ou false dependendo do valor do radio group
         * evita bug destes campos habilitarem quando há mudanças no formulario
         */
        formInstance.value.instance.getEditor('obra.possuiAlvara').option('disabled', value)
        if (formInstance.value.instance.getEditor('obra.numeroAlvara')) {
          formInstance.value.instance.getEditor('obra.numeroAlvara').option('disabled', value)
          formInstance.value.instance.updateData('obra.numeroAlvara', null)
        }
      }
    }
  },
  {
    dataField: 'selectedObra',
    name: 'selectedObra',
    colSpan: 2,
    label: { text: 'Selecionar obra', location: 'top' },
    validationRules: [{ type: 'required', message: 'Por favor, selecione uma obra' }],
    editorType: 'dxLookup',
    visible: false,
    editorOptions: {
      searchExpr: '$obra.nomeObra$',
      dataSource: {
        store: new CustomStore({
          key: 'id',
          load: async loadOptions => {
            const params = getParamsFromLoadOptions(loadOptions)
            params.include = [
              {
                model: 'Obra',
                as: 'obra',
                attributes: ['codIbge', 'endereco', 'bairro', 'complemento', 'numero', 'nomeObra', 'duracaoInicial', 'duracaoFinal', 'cep', 'shape', 'possuiAlvara', 'numeroAlvara'],
                where: {
                  idGerador: formData.value.idGerador
                },
                include: [
                  {
                    model: 'Municipio',
                    as: 'municipio',
                    attributes: ['uf', 'nome']
                  }
                ]
              }
            ]
            params.where = {
              ...params.where,
              idPerfil: formData.value.idTransportador
            }
            const result = await http.post(`${config.baseUrl}/ObraTransportador/find`, params)
            const rows = result.data.data.rows.map(row => {
              return {
                ...row.obra,
                id: row.idObra
              }
            })
            return { data: rows, totalCount: result.data.data.count }
          },
          byKey: (key) => formData.value.obra
        }),
        paginate: true,
        pageSize: 10
      },
      displayExpr: 'nomeObra',
      valueExpr: 'id',
      onValueChanged: async ({ component }) => {
        const value = JSON.parse(JSON.stringify(component.option('selectedItem')))
        if (value) {
          for (const key in value) {
            if (formData.value.obra.pessoa.key === null) formInstance.value.instance.updateData(`obra.pessoa.${key}`, value[key])
            formInstance.value.instance.updateData(`obra.${key}`, value[key])
          }
          formInstance.value.instance.updateData('obra.municipio', value.municipio.nome)
          formInstance.value.instance.updateData('obra.uf', value.municipio.uf)
          loading.value.message = 'Buscando dados da obra...'
          loading.value.visible = true
          const { data: obraResiduos } = await doRequest({
            method: 'post',
            url: 'ObraTipoResiduo/find',
            params: {
              where: {
                idObra: formData.value.obra.id
              },
              attributes: ['id', 'volume'],
              include: JSON.stringify([
                {
                  model: 'TipoResiduo',
                  as: 'tipoResiduo',
                  required: true,
                  attributes: ['id', 'nome'],
                  include: [
                    {
                      model: 'ClasseMaterial',
                      as: 'classeMaterial',
                      required: true,
                      attributes: ['id', 'classe'],
                      include: {
                        model: 'TipoResiduo',
                        as: 'tipoResiduos',
                        attributes: ['id'],
                        required: true
                      }
                    }
                  ]
                }
              ])
            }
          })
          gridResiduos.value.residuosDataSource = []
          obraResiduos.rows.forEach((residuo) => {
            const parsedResiduo = {
              id: residuo.tipoResiduo.id,
              classeMaterial: residuo.tipoResiduo.classeMaterial.classe,
              materialTipoResiduo: residuo.tipoResiduo.nome,
              volumeM3: parseInt(residuo.volume)
            }
            gridResiduos.value.addResiduo(null, parsedResiduo, false)
          })
          /**
           * Força PossuiAlvara e Número Alvara para false novamente, evita bug destes campos habilitarem quando há mudanças no formulario
           */
          formInstance.value.instance.getEditor('obra.possuiAlvara').option('disabled', true)
          if (formInstance.value.instance.getEditor('obra.numeroAlvara')) formInstance.value.instance.getEditor('obra.numeroAlvara').option('disabled', true)
          loading.value.visible = false
        }
      },
      dropDownOptions: {
        closeOnOutsideClick: true
      }
    }
  },
  {
    name: 'obra-empty-field',
    itemType: 'empty',
    colSpan: 2,
    visible: false
  }
])
/**
 * TODO
 */
const obraFields = ref([
  {
    dataField: 'obra.nomeObra',
    colSpan: 1,
    label: { text: 'Nome da obra', location: 'top' },
    validationRules: [{ type: 'required', message: 'Nome da obra não foi preenchido' }],
    editorOptions: {
      disabled: false,
      maxLength: 100
    }
  },
  {
    dataField: 'obra.duracaoInicial',
    colSpan: 1,
    editorType: 'dxDateBox',
    format: 'datetime',
    label: { text: 'Duração Inicial', location: 'top' },
    validationRules: [{ type: 'required', message: 'Duração Inicial da obra não foi preenchida' }],
    editorOptions: {
      onValueChanged: ({ value }) => {
        formInstance.value.instance.getEditor('obra.duracaoFinal').option('min', value)
      },
      disabled: false
    }
  },
  {
    dataField: 'obra.duracaoFinal',
    colSpan: 1,
    editorType: 'dxDateBox',
    format: 'datetime',
    label: { text: 'Duração Final', location: 'top' },
    validationRules: [{ type: 'required', message: 'Duração Final da obra não foi preenchida' }],
    editorOptions: {
      onValueChanged: ({ value }) => {
        formInstance.value.instance.getEditor('obra.duracaoInicial').option('max', value)
      },
      disabled: false
    }
  },
  {
    itemType: 'empty',
    colSpan: 1
  }
])

const tipo = reactive({
  dataField: 'veiculo.tipo',
  colSpan: 1,
  label: { text: 'Tipo', location: 'top' },
  editorOptions: {
    readOnly: true
  }
})

const poliguindaste = reactive({
  name: 'poliguindaste',
  dataField: 'veiculo.poliguindaste',
  colSpan: 1,
  label: { text: 'Poliguindaste', location: 'top' },
  visible: false,
  editorOptions: {
    readOnly: true
  }
})

const veiculo = reactive({
  name: 'veiculo',
  dataField: 'idVeiculo',
  colSpan: 1,
  label: { text: 'Veículo', location: 'top' },
  editorType: 'dxLookup',
  validationRules: [{ type: 'required', message: 'Selecione um veículo' }],
  editorOptions: {
    dataSource: {
      store: new CustomStore({
        key: 'id',
        load: async loadOptions => {
          const params = getParamsFromLoadOptions(loadOptions)
          const result = await http.post(`${config.baseUrl}/ViewVeiculosDisponiveis/find`, params)
          return { data: result.data.data.rows, totalCount: result.data.data.count }
        },
        byKey: key => formData.value.veiculo
      }),
      paginate: true,
      pageSize: 10
    },
    displayExpr: 'placa',
    valueExpr: 'id',
    onValueChanged: async ({ component }) => {
      const value = component.option('selectedItem')
      formInstance.value.instance.updateData('cacamba', null)
      if (value) {
        for (const key in value) {
          formInstance.value.instance.updateData(`veiculo.${key}`, value[key])
        }
        formInstance.value.instance.itemOption('veiculo.poliguindaste', 'visible', !!(value.poliguindaste))
        formInstance.value.instance.itemOption('cacamba', 'visible', !!(value.poliguindaste))
        // Força scroll para final da página, devextreme rola a página para cima automaticamente quando há uma mudança no itemOption
        document.getElementsByClassName('dx-validationsummary')[0].scrollIntoView()
      }
    },
    dropDownOptions: {
      closeOnOutsideClick: true
    }
  }
})

const cacamba = reactive({
  name: 'cacamba',
  dataField: 'idCacamba',
  colSpan: 1,
  label: { text: 'Caçamba', location: 'top' },
  editorType: 'dxLookup',
  validationRules: [{ type: 'required', message: 'Selecione uma Caçamba' }],
  visible: false,
  editorOptions: {
    dataSource: {
      store: new CustomStore({
        key: 'id',
        load: async loadOptions => {
          const params = getParamsFromLoadOptions(loadOptions)
          const result = await http.post(`${config.baseUrl}/ViewCacambasDisponiveis/find`, params)
          return { data: result.data.data.rows, totalCount: result.data.data.count }
        },
        byKey: key => formData.value.cacamba
      }),
      paginate: true,
      pageSize: 10
    },
    displayExpr: 'numeroIdentificacao',
    valueExpr: 'id',
    dropDownOptions: {
      closeOnOutsideClick: true
    }
  }
})

const solicitacaoTransporteFields = ref([
  possuiAlvara,
  numeroAlvara
])

const veiculoFields = ref([
  veiculo,
  tipo,
  poliguindaste,
  cacamba
])

const formInstance = ref()

/**
 * Animação do botão com erros de validação
 */
const clickEnviarTransporte = () => {
  delete formData.value.veiculo
  const { isValid } = formInstance.value.instance.validate()
  if (!isValid) {
    const enviarTransporteIcon = document.getElementsByClassName('enviar-transporte-icon')[0]
    enviarTransporteIcon.querySelector('.dx-button').style.backgroundColor = 'red'
    enviarTransporteIcon.querySelector('.dx-button').style.transition = 'all 0.5s'
    enviarTransporteIcon.querySelector('.dx-button').style.animation = 'headShake 0.5s'
    setTimeout(() => {
      document.getElementsByClassName('dx-validationsummary')[0].scrollIntoView({ behavior: 'smooth' })
      enviarTransporteIcon.querySelector('.dx-button').style.backgroundColor = ''
      enviarTransporteIcon.querySelector('.dx-button').style.animation = null
    }, 500)
  }
}

const gridResiduosChange = (data) => {
  formData.value.obra.residuos = data.residuos.value.map(residuo => {
    return {
      idTipoResiduo: residuo.id,
      volume: residuo.volumeM3
    }
  })
  formData.value.idResiduoPredominante = data.idResiduoPredominante
  formInstance.value.instance.validate()
}
</script>

<style>
.flash {
  border: solid red 1px !important;
  border-radius: 5px;
  transform: scale(1.01);
  transition: all 0.5s;
}
.hidden {
  display: none
}
.hidden-icon {
  position: relative;
  right: 100%;
}
.enviar-transporte-icon:hover .dx-button .dx-icon {
  transition: all 0.2s;
  right: 0;
}
.enviar-transporte-icon:hover .dx-button-text {
  font-size: 13px;
  transition: all 0.4s;
}
.actions-container {
  position: fixed;
  width: 65%;
  left: 50%;
  transform: translateX(-50%);
  bottom: 3px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
  z-index: 100;
}
.actions-container .dx-form-group-content {
  border: unset !important;
  padding: unset !important;
}
.actions-container .dx-layout-manager .dx-field-item {
  padding-bottom: 10px !important;
}
@keyframes headShake {
  0% { transform: translateX(0); }
  12.5% {transform: translateX(-6px) rotateY(-9deg) skewY(1deg);}
  37.5% {transform: translateX(5px) rotateY(4.5deg) skewY(-1deg);}
  62.5% { transform: translateX(-3px) rotateY(-2.25deg) skewY(0);}
  87.5% { transform: translateX(2px) rotateY(3deg);}
  100% {transform: translateX(0);}
}
</style>
