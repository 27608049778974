<template>
  <div class="generic-crud">
    <div>
      <div class="md-layout">
        <div class="md-layout-item">
          <h2 class="crud-title">Consultar Veículos e Caçambas do Transportador</h2>
        </div>
      </div>
      <simple-grid entity="Perfil"
                   popup-title="Perfil"
                   :grid-data="gridData"
                   :deletable="false"
                   :editable="false"
                   :viewable="false"
      ></simple-grid>
    </div>
  </div>
</template>
<script>
import { SimpleGrid } from '@/components'
import CustomStore from 'devextreme/data/custom_store'
import mixinCrud from '../mixin-crud'
import { mapActions } from 'vuex'
import { getParamsFromLoadOptions, parseRawSqlFilter } from '../../../util/loadOptionsParams'
import { getByKeys } from '../../../util/getByKeys'

export default {
  name: 'perfil-crud',
  mixins: [
    mixinCrud
  ],
  components: {
    SimpleGrid
  },
  data () {
    return {
      entity: 'Perfil',
      permissionName: 'Consultar Veículo e Caçambas do Transportador',
      formData: null,
      gridData: {
        value: {
          visible: true
        },
        columns: this.parseColumns([
          {
            dataField: 'id',
            visible: false,
            caption: 'id'
          },
          {
            dataField: 'nome_razao_social',
            caption: 'Nome razão social',
            calculateDisplayValue: 'nome_razao_social',
            editorType: 'dxLookup',
            editorOptions: {
              dataSource: {
                store: new CustomStore({
                  load: (loadOptions) => this.$utils.wrapRequestForGrid(this.find({
                    entity: 'ViewTransportador',
                    loadOptions
                  })),
                  byKey: key => key || null
                }),
                sort: [
                  { selector: 'nomeRazaoSocial', asc: true }
                ],
                paginate: true,
                pageSize: 10
              },
              displayExpr: 'nomeRazaoSocial',
              valueExpr: 'nomeRazaoSocial'
            }
          },
          {
            dataField: 'cpf_cnpj',
            caption: 'cnpj',
            calculateDisplayValue: 'cpf_cnpj',
            editorType: 'dxLookup',
            editorOptions: {
              dataSource: {
                store: new CustomStore({
                  load: (loadOptions) => this.$utils.wrapRequestForGrid(this.find({
                    entity: 'ViewTransportador',
                    loadOptions
                  })),
                  byKey: key => key || null
                }),
                sort: [
                  { selector: 'cpfCnpj', asc: true }
                ],
                paginate: true,
                pageSize: 10
              },
              displayExpr: 'cpfCnpj',
              valueExpr: 'cpfCnpj'
            }
          },
          {
            dataField: 'situacao_validacao',
            caption: 'situação Validação',
            calculateDisplayValue: 'situacao_validacao',
            editorType: 'dxLookup',
            editorOptions: {
              allowClearing: true,
              showClearButton: true,
              acceptCustomValue: true,
              dataSource: new CustomStore({
                key: 'id',
                load: async (loadOptions) => {
                  const res = await this.$utils.wrapRequestForGrid(getByKeys({
                    urlModel: 'Perfil',
                    where: {
                      idTipoPerfil: [2, 9]
                    },
                    displayField: 'situacaoValidacao'
                  }))
                  return res.totalCount > 0 ? res.data[0] : null
                },
                byKey: async key => {
                  const res = await getByKeys({
                    urlModel: 'Perfil',
                    where: {
                      idTipoPerfil: [2, 9],
                      situacaoValidacao: key
                    },
                    displayField: 'situacaoValidacao'
                  })
                  return res.totalCount > 0 ? res.data[0] : null
                }
              }),
              displayExpr: 'situacaoValidacao',
              valueExpr: 'situacaoValidacao'
            }
          },
          {
            dataField: 'quantidadeCacambas',
            caption: 'Quantidade de Caçambas',
            allowFiltering: false
          },
          {
            dataField: 'quantidadeVeiculos',
            caption: 'Quantidade de Veículos',
            allowFiltering: false
          }
        ]),
        dataSource: new CustomStore({
          key: 'id',
          load: (loadOptions) => {
            return this.$utils.wrapRequestForGrid(this.findVeiculoCacambas(loadOptions))
          }
        })
      }
    }
  },

  methods: {
    /**
     * Metodo para obter o objeto com valores do grid
     * @param {Object} loadOptions - Os parametros de busca do datasource do
     * devextreme.
     * @returns {Object} Os parametros da requisicao.
     */
    async findVeiculoCacambas (loadOptions) {
      const params = getParamsFromLoadOptions(loadOptions, null, false)
      if (Array.isArray(params.where) && params.where.length) {
        const fields = {
          nomeRazaoSocial: 'nome_razao_social'
        }
        params.where = parseRawSqlFilter(params.where, fields)
      }
      const result = await this.findVeiculoCacamba(params)

      return { data: result.rows, totalCount: result.count }
    },
    ...mapActions('Crud', ['findVeiculoCacamba'])
  }
}
</script>

<style scoped lang="scss">
</style>
