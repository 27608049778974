<template>
  <div class="radioBoxContainer">
    <div class="checkboxField"><b>Registrar ocorrência de:</b></div>
    <div class="cacambaTransporteCheckbox">
      <DxRadioGroup
        class="cacambaTransporteRadioGroup"
        layout="horizontal"
        :items="typeSelection"
        @value-changed="tipoRegistroOcorrenciaRadioGroup"
        :v-model="selectedType"/>
    </div>
  </div>
</template>
<script>

import DxRadioGroup from 'devextreme-vue/radio-group'

export default {
  name: 'popup-cacamba-irregular',
  components: {
    DxRadioGroup
  },
  props: {
    title: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      fotoCacamba: null,
      showIdentificacaoCacamba: null,
      identificacaoCacamba: null,
      showNumeroIdentificacao: null,
      numeroIdentificacao: null,
      selectedType: null,
      typeSelection: ['Caçamba', 'Transporte'],
      irregularidadeTextArea: null
    }
  },
  computed: {
    style () {
      return {
        width: this.fieldSetWidth,
        height: this.fieldSetHeight
      }
    }
  },
  watch: {
  },
  methods: {
    /**
     * Metodo para obter o geojson da visualizacao atual do mapa.
     * @returns {Object} O geoJson da visualizacao atual do mapa.
     */
    getBoundsGeoJson (latlong) {
      return {
        type: 'Point',
        coordinates: [latlong.lng, latlong.lat],
        srid: '4326',
        crs: { type: 'name', properties: { name: 'EPSG:4326' } }
      }
    },
    tipoRegistroOcorrenciaRadioGroup (payload) {
      this.$emit('checkboxChanged', payload)
    }
  }
}
</script>

<style lang="scss">
@import "../../../../../global.scss";
.radioBoxContainer {
  background-color: white;
  width: 288px;
  height: 99px;
  -webkit-box-shadow: -2px 1px 6px 1px rgba(0,0,0,0.21);
  @media only screen and (max-width: 600px) {
    background-color: white;
    width: 300px;
    height: 99px;
    -webkit-box-shadow: -2px 1px 6px 1px rgba(0,0,0,0.21);
  }
}
.checkboxField {
  position: relative;
  top: 10px;
  left: 19px;
  font-size: 14px;
}
.cacambaTransporteCheckbox {
  position: relative;
  top: 16px;
  left: 20px;
  @media only screen and (max-width: 600px) {
    position: relative;
    bottom: 89px;
    top: 10px;
    left: 12px;
  }
}
.cacambaTransporteRadioGroup {
  @media only screen and (max-width: 600px) {
    display: block;
  }
}
</style>
