import Home from '@/pages/Home/Home.vue'
import Login from '@/pages/Login'
import Configuracoes from '@/pages/Configuracoes'
import Transportadores from '@/pages/Public/Transportadores'
import DestinoRapidoLogin from '@/pages/Cruds/DestinoRapido/DestinoRapidoLogin.vue'
import DestinoRapido from '@/pages/Cruds/DestinoRapido/DestinoRapido.vue'

const files = require.context('../pages/Cruds', true, /\.js$/)

let crudRoutes = []

files.keys().forEach(key => {
  if (key.match(/routes\.js/g) !== null) {
    if (Array.isArray(files(key).default)) {
      for (const rt of files(key).default) {
        crudRoutes = crudRoutes.concat(rt)
      }
    } else {
      crudRoutes = crudRoutes.concat(files(key).default)
    }
    // if (files(key).default.routes) {
    //   console.log(files(key).default.routes)
    //   crudRoutes = crudRoutes.concat(files(key).default.routes)
    // } else {
    //   crudRoutes = crudRoutes.concat(files(key).default)
    // }
  }
})

const routes = [
  {
    path: '/transportadores',
    name: 'Transportadores',
    component: Transportadores,
    meta: {
      publicRoute: true,
      requiresAuth: false
    }
  },
  {
    path: '/destino-rapido',
    name: 'Destino Rapido',
    component: DestinoRapidoLogin,
    meta: {
      guest: true,
      publicRoute: true,
      requiresAuth: false
    }
  },
  {
    path: '/destino-rapido/:placa/:token',
    name: 'Destino Rapido',
    component: DestinoRapido,
    meta: {
      guest: true,
      publicRoute: true,
      requiresAuth: false
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      guest: true,
      requiresAuth: false
    }
  },
  {
    path: '/recovery/:id/:hash*',
    name: 'recovery',
    component: Login,
    meta: {
      guest: true,
      requiresAuth: false
    }
  },
  {
    path: '/confirmed/:id/:hash*',
    name: 'confirmed',
    component: Login,
    meta: {
      guest: true,
      requiresAuth: false
    }
  },
  {
    path: '/',
    component: Home,
    children: crudRoutes
  },
  {
    path: '/configuracoes',
    name: 'configuracoes',
    component: Configuracoes,
    meta: {
      icon: 'mdi mdi-cog',
      title: 'Configurações do Sistema',
      requiresAuth: true,
      permissionType: 'consulta'
    }
  }
]

export default routes
