<template>
  <div class="localizar-cacamba">
    <div>
      <div class="md-layout">
        <div class="md-layout-item">
          <h2 class="crud-title">Localizar caçamba</h2>
        </div>

        <div class="md-layout-item" style="text-align: right;">
          <dx-button type="success" @click="openCacambasMap">Visualizar Mapa</dx-button>
        </div>
      </div>
      <simple-grid
        entity="SolicitacaoTransporte"
        :editable="false"
        :deletable="false"
        :grid-data="gridData"
        ref="simpleGrid"/>
    </div>
  </div>
</template>
<script>
import DxButton from 'devextreme-vue/button'
import config from 'config'

import { SimpleGrid } from '@/components'
import CustomStore from 'devextreme/data/custom_store'

import {
  getCacambas,
  getCacambasSolicitacao
} from '../service'
import mixinCrud from '../../mixin-crud'

export default {
  name: 'list-situacao-cacamba',
  components: {
    SimpleGrid,
    DxButton
  },
  mixins: [mixinCrud],
  /**
   * Metodo do vue, para obter o estado inicial do componente.
   * @returns {Object} O estado inicial do componente.
   */
  data () {
    return {
      gridData: {
        value: {
          visible: true
        },
        columns: this.parseColumns([
          {
            dataField: 'id',
            editorType: 'dxNumberBox',
            visible: false
          },
          {
            dataField: 'situacao',
            caption: 'Situação',
            lookup: {
              allowClearing: true,
              dataSource: [
                'Enviada', 'Alocada', 'Retirada', 'Destinada',
                'Irregular', 'Com ocorrência Pessoa Física', 'Com ocorrência Pessoa Jurídica'
              ]
            }
          },
          {
            dataField: 'nomeRazaoSocial',
            calculateDisplayValue: 'nomeRazaoSocial',
            calculateSortValue: 'idTransportador',
            caption: 'Transportador',
            editorType: 'dxLookup',
            editorOptions: {
              dataSource: {
                store: new CustomStore({
                  key: 'id',
                  load: (loadOptions) => this.$utils.wrapRequestForGrid(this.find({
                    entity: 'ViewTransportador',
                    loadOptions
                  })),
                  byKey: key => key || null
                }),
                sort: [
                  { selector: 'nomeRazaoSocial', asc: true }
                ],
                paginate: true,
                pageSize: 10
              },
              displayExpr: 'nomeRazaoSocial',
              valueExpr: 'nomeRazaoSocial'
            }
          },
          {
            dataField: 'numeroIdentificacao',
            calculateSortValue: 'numeroIdentificacao',
            calculateDisplayValue: data => data.numeroIdentificacao,
            caption: 'Nº de Identificação',
            editorOptions: {
              allowClearing: true,
              showClearButton: true,
              showCancelButton: false,
              dataSource: {
                store: new CustomStore({
                  load: loadOptions => this.$utils.wrapRequestForGrid(getCacambasSolicitacao({
                    loadOptions,
                    subQuery: false,
                    fields: [{
                      fn: 'DISTINCT',
                      col: 'numero_identificacao',
                      as: 'numeroIdentificacao'
                    }]
                  })),
                  byKey: key => key || null
                }),
                sort: [
                  { selector: 'numero_identificacao', asc: true }
                ],
                paginate: true,
                pageSize: 5
              },
              displayExpr: 'numeroIdentificacao',
              valueExpr: 'numeroIdentificacao'
            }
          },
          {
            dataField: 'capacidadeM3',
            caption: 'Capacidade da caçamba m³',
            editorType: 'dxNumberBox'
          },
          {
            dataField: 'endereco',
            caption: 'Localização Atual'
          },
          {
            dataField: 'enderecoDeposito',
            caption: 'Endereço do Depósito'
          },
          {
            type: 'buttons',
            width: 125,
            showInColumnChooser: false,
            buttons: [
              {
                cssClass: 'dx-button dx-button-primary mdi mdi-file-document',
                text: 'CTR',
                onClick: ({ row: { data } }) => {
                  this.onSoliticaoActionClick('view', data)
                }
              }
            ]
          }
        ]),
        dataSource: new CustomStore({
          key: 'id',
          load: async loadOptions => this.$utils.wrapRequestForGrid(getCacambas(loadOptions))
        })
      }
    }
  },
  methods: {
    /**
     * Metodo de callback de quando e clicado no botao de acao da solicitacao,
     * com o objetivo de abrir a popup de acao da solicitacao.
     * @param {string} action - A acao que sera feita na solicitacao, 'view'
     * para visualizar a solicitacao, 'update' para atualizar a solicitacao, ou
     * 'cancel' para solicitar o cancelamento da solicitacao.
     * @param {Object} solicitacao - A solicitacao que foi clicada.
     */
    onSoliticaoActionClick (action, solicitacao) {
      if (action === 'view') {
        window.open(`${config.baseUrl}/FichaCTR/${solicitacao.idSolicitacaoTransporte}`, '_blank')
      }
    },

    /**
     * Metodo de comando, com o objetivo de abrir a tela de mapa de localizar
     * cacambas.
     */
    openCacambasMap () {
      this.$router.push({ path: '/localizar-cacamba/map' })
    }
  }
}
</script>

<style lang="scss">
</style>
