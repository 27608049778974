<template>
  <div class="registrar-ocorrencia">

    <viewOcorrencia
      v-if="showOcorrencia"
      :values="currentRowData"
      @ocorrencia-saved="onOcorrenciaSaved"
    >
    </viewOcorrencia>
    <DxToolTipGrid
      ref="tooltip"
      :visible="false"
    >
      <p>{{toolTipContent}}</p>
    </DxToolTipGrid>
    <div v-if="!showOcorrencia">
      <div class="md-layout">
        <div class="md-layout-item">
          <h2 class="crud-title">Acompanhar Ocorrência</h2>
        </div>

        <div class="md-layout-item" style="text-align: right;">
          <dx-button v-show="!onCreatingGraphic" type="success" @click="$router.push({ path: '/registrar-ocorrencia/map' })">Nova Ocorrência</dx-button>
        </div>
      </div>
      <simple-grid v-if="!onCreatingGraphic"
        entity="ocorrencia"
        :editable="false"
        :deletable="false"
        :excludable="false"
        :grid-data="gridData"
        ref="simpleGrid"/>
    </div>
    <div class="md-layout-item" style="text-align: right;">
      <dx-button v-show="!onCreatingGraphic && !showOcorrencia" type="success" @click="createGraphics">Painel de Ocorrências</dx-button>
    </div>
    <fieldset class="ocorrenciasGraficoFieldSet" v-if="onCreatingGraphic">
      <legend>Gerar Gráfico</legend>
        <div style="display: block; width: 100%" class="dx-field-label">
          <p style="font-size: 12px;">Responsável pelo registro das ocorrências</p>
          <DxRadioGroup
            :items="onCreateGraphicPage.responsavelType"
            v-model="onCreateGraphicPage.responsavelTypeSelected"
          />
          <p style="font-size: 12px; padding-top: 7px">Gerar Indicadores</p>
          <div class="ocorrenciaCheckboxes">
            <DxCheckBox
              :acceptCustomValue="false"
              @value-changed="situacaoCheckboxStatus"
              v-model="onCreateGraphicPage.situacaoOcorrencia.checkboxValue"
              text="Situação das Ocorrências  "/>
            <DxCheckBox
              :acceptCustomValue="false"
              @value-changed="irregularidadeCheckboxStatus"
              v-model="onCreateGraphicPage.irregularidadeCom.checkboxValue"
              text="Irregularidades com Caçambas e Transportes"/>
            <p style="font-size: 12px; padding-top: 11px">Período</p>
          </div>
          <DxDateBox
            :acceptCustomValue="false"
            style="display: inline-block;"
            :value.sync="onCreateGraphicPage.periodo.startValue"
            :max.sync="onCreateGraphicPage.periodo.endValue"
            width="180"
            type="date"/>
          <DxDateBox
            :acceptCustomValue="false"
            style="display: inline-block; left: 7px;"
            :value.sync="onCreateGraphicPage.periodo.endValue"
            :min.sync="onCreateGraphicPage.periodo.startValue"
            width="180"
            type="date"/>
        </div>
      <div class="md-layout-item" style="text-align: left;">
        <dx-button style="background-color: #FF8402; position: relative; right: 3px;" v-show="onCreatingGraphic" type="success" @click="goBackGraphic">Voltar</dx-button>
        <dx-button style="position: relative; left: 10px;" v-show="onCreatingGraphic" type="success" @click="onGenerateGraphics">Gerar Gráficos</dx-button>
        <dx-button style="position: relative; left: 20px;" v-show="onCreatingGraphic" type="success" @click="clearGraphics">Limpar</dx-button>
      </div>
    </fieldset>
    <div class="ocorrenciaGraphics">
      <DxPieChart v-if="onCreateGraphicPage.situacaoOcorrenciaGraphicGenerated"
                  id="situacaoPieChart"
                  :data-source="onCreateGraphicPage.datasourceSituacao"
                  :customize-label="customLabel"
                  palette="Bright"
                  title="Situação das Ocorrências">
        <DxSeries
          argument-field="situacao"
          percent="porcentagem"
          :label="{
           visible: false
          }"
          value-field="porcentagem">
          <DxLabel :visible="true">
            <DxConnector
              :visible="true"
              :width="1"
            />
          </DxLabel>
        </DxSeries>
        <DxSize :width="500"/>
        <DxExport :enabled="true"/>
        <DxTooltip
          :enabled="true"
          contentTemplate="tooltipTemplate"
        />
        <template #tooltipTemplate="{ data }">
          <TooltipTemplate :info="data"/>
        </template>
      </DxPieChart>

      <DxPieChart v-if="onCreateGraphicPage.irregularidadeComGraphicGenerated"
                  id="irregComPieChart"
                  :customize-label="customLabel"
                  :data-source="onCreateGraphicPage.datasourceIrregCom"
                  palette="Bright"
                  title="Irregularidades com Caçambas e Transportes">
        <DxSeries
          argument-field="irregularidade_com"
          value-field="porcentagem">
          <DxLabel :visible="true">
            <DxConnector
              :visible="true"
              :width="1"
            />
          </DxLabel>
        </DxSeries>
        <DxSize :width="500"/>
        <DxExport :enabled="true"/>
        <DxTooltip
          :enabled="true"
          contentTemplate="tooltipTemplate"
        />
        <template #tooltipTemplate="{ data }">
          <TooltipTemplate :info="data"/>
        </template>
      </DxPieChart>
    </div>
  </div>
</template>
<script>
import DxButton from 'devextreme-vue/button'
import { mapGetters, mapActions } from 'vuex'
import viewOcorrencia from '../View/viewOcorrencia'
import { SimpleGrid } from '@/components'
import CustomStore from 'devextreme/data/custom_store'
import { DxDateBox } from 'devextreme-vue'
import DxRadioGroup from 'devextreme-vue/radio-group'
import TooltipTemplate from './TooltipTemplate.vue'
import DxPieChart, {
  DxSize,
  DxSeries,
  DxLabel,
  DxConnector,
  DxExport,
  DxTooltip
} from 'devextreme-vue/pie-chart'
import { DxCheckBox } from 'devextreme-vue/check-box'
import {
  convertDMS
} from '@/helper/coordinates'
import {
  getOcorrencias,
  getFotoOcorrencia,
  generateGraphics,
  findAllUserCidadao,
  findAllUserFiscalizacao
} from '../service'
import mixinCrud from '../../mixin-crud'
import moment from 'moment'
import DataSource from 'devextreme/data/data_source'
import { DxTooltip as DxToolTipGrid } from 'devextreme-vue/tooltip'
import { hasPermission } from '@/util/permissions'

export default {
  name: 'list-registrar-ocorrencia',
  components: {
    SimpleGrid,
    DxButton,
    viewOcorrencia,
    DxTooltip,
    DxRadioGroup,
    DxDateBox,
    DxCheckBox,
    DxPieChart,
    DxSize,
    DxSeries,
    DxLabel,
    DxConnector,
    DxExport,
    TooltipTemplate,
    DxToolTipGrid
  },
  mixins: [mixinCrud],
  /**
   * Metodo do vue, para obter o estado inicial do componente.
   * @returns {Object} O estado inicial do componente.
   */
  data () {
    return {
      onCreatingGraphic: false,
      graphicsGenerated: false,
      onCreateGraphicPage: {
        situacaoOcorrenciaCurrentValue: null,
        irregularidadeComCurrentValue: null,
        situacaoOcorrenciaGraphicGenerated: false,
        irregularidadeComGraphicGenerated: false,
        datasourceIrregCom: null,
        datasourceSituacao: null,
        responsavelType: ['Cidadão', 'Fiscalização'],
        responsavelTypeSelected: null,
        situacaoOcorrencia: {
          checkboxValue: null
        },
        irregularidadeCom: {
          checkboxValue: null
        },
        periodo: {
          startValue: null,
          endValue: null
        }
      },
      toolTipContent: null,
      showOcorrencia: false,
      currentRowData: null,
      gridData: {
        value: {
          visible: true
        },
        columns: this.parseColumns([
          {
            dataField: 'id',
            sortOrder: 'desc',
            caption: 'Nº da Ocorrência',
            dataType: 'number',
            editorType: 'dxNumberBox',
            width: 120
          },
          {
            dataField: 'dataHoraRegistro',
            caption: 'Data/Hora do registro da ocorrência',
            format: 'dd/MM/yyyy - HH:mm',
            dataType: 'date',
            editorType: 'dxDateBox',
            width: 200
          },
          {
            dataField: 'local',
            caption: 'Local da Ocorrência',
            dataType: 'string',
            width: 200
          },
          {
            dataField: 'numeroIdentificacao',
            caption: 'Número de Identificação',
            dataType: 'string',
            width: 200
          },
          {
            dataField: 'irregularidadeCom',
            caption: 'Irregularidade com',
            width: 200,
            dataType: 'string',
            editorType: 'dxLookup',
            editorOptions: {
              items: [
                'Transporte',
                'Caçamba'
              ]
            }
          },
          {
            dataField: 'nomeRazaoSocial',
            calculateDisplayValue (data) {
              return data.nomeRazaoSocial
            },
            caption: 'Transportador(a)',
            width: 150,
            editorType: 'dxLookup',
            editorOptions: {
              dataSource: new CustomStore({
                key: 'nomeRazaoSocial',
                load: (loadOptions) => this.$utils.wrapRequestForGrid(this.find({
                  entity: 'ViewTransportador',
                  loadOptions
                })),
                byKey: async (key) => {
                  const result = await this.find({
                    entity: 'ViewTransportador',
                    params: {
                      where: {
                        nomeRazaoSocial: key
                      }
                    }
                  })
                  return result.data[0]
                }
              }),
              displayExpr: 'nomeRazaoSocial',
              valueExpr: 'nomeRazaoSocial'
            }
          },
          {
            dataField: 'cpfCnpj',
            caption: 'CPF/CNPJ',
            dataType: 'string',
            width: 130
          },
          {
            dataField: 'situacao',
            caption: 'Situação',
            dataType: 'string',
            editorType: 'dxLookup',
            width: 200,
            editorOptions: {
              items: [
                'Respondida',
                'Em andamento',
                'Aguardando resposta',
                'Improcedente'
              ]
            }
          },
          {
            dataField: 'nomeUsuarioRegistro',
            caption: 'Usuário',
            dataType: 'string',
            width: 130
          },
          {
            type: 'buttons',
            width: 280,
            showInColumnChooser: false,
            buttons: [
              {
                cssClass: 'dx-button mdi mdi-eye',
                text: 'Visualizar',
                onClick: ({ row: { data } }) => {
                  this.$router.push({ name: 'acompanhar-ocorrencia-map', params: { rowValues: data } })
                }
              },
              {
                cssClass: 'dx-button mdi mdi-pencil',
                text: 'Alterar',
                visible: (data) => {
                  if (!hasPermission('Acompanhar Ocorrência', 'altera')) {
                    return false
                  } else if (data.row.values.find(value => value === 'Em andamento')) {
                    return true
                  } else {
                    return false
                  }
                },
                onClick: ({ row: { data } }) => {
                  this.onOcorrenciaAnswer('answer', data)
                }
              },
              {
                cssClass: 'dx-button mdi mdi-checkbox-multiple-marked-outline',
                text: 'Responder',
                visible: (data) => {
                  if (!hasPermission('Acompanhar Ocorrência', 'altera')) {
                    return false
                  } else if (data.row.values.find(value => value === 'Aguardando resposta')) {
                    return true
                  } else {
                    return false
                  }
                },
                onClick: ({ row: { data } }) => {
                  this.onOcorrenciaAnswer('answer', data)
                }
              }
            ]
          }
        ]),
        onCellPrepared: async (e) => {
          if (e.rowType === 'data') {
          /*  if (e.data.veiculo) {
              this.$refs.simpleGrid.$refs.gridInstance.instance.columnOption('Transportador(a)', 'datafield', '$veiculo.perfil.pessoa.nome_razao_social$')
            }
            if (e.data.cacamba) {
              this.$refs.simpleGrid.$refs.gridInstance.instance.columnOption('Transportador(a)', 'datafield', '$cacamba.perfil.pessoa.nome_razao_social$')
            } */
          }
          if (e.rowType === 'data' && e.columnIndex === 2) {
            await this.$nextTick(() => {
              e.cellElement.onmouseenter = () => {
                this.$refs.tooltip.instance.option({
                  target: e.cellElement,
                  visible: true
                })
                const toolTipText = convertDMS(e.data.shape.coordinates[1], e.data.shape.coordinates[0])
                this.toolTipContent = toolTipText
              }
            })
          }
        },
        dataSource: new CustomStore({
          key: 'id',
          load: loadOptions => this.$utils.wrapRequestForGrid(getOcorrencias(loadOptions, this.userData, this.$utils))
        })
      }
    }
  },
  computed: {
    ...mapGetters('Login', { userData: 'userData' })
  },
  methods: {
    customLabel ({ value }) {
      return {
        visible: true,
        customizeText: ({ valueText }) => {
          return `${valueText}%`
        }
      }
    },
    clearGraphics () {
      this.onCreateGraphicPage.responsavelTypeSelected = null
      this.onCreateGraphicPage.situacaoOcorrencia.checkboxValue = null
      this.onCreateGraphicPage.irregularidadeCom.checkboxValue = null
      this.onCreateGraphicPage.irregularidadeComGraphicGenerated = false
      this.onCreateGraphicPage.situacaoOcorrenciaGraphicGenerated = false
      this.onCreateGraphicPage.periodo.startValue = null
      this.onCreateGraphicPage.periodo.endValue = null
    },
    goBackGraphic () {
      this.onCreatingGraphic = false
      this.onCreateGraphicPage.irregularidadeComGraphicGenerated = false
      this.onCreateGraphicPage.situacaoOcorrenciaGraphicGenerated = false
    },
    situacaoCheckboxStatus (value) {
      this.onCreateGraphicPage.situacaoOcorrenciaCurrentValue = value.value
      if (!value.value) {
        this.onCreateGraphicPage.situacaoOcorrenciaGraphicGenerated = false
      }
    },
    irregularidadeCheckboxStatus (value) {
      this.onCreateGraphicPage.irregularidadeComCurrentValue = value.value
      if (!value.value) {
        this.onCreateGraphicPage.irregularidadeComGraphicGenerated = false
      }
    },
    async onGenerateGraphics () {
      this.setLoading(true)
      const checkBoxValues = []
      if (this.onCreateGraphicPage.situacaoOcorrencia.checkboxValue) checkBoxValues.push(this.onCreateGraphicPage.situacaoOcorrencia.checkboxValue)
      if (this.onCreateGraphicPage.irregularidadeCom.checkboxValue) checkBoxValues.push(this.onCreateGraphicPage.irregularidadeCom.checkboxValue)
      const validate = {
        responsavelTypeSelected: this.onCreateGraphicPage.responsavelTypeSelected,
        checkBoxValues: checkBoxValues.length,
        startDate: this.onCreateGraphicPage.periodo.startValue,
        endDate: this.onCreateGraphicPage.periodo.endValue
      }
      for (const value of Object.values(validate)) {
        if (!value) {
          this.setLoading(false)
          return this.$utils.notifyError('Invalid Fields', 'Por favor, preencha todos os campos corretamente')
        }
      }
      const usersCidadao = []
      const usersFiscalizacao = []
      if (this.onCreateGraphicPage.responsavelTypeSelected === 'Cidadão') {
        const result = await findAllUserCidadao()
        for (const value of result.data.rows) {
          usersCidadao.push(value.idUsuario)
        }
      }
      if (this.onCreateGraphicPage.responsavelTypeSelected === 'Fiscalização') {
        const result = await findAllUserFiscalizacao()
        for (const value of result.data.rows) {
          usersFiscalizacao.push(value.idUsuario)
        }
      }
      const resultQueryGraphics = await generateGraphics({
        starValue: moment(this.onCreateGraphicPage.periodo.startValue).format('YYYY-MM-DD'),
        endValue: moment(this.onCreateGraphicPage.periodo.endValue).format('YYYY-MM-DD'),
        responsavel: usersCidadao.length ? usersCidadao : usersFiscalizacao,
        situacaoOcorrencia: this.onCreateGraphicPage.situacaoOcorrencia ? this.onCreateGraphicPage.situacaoOcorrencia.checkboxValue : undefined,
        irregularidadeCom: this.onCreateGraphicPage.irregularidadeCom ? this.onCreateGraphicPage.irregularidadeCom.checkboxValue : undefined
      })
      this.setLoading(false)
      if (resultQueryGraphics.data.situacaoOcorrencia.result) {
        this.onCreateGraphicPage.datasourceSituacao = new DataSource({
          store: {
            type: 'array',
            key: 'id',
            data: resultQueryGraphics.data.situacaoOcorrencia.result
          }
        })
      }
      if (resultQueryGraphics.data.irregularidadeCom.result) {
        this.onCreateGraphicPage.datasourceIrregCom = new DataSource({
          store: {
            type: 'array',
            key: 'id',
            data: resultQueryGraphics.data.irregularidadeCom.result
          }
        })
      }
      if (this.onCreateGraphicPage.situacaoOcorrenciaCurrentValue) {
        this.onCreateGraphicPage.situacaoOcorrenciaGraphicGenerated = true
      } else {
        this.onCreateGraphicPage.situacaoOcorrenciaGraphicGenerated = false
      }
      if (this.onCreateGraphicPage.irregularidadeComCurrentValue) {
        this.onCreateGraphicPage.irregularidadeComGraphicGenerated = true
      } else {
        this.onCreateGraphicPage.irregularidadeComGraphicGenerated = false
      }
    },
    createGraphics () {
      this.onCreatingGraphic = true
    },
    ...mapActions('Crud', ['setLoading']),
    /**
     * TODO
     */
    onOcorrenciaSaved () {
      this.showOcorrencia = false
    },
    /**
     * Metodo de callback de quando e clicado no botao de acao da solicitacao,
     * com o objetivo de abrir a popup de acao da solicitacao.
     * @param {string} action - A acao que sera feita na solicitacao, 'view'
     * para visualizar a solicitacao, 'update' para atualizar a solicitacao, ou
     * 'cancel' para solicitar o cancelamento da solicitacao.
     * @param {Object} solicitacao - A solicitacao que foi clicada.
     */
    async onOcorrenciaAnswer (action, ocorrencia) {
      if (action === 'answer') {
        this.setLoading(true)
        const fotoOcorrencia = await getFotoOcorrencia(ocorrencia)
        if (Array.isArray(fotoOcorrencia.data) && fotoOcorrencia.data.length) {
          ocorrencia.foto = fotoOcorrencia.data[0].foto
        }
        this.setLoading(false)
        this.currentRowData = ocorrencia
        this.showOcorrencia = true
      }
    }
  }
}
</script>

<style lang="scss">
@import '../../../../global.scss';
.ocorrenciasGraficoFieldSet {
  width: 100%;
  padding: 20px 20px;
  border-color: #eeeeee;
  border-style: solid;
}
.ocorrenciaGraphics {
  display: flex;
  @media only screen and (max-width: 1000px) {
    display: block;
  }
}
.ocorrenciaCheckboxes {
  display: block;
  @media only screen and (max-width: 932px) {
    display: grid;
  }
}
.registrar-ocorrencia {
  .dx-button-view-custom {
    min-width: 110px !important;
    min-height: 40px !important;
    display: inline-block !important;
    line-height: 38px !important;
    position: relative;
    color: $primary !important;

    &:after {
      content: 'TESTE';
      position: absolute;
      padding-right: 20px;
      top: -1px;
    }

    &:before {
      margin-left: -70px;
      margin-right: 3px;
    }
  }
}
</style>
