<template>
  <div class="enviar-transporte">
    <div>
      <dx-popup
        :hide-on-outside-click="true"
        :drag-enabled="false"
        height="auto"
        :show-title="true"
        title="Aceitar Solicitação de Transporte"
        :visible.sync="popupAcceptVisible">
        <template v-slot:content>
          <div class="solicitacao-accept-popup-content">
            <div class="solicitacao-accept-popup-form">
              <div class="dx-field">
                <div class="dx-field-label-location-top">Veículo (Placa)</div>
                <div class="dx-field-content">
                  <dx-lookup
                    v-bind="fieldsBind.veiculo"
                    :value.sync="formPopupData.veiculo"
                    @valueChanged="onChangeVeiculo"
                    ref="veiculoLookup"/>
                </div>
              </div>
              <div class="dx-field">
                <div class="dx-field-label-location-top">Tipo</div>
                <div class="dx-field-content">
                  <dx-text-box v-bind="fieldsBind.tipo" :value="formPopupData.tipo"/>
                </div>
              </div>
              <div class="dx-field" v-if="formPopupData.tipo === 'Poliguindaste'">
                <div class="dx-field-label-location-top">Poliguindaste</div>
                <div class="dx-field-content">
                  <dx-text-box v-bind="fieldsBind.poliguindaste" :value="formPopupData.poliguindaste"/>
                </div>
              </div>
              <div class="dx-field" v-if="formPopupData.tipo === 'Poliguindaste'">
                <div class="dx-field-label-location-top">Caçamba (Nº de Identicação)</div>
                <div class="dx-field-content">
                  <dx-lookup
                    v-bind="fieldsBind.cacamba"
                    :value.sync="formPopupData.cacamba"
                    ref="cacambaLookup"/>

                </div>
              </div>
            </div>

            <message-error :message="popupErrorMessage"/>

            <div class="solicitacao-accept-popup-buttons">
              <dx-button type="success" @click="onAvalicaoPopupSave">Salvar</dx-button>
              <dx-button type="normal" @click="popupAcceptVisible = false">Voltar</dx-button>
            </div>
          </div>
        </template>
      </dx-popup>

      <dx-popup
        :hide-on-outside-click="true"
        :drag-enabled="false"
        height="auto"
        :show-title="true"
        title="Recusar Solicitação de Transporte"
        :visible.sync="popupDeclineVisible">
        <template v-slot:content>
          <div class="solicitacao-decline-popup-content">
            <div class="dx-field">
              <div class="dx-field-label-location-top">Justifique a recusa da solicitaçao de transporte</div>
              <div class="dx-field-content">
                <dx-text-area
                  :max-length="200"
                  :value.sync="formPopupData.justificativa"/>
              </div>
            </div>

            <message-error :message="popupErrorMessage"/>

            <div class="solicitacao-decline-popup-buttons">
              <dx-button type="success" @click="onAvalicaoPopupSave">Salvar</dx-button>
              <dx-button type="normal" @click="popupDeclineVisible = false">Voltar</dx-button>
            </div>
          </div>
        </template>
      </dx-popup>

      <div class="md-layout">
        <div class="md-layout-item">
          <h2 class="crud-title">Avaliar Solicitação de Transporte</h2>
        </div>

        <div
          v-if="canGoToEnviarTransporteNaoSolicitado"
          class="md-layout-item"
          style="text-align: right;">
          <dx-button text="Enviar transporte não Solicitado" styling-mode="contained" type="success" @click="$router.push({ path: 'enviar-transporte-nao-solicitado/add' })"></dx-button>
        </div>
      </div>
      <simple-grid
        entity="SolicitacaoTransporte"
        :editable="false"
        :deletable="false"
        :grid-data="gridData"
        popup-title="Solicitacao transporte"
        ref="simpleGrid"/>
    </div>
  </div>
</template>
<script>
import { SimpleGrid } from '@/components'
import CustomStore from 'devextreme/data/custom_store'
import { DxButton, DxPopup, DxTextArea, DxLookup, DxTextBox } from 'devextreme-vue'

import mixinCrud from '../mixin-crud'
import {
  saveAvaliacaoSolicitacoesTransporte,
  getSolicitacoesTransporteSolicitados,
  getPessoaGeradorObra
} from './service'
import { getAvailableVeiculos, getAvailableCacambas } from '@/components/SolicitacaoTransporte/service'
import MessageError from '../../../components/MessageError'
import { hasPermission } from '@/util/permissions'
import { doRequest } from '@/pluggables/http'

export default {
  name: 'solicitacao-transporte-crud',
  mixins: [
    mixinCrud
  ],
  components: {
    SimpleGrid,
    DxButton,
    DxPopup,
    DxTextArea,
    DxLookup,
    DxTextBox,
    MessageError
  },
  /**
   * Metodo do vue, para obter o estado inicial do componente.
   * @returns {Object} O estado inicial do componente.
   */
  data () {
    const canEdit = hasPermission('Avaliar Solicitação de Transporte', 'altera')

    return {
      entity: 'SolicitacaoTransporte',
      formData: null,
      gridData: {
        value: {
          visible: true
        },
        columns: this.parseColumns([
          {
            dataField: 'id',
            caption: 'Nº do CTR',
            editorType: 'dxNumberBox'
          },
          {
            dataField: '$obra->gerador->pessoa.id$',
            calculateDisplayValue: 'obra.gerador.pessoa.nomeRazaoSocial',
            caption: 'Solicitante',
            editorType: 'dxLookup',
            editorOptions: {
              dataSource: new CustomStore({
                key: 'id',
                load: loadOptions =>
                  this.$utils.wrapRequestForGrid(
                    getPessoaGeradorObra({ loadOptions, displayField: 'nomeRazaoSocial' })
                  ),
                byKey: async key => {
                  const res = await getPessoaGeradorObra({
                    where: { id: key },
                    displayField: 'nomeRazaoSocial'
                  })
                  return res.totalCount > 0 ? res.data[0] : null
                }
              }),
              displayExpr: 'nomeRazaoSocial',
              valueExpr: 'id'
            }
          },
          {
            dataField: '$obra.nome_obra$',
            calculateDisplayValue: 'obra.nomeObra',
            calculateSortValue: 'obra.nomeObra',
            caption: 'Nome da obra',
            dataType: 'string'
          },
          {
            dataField: '$obra->gerador->pessoa.telefone$',
            calculateDisplayValue: 'obra.gerador.pessoa.telefone',
            caption: 'Telefone',
            editorType: 'dxNumberBox',
            dataType: 'number'
          },
          {
            dataField: '$obra->gerador->pessoa.celular$',
            calculateDisplayValue: 'obra.gerador.pessoa.celular',
            caption: 'Celular',
            editorType: 'dxNumberBox',
            dataType: 'number'
          },
          {
            dataField: '$obra.endereco$',
            calculateDisplayValue: 'obra.endereco',
            calculateSortValue: 'obra.endereco',
            caption: 'Endereço',
            dataType: 'string'
          },
          {
            dataField: 'dataHoraSolicitacao',
            caption: 'Data/Hora da Solicitação',
            format: 'dd/MM/yyyy - HH:mm',
            dataType: 'date',
            editorType: 'dxDateBox'
          },
          {
            dataField: '$residuoPredominante.classeMaterial.id$',
            calculateDisplayValue (data) {
              return `${data.residuoPredominante.classeMaterial.classe} (${data.residuoPredominante.nome})`
            },
            xlsxObjPath: '{residuoPredominante.classeMaterial.classe}({residuoPredominante.nome})',
            calculateFilterExpression: (classeMaterial, operation) => {
              return ['$residuoPredominante->classeMaterial.id$', operation, classeMaterial]
            },
            caption: 'Classe de Resíduo / Tipo de Resíduo Predominante',
            editorType: 'dxLookup',
            editorOptions: {
              allowClearing: true,
              showClearButton: true,
              showCancelButton: false,
              displayExpr: (data) => {
                if (data && data.classeMaterial) return `${data.classeMaterial.classe} (${data.nome})`
                else return ''
              },
              valueExpr: 'classeMaterial.id',
              dataSource: {
                key: 'id',
                store: new CustomStore({
                  load: async () => {
                    this.showProgressBarLoading = true
                    const result = await doRequest({
                      method: 'post',
                      url: 'TipoResiduo/find',
                      params: {
                        include: [
                          {
                            model: 'ClasseMaterial',
                            as: 'classeMaterial',
                            required: true,
                            attributes: ['id', 'classe']
                          }
                        ],
                        subQuery: false
                      }
                    })
                    this.showProgressBarLoading = false
                    return result.data.rows
                  },
                  byKey: key => key
                }),
                paginate: true,
                pageSize: 10,
                reshapeOnPush: true
              }
            }
          },
          {
            dataField: 'quantidadeCacamba',
            caption: 'Quantidade de Caçambas',
            editorType: 'dxNumberBox'
          },
          {
            dataField: 'volumeAproximadoM3',
            caption: 'Volume aproximado em m³'
          },
          ...(canEdit ? [{
            type: 'buttons',
            minWidth: 230,
            width: 230,
            showInColumnChooser: false,
            buttons: [
              {
                name: 'decline',
                cssClass: 'dx-button mdi mdi-thumb-down',
                text: 'Recusar',
                onClick: ({ row: { data } }) => {
                  this.onSolicitacaoActionClick('decline', data)
                }
              },
              {
                name: 'accept',
                cssClass: 'dx-button mdi mdi-thumb-up',
                text: 'Aceitar',
                onClick: ({ row: { data } }) => {
                  this.onSolicitacaoActionClick('accept', data)
                }
              }
            ]
          }] : [])
        ]),
        dataSource: new CustomStore({
          key: 'id',
          load: loadOptions => this.$utils.wrapRequestForGrid(getSolicitacoesTransporteSolicitados(loadOptions))
        })
      },
      popupAcceptVisible: false,
      popupDeclineVisible: false,
      solicitacaoPopup: null,
      formPopupData: {
        cacamba: null,
        veiculo: null,
        tipo: null,
        poliguindaste: null,
        justificativa: null
      },
      fieldsBind: {
        cacamba: {
          allowClearing: true,
          showClearButton: true,
          showCancelButton: false,
          dropDownOptions: {
            closeOnOutsideClick: true
          },
          dataSource: new CustomStore({
            key: 'id',
            load: loadOptions => this.$utils.wrapRequestForGrid(getAvailableCacambas({}, { ...loadOptions, paginate: false })),
            byKey: async (key, loadOptions) => {
              const res = await getAvailableCacambas({ loadOptions, where: { id: key } })
              return res.totalCount > 0 ? res.data[0] : null
            }
          }),
          paginate: false,
          displayExpr: 'numeroIdentificacao',
          noDataText: 'Não existem caçambas disponíveis nesse momento.'
        },
        veiculo: {
          allowClearing: true,
          showClearButton: true,
          showCancelButton: false,
          dataSource: new CustomStore({
            key: 'id',
            load: loadOptions => this.$utils.wrapRequestForGrid(getAvailableVeiculos({}, { ...loadOptions, paginate: false })),
            byKey: async key => {
              const res = await getAvailableVeiculos({ where: { id: key } })
              return res.totalCount > 0 ? res.data[0] : null
            }
          }),
          paginate: false,
          displayExpr: 'placa',
          noDataText: 'Não existem veículos disponíveis nesse momento.'
        },
        tipo: {
          readOnly: true
        },
        poliguindaste: {
          readOnly: true
        }
      },
      popupErrorMessage: null
    }
  },

  computed: {
    /**
     * Valor computado de se o usuario logado pode acessar a pagina de enviar
     * transporte nao solicitado.
     * @returns {boolean}
     */
    canGoToEnviarTransporteNaoSolicitado () {
      return hasPermission('Enviar Transporte não Solicitado', 'consulta')
    }
  },

  watch: {
    /**
     * Metodo de callback de quando ocorre mudancas no valor da prop
     * 'popupAcceptVisible', com o objetivo de limpar os dados do popup de
     * aceitar solicitacao ou atualizar a lista de veiculos e cacambas.
     * @param {Boolean} visible - Se o popup de aceitar solicitacao esta visvel.
     */
    popupAcceptVisible (visible) {
      if (!visible) {
        this._clearPopupData()

        this.$refs.cacambaLookup && this.$refs.cacambaLookup.instance.close()
        this.$refs.veiculoLookup && this.$refs.veiculoLookup.instance.close()
      } else {
        this.$refs.cacambaLookup && this.$refs.cacambaLookup.instance.getDataSource().reload()
        this.$refs.veiculoLookup && this.$refs.veiculoLookup.instance.getDataSource().reload()
      }
    },

    /**
     * Metodo de callback de quando ocorre mudancas no valor da prop
     * 'popupDeclineVisible', com o objetivo de limpar os dados do popup de
     * recusar solicitacao.
     * @param {Boolean} visible - Se o popup de recusar solicitacao esta visvel.
     */
    popupDeclineVisible (visible) {
      if (!visible) {
        this._clearPopupData()
      }
    }
  },
  methods: {
    /**
     * Metodo de callback de quando e clicado no botao de acao da solicitacao,
     * com o objetivo de abrir a popup de acao da solicitacao.
     * @param {string} action - A acao que sera feita na solicitacao, 'accept'
     * para aceitar a solicitacao, ou 'decline' para recusar a solicitacao.
     * @param {Object} solicitacao - A solicitacao que foi clicada.
     */
    onSolicitacaoActionClick (action, solicitacao) {
      this.solicitacaoPopup = solicitacao
      this[action === 'accept' ? 'popupAcceptVisible' : 'popupDeclineVisible'] = true
      this.formPopupData = {
        cacamba: null,
        veiculo: null,
        tipo: null,
        poliguindaste: null,
        justificativa: null
      }
    },

    /**
     * Metodo de callback de quando foi alterado o valor do veiculo no popup de
     * aceitar solicitacao. Com o objetivo de preencher o valor dos outros
     * campos do veiculo.
     * @param {Object} params - Os parametros do evento que ocorreu.
     * @param {Object} params.value - O veiculo que foi selecionado.
     */
    onChangeVeiculo ({ value }) {
      if (value) {
        this.formPopupData.tipo = value.tipo
        this.formPopupData.poliguindaste = value.poliguindaste
      }
    },

    /**
     * Metodo de callback de quando foi clicado no botao de salvar dos popup de
     * acao da solicitacao. Com o objetivo de salvar a avaliacao da solicitacao.
     */
    async onAvalicaoPopupSave () {
      this.popupErrorMessage = null

      if (this.popupAcceptVisible) {
        if (!this.formPopupData.veiculo) {
          this.popupErrorMessage = 'O veiculo informado é inválido.'
          return
        }
        if (this.formPopupData.tipo === 'Poliguindaste') {
          if (!this.formPopupData.cacamba) {
            this.popupErrorMessage = 'A caçamba informada é inválida.'
            return
          }
          if (this.formPopupData.cacamba.solicitacoesTransporte && this.formPopupData.cacamba.solicitacoesTransporte.length) {
            for (const solicitacaoTransporte of this.formPopupData.cacamba.solicitacoesTransporte) {
              if (solicitacaoTransporte.situacao === 'Enviada' || solicitacaoTransporte.situacao === 'Alocada') {
                this.popupErrorMessage = `Atenção, a cacamba "${this.formPopupData.cacamba.numeroIdentificacao}" já está atrelado a CTR Nº:"${solicitacaoTransporte.id}"`
                return
              }
            }
          }
          this.formPopupData.cacamba = this.formPopupData.cacamba.id
        }

        if (this.formPopupData.veiculo.solicitacoesTransporte && this.formPopupData.veiculo.solicitacoesTransporte.length) {
          for (const solicitacaoTransporte of this.formPopupData.veiculo.solicitacoesTransporte) {
            if (solicitacaoTransporte.situacao === 'Enviada' || solicitacaoTransporte.situacao === 'Alocada') {
              this.popupErrorMessage = `Atenção, o veículo de placa "${this.formPopupData.veiculo.placa}" já está atrelado a CTR Nº:"${solicitacaoTransporte.id}"`
              return
            }
          }
        }
        this.formPopupData.veiculo = this.formPopupData.veiculo.id
      } else if (!this.formPopupData.justificativa || !this.formPopupData.justificativa.length) {
        this.popupErrorMessage = 'A justificativa informada é inválida.'
        return
      }
      if (this.formPopupData.tipo === 'Basculante') {
        this.formPopupData.cacamba = null
      }
      const res = await saveAvaliacaoSolicitacoesTransporte({
        ...this.formPopupData,
        avaliacao: this.popupAcceptVisible,
        id: this.solicitacaoPopup.id
      })

      if (res.success) {
        this.$refs.simpleGrid.$refs.gridInstance.instance.getDataSource().reload()

        this[this.popupAcceptVisible ? 'popupAcceptVisible' : 'popupDeclineVisible'] = false
        this._clearPopupData()
      } else {
        this.popupErrorMessage = res.error
      }
    },

    /**
     * Metodo de comando, com o objetivo de limpar os dados dos popups de acao
     * da solicitacao.
     */
    _clearPopupData () {
      this.solicitacaoPopup = null
      this.formPopupData = {
        cacamba: null,
        veiculo: null,
        tipo: null,
        poliguindaste: null,
        justificativa: null
      }
      this.popupErrorMessage = null
    }
  }
}
</script>

<style lang="scss">
@import '../../../global.scss';

.solicitacao-decline-popup-content {
  display: flex;
  flex-direction: column;

  .solicitacao-decline-popup-buttons {
    display: flex;
    flex-direction: row-reverse;
  }

  > :not(.solicitacao-decline-popup-buttons) {
    flex: 1;
  }

  .error-message-card {
    background-color: #B00020 !important;
    color: white !important;
    width: 100%;
    margin-top: 6px;
    margin-bottom: 16px;
  }
}

.solicitacao-accept-popup-content {
  display: flex;
  flex-direction: column;

  > .solicitacao-accept-popup-form {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 16px;
  }

  > .solicitacao-accept-popup-buttons {
    display: flex;
    flex-direction: row-reverse;
  }

  .error-message-card {
    background-color: #B00020 !important;
    color: white !important;
    width: 100%;
    margin-top: 6px;
    margin-bottom: 16px;
  }
}
</style>
