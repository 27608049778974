<template>
  <div class="map-localizar-cacamba">
    <div class="dx-field" style="display: none;">
      <div class="checkbox">
        <DxRadioGroup :value="true"/>
      </div>
    </div>
    <leaflet-map :bounds.sync="mapBounds" ref="leafletMap">
      <leaflet-marker
        v-for="(marker, i) in markers"
        :key="i"
        :markerOptions="markerOptions"
        :latlng="marker.latlng"
        @click="onCacambaClick(marker)">
        <div class="cacamba-icon">
          <md-icon ref="icon" class="mdi mdi-bucket"></md-icon>
        </div>
      </leaflet-marker>
    </leaflet-map>
    <md-card
      v-if="message"
      :class="getMessageClasses(message)">
      <md-card-content>{{ message.message }}</md-card-content>
    </md-card>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Vue from 'vue'
import OcorrenceCacamba from './assets/ocorrence-truck.svg'
import OcorrenceTransporte from './assets/ocorrence-car.svg'
import DxRadioGroup from 'devextreme-vue/radio-group'
import CacambaTransporteCheckBox from './Components/CacambaTransporteCheckBox'
import { LeafletMap, LeafletMarker } from '@/components/LeafletMap'
import L from 'leaflet'
import {
  findOcorrencias,
  findAllUserCidadao
} from '../service'
import PopupOcorrencia from './Components/PopupOcorrencia'

export default {
  name: 'map-localizar-cacamba',
  components: {
    LeafletMap,
    LeafletMarker,
    DxRadioGroup
  },
  props: {
    rowValues: {
      type: Object,
      required: true
    }
  },
  /**
     * Metodo do vue de obter o estado inicial do componente.
     * @returns {Object} O estado inicial do componente.
     */
  data () {
    return {
      markerOptions: {
        interactive: false
      },
      allUsersCidadao: null,
      radioOcorrenciaValue: null,
      currentLocation: null,
      showTransportePopup: false,
      marker: null,
      popupInstance: null,
      selectedType: null,
      typeSelection: ['Caçamba', 'Transporte'],
      markers: [],
      message: null,
      lastUpdateDate: '',
      totalCacambas: 0,
      mapBounds: null,
      situacoesFilter: [
        {
          situacao: 'Caçamba',
          value: 0,
          checked: false,
          colorClass: 'cacamba-enviada'
        },
        {
          situacao: 'Transporte',
          value: 0,
          checked: false,
          colorClass: 'cacamba-alocada'
        }
      ],
      ocorrenciasFilter: [
        {
          situacao: 'Com ocorrência Pessoa Física',
          value: 0,
          checked: true,
          colorClass: 'cacamba-ocorrencia-pf'
        },
        {
          situacao: 'Com ocorrência Pessoa Jurídica',
          value: 0,
          checked: true,
          colorClass: 'cacamba-ocorrencia-pj'
        }
      ],
      cacambaPopup: null,
      showSituacaoCacambaPopup: false
    }
  },

  watch: {
    /**
       * Metodo de callback de quando ocorre mudancas no valor da prop 'mapBounds',
       * com o objetivo de atualizar as cacambas no mapa.
       * @param {number[][]} currentBounds - O valor atual da prop.
       * @param {number[][]} oldBounds - O valor anterior da prop.
       */
    mapBounds (currentBounds, oldBounds) {
      this.updateCacambasMap(oldBounds === null)
    },

    situacoesFilter: 'onChangeFilter',
    ocorrenciasFilter: 'onChangeFilter',

    /**
       * Metodo de callback de quando ocorre mudancas na prop
       * 'showSituacaoCacambaPopup', com o objetivo de limpar os dados da cacamba
       * do popup quando ele e fechado.
       * @param {boolean} popupActive - O valor atual da prop.
       */
    showSituacaoCacambaPopup (popupActive) {
      if (!popupActive) {
        this.cacambaPopup = null
      }
    }
  },

  /**
     * Metodo do ciclo de vida do vue, de quando o componente foi montado na tela.
     * Com o objetivo de buscar os dados das cacambas.
     */
  async mounted () {
    await this._findAllUsersCidadao()
    const ComponentClass = Vue.extend(CacambaTransporteCheckBox)
    const instance = new ComponentClass({
      propsData: {
        title: 'Caçamba Transporte CheckBox',
        marker: this.marker,
        setLoading: (status) => {
          return this.setLoading(status)
        },
        defaultValue: this.rowValues.irregularidadeCom
      }
    })
    instance.$mount()
    L.Control.MyControl = L.Control.extend({
      onAdd: function (map) {
        L.DomEvent.disableClickPropagation(instance.$el)
        return instance.$el
      }
    })

    L.control.myControl = function (opts) {
      return new L.Control.MyControl(opts)
    }

    L.control.myControl({ position: 'topright' }).addTo(this.$refs.leafletMap.mapInstance)
    const icon = L.icon({
      iconUrl: this.rowValues.irregularidadeCom === 'Transporte' ? OcorrenceTransporte : this.rowValues.irregularidadeCom === 'Caçamba' ? OcorrenceCacamba : '',
      className: 'ocorrencia-icon',
      iconSize: [38, 40]
    })
    const latlng = {
      lat: this.rowValues.shape.coordinates[1],
      lng: this.rowValues.shape.coordinates[0]
    }
    this.marker = L.marker(latlng, { icon })
    this.marker.addTo(this.$refs.leafletMap.mapInstance)

    this.$refs.leafletMap.mapInstance.setView([latlng.lat + 0.0008, latlng.lng], 70)
    const latlong = {
      latlng: {
        lat: this.rowValues.shape.coordinates[1],
        lng: this.rowValues.shape.coordinates[0]
      }
    }
    this._newInstancePopup(latlong, this.$refs.leafletMap.mapInstance, this)
    this.marker.on('click', () => {
      this._newInstancePopup(latlong, this.$refs.leafletMap.mapInstance, this)
    })
  },
  computed: {
    ...mapGetters('Login', { userData: 'userData' })
  },
  methods: {
    _newInstancePopup (e, mapInstance, mapVueInstance) {
      const ComponentClass = Vue.extend(PopupOcorrencia)
      const instance = new ComponentClass({
        propsData: {
          title: 'Ocorrência',
          marker: this.marker,
          userData: this.userData,
          values: this.rowValues,
          setLoading: (status) => {
            return this.setLoading(status)
          },
          find: (values) => {
            return this.find({ entity: values.entity, params: values.params })
          }
        },
        created () {
          this.$on(['taskSaved'], function (payload) {
            mapVueInstance.$router.push({ path: '/acompanhar-ocorrencia/' })
          })
        }
      })
      instance.$mount()
      instance.showNumeroIdentificacao = false
      this.popupInstance = L.popup({
        closeOnClick: false,
        className: 'popupOcorrenciaContainer'
      }).setLatLng(e.latlng)
        .setContent(instance.$el)
      this.marker.bindPopup(this.popupInstance).openPopup()
      this.marker
        .openTooltip()
    },
    async _findAllUsersCidadao () {
      const result = await findAllUserCidadao()
      this.allUsersCidadao = result.data.rows
    },
    getUrl () {
      return 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer'
    },
    tipoRegistroOcorrenciaRadioGroup (payload) {
    },

    /**
       * Meoto de comando, com o objetivo de atualizar as cacambas do mapa.
       * @param {boolean} zoomMarkers - Se deve dar zoom nos marcadores, depois de
       * busca-los.
       */
    async updateCacambasMap (zoomMarkers) {
      this.message = null
      const shapeParam = this.rowValues.shape
      const cacambasResult = await findOcorrencias({
        ids: this.markers.map(marker => marker.cacamba.id),
        idUsersCidadao: this.allUsersCidadao,
        shape: shapeParam
      })

      if (cacambasResult.success) {
        if (cacambasResult.data.cacambas.find(cacamba => !cacamba.shape)) {
          this.message = {
            message: 'Não foi encontrada a localização de uma ou mais cacambas.',
            type: 'info'
          }
        }

        this.createMarkers(cacambasResult.data.cacambas)

        if (zoomMarkers && !this.currentLocation) {
          this.zoomToMarkers(this.markers)
        }
      } else {
        console.error(cacambasResult.error)
        this.markers = []
        this.message = {
          message: 'Ocorreu uma falha ao buscar a lista de caçambas para o mapa.',
          type: 'error'
        }
      }
    },

    /**
       * Metodo de callback de quando ocorrem mudancas no filtro de situacoes das
       * cacambas, com o objetivo de remover os marcadores atuais e buscar a nova
       * lista de cacambas.
       */
    onChangeFilter () {
      this.markers = []
      this.updateCacambasMap(false)
    },

    /**
       * Metodo de comando, com o objetivo de atualizar todos os dados da cacamba.
       */
    updateCacambasData () {
      this.markers = []
      this.updateCacambasMap(true)
    },

    /**
       * Metodo de callback de quando clica no icone de cacamba, com o objetivo de
       * abrir o popup de situacao da cacamba.
       * @param {Object} marker - Os dados do marcador da cacamba que se quer
       * obter o conteudo do popup.
       */
    onCacambaClick (marker) {
      this.cacambaPopup = marker.cacamba
      this.showSituacaoCacambaPopup = true
    },

    /**
       * Metodo de criacao de markers das cacambas.
       * @param {Object[]} cacambas - As cacambas que serao criados os marcadores.
       */
    createMarkers (cacambas) {
      this.markers = cacambas
        .filter(cacamba => cacamba.shape)
        .map(cacamba => ({
          cacamba,
          latlng: [cacamba.shape.coordinates[1], cacamba.shape.coordinates[0]],
          options: {}
        }))
    },

    /**
       * Metodo de comando, com o objetivo de dar zoom no mapa em uma lista de
       * marcadores.
       * @param {Object[]} markers - A lista de marcadores que receberao zoom no
       * mapa.
       */
    zoomToMarkers (markers) {
      if (markers.length > 0) {
        this.$refs.leafletMap.mapInstance.fitBounds([
          markers.map(marker => marker.latlng)
        ])
      }
    },

    /**
       * Metodo para obter a lista de classes para o card de mensagens.
       * @param {Object} message - Os dados da mensagem atual.
       */
    getMessageClasses (message) {
      return {
        error: ['map-message-card', 'error-message'],
        info: ['map-message-card', 'info-message']
      }[message.type] || null
    },

    /**
       * Metodo para obter o filtro da lista de situacoes como parametro para as
       * buscas.
       * @returns {string[]} A lista de situacoes do filtro.
       */
    getSituacoesParam () {
      return [
        this.situacoesFilter,
        this.ocorrenciasFilter
      ]
        .flat()
        .reduce((acc, situacaoFilter) => {
          if (situacaoFilter.checked) {
            acc.push(situacaoFilter.situacao)
          }
          return acc
        }, [])
    },

    /**
       * Metodo para obter o geojson da visualizacao atual do mapa.
       * @returns {Object} O geoJson da visualizacao atual do mapa.
       */
    getBoundsGeoJson () {
      return {
        type: 'Polygon',
        coordinates: [[
          // ymax, xmin
          [this.mapBounds[1][0], this.mapBounds[0][1]],
          // ymax, xmax
          [this.mapBounds[1][0], this.mapBounds[1][1]],
          // ymin, xmax
          [this.mapBounds[0][0], this.mapBounds[1][1]],
          // ymin, xmin
          [this.mapBounds[0][0], this.mapBounds[0][1]],
          // ymax, xmin
          [this.mapBounds[1][0], this.mapBounds[0][1]]
        ]]
      }
    },

    ...mapActions('Crud', ['setLoading', 'find'])
  }
}
</script>

<style lang="scss">
.page-route-acompanhar-ocorrencia-map.md-app-content {
  padding: 0;
  margin: 0;
}

.cs {
  cursor: pointer;
}
.geocoderInput {
  @media only screen and (max-width: 600px) {
    width: 121px;
  }
}
.geocodeClass {
  position: absolute;
  left: 585px;
  background-color: white;
  @media only screen and (max-width: 1390px) {
    position: absolute;
    left: 100%;
    background-color: white;
  }
}
.geocodeSuggestionsClass {
  border-radius: 30px;
  cursor: pointer;
}
.page-route-acompanhar-ocorrencia-map {
  > div {
    display: flex;
  }
}
</style>
