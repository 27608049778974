export default {
  props: {
    /**
     * Se o popup esta ativo.
     */
    active: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    /**
     * Valor computado de se o popup esta ativo.
     * @returns {boolean}
     */
    popupActive: {
      get () {
        return this.active
      },
      set (v) {
        this.$emit('update:active', v)
      }
    }
  }
}
